import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import JobsList from 'components/Jobs/JobsList/JobsList';
import PlaceholderContent from 'componentsShared/PlaceholderContent/PlaceholderContent';
import {
  NO_INFORMATION_FOUND_JOB_TEXT,
  NO_INFORMATION_FOUND_SEARCH_TEXT,
} from 'constants/ui';
import { JOBS_TYPES_ALL } from 'constants/jobs';
import { array } from 'prop-types';
import st from './JobListWrapper.module.scss';

JobListWrapper.propTypes = {
  data: array,
};

function JobListWrapper({ cardValue = {}, data = [] }) {
  const isSearch =
    useSelector((state) => !!state?.jobs?.filterValue?.search) ?? false;

  const { type, ids, finalIdsProgress } = cardValue;

  if (!ids || ids.length === 0) {
    return (
      <PlaceholderContent
        emptyText={NO_INFORMATION_FOUND_JOB_TEXT}
        searchEmptyText={NO_INFORMATION_FOUND_SEARCH_TEXT}
        isSearch={isSearch}
        classNameContainer={st.emptyContainer}
      />
    );
  }

  if (type === JOBS_TYPES_ALL) {
    const currentData = [...data];

    const allTypesIndex = data.findIndex(
      (item) => item.type === JOBS_TYPES_ALL
    );

    currentData.splice(allTypesIndex, 1);

    return currentData.map((item, index) => {
      const lastIndex = currentData.length - 1;

      return (
        <JobsList
          key={uuidv4()}
          ids={item.ids}
          type={item.type}
          finalIdsProgress={item.finalIdsProgress}
          isPaddingBottom={index === lastIndex}
        />
      );
    });
  }

  return (
    <JobsList
      key={uuidv4()}
      ids={ids}
      type={type}
      finalIdsProgress={finalIdsProgress}
    />
  );
}

export default memo(JobListWrapper);
