import React from 'react';
import { FormControl } from 'react-bootstrap';
import { FORMAT_DATE_USA } from 'constants/ui';
import moment from 'moment';
import DateRangePickerBootstrap from 'react-bootstrap-daterangepicker';
import { CalendarReportIcon } from 'assets/icons';
import st from './DateRangePicker.module.scss';

function DateRangePicker({
  dates = {},
  setDates = () => {},
  handleChangeDate = () => {},
}) {
  const onChangeDate = (e, value) => {
    setDates({
      startDate: value.startDate._d,
      endDate: value.endDate._d,
    });
    handleChangeDate();
  };

  return (
    <div className={st.wrapperTimeFrame}>
      <DateRangePickerBootstrap
        onApply={onChangeDate}
        initialSettings={{
          startDate: moment(dates.startDate),
          endDate: moment(dates.endDate),
          locale: {
            format: FORMAT_DATE_USA,
          },
        }}>
        <FormControl className={st.inputTime} />
      </DateRangePickerBootstrap>
      <div className={st.holderCalendarIcon}>
        <CalendarReportIcon className={st.calendarIcon} />
      </div>
    </div>
  );
}

export default DateRangePicker;
