import React, { useEffect } from 'react';
import Loader from 'componentsShared/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import NavigationService from 'services/navigation/NavigationService';
import { ROUTES } from 'router/routes';
import AuthService from 'services/auth/AuthService';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useParams, useLocation } from 'react-router';
import { USER_ROLES } from 'constants/userRoles';
import { getRoutePath } from 'helpers/path';
import * as jobsActions from 'store/jobs/actions';
import * as authActions from 'store/auth/actions';
import * as ApiTools from 'services/api/ApiService';
import LocalStorageService from 'services/localStorage/LocalStorageService';
import { KEY_DEFAULT_REDIRECT } from 'constants/ui';

function CheckQrCodePage() {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { id: jobId } = useParams();

  const user = useSelector((state) => state?.auth?.user) ?? {};
  const isUser = Object.keys(user)?.length > 0;

  const checkRole = () => {
    const highestUserRole = AuthService.getHighestUserRole();

    if (isUser) {
      if (
        highestUserRole === USER_ROLES.admin ||
        highestUserRole === USER_ROLES.manager
      ) {
        NavigationService.navigateToPath(
          getRoutePath(ROUTES.productionDetailsManager.path, {
            id: jobId,
          }),
          { [KEY_DEFAULT_REDIRECT]: true }
        );
        return;
      }

      if (
        highestUserRole === USER_ROLES.qa ||
        highestUserRole === USER_ROLES.shipping ||
        highestUserRole === USER_ROLES.painter ||
        highestUserRole === USER_ROLES.preparer
      ) {
        dispatch(jobsActions.changeJobIdPhone({ qr: jobId }));
        NavigationService.navigateToPath(ROUTES.jobList.path);
        return;
      }

      NavigationService.navigateToPath(
        getRoutePath(ROUTES.orderDetailsHistory.path, {
          jobId,
        }),
        { [KEY_DEFAULT_REDIRECT]: true }
      );

      return;
    }

    LocalStorageService.clear('token');
    ApiTools.api.deleteToken();
    dispatch(authActions.changeUrlRedirectAfterAuthorization(pathname));

    NavigationService.replacePath(ROUTES.loginEmployee.path);
  };

  useEffect(() => {
    checkRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <Loader isVisible />
    </div>
  );
}

export default CheckQrCodePage;
