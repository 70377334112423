import React, { useState } from 'react';
import Text from 'componentsShared/Text/Text';
import { IMAGE_TYPE_ARRAY } from 'constants/files';
import {
  checkFileTypeDocOrXlsFromPath,
  getIconFile,
  getTypeFile,
} from 'helpers/file';
import cn from 'classnames';
import ModalFilePreview from 'components/Modal/ModalFilePreview/ModalFilePreview';
import * as filesActions from 'store/files/actions';
import { useDispatch } from 'react-redux';
import st from './OrderHistoryFileList.module.scss';

function OrderHistoryFileList({
  fileList = [],
  isOpen = false,
  className = '',
}) {
  const dispatch = useDispatch();

  const [selectedDataFile, setSelectedDataFile] = useState({
    path: '',
    content_type: '',
  });

  const handleClickCard = (filePath, type) => {
    const isTypeDocOrXls = checkFileTypeDocOrXlsFromPath(filePath);

    if (isTypeDocOrXls) {
      dispatch(
        filesActions.getFile.start({
          path: filePath,
          download: true,
        })
      );
      return;
    }

    setSelectedDataFile({
      path: filePath,
      content_type: type,
    });
  };

  const closeModal = () => {
    setSelectedDataFile({
      path: '',
      content_type: '',
    });
  };

  if (!isOpen) {
    return null;
  }

  return (
    <div className={cn(st.container, className)}>
      {isOpen && (
        <div className={st.containerFileList}>
          {fileList?.length > 0 && (
            <>
              <div className={st.itemFiles}>
                {fileList.map((item) => {
                  const pathArray = item?.split('/') || [];
                  const validPathName = pathArray[pathArray.length - 1];

                  const typeFile = getTypeFile(item);

                  const isTypeImage =
                    IMAGE_TYPE_ARRAY?.includes(typeFile) || false;
                  const isTypePDF = typeFile?.includes('pdf') || false;

                  return (
                    <div
                      key={item}
                      className={cn(st.itemFileWrapper, {
                        [st.cursorDefault]: !isTypeImage && !isTypePDF,
                      })}
                      onClick={() => handleClickCard(item, typeFile)}>
                      {getIconFile(item, st.iconFile)}
                      <Text className={st.itemFileName}>{validPathName}</Text>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      )}
      <ModalFilePreview
        data={selectedDataFile}
        showModal={selectedDataFile.path}
        setShowModal={closeModal}
      />
    </div>
  );
}

export default OrderHistoryFileList;
