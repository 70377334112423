import React from 'react';
import Loader from 'componentsShared/Loader/Loader';
import {
  NO_INFORMATION_FOUND_SEARCH_CUSTOMER_TEXT,
  NO_INFORMATION_FOUND_SEARCH_TEXT,
} from 'constants/ui';
import PlaceholderContent from 'componentsShared/PlaceholderContent/PlaceholderContent';
import { useSelector } from 'react-redux';
import CustomerCardManager from 'components/Customers/CustomersCardManager/CustomersCardManager';
import cn from 'classnames';
import st from './CustomersListManagerShipping.module.scss';

function CustomersListManagerShipping({ onClickCard }) {
  const customers =
    useSelector((state) => state?.customers?.customers?.data) ?? {};
  const customersIds =
    useSelector((state) => state?.customers?.customers.sortIds) ?? [];
  const searchValue =
    useSelector((state) => state?.customers?.filterValue?.search) ?? '';
  const isLoadingCustomers =
    useSelector((state) => state?.customers?.customers?.isLoading) ?? false;

  const renderCustomersList = () => {
    return customersIds.map((id) => {
      const currentCustomer = customers[id];
      return (
        <CustomerCardManager
          key={`item-customer-${id}`}
          customer={currentCustomer}
          onClickCard={onClickCard}
        />
      );
    });
  };

  return (
    <div
      className={cn(st.cusomerList, {
        [st.cusomerListLoading]: isLoadingCustomers,
      })}>
      {customersIds?.length > 0 ? (
        renderCustomersList()
      ) : (
        <PlaceholderContent
          emptyText={NO_INFORMATION_FOUND_SEARCH_CUSTOMER_TEXT}
          searchEmptyText={NO_INFORMATION_FOUND_SEARCH_TEXT}
          isSearch={!!searchValue}
          classNameContainer={st.holderEmpty}
          classNameText={st.emptyText}
        />
      )}
      <Loader isVisible={isLoadingCustomers} isBackgroundColorGray />
    </div>
  );
}

export default CustomersListManagerShipping;
