import { USER_ROLES } from 'constants/userRoles';

export const ROUTES = {
  redirectUrl: {
    path: 'sign-in',
    roles: [],
  },
  signIn: {
    path: 'sign-in',
    roles: [],
  },
  signUpConfirm: {
    path: 'sign-up-confirm/:token',
    roles: [],
  },
  forgotPassword: {
    path: 'forgot-password',
    roles: [],
  },
  changeTempPassword: {
    path: 'change-temp-password',
    roles: [
      USER_ROLES.admin,
      USER_ROLES.manager,
      USER_ROLES.painter,
      USER_ROLES.preparer,
      USER_ROLES.qa,
      USER_ROLES.shipping,
    ],
  },
  loginEmployee: {
    path: 'login-employee',
    roles: [],
  },
  signOut: {
    path: 'sign-out',
    roles: [
      USER_ROLES.admin,
      USER_ROLES.manager,
      USER_ROLES.painter,
      USER_ROLES.preparer,
      USER_ROLES.qa,
      USER_ROLES.shipping,
      USER_ROLES.customer,
    ],
  },
  checkVersion: {
    path: 'check-version',
    roles: [],
  },
  treavelersDetails: {
    path: `parttypes/travelers/:id`,
    roles: [USER_ROLES.admin, USER_ROLES.manager],
  },
  createTraveler: {
    path: 'parttypes/travelers/new',
    roles: [USER_ROLES.admin, USER_ROLES.manager],
  },
  customerList: {
    path: 'customers',
    roles: [
      USER_ROLES.admin,
      USER_ROLES.manager,
      USER_ROLES.painter,
      USER_ROLES.preparer,
    ],
  },
  customerDetails: {
    path: 'customers/:id',
    roles: [
      USER_ROLES.admin,
      USER_ROLES.manager,
      USER_ROLES.painter,
      USER_ROLES.preparer,
    ],
  },
  createOrder: {
    path: 'orders/new',
    roles: [
      USER_ROLES.admin,
      USER_ROLES.manager,
      USER_ROLES.shipping,
      USER_ROLES.customer,
    ],
  },
  ordersDetails: {
    path: 'orders/:id',
    roles: [
      USER_ROLES.admin,
      USER_ROLES.manager,
      USER_ROLES.shipping,
      USER_ROLES.customer,
    ],
  },
  adminCustomers: {
    path: 'admin/customers',
    roles: [USER_ROLES.admin],
  },
  adminEmployees: {
    path: 'admin/employees',
    roles: [USER_ROLES.admin],
  },
  adminTravelerTemplates: {
    path: 'admin/travelerTemplates',
    roles: [USER_ROLES.admin, USER_ROLES.manager],
  },
  adminTravelerTemplateDetails: {
    path: 'admin/travelerTemplates/:id',
    roles: [USER_ROLES.admin, USER_ROLES.manager],
  },
  adminTravelerTemplateCreate: {
    path: 'admin/travelerTemplates/new',
    roles: [USER_ROLES.admin, USER_ROLES.manager],
  },
  adminControlValues: {
    path: 'admin/controlValues',
    roles: [USER_ROLES.admin, USER_ROLES.manager],
  },
  adminNCR: {
    path: 'admin/ncr',
    roles: [USER_ROLES.admin, USER_ROLES.manager],
  },
  adminReports: {
    path: 'admin/reports',
    roles: [USER_ROLES.admin, USER_ROLES.manager],
  },
  jobList: {
    path: 'jobs',
    roles: [
      USER_ROLES.admin,
      USER_ROLES.manager,
      USER_ROLES.painter,
      USER_ROLES.preparer,
      USER_ROLES.shipping,
      USER_ROLES.qa,
    ],
  },
  jobDetails: {
    path: 'jobs/progress',
    roles: [
      USER_ROLES.admin,
      USER_ROLES.manager,
      USER_ROLES.painter,
      USER_ROLES.preparer,
      USER_ROLES.shipping,
      USER_ROLES.qa,
    ],
  },
  jobDetailsBatch: {
    path: 'jobs/progress/batch',
    roles: [
      USER_ROLES.admin,
      USER_ROLES.manager,
      USER_ROLES.painter,
      USER_ROLES.preparer,
      USER_ROLES.shipping,
      USER_ROLES.qa,
    ],
  },
  jobDetailsNCR: {
    path: 'jobs/progress/ncr',
    roles: [USER_ROLES.qa],
  },
  customerListManager: {
    path: 'customersManager',
    roles: [USER_ROLES.manager, USER_ROLES.admin],
  },
  customerDetailsManager: {
    path: 'customersManager/:id',
    roles: [USER_ROLES.manager, USER_ROLES.admin],
  },
  customerDetailsManagerTypeRFQ: {
    path: 'customersManager/:id?type=rfq',
    roles: [USER_ROLES.manager, USER_ROLES.admin],
  },
  createPartTypes: {
    path: 'parttypes/new',
    roles: [
      USER_ROLES.manager,
      USER_ROLES.admin,
      USER_ROLES.shipping,
      USER_ROLES.customer,
    ],
  },
  partTypesDetails: {
    path: 'parttypes/:id',
    roles: [USER_ROLES.admin, USER_ROLES.manager, USER_ROLES.shipping],
  },
  partTypesListManager: {
    path: 'parttypesManager',
    roles: [USER_ROLES.manager, USER_ROLES.admin],
  },
  partTypesListManagerInitialPage: {
    path: 'parttypesManager?page=0',
    roles: [USER_ROLES.manager, USER_ROLES.admin],
  },

  // Manager Pages
  productionManager: {
    path: 'productionManager',
    roles: [USER_ROLES.admin, USER_ROLES.manager],
  },
  productionDetailsManager: {
    path: 'productionManager/:id',
    roles: [USER_ROLES.admin, USER_ROLES.manager],
  },

  // Customer Pages
  orderListCustomer: {
    path: 'ordersListCustomer',
    roles: [USER_ROLES.customer],
  },
  partTypesListCustomer: {
    path: 'parttypesCustomer',
    roles: [USER_ROLES.customer],
  },
  rfqListCustomer: {
    path: 'rfqsListCustomer',
    roles: [USER_ROLES.customer],
  },

  // Shipping Pages
  customerListShipping: {
    path: 'customersShipping',
    roles: [USER_ROLES.shipping],
  },
  customerDetailsShipping: {
    path: 'customersShipping/:id',
    roles: [USER_ROLES.shipping],
  },
  partTypesListShipping: {
    path: 'parttypesShipping',
    roles: [USER_ROLES.shipping],
  },
  orderDetailsHistory: {
    path: 'ordersListCustomer/history/:jobId',
    roles: [USER_ROLES.manager, USER_ROLES.admin, USER_ROLES.customer],
  },
  rfqDetails: {
    path: 'rfqs/:id',
    roles: [USER_ROLES.admin, USER_ROLES.manager, USER_ROLES.customer],
  },
  createRfq: {
    path: 'rfqs/new',
    roles: [USER_ROLES.manager, USER_ROLES.admin, USER_ROLES.customer],
  },
  // QA Pages
  jobDetailsQA: {
    path: 'jobsQA/progress',
    roles: [USER_ROLES.admin, USER_ROLES.manager, USER_ROLES.qa],
  },
  jobDetailsFinalQA: {
    path: 'jobsQA/finalQA/progress',
    roles: [USER_ROLES.admin, USER_ROLES.manager, USER_ROLES.qa],
  },
  emptyPage: {
    path: 'emptyPage',
    roles: [
      USER_ROLES.admin,
      USER_ROLES.manager,
      USER_ROLES.qa,
      USER_ROLES.shipping,
      USER_ROLES.customer,
      USER_ROLES.painter,
      USER_ROLES.preparer,
    ],
  },

  // QR code
  checkQrCode: {
    path: 'api/jobs/qr/:id',
    roles: [
      USER_ROLES.admin,
      USER_ROLES.manager,
      USER_ROLES.qa,
      USER_ROLES.shipping,
      USER_ROLES.customer,
      USER_ROLES.painter,
      USER_ROLES.preparer,
    ],
  },
};
