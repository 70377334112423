import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormControl, Button } from 'react-bootstrap';
import { MAX_LENGTH_INPUT, VARIANT_OUTLINE_UI, VARIANT_UI } from 'constants/ui';
import autosize from 'autosize';
import { v4 as uuidv4 } from 'uuid';
import { string, func, bool } from 'prop-types';
import st from './AdminTemplateInput.module.scss';

AdminTemplateInput.propTypes = {
  handleSubmit: func,
  initialValue: string,
  titleAcceptBtn: string,
  titleCancelBtn: string,
  isChangeButtons: bool,
};

function AdminTemplateInput({
  handleSubmit,
  initialValue = '',
  titleAcceptBtn = 'Accept',
  titleCancelBtn = 'Cancel',
  isChangeButtons = true,
}) {
  const [value, setValue] = useState(initialValue);
  const inputId = uuidv4();
  const textareaRef = useRef(null);

  const buttonValue = useMemo(() => {
    if (isChangeButtons) {
      return {
        title: value ? titleAcceptBtn : titleCancelBtn,
        variant: value ? VARIANT_UI.primary : VARIANT_OUTLINE_UI.primary,
      };
    }
    return {
      title: titleAcceptBtn,
      variant: VARIANT_UI.primary,
    };
  }, [value, titleAcceptBtn, titleCancelBtn, isChangeButtons]);

  const handleChange = (e) => {
    setValue(e.target.value || '');
  };

  const onSubmit = () => {
    handleSubmit(value);
  };

  useEffect(() => {
    if (inputId && textareaRef.current) {
      autosize(textareaRef.current);
    }
  }, [inputId]);

  return (
    <div className={st.container}>
      <FormControl
        value={value}
        onChange={handleChange}
        id={inputId}
        rows={1}
        as='textarea'
        ref={textareaRef}
        size='sm'
        maxLength={MAX_LENGTH_INPUT}
      />

      <Button
        variant={buttonValue.variant}
        className={st.buttonEditDone}
        onClick={onSubmit}
        size='sm'>
        {buttonValue.title}
      </Button>
    </div>
  );
}

export default AdminTemplateInput;
