import { createReducer } from '@reduxjs/toolkit';
import { PAGE_SIZE } from 'constants/ui';
import * as actions from './actions';

export const initialState = {
  rfqs: {
    data: {},
    sortIds: [],
    isLoading: false,
    isRemoving: false,
  },
  fetchParams: {
    pagination: {
      page: 0,
      size: PAGE_SIZE,
      count: 0,
    },
    search: '',
    isLoading: false,
  },
  files: {
    data: {},
    isLoading: false,
  },
  isAddingRFQ: {
    rfqId: null,
    isNewRFQ: false,
  },
  isScrollRFQ: false,
  scrollRFQId: '',
};

export const rfqsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.getRFQList.start, (state, action) => {
      const { params, showLoader = false, isClearPage } = action?.payload || {};

      return {
        ...state,
        rfqs: {
          ...state.rfqs,
          isLoading: !!showLoader,
        },

        fetchParams: {
          ...state.fetchParams,
          pagination: {
            ...state.fetchParams.pagination,
            page: isClearPage ? 0 : state.fetchParams.pagination.page,
          },
          isLoading: !!params?.filter,
        },
      };
    })
    .addCase(actions.getRFQList.success, (state, { payload }) => {
      const { page, count } = payload;

      return {
        ...state,
        rfqs: {
          ...state.rfqs,
          data: payload.fetchedValue,
          sortIds: payload.ids,
          isLoading: false,
        },
        fetchParams: {
          ...state.fetchParams,
          pagination: {
            ...state.fetchParams.pagination,
            page,
            count,
          },
          isLoading: false,
        },
      };
    })
    .addCase(actions.getRFQList.failure, (state, { payload }) => ({
      ...state,
      rfqs: {
        ...state.rfqs,
        isLoading: !!payload?.showLoader,
      },
      fetchParams: {
        ...state.fetchParams,
        isLoading: false,
      },
    }))
    .addCase(actions.createRFQ.start, (state) => ({
      ...state,
      rfqs: { ...state.rfqs, isLoading: true },
    }))
    .addCase(actions.createRFQ.success, (state, { payload }) => {
      const isAddingRFQId = payload;
      return {
        ...state,
        rfqs: {
          ...state.rfqs,
          isLoading: false,
        },
        isAddingRFQ: {
          ...state.isAddingRFQ,
          rfqId: isAddingRFQId,
        },
      };
    })
    .addCase(actions.createRFQ.failure, (state) => ({
      ...state,
      rfqs: { ...state.rfqs, isLoading: false },
    }))
    .addCase(actions.removeRFQ.start, (state) => ({
      ...state,
      rfqs: { ...state.rfqs, isRemoving: true },
    }))
    .addCase(actions.removeRFQ.success, (state) => {
      return {
        ...state,
        rfqs: {
          ...state.rfqs,
          isRemoving: false,
        },
      };
    })
    .addCase(actions.removeRFQ.failure, (state) => ({
      ...state,
      rfqs: { ...state.rfqs, isRemoving: false },
    }))
    .addCase(actions.changeFilterSearch, (state, action) => {
      const currentValue = action.payload;
      return {
        ...state,
        fetchParams: {
          ...state.fetchParams,
          pagination: {
            ...state.fetchParams.pagination,
          },
          search: currentValue,
        },
      };
    })
    .addCase(actions.changePage, (state, action) => {
      const page = action.payload;
      return {
        ...state,
        fetchParams: {
          ...state.fetchParams,
          pagination: {
            ...state.fetchParams.pagination,
            page,
          },
        },
      };
    })
    .addCase(actions.submitRFQ.start, (state) => ({
      ...state,
      rfqs: { ...state.rfqs, isLoading: true },
    }))
    .addCase(actions.submitRFQ.success, (state) => {
      return {
        ...state,
        rfqs: {
          ...state.rfqs,
          isLoading: false,
        },
        isAddingRFQ: {
          ...state.isAddingRFQ,
          isNewRFQ: true,
        },
      };
    })
    .addCase(actions.submitRFQ.failure, (state) => ({
      ...state,
      rfqs: { ...state.rfqs, isLoading: false },
    }))
    .addCase(actions.submitNewRFQ.start, (state) => ({
      ...state,
      rfqs: { ...state.rfqs, isLoading: true },
    }))
    .addCase(actions.submitNewRFQ.success, (state, action) => {
      const isAddingRFQId = action.payload;

      return {
        ...state,
        rfqs: {
          ...state.rfqs,
          isLoading: false,
        },
        isAddingRFQ: {
          ...state.isAddingRFQ,
          rfqId: isAddingRFQId,
          isNewRFQ: true,
        },
      };
    })
    .addCase(actions.submitNewRFQ.failure, (state) => ({
      ...state,
      rfqs: { ...state.rfqs, isLoading: false },
    }))
    .addCase(actions.getRFQ.start, (state) => ({
      ...state,
      rfqs: { ...state.rfqs, isLoading: true },
    }))
    .addCase(actions.getRFQ.success, (state, { payload }) => {
      return {
        ...state,
        rfqs: {
          ...state.rfqs,
          data: payload.data,
          sortIds: payload.sortIds,
          isLoading: false,
        },
      };
    })
    .addCase(actions.getRFQ.failure, (state) => ({
      ...state,
      rfqs: { ...state.rfqs, isLoading: false },
    }))
    .addCase(actions.updateRFQ.start, (state) => ({
      ...state,
      rfqs: { ...state.rfqs, isLoading: true },
    }))
    .addCase(actions.updateRFQ.success, (state, action) => {
      const { data } = action.payload;

      return {
        ...state,
        rfqs: {
          ...state.rfqs,
          data: { ...state.rfqs.data, ...data },
          isLoading: false,
        },
      };
    })
    .addCase(actions.updateRFQ.failure, (state) => ({
      ...state,
      rfqs: { ...state.rfqs, isLoading: false },
    }))
    .addCase(actions.addFile.start, (state) => ({
      ...state,
      files: { ...state.files, isLoading: true },
    }))
    .addCase(actions.addFile.success, (state) => {
      return {
        ...state,
        files: { ...state.files, isLoading: false },
      };
    })
    .addCase(actions.addFile.failure, (state) => ({
      ...state,
      files: { ...state.files, isLoading: false },
    }))
    .addCase(actions.getFiles.start, (state) => ({
      ...state,
      files: { ...state.files, isLoading: true },
    }))
    .addCase(actions.getFiles.success, (state, action) => {
      const { data } = action.payload;
      return {
        ...state,
        files: { ...state.files, data, isLoading: false },
      };
    })
    .addCase(actions.getFiles.failure, (state) => ({
      ...state,
      files: { ...state.files, isLoading: false },
    }))
    .addCase(actions.clearIsAddingRFQ, (state) => ({
      ...state,
      isAddingRFQ: {
        ...state.isAddingRFQ,
        rfqId: null,
        isNewRFQ: false,
      },
    }))
    .addCase(actions.addIsNewRFQ, (state) => ({
      ...state,
      isAddingRFQ: {
        ...state.isAddingRFQ,
        isNewRFQ: true,
      },
    }))
    .addCase(actions.removeFiles.start, (state) => ({
      ...state,
      files: { ...state.files, isLoading: true },
    }))
    .addCase(actions.removeFiles.success, (state) => {
      return {
        ...state,
        files: { ...state.files, isLoading: false },
      };
    })
    .addCase(actions.removeFiles.failure, (state) => ({
      ...state,
      files: { ...state.files, isLoading: false },
    }))
    .addCase(actions.changeIsScrollRFQ, (state, action) => {
      const value = action.payload || false;

      return {
        ...state,
        isScrollRFQ: value,
      };
    })
    .addCase(actions.changeScrollRFQId, (state, action) => {
      const value = action.payload || '';

      return {
        ...state,
        scrollRFQId: value,
      };
    });
});
