import React from 'react';
import { OverlayTrigger, Tooltip as TooltipBootstrap } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { node, string, bool, oneOfType, object } from 'prop-types';

Tooltip.propTypes = {
  placement: string,
  textTooltip: oneOfType([object, string]),
  isTooltip: bool,
  children: node,
};

function Tooltip({
  placement = 'top',
  textTooltip = '',
  isTooltip = false,
  children,
}) {
  if (!isTooltip) {
    return children;
  }

  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <TooltipBootstrap id={uuidv4()}>{textTooltip}</TooltipBootstrap>
      }>
      {children}
    </OverlayTrigger>
  );
}

export default Tooltip;
