import React, { useState } from 'react';
import Text from 'componentsShared/Text/Text';
import { CVIcon } from 'assets/icons';
import { v4 as uuidv4 } from 'uuid';
import cn from 'classnames';
import st from './JobsQAItemControlValues.module.scss';

function JobsQAItemControlValues({
  showTestCheck = true,
  controlValues,
  name,
}) {
  const [isOpen, setIsOpen] = useState(false);
  const controlValuesKeys = Object.keys(controlValues?.value || {});
  const controlValuesValues = Object.values(controlValues?.value || {});
  const checkText = controlValues?.informal ? 'Informal Check' : 'Formal Check';

  return (
    <div className={st.container}>
      <div className={st.containerInfo}>
        <div className={st.wrapperCVText}>
          <div className={st.holderIconCV}>
            <CVIcon />
          </div>

          <Text variant='normal'>
            {name} {showTestCheck ? `(${checkText})` : ''}
          </Text>
        </div>

        <div
          className={st.containerArrowIcon}
          onClick={() => setIsOpen((prev) => !prev)}>
          <Text variant='normal' className={st.controlShowCV}>
            {isOpen ? 'Hide details' : 'See details'}
          </Text>
        </div>
      </div>

      {isOpen && (
        <div className={st.containerControlValues}>
          {controlValuesKeys?.length > 0 && (
            <>
              <div className={st.listControlValues}>
                {controlValuesKeys.map((item, index) => {
                  return (
                    <div className={st.itemControlValuesWrapper} key={uuidv4()}>
                      <div className={st.itemControlValues}>
                        <Text
                          key={uuidv4()}
                          variant='normal'
                          className={st.itemControlValuesName}>
                          {item}
                        </Text>
                      </div>
                      <div
                        className={cn(
                          st.itemControlValues,
                          st.itemControlValuesNumber
                        )}>
                        <Text
                          key={uuidv4()}
                          variant='normal'
                          className={st.itemControlValuesNumberText}>
                          {controlValuesValues?.[index] || '-'}
                        </Text>
                      </div>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default JobsQAItemControlValues;
