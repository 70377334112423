import NavigationService from 'services/navigation/NavigationService';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { ROUTES } from 'router/routes';
import { getValidPath } from 'helpers/path';
import { useSelector } from 'react-redux';

export default function CustomRedirect() {
  const isAuthorized =
    useSelector((state) => state?.auth?.isAuthorized) ?? false;

  if (!isAuthorized) {
    return <Redirect to={getValidPath(ROUTES.signIn.path)} />;
  }
  const redirectPath = NavigationService.getDefaultPath();
  return <Redirect to={getValidPath(redirectPath)} />;
}
