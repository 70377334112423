import React, { useEffect, useState } from 'react';
import { CampaignIcon, EditIcon, EllipseIcon, TrashIcon } from 'assets/icons';
import Text from 'componentsShared/Text/Text';
import { FormControl, Button } from 'react-bootstrap';
import { bool, string, func, object } from 'prop-types';
import { MAX_LENGTH_INPUT } from 'constants/ui';
import cn from 'classnames';
import st from './CardNotes.module.scss';

CardNotes.propTypes = {
  title: object,
  text: string,
  isActive: bool,
  handleChangeNote: func,
  isFullWidth: bool,
  isShowPhase: bool,
  isShowCustomer: bool,
};

function CardNotes({
  title = {},
  text = '',
  isActive = false,
  handleChangeNote = () => {},
  isFullWidth = false,
  isShowPhase = true,
  isShowCustomer = false,
}) {
  const [isEdit, setIsEdit] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const handleChange = (e) => {
    const { value } = e.target.value;
    setInputValue(value);
  };

  const handleSave = () => {
    handleChangeNote(inputValue);
    setIsEdit(false);
  };

  useEffect(() => {
    setInputValue(text);
  }, [text]);

  return (
    <div
      className={cn(st.containerCard, {
        [st.containerCardActive]: isActive,
        [st.containerFullWidth]: isFullWidth,
      })}>
      <div className={st.cardHeaderWrapper}>
        <div className={st.cardHeaderTitleWrapper}>
          <Text variant='normal2' className={st.cardHeaderText}>
            <Text variant='normal2' className={st.cardHeaderText}>
              {title.user}
            </Text>{' '}
            {isShowPhase && (
              <>
                <EllipseIcon />{' '}
                <Text variant='normal2' className={st.cardHeaderText}>
                  {title.phase}
                </Text>{' '}
                <EllipseIcon />{' '}
              </>
            )}
            <Text variant='normal2' className={st.cardHeaderText}>
              {title.date}
            </Text>
          </Text>
        </div>
        {isActive && (
          <div className={st.cardHeaderActionWrapper}>
            {!isEdit && <EditIcon onClick={() => setIsEdit(true)} />}
            <TrashIcon />
          </div>
        )}
      </div>
      <div className={st.cardTextWrapper}>
        {isEdit ? (
          <div className={st.containerEditCard}>
            <FormControl
              value={inputValue}
              onChange={handleChange}
              size='sm'
              maxLength={MAX_LENGTH_INPUT}
            />
            <Button onClick={handleSave}>Save Changes</Button>
          </div>
        ) : (
          <Text variant='normal2'>
            {isShowCustomer && <CampaignIcon className={st.campaignIcon} />}
            {text}
          </Text>
        )}
      </div>
    </div>
  );
}

export default CardNotes;
