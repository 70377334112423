// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditCustomerForm_wrapperForm__1aZCr {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  grid-row-gap: 15px;\n  row-gap: 15px; }\n\n.EditCustomerForm_formGroup__1PnsK {\n  width: 100%; }\n  .EditCustomerForm_formGroup__1PnsK .form-control.is-invalid {\n    background-image: none; }\n\n.EditCustomerForm_buttonGroup__1-IvT {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  width: 100%;\n  grid-row-gap: 8px;\n  row-gap: 8px; }\n\n.EditCustomerForm_nameCompany__3kj6n {\n  font-size: 21px;\n  line-height: 25px; }\n\n.EditCustomerForm_holderStatus__2emqv {\n  display: flex;\n  justify-content: flex-start; }\n", "",{"version":3,"sources":["webpack://src/components/Form/EditCustomerForm/EditCustomerForm.module.scss"],"names":[],"mappings":"AAEA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,kBAAa;EAAb,aAAa,EAAA;;AAGf;EACE,WAAW,EAAA;EADb;IAIM,sBAAsB,EAAA;;AAK5B;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,WAAW;EACX,iBAAY;EAAZ,YAAY,EAAA;;AAGd;EACE,eAAe;EACf,iBAAiB,EAAA;;AAGnB;EACE,aAAa;EACb,2BAA2B,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.wrapperForm {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  row-gap: 15px;\n}\n\n.formGroup {\n  width: 100%;\n  :global {\n    .form-control.is-invalid {\n      background-image: none;\n    }\n  }\n}\n\n.buttonGroup {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  width: 100%;\n  row-gap: 8px;\n}\n\n.nameCompany {\n  font-size: 21px;\n  line-height: 25px;\n}\n\n.holderStatus {\n  display: flex;\n  justify-content: flex-start;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperForm": "EditCustomerForm_wrapperForm__1aZCr",
	"formGroup": "EditCustomerForm_formGroup__1PnsK",
	"buttonGroup": "EditCustomerForm_buttonGroup__1-IvT",
	"nameCompany": "EditCustomerForm_nameCompany__3kj6n",
	"holderStatus": "EditCustomerForm_holderStatus__2emqv"
};
export default ___CSS_LOADER_EXPORT___;
