import React, { useEffect, useRef } from 'react';
import { func, bool, string } from 'prop-types';
import { QrReader } from 'react-qr-reader';
import { TIMER_ERROR_QR_CODE } from 'constants/jobs';
import ReaderQRContentErrorOrLoader from 'componentsShared/ReaderQRContentErrorOrLoader/ReaderQRContentErrorOrLoader';
import cn from 'classnames';
import { FlipCameraIosIcon } from 'assets/icons';
import Text from 'componentsShared/Text/Text';
import st from './ReaderQRContent.module.scss';

ReaderQRContent.propTypes = {
  handleReadQR: func,
  handleError: func,
  isLoading: bool,
  isSuccess: bool,
  isError: bool,
  textLoading: string,
  textSuccess: string,
  textError: string,
  selectCamera: string,
  setSelectCamera: func,
};

function ReaderQRContent({
  handleReadQR = () => {},
  handleError = () => {},
  isLoading = false,
  isSuccess = false,
  isError = false,
  textLoading = 'Loading',
  textSuccess = 'Success',
  textError = 'Error',
  selectCamera = 'user',
  setSelectCamera = () => {},
}) {
  const onChangeCamera = () => {
    setSelectCamera((prev) => {
      if (prev === 'environment') {
        return 'user';
      }
      return 'environment';
    });
  };

  const first = useRef(false);

  const onReadQR = (result) => {
    if (!first.current) {
      first.current = true;
      return;
    }

    if (result) {
      const currentText = result?.text || '';
      handleReadQR(currentText);
    }
  };

  useEffect(() => {
    let timerError = 0;

    if (!isLoading || !isSuccess || !isError) {
      timerError = setTimeout(() => {
        handleError();
      }, TIMER_ERROR_QR_CODE);
    }

    return () => {
      clearTimeout(timerError);
    };
  }, [isLoading, isSuccess, isError, handleError]);

  if (isLoading || isSuccess || isError) {
    return (
      <ReaderQRContentErrorOrLoader
        isLoading={isLoading}
        isSuccess={isSuccess}
        isError={isError}
        textLoading={textLoading}
        textSuccess={textSuccess}
        textError={textError}
      />
    );
  }

  return (
    <div
      className={cn(st.bodyContent, st.backgroundBlack)}
      key={selectCamera}
      onClick={onChangeCamera}>
      {selectCamera === 'user' ? (
        <div className={st.wrapperQrReader}>
          <QrReader constraints={{ facingMode: 'user' }} onResult={onReadQR} />
        </div>
      ) : (
        <div className={st.wrapperQrReader}>
          <QrReader
            constraints={{ facingMode: 'environment' }}
            onResult={onReadQR}
          />
        </div>
      )}
      <div className={st.wrapperFrame}>
        <div className={st.holderFrame}>
          <div className={st.holderFrameTop}>
            <div className={st.holderFrameTopLeft} />
            <div className={st.holderFrameTopRight} />
          </div>

          <div className={st.holderFrameTop}>
            <div className={st.holderFrameBottomLeft} />
            <div className={st.holderFrameBottomRight} />
          </div>
        </div>
      </div>
      <div className={st.wrapperScanerLine}>
        <div className={st.scanerLine}> </div>
      </div>
      <div className={st.wrapperCameraHolder}>
        <div className={st.changeCameraHolder}>
          <FlipCameraIosIcon className={st.changeCamera} />
          <Text variant='normal' uppercase className={st.changeCameraText}>
            tap to flip the camera
          </Text>
        </div>
      </div>
    </div>
  );
}

export default ReaderQRContent;
