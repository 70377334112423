import React from 'react';
import DropdownButton from 'componentsShared/DropdownButton/DropdownButton';
import { useSelector } from 'react-redux';
import NavigationService from 'services/navigation/NavigationService';
import { ROUTES } from 'router/routes';
import { VARIANT_OUTLINE_UI } from 'constants/ui';
import Text from 'componentsShared/Text/Text';
import { PeopleCircleFillIcon } from 'assets/icons';
import st from './AdminUesrMenu.module.scss';

function AdminUesrMenu() {
  const handleLogout = () => {
    NavigationService.replacePath(ROUTES.signOut.path);
  };

  const userName = useSelector((state) => state?.auth?.user?.name) ?? '';

  const dropdownList = [
    {
      content: () => <Text variant='normal2'>Logout</Text>,
      onClick: handleLogout,
    },
  ];
  return (
    <DropdownButton
      startIcon={<PeopleCircleFillIcon />}
      title={userName}
      list={dropdownList}
      btnVariant={VARIANT_OUTLINE_UI.light}
      className={st.userMenu}
      classNameBtn={st.userMenuBtn}
    />
  );
}

export default AdminUesrMenu;
