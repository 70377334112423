import React from 'react';
import logo from 'assets/images/logo.svg';
import st from './HeaderLogo.module.scss';

function HeaderLogo() {
  return (
    <div>
      <img src={logo} alt='main-logo' className={st.logo} />
    </div>
  );
}

export default HeaderLogo;
