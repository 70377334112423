// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdminHeaderLayout_contaier__33Mwk {\n  width: 100%; }\n\n.AdminHeaderLayout_headerWrapper__Ulb9n {\n  display: flex;\n  flex-direction: column;\n  grid-row-gap: 24px;\n  row-gap: 24px;\n  padding: 24px; }\n\n.AdminHeaderLayout_headerTitle__1clp6 {\n  font-size: 20px; }\n\n.AdminHeaderLayout_headerContent__zTO6p {\n  display: flex;\n  align-items: center;\n  justify-content: space-between; }\n\n.AdminHeaderLayout_contentHolder__1_krZ {\n  width: 100%;\n  min-height: calc(100vh - 150px);\n  position: relative; }\n\n.AdminHeaderLayout_holderInputSearch__3ErD7 {\n  width: 550px;\n  display: flex;\n  justify-content: flex-end; }\n", "",{"version":3,"sources":["webpack://src/layouts/AdminHeaderLayout/AdminHeaderLayout.module.scss"],"names":[],"mappings":"AAEA;EACE,WAAW,EAAA;;AAGb;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAa;EAAb,aAAa;EACb,aAAa,EAAA;;AAGf;EACE,eAAe,EAAA;;AAGjB;EACE,aAAa;EACb,mBAAmB;EACnB,8BAA8B,EAAA;;AAGhC;EACE,WAAW;EACX,+BAA+B;EAC/B,kBAAkB,EAAA;;AAGpB;EACE,YAAY;EACZ,aAAa;EACb,yBAAyB,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.contaier {\n  width: 100%;\n}\n\n.headerWrapper {\n  display: flex;\n  flex-direction: column;\n  row-gap: 24px;\n  padding: 24px;\n}\n\n.headerTitle {\n  font-size: 20px;\n}\n\n.headerContent {\n  display: flex;\n  align-items: center;\n  justify-content: space-between;\n}\n\n.contentHolder {\n  width: 100%;\n  min-height: calc(100vh - 150px);\n  position: relative;\n}\n\n.holderInputSearch {\n  width: 550px;\n  display: flex;\n  justify-content: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contaier": "AdminHeaderLayout_contaier__33Mwk",
	"headerWrapper": "AdminHeaderLayout_headerWrapper__Ulb9n",
	"headerTitle": "AdminHeaderLayout_headerTitle__1clp6",
	"headerContent": "AdminHeaderLayout_headerContent__zTO6p",
	"contentHolder": "AdminHeaderLayout_contentHolder__1_krZ",
	"holderInputSearch": "AdminHeaderLayout_holderInputSearch__3ErD7"
};
export default ___CSS_LOADER_EXPORT___;
