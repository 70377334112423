import React, { useMemo } from 'react';
import * as Yup from 'yup';
import { Button } from 'react-bootstrap';
import Offcanvas from 'componentsShared/Offcanvas/Offcanvas';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { VARIANT_UI } from 'constants/ui';
import { object, func, bool } from 'prop-types';
import OffcanvasCompanyEditContent from 'components/Offcanvas/OffcanvasCompanyEditContent/OffcanvasCompanyEditContent';
import * as customersActions from 'store/customers/actions';
import st from './OffcanvasCompanyEdit.module.scss';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
});

OffcanvasCompanyEdit.propTypes = {
  showOffcanvas: bool,
  setShowOffcanvas: func,
  company: object,
  setSelectCompanyId: func,
};

function OffcanvasCompanyEdit({
  showOffcanvas = false,
  setShowOffcanvas = () => {},
  company,
  setSelectCompanyId = () => {},
}) {
  const dispatch = useDispatch();

  const initialValue = useMemo(
    () => ({
      name: company.name,
    }),
    [company]
  );

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: initialValue,
  };

  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors, isDirty } = formState;

  const onSubmit = (data, onClose) => {
    const validData = { data, id: company.id };
    dispatch(
      customersActions.changeCustomer.start({
        ...validData,
        onCloseModal: onClose,
      })
    );
  };

  return (
    <Offcanvas
      backdrop={false}
      onHide={() => {
        setShowOffcanvas(false);
        setSelectCompanyId(undefined);
      }}
      width={460}
      show={showOffcanvas}
      setShow={setShowOffcanvas}
      title='Edit Company'
      footerContent={({ onHide }) => (
        <div className={st.buttonGroup}>
          <Button
            variant={VARIANT_UI.primary}
            type='submit'
            disabled={!isDirty}
            onClick={handleSubmit((value) => {
              onSubmit(value, onHide);
            })}>
            Save Changes
          </Button>
        </div>
      )}>
      <OffcanvasCompanyEditContent
        onSubmit={handleSubmit(onSubmit)}
        register={register}
        errors={errors}
      />
    </Offcanvas>
  );
}

export default OffcanvasCompanyEdit;
