import React from 'react';
import { Button } from 'react-bootstrap';
import DropdownButton from 'componentsShared/DropdownButton/DropdownButton';
import { MoreVertIcon } from 'assets/icons';
import { VARIANT_OUTLINE_UI, VARIANT_UI } from 'constants/ui';
import HeaderContent from 'componentsShared/HeaderContent/HeaderContent';
import { bool, array, func, string } from 'prop-types';
import ButtonBack from 'componentsShared/ButtonBack/ButtonBack';
import AuthService from 'services/auth/AuthService';
import st from './OrderHeader.module.scss';

OrderHeader.propTypes = {
  onСlickBack: func,
  handleSubmitOrder: func,
  isLoading: bool,
  handleSaveOrder: func,
  dropdownList: array,
  title: string,
  isBurger: bool,
  isDisabledSubmit: bool,
  isDisabledSave: bool,
  isCheckModalBackBtn: bool,
  handleNavigateBack: func,
};

function OrderHeader({
  onСlickBack = () => {},
  handleSubmitOrder = () => {},
  isLoading = false,
  handleSaveOrder = () => {},
  dropdownList = [],
  title = 'PO',
  isBurger = false,
  isDisabledSubmit = false,
  isDisabledSave = false,
  isCheckModalBackBtn = false,
  handleNavigateBack = () => {},
}) {
  const isClient = AuthService.isClient();

  const titleSaveBtn = isClient ? 'Save' : 'Save Draft';
  const variantSaveBtn = isClient
    ? VARIANT_UI.primary
    : VARIANT_OUTLINE_UI.secondary;

  return (
    <HeaderContent
      title={title}
      backBtn={
        <ButtonBack
          onClick={onСlickBack}
          handleNavigateBack={handleNavigateBack}
          isCheckModal={isCheckModalBackBtn}
        />
      }
      isActive
      componentActive={() => (
        <div className={st.buttonGroup}>
          <>
            {!isClient && (
              <Button
                onClick={handleSubmitOrder}
                disabled={isLoading || isDisabledSubmit}>
                Submit
              </Button>
            )}
            <Button
              variant={variantSaveBtn}
              onClick={handleSaveOrder}
              disabled={isLoading || isDisabledSave}>
              {titleSaveBtn}
            </Button>
          </>
          {isBurger && (
            <DropdownButton
              list={dropdownList}
              classNameBtn={st.moreButton}
              startIcon={<MoreVertIcon />}
              btnVariant={VARIANT_OUTLINE_UI.secondary}
              arrowNone
              disabled={isLoading}
            />
          )}
        </div>
      )}
    />
  );
}

export default OrderHeader;
