import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { CropOriginalIcon, FileIcon, ClearIcon } from 'assets/icons';
import { object, func, bool, string } from 'prop-types';
import Text from 'componentsShared/Text/Text';
import ModalFilePreview from 'components/Modal/ModalFilePreview/ModalFilePreview';
import cn from 'classnames';
import { useDispatch } from 'react-redux';
import * as filesActions from 'store/files/actions';
import st from './FileItem.module.scss';

FileItem.propTypes = {
  data: object,
  handleRemoveFile: func,
  showClearIcon: bool,
  disableClearIcon: bool,
  classNameFileText: string,
  classNameHolderFileName: string,
  classNameHolderFile: string,
};

function FileItem({
  data = {},
  handleRemoveFile = () => {},
  showClearIcon = true,
  disableClearIcon = false,
  classNameFileText,
  classNameHolderFileName,
  classNameHolderFile,
  isShowPreview,
}) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const isTypeImage =
    data?.content_type?.includes('image') ||
    data?.type?.includes('image') ||
    false;
  const isTypePDF =
    data?.content_type?.includes('pdf') || data?.type?.includes('pdf') || false;
  const isShowFilePreview = isTypeImage || isTypePDF;

  const getIconFile = () => {
    if (isTypeImage) {
      return <CropOriginalIcon className={st.iconFile} />;
    }
    return <FileIcon className={st.iconFile} />;
  };

  const handleClickCard = () => {
    if (!isShowPreview) {
      return;
    }

    if (isShowFilePreview) {
      setShowModal(true);
      return;
    }

    dispatch(
      filesActions.getFile.start({
        path: data?.path,
        download: true,
        fileName: data.name,
      })
    );
  };

  const handleClickClearIcon = () => {
    if (!disableClearIcon) {
      handleRemoveFile(data.path);
    }
  };

  return (
    <div className={cn(st.holderFile, classNameHolderFile)} key={uuidv4()}>
      <div
        className={cn(st.holderFileName, classNameHolderFileName)}
        onClick={handleClickCard}>
        {getIconFile()}
        <Text variant='normal' className={cn(st.fileText, classNameFileText)}>
          {data.name}
        </Text>
      </div>
      {showClearIcon && (
        <div className={st.holderClear} onClick={handleClickClearIcon}>
          <ClearIcon />
        </div>
      )}
      {isShowPreview && (
        <ModalFilePreview
          showModal={showModal}
          setShowModal={setShowModal}
          data={data}
        />
      )}
    </div>
  );
}

export default FileItem;
