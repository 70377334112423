import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CenteredColumnLayout from 'layouts/CenteredColumnLayout/CenteredColumnLayout';
import Loader from 'componentsShared/Loader/Loader';
import HeaderContent from 'componentsShared/HeaderContent/HeaderContent';
import * as customersActions from 'store/customers/actions';
import * as partTypesActions from 'store/partTypes/actions';
import { Button } from 'react-bootstrap';
import PartsDetails from 'components/Parts/PartsDetails/PartsDetails';
import ButtonBack from 'componentsShared/ButtonBack/ButtonBack';
import AuthService from 'services/auth/AuthService';
import NavigationService from 'services/navigation/NavigationService';
import BeforeRedirect from 'componentsShared/BeforeRedirect/BeforeRedirect';
import { FILE_TYPES } from 'constants/files';
import st from './NewPartTypesPage.module.scss';

const NewPartTypesPage = () => {
  const dispatch = useDispatch();

  const currentCustomerId =
    useSelector((state) => state?.customers?.currentCustomerId) ?? null;
  const customerDataList =
    useSelector((state) => state?.customers?.customers?.data) ?? {};
  const customersIds =
    useSelector((state) => state?.customers?.customers.sortIds) ?? [];
  const currentCustomerName =
    useSelector(
      (state) => state?.customers?.customers?.data?.[currentCustomerId]?.name
    ) ?? '';
  const isLoading =
    useSelector((state) => state?.partTypes?.partTypes?.isLoading) ?? false;
  const isLoadingFile =
    useSelector((state) => state?.partTypes?.files?.isLoading) ?? false;

  const isCustomer = !!currentCustomerId;
  const isClient = AuthService.isClient();

  const title = useMemo(() => {
    if (isCustomer && !isClient) {
      return `${currentCustomerName}: New Part`;
    }
    return 'New Part';
  }, [isCustomer, currentCustomerName, isClient]);

  const getCustomerOptionList = () => {
    if (isCustomer) {
      return [
        {
          name: currentCustomerName,
          value: currentCustomerId,
        },
      ];
    }

    return customersIds.map((id) => ({
      name: customerDataList?.[id]?.name || '',
      value: customerDataList?.[id]?.id || '',
    }));
  };

  const customerOptionList = getCustomerOptionList();

  const [formValue, setFormValue] = useState({
    customer: currentCustomerId || '',
    name: '',
    number: '',
    revision: '',
    revision_notes: '',
  });

  const [formDirty, setFormDirty] = useState({
    customer: false,
    name: false,
    number: false,
    revision: false,
    revision_notes: false,
  });

  const [cacheClientFile, setCacheClientFile] = useState({
    [FILE_TYPES.cover]: [],
    [FILE_TYPES.specification]: [],
    [FILE_TYPES.drawing]: [],
  });

  const errors = useMemo(() => {
    const currentErrors = {};

    if (!formValue.customer) {
      currentErrors.customer = 'Required';
    }
    if (!formValue.name) {
      currentErrors.name = 'Required';
    }
    if (!formValue.number) {
      currentErrors.number = 'Required';
    }
    if (!formValue.revision) {
      currentErrors.revision = 'Required';
    }

    return currentErrors;
  }, [formValue]);

  const isDisabledSave = useMemo(() => {
    return Object.keys(errors).length > 0;
  }, [errors]);

  const handleSave = () => {
    const currentValue = { ...formValue, customer_id: formValue.customer };
    dispatch(
      partTypesActions.createPart.start({ part: currentValue, cacheClientFile })
    );
  };

  const handleBack = () => {
    return NavigationService.back();
  };

  const isCheckModalForButtonBack = useMemo(() => {
    return Object.values(formDirty).some((item) => item);
  }, [formDirty]);

  useEffect(() => {
    if (!isClient) {
      if (isCustomer && !currentCustomerName) {
        dispatch(customersActions.getCustomer.start(currentCustomerId));
      } else {
        dispatch(customersActions.getCustomerList.start());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentCustomerId, isCustomer]);

  useEffect(() => {
    setCacheClientFile({
      [FILE_TYPES.cover]: [],
      [FILE_TYPES.specification]: [],
      [FILE_TYPES.drawing]: [],
    });
  }, []);

  return (
    <CenteredColumnLayout>
      <HeaderContent
        title={title}
        backBtn={
          <ButtonBack
            isCheckModal={isCheckModalForButtonBack}
            onClick={handleBack}
          />
        }
        isActive
        componentActive={() => (
          <div className={st.buttonGroup}>
            <Button onClick={handleSave} disabled={isDisabledSave}>
              Save
            </Button>
          </div>
        )}
      />
      <div className={st.mainContentHolder}>
        <PartsDetails
          formValue={formValue}
          setFormValue={setFormValue}
          errors={errors}
          formDirty={formDirty}
          setFormDirty={setFormDirty}
          customerOptionList={customerOptionList}
          isNewPart
          isCustomer={isCustomer}
          cacheClientFile={cacheClientFile}
          setCacheClientFile={setCacheClientFile}
        />

        <Loader isVisible={isLoading || isLoadingFile} isBackgroundColorGray />
      </div>
      <BeforeRedirect
        when={isCheckModalForButtonBack && !isLoading && !isLoadingFile}
      />
    </CenteredColumnLayout>
  );
};

export default NewPartTypesPage;
