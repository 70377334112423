export const TRAVELER_STATUS = {
  DRAFT: 'DRAFT',
  ACTUAL: 'ACTUAL',
};

export const DROPPABLE_DELETE_ID = {
  operation: 'OPERATIONS_DELETE',
  file: 'FILES_DELETE',
  requireFile: 'REQUIRE_FILE',
};

export const DND_TYPE = {
  operation: 'OPERATIONS',
  file: 'FILES',
  step: 'STEPS',
  controlValues: 'CONTROL_VALUES',
  requireFile: 'REQUIRE_FILE',
};

export const LABEL_CONTROL_VALUES = 'Check and provide Control Values for:';

export const ARRAY_KEY_HIDE_CONTROL_VALUES = [];

export const ARRAY_KEY_PAINTER_CONTROL_VALUES = ['primer', 'paint'];

export const FIRST_PHASE_KEY = 'receiving';
