import React, { useMemo, useState } from 'react';
import { Pagination } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import st from './ModalFilePreviewPagination.module.scss';

function ModalFilePreviewPagination({
  pageNumber,
  numPages,
  setPageNumber = () => {},
}) {
  const [isEllipsis, setIsEllipsis] = useState({
    start: false,
    end: false,
  });
  const isEmptyPage = numPages === 0;

  const pages = useMemo(() => {
    if (isEmptyPage) {
      setIsEllipsis({ start: false, end: false });
      return [1];
    }
    if (numPages <= 9) {
      setIsEllipsis({ start: false, end: false });
      return Array(numPages)
        .fill()
        .map((x, i) => i + 1);
    }
    const arrayPages = [
      1,
      pageNumber - 2,
      pageNumber - 1,
      pageNumber,
      pageNumber + 1 > numPages ? -1 : pageNumber + 1,
      pageNumber + 2 > numPages ? -1 : pageNumber + 2,
      numPages,
    ].filter((item) => item > 0);

    const uniquePages = [...new Set(arrayPages)];
    setIsEllipsis({
      start: uniquePages[1] !== 2,
      end:
        uniquePages[uniquePages.length - 1] - 1 !==
        uniquePages[uniquePages.length - 2],
    });

    return uniquePages;
  }, [pageNumber, numPages, isEmptyPage]);

  const disabledStartArrow = useMemo(() => {
    return pageNumber === 1;
  }, [pageNumber]);

  const disabledEndArrow = useMemo(() => {
    return pageNumber === numPages || isEmptyPage;
  }, [pageNumber, numPages, isEmptyPage]);

  const onChangePages = (value) => {
    if (value !== pageNumber) {
      setPageNumber(value);
    }
    return null;
  };

  return (
    <div className={st.pdfPagination}>
      <Pagination>
        <Pagination.Prev
          disabled={disabledStartArrow}
          onClick={() => onChangePages(pageNumber - 1)}
        />

        {pages.map((item, index) => {
          const currentKey = uuidv4();

          return (
            <React.Fragment key={currentKey}>
              {index === pages.length - 1 && isEllipsis.end && (
                <Pagination.Ellipsis disabled />
              )}
              <Pagination.Item
                disabled={isEmptyPage}
                active={pageNumber === item}
                onClick={() => onChangePages(item)}>
                {item}
              </Pagination.Item>
              {index === 0 && isEllipsis.start && (
                <Pagination.Ellipsis disabled />
              )}
            </React.Fragment>
          );
        })}
        <Pagination.Next
          disabled={disabledEndArrow}
          onClick={() => onChangePages(pageNumber + 1)}
        />
      </Pagination>
    </div>
  );
}

export default ModalFilePreviewPagination;
