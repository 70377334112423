import React from 'react';
import EditCustomerForm from 'components/Form/EditCustomerForm/EditCustomerForm';
import { object, func, bool, string } from 'prop-types';
import st from './OffcanvasCustomerEditContent.module.scss';

OffcanvasCustomerEditContent.propTypes = {
  customer: object,
  onClose: func,
  onSubmit: func,
  register: func,
  errors: object,
  initialValue: object,
  isDisabledInvite: bool,
  setEmailValue: func,
  isChangedEmail: bool,
  handleResendInviteWithSaving: func,
  isCreate: bool,
  statusBlocked: bool,
  companyName: string,
};

function OffcanvasCustomerEditContent({
  companyName,
  customer,
  onClose = () => {},
  onSubmit = () => {},
  register = () => {},
  errors,
  initialValue,
  isDisabledInvite,
  setEmailValue = () => {},
  setNameValue = () => {},
  isChangedEmail,
  handleResendInviteWithSaving = () => {},
  isCreate = false,
  statusBlocked = false,
}) {
  return (
    <div className={st.container}>
      <div>
        <EditCustomerForm
          companyName={companyName}
          initialValue={initialValue}
          customerId={customer?.id}
          customer={customer}
          onClose={onClose}
          onSubmit={onSubmit}
          register={register}
          errors={errors}
          setEmailValue={setEmailValue}
          setNameValue={setNameValue}
          isDisabledInvite={isDisabledInvite}
          isChangedEmail={isChangedEmail}
          handleResendInviteWithSaving={handleResendInviteWithSaving}
          isCreate={isCreate}
          statusBlocked={statusBlocked}
        />
      </div>
    </div>
  );
}

export default OffcanvasCustomerEditContent;
