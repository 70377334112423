import React from 'react';
import TravalerTemplateBreadCrumbs from '../TravalerTemplateBreadCrumbs/TravalerTemplateBreadCrumbs';
import TemplateHeaderDetails from '../TemplateHeaderDetails/TemplateHeaderDetails';
import st from './TemplateWrapperHeaderDetails.module.scss';

function TemplateWrapperHeaderDetails({
  isEditTemplate = false,
  phaseList = [],
  template,
  handleToggleEditTemplate = () => {},
  handleChangeFormName = () => {},
  formNameValue = '',
  handleSaveTemplate = () => {},
  activePhase = '',
  setAreScrollToPhase = () => {},
  isNew = false,
  isDisabledSave = false,
}) {
  return (
    <div className={st.headerHolder}>
      <TemplateHeaderDetails
        isEditTemplate={isEditTemplate}
        template={template}
        handleToggleEditTemplate={handleToggleEditTemplate}
        handleChangeFormName={handleChangeFormName}
        formNameValue={formNameValue}
        handleSaveTemplate={handleSaveTemplate}
        isNew={isNew}
        isDisabledSave={isDisabledSave}
      />

      <TravalerTemplateBreadCrumbs
        phaseList={phaseList}
        isEditTemplate={isEditTemplate}
        activePhase={activePhase}
        setAreScrollToPhase={setAreScrollToPhase}
      />
    </div>
  );
}

export default TemplateWrapperHeaderDetails;
