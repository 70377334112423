import React from 'react';
import { func, object } from 'prop-types';
import { Form, FormControl } from 'react-bootstrap';
import RequiredLabel from 'componentsShared/RequiredLabel/RequiredLabel';
import { MAX_LENGTH_INPUT } from 'constants/ui';
import st from './OffcanvasEditNCR.module.scss';

OffcanvasEditNCR.propTypes = {
  register: func,
  errors: object,
};

function OffcanvasEditNCR({ register, errors }) {
  return (
    <div>
      <Form className={st.wrapperForm} onSubmit={(e) => e.preventDefault()}>
        <div className={st.formGroup}>
          <Form.Label>
            <RequiredLabel />
            Field Label
          </Form.Label>
          <FormControl
            {...register('label')}
            isInvalid={errors.name}
            maxLength={MAX_LENGTH_INPUT}
          />
        </div>
      </Form>
    </div>
  );
}

export default OffcanvasEditNCR;
