import React from 'react';
import Text from 'componentsShared/Text/Text';
import { object } from 'prop-types';
import st from './ModalJobSummaryBody.module.scss';

ModalJobSummaryBody.propTypes = {
  partDateils: object,
};

function ModalJobSummaryBody({ partDateils = {} }) {
  const jobSummaryInfoList = [
    { label: 'Customer', value: partDateils?.customerName },
    { label: 'Part Type Name', value: partDateils?.partTypeName },
    { label: 'Part Number', value: partDateils?.partTypeNumber },
    { label: 'Traveler Revision', value: partDateils?.partTypeRevision },
    { label: 'Revision Notes', value: partDateils?.partTypeRevisionNotes },
  ];

  const filterJobSummaryInfoList = jobSummaryInfoList.filter(
    ({ value }) => !!value
  );

  const renderPartDetailInfo = () => {
    return filterJobSummaryInfoList.map(({ label, value }) => {
      return (
        <div className={st.holderItemInfo} key={`item-info-${label}`}>
          <div className={st.holderInfoLabel}>
            <Text variant='normal' className={st.infoLabel}>
              {label}:
            </Text>
          </div>
          <div>
            <Text variant='normal' className={st.infoValue}>
              {value}
            </Text>
          </div>
        </div>
      );
    });
  };

  return <div className={st.wrapperModalContent}>{renderPartDetailInfo()}</div>;
}

export default ModalJobSummaryBody;
