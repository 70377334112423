// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalButton_modalBody__3Pxoy {\n  overflow-y: auto; }\n\n.ModalButton_modalContainer__1xS4O .modal-footer {\n  display: flex;\n  justify-content: space-between;\n  align-items: center; }\n\n.ModalButton_buttonBottomGroupRigth__LARhG {\n  display: flex;\n  grid-column-gap: 16px;\n  column-gap: 16px; }\n\n.ModalButton_buttonBottomLeft__3Vxqw {\n  display: flex;\n  align-items: center;\n  grid-column-gap: 16px;\n  column-gap: 16px; }\n", "",{"version":3,"sources":["webpack://src/componentsShared/ModalButton/ModalButton.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB,EAAA;;AAGlB;EAGM,aAAa;EACb,8BAA8B;EAC9B,mBAAmB,EAAA;;AAKzB;EACE,aAAa;EACb,qBAAgB;EAAhB,gBAAgB,EAAA;;AAGlB;EACE,aAAa;EACb,mBAAmB;EACnB,qBAAgB;EAAhB,gBAAgB,EAAA","sourcesContent":[".modalBody {\n  overflow-y: auto;\n}\n\n.modalContainer {\n  :global {\n    .modal-footer {\n      display: flex;\n      justify-content: space-between;\n      align-items: center;\n    }\n  }\n}\n\n.buttonBottomGroupRigth {\n  display: flex;\n  column-gap: 16px;\n}\n\n.buttonBottomLeft {\n  display: flex;\n  align-items: center;\n  column-gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBody": "ModalButton_modalBody__3Pxoy",
	"modalContainer": "ModalButton_modalContainer__1xS4O",
	"buttonBottomGroupRigth": "ModalButton_buttonBottomGroupRigth__LARhG",
	"buttonBottomLeft": "ModalButton_buttonBottomLeft__3Vxqw"
};
export default ___CSS_LOADER_EXPORT___;
