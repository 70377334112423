import React from 'react';
import Text from 'componentsShared/Text/Text';
import { Form } from 'react-bootstrap';
import { bool, func, array } from 'prop-types';
import cn from 'classnames';
import st from './JobsPartsToReject.module.scss';

JobsPartsToReject.propTypes = {
  selectRejectJob: array,
  setSelectRejectJob: func,
  jobIdList: array,
  error: bool,
  setError: func,
};

function JobsPartsToReject({
  selectRejectJob = [],
  setSelectRejectJob = () => {},
  jobIdList = [],
  error = false,
  setError = () => {},
}) {
  const isSelectAll = selectRejectJob.length === jobIdList.length;

  const handleChangeCheckbox = (jobId) => {
    setError(false);
    setSelectRejectJob((prev) => {
      if (selectRejectJob.includes(jobId)) {
        return prev.filter((item) => jobId.toString() !== item.toString());
      }
      return [...prev, jobId];
    });
  };

  const renderJobList = (jobList) => {
    return jobList.map((item) => (
      <div className={st.holderJobItem} key={item}>
        <div className={st.holderJobCheck}>
          <Form.Check
            checked={selectRejectJob.includes(item)}
            onChange={() => handleChangeCheckbox(item)}
          />
          <Text variant='normal' className={cn(st.checkLabel)}>
            #{item}
          </Text>
        </div>
      </div>
    ));
  };

  const handleMassSelection = () => {
    setError(false);
    const jobIds = isSelectAll ? [] : [...jobIdList];
    setSelectRejectJob(jobIds);
  };

  return (
    <div className={st.container} id='partsToReject'>
      <div className={st.holderTitle}>
        <Text variant='normal' bold className={st.title}>
          Parts to reject
        </Text>
        <div className={st.selectAllButton} onClick={handleMassSelection}>
          <Text className={st.selectAllButtonText} variant='normal'>
            {isSelectAll ? 'Deselect All' : 'Select All'}
          </Text>
        </div>
      </div>
      {error && (
        <div className={st.holderErrorText}>
          <Text variant='normal2' className={st.errorText}>
            Specify Parts for rejecting
          </Text>
        </div>
      )}
      <div className={st.holderJobList}>{renderJobList(jobIdList)}</div>
      {error && (
        <div>
          <Text variant='normal2' className={st.errorText}>
            Specify Parts for rejecting
          </Text>
        </div>
      )}
    </div>
  );
}

export default JobsPartsToReject;
