// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditCompanyForm_wrapperForm__2FjfR {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  grid-row-gap: 15px;\n  row-gap: 15px; }\n\n.EditCompanyForm_formGroup__L8TGh {\n  width: 100%; }\n  .EditCompanyForm_formGroup__L8TGh .form-control.is-invalid {\n    background-image: none; }\n", "",{"version":3,"sources":["webpack://src/components/Form/EditCompanyForm/EditCompanyForm.module.scss"],"names":[],"mappings":"AAEA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,kBAAa;EAAb,aAAa,EAAA;;AAGf;EACE,WAAW,EAAA;EADb;IAIM,sBAAsB,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.wrapperForm {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  row-gap: 15px;\n}\n\n.formGroup {\n  width: 100%;\n  :global {\n    .form-control.is-invalid {\n      background-image: none;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperForm": "EditCompanyForm_wrapperForm__2FjfR",
	"formGroup": "EditCompanyForm_formGroup__L8TGh"
};
export default ___CSS_LOADER_EXPORT___;
