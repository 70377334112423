import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as worklogsActions from 'store/worklogs/actions';
import * as jobsActions from 'store/jobs/actions';
import * as customersActions from 'store/customers/actions';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useParams } from 'react-router';
import HeaderContent from 'componentsShared/HeaderContent/HeaderContent';
import Modal from 'componentsShared/Modal/Modal';
import Loader from 'componentsShared/Loader/Loader';
import CenteredColumnLayout from 'layouts/CenteredColumnLayout/CenteredColumnLayout';
import WorklogList from 'components/Worklog/WorklogList/WorklogList';
import { Button } from 'react-bootstrap';
import { VARIANT_OUTLINE_UI } from 'constants/ui';
import { JOBS_STATUS } from 'constants/jobs';
import DropdownButton from 'componentsShared/DropdownButton/DropdownButton';
import ButtonNotesWrapper from 'components/Notes/ButtonNotesWrapper/ButtonNotesWrapper';
import { MoreVertIcon } from 'assets/icons';
import ModalMoveJobStep from 'components/Modal/ModalMoveJobStep/ModalMoveJobStep';
import Text from 'componentsShared/Text/Text';
import ButtonBack from 'componentsShared/ButtonBack/ButtonBack';
import NavigationService from 'services/navigation/NavigationService';
import * as ordersActions from 'store/orders/actions';
import st from './ProductionDetailsManagerPage.module.scss';

function ProductionDetailsManagerPage() {
  const dispatch = useDispatch();
  const { id: jobId } = useParams();

  const [showModalDone, setShowModalDone] = useState(false);

  const isLoadingWorklogs =
    useSelector((state) => state?.worklogs?.worklogs?.isLoading) ?? false;
  const isLoadingJobs =
    useSelector((state) => state?.jobs?.jobs?.isLoading) ?? false;
  const currentWorklog =
    useSelector((state) => state?.worklogs?.worklogs?.data?.[jobId]) ?? false;
  const job = useSelector((state) => state?.jobs?.jobs?.data?.[jobId]) ?? {};
  const customerName =
    useSelector(
      (state) =>
        state?.customers?.customers?.data?.[job?.part_type?.customer_id]?.name
    ) ?? '';
  const isLoadingJobQR =
    useSelector((state) => state?.jobs?.jobsQr?.isLoading) ?? false;

  const customerId = job?.part_type?.customer_id;

  const isJobDone = job?.status === JOBS_STATUS.done;
  const isLoading = isLoadingWorklogs || isLoadingJobs;

  const handleCancelJob = () => {
    dispatch(
      jobsActions.cancelJob.start({
        ids: [jobId],
      })
    );
    dispatch(ordersActions.changeIsScrollJob(true));
  };

  const showQR = () => {
    if (jobId) {
      dispatch(jobsActions.getJobQr.start(jobId));
    }
  };

  const showAllQR = () => {
    const partTypeId = job?.part_type?.id;
    const purchaseOrderId = job?.purchase_order_id;

    if (partTypeId) {
      const dataQuery = {
        partTypeId,
        purchaseOrderId,
      };
      dispatch(jobsActions.getJobSeriesQr.start(dataQuery));
    }
  };

  const dropdownListHeader = useMemo(() => {
    const itemQrCode = isJobDone
      ? []
      : [
          {
            content: () => <Text variant='normal'>Print Job ID label</Text>,
            onClick: showQR,
            disabled: isLoadingJobQR,
          },
          {
            content: () => (
              <Text variant='normal'>Print Part Type ID labels...</Text>
            ),
            onClick: showAllQR,
            disabled: isLoadingJobQR,
          },
          { isDivider: true },
        ];

    return [
      ...itemQrCode,
      {
        content: () => (
          <Text variant='normal' className={st.textRemove}>
            Cancel Job
          </Text>
        ),
        onClick: isJobDone
          ? () => setShowModalDone(true)
          : () => handleCancelJob(),
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isJobDone, isLoadingJobQR, job]);

  const loadWorklogList = () => {
    dispatch(worklogsActions.getWorklog.start(jobId));
  };

  const handleNavigateBack = () => {
    NavigationService.back(true);
    dispatch(ordersActions.changeIsScrollJob(true));
  };

  useEffect(() => {
    loadWorklogList();
    dispatch(jobsActions.getJob.start({ id: jobId }));
    dispatch(jobsActions.getFileInterprocessQA.start({ id: jobId }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId, dispatch]);

  useEffect(() => {
    if (customerId && !customerName) {
      dispatch(customersActions.getCustomer.start(customerId));
    }
  }, [dispatch, customerName, customerId]);

  return (
    <CenteredColumnLayout
      classNamePageContentHolder={st.classNamePageContentHolder}
      classNamePageWrapper={st.classNamePageWrapper}>
      <div className={st.headerContainer}>
        <HeaderContent
          isActive
          title='Production'
          className={st.holderHeader}
          backBtn={
            <ButtonBack isCheckModal={false} onClick={handleNavigateBack} />
          }
          componentActive={() => (
            <div className={st.containerHeaderActiveContent}>
              <ButtonNotesWrapper
                jobId={jobId}
                title={`Notes for ${customerName}: ${job?.part_type?.name}`}
              />
              <ModalMoveJobStep
                jobId={jobId}
                isJobDone={isJobDone}
                setShowModalDone={setShowModalDone}
              />
              <DropdownButton
                list={dropdownListHeader}
                className={st.containerMoreButton}
                classNameBtn={st.moreButton}
                startIcon={<MoreVertIcon />}
                btnVariant={VARIANT_OUTLINE_UI.secondary}
                arrowNone
                disabled={isLoading}
              />
            </div>
          )}
        />
      </div>
      <div className={st.containerList}>
        <div className={st.contentList}>
          <WorklogList worklog={currentWorklog} />
          <Loader isVisible={isLoading} />
        </div>
      </div>

      <Modal
        show={showModalDone}
        setShow={setShowModalDone}
        title='The job is finished'
        buttonBottomRight={({ onClose }) => (
          <Button onClick={onClose} variant={VARIANT_OUTLINE_UI.secondary}>
            Close
          </Button>
        )}>
        <div>
          <Text variant='normal'>
            The job is already finished. Can not proceed operation.
          </Text>
        </div>
      </Modal>
    </CenteredColumnLayout>
  );
}

export default ProductionDetailsManagerPage;
