import { createAction } from '@reduxjs/toolkit';
import { getRequestAction } from 'helpers/functions';

const ACTION_PREFIX = 'jobs';

export const getJob = getRequestAction(`${ACTION_PREFIX}/getJob`);

export const submitJob = getRequestAction(`${ACTION_PREFIX}/submitJob`);

export const takeJob = getRequestAction(`${ACTION_PREFIX}/takeJob`);

export const rejectJob = getRequestAction(`${ACTION_PREFIX}/rejectJob`);

export const changeFilterSearch = createAction(
  `${ACTION_PREFIX}/changeFilterSearch`
);

export const approveJobQA = getRequestAction(`${ACTION_PREFIX}/approveJobQA`);

export const takeJobQA = getRequestAction(`${ACTION_PREFIX}/takeJobQA`);

export const cancelJob = getRequestAction(`${ACTION_PREFIX}/cancelJob`);

export const addFileInterprocessQA = getRequestAction(
  `${ACTION_PREFIX}/addFileInterprocessQA`
);

export const getFileInterprocessQA = getRequestAction(
  `${ACTION_PREFIX}/getFileInterprocessQA`
);

export const getJobOperationFile = getRequestAction(
  `${ACTION_PREFIX}/getJobOperationFile`
);

export const removeFileInterprocessQA = getRequestAction(
  `${ACTION_PREFIX}/removeFileInterprocessQA`
);

export const getJobList = getRequestAction(`${ACTION_PREFIX}/getJobList`);

export const moveJobToStep = getRequestAction(`${ACTION_PREFIX}/moveJobToStep`);

export const checkJob = getRequestAction(`${ACTION_PREFIX}/checkJob`);

export const clearErrorCheckJob = createAction(
  `${ACTION_PREFIX}/clearErrorCheckJob`
);

export const clearJobIdCheckJob = createAction(
  `${ACTION_PREFIX}/clearJobIdCheckJob`
);

export const getJobQr = getRequestAction(`${ACTION_PREFIX}/getJobQr`);

export const getJobSeriesQr = getRequestAction(
  `${ACTION_PREFIX}/getJobSeriesQr`
);

export const getJobSummary = getRequestAction(`${ACTION_PREFIX}/getJobSummary`);

export const submitJobNCR = getRequestAction(`${ACTION_PREFIX}/submitJobNCR`);

export const changeJobIdPhone = createAction(
  `${ACTION_PREFIX}/changeJobIdPhone`
);

export const getReasonRejectJob = getRequestAction(
  `${ACTION_PREFIX}/getReasonRejectJob`
);

export const changeIsCreateBatch = createAction(
  `${ACTION_PREFIX}/changeIsCreateBatch`
);

export const changeSelectJobIdList = createAction(
  `${ACTION_PREFIX}/changeSelectJobIdList`
);

export const clearSelectJobIdList = createAction(
  `${ACTION_PREFIX}/clearSelectJobIdList`
);

export const changeShowButtonBatch = createAction(
  `${ACTION_PREFIX}/changeShowButtonBatch`
);

export const clearBatchData = createAction(`${ACTION_PREFIX}/clearBatchData`);

export const takeBatchJob = getRequestAction(`${ACTION_PREFIX}/takeBatchJob`);

export const getBatchJob = getRequestAction(`${ACTION_PREFIX}/getBatchJob`);

export const submitBatchJob = getRequestAction(
  `${ACTION_PREFIX}/submitBatchJob`
);

export const clearJobFileList = createAction(
  `${ACTION_PREFIX}/clearJobFileList`
);

export const clearLoadFileName = createAction(
  `${ACTION_PREFIX}/clearLoadFileName`
);

export const changeIsScrollJob = createAction(
  `${ACTION_PREFIX}/changeIsScrollJob`
);

export const changeScrollJobId = createAction(
  `${ACTION_PREFIX}/changeScrollJobId`
);
