import React from 'react';
import { MinusCircleIcon } from 'assets/icons';
import Text from 'componentsShared/Text/Text';
import { VARIANT_UI } from 'constants/ui';
import ModalButton from 'componentsShared/ModalButton/ModalButton';
import st from './TemplateDeletePhaseButton.module.scss';

function TemplateDeletePhaseButton({
  isEditTemplate,
  defaultPhase,
  handleDeletePhase = () => {},
  phaseName = '',
  index,
}) {
  if (!isEditTemplate || defaultPhase) {
    return null;
  }

  const onDeletePhase = () => {
    handleDeletePhase(index);
  };

  return (
    <ModalButton
      title='Remove the phase from the Workflow'
      titleAcceptBtn='Remove'
      onAcceptBtn={onDeletePhase}
      variantAcceptBtn={VARIANT_UI.danger}
      bodyContent={() => (
        <div className={st.wrapperContent}>
          <Text variant='normal'>
            You are about to remove the Phase ‘{phaseName}’ from the Traveler
            Template.
          </Text>
          <br />
          <br />
          <Text variant='normal'>
            This removes also the phase’s steps and can not be undone.
          </Text>
        </div>
      )}
      buttonRenderer={({ onClick: openModal }) => (
        <MinusCircleIcon className={st.deletePhase} onClick={openModal} />
      )}
    />
  );
}

export default TemplateDeletePhaseButton;
