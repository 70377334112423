import React from 'react';
import { Form, FormControl } from 'react-bootstrap';
import { object, func, bool } from 'prop-types';
import { scoringMatrixData } from 'constants/order';
import { moreOneZeroBeginning } from 'constants/regulars';
import { FunctionsIcon } from 'assets/icons';
import Text from 'componentsShared/Text/Text';
import st from './OrderScoreMatrix.module.scss';

OrderScoreMatrix.propTypes = {
  scoreData: object,
  scoreValue: object,
  handleChangeScore: func,
  errorScore: object,
  isShowError: bool,
};

function OrderScoreMatrix({
  scoreData = {},
  scoreValue = {},
  handleChangeScore = () => {},
  finalScore = 0,
  errorScore = {},
  isShowError = false,
}) {
  const scoringMatrixDataArray = Object.values(scoringMatrixData);
  const scoreDataArray = Object.values(scoreData);

  return (
    <>
      <div className={st.containerFormGroupList}>
        {scoringMatrixDataArray.map((item) => {
          const { label: scoreItemName, icon } = item;
          const currentScoreData =
            scoreDataArray.find((scoreDataItem) => {
              return (
                scoreDataItem?.name.toString() === scoreItemName.toString()
              );
            }) || {};

          const { min_value: minValue, max_value: maxValue } = currentScoreData;
          const currentValue = scoreValue[scoreItemName];
          const isError = errorScore?.[scoreItemName] || '';
          const isInvalid = isShowError && isError;
          const validValue =
            typeof currentValue === 'number' || typeof currentValue === 'string'
              ? currentValue.toString().replace(moreOneZeroBeginning, '')
              : '';

          return (
            <div className={st.formGroup} key={scoreItemName}>
              <div className={st.labelWrapper}>
                <Form.Label className={st.formGroupLabel}>
                  {scoreItemName}
                </Form.Label>
                <div>{icon}</div>
              </div>

              <div className={st.formControlHolder}>
                <FormControl
                  type='number'
                  value={validValue}
                  min={minValue}
                  max={maxValue}
                  isInvalid={isInvalid}
                  onChange={(e) =>
                    handleChangeScore(scoreItemName, +e.target.value)
                  }
                />
              </div>
            </div>
          );
        })}
      </div>

      <div className={st.holderScore}>
        <Text variant='normal' bold className={st.scoreText}>
          PO Score
          <div className={st.holderFunctionsIcon}>
            <FunctionsIcon className={st.functionsIcon} />
          </div>
          {finalScore}
        </Text>
      </div>
    </>
  );
}

export default OrderScoreMatrix;
