import * as ordersApi from './orders';
import * as customersApi from './customers';
import * as partTypesApi from './partTypes';
import * as suggest from './suggest';
import * as templates from './templates';
import * as travelerApi from './travelers';
import * as usersApi from './users';
import * as controlValuesApi from './controlValues';
import * as jobs from './jobs';
import * as files from './files';
import * as client from './client';
import * as rfqs from './rfqs';
import * as worklog from './worklog';
import * as notes from './notes';
import * as scoreItems from './scoreItems';
import * as ncr from './ncr';
import * as reports from './reports';
import * as metadata from './metadata';

export default {
  ...ordersApi,
  ...customersApi,
  ...partTypesApi,
  ...suggest,
  ...templates,
  ...travelerApi,
  ...usersApi,
  ...controlValuesApi,
  ...jobs,
  ...files,
  ...client,
  ...rfqs,
  ...worklog,
  ...notes,
  ...scoreItems,
  ...ncr,
  ...reports,
  ...metadata,
};
