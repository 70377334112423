import React, { useEffect, useMemo, useState } from 'react';
import CenteredColumnLayout from 'layouts/CenteredColumnLayout/CenteredColumnLayout';
import HeaderContent from 'componentsShared/HeaderContent/HeaderContent';
import * as jobsActions from 'store/jobs/actions';
import { useDispatch, useSelector } from 'react-redux';
import JobsListContainer from 'components/Jobs/JobsListContainer/JobsListContainer';
import Loader from 'componentsShared/Loader/Loader';
import AuthService from 'services/auth/AuthService';
import JobListActionButtonQRCode from 'components/Jobs/JobListActionButtonQRCode/JobListActionButtonQRCode';
import JobListActionButtonBatch from 'components/Jobs/JobListActionButtonBatch/JobListActionButtonBatch';
import { JOBS_QA_TYPES, JOBS_TYPES } from 'constants/jobs';
import st from './JobListPage.module.scss';

const JobListPage = () => {
  const dispatch = useDispatch();

  const isIncludeOnlyWorkerWithoutQA =
    AuthService.isIncludeOnlyWorkerWithoutQA();
  const isIncludeOnlyQAWithoutWorker =
    AuthService.isIncludeOnlyQAWithoutWorker();
  const isIncludeWorkerAndQA = AuthService.isIncludeWorkerAndQA();

  const isLoadingJob =
    useSelector((state) => state?.jobs?.jobs?.isLoading) ?? false;
  const isLoadingJobList =
    useSelector((state) => state?.jobs?.jobs?.isLoadingList) ?? false;
  const isScrollJob = useSelector((state) => state?.jobs?.isScrollJob) ?? false;
  const scrollJobId = useSelector((state) => state?.jobs?.scrollJobId) ?? '';
  const data = useSelector((state) => state?.jobs?.jobs?.data) ?? {};

  const assignedIds =
    useSelector((state) => state?.jobs?.jobs?.assignedIds) ?? [];
  const availableIds =
    useSelector((state) => state?.jobs?.jobs?.availableIds) ?? [];
  const needsQAIds =
    useSelector((state) => state?.jobs?.jobs?.needsQAIds) ?? [];
  const interprocessIds =
    useSelector((state) => state?.jobs?.jobs?.interprocessIds) ?? [];
  const ncrIds = useSelector((state) => state?.jobs?.jobs?.ncrIds) ?? [];

  const [isLoading, setIsLoading] = useState(false);
  const [isChangedTab, setIsChangedTab] = useState('');
  const [isLoadingJobListState, setIsLoadingJobListState] = useState(true);

  const title = useMemo(() => {
    if (isIncludeOnlyWorkerWithoutQA) {
      return 'My Jobs';
    }

    return 'Jobs';
  }, [isIncludeOnlyWorkerWithoutQA]);

  const loadJobList = () => {
    setIsLoadingJobListState(true);
    dispatch(
      jobsActions.getJobList.start({
        clearLoad: () => setIsLoadingJobListState(false),
      })
    );
  };

  const handleFindJob = (jobId) => {
    dispatch(jobsActions.checkJob.start(jobId));
  };

  useEffect(() => {
    dispatch(jobsActions.clearSelectJobIdList());
    dispatch(jobsActions.changeIsCreateBatch());

    loadJobList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const findScrollId = (tabList) => {
    const arrayIds = [];
    tabList.forEach((keyJob) => {
      const list = data?.[keyJob]?.list;
      // console.log(data, keyJob, scrollJobId, arrayIds);
      if (list && Array.isArray(list)) {
        list.forEach(({ id }) => arrayIds.push(id));
      }
    });

    return arrayIds.includes(scrollJobId);
  };

  const checkChangedTab = () => {
    if (isIncludeOnlyWorkerWithoutQA) {
      if (findScrollId(assignedIds)) {
        setIsChangedTab(JOBS_TYPES.assigned);
        return;
      }

      if (findScrollId(availableIds)) {
        setIsChangedTab(JOBS_TYPES.available);
        return;
      }

      if (findScrollId(needsQAIds)) {
        setIsChangedTab(JOBS_TYPES.needsQA);
        return;
      }
    }
    if (isIncludeOnlyQAWithoutWorker) {
      if (findScrollId(interprocessIds)) {
        setIsChangedTab(JOBS_QA_TYPES.interprocessQA);
        return;
      }

      if (findScrollId([...assignedIds, ...availableIds])) {
        setIsChangedTab(JOBS_QA_TYPES.finalQA);
        return;
      }

      if (findScrollId(ncrIds)) {
        setIsChangedTab(JOBS_QA_TYPES.ncr);
        return;
      }
    }

    if (isIncludeWorkerAndQA) {
      if (findScrollId(interprocessIds)) {
        setIsChangedTab(JOBS_QA_TYPES.interprocessQA);
        return;
      }

      if (findScrollId(ncrIds)) {
        setIsChangedTab(JOBS_QA_TYPES.ncr);
        return;
      }

      if (findScrollId(assignedIds)) {
        setIsChangedTab(JOBS_TYPES.assigned);
        return;
      }

      if (findScrollId(availableIds)) {
        setIsChangedTab(JOBS_TYPES.available);
        return;
      }
    }
  };

  useEffect(() => {
    if (
      scrollJobId &&
      isScrollJob &&
      !isLoadingJob &&
      !isLoadingJobList &&
      !isLoadingJobListState &&
      !isLoadingJobListState
    ) {
      setIsLoading(true);
      checkChangedTab();
      setIsLoading(false);

      setTimeout(() => {
        const element = document.getElementById(`job-id-${scrollJobId}`);

        if (element) {
          element.scrollIntoView({
            block: 'center',
            behavior: 'smooth',
          });
        }

        dispatch(jobsActions.changeIsScrollJob());
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    scrollJobId,
    isScrollJob,
    isLoadingJob,
    dispatch,
    isLoadingJobList,
    isLoadingJobListState,
  ]);

  return (
    <CenteredColumnLayout classNamePageContentHolder={st.pageContentHolder}>
      <div className={st.headerContentHolder}>
        <HeaderContent
          title={title}
          isActive
          className={st.headerContent}
          componentActive={() => (
            <>
              <JobListActionButtonBatch />
              <JobListActionButtonQRCode handleFindJob={handleFindJob} />
            </>
          )}
        />
      </div>

      <div className={st.contentWrapper}>
        <JobsListContainer
          isChangedTab={isChangedTab}
          setIsChangedTab={setIsChangedTab}
        />
        <Loader
          isVisible={
            isLoadingJob ||
            isLoading ||
            isLoadingJobList ||
            isLoadingJobListState
          }
          isBackgroundColorGray
        />
      </div>
    </CenteredColumnLayout>
  );
};

export default JobListPage;
