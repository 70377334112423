import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import { handleRequestError } from 'services/api/apiTools';
import { getParsingArrayToObj } from 'helpers/functions';
import NotificationsService from 'services/notifications/NotificationsService';
import { PAGE_SIZE, VARIANT_UI } from 'constants/ui';
import {
  ERROR_TEXT_REUSE_EMAIL,
  HEADER_ATTENTION,
} from 'constants/notifications';
import ApiService from 'services/api';
import * as actions from './actions';

export function* getCustomerListSaga(action) {
  const { isPagination } = action?.payload || {};

  const filterSearch = yield select((state) =>
    state?.customers?.fetchParams?.search?.trim()
  ) ?? '';

  const page = yield select(
    (state) => state?.customers?.fetchParams?.pagination?.page
  ) ?? 0;
  const size = yield select(
    (state) => state?.customers?.fetchParams?.pagination?.size
  ) ?? PAGE_SIZE;

  const filter = {
    filter: filterSearch,
    page: isPagination ? page : null,
    size: isPagination ? size : null,
  };

  let requestValue;
  try {
    requestValue = yield call(ApiService.getCustomerList, filter);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getCustomerList.failure());
    return;
  }

  const customerObj = getParsingArrayToObj(requestValue.items);
  const customerIds = requestValue?.items?.map(({ id }) => id) || [];

  yield put(
    actions.getCustomerList.success({
      data: customerObj,
      ids: customerIds,
      page: requestValue.page,
      count: requestValue.count,
    })
  );
}

export function* createCustomerSaga(action) {
  const { data: valueForm, onCloseModal } = action.payload;
  const validValueRequest = { name: valueForm.name };

  try {
    yield call(ApiService.createCustomer, validValueRequest);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.createCustomer.failure());
    return;
  }

  yield put(actions.getCustomerList.start({ isPagination: true }));

  yield put(actions.createCustomer.success());
  onCloseModal();
}

export function* changeCustomerSaga(action) {
  const { data, id, onCloseModal } = action.payload;
  try {
    yield call(ApiService.changeCustomer, { data, id });
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.changeCustomer.failure());
    return;
  }

  yield put(actions.getCustomerList.start({ isPagination: true }));
  yield put(actions.changeCustomer.success());
  onCloseModal();
}

export function* resendInviteCustomerSaga(action) {
  try {
    yield call(ApiService.resendInviteCustomer, action.payload);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.resendInviteCustomer.failure());
    return;
  }

  yield put(actions.getCustomerList.start({ isPagination: true }));

  yield put(actions.resendInviteCustomer.success());
  NotificationsService.throwNotification({
    title: 'Successfully!',
    text: 'Invite sent',
    variant: VARIANT_UI.success,
  });
}

export function* removeCustomerSaga(action) {
  const params = action.payload;
  try {
    yield call(ApiService.removeCustomer, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.removeCustomer.failure());
    return;
  }

  yield put(actions.getCustomerList.start({ isPagination: true }));
  yield put(actions.removeCustomer.success());
}

export function* getCustomerSaga(action) {
  const params = action.payload;
  let requestValue;

  try {
    requestValue = yield call(ApiService.getCustomer, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getCustomer.failure());
    return;
  }

  const customerObj = getParsingArrayToObj([requestValue]);
  const customerIds = [requestValue.id];

  yield put(
    actions.getCustomer.success({ data: customerObj, ids: customerIds })
  );
}

export function* resendInviteCustomerWithSavingSaga(action) {
  const { data, customerId, isChangeEmail, onCloseModal, companyId } =
    action.payload;
  let requestValue;

  try {
    if (data.email && isChangeEmail) {
      requestValue = yield call(ApiService.checkCustomerEmail, data.email);
      const isUniqueEmail = requestValue.unique;

      if (!isUniqueEmail) {
        yield put(actions.resendInviteCustomerWithSaving.failure());
        NotificationsService.throwNotification({
          title: HEADER_ATTENTION,
          text: ERROR_TEXT_REUSE_EMAIL,
          variant: VARIANT_UI.danger,
        });
        return;
      }
    }
    yield call(ApiService.changeCustomerLogin, { data, companyId, customerId });
    yield put(actions.resendInviteCustomer.start(customerId));
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.resendInviteCustomerWithSaving.failure());
    return;
  }

  yield put(actions.getCustomerList.start({ isPagination: true }));
  yield put(actions.resendInviteCustomerWithSaving.success());
  onCloseModal();
}

export function* createCustomerLoginSaga(action) {
  const { data: valueForm, onCloseModal } = action.payload;
  const validValueRequest = { name: valueForm.name, email: valueForm.email };

  let requestValue;
  let requestValueCustomerLogin;
  try {
    if (valueForm.email) {
      requestValue = yield call(ApiService.checkCustomerEmail, valueForm.email);
      const isUniqueEmail = requestValue.unique;

      if (!isUniqueEmail) {
        yield put(actions.createCustomerLogin.failure());
        NotificationsService.throwNotification({
          title: HEADER_ATTENTION,
          text: ERROR_TEXT_REUSE_EMAIL,
          variant: VARIANT_UI.danger,
        });
        return;
      }
    }

    requestValueCustomerLogin = yield call(ApiService.createCustomerLogin, {
      data: validValueRequest,
      id: valueForm.companyId,
    });
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.createCustomerLogin.failure());
    return;
  }
  if (valueForm.isInvite) {
    const customerId = requestValueCustomerLogin.id;
    yield put(actions.resendInviteCustomer.start(customerId));
  } else {
    yield put(actions.getCustomerList.start({ isPagination: true }));
  }

  yield put(actions.createCustomerLogin.success());
  onCloseModal();
}

export function* changeCustomerLoginSaga(action) {
  const { data, id, companyId, isChangeEmail, onCloseModal } = action.payload;
  let requestValue;
  try {
    if (data.email && isChangeEmail) {
      requestValue = yield call(ApiService.checkCustomerEmail, data.email);
      const isUniqueEmail = requestValue.unique;

      if (!isUniqueEmail) {
        yield put(actions.changeCustomer.failure());
        NotificationsService.throwNotification({
          title: HEADER_ATTENTION,
          text: ERROR_TEXT_REUSE_EMAIL,
          variant: VARIANT_UI.danger,
        });
        return;
      }
    }
    yield call(ApiService.changeCustomerLogin, {
      data,
      companyId,
      customerId: id,
    });
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.changeCustomerLogin.failure());
    return;
  }

  yield put(actions.getCustomerList.start({ isPagination: true }));
  yield put(actions.changeCustomerLogin.success());
  onCloseModal();
}

export function* blockCustomerLoginSaga(action) {
  const params = action.payload;

  try {
    yield call(ApiService.blockCustomerLogin, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.blockCustomerLogin.failure());
    return;
  }
  yield put(actions.getCustomerList.start({ isPagination: true }));
  yield put(actions.blockCustomerLogin.success());
}

export function* unblockCustomerLoginSaga(action) {
  const {
    companyId,
    customerId,
    isChangeEmail,
    email,
    name,
    onClose,
    reset,
    onHideModal,
  } = action.payload;

  const params = { companyId, customerId };

  let requestValue;
  try {
    yield call(ApiService.unblockCustomerLogin, params);

    if (isChangeEmail) {
      requestValue = yield call(ApiService.checkCustomerEmail, email);
      const isUniqueEmail = requestValue.unique;

      if (!isUniqueEmail) {
        yield put(actions.changeCustomer.failure());
        NotificationsService.throwNotification({
          title: HEADER_ATTENTION,
          text: ERROR_TEXT_REUSE_EMAIL,
          variant: VARIANT_UI.danger,
        });
        return;
      }

      const dataLogin = { email, name };

      yield call(ApiService.changeCustomerLogin, {
        data: dataLogin,
        companyId,
        customerId,
      });
    }
    yield put(actions.resendInviteCustomer.start(customerId));

    onHideModal();
    onClose();
    reset();
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.unblockCustomerLogin.failure());
    return;
  }
  yield put(actions.getCustomerList.start({ isPagination: true }));
  yield put(actions.unblockCustomerLogin.success());
}

export function* customersSagas() {
  yield all([
    yield takeLatest(actions.getCustomerList.start, getCustomerListSaga),
    yield takeLatest(actions.createCustomer.start, createCustomerSaga),
    yield takeLatest(actions.changeCustomer.start, changeCustomerSaga),
    yield takeLatest(
      actions.resendInviteCustomer.start,
      resendInviteCustomerSaga
    ),
    yield takeLatest(actions.removeCustomer.start, removeCustomerSaga),
    yield takeLatest(actions.getCustomer.start, getCustomerSaga),
    yield takeLatest(
      actions.resendInviteCustomerWithSaving.start,
      resendInviteCustomerWithSavingSaga
    ),
    yield takeLatest(
      actions.createCustomerLogin.start,
      createCustomerLoginSaga
    ),
    yield takeLatest(
      actions.changeCustomerLogin.start,
      changeCustomerLoginSaga
    ),
    yield takeLatest(actions.blockCustomerLogin.start, blockCustomerLoginSaga),
    yield takeLatest(
      actions.unblockCustomerLogin.start,
      unblockCustomerLoginSaga
    ),
  ]);
}
