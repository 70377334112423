import CenteredColumnLayout from 'layouts/CenteredColumnLayout/CenteredColumnLayout';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as customersActions from 'store/customers/actions';
import HeaderContent from 'componentsShared/HeaderContent/HeaderContent';
import CustomersListManagerShipping from 'components/Customers/CustomersListManagerShipping/CustomersListManagerShipping';
import { TIME_SEARCH_DEBOUNCE } from 'constants/ui';
import { debounce } from 'helpers/functions';
import InputSearch from 'componentsShared/InputSearch/InputSearch';
import NavigationService from 'services/navigation/NavigationService';
import { getRoutePath } from 'helpers/path';
import { ROUTES } from 'router/routes';
import st from './CustomerListManagerPage.module.scss';

const debouncedSearch = debounce((onSearch, data) => {
  onSearch(data);
}, TIME_SEARCH_DEBOUNCE);

function CustomerListManagerPage() {
  const dispatch = useDispatch();

  const searchValue =
    useSelector((state) => state?.customers?.fetchParams?.search) ?? '';
  const isLoadingSearch =
    useSelector((state) => state?.customers?.fetchParams?.isLoading) ?? false;

  const onChangeFilterSearch = (value) => {
    dispatch(customersActions.changeFilterSearch(value));
  };

  const loadCustomerList = () => {
    dispatch(customersActions.getCustomerList.start({ filter: searchValue }));
  };

  const handleChangeSearch = (value) => {
    onChangeFilterSearch(value);
    if (value) {
      return debouncedSearch(() => {
        loadCustomerList();
      });
    }
    loadCustomerList();
  };

  const handleClickCard = (customerId) => {
    NavigationService.navigateToPath(
      getRoutePath(ROUTES.customerDetailsManagerTypeRFQ.path, {
        id: customerId,
      })
    );
  };

  useEffect(() => {
    loadCustomerList();
    return () => {
      onChangeFilterSearch('');
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CenteredColumnLayout
      classNamePageContentHolder={st.layoutContentHolder}
      classNamePageWrapper={st.layoutWrapper}>
      <HeaderContent
        title='Customers'
        isActive
        className={st.headerContent}
        componentActive={() => (
          <InputSearch
            value={searchValue}
            onChange={handleChangeSearch}
            isLoadingSearch={isLoadingSearch}
            placeholder='Search by name'
          />
        )}
      />
      <div className={st.holderPartsList}>
        <div className={st.holderPartsListContent}>
          <div className={st.partListContainer}>
            <CustomersListManagerShipping onClickCard={handleClickCard} />
          </div>
        </div>
      </div>
    </CenteredColumnLayout>
  );
}

export default CustomerListManagerPage;
