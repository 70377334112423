import React from 'react';
import { oneOfType, string, object, number, bool, array } from 'prop-types';
import JobsOperation from 'components/Jobs/JobsOperation/JobsOperation';

JobsOperationWrapper.propTypes = {
  jobId: oneOfType([number, string]),
  currentOperation: object,
  quantityOperation: oneOfType([number, string]),
  currentOperationIndex: oneOfType([number, string]),
  isLastOperationWithNeedsQA: bool,
  isLastOperation: bool,
  isControlValues: bool,
  jobIdList: array,
  isFinalQA: bool,
};

function JobsOperationWrapper({
  jobIdList = [],
  jobId,
  currentOperation,
  quantityOperation = 0,
  currentOperationIndex,
  isLastOperationWithNeedsQA = false,
  isLastOperation = false,
  isControlValues = false,
  isFinalQA = false,
}) {
  return (
    <JobsOperation
      jobIdList={jobIdList}
      currentOperation={currentOperation}
      quantityOperation={quantityOperation}
      jobId={jobId}
      currentOperationIndex={currentOperationIndex}
      isLastOperationWithNeedsQA={isLastOperationWithNeedsQA}
      isLastOperation={isLastOperation}
      isControlValues={isControlValues}
      isFinalQA={isFinalQA}
    />
  );
}

export default JobsOperationWrapper;
