import { getRequestAction } from 'helpers/functions';
import { createAction } from '@reduxjs/toolkit';

const ACTION_PREFIX = 'auth';

export const loginCustomer = getRequestAction(`${ACTION_PREFIX}/loginCustomer`);

export const loginEmployee = getRequestAction(`${ACTION_PREFIX}/loginEmployee`);

export const signOut = createAction(`${ACTION_PREFIX}/signOut`);

export const getUserData = getRequestAction(`${ACTION_PREFIX}/getUserData`);

export const signUpConfirm = getRequestAction(`${ACTION_PREFIX}/signUpConfirm`);

export const changeTempPassword = getRequestAction(
  `${ACTION_PREFIX}/changeTempPassword`
);

export const forgotPassword = getRequestAction(
  `${ACTION_PREFIX}/forgotPassword`
);

export const checkClientToken = getRequestAction(
  `${ACTION_PREFIX}/checkClientToken`
);

export const getVersionBacked = getRequestAction(
  `${ACTION_PREFIX}/getVersionBacked`
);

export const changeUrlRedirectAfterAuthorization = createAction(
  `${ACTION_PREFIX}/changeUrlRedirectAfterAuthorization`
);
