import React from 'react';
import { shape, bool, string, object } from 'prop-types';
import st from './DragCustomPlaceholder.module.scss';

DragCustomPlaceholder.propTypes = {
  type: string.isRequired,
  isDraggingOver: bool,
  placeholderProps: shape({
    props: object,
    type: string,
    sourceDroppableId: string,
  }),
};

function DragCustomPlaceholder({ placeholderProps, type, isDraggingOver }) {
  if (placeholderProps?.type === type && isDraggingOver) {
    return (
      <div
        className={st.placeholderContent}
        style={{
          top: placeholderProps.props.clientY,
          left: placeholderProps.props.clientX,
          height: placeholderProps.props.clientHeight,
          width: placeholderProps.props.clientWidth,
        }}
      />
    );
  }

  return null;
}

export default DragCustomPlaceholder;
