import React, { useEffect } from 'react';
import * as notesActions from 'store/notes/actions';
import { useSelector, useDispatch } from 'react-redux';
import ButtonNotes from 'components/Notes/ButtonNotes/ButtonNotes';
import AuthService from 'services/auth/AuthService';
import moment from 'moment';
import { oneOfType, string, number } from 'prop-types';
import { USER_ROLES } from 'constants/userRoles';
import { ucFirst } from 'helpers/string';
import { MODULE_TYPES } from 'constants/files';
import { FORMAT_DATE_USA } from 'constants/ui';

ButtonNotesWrapper.propTypes = {
  jobId: oneOfType([number, string]),
  title: string,
};

const getTitleUserCardNote = (userData, customerData, userLogin) => {
  const isClient = AuthService.isClient();
  if (userData) {
    return `${AuthService.getHighestUserRole(userData?.roles)}: ${
      userData?.name
    }`;
  }

  if (isClient && customerData?.email === userLogin) {
    return 'Me';
  }

  return customerData?.name ? `Customer: ${customerData?.name}` : 'Customer';
};

function ButtonNotesWrapper({ jobId, title }) {
  const dispatch = useDispatch();
  const isClient = AuthService.isClient();

  const currentNotes =
    useSelector((state) => state?.notes?.notes?.data?.[jobId]) ?? [];
  const userRoles = useSelector((state) => state?.auth?.user?.roles) ?? [];
  const userLogin = useSelector((state) => state?.auth?.user?.login) ?? '';

  const countNotes = currentNotes?.length || '0';

  const isShowSelectVisibleCustomer =
    userRoles.includes(USER_ROLES.qa) ||
    userRoles.includes(USER_ROLES.manager) ||
    userRoles.includes(USER_ROLES.admin);

  const notesArray =
    currentNotes.length > 0
      ? currentNotes.map((item) => {
          const titleUser = getTitleUserCardNote(
            item?.user,
            item?.customer,
            userLogin
          );
          const phase = ucFirst(item.phase || '');

          return {
            title: {
              user: titleUser,
              phase,
              date: moment(item.created).format(FORMAT_DATE_USA),
            },
            text: item.text,
            currentUser:
              userLogin === item?.user?.login ||
              userLogin === item?.customer?.email,
            isShowCustomer:
              !isClient && !!item.user && item.visible_to_customer,
          };
        })
      : [];

  const handleChangeNote = () => {};

  const handleCreateNotes = (value, isVisibleToCustomer = false) => {
    dispatch(
      notesActions.createNote.start({
        id: jobId,
        data: { text: value, visible_to_customer: isVisibleToCustomer },
        type: MODULE_TYPES.jobs,
      })
    );
  };

  useEffect(() => {
    if (jobId) {
      dispatch(
        notesActions.getNoteList.start({ id: jobId, type: MODULE_TYPES.jobs })
      );
    }
  }, [jobId, dispatch]);

  return (
    <ButtonNotes
      countNotes={countNotes}
      notesArray={notesArray}
      titleHeader={title}
      handleChangeNote={handleChangeNote}
      handleCreateNotes={handleCreateNotes}
      isShowSelectVisibleCustomer={isShowSelectVisibleCustomer}
    />
  );
}

export default ButtonNotesWrapper;
