import { createReducer } from '@reduxjs/toolkit';
import { PAGE_SIZE } from 'constants/ui';
import * as actions from './actions';

export const initialState = {
  controlValues: {
    data: {},
    sortIds: [],
    sortActiveIds: [],
    isLoading: false,
    isLoadingCreate: false,
    isLoadingChange: false,
  },
  fetchParams: {
    pagination: {
      page: 0,
      size: PAGE_SIZE,
      count: 0,
    },
  },
};

export const controlValuesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.getControlValueList.start, (state, action) => {
      const { isClearPage } = action?.payload || {};

      return {
        ...state,
        controlValues: {
          ...state.controlValues,
          isLoading: true,
        },
        fetchParams: {
          ...state.fetchParams,
          pagination: {
            ...state.fetchParams.pagination,
            page: isClearPage ? 0 : state.fetchParams.pagination.page,
          },
        },
      };
    })
    .addCase(actions.getControlValueList.success, (state, action) => {
      const { data, ids, activeIds, page, count } = action.payload;

      return {
        ...state,
        controlValues: {
          ...state.controlValues,
          data,
          sortIds: ids,
          sortActiveIds: activeIds,
          isLoading: false,
        },
        fetchParams: {
          ...state.fetchParams,
          pagination: {
            ...state.fetchParams.pagination,
            page,
            count,
          },
        },
      };
    })
    .addCase(actions.getControlValueList.failure, (state) => ({
      ...state,
      controlValues: {
        ...state.controlValues,
        isLoading: false,
      },
    }))
    .addCase(actions.createControlValue.start, (state) => ({
      ...state,
      controlValues: {
        ...state.controlValues,
        isLoadingCreate: true,
      },
    }))
    .addCase(actions.createControlValue.success, (state) => {
      return {
        ...state,
        controlValues: {
          ...state.controlValues,
          isLoadingCreate: false,
        },
      };
    })
    .addCase(actions.createControlValue.failure, (state) => ({
      ...state,
      controlValues: {
        ...state.controlValues,
        isLoadingCreate: false,
      },
    }))
    .addCase(actions.changeControlValue.start, (state) => ({
      ...state,
      controlValues: {
        ...state.controlValues,
        isLoadingChange: true,
      },
    }))
    .addCase(actions.changeControlValue.success, (state) => {
      return {
        ...state,
        controlValues: {
          ...state.controlValues,
          isLoadingChange: false,
        },
      };
    })
    .addCase(actions.changeControlValue.failure, (state) => ({
      ...state,
      controlValues: {
        ...state.controlValues,
        isLoadingChange: false,
      },
    }))
    .addCase(actions.removeControlValue.start, (state) => ({
      ...state,
      controlValues: {
        ...state.controlValues,
        isLoading: true,
      },
    }))
    .addCase(actions.removeControlValue.success, (state) => {
      return {
        ...state,
        controlValues: {
          ...state.controlValues,
          isLoading: false,
        },
      };
    })
    .addCase(actions.removeControlValue.failure, (state) => ({
      ...state,
      controlValues: {
        ...state.controlValues,
        isLoading: false,
      },
    }))
    .addCase(actions.getControlValues.start, (state) => ({
      ...state,
      controlValues: {
        ...state.controlValues,
        isLoading: true,
      },
    }))
    .addCase(actions.getControlValues.success, (state, action) => {
      const { data, ids, activeIds } = action.payload;

      return {
        ...state,
        controlValues: {
          ...state.controlValues,
          data,
          sortIds: ids,
          sortActiveIds: activeIds,
          isLoading: false,
        },
      };
    })
    .addCase(actions.getControlValues.failure, (state) => ({
      ...state,
      controlValues: {
        ...state.controlValues,
        isLoading: false,
      },
    }))
    .addCase(actions.changePage, (state, action) => {
      const page = action.payload;
      return {
        ...state,
        fetchParams: {
          ...state.fetchParams,
          pagination: {
            ...state.fetchParams.pagination,
            page,
          },
        },
      };
    })
    .addCase(actions.getBatchControlValueList.start, (state) => {
      return {
        ...state,
        controlValues: {
          ...state.controlValues,
          isLoading: true,
        },
      };
    })
    .addCase(actions.getBatchControlValueList.success, (state, action) => {
      const { data, ids } = action.payload;

      return {
        ...state,
        controlValues: {
          ...state.controlValues,
          data,
          sortIds: ids,
          isLoading: false,
        },
      };
    })
    .addCase(actions.getBatchControlValueList.failure, (state) => ({
      ...state,
      controlValues: {
        ...state.controlValues,
        isLoading: false,
      },
    }));
});
