import { getRequestAction } from 'helpers/functions';
import { createAction } from '@reduxjs/toolkit';

const ACTION_PREFIX = 'templates';

export const getTemplateList = getRequestAction(
  `${ACTION_PREFIX}/getTemplateList`
);

export const createTemplate = getRequestAction(
  `${ACTION_PREFIX}/createTemplate`
);

export const changeTemplate = getRequestAction(
  `${ACTION_PREFIX}/changeTemplate`
);

export const removeTemplate = getRequestAction(
  `${ACTION_PREFIX}/removeTemplate`
);

export const updateDetailsTemplateData = createAction(
  `${ACTION_PREFIX}/updateDetailsTemplateData`
);

export const updateNewTemplateData = createAction(
  `${ACTION_PREFIX}/updateNewTemplateData`
);

export const clearNewTemplateData = createAction(
  `${ACTION_PREFIX}/clearNewTemplateData`
);

export const getTemplateListForWizard = getRequestAction(
  `${ACTION_PREFIX}/getTemplateListForWizard`
);

export const updateTemplateOperationControl = createAction(
  `${ACTION_PREFIX}/updateTemplateOperationControl`
);

export const updateTemplateSelectControlValues = createAction(
  `${ACTION_PREFIX}/updateTemplateSelectControlValues`
);

export const selectAllTemplateOperationControl = createAction(
  `${ACTION_PREFIX}/selectAllTemplateOperationControl`
);

export const deselectAllTemplateOperationControl = createAction(
  `${ACTION_PREFIX}/deselectAllTemplateOperationControl`
);

export const changeFilterSearch = createAction(
  `${ACTION_PREFIX}/changeFilterSearch`
);

export const changePage = createAction(`${ACTION_PREFIX}/changePage`);

export const addFileForTemplate = getRequestAction(
  `${ACTION_PREFIX}/addFileForTemplate`
);

export const getTemplate = getRequestAction(`${ACTION_PREFIX}/getTemplate`);

export const updateNewPhaseList = createAction(
  `${ACTION_PREFIX}/updateNewPhaseList`
);
