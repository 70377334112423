import { api } from './ApiService';

export const getNCRList = () => {
  return api.get('/bundles/ncr');
};

export const createNCR = (data) => {
  return api.post('/bundles/ncr', data);
};

export const removeNCR = () => {
  return api.delete('/bundles/ncr');
};
