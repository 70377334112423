import React, { useRef, useState } from 'react';
import { string, bool, func } from 'prop-types';
import Modal from 'componentsShared/Modal/Modal';
import { useSelector } from 'react-redux';
import ModalFilePreviewPagination from 'components/Modal/ModalFilePreviewPagination/ModalFilePreviewPagination';
import FilePDF from 'componentsShared/FilePDF/FilePDF';
import { VARIANT_OUTLINE_UI, ZOOM_IMG_ARRAY } from 'constants/ui';
import { Button } from 'react-bootstrap';
import { ZoomIn, ZoomOut } from 'react-bootstrap-icons';
import st from './ModalJobsOperationPreviewFile.module.scss';

ModalJobsOperationPreviewFile.propTypes = {
  file: string,
  showModal: bool,
  setShowModal: func,
};

function ModalJobsOperationPreviewFile({
  file = '',
  showModal = false,
  setShowModal = () => {},
  entityType = 'jobs',
}) {
  const [zoomImgIndex, setZoomImgIndex] = useState(0);

  const holderRef = useRef(null);

  const currentFileValue =
    useSelector((state) => state?.[entityType]?.files?.dataUrl?.[file]) ?? '';

  const fileNameArray = file.split('/');
  const fileName = fileNameArray[fileNameArray.length - 1];

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const isTypePDF = currentFileValue?.type?.includes('pdf') || false;

  const onDocumentLoadSuccess = (value) => {
    setNumPages(value.numPages || 0);
  };

  const onChangeZoomImg = (type = 'next') => {
    let index;
    if (type === 'next') {
      index =
        zoomImgIndex >= ZOOM_IMG_ARRAY.length - 1
          ? ZOOM_IMG_ARRAY.length - 1
          : zoomImgIndex + 1;
    } else {
      index = zoomImgIndex === 0 ? 0 : zoomImgIndex - 1;
    }
    setZoomImgIndex(index);
  };

  const renderButtonBottomLeft = () => {
    if (isTypePDF) {
      return (
        <ModalFilePreviewPagination
          pageNumber={pageNumber}
          numPages={numPages}
          setPageNumber={setPageNumber}
        />
      );
    }
  };

  const openFileInBrowser = () => {
    if (currentFileValue?.url) {
      window.open(currentFileValue.url, 'EPrescription');
    }
  };

  const renderButtonBottomRight = () => {
    if (isTypePDF) {
      return <Button onClick={openFileInBrowser}>Open in a browser</Button>;
    }

    return (
      <div className={st.footerButtonGroup}>
        <Button
          variant={VARIANT_OUTLINE_UI.primary}
          onClick={() => onChangeZoomImg('prev')}
          disabled={zoomImgIndex === 0}>
          <ZoomOut />
        </Button>
        <Button
          variant={VARIANT_OUTLINE_UI.primary}
          onClick={() => onChangeZoomImg('next')}
          disabled={zoomImgIndex === ZOOM_IMG_ARRAY.length - 1}>
          <ZoomIn />
        </Button>
      </div>
    );
  };

  const renderUrlFile = () => {
    if (isTypePDF) {
      return (
        <FilePDF
          currentUrl={currentFileValue.url}
          onDocumentLoadSuccess={onDocumentLoadSuccess}
          pageNumber={pageNumber}
          widthPDF={holderRef?.current?.offsetWidth || 700}
        />
      );
    }

    return (
      <img
        src={currentFileValue.url}
        className={st.jobSummaryImg}
        style={{ minWidth: `${100 * ZOOM_IMG_ARRAY[zoomImgIndex]}%` }}
        alt='Img'
      />
    );
  };

  return (
    <Modal
      title={fileName || 'File preview'}
      size='lg'
      show={showModal}
      setShow={setShowModal}
      buttonBottomRight={renderButtonBottomRight}
      buttonBottomLeft={renderButtonBottomLeft}
      heightBody='calc(100vh - 240px)'
      classNameTitle={st.modalTitle}
      fullWidthDesktop>
      <div className={st.wrapperModalContent}>
        <div className={st.holderImg} ref={holderRef}>
          {renderUrlFile()}
        </div>
      </div>
    </Modal>
  );
}

export default ModalJobsOperationPreviewFile;
