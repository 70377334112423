import { USER_ROLES } from 'constants/userRoles';
import { store } from 'store/store';

const AuthService = {
  getCurrentState() {
    return store.getState();
  },
  getHighestUserRole(roles = []) {
    const currentState = this.getCurrentState();
    const accountType =
      roles.length > 0 ? roles : currentState?.auth?.user?.roles ?? [];
    let currentRole = '';
    switch (true) {
      case accountType.includes(USER_ROLES.admin):
        currentRole = USER_ROLES.admin;
        break;
      case accountType.includes(USER_ROLES.manager):
        currentRole = USER_ROLES.manager;
        break;
      case accountType.includes(USER_ROLES.shipping):
        currentRole = USER_ROLES.shipping;
        break;
      case accountType.includes(USER_ROLES.painter):
        currentRole = USER_ROLES.painter;
        break;
      case accountType.includes(USER_ROLES.preparer):
        currentRole = USER_ROLES.preparer;
        break;
      case accountType.includes(USER_ROLES.qa):
        currentRole = USER_ROLES.qa;
        break;
      case accountType.includes(USER_ROLES.customer):
        currentRole = USER_ROLES.customer;
        break;
      default:
        break;
    }
    return currentRole;
  },
  getRoles() {
    const currentState = this.getCurrentState();
    const accountType = currentState?.auth?.user?.roles ?? [];
    return accountType;
  },
  isOneRole() {
    const roles = this.getRoles();
    return roles.length === 1;
  },
  isShippingRole() {
    const currentState = this.getCurrentState();
    const userRole = currentState?.auth?.user?.roles;
    return AuthService.getHighestUserRole(userRole) === USER_ROLES.shipping;
  },
  isClient() {
    const currentState = this.getCurrentState();
    const userRole = currentState?.auth?.user?.roles;
    return AuthService.getHighestUserRole(userRole) === USER_ROLES.customer;
  },
  isQARole() {
    const currentState = this.getCurrentState();
    const userRole = currentState?.auth?.user?.roles;
    return AuthService.getHighestUserRole(userRole) === USER_ROLES.qa;
  },
  isPainterRole() {
    const currentState = this.getCurrentState();
    const userRole = currentState?.auth?.user?.roles;
    return AuthService.getHighestUserRole(userRole) === USER_ROLES.painter;
  },
  isPreparerRole() {
    const currentState = this.getCurrentState();
    const userRole = currentState?.auth?.user?.roles;
    return AuthService.getHighestUserRole(userRole) === USER_ROLES.preparer;
  },
  isAdminRole() {
    const currentState = this.getCurrentState();
    const userRole = currentState?.auth?.user?.roles;
    return AuthService.getHighestUserRole(userRole) === USER_ROLES.admin;
  },
  isManagerRole() {
    const currentState = this.getCurrentState();
    const userRole = currentState?.auth?.user?.roles;
    return AuthService.getHighestUserRole(userRole) === USER_ROLES.manager;
  },
  isAccessRoute({ userRoles = [], routeRoles = [] }) {
    if (routeRoles.length === 0 || userRoles.length === 0) {
      return false;
    }
    return routeRoles.some((item) => userRoles.includes(item));
  },
  isOnlyWorker() {
    return (
      this.isOneRole() &&
      (this.isPainterRole() || this.isPreparerRole() || this.isShippingRole())
    );
  },
  isOnlyQA() {
    return this.isOneRole() && this.isQARole();
  },
  isIncludeWorkerRole() {
    const arrayWorkerRole = [
      USER_ROLES.preparer,
      USER_ROLES.painter,
      USER_ROLES.shipping,
    ];

    const roles = this.getRoles();

    const includeWorkerRole = roles.some((item) =>
      arrayWorkerRole.includes(item)
    );

    return includeWorkerRole;
  },
  isIncludeQARole() {
    const qaRole = USER_ROLES.qa;
    const roles = this.getRoles();
    const includeQARole = roles.includes(qaRole);

    return includeQARole;
  },
  isIncludeOnlyWorkerWithoutQA() {
    const includeWorkerRole = this.isIncludeWorkerRole();
    const includeQARole = this.isIncludeQARole();

    return includeWorkerRole && !includeQARole;
  },
  isIncludeOnlyQAWithoutWorker() {
    const includeWorkerRole = this.isIncludeWorkerRole();
    const includeQARole = this.isIncludeQARole();

    return !includeWorkerRole && includeQARole;
  },
  isIncludeWorkerAndQA() {
    const includeWorkerRole = this.isIncludeWorkerRole();
    const includeQARole = this.isIncludeQARole();

    return includeWorkerRole && includeQARole;
  },
  isIncludeWorkerOrQA() {
    const includeWorkerRole = this.isIncludeWorkerRole();
    const includeQARole = this.isIncludeQARole();

    return includeWorkerRole || includeQARole;
  },
  isCurrentEmployee(login) {
    const currentState = this.getCurrentState();
    const user = currentState?.auth?.user;
    return login === user.login;
  },
};

export default AuthService;
