import React, { useMemo, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { PlusCircle } from 'react-bootstrap-icons';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import Text from 'componentsShared/Text/Text';
import DropdownButton from 'componentsShared/DropdownButton/DropdownButton';
import { MoreVertIcon, TrashIcon } from 'assets/icons';
import WrapperDroppableDelete from 'components/Traveler/WrapperDroppableDelete/WrapperDroppableDelete';
import { DND_TYPE } from 'constants/traveler';
import {
  MAX_FILE_SIZE,
  NOTIFICATION_ERROR_TYPE_FILE_TITLE,
  NOTIFICATION_LARGE_SIZE_FILE_TEXT,
  NOTIFICATION_LARGE_SIZE_FILE_TITLE,
  VARIANT_OUTLINE_UI,
  VARIANT_UI,
} from 'constants/ui';
import DragCustomPlaceholder from 'componentsShared/DragCustomPlaceholder/DragCustomPlaceholder';
import cn from 'classnames';
import * as templatesActions from 'store/templates/actions';
import {
  oneOfType,
  string,
  func,
  bool,
  shape,
  instanceOf,
  object,
  number,
  array,
} from 'prop-types';
import AdminTemplateOperation from 'components/Admin/AdminTemplateOperation/AdminTemplateOperation';
import AdminTemplateInput from 'components/Admin/AdminTemplateInput/AdminTemplateInput';
import ControlValuesStep from 'components/ControlValuesStep/ControlValuesStep';
import AttachFilesButton from 'components/AttachFilesButton/AttachFilesButton';
import { FILE_ACCEPT_IMAGE_AND_PDF, FILE_TYPES } from 'constants/files';
import ModalButton from 'componentsShared/ModalButton/ModalButton';
import { useDispatch, useSelector } from 'react-redux';
import NotificationsService from 'services/notifications/NotificationsService';
import DropdownButtonControlOpen from 'componentsShared/DropdownButtonControlOpen/DropdownButtonControlOpen';
import Modal from 'componentsShared/Modal/Modal';
import st from './AdminTemplateStep.module.scss';

AdminTemplateStep.propTypes = {
  currentRef: oneOfType([func, shape({ current: instanceOf(Element) })]),
  provided: object,
  stepNumber: number,
  name: string,
  operations: array,
  stepId: oneOfType([number, string]),
  handleChangeOperation: func,
  handleDeleteStep: func,
  handleDeleteOperation: func,
  stepIndex: number,
  isDraggingOver: bool,
  isDragOperation: bool,
  snapshotStep: object,
  placeholderProps: object,
  phaseType: string,
  handleAddStep: func,
  phaseName: string,
  handleChangeTitleStep: func,
  bundleId: oneOfType([number, string]),
  handleUpdateControlValues: func,
  informalQa: bool,
  handleUpdateInformalQa: func,
  isShowControlValues: bool,
  isDragFile: bool,
  templateId: oneOfType([number, string]),
  handleAddControlValues: func,
  isControlValues: bool,
  isDragRequireFile: bool,
  handleAddFileRequireFile: func,
};

function AdminTemplateStep({
  currentRef,
  provided,
  stepNumber = 1,
  name = '',
  operations,
  stepId,
  handleChangeOperation = () => {},
  handleDeleteStep = () => {},
  handleDeleteOperation = () => {},
  stepIndex,
  isDraggingOver,
  isDragOperation,
  snapshotStep,
  placeholderProps,
  phaseType,
  handleAddStep,
  phaseName,
  handleChangeTitleStep,
  bundleId,
  handleUpdateControlValues = () => {},
  informalQa = false,
  handleUpdateInformalQa = () => {},
  isShowControlValues = false,
  isDragFile,
  templateId,
  handleAddControlValues = () => {},
  isControlValues = false,
  isDragRequireFile,
  handleAddFileRequireFile = () => {},
  isDisabledControl = false,
}) {
  const dispatch = useDispatch();

  const isLoadingFile =
    useSelector((state) => state?.templates?.files?.isLoading) ?? false;
  const controlValuesActiveIds =
    useSelector(
      (state) => state?.controlValues?.controlValues?.sortActiveIds
    ) ?? [];
  const controlValuesData =
    useSelector((state) => state?.controlValues?.controlValues?.data) ?? {};

  const [updaingOperationIds, setUpdaingOperationIds] = useState([]);
  const [isNewOperation, setIsNewOperation] = useState(false);
  const [showHideContent, setShowHideContent] = useState(false);
  const [isEditTitleStep, setIsEditTitleStep] = useState(false);
  const [showModalHasControlValues, setShowModalHasControlValues] =
    useState(false);
  const [showModalNoneControlValues, setShowModalNoneControlValues] =
    useState(false);

  const isDisabledAddImageBtn = useMemo(() => {
    if (
      operations.length === 0 ||
      operations.every(({ bundle_id }) => !!bundle_id) ||
      (operations.length === 1 && isControlValues) ||
      isNewOperation
    ) {
      return true;
    }

    return false;
  }, [operations, isNewOperation, isControlValues]);

  const isControlValuesStorage = controlValuesActiveIds.length > 0;

  const getDropdownListControlValues = () => {
    if (!controlValuesActiveIds || controlValuesActiveIds.length === 0) {
      return [];
    }

    return controlValuesActiveIds.map((item) => {
      const label = controlValuesData?.[item]?.name || '';
      const currentId = controlValuesData?.[item]?.id;
      const currentIndex = operations.length === 0 ? 0 : operations.length;

      return {
        content: () => <Text variant='normal'>{label}</Text>,
        onClick: () => {
          if (isControlValues) {
            return setShowModalHasControlValues(true);
          }
          handleAddControlValues(stepIndex, currentId, currentIndex);
        },
        disabled: isNewOperation,
      };
    });
  };

  const dropdownListControlValues = getDropdownListControlValues();

  const dropdownListHeader = [
    {
      content: () => (
        <Text variant='normal' className={st.textRemove}>
          Remove Step
        </Text>
      ),
      onClick: () => handleDeleteStep(stepIndex),
    },
  ];

  const dropdownListRequire = useMemo(() => {
    return [
      {
        content: () => <Text variant='normal'>File Upload</Text>,
        onClick: () => handleAddFileRequireFile(stepIndex),
        disabled: isDisabledAddImageBtn,
      },
      isControlValuesStorage
        ? {
            content: () => (
              <Text variant='normal' className={st.textCV} bold>
                control values
              </Text>
            ),
            disabled: true,
          }
        : {
            disabled: true,
            content: () => <></>,
          },
      ...dropdownListControlValues,
    ];
  }, [
    dropdownListControlValues,
    isDisabledAddImageBtn,
    handleAddFileRequireFile,
    stepIndex,
    isControlValuesStorage,
  ]);

  const handleAddUpdating = (id) => {
    if (isDisabledControl) {
      return;
    }
    setUpdaingOperationIds((prev) => [...prev, id]);
  };

  const handleUpdating = (value, operationIndex) => {
    if (!value) {
      return handleDelete(operationIndex);
    }

    const operationId = operations[operationIndex].id;
    setUpdaingOperationIds((prev) =>
      prev.filter((item) => item !== operationId)
    );
    return handleChangeOperation(stepIndex, value, operationIndex);
  };

  const handleDelete = (operationIndex) => {
    handleDeleteOperation(stepIndex, operationIndex);
  };

  const handleAddNewOperation = (value) => {
    if (!value) {
      return setIsNewOperation(false);
    }
    handleChangeOperation(stepIndex, value);
    return setIsNewOperation(false);
  };

  const onChangeTitleStep = (value) => {
    handleChangeTitleStep(stepIndex, value);
    setIsEditTitleStep(false);
  };

  const handleUploadFile = (file) => {
    dispatch(
      templatesActions.addFileForTemplate.start({
        id: templateId,
        file,
        phaseName,
        stepIndex,
      })
    );
  };

  const onUploadFile = (type) => (file, e) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);

    e.target.value = '';

    const fileTypeArray = FILE_ACCEPT_IMAGE_AND_PDF.split(',').map((item) =>
      item.trim()
    );

    if (!fileTypeArray.includes(file.type)) {
      NotificationsService.throwNotification({
        title: NOTIFICATION_LARGE_SIZE_FILE_TITLE,
        text: NOTIFICATION_ERROR_TYPE_FILE_TITLE,
        variant: VARIANT_UI.danger,
      });

      return;
    }

    if (!file || file.size > MAX_FILE_SIZE) {
      NotificationsService.throwNotification({
        title: NOTIFICATION_LARGE_SIZE_FILE_TITLE,
        text: NOTIFICATION_LARGE_SIZE_FILE_TEXT,
        variant: VARIANT_UI.danger,
      });
    } else {
      handleUploadFile(formData);
    }
  };

  const renderOperations = () => {
    return (
      <Droppable
        droppableId={`${DND_TYPE.operation}${stepId}`}
        type={`${stepIndex}`}>
        {(providedDroppable, snapshotDroppableOperation) => (
          <div ref={providedDroppable.innerRef} className={st.operationList}>
            {operations.map((operation, i) => {
              const operationNumber = i + 1;
              const operationId = operation.id;

              return (
                <Draggable
                  key={`${stepId}${operationId}`}
                  draggableId={`${stepId}${operationId}`}
                  index={i}
                  isDragDisabled={isDisabledControl}>
                  {(providedOperation, snapshotOperation) => (
                    <AdminTemplateOperation
                      currentRef={providedOperation.innerRef}
                      provided={providedOperation}
                      isEdit={updaingOperationIds.includes(operationId)} // ! operation.id is UUID
                      operation={operation}
                      operationNumber={operationNumber}
                      handleAddUpdating={handleAddUpdating}
                      handleUpdating={handleUpdating}
                      operationIndex={i}
                      operationId={operationId}
                      snapshotOperation={snapshotOperation}
                      phaseType={phaseType}
                      stepId={stepId}
                      stepIndex={stepIndex}
                      placeholderProps={placeholderProps}
                      isDisabledControl={isDisabledControl}
                    />
                  )}
                </Draggable>
              );
            })}
            {providedDroppable.placeholder}
            <DragCustomPlaceholder
              placeholderProps={placeholderProps}
              type={DND_TYPE.operation}
              isDraggingOver={snapshotDroppableOperation.isDraggingOver}
            />
          </div>
        )}
      </Droppable>
    );
  };

  return (
    <div
      ref={currentRef}
      {...provided.draggableProps}
      className={st.holderStep}>
      <div
        className={cn(st.container, {
          [st.stepDragging]: snapshotStep.isDragging,
        })}
        onMouseLeave={() => setShowHideContent(false)}
        onMouseMove={() => {
          if (!showHideContent) {
            setShowHideContent(true);
          }
        }}>
        <div
          className={cn(st.content, {
            [st.contentEmptyOperations]: operations.length === 0,
          })}>
          {isEditTitleStep ? (
            <>
              <AdminTemplateInput
                initialValue={name}
                handleSubmit={onChangeTitleStep}
                titleAcceptBtn='Save'
                phaseType={phaseType}
                isChangeButtons={false}
              />
              <span {...provided.dragHandleProps} />
            </>
          ) : (
            <div
              className={st.headerContainer}
              onDoubleClick={() => {
                if (!isDisabledControl) {
                  setIsEditTitleStep(true);
                }
              }}>
              <h4 className={st.title} {...provided.dragHandleProps}>
                Step {stepNumber}: {name}
              </h4>
              {!isDisabledControl && (
                <div className={st.headerAction}>
                  <DropdownButton
                    list={dropdownListHeader}
                    className={st.moreButtonWrapper}
                    classNameBtn={st.moreButton}
                    startIcon={<MoreVertIcon />}
                    btnVariant={VARIANT_OUTLINE_UI.secondary}
                    arrowNone
                  />
                </div>
              )}
            </div>
          )}

          {operations?.length > 0 && renderOperations()}
          {operations?.length === 0 && !isNewOperation && (
            <div className={st.holderAlert}>
              <Alert variant='warning'>
                Add an operation in order to save template
              </Alert>
            </div>
          )}

          {isNewOperation && (
            <AdminTemplateInput
              handleSubmit={handleAddNewOperation}
              titleAcceptBtn='Add'
              phaseType={phaseType}
            />
          )}
          <WrapperDroppableDelete
            isDisabledControl={isDisabledControl}
            stepIndex={stepIndex}
            isDrag={isDragOperation || isDragFile || isDragRequireFile}
            dragComponent={() => (
              <div className={st.containerDroppableDelete}>
                <TrashIcon className={st.trashIcon} />
              </div>
            )}
            component={() => (
              <div className={st.buttonGroup}>
                <Button
                  variant={VARIANT_OUTLINE_UI.primary}
                  onClick={() => setIsNewOperation(true)}
                  disabled={isNewOperation}
                  size='sm'>
                  Add Operation
                </Button>
                {templateId ? (
                  <AttachFilesButton
                    handleChange={onUploadFile(FILE_TYPES.attachment)}
                    title='Add Image'
                    size='sm'
                    varinant={VARIANT_OUTLINE_UI.primary}
                    accept={FILE_ACCEPT_IMAGE_AND_PDF}
                    disabled={isDisabledAddImageBtn || isLoadingFile}
                  />
                ) : (
                  <ModalButton
                    titleAcceptBtn='OK'
                    title='Save Changes'
                    bodyContent={() => {
                      return (
                        <div>
                          <Text variant='normal'>
                            Please save the template in order to attach files
                          </Text>
                        </div>
                      );
                    }}
                    buttonBottomRight={({ onHide }) => (
                      <Button onClick={onHide}>OK</Button>
                    )}
                    buttonRenderer={({ onClick }) => (
                      <Button
                        variant={VARIANT_OUTLINE_UI.primary}
                        onClick={onClick}
                        disabled={isDisabledAddImageBtn || isLoadingFile}
                        size='sm'>
                        Add Image
                      </Button>
                    )}
                  />
                )}

                {isShowControlValues && (
                  <DropdownButtonControlOpen
                    title='Require'
                    list={dropdownListRequire}
                    size='sm'
                    btnVariant={VARIANT_OUTLINE_UI.primary}
                  />
                )}
              </div>
            )}
          />
          {isShowControlValues && (
            <ControlValuesStep
              controlValues={bundleId}
              handleUpdateControlValues={handleUpdateControlValues}
              stepIndex={stepIndex}
              informalQa={informalQa}
              handleUpdateInformalQa={handleUpdateInformalQa}
              isDisabledControl={isDisabledControl}
            />
          )}
        </div>

        {!isDraggingOver && showHideContent && !isDisabledControl && (
          <div className={st.hiddenContent}>
            <div className={st.hiddenLine} />
            <Button
              variant={VARIANT_OUTLINE_UI.primary}
              onClick={() => handleAddStep(phaseName, stepNumber - 1)}>
              <PlusCircle className={st.hiddenButtonSvg} />
              Add Step
            </Button>

            <div className={st.hiddenLine} />
          </div>
        )}
      </div>

      <Modal
        title='Requiring Control Values'
        show={showModalHasControlValues}
        setShow={setShowModalHasControlValues}
        buttonBottomRight={({ onClose }) => (
          <>
            <Button onClick={onClose}>Okay</Button>
          </>
        )}>
        <Text variant='normal'>
          You can add only one bundle of Control Values to the step
        </Text>
      </Modal>

      <Modal
        title='Requiring Control Values'
        show={showModalNoneControlValues}
        setShow={setShowModalNoneControlValues}
        buttonBottomRight={({ onClose }) => (
          <>
            <Button onClick={onClose}>Okay</Button>
          </>
        )}>
        <Text variant='normal'>
          In order to require Control Values, the shoud be added as a bundle by
          Admin
        </Text>
      </Modal>
    </div>
  );
}

export default AdminTemplateStep;
