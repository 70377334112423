import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import { handleRequestError } from 'services/api/apiTools';
import AuthService from 'services/auth/AuthService';
import { store } from 'store/store';
import ApiService from 'services/api';
import { getBlobUrlForFile } from 'services/api/files';
import { getFileName } from 'helpers/file';
import * as actions from './actions';

export function* getFileSaga(action) {
  const { path, download, fileName } = action.payload;
  let requestValueGetFiles;
  let requestValueDownloadFile;

  const filesUrlObject = yield select(
    (state) => state?.files?.files?.dataUrl
  ) ?? {};

  Object.values(filesUrlObject).forEach((item) => {
    return URL.revokeObjectURL(item);
  });

  const isClient = AuthService.isClient();

  const currentApi = isClient
    ? ApiService.downloadClientBase64File
    : ApiService.downloadBase64File;

  try {
    requestValueDownloadFile = yield call(currentApi, path);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getFile.failure());
    return;
  }
  const url = `data:${requestValueDownloadFile.headers['content-type']};base64,${requestValueDownloadFile.data}`;
  const fileBlob = yield call(() => getBlobUrlForFile(url));
  const dataUrl = { [path]: fileBlob };

  if (download) {
    const currentFileName =
      fileName || getFileName(path) || fileBlob.split('/').pop();
    const a = document.createElement('a');
    a.href = fileBlob;
    a.download = currentFileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    return;
  }

  return store.dispatch(
    actions.getFile.success({
      dataUrl,
      data: requestValueGetFiles,
    })
  );
}

export function* filesSagas() {
  yield all([yield takeLatest(actions.getFile.start, getFileSaga)]);
}
