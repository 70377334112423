import React from 'react';
import * as Yup from 'yup';
import { Button, Form } from 'react-bootstrap';
import Offcanvas from 'componentsShared/Offcanvas/Offcanvas';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { VARIANT_UI } from 'constants/ui';
import { func, bool, oneOfType, number, string } from 'prop-types';
import OffcanvasCustomerEditContent from 'components/Offcanvas/OffcanvasCustomerEditContent/OffcanvasCustomerEditContent';
import Text from 'componentsShared/Text/Text';
import * as customersActions from 'store/customers/actions';
import st from './OffcanvasCustomerCreate.module.scss';

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Required').email(),
  name: Yup.string().required('Required'),
  isInvite: Yup.boolean(),
});

OffcanvasCustomerCreate.propTypes = {
  companyId: oneOfType([number, string]),
  showOffcanvas: bool,
  setShowOffcanvas: func,
  setSelectCompanyId: func,
};

function OffcanvasCustomerCreate({
  companyId,
  showOffcanvas = false,
  setShowOffcanvas = () => {},
  setSelectCompanyId = () => {},
}) {
  const dispatch = useDispatch();

  const companyName =
    useSelector(
      (state) => state?.customers?.customers?.data?.[companyId]?.name
    ) ?? '';

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      isInvite: true,
    },
  };
  const { register, formState, handleSubmit, reset } = useForm(formOptions);
  const { errors, isDirty } = formState;

  const onSubmit = (data, onClose) => {
    const validData = { ...data, companyId };
    dispatch(
      customersActions.createCustomerLogin.start({
        data: validData,
        onCloseModal: onClose,
      })
    );
    reset();
  };

  return (
    <Offcanvas
      backdrop={false}
      onHide={() => {
        setShowOffcanvas(false);
        setSelectCompanyId(undefined);
      }}
      width={460}
      show={showOffcanvas}
      setShow={setShowOffcanvas}
      title='New Company Representative'
      footerContent={({ onHide }) => (
        <div className={st.buttonGroup}>
          <div className={st.formGroupInvite}>
            <Form.Check {...register('isInvite')} />
            <Text variant='normal' className={st.labelCheckInvite}>
              Send an invitation email to new Representative
            </Text>
          </div>

          <Button
            variant={VARIANT_UI.primary}
            type='submit'
            disabled={!isDirty}
            onClick={handleSubmit((value) => onSubmit(value, onHide))}>
            Create Representative
          </Button>
        </div>
      )}>
      <OffcanvasCustomerEditContent
        isCreate
        companyId={companyId}
        onClose={() => setShowOffcanvas(false)}
        onSubmit={handleSubmit(onSubmit)}
        register={register}
        errors={errors}
        companyName={companyName}
      />
    </Offcanvas>
  );
}

export default OffcanvasCustomerCreate;
