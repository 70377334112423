import { createReducer } from '@reduxjs/toolkit';
import { deepClone } from 'helpers/functions';

import * as actions from './actions';

export const initialState = {
  jobs: {
    data: {},
    sortIds: [],
    isLoading: false,
    isLoadingList: false,
    isLoadingReject: false,
    assignedIds: [],
    availableIds: [],
    needsQAIds: [],
    ncrIds: [],
    assignedQuantity: 0,
    availableQuantity: 0,
    needsQAQuantity: 0,
    interprocessQuantity: 0,
    ncrQuantity: 0,
    interprocessIds: [],
    isNeedsQA: null,
  },
  getJobSummary: {
    sortIds: [],
    availableIds: [],
    unavailableIds: [],
    isLoading: false,
  },
  checkJob: {
    jobId: null,
    isLoadingCheckJob: false,
    typeError: '',
    jobIdPhone: null,
  },
  filterValue: {
    search: '',
    isLoading: false,
  },
  files: {
    data: {},
    dataUrl: {},
    isLoading: false,
    loadFileName: '',
  },
  jobsQr: {
    isLoading: false,
  },
  reasonRejectJob: {
    data: [],
    isLoading: false,
  },
  jobsBatch: {
    isCreateBatch: false,
    selectJobIdList: [],
    showButtonBatch: false,
    data: {},
    sortIdsList: [],
  },
  isScrollJob: false,
  scrollJobId: '',
};

export const jobsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.submitJob.start, (state) => ({
      ...state,
      jobs: { ...state.jobs, isLoading: true },
    }))
    .addCase(actions.submitJob.success, (state, action) => {
      const { data, isNeedsQA } = action.payload;
      return {
        ...state,
        jobs: {
          ...state.jobs,
          data,
          isLoading: false,
          isNeedsQA: isNeedsQA || null,
        },
      };
    })
    .addCase(actions.submitJob.failure, (state) => ({
      ...state,
      jobs: { ...state.jobs, isLoading: false },
    }))
    .addCase(actions.takeJob.start, (state) => ({
      ...state,
      jobs: { ...state.jobs, isLoading: true },
    }))
    .addCase(actions.takeJob.success, (state) => ({
      ...state,
      jobs: {
        ...state.jobs,
        isLoading: false,
      },
    }))
    .addCase(actions.takeJob.failure, (state) => ({
      ...state,
      jobs: { ...state.jobs, isLoading: false },
    }))
    .addCase(actions.getJob.start, (state, action) => {
      const { filter } = action?.payload || {};

      return {
        ...state,
        jobs: { ...state.jobs, isLoading: true },
        filterValue: {
          ...state.filterValue,
          isLoading: !!filter,
        },
      };
    })
    .addCase(actions.getJob.success, (state, action) => {
      const { id, data } = action.payload;

      return {
        ...state,
        jobs: {
          ...state.jobs,
          data,
          sortIds: [id],
          isLoading: false,
        },
        filterValue: {
          ...state.filterValue,
          isLoading: false,
        },
      };
    })
    .addCase(actions.getJob.failure, (state) => ({
      ...state,
      jobs: { ...state.jobs, data: {}, sortIds: [], isLoading: false },
      filterValue: {
        ...state.filterValue,
        isLoading: false,
      },
    }))
    .addCase(actions.rejectJob.start, (state) => ({
      ...state,
      jobs: { ...state.jobs, isLoadingReject: true },
    }))
    .addCase(actions.rejectJob.success, (state, action) => {
      const { rejectIds, isBatchJob } = action.payload;
      const sortIdsList = deepClone(state?.jobsBatch?.sortIdsList);
      let currentSortIdsList;

      if (isBatchJob) {
        currentSortIdsList = sortIdsList
          .map((ids) => ids.filter((id) => !rejectIds.includes(id.toString())))
          .filter((item) => item.length > 0);
      } else {
        currentSortIdsList = sortIdsList;
      }

      return {
        ...state,
        jobs: {
          ...state.jobs,
          isLoadingReject: false,
        },
        jobsBatch: {
          ...state.jobsBatch,
          sortIdsList: currentSortIdsList,
        },
      };
    })
    .addCase(actions.rejectJob.failure, (state) => ({
      ...state,
      jobs: { ...state.jobs, isLoadingReject: false },
    }))
    .addCase(actions.changeFilterSearch, (state, action) => {
      const currentValue = action.payload;
      return {
        ...state,
        filterValue: {
          ...state.filterValue,
          search: currentValue,
        },
      };
    })
    .addCase(actions.approveJobQA.start, (state) => ({
      ...state,
      jobs: { ...state.jobs, isLoading: true },
    }))
    .addCase(actions.approveJobQA.success, (state) => {
      return {
        ...state,
        jobs: {
          ...state.jobs,
          isLoading: false,
        },
      };
    })
    .addCase(actions.approveJobQA.failure, (state) => ({
      ...state,
      jobs: { ...state.jobs, isLoading: false },
    }))
    .addCase(actions.takeJobQA.start, (state) => ({
      ...state,
      jobs: { ...state.jobs, isLoading: true },
    }))
    .addCase(actions.takeJobQA.success, (state) => ({
      ...state,
      jobs: {
        ...state.jobs,
        isLoading: false,
      },
    }))
    .addCase(actions.takeJobQA.failure, (state) => ({
      ...state,
      jobs: { ...state.jobs, isLoading: false },
    }))
    .addCase(actions.cancelJob.start, (state) => ({
      ...state,
      jobs: { ...state.jobs, isLoading: true },
    }))
    .addCase(actions.cancelJob.success, (state) => ({
      ...state,
      jobs: {
        ...state.jobs,
        isLoading: false,
      },
    }))
    .addCase(actions.cancelJob.failure, (state) => ({
      ...state,
      jobs: { ...state.jobs, isLoading: false },
    }))
    .addCase(actions.addFileInterprocessQA.start, (state, action) => {
      const { fileName } = action.payload;

      return {
        ...state,
        files: { ...state.files, isLoading: true, loadFileName: fileName },
      };
    })
    .addCase(actions.addFileInterprocessQA.success, (state) => {
      return {
        ...state,
        files: { ...state.files, isLoading: false },
      };
    })
    .addCase(actions.addFileInterprocessQA.failure, (state) => ({
      ...state,
      files: { ...state.files, isLoading: false },
    }))
    .addCase(actions.getFileInterprocessQA.start, (state) => ({
      ...state,
      files: { ...state.files, isLoading: true },
    }))
    .addCase(actions.getFileInterprocessQA.success, (state, action) => {
      const { data } = action.payload;
      return {
        ...state,
        files: { ...state.files, data, isLoading: false },
      };
    })
    .addCase(actions.getFileInterprocessQA.failure, (state) => ({
      ...state,
      files: { ...state.files, isLoading: false },
    }))
    .addCase(actions.getJobOperationFile.start, (state, action) => {
      const { path } = action.payload;

      return {
        ...state,
        files: {
          ...state.files,
          dataUrl: { ...state.files.dataUrl, [path]: { load: true } },
        },
      };
    })
    .addCase(actions.getJobOperationFile.success, (state, action) => {
      const { dataUrl } = action.payload;

      return {
        ...state,
        files: {
          ...state.files,
          dataUrl: { ...state.files.dataUrl, ...dataUrl },
        },
      };
    })
    .addCase(actions.getJobOperationFile.failure, (state) => ({
      ...state,
      files: { ...state.files },
    }))
    .addCase(actions.clearJobFileList, (state) => ({
      ...state,
      files: {
        ...state.files,
        dataUrl: {},
        data: {},
      },
    }))
    .addCase(actions.removeFileInterprocessQA.start, (state) => ({
      ...state,
      files: { ...state.files, isLoading: true },
    }))
    .addCase(actions.removeFileInterprocessQA.success, (state) => {
      return {
        ...state,
        files: { ...state.files, isLoading: false },
      };
    })
    .addCase(actions.removeFileInterprocessQA.failure, (state) => ({
      ...state,
      files: { ...state.files, isLoading: false },
    }))
    .addCase(actions.getJobList.start, (state, action) => {
      const { filter } = action?.payload || {};

      return {
        ...state,
        jobs: { ...state.jobs, isLoading: true, isLoadingList: true },
        filterValue: {
          ...state.filterValue,
          isLoading: !!filter,
        },
      };
    })
    .addCase(actions.getJobList.success, (state, action) => {
      const currentValue = action.payload;

      return {
        ...state,
        jobs: {
          ...state.jobs,
          ...currentValue,
          isLoading: false,
          isLoadingList: false,
        },
        filterValue: {
          ...state.filterValue,
          isLoading: false,
        },
      };
    })
    .addCase(actions.getJobList.failure, (state) => ({
      ...state,
      jobs: { ...state.jobs, isLoading: false, isLoadingList: false },
      filterValue: {
        ...state.filterValue,
        isLoading: false,
      },
    }))
    .addCase(actions.moveJobToStep.start, (state) => ({
      ...state,
      files: { ...state.files, isLoading: true },
    }))
    .addCase(actions.moveJobToStep.success, (state) => {
      return {
        ...state,
        files: { ...state.files, isLoading: false },
      };
    })
    .addCase(actions.moveJobToStep.failure, (state) => ({
      ...state,
      files: { ...state.files, isLoading: false },
    }))
    .addCase(actions.checkJob.start, (state) => {
      return {
        ...state,
        checkJob: { ...state.checkJob, isLoadingCheckJob: true },
      };
    })
    .addCase(actions.checkJob.success, (state, action) => {
      const { jobId } = action.payload;

      return {
        ...state,
        checkJob: {
          ...state.checkJob,
          jobId,
          isLoadingCheckJob: false,
        },
      };
    })
    .addCase(actions.checkJob.failure, (state, action) => {
      const { error } = action.payload;

      return {
        ...state,
        checkJob: {
          ...state.checkJob,
          isLoadingCheckJob: false,
          typeError: error,
        },
      };
    })
    .addCase(actions.clearErrorCheckJob, (state) => {
      return {
        ...state,
        checkJob: {
          ...state.checkJob,
          typeError: '',
        },
      };
    })
    .addCase(actions.clearJobIdCheckJob, (state) => {
      return {
        ...state,
        checkJob: {
          ...state.checkJob,
          jobId: null,
        },
      };
    })
    .addCase(actions.getJobQr.start, (state) => {
      return {
        ...state,
        jobsQr: { ...state.jobsQr, isLoading: true },
      };
    })
    .addCase(actions.getJobQr.success, (state) => {
      return {
        ...state,
        jobsQr: {
          ...state.jobsQr,
          isLoading: false,
        },
      };
    })
    .addCase(actions.getJobQr.failure, (state) => {
      return {
        ...state,
        jobsQr: {
          ...state.jobsQr,
          isLoading: false,
        },
      };
    })
    .addCase(actions.getJobSeriesQr.start, (state) => {
      return {
        ...state,
        jobsQr: { ...state.jobsQr, isLoading: true },
      };
    })
    .addCase(actions.getJobSeriesQr.success, (state) => {
      return {
        ...state,
        jobsQr: {
          ...state.jobsQr,
          isLoading: false,
        },
      };
    })
    .addCase(actions.getJobSeriesQr.failure, (state) => {
      return {
        ...state,
        jobsQr: {
          ...state.jobsQr,
          isLoading: false,
        },
      };
    })
    .addCase(actions.getJobSummary.start, (state) => {
      return {
        ...state,
        getJobSummary: { ...state.getJobSummary, isLoading: true },
      };
    })
    .addCase(actions.getJobSummary.success, (state, action) => {
      const { availableIds, unavailableIds, sortIds } = action.payload;

      return {
        ...state,
        getJobSummary: {
          ...state.getJobSummary,
          availableIds,
          unavailableIds,
          sortIds,
          isLoading: false,
        },
      };
    })
    .addCase(actions.getJobSummary.failure, (state) => {
      return {
        ...state,
        getJobSummary: {
          ...state.getJobSummary,
          isLoading: false,
        },
      };
    })
    .addCase(actions.submitJobNCR.start, (state) => ({
      ...state,
      jobs: { ...state.jobs, isLoading: true },
    }))
    .addCase(actions.submitJobNCR.success, (state) => {
      return {
        ...state,
        jobs: {
          ...state.jobs,
          isLoading: false,
        },
      };
    })
    .addCase(actions.submitJobNCR.failure, (state) => ({
      ...state,
      jobs: { ...state.jobs, isLoading: false },
    }))
    .addCase(actions.changeJobIdPhone, (state, action) => {
      const { qr } = action?.payload || {};
      const currentQR = qr || null;

      return {
        ...state,
        checkJob: { ...state.checkJob, jobIdPhone: currentQR },
      };
    })
    .addCase(actions.getReasonRejectJob.start, (state) => ({
      ...state,
      reasonRejectJob: { ...state.reasonRejectJob, isLoading: true },
    }))
    .addCase(actions.getReasonRejectJob.success, (state, action) => {
      const { data } = action.payload;

      return {
        ...state,
        reasonRejectJob: {
          ...state.reasonRejectJob,
          isLoading: false,
          data,
        },
      };
    })
    .addCase(actions.getReasonRejectJob.failure, (state) => ({
      ...state,
      reasonRejectJob: { ...state.reasonRejectJob, isLoading: false },
    }))
    .addCase(actions.changeIsCreateBatch, (state, action) => {
      const value = action?.payload;

      return {
        ...state,
        jobsBatch: { ...state.jobsBatch, isCreateBatch: !!value },
      };
    })
    .addCase(actions.changeSelectJobIdList, (state, action) => {
      const jobId = action?.payload;
      const selectJobIdList = [...(state.jobsBatch.selectJobIdList || [])];

      const currentSelectJobIdList = selectJobIdList.includes(jobId)
        ? selectJobIdList.filter((item) => item !== jobId)
        : [...selectJobIdList, jobId];

      return {
        ...state,
        jobsBatch: {
          ...state.jobsBatch,
          selectJobIdList: currentSelectJobIdList,
        },
      };
    })
    .addCase(actions.clearSelectJobIdList, (state) => {
      return {
        ...state,
        jobsBatch: {
          ...state.jobsBatch,
          selectJobIdList: [],
        },
      };
    })
    .addCase(actions.changeShowButtonBatch, (state, action) => {
      const value = action?.payload;

      return {
        ...state,
        jobsBatch: {
          ...state.jobsBatch,
          showButtonBatch: !!value,
        },
      };
    })
    .addCase(actions.clearBatchData, (state) => {
      return {
        ...state,
        jobsBatch: {
          ...state.jobsBatch,
          data: {},
        },
      };
    })
    .addCase(actions.takeBatchJob.start, (state) => {
      return {
        ...state,
        jobs: {
          ...state.jobs,
          isLoading: true,
        },
      };
    })
    .addCase(actions.takeBatchJob.success, (state, action) => {
      const sortIdsList = action?.payload;

      return {
        ...state,
        jobs: {
          ...state.jobs,
          isLoading: false,
        },
        jobsBatch: {
          ...state.jobsBatch,
          sortIdsList,
        },
      };
    })
    .addCase(actions.takeBatchJob.failure, (state) => {
      return {
        ...state,
        jobs: {
          ...state.jobs,
          isLoading: false,
        },
      };
    })

    .addCase(actions.getBatchJob.start, (state) => {
      return {
        ...state,
        jobs: {
          ...state.jobs,
          isLoading: true,
        },
      };
    })
    .addCase(actions.getBatchJob.success, (state, action) => {
      const data = action?.payload;

      return {
        ...state,
        jobs: {
          ...state.jobs,
          isLoading: false,
        },
        jobsBatch: {
          ...state.jobsBatch,
          data,
        },
      };
    })
    .addCase(actions.getBatchJob.failure, (state) => {
      return {
        ...state,
        jobs: {
          ...state.jobs,
          isLoading: false,
        },
      };
    })

    .addCase(actions.submitBatchJob.start, (state) => ({
      ...state,
      jobs: { ...state.jobs, isLoading: true },
    }))
    .addCase(actions.submitBatchJob.success, (state, action) => {
      const { data, sortIds } = action.payload;
      return {
        ...state,
        jobs: {
          ...state.jobs,
          isLoading: false,
        },
        jobsBatch: {
          ...state.jobsBatch,
          data,
          sortIdsList: sortIds,
        },
      };
    })
    .addCase(actions.submitBatchJob.failure, (state) => ({
      ...state,
      jobs: { ...state.jobs, isLoading: false },
    }))
    .addCase(actions.clearLoadFileName, (state) => ({
      ...state,
      files: { ...state.files, loadFileName: '' },
    }))
    .addCase(actions.changeIsScrollJob, (state, action) => {
      const value = action.payload || false;

      return {
        ...state,
        isScrollJob: value,
      };
    })
    .addCase(actions.changeScrollJobId, (state, action) => {
      const value = action.payload || '';

      return {
        ...state,
        scrollJobId: value,
      };
    });
});
