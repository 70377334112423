import React from 'react';
import CenteredColumnLayout from 'layouts/CenteredColumnLayout/CenteredColumnLayout';
import { useDispatch } from 'react-redux';
import ForgotPasswordForm from 'components/Form/ForgotPasswordForm/ForgotPasswordForm';
import Text from 'componentsShared/Text/Text';
import * as authActions from 'store/auth/actions';
import st from './ForgotPasswordPage.module.scss';

function ForgotPasswordPage() {
  const dispatch = useDispatch();

  const onSubmit = (data) => {
    dispatch(authActions.forgotPassword.start({ email: data.email }));
  };

  return (
    <CenteredColumnLayout showNavbar={false} showUserMenu={false}>
      <div className={st.container}>
        <div className={st.containerForm}>
          <h4 className={st.title}>Password Recovery</h4>
          <Text variant='normal' className={st.hintText}>
            Enter the email you use for strobels supply.com
          </Text>
          <ForgotPasswordForm onSubmit={onSubmit} />
        </div>
      </div>
    </CenteredColumnLayout>
  );
}

export default ForgotPasswordPage;
