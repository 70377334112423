import { ORDER_STATUS } from 'constants/order';
import { store } from 'store/store';

const OrderService = {
  getCurrentState() {
    return store.getState();
  },
  checkStaus(orderId) {
    const currentState = this.getCurrentState();
    const orderStatus =
      currentState.orders?.orders?.data?.[orderId]?.status ?? '';

    return orderStatus;
  },
  isStatusDraft(orderId) {
    const status = this.checkStaus(orderId);
    return status.toUpperCase() === ORDER_STATUS.DRAFT.toUpperCase();
  },
};

export default OrderService;
