import React, { memo } from 'react';
import cn from 'classnames';
import { JOBS_QA_TYPES, JOBS_TYPES } from 'constants/jobs';
import JobsCardWrapper from 'components/Jobs/JobsCardWrapper/JobsCardWrapper';
import { string, array, bool } from 'prop-types';
import AuthService from 'services/auth/AuthService';
import JobsCardQAWrapper from '../JobsCardQAWrapper/JobsCardQAWrapper';
import st from './JobsList.module.scss';

JobsList.propTypes = {
  ids: array,
  type: string,
  isPaddingBottom: bool,
};

function JobsList({
  ids = [],
  type,
  isPaddingBottom = true,
  finalIdsProgress = null,
}) {
  const isIncludeOnlyWorkerWithoutQA =
    AuthService.isIncludeOnlyWorkerWithoutQA();
  const isIncludeOnlyQAWithoutWorker =
    AuthService.isIncludeOnlyQAWithoutWorker();
  const isIncludeWorkerAndQA = AuthService.isIncludeWorkerAndQA();

  const isNeedsQA = type === JOBS_TYPES.needsQA;
  const isFinalQA = type === JOBS_QA_TYPES.finalQA;
  const isAssigned = type === JOBS_TYPES.assigned;
  const isAvailable = type === JOBS_TYPES.available;
  const isInterprocessQA = type === JOBS_QA_TYPES.interprocessQA;
  const isNCR = type === JOBS_QA_TYPES.ncr;

  const renderJobList = () => {
    return ids.map((jobId) => {
      if (
        isIncludeOnlyWorkerWithoutQA ||
        (isIncludeWorkerAndQA && (isAssigned || isAvailable))
      ) {
        return (
          <JobsCardWrapper
            key={jobId}
            jobId={jobId}
            isNeedsQA={isNeedsQA}
            type={type}
            isAssigned={isAssigned}
          />
        );
      }

      if (
        isIncludeOnlyQAWithoutWorker ||
        (isIncludeWorkerAndQA && (isInterprocessQA || isNCR))
      ) {
        const isFinalProgress =
          Array.isArray(finalIdsProgress) && finalIdsProgress.includes(jobId);

        return (
          <JobsCardQAWrapper
            key={jobId}
            jobId={jobId}
            isNCR={isNCR}
            isFinalQA={isFinalQA}
            isFinalProgress={isFinalProgress}
          />
        );
      }

      return null;
    });
  };

  if (ids.length === 0) {
    return null;
  }

  return (
    <div className={st.container}>
      <div
        className={cn({
          [st.paddingBottom]: isPaddingBottom,
        })}>
        {renderJobList()}
      </div>
    </div>
  );
}

export default memo(JobsList);
