// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AttachFilesButton_container__12uNc {\n  position: relative; }\n  .AttachFilesButton_container__12uNc .form-label {\n    margin-bottom: 0; }\n\n.AttachFilesButton_inputFile__v_D3W {\n  position: absolute;\n  opacity: 0;\n  z-index: 0; }\n", "",{"version":3,"sources":["webpack://src/components/AttachFilesButton/AttachFilesButton.module.scss"],"names":[],"mappings":"AAEA;EACE,kBAAkB,EAAA;EADpB;IAKM,gBAAgB,EAAA;;AAKtB;EACE,kBAAkB;EAClB,UAAU;EACV,UAAU,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.container {\n  position: relative;\n\n  :global {\n    .form-label {\n      margin-bottom: 0;\n    }\n  }\n}\n\n.inputFile {\n  position: absolute;\n  opacity: 0;\n  z-index: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AttachFilesButton_container__12uNc",
	"inputFile": "AttachFilesButton_inputFile__v_D3W"
};
export default ___CSS_LOADER_EXPORT___;
