import React, { useEffect } from 'react';
import CenteredColumnLayout from 'layouts/CenteredColumnLayout/CenteredColumnLayout';
import Loader from 'componentsShared/Loader/Loader';
import HeaderContent from 'componentsShared/HeaderContent/HeaderContent';
import * as jobsActions from 'store/jobs/actions';
import * as ncrActions from 'store/ncr/actions';
import { useSelector, useDispatch } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from 'react-router';
import { ROUTES } from 'router/routes';
import * as travelersActions from 'store/travelers/actions';
import NavigationService from 'services/navigation/NavigationService';
import ButtonBack from 'componentsShared/ButtonBack/ButtonBack';
import JobsNCR from 'components/Jobs/JobsNCR/JobsNCR';
import { getQuerySearchParams } from 'helpers/path';
import st from './JobDetailsNCRPage.module.scss';

const JobDetailsNCRPage = () => {
  const location = useLocation();
  const jobIdList = getQuerySearchParams(location.search, 'ids');
  const firstJobId = jobIdList[0];

  const dispatch = useDispatch();

  const currentJob =
    useSelector((state) => state?.jobs?.jobs?.data?.[firstJobId]) ?? {};
  const isLoadingJob =
    useSelector((state) => state?.jobs?.jobs?.isLoading) ?? false;
  const isLoadingNCR =
    useSelector((state) => state?.ncr?.ncr?.isLoading) ?? false;

  const { current_operation: currentOperation } = currentJob;

  const handleBackToMyJobs = () => {
    dispatch(jobsActions.changeIsScrollJob(true));
    NavigationService.replacePath(ROUTES.jobList.path);
  };

  useEffect(() => {
    dispatch(jobsActions.getJob.start({ id: firstJobId }));
    dispatch(travelersActions.getPhaseList.start());
  }, [dispatch, firstJobId]);

  useEffect(() => {
    dispatch(ncrActions.getNCRList.start());
  }, [dispatch]);

  return (
    <CenteredColumnLayout>
      <HeaderContent
        backBtn={
          <ButtonBack isCheckModal={false} onClick={handleBackToMyJobs} />
        }
        title='Non-Conformance Report'
      />
      <div className={st.mainContentHolder}>
        <JobsNCR jobIdList={jobIdList} jobId={firstJobId} />
        <Loader
          isVisible={isLoadingJob || isLoadingNCR || !currentOperation}
          isBackgroundColorGray
        />
      </div>
    </CenteredColumnLayout>
  );
};

export default JobDetailsNCRPage;
