import React from 'react';
import * as Yup from 'yup';
import { Button } from 'react-bootstrap';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { VARIANT_UI } from 'constants/ui';
import OffcanvasCompanyEditContent from 'components/Offcanvas/OffcanvasCompanyEditContent/OffcanvasCompanyEditContent';
import OffcanvasButton from 'componentsShared/OffcanvasButton/OffcanvasButton';
import * as customersActions from 'store/customers/actions';
import st from './OffcanvasCompanyCreate.module.scss';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
});

function OffcanvasCompanyCreate() {
  const dispatch = useDispatch();

  const isLoadingCustomer =
    useSelector((state) => state?.customers?.customers?.isLoading) ?? false;
  const isLoadingCreateCustomer =
    useSelector((state) => state?.customers?.customers?.isLoadingCreate) ??
    false;
  const isLoadingChangeCustomer =
    useSelector((state) => state?.customers?.customers?.isLoadingChange) ??
    false;

  const isLoading =
    isLoadingCustomer || isLoadingCreateCustomer || isLoadingChangeCustomer;

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };

  const { register, handleSubmit, formState, reset } = useForm(formOptions);
  const { errors, isDirty } = formState;

  const onSubmit = (data, onClose) => {
    const validData = { data };

    dispatch(
      customersActions.createCustomer.start({
        ...validData,
        onCloseModal: onClose,
      })
    );
  };

  return (
    <OffcanvasButton
      onHide={reset}
      backdrop={false}
      width={460}
      title='New Company'
      buttonRenderer={({ onClick }) => (
        <Button
          variant={VARIANT_UI.primary}
          onClick={onClick}
          disabled={isLoading}>
          New Company
        </Button>
      )}
      footerContent={({ onHide }) => (
        <div className={st.buttonGroup}>
          <Button
            variant={VARIANT_UI.primary}
            type='submit'
            disabled={!isDirty}
            onClick={handleSubmit((value) => onSubmit(value, onHide))}>
            Save Changes
          </Button>
        </div>
      )}
      bodyContent={() => (
        <OffcanvasCompanyEditContent
          onSubmit={handleSubmit(onSubmit)}
          register={register}
          errors={errors}
        />
      )}
    />
  );
}

export default OffcanvasCompanyCreate;
