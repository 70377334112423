import React from 'react';
import Text from 'componentsShared/Text/Text';
import { Form, FormControl, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { getValidPath } from 'helpers/path';
import { MAX_LENGTH_INPUT } from 'constants/ui';
import { Link } from 'react-router-dom';
import { ROUTES } from 'router/routes';
import * as Yup from 'yup';
import { reValidEmail } from 'constants/regulars';
import st from './ForgotPasswordForm.module.scss';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Required')
    .matches(reValidEmail, 'Invalid email'),
});

function ForgotPasswordForm({
  onSubmit = () => {},
  textRedirectLink = 'Login as a customer',
  hrefRedirectLink = ROUTES.signIn.path,
  handleClickRedirectLink = () => {},
}) {
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className={st.wrapperForm}>
      <div className={st.formGroup}>
        <div>
          <FormControl
            isInvalid={errors.email}
            {...register('email')}
            maxLength={MAX_LENGTH_INPUT}
          />
        </div>
        <Text variant='normal2' className={st.errorText}>
          {errors?.email?.message}
        </Text>
      </div>

      <Button type='submit' className={st.buttonLogin}>
        Send Reset Email
      </Button>

      <Link
        className={st.hrefLoginCustemer}
        onClick={handleClickRedirectLink}
        to={getValidPath(hrefRedirectLink)}>
        <Text variant='normal2' className={st.textLoginCustemer}>
          {textRedirectLink}
        </Text>
      </Link>
    </Form>
  );
}

export default ForgotPasswordForm;
