import React from 'react';
import ModalButton from 'componentsShared/ModalButton/ModalButton';
import Text from 'componentsShared/Text/Text';
import { VARIANT_OUTLINE_UI } from 'constants/ui';
import { Button } from 'react-bootstrap';
import st from './ModalJobNotes.module.scss';

const ModalJobNotes = ({
  notes = '',
  buttonVariant = VARIANT_OUTLINE_UI.secondary,
  buttonSize = 'md',
}) => {
  return (
    <ModalButton
      size='lg'
      title='Revision Notes'
      bodyContent={() => {
        return (
          <div>
            <Text variant='normal' className={st.modalText}>
              {notes}
            </Text>
          </div>
        );
      }}
      buttonBottomRight={({ onHide }) => (
        <Button variant={VARIANT_OUTLINE_UI.secondary} onClick={onHide}>
          Close
        </Button>
      )}
      buttonRenderer={({ onClick }) => (
        <Button
          size={buttonSize}
          variant={buttonVariant}
          onClick={onClick}
          disabled={!notes}>
          Notes
        </Button>
      )}
    />
  );
};

export default ModalJobNotes;
