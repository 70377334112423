import React, { useState } from 'react';
import Text from 'componentsShared/Text/Text';
import st from './JobsWorklogItemAdditionalText.module.scss';

function JobsWorklogItemAdditionalText({ additionalText, name }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className={st.wrapper}>
      <div className={st.containerInfo}>
        <div className={st.wrapperCVText}>
          <Text bold>{name}</Text>
        </div>

        <div
          className={st.containerArrowIcon}
          onClick={() => setIsOpen((prev) => !prev)}>
          <Text className={st.controlShowCV}>
            {isOpen ? 'Hide details' : 'See details'}
          </Text>
        </div>
      </div>

      {isOpen && (
        <div className={st.containerControlValues}>
          <Text variant='normal2'>{additionalText}</Text>
        </div>
      )}
    </div>
  );
}

export default JobsWorklogItemAdditionalText;
