import {
  CropOriginalFillIcon,
  CropOriginalIcon,
  FileFillIcon,
  FileIcon,
} from 'assets/icons';
import {
  FILE_TYPES,
  IMAGE_TYPE_ARRAY,
  TYPES_NAME_FILE_XLS_OR_DOC,
} from 'constants/files';

export const getIsPDFFile = (filePath = '') => {
  if (!filePath || typeof filePath !== 'string') {
    return false;
  }

  const fileArray = filePath.split('.');
  const fileName = fileArray[fileArray.length - 1];
  const isPDFFile = fileName.includes('pdf');

  return isPDFFile;
};

export const getTypeFile = (str) => {
  if (!str || typeof str !== 'string') {
    return str;
  }

  const strArray = str.split('/');

  if (strArray[strArray.length - 1] === FILE_TYPES.cover) {
    return FILE_TYPES.cover;
  }

  return str
    .match(/\.\w+$/i)[0]
    .replace('.', '')
    .toLowerCase();
};

export const getIconFile = (filePath, className, isFillIcon = false) => {
  const typeFile = getTypeFile(filePath);

  if (IMAGE_TYPE_ARRAY.includes(typeFile)) {
    if (isFillIcon) {
      return <CropOriginalFillIcon className={className} />;
    }
    return <CropOriginalIcon className={className} />;
  }
  if (isFillIcon) {
    return <FileFillIcon className={className} />;
  }
  return <FileIcon className={className} />;
};

export const getFileName = (fileName) => {
  if (!fileName || typeof fileName !== 'string') {
    return '';
  }

  const fileNameArray = fileName.split('/');
  const currentFileName = fileNameArray[fileNameArray.length - 1];
  return currentFileName;
};

export const getFileType = (fileName) => {
  if (!fileName || typeof fileName !== 'string') {
    return '';
  }

  const fileNameArray = fileName.split('.');
  return fileNameArray[fileNameArray.length - 1];
};

export const checkFileTypeDocOrXlsFromPath = (path) => {
  if (!path || typeof path !== 'string') {
    return '';
  }

  const type = getFileType(getFileName(path));

  return TYPES_NAME_FILE_XLS_OR_DOC.some((item) => item === type);
};

export const shortenedFileName = (
  fileName,
  countСharacter = 12,
  countDisplayСharacter = 5
) => {
  if (!fileName || typeof fileName !== 'string') {
    return '';
  }

  if (fileName.length <= countСharacter) {
    return fileName;
  }

  const fileNameArray = fileName.split('.');
  const fileType = fileNameArray[fileNameArray.length - 1];
  const name = fileName.replace(`.${fileType}`, '');
  const lastIndex = name.length - 1;

  let startName = '';
  let lastName = '';

  for (let i = 0; i <= countDisplayСharacter - 1; i += 1) {
    startName += fileName[i];
    lastName = name[lastIndex - i] + lastName;
  }

  return `${startName}...${lastName}.${fileType}`;
};
