import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import { VARIANT_OUTLINE_UI } from 'constants/ui';
import * as ordersActions from 'store/orders/actions';
import HeaderContent from 'componentsShared/HeaderContent/HeaderContent';
import OrderList from 'components/Order/OrderList/OrderList';
import Loader from 'componentsShared/Loader/Loader';
import st from './OrderListCustomer.module.scss';

function OrderListCustomer({
  dataList,
  ids,
  isCustomer,
  isProductionPOCard,
  handleClickJobCard,
  isLoading,
  isBackgroundColorGrayLoader,
  emptyText,
  searchEmptyText,
  isSearch,
  findByJob,
  showDataOrderAndJob,
  page,
  footer,
}) {
  const dispatch = useDispatch();

  const isLoadingOrders =
    useSelector((state) => state?.orders?.orders?.isLoading) ?? false;

  return (
    <div className={st.container}>
      <HeaderContent
        isActive
        componentActive={() => <></>}
        className={st.holderHeader}>
        <div className={st.containerActionsHeader}>
          <Button
            variant={VARIANT_OUTLINE_UI.secondary}
            onClick={() => dispatch(ordersActions.orderExpandAll())}>
            Expand All
          </Button>
          <Button
            variant={VARIANT_OUTLINE_UI.secondary}
            onClick={() => dispatch(ordersActions.orderCollapseAll())}>
            Collapse All
          </Button>
        </div>
      </HeaderContent>
      <div className={st.contentWrapper}>
        <OrderList
          classNameList={st.orderList}
          dataList={dataList}
          ids={ids}
          isCustomer={isCustomer}
          isProductionPOCard={isProductionPOCard}
          handleClickJobCard={handleClickJobCard}
          isLoading={isLoading}
          isBackgroundColorGrayLoader={isBackgroundColorGrayLoader}
          emptyText={emptyText}
          searchEmptyText={searchEmptyText}
          isSearch={isSearch}
          findByJob={findByJob}
          showDataOrderAndJob={showDataOrderAndJob}
          page={page}
        />
        <Loader isVisible={isLoadingOrders} />
      </div>
      {footer}
    </div>
  );
}

export default OrderListCustomer;
