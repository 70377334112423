import { DND_TYPE as DND_TYPE_TRAVELER } from 'constants/traveler';
import { DND_TYPE as DND_TYPE_CONTROL_VALUE } from 'constants/controlValue';

import { deepClone } from 'helpers/functions';

export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

export const reorderOperation = (step, startIndex, endIndex) => {
  const isControlValues =
    !!step?.bundle_id && typeof step?.bundle_idx === 'number';

  let currentControlValuesIdx = step?.bundle_idx;

  const currentOperations = [...step.operations];

  const [removed] = currentOperations.splice(startIndex, 1);
  currentOperations.splice(endIndex, 0, removed);

  if (isControlValues) {
    currentControlValuesIdx = currentOperations.findIndex(
      (item) => !!item?.isControlValues
    );
  }

  return {
    operations: currentOperations,
    controlValuesIdx: currentControlValuesIdx,
  };
};

export const reorderFile = (
  operations,
  startId,
  endId,
  fileName,
  fileNameIndex
) => {
  const operationList = deepClone(operations);
  const operationEndIndex = operationList.findIndex(
    ({ id }) => id.toString() === endId.toString()
  );
  const operationStartIndex = operationList.findIndex(
    ({ id }) => id.toString() === startId.toString()
  );
  const fileIndex = operationList[operationStartIndex]?.files?.findIndex(
    (itemFileName) => itemFileName === fileName
  );

  const [removeFileName] = operationList[operationStartIndex]?.files.splice(
    fileIndex,
    1
  );

  const isFindFileInOperationEnd = operationList[
    operationEndIndex
  ]?.files?.find((itemFileName) => itemFileName === fileName);

  if (!isFindFileInOperationEnd) {
    if (Array.isArray(operationList?.[operationEndIndex]?.files)) {
      operationList[operationEndIndex]?.files?.splice(
        fileNameIndex,
        0,
        removeFileName
      );
    } else {
      operationList[operationEndIndex].files = [removeFileName];
    }
  }

  return operationList;
};

export const reorderRequireFile = (operations, startId, endId) => {
  const currentStartId = startId.replace('/requireFile', '');
  const currentEndId = endId.replace('/requireFile', '');

  const operationList = deepClone(operations);
  const operationEndIndex = operationList.findIndex(
    ({ id }) => id.toString() === currentEndId.toString()
  );
  const operationStartIndex = operationList.findIndex(
    ({ id }) => id.toString() === currentStartId.toString()
  );

  operationList[operationStartIndex].require_file = false;
  operationList[operationEndIndex].require_file = true;

  return operationList;
};

export const deleteOperation = (step, startIndex) => {
  const bundleId = step?.bundle_id;
  const controlValuesIdx = step?.bundle_idx;

  const currentOperations = [...step.operations];

  const [removedItem] = currentOperations.splice(startIndex, 1);
  const isRemovedCV = !!removedItem?.isControlValues;

  const currentControlValuesIdx =
    controlValuesIdx && startIndex < controlValuesIdx
      ? controlValuesIdx - 1
      : controlValuesIdx;

  return {
    operations: currentOperations,
    bundleId: isRemovedCV ? null : bundleId,
    controlValuesIdx: isRemovedCV ? null : currentControlValuesIdx,
  };
};

export const deleteFile = (operations, startId, fileName) => {
  const operationList = deepClone(operations);
  const operationIndex = operationList.findIndex(
    ({ id }) => id.toString() === startId.toString()
  );

  const fileIndex = operationList[operationIndex].files.findIndex(
    (fileItem) => fileItem === fileName
  );

  operationList[operationIndex].files.splice(fileIndex, 1);

  return operationList;
};

export const deleteRequireFile = (operations, startId) => {
  const operationList = deepClone(operations);
  const operationIndex = operationList.findIndex(
    ({ id }) => id.toString() === startId.toString()
  );

  operationList[operationIndex].require_file = false;

  return operationList;
};

export const getDraggedDom = (draggableId) => {
  const queryAttr = 'data-rbd-draggable-id';
  const domQuery = `[${queryAttr}='${draggableId}']`;
  const draggedDOM = document.querySelector(domQuery);
  return draggedDOM;
};

export const getTypeDragTraveler = (type) => {
  switch (true) {
    case type.includes(DND_TYPE_TRAVELER.step):
      return DND_TYPE_TRAVELER.step;
    case type.includes(DND_TYPE_TRAVELER.file):
      return DND_TYPE_TRAVELER.file;
    case type.includes(DND_TYPE_CONTROL_VALUE.controlValues):
      return DND_TYPE_CONTROL_VALUE.controlValues;
    case type.includes(DND_TYPE_TRAVELER.requireFile):
      return DND_TYPE_TRAVELER.requireFile;
    default:
      return DND_TYPE_TRAVELER.operation;
  }
};

export const getDragStartPlaceholder = ({ draggedDOM, result }) => {
  const { clientHeight, clientWidth } = draggedDOM;
  const sourceIndex = result.source.index;

  const clientY =
    parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
    [...draggedDOM.parentNode.children]
      .slice(0, sourceIndex)
      .reduce((total, curr) => {
        const style = curr.currentStyle || window.getComputedStyle(curr);
        const marginBottom = parseFloat(style.marginBottom);
        return total + curr.clientHeight + marginBottom;
      }, 0);

  const type = getTypeDragTraveler(result.type);

  return {
    type,
    sourceDroppableId: result.source.droppableId.toString(),
    props: {
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode).paddingLeft
      ),
    },
  };
};

export const getDragUpdatePlaceholder = ({ draggedDOM, result }) => {
  const { clientHeight, clientWidth } = draggedDOM;
  const destinationIndex = result.destination.index;
  const sourceIndex = result.source.index;

  const childrenArray = [...draggedDOM.parentNode.children];
  const movedItem = childrenArray[sourceIndex];
  childrenArray.splice(sourceIndex, 1);

  const updatedArray = [
    ...childrenArray.slice(0, destinationIndex),
    movedItem,
    ...childrenArray.slice(destinationIndex + 1),
  ];

  const clientY =
    parseFloat(window.getComputedStyle(draggedDOM.parentNode).paddingTop) +
    updatedArray.slice(0, destinationIndex).reduce((total, curr) => {
      const style = curr.currentStyle || window.getComputedStyle(curr);
      const marginBottom = parseFloat(style.marginBottom);
      return total + curr.clientHeight + marginBottom;
    }, 0);

  const type = getTypeDragTraveler(result.type);

  return {
    type,
    sourceDroppableId: result.source.droppableId.toString(),
    props: {
      clientHeight,
      clientWidth,
      clientY,
      clientX: parseFloat(
        window.getComputedStyle(draggedDOM.parentNode).paddingLeft
      ),
    },
  };
};
