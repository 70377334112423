import React, { useMemo } from 'react';
import Text from 'componentsShared/Text/Text';
import { useSelector } from 'react-redux';
import FileItem from 'componentsShared/FileItem/FileItem';
import { VARIANT_OUTLINE_UI } from 'constants/ui';
import ModalJobNotes from 'components/Modal/ModalJobNotes/ModalJobNotes';
import st from './JobsBatchOperationContent.module.scss';

function JobsBatchOperationContent({
  showControlValues,
  operationName,
  fileList,
  ids = [],
  bundleId,
  stepName = '',
  phaseName = '',
  notes = '',
}) {
  const controlValuesName =
    useSelector(
      (state) => state?.controlValues?.controlValues?.data?.[bundleId]?.name
    ) ?? '';

  const renderFileItem = () => {
    return fileList.map((fileItem) => {
      const filePathArray = fileItem?.split('/') || [];
      const fileName = filePathArray?.[filePathArray.length - 1] || '';
      const typeFileArray = fileName?.split('.');
      const typeFile =
        typeFileArray?.[typeFileArray.length - 1] === 'pdf' ? 'pdf' : 'images';
      const dataFile = {
        path: fileItem,
        content_type: typeFile,
        name: fileName,
      };

      return (
        <FileItem
          key={`file-item-${fileItem}`}
          data={dataFile}
          showClearIcon={false}
          classNameFileText={st.classNameFileText}
        />
      );
    });
  };

  const jobInfo = useMemo(
    () => [
      {
        name: 'Job IDs:',
        text: ids.map(
          (item, index) => `${ids.length > 0 && index !== 0 ? ', ' : ''}${item}`
        ),
      },
      {
        name: 'Phase:',
        text: phaseName,
      },
      {
        name: 'Step:',
        text: stepName,
      },
      {
        name: 'Operation:',
        text: showControlValues ? (
          <>
            Provide Control Values:
            <br />
            {controlValuesName}
          </>
        ) : (
          operationName
        ),
      },
    ],
    [
      ids,
      phaseName,
      stepName,
      showControlValues,
      operationName,
      controlValuesName,
    ]
  );

  const renderJobInfo = () => {
    return jobInfo.map(({ name, text }) => {
      return (
        <div className={st.holderJobOperationItem}>
          <div className={st.holderInfoItemName}>
            <Text variant='normal' className={st.infoItemName}>
              {name}
            </Text>
          </div>
          <div>
            <Text variant='normal' className={st.infoItemText}>
              {text}
            </Text>
          </div>
        </div>
      );
    });
  };

  return (
    <div className={st.holderJobOperation}>
      <div className={st.holderJobOperationInfo}>
        <div className={st.holderJobOperationList}>{renderJobInfo()}</div>
      </div>
      <div className={st.holderJobOperationImg}>
        {Array.isArray(fileList) &&
          fileList.length > 0 &&
          !showControlValues &&
          renderFileItem()}
      </div>
      <div className={st.holderNotes}>
        <ModalJobNotes
          notes={notes}
          buttonSize='sm'
          buttonVariant={VARIANT_OUTLINE_UI.primary}
        />
      </div>
    </div>
  );
}

export default JobsBatchOperationContent;
