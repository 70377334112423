import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NoseIcon, TailIcon } from 'assets/icons';
import * as templatesActions from 'store/templates/actions';
import * as travelersActions from 'store/travelers/actions';
import cn from 'classnames';
import st from './TravalerTemplateBreadCrumbs.module.scss';
import TemplateDeletePhaseButton from '../TemplateDeletePhaseButton/TemplateDeletePhaseButton';
import TemplateAddPhaseButton from '../TemplateAddPhaseButton/TemplateAddPhaseButton';

function TravalerTemplateBreadCrumbs({
  phaseList = [],
  isEditTemplate = false,
  activePhase = '',
  setAreScrollToPhase = () => {},
  type = 'templates',
}) {
  const dispatch = useDispatch();
  const defaultPhase =
    useSelector((state) => state?.travelers?.phaseList?.data) ?? {};
  const newPhaseList =
    useSelector((state) => state?.[type]?.newPhaseList) ?? [];

  const handleScrollToPhase = (key) => {
    setAreScrollToPhase(key);
  };

  const handleUpdateNewPhase = (value) => {
    if (type === 'templates') {
      dispatch(templatesActions.updateNewPhaseList(value));
    } else {
      dispatch(travelersActions.updateNewPhaseList(value));
    }
  };

  const handleAddPhase = ({ phase, postfix, index }) => {
    if (newPhaseList.length === 0) {
      const defaultPhaseArray = Object.values(defaultPhase);
      defaultPhaseArray.splice(index, 0, {
        name: `${phase.name} ${postfix.trim()}`,
        type: phase.type,
        human_name: `${phase.human_name} ${postfix.trim()}`,
      });

      handleUpdateNewPhase(defaultPhaseArray);
      return;
    }

    const currentPhase = [...newPhaseList];
    currentPhase.splice(index, 0, {
      name: `${phase.name} ${postfix.trim()}`,
      type: phase.type,
      human_name: `${phase.human_name} ${postfix.trim()}`,
    });
    handleUpdateNewPhase(currentPhase);
  };

  const handleDeletePhase = (index) => {
    const currentPhase = [...newPhaseList];
    currentPhase.splice(index, 1);
    handleUpdateNewPhase(currentPhase);
  };

  const renderPhaseName = () => {
    return phaseList.map(
      ({ name, default: defaultPhaseType, human_name: humanName }, index) => {
        const isShowAddButton =
          isEditTemplate && index > 0 && index < phaseList.length - 1;

        return (
          <Fragment key={name}>
            <TemplateAddPhaseButton
              isShowAddButton={isShowAddButton}
              handleAddPhase={handleAddPhase}
              index={index}
              typeEntity={type}
            />
            <div
              className={cn(st.holderPhase, {
                [st.holderActivePhase]: activePhase === name,
              })}
              onClick={() => handleScrollToPhase(name)}>
              <TailIcon className={st.tailIcon} />
              <div className={st.phaseName}>
                {humanName}
                <TemplateDeletePhaseButton
                  isEditTemplate={isEditTemplate}
                  defaultPhase={defaultPhaseType}
                  handleDeletePhase={handleDeletePhase}
                  phaseName={name}
                  index={index}
                />
              </div>
              <NoseIcon className={st.noseIcon} />
            </div>
          </Fragment>
        );
      }
    );
  };

  return <div className={st.holderBreadCrumbs}>{renderPhaseName()}</div>;
}

export default TravalerTemplateBreadCrumbs;
