import React, { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as jobsActions from 'store/jobs/actions';
import JobsCard from 'components/Jobs/JobsCard/JobsCard';
import NavigationService from 'services/navigation/NavigationService';
import { getRoutePath } from 'helpers/path';
import { ROUTES } from 'router/routes';
import { bool, oneOfType, number, string } from 'prop-types';
import { JOB_ERROR_QR_CODE, JOBS_QA_TYPES } from 'constants/jobs';

JobsCardQAWrapper.propTypes = {
  jobId: oneOfType([number, string]),
  isFinalQA: bool,
  isFinalProgress: bool,
  isNCR: bool,
};

const getValidDataJob = (infoJob) => {
  const currentPhaseName =
    infoJob?.phases?.find((item) => item.name === infoJob?.current_step?.phase)
      ?.human_name || '';

  return {
    customerName: infoJob?.customer_name || '',
    partTypeName: infoJob?.part_type?.name || '',
    partTypeNumber: infoJob?.part_type?.number || '',
    partTypeRevision: infoJob?.part_type?.revision || '',
    partTypeRevisionNotes: infoJob?.part_type?.revision_notes || '',
    partId: infoJob?.part_type?.id,
    poNumber: infoJob?.po_number || '',
    currentPhaseName,
    currentPhase: infoJob?.current_step?.phase || '',
    currentStep: infoJob?.current_step?.name || '',
    currentOperation: infoJob?.current_operation?.name || '',
    quantity: infoJob.quantity,
  };
};

function JobsCardQAWrapper({
  jobId,
  isFinalQA = false,
  isFinalProgress = false,
  isNCR = false,
}) {
  const dispatch = useDispatch();

  const cuurentJob =
    useSelector((state) => state?.jobs?.jobs?.data?.[jobId]) ?? {};
  const jobAssignedIds =
    useSelector((state) => state?.jobs?.jobs?.assignedIds) ?? [];
  const jobAvailableIds =
    useSelector((state) => state?.jobs?.jobs?.availableIds) ?? [];
  const unavailableIdsJobIds =
    useSelector((state) => state?.jobs?.getJobSummary?.unavailableIds) ?? [];
  const checkedJobId =
    useSelector((state) => state?.jobs?.checkJob?.jobId) ?? null;

  const jobList = cuurentJob?.list || [];
  const jobIdList = jobList.map(({ id }) => id);
  const infoJob = jobList?.[0] || {};
  const jobLength = jobIdList?.length || 0;
  const jobData = getValidDataJob(infoJob);

  const jobSummaryInfo = {
    partDateils: {
      customerName: jobData.customerName,
      partTypeName: jobData.partTypeName,
      partTypeNumber: jobData.partTypeNumber,
      partTypeRevision: jobData.partTypeRevision,
      partTypeRevisionNotes: jobData.partTypeRevisionNotes,
    },
    partId: jobData.partId,
  };

  const [showModalSummary, setShowModalSummary] = useState(false);
  const [showModalQR, setShowModalQR] = useState(false);
  const [isErrorReaderQR, setIsErrorReaderQR] = useState(false);
  const [isError, setIsError] = useState(false);
  const [typeError, setTypeError] = useState(null);
  const [selectAvailableJob, setSelectAvailableJob] = useState([]);

  const jobStatus = `${jobData.currentPhaseName} / ${jobData.currentStep}`;

  const isAssigned = jobAssignedIds.includes(jobId);
  const isAvailable = jobAvailableIds.includes(jobId);

  const handleOpenModalSummary = (value) => {
    const arrayJobIds = isFinalProgress ? [...jobIdList] : [value];

    setShowModalSummary(true);
    setSelectAvailableJob(arrayJobIds);
  };

  const onCancelModal = () => {
    setIsErrorReaderQR(false);
    setIsError(false);
    setTypeError(null);
    setShowModalSummary(true);
  };

  const handleReaderError = () => {
    setIsErrorReaderQR(true);
  };

  const handleChangeTypeQR = () => {
    setIsError(false);
    setTypeError(null);
  };

  const handleReadQR = (url) => {
    const [, currentJobId] = url.split('/api/jobs/qr/');
    const numberJobIdQR = +currentJobId;
    if (jobIdList.includes(numberJobIdQR)) {
      setSelectAvailableJob((prev) => {
        const arrayJobId = prev.includes(numberJobIdQR) ? [] : [numberJobIdQR];
        return [...prev, ...arrayJobId];
      });

      setShowModalQR(false);
      setShowModalSummary(true);
      return;
    }

    setIsError(true);
    const error = unavailableIdsJobIds.includes(numberJobIdQR)
      ? JOB_ERROR_QR_CODE.isNotAvailable.type
      : JOB_ERROR_QR_CODE.notMatchSeries.type;

    setTypeError(error);
  };

  const handleAcceptBtn = () => {
    setShowModalSummary(false);
    dispatch(jobsActions.changeScrollJobId(selectAvailableJob?.[0] || ''));

    if (isNCR) {
      return NavigationService.navigateToPath(
        getRoutePath(ROUTES.jobDetailsNCR.path, null, {
          ids: selectAvailableJob,
        })
      );
    }
    if (!isFinalQA) {
      return NavigationService.navigateToPath(
        getRoutePath(ROUTES.jobDetailsQA.path, null, {
          ids: selectAvailableJob,
        })
      );
    }
    if (isAvailable) {
      return dispatch(
        jobsActions.takeJobQA.start({
          ids: selectAvailableJob,
        })
      );
    }
    if (isAssigned) {
      if (jobData.currentPhase === JOBS_QA_TYPES.finalQA) {
        return NavigationService.navigateToPath(
          getRoutePath(ROUTES.jobDetailsFinalQA.path, null, {
            ids: selectAvailableJob,
          })
        );
      }

      return NavigationService.navigateToPath(
        getRoutePath(ROUTES.jobDetails.path, null, {
          ids: selectAvailableJob,
        })
      );
    }
  };

  const modalTitleAcceptBtn = useMemo(() => {
    if (!isFinalQA || isAssigned) {
      return 'Continue';
    }
    if (isAvailable) {
      return 'Take Job';
    }
  }, [isFinalQA, isAssigned, isAvailable]);

  const changeSelectJobIdList = (id) => {
    dispatch(jobsActions.changeSelectJobIdList(id));
  };

  useEffect(() => {
    if (jobIdList.includes(checkedJobId)) {
      const currentJobIds = isAssigned ? jobIdList : [checkedJobId];
      setSelectAvailableJob(currentJobIds);
      setShowModalSummary(true);
      dispatch(jobsActions.clearJobIdCheckJob());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedJobId, jobIdList]);

  return (
    <JobsCard
      jobData={jobData}
      showModalQR={showModalQR}
      setShowModalQR={setShowModalQR}
      isErrorReaderQR={isErrorReaderQR}
      onCancelModal={onCancelModal}
      handleReaderError={handleReaderError}
      isError={isError}
      typeError={typeError}
      showModalSummary={showModalSummary}
      isProgressJob={isFinalProgress}
      setShowModalSummary={setShowModalSummary}
      selectAvailableJob={selectAvailableJob}
      modalTitleAcceptBtn={modalTitleAcceptBtn}
      handleOpenModalSummary={handleOpenModalSummary}
      handleAcceptBtn={handleAcceptBtn}
      jobStatus={jobStatus}
      quantityCard={`${jobLength} of ${jobData.quantity} pcs`}
      jobList={jobList}
      handleReadQR={handleReadQR}
      handleChangeTypeQR={handleChangeTypeQR}
      setSelectAvailableJob={setSelectAvailableJob}
      isInterprocessQA={!isFinalQA}
      isNCR={isNCR}
      changeSelectJobIdList={changeSelectJobIdList}
      jobId={jobId}
      jobSummaryInfo={jobSummaryInfo}
      isQA
    />
  );
}

export default memo(JobsCardQAWrapper);
