import React, { useEffect } from 'react';
import Truncate from 'react-truncate';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { ImageIcon } from 'assets/icons';
import { object } from 'prop-types';
import st from './CustomersCardManager.module.scss';

CustomersCardManager.propTypes = {
  customer: object,
};

const HOLDER_TEXT_WIDTH = 136;

function CustomersCardManager({ customer, onClickCard }) {
  const dispatch = useDispatch();

  const currentFileValue =
    useSelector((state) => state?.files?.files?.data?.[customer.img]) ?? {};

  const handleClick = () => {
    onClickCard(customer.id);
  };

  useEffect(() => {
    // dispatch(filesActions.getFile.start(customer.img)); //TODO: add getting a logo
  }, [dispatch, customer]);

  return (
    <div className={st.container} onClick={handleClick}>
      <div
        className={cn(st.holderImg, {
          [st.holderEmptyImg]: !currentFileValue.uri,
        })}>
        {currentFileValue.uri ? (
          <img
            src={currentFileValue.uri}
            alt='Customer img'
            className={st.customerImg}
          />
        ) : (
          <ImageIcon />
        )}
      </div>
      <div className={st.holderName}>
        <Truncate lines={3} width={HOLDER_TEXT_WIDTH} title={customer.name}>
          {customer.name}
        </Truncate>
      </div>
    </div>
  );
}

export default CustomersCardManager;
