// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TemplateConstructorWrapper_container__1XWRu {\n  overflow-y: auto;\n  padding: 16px; }\n", "",{"version":3,"sources":["webpack://src/components/TravalerTemplate/TemplateConstructorWrapper/TemplateConstructorWrapper.module.scss"],"names":[],"mappings":"AAEA;EACI,gBAAgB;EAChB,aAAa,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.container{\n    overflow-y: auto;\n    padding: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "TemplateConstructorWrapper_container__1XWRu"
};
export default ___CSS_LOADER_EXPORT___;
