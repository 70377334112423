import React from 'react';
import Text from 'componentsShared/Text/Text';
import cn from 'classnames';
import { FormControl } from 'react-bootstrap';
import { MAX_LENGTH_INPUT } from 'constants/ui';
import {
  number,
  string,
  oneOfType,
  shape,
  func,
  instanceOf,
  object,
  bool,
} from 'prop-types';
import st from './ControlValueItem.module.scss';

ControlValueItem.propTypes = {
  controlValueNumber: oneOfType([number, string]),
  value: string,
  currentRef: oneOfType([func, shape({ current: instanceOf(Element) })]),
  provided: object,
  snapshot: object,
  isDrag: bool,
  handleChangeValue: func,
  index: number,
};

function ControlValueItem({
  controlValueNumber,
  value,
  currentRef,
  provided,
  snapshot,
  isDrag,
  handleChangeValue,
  index,
}) {
  const handleChange = (e) => {
    const { value: valueInput } = e.target;
    handleChangeValue(valueInput, index);
  };

  return (
    <div ref={currentRef} {...provided.draggableProps}>
      <div
        className={cn(st.holderContent, {
          [st.holderContentDragging]: snapshot.isDragging && isDrag,
        })}>
        <div {...provided.dragHandleProps}>
          <Text variant='normal'>Parameter {controlValueNumber}</Text>
        </div>
        <div className={st.holderInput}>
          <FormControl
            value={value}
            onChange={handleChange}
            maxLength={MAX_LENGTH_INPUT}
          />
        </div>
      </div>
    </div>
  );
}

export default ControlValueItem;
