import React, { useState, memo, useMemo, useEffect } from 'react';
import { array } from 'prop-types';
import AuthService from 'services/auth/AuthService';
import { useDispatch } from 'react-redux';
import { JOBS_TYPES } from 'constants/jobs';
import * as jobsActions from 'store/jobs/actions';
import JobListWrapper from '../JobListWrapper/JobListWrapper';
import JobTabs, { JobTab } from '../JobTabs/JobTabs';
import st from './JobListTabs.module.scss';

JobListTabs.propTypes = {
  data: array,
};

function JobListTabs({
  data = [],
  isChangedTab = '',
  setIsChangedTab = () => {},
}) {
  const dispatch = useDispatch();

  const [changeSelectTab, setChangeSelectTab] = useState(null);

  const isIncludeOnlyWorkerWithoutQA =
    AuthService.isIncludeOnlyWorkerWithoutQA();

  const defaultActiveKey = useMemo(() => {
    if (isIncludeOnlyWorkerWithoutQA) {
      dispatch(jobsActions.changeShowButtonBatch(true));
      return JOBS_TYPES.available;
    }

    dispatch(jobsActions.changeShowButtonBatch(false));
    return data?.length > 0 ? data[0].type : '';
  }, [data, isIncludeOnlyWorkerWithoutQA, dispatch]);

  const handleSelect = (keyTab) => {
    dispatch(jobsActions.clearSelectJobIdList());
    dispatch(jobsActions.changeIsCreateBatch(false));

    const showButtonBatch =
      data.find(({ type }) => type === keyTab)?.showButtonBatch || false;

    dispatch(jobsActions.changeShowButtonBatch(showButtonBatch));
  };

  const handleClick = () => {
    dispatch(jobsActions.changeScrollJobId());
  };

  const renderJobTab = () => {
    return data.map((item) => {
      const { type, title } = item;

      return (
        <JobTab eventKey={type} title={title} key={type}>
          <div className={st.container}>
            <JobListWrapper cardValue={item} data={data} />
          </div>
        </JobTab>
      );
    });
  };

  useEffect(() => {
    if (isChangedTab) {
      setChangeSelectTab(isChangedTab);
      setIsChangedTab('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isChangedTab]);

  return (
    <JobTabs
      defaultActiveKey={defaultActiveKey}
      classNameContainerTabList={st.containerTabList}
      classNameWrapperTabList={st.wrapperTabList}
      classNameContainerContent={st.containerContent}
      changeSelectTab={changeSelectTab}
      setChangeSelectTab={setChangeSelectTab}
      onSelect={handleSelect}
      jobData={data}
      onClick={handleClick}>
      {data.length > 0 && renderJobTab()}
    </JobTabs>
  );
}

export default memo(JobListTabs);
