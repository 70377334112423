import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FILE_TYPES } from 'constants/files';
import Loader from 'componentsShared/Loader/Loader';
import * as partTypesActions from 'store/partTypes/actions';
import Text from 'componentsShared/Text/Text';
import JobsPreviewFile from 'components/Jobs/JobsPreviewFile/JobsPreviewFile';
import st from './ModalJobTechnicalDrawings.module.scss';

function ModalJobTechnicalDrawings({ partId }) {
  const dispatch = useDispatch();

  const filesPathList =
    useSelector(
      (state) => state?.partTypes?.files?.data?.[FILE_TYPES?.drawing]
    ) ?? [];

  const isLoadingFile =
    useSelector((state) => state?.partTypes?.files?.isLoading) ?? '';

  const loadFile = (path) => {
    dispatch(partTypesActions.getFileByType.start({ path }));
  };

  const renderFileList = () => {
    return filesPathList.map(({ path, type }) => (
      <JobsPreviewFile
        file={path}
        key={`file-technical-drawings-${path}-${type}`}
        entityType='partTypes'
        loadFile={loadFile}
        classNameHolderImg={st.classNameHolderImg}
        classNameContainer={st.classNameContainer}
        classNameImg={st.classNameImg}
        classNameHolderFile={st.classNameHolderFile}
        classNameHolderFileName={st.classNameHolderFileName}
        heightPDF={130}
        classNamePDfDocument={st.classNamePDfDocument}
      />
    ));
  };

  return (
    <div className={st.wrapperModalContent}>
      {partId && filesPathList.length > 0 && renderFileList()}
      {partId && filesPathList.length === 0 && (
        <div className={st.holderPlaceholderEmptyFile}>
          <Text variant='normal' className={st.placeholderEmptyFile}>
            There are no technical drawings for this job
          </Text>
        </div>
      )}
      <Loader isVisible={isLoadingFile} className={st.loader} />
    </div>
  );
}

export default ModalJobTechnicalDrawings;
