import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import HeaderNotification from 'components/HeaderNotification/HeaderNotification';
import AuthService from 'services/auth/AuthService';
import * as metadataActions from 'store/metadata/actions';

const TIMER = 60000;

function HeaderWrapperNotification() {
  const isClient = AuthService.isClient();
  const dispatch = useDispatch();

  const isAuthorized =
    useSelector((state) => state?.auth?.isAuthorized) ?? false;

  const [stopCheckMessage, setStopCheckMessage] = useState(false);
  const timeout = useRef(0);
  const isMountedRef = useRef(false);

  const checkHash = async () => {
    try {
      await dispatch(metadataActions.getMessageHash.start());
      if (isMountedRef.current && !stopCheckMessage) {
        timeout.current = setTimeout(() => {
          checkHash();
        }, TIMER);
      }
    } catch (error) {
      setStopCheckMessage(true);
    }
  };

  useEffect(() => {
    isMountedRef.current = true;

    if (!isClient && isAuthorized) {
      checkHash();
    }

    return () => {
      isMountedRef.current = false;
      clearTimeout(timeout.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isClient, stopCheckMessage, isAuthorized]);

  useEffect(() => {
    if (stopCheckMessage) {
      clearTimeout(timeout.current);
    }
  }, [stopCheckMessage]);

  if (isClient || !isAuthorized) {
    return null;
  }

  return <HeaderNotification setStopCheckMessage={setStopCheckMessage} />;
}

export default HeaderWrapperNotification;
