import { ArrowBackIcon } from 'assets/icons';
import cn from 'classnames';
import ModalBack from 'components/Modal/ModalBack/ModalBack';
import React, { useState } from 'react';
import NavigationService from 'services/navigation/NavigationService';
import { func, string, bool } from 'prop-types';
import st from './ButtonBack.module.scss';

ButtonBack.propTypes = {
  handleNavigateBack: func,
  className: string,
  isCheckModal: bool,
};

function ButtonBack({
  handleNavigateBack,
  className,
  onClick = () => {},
  isCheckModal = true,
}) {
  const [showModal, setShowModal] = useState(false);

  const showModalCallback = () => setShowModal(true);

  const onNavigateBack = () => {
    if (typeof handleNavigateBack === 'function') {
      handleNavigateBack();
    } else {
      NavigationService.back();
    }
  };

  const handleClick = () => {
    if (isCheckModal) {
      showModalCallback();
    } else {
      onClick();
    }
  };

  return (
    <>
      <div
        onClick={handleClick}
        className={cn(st.containerBackButton, className)}>
        <ArrowBackIcon className={st.backButton} />
      </div>

      <ModalBack
        show={showModal}
        setShow={setShowModal}
        navigateBack={onNavigateBack}
      />
    </>
  );
}

export default ButtonBack;
