import React from 'react';
import Text from 'componentsShared/Text/Text';
import { VARIANT_OUTLINE_UI, VARIANT_UI } from 'constants/ui';
import { Button } from 'react-bootstrap';
import MultiDropDown from 'componentsShared/MultiDropDown/MultiDropDown';
import st from './JobsDetailsQAFooterActive.module.scss';

function JobsDetailsQAFooterActive({
  show = false,
  setShow = () => {},
  label = '',
  titleBtnAccept = '',
  titleBtnCancel = 'Cancel',
  handleAccept = () => {},
  varinantBtnAccept = VARIANT_UI.primary,
  phaseArray = [],
  handleSelectStepIndex = () => {},
  type = '',
  selectStepString = '',
  disabledAcceptBtn = false,
  varinantBtnCancel = VARIANT_OUTLINE_UI.primary,
  firstJobId = '',
}) {
  if (!show) {
    return <></>;
  }

  return (
    <div className={st.wrapperSign}>
      <div className={st.holderSelectStep}>
        <Text varinant='normal'>{label}</Text>
        <div className={st.containerSelectStep}>
          <MultiDropDown
            phaseArray={phaseArray}
            handleSelectStepIndex={handleSelectStepIndex}
            type={type}
            selectStepString={selectStepString}
            firstJobId={firstJobId}
          />
        </div>
      </div>
      <div className={st.buttonGroup}>
        <Button
          variant={varinantBtnAccept}
          onClick={handleAccept}
          disabled={disabledAcceptBtn}>
          {titleBtnAccept}
        </Button>
        <Button variant={varinantBtnCancel} onClick={() => setShow(false)}>
          {titleBtnCancel}
        </Button>
      </div>
    </div>
  );
}

export default JobsDetailsQAFooterActive;
