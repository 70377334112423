import { api } from './ApiService';

export const getTravelerList = () => {
  return api.get('travelers');
};

export const getTraveler = (id) => {
  return api.get(`travelers/${id}`);
};

export const createTraveler = (data) => {
  return api.post('travelers', data);
};

export const addFileTraveler = ({ id, file }) => {
  return api.post(`travelers/${id}/files`, file);
};

export const getFileTraveler = (id) => {
  return api.get(`travelers/${id}/files`);
};

export const updateTraveler = ({ id, data }) => {
  return api.put(`travelers/${id}`, data);
};

export const removeTraveler = (id) => {
  return api.delete(`travelers/${id}`);
};

export const submitTraveler = (id) => {
  return api.put(`travelers/${id}/submit`);
};

export const getPhaseList = () => {
  return api.get('/travelers/phases');
};
