import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import { handleRequestError } from 'services/api/apiTools';
import { PAGE_SIZE } from 'constants/ui';
import ApiService from 'services/api';
import { validBodyGenerateApi } from 'helpers/reports';
import { getBlobUrlForFile } from 'services/api/files';
import * as actions from './actions';

export function* getReportListSaga() {
  const page = yield select(
    (state) => state?.reports?.fetchParams?.pagination?.page
  ) ?? 0;
  const size = yield select(
    (state) => state?.reports?.fetchParams?.pagination?.size
  ) ?? PAGE_SIZE;
  const settings = yield select((state) => state?.reports?.newSettings) ?? {};

  const currentSettings = { ...settings, reportType: settings.reportType.key };

  const body = validBodyGenerateApi(currentSettings);

  const query = {
    page,
    size,
  };

  let requestValue;

  try {
    requestValue = yield call(ApiService.getReports, {
      query,
      body,
    });
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getReportList.failure());
    return;
  }

  const [fields, ...report] = requestValue.items;

  const reportObj = report.reduce((acc, item) => {
    const currentValue = item.reduce((accField, itemField, indexField) => {
      return {
        ...accField,
        [fields[indexField]]: itemField,
      };
    }, {});

    return [...acc, currentValue];
  }, []);

  yield put(
    actions.getReportList.success({
      report: reportObj || [],
      page: requestValue.page,
      count: requestValue.count - 1,
      size: requestValue.size,
      fields,
    })
  );
}

export function* loadFileCSVSaga() {
  const page = yield select(
    (state) => state?.reports?.fetchParams?.pagination?.page
  ) ?? 0;
  const size = yield select(
    (state) => state?.reports?.fetchParams?.pagination?.size
  ) ?? PAGE_SIZE;
  const settings = yield select((state) => state?.reports?.newSettings) ?? {};

  const currentSettings = { ...settings, reportType: settings.reportType.key };

  const body = validBodyGenerateApi(currentSettings);

  const query = {
    page,
    size,
  };

  let requestValue;

  try {
    requestValue = yield call(ApiService.getReportsFileCSV, {
      query,
      body,
    });
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.loadFileCSV.failure());
    return;
  }

  const contentType = requestValue.headers['content-type'];
  const url = `data:${contentType};base64,${requestValue.data}`;

  const fileBlob = yield call(getBlobUrlForFile, url);

  yield put(actions.loadFileCSV.success(fileBlob));
}

export function* reportsSagas() {
  yield all([
    yield takeLatest(actions.getReportList.start, getReportListSaga),
    yield takeLatest(actions.loadFileCSV.start, loadFileCSVSaga),
  ]);
}
