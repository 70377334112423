import { getQueryParam } from 'helpers/path';
import { api } from './ApiService';

export const loginEmployee = (data) => {
  return api.post('login', data);
};

export const getUserData = () => {
  return api.get('me');
};

export const getEmployeeList = (query) => {
  const queryParam = getQueryParam(query);
  return api.get(`/users${queryParam}`);
};

export const createEmployee = (data) => {
  return api.post('/users', data);
};

export const changeEmployee = ({ login, data }) => {
  return api.put(`/users/${login}`, data);
};

export const removeEmployee = (login) => {
  return api.delete(`/users/${login}`);
};

export const getUserRoles = () => {
  return api.get(`users/roles`);
};

export const checkUserEmail = (email) => {
  return api.get(`user/email-uniqueness-check?email=${email}`);
};

export const changeTempPassword = (data) => {
  return api.put('me/default-password', data);
};

export const checkClientToken = (data) => {
  return api.put('client/login/check', data);
};

export const changePasswordForClient = (data) => {
  return api.put('client/login/check_change_password', data);
};

export const forgotPasswordClient = (data) => {
  return api.put(`/client/login/forgot`, data);
};

export const getVersionBacked = () => {
  return api.get('/version');
};
