import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import { handleRequestError } from 'services/api/apiTools';
import LocalStorageService from 'services/localStorage/LocalStorageService';
import NavigationService from 'services/navigation/NavigationService';
import { ROUTES } from 'router/routes';
import { USER_ROLES } from 'constants/userRoles';
import ApiService from 'services/api';
import NotificationsService from 'services/notifications/NotificationsService';
import AuthService from 'services/auth/AuthService';
import { VARIANT_UI } from 'constants/ui';
import { getValidPath } from 'helpers/path';
import * as ApiTools from 'services/api/ApiService';
import * as rootActions from 'store/rootActions';
import * as customersActions from 'store/customers/actions';
import * as actions from './actions';

export function* loginCustomerSaga(action) {
  const data = action.payload;
  let requestValue;
  try {
    requestValue = yield call(ApiService.loginCustomer, data);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.loginCustomer.failure());
    return;
  }

  const { token } = requestValue;
  yield call(signInSaga, { token, isCustomerRole: true });
}

export function* loginEmployeeSaga(action) {
  const data = action.payload;
  let requestValue;
  try {
    requestValue = yield call(ApiService.loginEmployee, data);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.loginEmployee.failure());
    return;
  }
  const { token } = requestValue;
  yield call(signInSaga, { token });
}

export function* signInSaga(action) {
  const { token, isCustomerRole } = action?.payload ?? action;
  const urlRedirectAfterAuthorization = yield select(
    (state) => state?.auth?.urlRedirectAfterAuthorization
  ) ?? '';

  LocalStorageService.setItem('token', token);
  ApiTools.api.saveToken(token);

  let userData;
  if (isCustomerRole) {
    yield put(actions.loginCustomer.success(token));
    userData = yield call(getClientDataSaga);
  } else {
    yield put(actions.loginEmployee.success(token));
    userData = yield call(getUserDataSaga);
  }

  const redirectPath =
    getValidPath(urlRedirectAfterAuthorization) ||
    NavigationService.getDefaultPath(userData.roles);
  NavigationService.replacePath(redirectPath);
}

export function* getUserDataSaga() {
  let requestValue = {};
  try {
    requestValue = yield call(ApiService.getUserData);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getUserData.failure());
    return;
  }

  yield put(actions.getUserData.success(requestValue));
  return requestValue;
}

export function* getClientDataSaga() {
  let requestValue;
  try {
    requestValue = yield call(ApiService.getClientData);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getUserData.failure());
    return;
  }

  const currentId = requestValue.id;

  const currentDataClient = {
    id: requestValue.id,
    roles: [USER_ROLES.customer],
    name: requestValue.name,
    login: requestValue.email,
  };

  yield put(customersActions.setCurrentCustomer(currentId));
  yield put(
    customersActions.getCustomer.success({
      data: { [currentId]: requestValue },
      ids: [currentId],
    })
  );
  yield put(actions.getUserData.success(currentDataClient));
  return requestValue;
}

export function* signUpConfirmSaga(action) {
  const params = action.payload;
  try {
    yield call(ApiService.changePasswordForClient, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.signUpConfirm.failure());
    return;
  }

  yield put(actions.signUpConfirm.success());
  NotificationsService.throwNotification({
    title: 'Successfully',
    text: 'Please login  with the new password',
    variant: VARIANT_UI.success,
  });
  NavigationService.navigateToPath(ROUTES.signIn.path);
}

export function* changeTempPasswordSaga(action) {
  const params = action.payload;
  try {
    yield call(ApiService.changeTempPassword, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.changeTempPassword.failure());
    return;
  }

  yield put(actions.changeTempPassword.success());
  NotificationsService.throwNotification({
    title: 'Successfully',
    text: 'Password has been successfully updated',
    variant: VARIANT_UI.success,
  });
  NavigationService.navigateToDefaultPath();
}

export function* forgotPasswordSaga(action) {
  const params = action.payload;
  try {
    yield call(ApiService.forgotPasswordClient, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.forgotPassword.failure());
    return;
  }

  yield put(actions.forgotPassword.success());
  NotificationsService.throwNotification({
    title: 'Successfully',
    text: 'We have sent you an email with further actions',
    variant: VARIANT_UI.success,
  });
  NavigationService.navigateToPath(ROUTES.signIn.path);
}

export function* checkClientTokenSaga(action) {
  const params = action.payload;
  try {
    yield call(ApiService.checkClientToken, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.checkClientToken.failure());
    return;
  }

  yield put(actions.checkClientToken.success());
}

export function* getVersionBackedSaga() {
  let requestValue;
  try {
    requestValue = yield call(ApiService.getVersionBacked);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getVersionBacked.failure());
    return;
  }

  yield put(
    actions.getVersionBacked.success({ version: requestValue.version })
  );
}

function restoreSession() {
  const token = LocalStorageService.getItem('token');
  if (!token) {
    return;
  }
  ApiTools.api.saveToken(token);
}

export function* signOutSaga(action) {
  const { isRedirect } = action?.payload || {};
  const isClient = AuthService.isClient();

  LocalStorageService.clear('token');
  ApiTools.api.deleteToken();
  yield put(rootActions.resetState());

  if (isRedirect) {
    if (isClient) {
      NavigationService.replacePath(ROUTES.signIn.path);
    } else {
      NavigationService.replacePath(ROUTES.loginEmployee.path);
    }
  }
}

export function* authSagas() {
  yield all([
    yield takeLatest(actions.loginCustomer.start, loginCustomerSaga),
    yield takeLatest(actions.loginEmployee.start, loginEmployeeSaga),
    yield takeLatest(actions.signOut, signOutSaga),
    yield takeLatest(actions.getUserData.start, getUserDataSaga),
    yield takeLatest(actions.signUpConfirm.start, signUpConfirmSaga),
    yield takeLatest(actions.changeTempPassword.start, changeTempPasswordSaga),
    yield takeLatest(actions.forgotPassword.start, forgotPasswordSaga),
    yield takeLatest(actions.checkClientToken.start, checkClientTokenSaga),
    yield takeLatest(actions.getVersionBacked.start, getVersionBackedSaga),
    call(restoreSession),
  ]);
}
