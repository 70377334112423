import { createAction } from '@reduxjs/toolkit';
import { getRequestAction } from 'helpers/functions';

const ACTION_PREFIX = 'travelers';

export const updateNewTravelerData = createAction(
  `${ACTION_PREFIX}/updateNewTravelerData`
);
export const updateDetailsTravelerData = createAction(
  `${ACTION_PREFIX}/updateDetailsTravelerData`
);
export const getTravelerList = getRequestAction(
  `${ACTION_PREFIX}/getTravelerList`
);
export const getTraveler = getRequestAction(`${ACTION_PREFIX}/getTraveler`);

export const createTraveler = getRequestAction(
  `${ACTION_PREFIX}/createTraveler`
);

export const addFileTraveler = getRequestAction(
  `${ACTION_PREFIX}/addFileTraveler`
);

export const getFileTraveler = getRequestAction(
  `${ACTION_PREFIX}/getFileTraveler`
);

export const updateTraveler = getRequestAction(
  `${ACTION_PREFIX}/updateTraveler`
);

export const removeTraveler = getRequestAction(
  `${ACTION_PREFIX}/removeTraveler`
);

export const submitTraveler = getRequestAction(
  `${ACTION_PREFIX}/submitTraveler`
);

export const getPhaseList = getRequestAction(`${ACTION_PREFIX}/getPhaseList`);

export const getSuggestOperationByPhase = getRequestAction(
  `${ACTION_PREFIX}/getSuggestOperationByPhase`
);

export const getSuggestStepByPhase = getRequestAction(
  `${ACTION_PREFIX}/getSuggestStepByPhase`
);

export const discardChangesTravelerData = createAction(
  `${ACTION_PREFIX}/discardChangesTravelerData`
);

export const changeDirtyNewTraveler = createAction(
  `${ACTION_PREFIX}/changeDirtyNewTraveler`
);

export const updateNewPhaseList = createAction(
  `${ACTION_PREFIX}/updateNewPhaseList`
);
