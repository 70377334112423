import { getQueryParam } from 'helpers/path';
import { api } from './ApiService';

export const getSuggestOperation = (phase) => {
  const queryParam = getQueryParam({ phase });
  return api.get(`/suggest/operation${queryParam}`);
};

export const getSuggestStep = (phase) => {
  const queryParam = getQueryParam({ phase });
  return api.get(`/suggest/step${queryParam}`);
};
