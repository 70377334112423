import { getQueryParam } from 'helpers/path';
import { api } from './ApiService';

export const getTemlateList = (query) => {
  const queryParam = getQueryParam(query);
  return api.get(`/templates${queryParam}`);
};

export const getTemplate = (id) => {
  return api.get(`/templates/${id}`);
};

export const createTemlate = (data) => {
  return api.post('/templates', data);
};

export const removeTemplate = (id) => {
  return api.delete(`/templates/${id}`);
};

export const changeTemplate = ({ id, data }) => {
  return api.put(`/templates/${id}`, data);
};
