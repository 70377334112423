import { createReducer } from '@reduxjs/toolkit';
import { PAGE_SIZE } from 'constants/ui';
import {
  changeAllTemplateOperationControl,
  deepClone,
} from 'helpers/functions';

import * as actions from './actions';

export const initialState = {
  newTemplate: {},
  newPhaseList: [],
  templates: {
    data: {},
    sortIds: [],
    isLoading: false,
    isLoadingCreate: false,
    isLoadingChange: false,
  },
  isModifiedTemplate: false,
  fetchParams: {
    pagination: {
      page: 0,
      size: PAGE_SIZE,
      count: 0,
    },
    search: '',
    isLoading: false,
  },
  files: {
    isLoading: false,
    data: {},
  },
};

export const templatesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.getTemplateList.start, (state, action) => {
      const { filter, isClearPage } = action?.payload || {};
      return {
        ...state,
        templates: {
          ...state.templates,
          isLoading: true,
        },
        fetchParams: {
          ...state.fetchParams,
          pagination: {
            ...state.fetchParams.pagination,
            page: isClearPage ? 0 : state.fetchParams.pagination.page,
          },
          isLoading: !!filter,
        },
      };
    })
    .addCase(actions.getTemplateList.success, (state, action) => {
      const { data, ids, page, count } = action.payload;

      return {
        ...state,
        templates: {
          ...state.templates,
          data,
          sortIds: ids,
          isLoading: false,
        },
        fetchParams: {
          ...state.fetchParams,
          pagination: {
            ...state.fetchParams.pagination,
            page,
            count,
          },
          isLoading: false,
        },
      };
    })
    .addCase(actions.getTemplateList.failure, (state) => ({
      ...state,
      templates: {
        ...state.templates,
        isLoading: false,
      },
      fetchParams: {
        ...state.fetchParams,
        isLoading: false,
      },
    }))
    .addCase(actions.createTemplate.start, (state) => ({
      ...state,
      templates: {
        ...state.templates,
        isLoadingCreate: true,
      },
    }))
    .addCase(actions.createTemplate.success, (state) => {
      return {
        ...state,
        templates: {
          ...state.templates,
          isLoadingCreate: false,
        },
      };
    })
    .addCase(actions.createTemplate.failure, (state) => ({
      ...state,
      templates: {
        ...state.templates,
        isLoadingCreate: false,
      },
    }))
    .addCase(actions.changeTemplate.start, (state) => ({
      ...state,
      templates: {
        ...state.templates,
        isLoadingChange: true,
      },
    }))
    .addCase(actions.changeTemplate.success, (state) => {
      return {
        ...state,
        templates: {
          ...state.templates,
          isLoadingChange: false,
        },
      };
    })
    .addCase(actions.changeTemplate.failure, (state) => ({
      ...state,
      templates: {
        ...state.templates,
        isLoadingChange: false,
      },
    }))
    .addCase(actions.removeTemplate.start, (state) => ({
      ...state,
      templates: {
        ...state.templates,
        isLoading: true,
      },
    }))
    .addCase(actions.removeTemplate.success, (state) => {
      return {
        ...state,
        templates: {
          ...state.templates,
          isLoading: false,
        },
      };
    })
    .addCase(actions.removeTemplate.failure, (state) => ({
      ...state,
      templates: {
        ...state.templates,
        isLoading: false,
      },
    }))
    .addCase(actions.updateDetailsTemplateData, (state, action) => {
      const cuurentTemplate = action.payload;

      return {
        ...state,
        newTemplate: cuurentTemplate,
      };
    })
    .addCase(actions.updateNewTemplateData, (state, action) => {
      const { phase, field, value } = action.payload;

      return {
        ...state,
        isModifiedTemplate: true,
        newTemplate: {
          ...state.newTemplate,
          [phase]: {
            ...state.newTemplate[phase],
            [field]: value,
          },
        },
      };
    })
    .addCase(actions.clearNewTemplateData, (state) => {
      return {
        ...state,
        newTemplate: {},
      };
    })
    .addCase(actions.getTemplateListForWizard.start, (state) => ({
      ...state,
      templates: { ...state.templates, isLoading: true },
    }))
    .addCase(actions.getTemplateListForWizard.success, (state, action) => {
      const { temlateListObj, temlateIds } = action.payload;
      return {
        ...state,
        templates: {
          ...state.templates,
          data: temlateListObj,
          sortIds: temlateIds,
          isLoading: false,
        },
      };
    })
    .addCase(actions.getTemplateListForWizard.failure, (state) => ({
      ...state,
      templates: { ...state.templates, isLoading: false },
    }))
    .addCase(actions.updateTemplateOperationControl, (state, action) => {
      const { phaseType, stepIndex, operationIndex, value, templateId } =
        action.payload;
      const currentState = deepClone(state);

      currentState.templates.data[templateId][phaseType].steps[
        stepIndex
      ].operations[operationIndex].control = value;

      return {
        ...currentState,
      };
    })
    .addCase(actions.updateTemplateSelectControlValues, (state, action) => {
      const { phaseType, stepIndex, value, templateId } = action.payload;
      const currentState = deepClone(state);
      currentState.templates.data[templateId][phaseType].steps[
        stepIndex
      ].selectControlValue = value;

      return {
        ...currentState,
      };
    })
    .addCase(actions.selectAllTemplateOperationControl, (state, action) => {
      const { templateId } = action.payload;
      const cloneTemplateList = deepClone(state.templates.data);

      const currentTemplate = changeAllTemplateOperationControl(
        templateId,
        cloneTemplateList
      );

      return {
        ...state,
        templates: {
          ...state.templates,
          data: currentTemplate,
        },
      };
    })
    .addCase(actions.deselectAllTemplateOperationControl, (state, action) => {
      const { templateId } = action.payload;
      const cloneTemplateList = deepClone(state.templates.data);

      const currentTemplate = changeAllTemplateOperationControl(
        templateId,
        cloneTemplateList,
        false
      );

      return {
        ...state,
        templates: {
          ...state.templates,
          data: currentTemplate,
        },
      };
    })
    .addCase(actions.changeFilterSearch, (state, action) => {
      const currentValue = action.payload;
      return {
        ...state,
        fetchParams: {
          ...state.fetchParams,
          pagination: {
            ...state.fetchParams.pagination,
          },
          search: currentValue,
        },
      };
    })
    .addCase(actions.changePage, (state, action) => {
      const page = action.payload;
      return {
        ...state,
        fetchParams: {
          ...state.fetchParams,
          pagination: {
            ...state.fetchParams.pagination,
            page,
          },
        },
      };
    })
    .addCase(actions.addFileForTemplate.start, (state) => ({
      ...state,
      files: {
        ...state.files,
        isLoading: true,
      },
    }))
    .addCase(actions.addFileForTemplate.success, (state, action) => {
      const { phaseName, stepIndex, fileName } = action.payload;

      const currentTemplate = deepClone(state.newTemplate);
      const step = currentTemplate?.[phaseName]?.steps?.[stepIndex];
      const currentOperations = step?.operations;

      const lastOperationIndex = currentOperations.length - 1;
      const operationIndex =
        step?.bundle_idx === lastOperationIndex
          ? lastOperationIndex - 1
          : lastOperationIndex;

      const operationFileList = step.operations[operationIndex].files;

      if (Array.isArray(operationFileList)) {
        const isFindFile = step.operations[operationIndex].files.find(
          (itemFileName) => itemFileName === fileName
        );

        if (!isFindFile) {
          step.operations[operationIndex].files = [
            ...operationFileList,
            fileName,
          ];
        }
      } else {
        step.operations[operationIndex].files = [fileName];
      }

      return {
        ...state,
        newTemplate: currentTemplate,
        files: {
          ...state.files,
          isLoading: false,
        },
      };
    })
    .addCase(actions.addFileForTemplate.failure, (state) => {
      return {
        ...state,
        files: {
          ...state.files,
          isLoading: false,
        },
      };
    })
    .addCase(actions.getTemplate.start, (state) => {
      return {
        ...state,
        templates: {
          ...state.templates,
          isLoading: true,
        },
      };
    })
    .addCase(actions.getTemplate.success, (state, action) => {
      const { data, ids } = action.payload;

      return {
        ...state,
        templates: {
          ...state.templates,
          data,
          sortIds: ids,
          isLoading: false,
        },
      };
    })
    .addCase(actions.getTemplate.failure, (state) => ({
      ...state,
      templates: {
        ...state.templates,
        isLoading: false,
      },
    }))
    .addCase(actions.updateNewPhaseList, (state, action) => {
      const data = action?.payload || [];
      return {
        ...state,
        newPhaseList: data,
      };
    });
});
