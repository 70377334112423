/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef } from 'react';
import PhasesContent from 'components/Traveler/PhasesContent/PhasesContent';
import { useSelector } from 'react-redux';
import CheckActivePhase from 'hooks/CheckActivePhase';
import st from './TravelerDetails.module.scss';

function TravelerDetails({
  setActivePhase = () => {},
  areScrollToPhase = '',
  setAreScrollToPhase = () => {},
}) {
  const phaseList =
    useSelector((state) => state?.travelers?.phaseList?.data) ?? {};
  const newPhaseList =
    useSelector((state) => state?.travelers?.newPhaseList) ?? [];
  const phasesWrapperRef = useRef();

  const phase = useMemo(() => {
    const arrayPhaseList = Object.values(phaseList);
    if (
      (!newPhaseList || newPhaseList.length === 0) &&
      arrayPhaseList.length === 0
    ) {
      return [];
    }

    if (
      (!newPhaseList || newPhaseList.length === 0) &&
      arrayPhaseList.length > 0
    ) {
      return arrayPhaseList;
    }

    if (newPhaseList.length > 0) {
      return newPhaseList;
    }
  }, [newPhaseList, phaseList]);

  const phaseIds = useMemo(() => {
    return newPhaseList.map((item) => item.name);
  }, [newPhaseList]);

  const { activePhaseId, heightLastComponent, heightContainer } =
    CheckActivePhase({
      phaseIds,
      ref: phasesWrapperRef,
      initialId: phaseIds?.[0],
      addingScroll: 250,
    });

  const scrollToPhase = (id) => {
    const currentPhaseOffsetTop = document.getElementById(id)?.offsetTop || 0;
    const firstPhaseOffsetTop =
      document.getElementById(phaseIds[0])?.offsetTop || 0;
    const position = currentPhaseOffsetTop - firstPhaseOffsetTop;
    phasesWrapperRef.current.scrollTo(0, position);
  };

  const widthInvisibleBlock = useMemo(() => {
    const width = heightContainer - heightLastComponent - 5;
    return width > 0 ? width : 0;
  }, [heightContainer, heightLastComponent]);

  useEffect(() => {
    if (activePhaseId) {
      setActivePhase(activePhaseId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePhaseId]);

  useEffect(() => {
    if (newPhaseList[0]) {
      scrollToPhase(newPhaseList[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (areScrollToPhase) {
      scrollToPhase(areScrollToPhase);
      setAreScrollToPhase('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areScrollToPhase]);

  const renderPhasesContent = () => {
    return (
      <>
        {phase.map(({ type, name, human_name }) => (
          <PhasesContent
            phaseType={type}
            phaseName={name}
            key={name}
            titlePhase={human_name}
          />
        ))}
      </>
    );
  };

  return (
    <div className={st.phaseListWrapper} ref={phasesWrapperRef}>
      {phase.length > 0 && renderPhasesContent()}
      <div
        style={{
          minHeight: `${widthInvisibleBlock}px`,
        }}
      />
    </div>
  );
}

export default TravelerDetails;
