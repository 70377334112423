import React, { useState } from 'react';
import { object, func, bool } from 'prop-types';
import Modal from 'componentsShared/Modal/Modal';
import ModalFileBody from 'components/Modal/ModalFileBody/ModalFileBody';
import ModalFilePreviewPagination from 'components/Modal/ModalFilePreviewPagination/ModalFilePreviewPagination';
import { ZoomIn, ZoomOut } from 'react-bootstrap-icons';
import { Button } from 'react-bootstrap';
import { VARIANT_OUTLINE_UI, ZOOM_IMG_ARRAY } from 'constants/ui';
import { useSelector } from 'react-redux';
import st from './ModalFilePreview.module.scss';

ModalFilePreview.propTypes = {
  data: object,
  showModal: bool,
  setShowModal: func,
};

function ModalFilePreview({
  data = {},
  showModal = false,
  setShowModal = () => {},
}) {
  const [zoomImgIndex, setZoomImgIndex] = useState(0);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const filesUrlObject =
    useSelector((state) => state?.files?.files?.dataUrl) ?? {};

  const currentUrl = filesUrlObject?.[data?.path] || '';

  const fileNameArray = data?.path.split('/');
  const fileName = fileNameArray?.[fileNameArray.length - 1];

  const isTypePDF = data?.content_type?.includes('pdf') || false;

  const onChangeZoomImg = (type = 'next') => {
    let index;
    if (type === 'next') {
      index =
        zoomImgIndex >= ZOOM_IMG_ARRAY.length - 1
          ? ZOOM_IMG_ARRAY.length - 1
          : zoomImgIndex + 1;
    } else {
      index = zoomImgIndex === 0 ? 0 : zoomImgIndex - 1;
    }
    setZoomImgIndex(index);
  };

  const renderButtonBottomLeft = () => {
    if (isTypePDF) {
      return (
        <ModalFilePreviewPagination
          pageNumber={pageNumber}
          numPages={numPages}
          setPageNumber={setPageNumber}
        />
      );
    }
  };

  const openFileInBrowser = () => {
    if (currentUrl) {
      window.open(currentUrl, 'EPrescription');
    }
  };

  const renderButtonBottomRight = () => {
    if (isTypePDF) {
      return <Button onClick={openFileInBrowser}>Open in a browser</Button>;
    }

    return (
      <div className={st.footerButtonGroup}>
        <Button
          variant={VARIANT_OUTLINE_UI.primary}
          onClick={() => onChangeZoomImg('prev')}
          disabled={zoomImgIndex === 0}>
          <ZoomOut />
        </Button>
        <Button
          variant={VARIANT_OUTLINE_UI.primary}
          onClick={() => onChangeZoomImg('next')}
          disabled={zoomImgIndex === ZOOM_IMG_ARRAY.length - 1}>
          <ZoomIn />
        </Button>
      </div>
    );
  };

  return (
    <Modal
      title={fileName || 'File preview'}
      size='lg'
      show={showModal}
      setShow={setShowModal}
      buttonBottomRight={renderButtonBottomRight}
      buttonBottomLeft={renderButtonBottomLeft}
      heightBody='calc(100vh - 240px)'
      classNameTitle={st.modalTitle}>
      <ModalFileBody
        filePath={data.path}
        isTypePDF={isTypePDF}
        setNumPages={setNumPages}
        pageNumber={pageNumber}
        zoomImgIndex={zoomImgIndex}
      />
    </Modal>
  );
}

export default ModalFilePreview;
