import React, { memo } from 'react';
import ModalJobSummaryBody from 'components/Modal/ModalJobSummaryBody/ModalJobSummaryBody';
import Tabs, { Tab } from 'componentsShared/Tabs/Tabs/Tabs';
import ModalJobRelatedBody from 'components/Modal/ModalJobRelatedBody/ModalJobRelatedBody';
import { bool, func, array, object } from 'prop-types';
import ModalJobTechnicalDrawings from 'components/Modal/ModalJobTechnicalDrawings/ModalJobTechnicalDrawings';
import ModalJobDocumentation from 'components/Modal/ModalJobDocumentation/ModalJobDocumentation';
import st from './JobsSummaryWrapper.module.scss';

JobsSummaryWrapper.propTypes = {
  selectAvailableJob: array,
  setSelectAvailableJob: func,
  availableJobIds: array,
  unavailableIdsJobIds: array,
  isProgressJob: bool,
  isInterprocessQA: bool,
  jobSummaryInfo: object,
};

function JobsSummaryWrapper({
  selectAvailableJob = [],
  setSelectAvailableJob = () => {},
  availableJobIds = [],
  unavailableIdsJobIds = [],
  isProgressJob = false,
  isInterprocessQA,
  jobSummaryInfo = {},
  jobIdList = [],
}) {
  const availableJobIdsLength = availableJobIds.length || 0;
  const unavailableIdsJobIdsLength = unavailableIdsJobIds.length || 0;

  const allJobIdsLength = availableJobIdsLength + unavailableIdsJobIdsLength;

  const contentArray = [
    {
      type: 'summary',
      title: 'Part Details',
      content: () => (
        <ModalJobSummaryBody partDateils={jobSummaryInfo?.partDateils} />
      ),
    },
    {
      type: 'technicalDrawings',
      title: 'Technical Drawings',
      content: () => (
        <ModalJobTechnicalDrawings partId={jobSummaryInfo.partId} />
      ),
    },
    {
      type: 'relatedJobs',
      title: `Related Jobs (${availableJobIdsLength}/${allJobIdsLength})`,
      content: () => (
        <ModalJobRelatedBody
          selectAvailableJob={selectAvailableJob}
          setSelectAvailableJob={setSelectAvailableJob}
          availableJobIds={availableJobIds}
          unavailableIdsJobIds={unavailableIdsJobIds}
          inProgress={isProgressJob}
          isInterprocessQA={isInterprocessQA}
        />
      ),
    },
    {
      type: 'documentation',
      title: 'Documentation',
      content: () => (
        <ModalJobDocumentation
          jobIdList={
            selectAvailableJob.length > 0 ? selectAvailableJob : jobIdList
          }
        />
      ),
    },
  ];

  const renderTab = () => {
    return contentArray.map((item) => {
      const { type, title, content } = item;

      return (
        <Tab eventKey={type} title={title} key={type}>
          <div className={st.container}>{content()}</div>
        </Tab>
      );
    });
  };

  const defaultActiveKey = contentArray[0].type;

  return (
    <div className={st.wrapper}>
      <Tabs
        defaultActiveKey={defaultActiveKey}
        classNameContainerTabList={st.containerTabList}
        classNameWrapperTabList={st.wrapperTabList}
        classNameContainerContent={st.containerContent}
        classNameContainer={st.containerTabComponent}>
        {renderTab()}
      </Tabs>
    </div>
  );
}

export default memo(JobsSummaryWrapper);
