import React from 'react';
import { object, func } from 'prop-types';
import EditCompanyForm from 'components/Form/EditCompanyForm/EditCompanyForm';
import st from './OffcanvasCompanyEditContent.module.scss';

OffcanvasCompanyEditContent.propTypes = {
  onSubmit: func,
  register: func,
  errors: object,
};

function OffcanvasCompanyEditContent({
  onSubmit = () => {},
  register = () => {},
  errors,
}) {
  return (
    <div className={st.container}>
      <EditCompanyForm
        onSubmit={onSubmit}
        register={register}
        errors={errors}
      />
    </div>
  );
}

export default OffcanvasCompanyEditContent;
