import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import Text from 'componentsShared/Text/Text';
import { Button } from 'react-bootstrap';
import * as reportsActions from 'store/reports/actions';
import ModalNewReport from 'components/Modal/ModalNewReport/ModalNewReport';
import FooterPagination from 'componentsShared/FooterPagination/FooterPagination';
import FooterPaginationSize from 'componentsShared/FooterPaginationSize/FooterPaginationSize';
import { FORMAT_DATE_USA, VARIANT_OUTLINE_UI } from 'constants/ui';
import Chip from 'components/Chip/Chip';
import {
  BagFillIcon,
  CalendarReportIcon,
  HammerFillIcon,
  PersonFillIcon,
  RecordCircleIcon,
} from 'assets/icons';
import {
  COLUMN_TABLE_CUSTOMER,
  COLUMN_TABLE_EMPLOYEE,
  COLUMN_TABLE_JOB,
  COLUMN_TABLE_NCR,
  REPORT_BREAKDOWN,
} from 'constants/reports';
import Loader from 'componentsShared/Loader/Loader';
import moment from 'moment';
import ListComponent from 'componentsShared/ListComponent/ListComponent';
import cn from 'classnames';
import st from './AdminReportsPage.module.scss';

function AdminReportsPage() {
  const dispatch = useDispatch();
  const paginationObj =
    useSelector((state) => state?.reports?.fetchParams?.pagination) ?? {};
  const settingsReport = useSelector((state) => state?.reports?.settings) ?? {};
  const reportList = useSelector((state) => state?.reports?.reports.data) ?? [];
  const fileData = useSelector((state) => state?.reports?.file) ?? {};
  const isLoading =
    useSelector((state) => state?.reports?.reports?.isLoading) ?? false;

  const [showModal, setShowModal] = useState(false);
  const [showStartContent, setShowStartContent] = useState(true);

  const emptyReportList = reportList.length === 0;

  const columns = useMemo(() => {
    const reportBreakdown = settingsReport.reportType || REPORT_BREAKDOWN[0];

    if (reportBreakdown.key === REPORT_BREAKDOWN[0].key) {
      return COLUMN_TABLE_EMPLOYEE;
    }
    if (reportBreakdown.key === REPORT_BREAKDOWN[1].key) {
      return COLUMN_TABLE_JOB;
    }
    if (reportBreakdown.key === REPORT_BREAKDOWN[3].key) {
      return COLUMN_TABLE_NCR;
    }
    return COLUMN_TABLE_CUSTOMER;
  }, [settingsReport.reportType]);

  const getFilterListChip = (reportBreakdown, selectedFilters) => {
    const lengthSelectedFilters = selectedFilters.length;
    if (lengthSelectedFilters === 0) {
      return [];
    }

    let icon = <PersonFillIcon />;

    if (reportBreakdown.key === REPORT_BREAKDOWN[1].key) {
      icon = <HammerFillIcon />;
    }
    if (reportBreakdown.key === REPORT_BREAKDOWN[2].key) {
      icon = <BagFillIcon />;
    }

    if (lengthSelectedFilters > 3) {
      return [
        {
          label: `${lengthSelectedFilters} ${reportBreakdown.chipLabel}`,
          icon,
        },
      ];
    }

    return selectedFilters.map(({ label }) => {
      return { label, icon };
    });
  };

  const settingsReportForChip = useMemo(() => {
    const reportBreakdown = settingsReport.reportType || REPORT_BREAKDOWN[0];
    const selectedFilters = settingsReport.filters || [];
    const dates = {
      startDate: settingsReport.timeFrom || new Date(),
      endDate: settingsReport.timeTo || new Date(),
    };

    const filterList = getFilterListChip(reportBreakdown, selectedFilters);

    return [
      { label: `By ${reportBreakdown.label}`, icon: <RecordCircleIcon /> },
      {
        label: `${moment(dates.startDate).format(FORMAT_DATE_USA)} – ${moment(
          dates.endDate
        ).format(FORMAT_DATE_USA)} `,
        icon: <CalendarReportIcon />,
      },
      ...filterList,
    ];
  }, [
    settingsReport.reportType,
    settingsReport.filters,
    settingsReport.timeTo,
    settingsReport.timeFrom,
  ]);

  const openModal = () => {
    setShowModal(true);
  };

  const loadReport = () => {
    dispatch(reportsActions.getReportList.start());
  };

  const generateReport = () => {
    loadReport();
    setShowStartContent(false);
  };

  const handleChangePage = (page) => {
    dispatch(reportsActions.changePage(page));
    loadReport();
  };

  const handleChangePageSize = (size) => {
    dispatch(reportsActions.changePageSize(size));
    loadReport();
  };

  const renderSettingsReport = () => {
    return settingsReportForChip.map(({ label, icon }) => {
      return <Chip label={label} icon={icon} key={label} />;
    });
  };

  const loadFileCSV = () => {
    dispatch(reportsActions.loadFileCSV.start());
  };

  const downloadFileCSV = () => {
    const a = document.createElement('a');
    a.href = fileData.data;
    const fileName = `Strobels-Supply-Report-${
      settingsReport.reportType.fileName
    }-${moment().format(FORMAT_DATE_USA)}.csv`;
    a.download = fileName;
    a.click();
    a.remove();
    dispatch(reportsActions.changeLoadingFile(false));
  };

  useEffect(() => {
    if (fileData.loadingFile) {
      downloadFileCSV();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileData.loadingFile]);

  useEffect(() => {
    dispatch(
      reportsActions.changeSettingsReport({
        timeFrom: moment().startOf('day'),
        timeTo: moment().endOf('day'),
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (showStartContent) {
    return (
      <AdminLayout>
        <>
          <div className={st.containerPage}>
            <div className={st.header}>
              <div className={st.headerContent}>
                <h4 className={st.headerTitle}>Reports</h4>
              </div>
            </div>
            <div className={st.content}>
              <div className={st.holderContentConfigure}>
                <Text variant='normal' className={st.textConfigure}>
                  Configure report to see data
                </Text>
                <Button onClick={openModal}>Configure Report</Button>
              </div>
            </div>
          </div>
          <ModalNewReport
            show={showModal}
            setShow={setShowModal}
            generateReport={generateReport}
          />
        </>
      </AdminLayout>
    );
  }

  if (emptyReportList) {
    return (
      <AdminLayout>
        <>
          <div className={st.containerPage}>
            <div className={st.header}>
              <div className={st.headerContent}>
                <div className={st.holderHeaderTitle}>
                  <h4 className={st.headerTitle}>Reports</h4>
                </div>
                <div className={st.settingsReportList} onClick={openModal}>
                  {renderSettingsReport()}
                </div>
              </div>
            </div>
            <div className={st.content}>
              <div className={st.holderContentConfigure}>
                <Text variant='normal' className={st.textConfigure}>
                  There is no data matching report configuration
                </Text>
                <Button onClick={openModal}>Configure Report</Button>
              </div>
            </div>
          </div>
          <ModalNewReport
            show={showModal}
            setShow={setShowModal}
            generateReport={generateReport}
          />
        </>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout>
      <>
        <div className={st.containerPage}>
          <div className={st.header}>
            <div className={st.headerContent}>
              <div className={st.holderHeaderTitle}>
                <h4 className={st.headerTitle}>Reports</h4>
              </div>
              <div className={st.settingsReportList} onClick={openModal}>
                {renderSettingsReport()}
              </div>
              <div className={st.holderDownloadBtn}>
                <Button
                  variant={VARIANT_OUTLINE_UI.primary}
                  onClick={loadFileCSV}
                  disabled={isLoading || fileData?.isLoading}>
                  Download CSV
                </Button>
              </div>
            </div>
          </div>
          <div className={cn(st.content, st.holderTable)}>
            <ListComponent
              data={reportList}
              columns={columns}
              classNameHeaderHolder={st.tableHeaderHolder}
              classNameHeaderText={st.tableHeaderText}
              classNameHeaderContainer={st.tableHeaderContainer}
              classNameColumnHolder={st.tableColumnHolder}
              classNameColumnText={st.tableColumnText}
              isShowActionColumn={false}
              classNameRowListWrapper={st.rowListWrapper}
              classNameItemRowContainer={st.tableItemRowContainer}
              classNameRowListHolder={st.tableRowListHolder}
              classNameContainer={st.tableContainer}
            />
            {isLoading && (
              <div className={st.containerLoader}>
                <Loader isVisible={isLoading} />
              </div>
            )}
          </div>

          <div className={st.footer}>
            <FooterPagination
              onChange={handleChangePage}
              countItem={paginationObj.count}
              size={paginationObj.size}
              className={st.footerPagination}
              page={paginationObj.page}
            />
            <FooterPaginationSize
              handleChangeSize={handleChangePageSize}
              size={paginationObj.size}
            />
          </div>
        </div>
        <ModalNewReport
          show={showModal}
          setShow={setShowModal}
          generateReport={generateReport}
        />
      </>
    </AdminLayout>
  );
}

export default AdminReportsPage;
