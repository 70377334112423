// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TravelerTemplateSelectList_container__2QRWD {\n  padding-left: 4px;\n  position: relative;\n  height: 100%; }\n", "",{"version":3,"sources":["webpack://src/components/TravalerTemplate/TravelerTemplateSelectList/TravelerTemplateSelectList.module.scss"],"names":[],"mappings":"AAEA;EACE,iBAAiB;EACjB,kBAAkB;EAClB,YAAY,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.container {\n  padding-left: 4px;\n  position: relative;\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "TravelerTemplateSelectList_container__2QRWD"
};
export default ___CSS_LOADER_EXPORT___;
