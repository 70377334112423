import React, { useEffect, useState } from 'react';
import { ArrowForwardIcon } from 'assets/icons';
import TravelerTemplateOperation from 'components/TravalerTemplate/TravelerTemplateOperation/TravelerTemplateOperation';
import Text from 'componentsShared/Text/Text';
import { array, number, string, oneOfType } from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import cn from 'classnames';
import st from './TravelerTemplateStep.module.scss';

TravelerTemplateStep.propTypes = {
  stepList: array.isRequired,
  phaseType: string.isRequired,
  templateId: oneOfType([number, string]),
};

function TravelerTemplateStep({ stepList, phaseType, templateId }) {
  const [openStepList, setOpenStepList] = useState([]);

  const handlePhaseList = (stepId) => {
    if (openStepList.includes(stepId)) {
      return setOpenStepList((prev) => prev.filter((item) => item !== stepId));
    }
    return setOpenStepList((prev) => [...prev, stepId]);
  };

  const renderStepList = () => {
    return stepList.map((step, index) => {
      const stepKey = uuidv4();
      const operations = [...(step?.operations || [])];

      return (
        <div key={`wizard-step-${stepKey}`}>
          <div
            className={st.stepTitleContainer}
            onClick={() => handlePhaseList(step.id)}>
            <ArrowForwardIcon
              className={cn({
                [st.stepTitleIconClose]: !openStepList.includes(step.id),
              })}
            />
            <Text varint='normal' bold className={st.stepTitle}>
              {step.name}
            </Text>
          </div>
          <div
            className={cn({
              [st.hiddenStepContent]: openStepList.includes(step.id),
            })}>
            <TravelerTemplateOperation
              operationList={operations}
              stepIndex={index}
              phaseType={phaseType}
              templateId={templateId}
            />
          </div>
        </div>
      );
    });
  };

  useEffect(() => {
    setOpenStepList([]);
  }, [templateId]);

  return (
    <div
      className={cn(st.container, {
        [st.containerPadding]: stepList.length > 0,
      })}>
      {stepList.length > 0 && renderStepList()}
    </div>
  );
}

export default TravelerTemplateStep;
