import AdminUesrMenu from 'components/Admin/AdminUesrMenu/AdminUesrMenu';
import AdminNavBar from 'components/AdminNavBar/AdminNavBar';
import HeaderLogo from 'components/HeaderLogo/HeaderLogo';
import React from 'react';
import st from './AdminMenu.module.scss';

function AdminMenu() {
  return (
    <div className={st.container}>
      <div className={st.headerLogoContainer}>
        <HeaderLogo />
      </div>
      <div className={st.navBarContainer}>
        <AdminNavBar />
      </div>
      <div className={st.uesrMenuContainer}>
        <AdminUesrMenu />
      </div>
    </div>
  );
}

export default AdminMenu;
