// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalFileBody_holderImg__17UKm {\n  position: relative;\n  min-width: 70px;\n  min-height: 70px;\n  height: 100%; }\n\n.ModalFileBody_jobSummaryImg__2sP9a {\n  min-height: 50px;\n  height: -moz-fit-content;\n  height: fit-content;\n  min-width: 100%;\n  max-width: 100%;\n  margin-top: 8px; }\n\n.ModalFileBody_wrapperModalContent__2l6bt {\n  height: 100%; }\n", "",{"version":3,"sources":["webpack://src/components/Modal/ModalFileBody/ModalFileBody.module.scss"],"names":[],"mappings":"AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,YAAY,EAAA;;AAGd;EACE,gBAAgB;EAChB,wBAAmB;EAAnB,mBAAmB;EACnB,eAAe;EACf,eAAe;EACf,eAAe,EAAA;;AAGjB;EACE,YAAY,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.holderImg {\n  position: relative;\n  min-width: 70px;\n  min-height: 70px;\n  height: 100%;\n}\n\n.jobSummaryImg {\n  min-height: 50px;\n  height: fit-content;\n  min-width: 100%;\n  max-width: 100%;\n  margin-top: 8px;\n}\n\n.wrapperModalContent {\n  height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"holderImg": "ModalFileBody_holderImg__17UKm",
	"jobSummaryImg": "ModalFileBody_jobSummaryImg__2sP9a",
	"wrapperModalContent": "ModalFileBody_wrapperModalContent__2l6bt"
};
export default ___CSS_LOADER_EXPORT___;
