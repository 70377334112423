import React from 'react';
import LoginEmployeeForm from 'components/Form/LoginEployeeForm/LoginEmployeeForm';
import CenteredColumnLayout from 'layouts/CenteredColumnLayout/CenteredColumnLayout';
import st from './LoginEmployeePage.module.scss';

function LoginEmployeePage() {
  return (
    <CenteredColumnLayout showNavbar={false} showUserMenu={false}>
      <div className={st.container}>
        <div className={st.containerForm}>
          <h4 className={st.title}>Log in as an employee</h4>
          <LoginEmployeeForm />
        </div>
      </div>
    </CenteredColumnLayout>
  );
}

export default LoginEmployeePage;
