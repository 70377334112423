/* eslint-disable react-hooks/exhaustive-deps */
import ModalJobsUnsubmiting from 'components/Modal/ModalJobsUnsubmiting/ModalJobsUnsubmiting';
import Modal from 'componentsShared/Modal/Modal';
import Text from 'componentsShared/Text/Text';
import React, { useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import * as jobsActions from 'store/jobs/actions';
import JobsOperationFooter from 'components/Jobs/JobsOperationFooter/JobsOperationFooter';
import ModalBatchJobControlValue from 'components/Modal/ModalBatchJobControlValue/ModalBatchJobControlValue';
import JobsBatchOperationContent from '../JobsBatchOperationContent/JobsBatchOperationContent';
import st from './JobsBatchOperation.module.scss';

function JobsBatchOperation({ jobListData = [], jobIdList = [] }) {
  const dispatch = useDispatch();

  const CVDataList =
    useSelector((state) => state?.controlValues?.controlValues?.data) ?? {};
  const [showModalUnsubmiting, setShowModalUnsubmiting] = useState(false);
  const [showModalSubmit, setShowModalSubmit] = useState(false);
  const [jobControlValues, setJobControlValues] = useState({});
  const [isShowErrorControlValues, setIsShowErrorControlValues] =
    useState(false);
  const [showModalRequireCV, setShowModalRequireCV] = useState(false);

  const showControlValues = jobListData.some(
    ({ bundleId, isControlValues }) => bundleId && isControlValues
  );

  const dataControlValues = jobListData.reduce((acc, item) => {
    if (item.bundleId) {
      return {
        ...acc,
        [item.bundleId]: [...(acc?.[item.bundleId] || []), ...item.ids],
      };
    }

    return { ...acc };
  }, {});

  const CVIds = Object.keys(dataControlValues);

  const errorsControlValues = useMemo(() => {
    const currentErrors = {};
    const headersCV = CVIds.reduce((acc, id) => {
      return [...acc, ...(CVDataList?.[id]?.headers || [])];
    }, []);

    if (!showControlValues) {
      return currentErrors;
    }
    headersCV.forEach((item) => {
      if (!jobControlValues?.[item.value]) {
        currentErrors[item.value] = 'Require';
      }
    });

    return currentErrors;
  }, [CVDataList, CVIds, jobControlValues, showControlValues]);

  const onCancel = () => {
    setShowModalUnsubmiting(true);
  };

  const onOpenModalSubmit = () => {
    if (showControlValues && Object.keys(errorsControlValues).length > 0) {
      setIsShowErrorControlValues(true);
      return setShowModalRequireCV(true);
    }

    setShowModalSubmit(true);
  };

  const onSubmit = () => {
    const data = jobListData.map((item) => {
      const currentHeadersCV = CVDataList?.[item.bundleId]?.headers || [];
      const currentCVData =
        item.bundleId && item.isControlValues && currentHeadersCV.length > 0
          ? currentHeadersCV.reduce((acc, itemHeaders) => {
              return {
                ...acc,
                [itemHeaders.value]: jobControlValues[itemHeaders.value],
              };
            }, {})
          : null;

      return {
        data: {
          curent_step_index: item.curent_step_index,
          current_operation_index: item.current_operation_index,
          values: currentCVData,
          ids: item.ids,
        },
        isLastOperation: item.isLastOperation,
      };
    });

    dispatch(jobsActions.submitBatchJob.start(data));
  };

  return (
    <div className={st.container}>
      <div className={st.content}>
        <div className={st.holderOperationList}>
          {jobListData.length > 0 &&
            jobListData.map((item) => {
              const {
                operationName,
                operationFileList,
                ids,
                bundleId,
                stepName,
                phaseName,
                part_type,
              } = item;

              const notes = part_type?.revision_notes || '';

              return (
                <JobsBatchOperationContent
                  operationName={operationName}
                  fileList={operationFileList}
                  ids={ids}
                  bundleId={bundleId}
                  stepName={stepName}
                  phaseName={phaseName}
                  notes={notes}
                  key={`job-batch-operation-content-${ids[0]}`}
                />
              );
            })}
        </div>
      </div>
      <JobsOperationFooter
        onApply={onOpenModalSubmit}
        onCancel={onCancel}
        tiltleCancelBtn='Reject Parts...'
        buttonsLeft={() => (
          <div>
            <Text variant='normal' className={st.footerInfoText}>
              {jobIdList.length} part{jobIdList.length === 1 ? '' : 's'} in the
              job series
            </Text>
          </div>
        )}>
        {showControlValues && (
          <ModalBatchJobControlValue
            dataCV={dataControlValues}
            jobControlValues={jobControlValues}
            setJobControlValues={setJobControlValues}
            CVDataList={CVDataList}
            errorsControlValues={errorsControlValues}
            isShowErrorControlValues={isShowErrorControlValues}
            setIsShowErrorControlValues={setIsShowErrorControlValues}
          />
        )}
      </JobsOperationFooter>

      <ModalJobsUnsubmiting
        jobIdList={jobIdList}
        show={showModalUnsubmiting}
        setShow={setShowModalUnsubmiting}
        isBatchJob
      />

      <Modal
        title='Missing Control Values'
        show={showModalRequireCV}
        setShow={setShowModalRequireCV}
        buttonBottomRight={({ onClose }) => (
          <>
            <Button onClick={onClose}>Okay</Button>
          </>
        )}>
        <Text variant='normal'>
          You have to provide required Control Values in order to proceed
        </Text>
      </Modal>

      <Modal
        title='Submit Operation'
        show={showModalSubmit}
        setShow={setShowModalSubmit}
        titleAcceptBtn='Send Request'
        onAcceptBtn={onSubmit}>
        <Text variant='normal'>
          Are you sure you want to complete the operation?
        </Text>
      </Modal>
    </div>
  );
}

export default JobsBatchOperation;
