import React from 'react';
import AuthService from 'services/auth/AuthService';
import { HEADER_NAV } from 'constants/navBar';
import { Link } from 'react-router-dom';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from 'react-router';
import { getValidPath } from 'helpers/path';
import { USER_ROLES } from 'constants/userRoles';
import cn from 'classnames';
import st from './HeaderNav.module.scss';

function HeaderNav() {
  const location = useLocation();
  const rootURL = location.pathname.split('/')[1];

  const currentRole = AuthService.getHighestUserRole();
  const isIncludeWorkerOrQA = AuthService.isIncludeWorkerOrQA();
  const jobsNavItem = HEADER_NAV[USER_ROLES.painter][0];

  const getMenuItems = () => {
    const value = [...(HEADER_NAV?.[currentRole] || [])];

    if (
      value.length > 0 &&
      (AuthService.isAdminRole() || AuthService.isManagerRole()) &&
      isIncludeWorkerOrQA
    ) {
      value.splice(value.length - 1, 0, jobsNavItem);
    }

    return value;
  };

  const menuItems = getMenuItems();

  const isActivePage = (pageURL) => {
    const rootPageURL = pageURL.split('/')[0] || '';
    return rootPageURL.includes(rootURL);
  };

  const isDisabledLink = (pageURL) => {
    return pageURL === '#';
  };

  const renderMenuItems = () => {
    return menuItems.map((item) => {
      const isActive = isActivePage(item.link);
      const isDisabled = isDisabledLink(item.link);

      return (
        <li className={st.headerMenuItem} key={item.name}>
          <Link
            to={getValidPath(item.link)}
            className={cn(st.headerMenuItemLink, {
              [st.navItemActive]: isActive,
              [st.navItemDisabled]: isDisabled,
            })}>
            {item.name}
          </Link>
        </li>
      );
    });
  };

  return (
    <nav>
      <ul className={st.headerMenu}>
        {menuItems.length > 0 && renderMenuItems()}
      </ul>
    </nav>
  );
}

export default HeaderNav;
