import React from 'react';
import Text from 'componentsShared/Text/Text';
import PropTypes from 'prop-types';
import InputOperation from 'components/Traveler/InputOperation/InputOperation';
import { CVIcon } from 'assets/icons';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useParams } from 'react-router';
import TravelerService from 'services/traveler/TravelerService';
import TravelerFileList from 'components/Traveler/TravelerFileList/TravelerFileList';
import cn from 'classnames';
import { Droppable } from 'react-beautiful-dnd';
import { DND_TYPE } from 'constants/traveler';
import TravelerRequireFile from 'components/Traveler/TravelerRequireFile/TravelerRequireFile';
import st from './TravelerOperation.module.scss';

TravelerOperation.propTypes = {
  operation: PropTypes.object.isRequired,
  operationNumber: PropTypes.number.isRequired,
  isEdit: PropTypes.bool,
  handleAddUpdating: PropTypes.func.isRequired,
  handleUpdating: PropTypes.func.isRequired,
  currentRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  operationIndex: PropTypes.number.isRequired,
  operationId: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]),
  stepId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  stepIndex: PropTypes.number.isRequired,
  placeholderProps: PropTypes.shape({
    props: PropTypes.object,
    type: PropTypes.string,
    sourceDroppableId: PropTypes.string,
  }),
  phaseType: PropTypes.string,
};

function TravelerOperation({
  operation,
  operationNumber,
  isEdit = false,
  handleAddUpdating,
  handleUpdating,
  currentRef,
  provided,
  operationIndex,
  operationId,
  stepId,
  stepIndex,
  snapshotOperation,
  placeholderProps,
  phaseType,
}) {
  const { id: travelerId } = useParams();
  const { name, require_file: requireFile, files: fileList } = operation;

  const isStatusActual = TravelerService.isStatusActual(travelerId);
  const isControlValues = operation?.isControlValues || false;
  const hasFileList = fileList && fileList.length > 0;

  const onAddUpdating = () => {
    if (!isStatusActual && !isControlValues) {
      handleAddUpdating(operationId);
    }
  };

  if (isEdit) {
    return (
      <div ref={currentRef} {...provided.draggableProps}>
        <InputOperation
          handleSubmit={(value) => handleUpdating(value, operationIndex)}
          initialValue={name}
          titleAcceptBtn='Save'
          titleCancelBtn='Delete'
          phaseType={phaseType}
        />
        <div {...provided.dragHandleProps} />
      </div>
    );
  }

  return (
    <div ref={currentRef} {...provided.draggableProps}>
      <div
        className={cn(st.container, {
          [st.operationDragging]: snapshotOperation.isDragging,
          [st.emptyImgAndRequire]: !hasFileList && !requireFile,
          [st.emptyRequire]: !requireFile,
        })}>
        <div
          className={cn(st.holderOperation, {
            [st.operationHolderDragging]: snapshotOperation.isDragging,
          })}
          {...(!isStatusActual ? provided.dragHandleProps : {})}>
          <div className={st.holderOperationInfo}>
            <div>
              <Text
                variant='normal'
                className={cn(st.operationName, {
                  [st.disabledDrag]: isStatusActual,
                })}>
                {operationNumber}.
              </Text>
            </div>
            <div
              onDoubleClick={onAddUpdating}
              className={st.holderOperationName}>
              <Text
                className={cn(st.operationName, {
                  [st.disabledDrag]: isStatusActual,
                })}
                variant='normal2'>
                {isControlValues ? 'Provide control values' : name}
              </Text>
            </div>
          </div>
          {isControlValues && (
            <div className={st.holderCV}>
              <div className={st.holderIconCV}>
                <CVIcon />
              </div>
              <div>
                <Text
                  className={cn(st.operationName, {
                    [st.disabledDrag]: isStatusActual,
                  })}
                  variant='normal2'>
                  {name}
                </Text>
              </div>
            </div>
          )}
        </div>

        {isStatusActual && <div {...provided.dragHandleProps} />}
        {!isControlValues && (
          <>
            <Droppable
              droppableId={`${operationId}`}
              type={`${DND_TYPE.file}${stepIndex}`}
              isDropDisabled={!!isControlValues}>
              {(providedDroppableFile, snapshotDroppableFile) => (
                <TravelerFileList
                  requireFile={requireFile}
                  operation={operation}
                  operationId={operationId}
                  stepId={stepId}
                  placeholderProps={placeholderProps}
                  providedDroppableFile={providedDroppableFile}
                  snapshotDroppableFile={snapshotDroppableFile}
                />
              )}
            </Droppable>

            <Droppable
              droppableId={`${operationId}/requireFile`}
              type={`${DND_TYPE.requireFile}${stepIndex}`}
              isDropDisabled={!!isControlValues || !!requireFile}>
              {(providedDroppableRequireFile, snapshotDroppableRequireFile) => (
                <TravelerRequireFile
                  requireFile={requireFile}
                  operationId={operationId}
                  stepId={stepId}
                  placeholderProps={placeholderProps}
                  providedDroppableFile={providedDroppableRequireFile}
                  snapshotDroppableFile={snapshotDroppableRequireFile}
                  hasFileList={hasFileList}
                />
              )}
            </Droppable>
          </>
        )}
      </div>
    </div>
  );
}

export default TravelerOperation;
