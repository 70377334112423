import { getQueryParam } from 'helpers/path';
import { api } from './ApiService';

export const getRFQList = (params) => {
  const queryParam = getQueryParam(params);
  return api.get(`rfqs${queryParam}`);
};

export const createRFQ = (data) => {
  return api.post('/rfqs/', data);
};

export const getRFQ = (id) => {
  return api.get(`/rfqs/${id}`);
};

export const updateRFQ = ({ id, data }) => {
  return api.put(`/rfqs/${id}`, data);
};

export const removeRFQ = (id) => {
  return api.delete(`rfqs/${id}`);
};

export const submitRFQ = (id) => {
  return api.put(`/rfqs/${id}`);
};
