export const VARIANT_UI = {
  primary: 'primary',
  secondary: 'secondary',
  success: 'success',
  danger: 'danger',
  warning: 'warning',
  info: 'info',
  light: 'light',
  dark: 'dark',
};

export const VARIANT_OUTLINE_UI = {
  primary: 'outline-primary',
  secondary: 'outline-secondary',
  success: 'outline-success',
  danger: 'outline-danger',
  warning: 'outline-warning',
  info: 'outline-info',
  light: 'outline-light',
  dark: 'outline-dark',
};

export const TIME_SEARCH_DEBOUNCE = 800;

export const PAGE_SIZE = 15;

export const NO_INFORMATION_FOUND_SEARCH_TEXT =
  'Sorry, we were not able to find information you requested.';

export const NO_INFORMATION_FOUND_SEARCH_CUSTOMER_TEXT =
  'No customers have been added yet. You can add a customer in the admin panel.';

export const NO_INFORMATION_FOUND_JOB_TEXT = 'No jobs to display here';

export const TRAVELER_SUCCESS_TOAST_TEXT =
  'The traveler is successfully submitted and ready for including in POs';

export const MAX_LENGTH_INPUT = '255';

export const CUSTOMER_HAS_NO_PARTS_TEXT = 'The Customer has no Parts';

export const CUSTOMER_HAS_NO_PO_TEXT = 'The Customer has no Purchase Orders';

export const CUSTOMER_HAS_NO_RFQ_TEXT = 'The Customer has no Purchase Orders';

export const NO_MATCHES_FOUND =
  'No matches found for your search. Check your search word or cancel the search at all';

export const EMPTY_PARTS_CUSTOMER_ROLE_TEXT =
  'Here is the home for your Parts. Currently you have no Parts to work with. Create a new Part in order to add it to an RFQ!';

export const EMPTY_FOUND_PARTS_CUSTOMER_ROLE_TEXT =
  'There are no Parts matching your search criteria to display them here. You could check and modify the search word, or cancel the search at all.';

export const EMPTY_PARTS_EMPLOYEE_ROLE_TEXT =
  "Here is the home for all Parts stored in the system. Currently it's empty, so you could add a new one. You might need to create a Customer first in order to proceed adding new Parts.";

export const EMPTY_PO_CUSTOMER_ROLE_TEXT =
  'Here is the home for your Purchase Orders. Currently you have no POs to work with. Create a new Purchase Order and track the progress here!';

export const EMPTY_FOUND_PO_CUSTOMER_ROLE_TEXT =
  'There are no Purchase Orders matching your search criteria to display them here. You could check and modify the search word, or cancel the search at all.';

export const EMPTY_PRODUCTION_PAGE_TEXT =
  "The Production has no POs to work with. You could create a new one from a ready to use RFQ in the Customer's RFQ List";

export const EMPTY_RFQ_CUSTOMER_ROLE_TEXT =
  "Here is the home for your Requests for a Quote. Currently you have no RFQs to work with. Create a new RFQ and get notified on it's updates here!";

export const EMPTY_FOUND_RFQ_CUSTOMER_ROLE_TEXT =
  'There are no Parts matching your search criteria to display them here. You could check and modify the search word, or cancel the search at all.';

export const IS_PHONE = '(max-width: 768px)';

export const IS_PHONE_MD = '(max-width: 560px)';

export const MODAL_TITLE_BTN_DISCARD_CHANGES = 'Discard Changes';

export const MANAGER_NOTIFICATIONS_LABEL = 'Manager Notifications';

export const MAX_FILE_SIZE = 15000000;

export const NOTIFICATION_LARGE_SIZE_FILE_TEXT =
  'The file is too big for uploading. The maximum file size is 15 Mb';

export const NOTIFICATION_LARGE_SIZE_FILE_TITLE = 'File Error';

export const NOTIFICATION_ERROR_TYPE_FILE_TITLE = 'Unsupported Image Format';

export const FORMAT_DATE_USA = 'MM-DD-YY';

export const FORMAT_DATE_FULL_MONTH = 'MMMM DD, YYYY';

export const FORMAT_DATE_FULL_MONTH_KEBAB = 'MM-DD-YYYY';

export const FORMAT_DATE_USA_WITH_TIME = `${FORMAT_DATE_USA}, hh:mm A`;

export const KEY_DEFAULT_REDIRECT = 'defaultRedirect';

export const ZOOM_IMG_ARRAY = [1, 1.5, 2, 3];
