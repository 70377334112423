import React, { useState } from 'react';
import Text from 'componentsShared/Text/Text';
import { FormControl, InputGroup } from 'react-bootstrap';
import DropdownButton from 'componentsShared/DropdownButton/DropdownButton';
import Modal from 'componentsShared/Modal/Modal';
import { MoreVertIcon } from 'assets/icons';
import { VARIANT_OUTLINE_UI, VARIANT_UI } from 'constants/ui';
import { func, string, object, number, oneOfType } from 'prop-types';
import cn from 'classnames';
import NavigationService from 'services/navigation/NavigationService';
import { getRoutePath } from 'helpers/path';
import { ROUTES } from 'router/routes';
import st from './PartsCardConstructor.module.scss';

PartsCardConstructor.propTypes = {
  index: oneOfType([number, string]),
  part: object,
  quantity: oneOfType([number, string]),
  handleDeleteOrderPositions: func,
  decrementQuantity: func,
  handleChangeQuantity: func,
  incrementQuantity: func,
  currentPage: string,
};

function PartsCardConstructor({
  index,
  part = {},
  quantity,
  handleDeleteOrderPositions = () => {},
  decrementQuantity = () => {},
  handleChangeQuantity = () => {},
  incrementQuantity = () => {},
  currentPage = 'PO',
  isShowDropdown = true,
  isAddingCurrentPart = false,
}) {
  const [openRemoveModal, setOpenRemoveModal] = useState(false);

  const modalTitle = `Remove Part from the ${currentPage}`;
  const modalText = `Would you like to remove from the ${currentPage} the following Part?`;

  const dropdownListHeader = [
    {
      content: () => (
        <Text variant='normal' className={st.textRemove}>
          Remove Part
        </Text>
      ),
      onClick: () => setOpenRemoveModal(true),
    },
  ];

  const removePart = () => {
    handleDeleteOrderPositions(index);
  };

  const onNavigatePart = () => {
    NavigationService.navigateToPath(
      getRoutePath(ROUTES.partTypesDetails.path, {
        id: part.id,
      })
    );
  };

  return (
    <div id={`part-${part.id}`}>
      <div className={st.rootWrapper}>
        <div
          className={st.containerOrderPositions}
          onMouseDown={onNavigatePart}>
          <div
            className={cn(st.infoPositions, {
              [st.infoPositionsFullName]: currentPage !== 'PO',
            })}>
            <Text variant='normal' className={st.infoPositionsNumber}>
              {part.number}-Revision-{part.revision}
            </Text>
            <Text variant='normal2' className={st.infoPositionsName}>
              {part.name}
            </Text>
          </div>
          <div className={st.activeOrderPositions}>
            {currentPage === 'PO' && (
              <div className={st.holderInputQuantity}>
                <InputGroup size='sm'>
                  <InputGroup.Text
                    className={st.inputQuantityIcon}
                    onMouseDown={(e) => {
                      e.stopPropagation();
                      decrementQuantity(index);
                    }}>
                    -
                  </InputGroup.Text>
                  <FormControl
                    type='number'
                    value={quantity}
                    min={1}
                    onClick={(e) => e.stopPropagation()}
                    onMouseDown={(e) => e.stopPropagation()}
                    onChange={(e) => {
                      handleChangeQuantity(e.target.value, index);
                    }}
                  />
                  <InputGroup.Text
                    className={st.inputQuantityIcon}
                    onMouseDown={(e) => {
                      e.stopPropagation();
                      incrementQuantity(index);
                    }}>
                    +
                  </InputGroup.Text>
                </InputGroup>
              </div>
            )}
            <div>
              {isShowDropdown && (
                <DropdownButton
                  list={dropdownListHeader}
                  className={st.moreButton}
                  startIcon={<MoreVertIcon />}
                  btnVariant={VARIANT_OUTLINE_UI.light}
                  arrowNone
                  onMouseDown={(e) => e.stopPropagation()}
                  onClick={(e) => e.stopPropagation()}
                />
              )}
            </div>
          </div>
        </div>
        {isAddingCurrentPart && <div className={st.addingAnimation} />}
      </div>

      <Modal
        title={modalTitle}
        show={openRemoveModal}
        setShow={setOpenRemoveModal}
        titleAcceptBtn='Remove'
        onAcceptBtn={removePart}
        variantAcceptBtn={VARIANT_UI.danger}>
        <div className={st.wrapperModalContent}>
          <Text variant='normal'>{modalText}</Text>
          <Text variant='normal' bold>
            {part.number}-Revision-{part.revision}
          </Text>
          <Text variant='normal' bold>
            {part.name}
          </Text>
        </div>
      </Modal>
    </div>
  );
}

export default PartsCardConstructor;
