import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { oneOfType, string, number, bool } from 'prop-types';
import { getStepListArray } from 'helpers/functions';
import MultiDropDown from 'componentsShared/MultiDropDown/MultiDropDown';
import st from './JobsMultiDropDownNCR.module.scss';

JobsMultiDropDownNCR.propTypes = {
  jobId: oneOfType([number, string]),
  selectStep: number,
  setSelectStep: () => {},
  isError: bool,
  setIsError: () => {},
};

function JobsMultiDropDownNCR({
  jobId,
  selectStep,
  setSelectStep = () => {},
  isError = false,
  setIsError = () => {},
}) {
  const currentJob =
    useSelector((state) => state?.jobs?.jobs?.data?.[jobId]) ?? {};

  const phaseArray = useMemo(() => {
    const stepList = [...(currentJob?.steps || [])];
    return getStepListArray(stepList, -1);
  }, [currentJob.steps]);

  const selectStepString = useMemo(() => {
    const jobStepList = currentJob?.steps;

    const phaseStep = currentJob?.phases?.find(
      ({ name }) => name === jobStepList?.[selectStep]?.phase
    )?.human_name;

    const currentSelectStepString =
      selectStep > -1
        ? `${phaseStep} / ${jobStepList?.[selectStep]?.name}`
        : '';

    return currentSelectStepString;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectStep, currentJob?.steps]);

  const handleSelectStepIndex = (type, stepIndex) => {
    setSelectStep(stepIndex);
    setIsError(false);
  };

  return (
    <div className={st.holderSelectStep}>
      <MultiDropDown
        phaseArray={phaseArray}
        handleSelectStepIndex={handleSelectStepIndex}
        selectStepString={selectStepString}
        isError={isError}
        firstJobId={jobId}
      />
    </div>
  );
}

export default JobsMultiDropDownNCR;
