import SignInForm from 'components/Form/SignInForm/SignInForm';
import CenteredColumnLayout from 'layouts/CenteredColumnLayout/CenteredColumnLayout';
import React from 'react';
import st from './SignInPage.module.scss';

function SignInPage() {
  return (
    <CenteredColumnLayout showNavbar={false} showUserMenu={false}>
      <div className={st.container}>
        <div className={st.containerForm}>
          <h4 className={st.title}>Log in as a customer</h4>
          <SignInForm />
        </div>
      </div>
    </CenteredColumnLayout>
  );
}

export default SignInPage;
