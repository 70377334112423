import React, { useEffect } from 'react';
import AdminHeaderLayout from 'layouts/AdminHeaderLayout/AdminHeaderLayout';
import { useSelector, useDispatch } from 'react-redux';
import * as templatesActions from 'store/templates/actions';
import { EditIcon } from 'assets/icons';
import {
  FORMAT_DATE_FULL_MONTH,
  NO_INFORMATION_FOUND_SEARCH_TEXT,
  TIME_SEARCH_DEBOUNCE,
  VARIANT_UI,
} from 'constants/ui';
import * as travelersActions from 'store/travelers/actions';
import moment from 'moment';
import { debounce } from 'helpers/functions';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from 'react-router';
import { getQueryVariable, getRoutePath } from 'helpers/path';
import ListComponent from 'componentsShared/ListComponent/ListComponent';
import FooterPagination from 'componentsShared/FooterPagination/FooterPagination';
import { ACTION_COLUMN } from 'constants/table';
import { Files } from 'react-bootstrap-icons';
import NavigationService from 'services/navigation/NavigationService';
import { ROUTES } from 'router/routes';
import { Button } from 'react-bootstrap';
import st from './AdminTravelerTemplatesPage.module.scss';

const columnTable = [
  { dataKey: 'name', width: 366, title: 'Name of Template', flexGrow: true },
  { dataKey: 'author', width: 220, title: 'Author', truncated: true },
  { dataKey: 'date', width: 220, title: 'Creation Date', truncated: true },
  { dataKey: ACTION_COLUMN, width: 100, title: '' },
];

const debouncedSearch = debounce((onSearch, data) => {
  onSearch(data);
}, TIME_SEARCH_DEBOUNCE);

function AdminTravelerTemplatesPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryVariable = getQueryVariable(location.search);
  const currentPage = queryVariable.page || 0;

  const isLoadingTemplates =
    useSelector((state) => state?.templates?.templates?.isLoading) ?? false;
  const isLoadingCreateTemplates =
    useSelector((state) => state?.templates?.templates?.isLoadingCreate) ??
    false;
  const isLoadingChangeTemplates =
    useSelector((state) => state?.templates?.templates?.isLoadingChange) ??
    false;
  const serchValue =
    useSelector((state) => state?.templates?.fetchParams?.search) ?? '';
  const isLoadingSearch =
    useSelector((state) => state?.templates?.fetchParams?.isLoading) ?? false;
  const paginationObj =
    useSelector((state) => state?.templates?.fetchParams?.pagination) ?? {};

  const templateList =
    useSelector((state) => state?.templates?.templates?.data) ?? {};
  const templateListArray = Object.values(templateList);
  const dataTable = templateListArray.map((template) => ({
    id: template.id,
    name: template.name,
    author:
      template?.author && typeof template.author === 'string'
        ? template.author
        : '-',
    date: template.created
      ? moment(template.created).format(FORMAT_DATE_FULL_MONTH)
      : '-',
  }));

  const actionsTable = [
    {
      icon: <Files className={st.fileIcon} />,
      onClick: (rowData) => {
        const currentTemplate = templateList?.[rowData.id];
        dispatch(templatesActions.updateDetailsTemplateData(currentTemplate));
        dispatch(
          templatesActions.updateNewPhaseList(currentTemplate.phaseList)
        );

        NavigationService.navigateToPath(
          getRoutePath(ROUTES.adminTravelerTemplateCreate.path)
        );
      },
    },
    {
      icon: <EditIcon className={st.editIcon} />,
      onClick: (rowData) => {
        NavigationService.navigateToPath(
          getRoutePath(ROUTES.adminTravelerTemplateDetails.path, {
            id: rowData.id,
          })
        );
      },
    },
  ];

  const onChangeFilterSearch = (value) => {
    dispatch(templatesActions.changeFilterSearch(value));
  };

  const loadTemplateList = (isClearPage) => {
    dispatch(
      templatesActions.getTemplateList.start({
        filter: serchValue,
        isClearPage,
        isPagination: true,
      })
    );
  };

  const handleChangeSearch = (value) => {
    onChangeFilterSearch(value);
    if (value) {
      return debouncedSearch(() => {
        loadTemplateList(true);
      });
    }
    loadTemplateList(true);
  };

  const handleChangePage = (page) => {
    dispatch(templatesActions.changePage(page));
    loadTemplateList();
  };

  const createTemplate = () => {
    dispatch(templatesActions.updateDetailsTemplateData({}));
    dispatch(templatesActions.updateNewPhaseList());

    NavigationService.navigateToPath(
      getRoutePath(ROUTES.adminTravelerTemplateCreate.path)
    );
  };

  useEffect(() => {
    if (!+currentPage) {
      onChangeFilterSearch('');
    }
    handleChangePage(+currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(travelersActions.getPhaseList.start());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AdminHeaderLayout
      title='Traveler Templates'
      classNameContentHolder={st.layoutContentHolder}
      searchValue={serchValue}
      handleChangeSearch={handleChangeSearch}
      inviteButton={() => (
        <Button variant={VARIANT_UI.primary} onClick={createTemplate}>
          New Template
        </Button>
      )}
      isLoadingSearch={isLoadingSearch}
      placeholderSearch='Search by template name'
      renderFooter={() => (
        <FooterPagination
          page={paginationObj.page}
          countItem={paginationObj.count}
          size={paginationObj.size}
          onChange={handleChangePage}
          isFullLine
          isShowPlaceholder={dataTable.length === 0}
        />
      )}>
      <ListComponent
        data={dataTable}
        columns={columnTable}
        actions={actionsTable}
        classNameRowListWrapper={st.rowListWrapper}
        classNameRowListHolder={st.rowListHolder}
        isLoading={
          isLoadingTemplates ||
          isLoadingCreateTemplates ||
          isLoadingChangeTemplates
        }
        placeholderText={NO_INFORMATION_FOUND_SEARCH_TEXT}
      />
    </AdminHeaderLayout>
  );
}

export default AdminTravelerTemplatesPage;
