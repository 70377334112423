import React, { useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
import autosize from 'autosize';
import { v4 as uuidv4 } from 'uuid';
import { func, string, bool, array } from 'prop-types';
import Text from 'componentsShared/Text/Text';
import st from './JobsReasonForRejecting.module.scss';

JobsReasonForRejecting.propTypes = {
  reason: string,
  setReason: func,
  errorReasonRequired: bool,
  setError: func,
  reasonList: array,
};

function JobsReasonForRejecting({
  reason = '',
  setReason = () => {},
  errorReasonRequired = false,
  setError = () => {},
  reasonList = [],
}) {
  const inputId = uuidv4();
  const textareaRef = useRef(null);

  const handleChangeReason = (value) => {
    setError('reasonRequired', false);
    setReason(value);
  };

  useEffect(() => {
    if (inputId && textareaRef.current) {
      autosize(textareaRef.current);
    }
  }, [inputId]);

  return (
    <div className={st.container} id='reasonForRejecting'>
      <div className={st.holderTitle}>
        <Text className={st.title} bold variant='normal'>
          Reason for rejecting
        </Text>
      </div>

      {errorReasonRequired && (
        <div className={st.holderErrorText}>
          <Text variant='normal2' className={st.errorText}>
            Specify Reason for rejection
          </Text>
        </div>
      )}

      <div className={st.holderReasonList}>
        <div className={st.holderRadioList}>
          {reasonList.map((item) => (
            <div key={item.key} className={st.holderRadioBox}>
              <Form.Check
                type='radio'
                name='reason'
                checked={reason === item.key}
                onChange={() => handleChangeReason(item.key)}
              />
              <Text
                variant='normal'
                className={st.labelRadiobox}
                onClick={() => handleChangeReason(item.key)}>
                {item.text}
              </Text>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default JobsReasonForRejecting;
