// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdminTravelerTemplateDetailsPage_container__aK4-C {\n  width: 100%;\n  height: 100%;\n  display: grid;\n  grid-template-rows: auto 1fr; }\n", "",{"version":3,"sources":["webpack://src/pages/AdminTravelerTemplateDetailsPage/AdminTravelerTemplateDetailsPage.module.scss"],"names":[],"mappings":"AAEA;EACI,WAAW;EACX,YAAY;EACZ,aAAa;EACb,4BAA4B,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.container{\n    width: 100%;\n    height: 100%;\n    display: grid;\n    grid-template-rows: auto 1fr;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AdminTravelerTemplateDetailsPage_container__aK4-C"
};
export default ___CSS_LOADER_EXPORT___;
