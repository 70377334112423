// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalFilePreviewPagination_pdfPagination__2RfIT {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  list-style-type: none; }\n  .ModalFilePreviewPagination_pdfPagination__2RfIT .pagination {\n    margin-bottom: 0; }\n", "",{"version":3,"sources":["webpack://src/components/Modal/ModalFilePreviewPagination/ModalFilePreviewPagination.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,qBAAqB,EAAA;EAJvB;IAQM,gBAAgB,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.pdfPagination {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  list-style-type: none;\n\n  :global {\n    .pagination {\n      margin-bottom: 0;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"pdfPagination": "ModalFilePreviewPagination_pdfPagination__2RfIT"
};
export default ___CSS_LOADER_EXPORT___;
