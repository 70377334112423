import {
  all,
  put,
  takeLatest,
  call,
  select,
  takeEvery,
} from 'redux-saga/effects';
import { handleRequestError } from 'services/api/apiTools';
import ApiService from 'services/api';
import {
  getParsingArrayToObj,
  getValidInterprocessJobList,
  getValidMyJobList,
} from 'helpers/functions';
import NavigationService from 'services/navigation/NavigationService';
import AuthService from 'services/auth/AuthService';
import { ROUTES } from 'router/routes';
import { getRoutePath } from 'helpers/path';
import { JOBS_STATUS, JOB_ERROR_QR_CODE } from 'constants/jobs';
import { MODULE_TYPES } from 'constants/files';
import { getBlobUrlForFile } from 'services/api/files';
import * as worklogsActions from 'store/worklogs/actions';
import * as actions from './actions';

export function* submitJobSaga(action) {
  const { isNeedsQA, isLastOperation, data } = action.payload;
  let requestValue;

  try {
    requestValue = yield call(ApiService.submitJob, data);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.submitJob.failure());
    return;
  }

  const jobs = getParsingArrayToObj(requestValue);
  const firstJob = requestValue[0];

  yield put(
    actions.submitJob.success({
      data: { ...jobs },
      isNeedsQA,
    })
  );

  if (firstJob.status === JOBS_STATUS.done || isLastOperation) {
    yield put(actions.changeIsScrollJob(true));
    NavigationService.navigateToPath(ROUTES.jobList.path);
  }
}

export function* takeJobSaga(action) {
  const params = action.payload;
  let requestValue;
  try {
    requestValue = yield call(ApiService.takeJob, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.takeJob.failure());
    return;
  }

  const ids = requestValue.map(({ id }) => id);
  NavigationService.navigateToPath(
    getRoutePath(ROUTES.jobDetails.path, null, {
      ids,
    })
  );

  yield put(actions.takeJob.success());
}

export function* getJobSaga(action) {
  const { id, hideError, isSearchJobId } = action?.payload ?? {};

  let jobIdApi;

  if (isSearchJobId) {
    jobIdApi = yield select((state) =>
      state?.jobs?.filterValue?.search?.trim()
    ) ?? '';
  } else {
    jobIdApi = id;
  }

  if (!jobIdApi) {
    yield put(actions.getJob.failure());
    return;
  }

  let requestValue;
  try {
    requestValue = yield call(ApiService.getJob, jobIdApi);
  } catch (errors) {
    if (!hideError) {
      handleRequestError(errors);
    }
    yield put(actions.getJob.failure());
    return;
  }

  const jobId = requestValue.id;

  yield put(
    actions.getJob.success({ id: jobId, data: { [jobId]: requestValue } })
  );
}

export function* rejectJobSaga(action) {
  const { data, isSelectAll, currentJobIds, pathUrl, isBatchJob } =
    action.payload;

  try {
    yield call(ApiService.rejectJob, { data });
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.rejectJob.failure());
    return;
  }

  if (isSelectAll || currentJobIds.length === 0) {
    NavigationService.navigateToPath(ROUTES.jobList.path);
  } else {
    NavigationService.replacePath(
      getRoutePath(pathUrl, null, {
        ids: currentJobIds,
      })
    );
  }

  yield put(actions.rejectJob.success({ rejectIds: data.ids, isBatchJob }));
}

export function* approveJobQASaga(action) {
  const params = action.payload;
  try {
    yield call(ApiService.approveJob, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.approveJobQA.failure());
    return;
  }

  yield put(actions.approveJobQA.success());

  NavigationService.replacePath(ROUTES.jobList.path);
}

export function* takeJobQASaga(action) {
  const params = action.payload;
  let requestValue;
  try {
    requestValue = yield call(ApiService.takeJob, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.takeJobQA.failure());
    return;
  }

  const ids = requestValue.map(({ id }) => id);
  NavigationService.navigateToPath(
    getRoutePath(ROUTES.jobDetailsFinalQA.path, null, {
      ids,
    })
  );
  yield put(actions.takeJobQA.success());
}

export function* cancelJobSaga(action) {
  const params = action.payload;
  try {
    yield call(ApiService.cancelJob, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.cancelJob.failure());
    return;
  }
  NavigationService.back();
  yield put(actions.cancelJob.success());
}

export function* addFileInterprocessQASaga(action) {
  const { file, idList } = action.payload;

  try {
    yield all(
      idList.map((id) =>
        call(ApiService.addFileType, {
          data: file,
          id,
          object: MODULE_TYPES.jobs,
        })
      )
    );

    yield call(getFileInterprocessQASaga, { id: idList[0] });
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.addFileInterprocessQA.failure());
    return;
  }

  yield put(actions.addFileInterprocessQA.success());
}

export function* getFileInterprocessQASaga(actionOrArgument) {
  const { id } = actionOrArgument.payload ?? actionOrArgument;

  let requestValue;

  const isClient = AuthService.isClient();

  const currentApi = isClient
    ? ApiService.getClientFileType
    : ApiService.getFileType;

  try {
    requestValue = yield call(currentApi, {
      id,
      object: MODULE_TYPES.jobs,
    });
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getFileInterprocessQA.failure());
    return;
  }

  yield put(
    actions.getFileInterprocessQA.success({ data: { [id]: requestValue } })
  );
}

export function* getJobOperationFileSaga(action) {
  const { path } = action.payload;
  let requestValueDownloadFile;
  try {
    requestValueDownloadFile = yield call(ApiService.downloadBase64File, path);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getJobOperationFile.failure());
    return;
  }

  const contentType = requestValueDownloadFile.headers['content-type'];
  const url = `data:${contentType};base64,${requestValueDownloadFile.data}`;

  const fileBlob = yield call(getBlobUrlForFile, url);

  const dataUrl = { [path]: { url: fileBlob, type: contentType, load: true } };

  yield put(actions.getJobOperationFile.success({ dataUrl }));
}

export function* removeFileInterprocessQASaga(action) {
  const { id, path } = action.payload;
  const isClient = AuthService.isClient();

  const currentApi = isClient
    ? ApiService.removeClientFileType
    : ApiService.removeFileType;

  try {
    yield call(currentApi, {
      path,
    });

    yield call(getFileInterprocessQASaga, { id });
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.removeFileInterprocessQA.failure());
    return;
  }

  yield put(actions.removeFileInterprocessQA.success());
}

export function* getJobListSaga(action) {
  const { clearLoad } = action.payload;
  clearLoad?.();

  const filterSearch = yield select((state) =>
    state?.jobs?.filterValue?.search?.trim()
  ) ?? '';

  const params = { status: 'NEEDS_QA' };

  const filterMyJobs = { filter: filterSearch };
  const filterJobs = { ...params, filter: filterSearch };

  const isIncludeOnlyWorkerWithoutQA =
    AuthService.isIncludeOnlyWorkerWithoutQA();
  const isIncludeOnlyQAWithoutWorker =
    AuthService.isIncludeOnlyQAWithoutWorker();
  const isIncludeWorkerAndQA = AuthService.isIncludeWorkerAndQA();

  let requestValueMyJobs;
  let requestValueJobs;

  try {
    requestValueMyJobs = yield call(ApiService.getMyJobs, filterMyJobs);
    if (isIncludeOnlyQAWithoutWorker || isIncludeWorkerAndQA) {
      requestValueJobs = yield call(ApiService.getJobList, filterJobs);
    }
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getJobList.failure());
    return;
  }

  let validData = {};
  if (isIncludeOnlyWorkerWithoutQA) {
    validData = getValidMyJobList(requestValueMyJobs);
  }
  if (isIncludeOnlyQAWithoutWorker || isIncludeWorkerAndQA) {
    const validDataMyJobs = getValidMyJobList(requestValueMyJobs);
    const currentJobsData = getValidInterprocessJobList(requestValueJobs.items);

    validData = {
      ...validDataMyJobs,
      data: { ...validDataMyJobs.data, ...currentJobsData.data },
      sortIds: [...validDataMyJobs.sortIds, ...currentJobsData.sortIds],
      interprocessIds: currentJobsData.sortIds,
      interprocessQuantity: currentJobsData.interprocessQuantity,
    };
  }

  yield put(actions.getJobList.success({ ...validData }));
}

export function* moveJobToStepSaga(action) {
  const params = action.payload;
  const jobId = params.data.ids[0];
  try {
    yield call(ApiService.moveJobToStep, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.moveJobToStep.failure());
    return;
  }
  yield put(worklogsActions.getWorklog.start(jobId));
  yield put(actions.getJob.start({ id: jobId }));
  yield put(actions.moveJobToStep.success());
}

export function* checkJobSaga(action) {
  const params = action.payload;
  let requestValue;
  try {
    requestValue = yield call(ApiService.getJob, params, { validate: true });
  } catch (errors) {
    const errorStatus = errors.response.status;

    if (errorStatus === 404 || errorStatus === 403) {
      const typeError =
        errorStatus === 404
          ? JOB_ERROR_QR_CODE.unknown.type
          : JOB_ERROR_QR_CODE.isNotAvailable.type;

      yield put(actions.checkJob.failure({ error: typeError }));
      return;
    }

    yield put(actions.checkJob.failure());
    handleRequestError(errors);
    return;
  }

  const jobId = requestValue.id;

  yield put(actions.checkJob.success({ jobId }));
}

export function* getJobSummarySaga(action) {
  const params = action.payload;
  let requestValue;

  try {
    requestValue = yield call(ApiService.getJobSummary, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getJobSummary.failure());
    return;
  }

  const availableIds = requestValue.available.map(({ id }) => id) || [];
  const unavailableIds = requestValue.not_available.map(({ id }) => id) || [];

  yield put(
    actions.getJobSummary.success({
      availableIds,
      unavailableIds,
      sortIds: [...availableIds, ...unavailableIds],
    })
  );
}

export function* getJobQrSaga(action) {
  const params = action.payload;

  let requestValueDownloadFile;

  try {
    requestValueDownloadFile = yield call(ApiService.getJobQr, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getJobQr.failure());
    return;
  }

  const url = `data:${requestValueDownloadFile.headers['content-type']};base64,${requestValueDownloadFile.data}`;
  const fileBlob = yield call(() => getBlobUrlForFile(url));
  window.open(fileBlob, 'EPrescription');

  yield put(actions.getJobQr.success());
}

export function* getJobSeriesQrSaga(action) {
  const params = action.payload;

  let requestValueDownloadFile;

  try {
    requestValueDownloadFile = yield call(ApiService.getJobQrSeries, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getJobSeriesQr.failure());
    return;
  }

  const url = `data:${requestValueDownloadFile.headers['content-type']};base64,${requestValueDownloadFile.data}`;
  const fileBlob = yield call(() => getBlobUrlForFile(url));
  window.open(fileBlob, 'EPrescription');

  yield put(actions.getJobSeriesQr.success());
}

export function* submitJobNCRSaga(action) {
  const params = action.payload;

  try {
    yield call(ApiService.submitJobNCR, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.submitJobNCR.failure());
    return;
  }

  yield put(actions.changeIsScrollJob(true));
  yield put(actions.submitJobNCR.success());

  NavigationService.navigateToPath(ROUTES.jobList.path);
}

export function* getReasonRejectJobSaga() {
  let requestValue;
  try {
    requestValue = yield call(ApiService.getReasonRejectJob);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getReasonRejectJob.failure());
    return;
  }

  yield put(actions.getReasonRejectJob.success({ data: requestValue }));
}

export function* takeBatchJobSaga(action) {
  const { availableListIds, progressListIds } = action.payload || {};
  let requestValue;

  try {
    requestValue = yield all(
      availableListIds.map((ids) => call(ApiService.takeJob, { ids }))
    );
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.takeBatchJob.failure());
    return;
  }

  const sortAvailableIdsList = requestValue.map((item) =>
    item.map(({ id }) => id)
  );
  const queryAvailableIds = sortAvailableIdsList.reduce((acc, item) => {
    return [...acc, ...item];
  }, []);

  const queryProgressIds = progressListIds.reduce((acc, item) => {
    return [...acc, ...item];
  }, []);

  const sortIdsList = [...sortAvailableIdsList, ...progressListIds];

  const queryIds = [...queryAvailableIds, ...queryProgressIds];

  NavigationService.navigateToPath(
    getRoutePath(ROUTES.jobDetailsBatch.path, null, {
      ids: queryIds,
    })
  );

  yield put(actions.takeBatchJob.success(sortIdsList));
}

export function* getBatchJobSaga(action) {
  const params = action.payload;
  let requestValue;
  try {
    requestValue = yield all(
      params.map((ids) => call(ApiService.getJob, ids[0]))
    );
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getBatchJob.failure());
    return;
  }

  const data = requestValue.reduce((acc, item) => {
    const listIds = params.find((arrayId) => arrayId.includes(item.id));

    const jobDataList = listIds.reduce((jobAcc, itemJobId) => {
      return { ...jobAcc, [itemJobId]: { ...item, id: itemJobId } };
    }, {});

    return { ...acc, ...jobDataList };
  }, {});

  yield put(actions.getBatchJob.success(data));
}

export function* submitBatchJobSaga(action) {
  const data = action.payload;
  let requestValue;

  try {
    requestValue = yield all(
      data.map((item) => call(ApiService.submitJob, item.data))
    );
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.submitBatchJob.failure());
    return;
  }
  const jobDataList = requestValue.reduce((acc, item) => {
    return [...acc, ...item];
  }, []);

  const doneJobIds = jobDataList
    .filter((item) => {
      const isLastOperation =
        data.find(({ data: jobValue }) => jobValue.ids.includes(item.id))
          ?.isLastOperation || false;
      return item.status === JOBS_STATUS.done || isLastOperation;
    })
    .map(({ id }) => id);

  const sortIds = requestValue.reduce((acc, item) => {
    const arrayIds = item
      .map(({ id }) => id)
      .filter((jobId) => !doneJobIds.includes(jobId));

    return [...acc, ...(arrayIds.length > 0 ? [arrayIds] : [])];
  }, []);

  const sortData = jobDataList.filter(({ id }) => !doneJobIds.includes(id));

  const currentIds = sortData.map(({ id }) => id);

  yield put(
    actions.submitBatchJob.success({
      data: sortData,
      sortIds,
    })
  );

  if (currentIds.length > 0) {
    NavigationService.replacePath(
      getRoutePath(ROUTES.jobDetailsBatch.path, null, {
        ids: currentIds,
      })
    );
  } else {
    NavigationService.navigateToPath(ROUTES.jobList.path);
  }
}

export function* jobsSagas() {
  yield all([
    yield takeLatest(actions.submitJob.start, submitJobSaga),
    yield takeLatest(actions.takeJob.start, takeJobSaga),
    yield takeLatest(actions.getJob.start, getJobSaga),
    yield takeLatest(actions.rejectJob.start, rejectJobSaga),
    yield takeLatest(actions.approveJobQA.start, approveJobQASaga),
    yield takeLatest(actions.takeJobQA.start, takeJobQASaga),
    yield takeLatest(actions.cancelJob.start, cancelJobSaga),
    yield takeLatest(
      actions.addFileInterprocessQA.start,
      addFileInterprocessQASaga
    ),
    yield takeLatest(
      actions.getFileInterprocessQA.start,
      getFileInterprocessQASaga
    ),
    yield takeEvery(actions.getJobOperationFile.start, getJobOperationFileSaga),
    yield takeLatest(
      actions.removeFileInterprocessQA.start,
      removeFileInterprocessQASaga
    ),
    yield takeLatest(actions.getJobList.start, getJobListSaga),
    yield takeLatest(actions.moveJobToStep.start, moveJobToStepSaga),
    yield takeLatest(actions.checkJob.start, checkJobSaga),
    yield takeLatest(actions.getJobQr.start, getJobQrSaga),
    yield takeLatest(actions.getJobSeriesQr.start, getJobSeriesQrSaga),
    yield takeLatest(actions.getJobSummary.start, getJobSummarySaga),
    yield takeLatest(actions.submitJobNCR.start, submitJobNCRSaga),
    yield takeLatest(actions.getReasonRejectJob.start, getReasonRejectJobSaga),
    yield takeLatest(actions.takeBatchJob.start, takeBatchJobSaga),
    yield takeLatest(actions.getBatchJob.start, getBatchJobSaga),
    yield takeLatest(actions.submitBatchJob.start, submitBatchJobSaga),
  ]);
}
