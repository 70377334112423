import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import { handleRequestError } from 'services/api/apiTools';
import {
  addIdTravelers,
  getParsingArrayToObj,
  getValidTravelerStepList,
  structureTransformationFiles,
} from 'helpers/functions';
import { getRoutePath } from 'helpers/path';
import { ROUTES } from 'router/routes';
import NavigationService from 'services/navigation/NavigationService';
import ApiService from 'services/api';
import NotificationsService from 'services/notifications/NotificationsService';
import { TRAVELER_SUCCESS_TOAST_TEXT, VARIANT_UI } from 'constants/ui';
import { MODULE_TYPES } from 'constants/files';
import * as templatesActions from 'store/templates/actions';
import * as actions from './actions';

export function* getTravelerListSaga() {
  let requestValue;
  try {
    requestValue = yield call(ApiService.getTravelerList);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getTravelerList.failure());
    return;
  }
  const validTravelers = addIdTravelers(requestValue);
  const travelersObj = getParsingArrayToObj(validTravelers);
  const travelersIds = Object.keys(travelersObj);

  yield put(
    actions.getTravelerList.success({
      fetchedValue: travelersObj,
      ids: travelersIds,
    })
  );
}

export function* createTravelerSaga(action) {
  const { data, selectTemplateId } = action?.payload || {};
  const newTraveler = yield select((state) => state?.travelers?.newTraveler) ??
    {};
  const currentPartTypes = yield select(
    (state) => state?.partTypes?.partTypeId
  ) ?? null;
  const newTravelerValue = {
    ...newTraveler,
    steps: [],
    part_type_id: currentPartTypes,
    ...data,
  };

  let requestValue;
  try {
    requestValue = yield call(ApiService.createTraveler, newTravelerValue);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.createTraveler.failure());
    return;
  }

  const currentStepList = getValidTravelerStepList(requestValue?.steps);

  const validTravelers = {
    ...requestValue,
    steps: addIdTravelers(currentStepList || []),
  };
  yield put(actions.createTraveler.success({ fetchedValue: validTravelers }));

  if (selectTemplateId) {
    yield put(
      templatesActions.deselectAllTemplateOperationControl({
        templateId: selectTemplateId,
      }) // clearing template values when creating a traveler
    );
  }

  NavigationService.replacePath(
    getRoutePath(ROUTES.treavelersDetails.path, { id: requestValue.id })
  );
}

export function* getTravelerSaga(action) {
  let requestValue;
  try {
    requestValue = yield call(ApiService.getTraveler, action.payload);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getTraveler.failure());
    return;
  }

  const currentStepList = getValidTravelerStepList(requestValue?.steps);

  const validTraveler = {
    ...requestValue,
    steps: addIdTravelers(currentStepList),
  };

  yield put(
    actions.getTraveler.success({
      fetchedValue: { [requestValue.id]: validTraveler },
      id: requestValue.id,
    })
  );
}

export function* addFileTravelerSaga(action) {
  const { file, id } = action.payload;

  try {
    yield call(ApiService.addFileType, {
      data: file,
      id,
      object: MODULE_TYPES.travelers,
    });

    yield call(getFileTravelerSaga, { id });
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.addFileTraveler.failure());
    return;
  }

  yield put(actions.addFileTraveler.success());
}

export function* getFileTravelerSaga(actionOrArgument) {
  const { id } = actionOrArgument.payload ?? actionOrArgument;

  let requestValue;
  try {
    requestValue = yield call(ApiService.getFileType, {
      id,
      object: MODULE_TYPES.travelers,
    });
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getFileTraveler.failure());
    return;
  }

  const validFiles = structureTransformationFiles(requestValue);

  yield put(
    actions.getFileTraveler.success({
      ...validFiles,
    })
  );
}

export function* updateTravelerSaga(action) {
  let requestValue;
  try {
    requestValue = yield call(ApiService.updateTraveler, action.payload);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.updateTraveler.failure());
    return;
  }

  const currentStepList = getValidTravelerStepList(requestValue?.steps);

  const validTraveler = {
    ...requestValue,
    steps: addIdTravelers(currentStepList),
  };

  yield put(
    actions.updateTraveler.success({
      travelerData: { [requestValue.id]: validTraveler },
      travelerId: requestValue.id,
    })
  );
}

export function* removeTravelerSaga(action) {
  const { travelerId, callback } = action.payload;

  try {
    yield call(ApiService.removeTraveler, travelerId);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.removeTraveler.failure());
    return;
  }

  if (typeof callback === 'function') {
    callback();
  }

  yield put(
    actions.removeTraveler.success({
      removeTravelerId: travelerId,
    })
  );
}

export function* submitTravelerSaga(action) {
  let requestValue;
  yield call(updateTravelerSaga, action);
  try {
    requestValue = yield call(ApiService.submitTraveler, action.payload.id);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.submitTraveler.failure());
    return;
  }

  const currentStepList = getValidTravelerStepList(requestValue?.steps);

  const validTraveler = {
    ...requestValue,
    steps: addIdTravelers(currentStepList),
  };

  yield put(
    actions.submitTraveler.success({
      travelerData: { [requestValue.id]: validTraveler },
      travelerId: requestValue.id,
    })
  );

  NotificationsService.throwNotification({
    title: 'Successful',
    text: TRAVELER_SUCCESS_TOAST_TEXT,
    variant: VARIANT_UI.success,
  });
}

export function* getPhaseListSaga() {
  let requestValue;
  try {
    requestValue = yield call(ApiService.getPhaseList);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getPhaseList.failure());
    return;
  }

  const phaseListObject = getParsingArrayToObj(requestValue, 'name');

  yield put(
    actions.getPhaseList.success({
      data: phaseListObject,
    })
  );
}

export function* getSuggestOperationByPhaseSaga(action) {
  const { phase } = action.payload;
  let requestValue;
  try {
    requestValue = yield call(ApiService.getSuggestOperation, phase);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getSuggestOperationByPhase.failure());
    return;
  }

  yield put(
    actions.getSuggestOperationByPhase.success({
      [phase]: requestValue,
    })
  );
}

export function* getSuggestStepByPhaseSaga(action) {
  const { phase } = action.payload;
  let requestValue;
  try {
    requestValue = yield call(ApiService.getSuggestStep, phase);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getSuggestStepByPhase.failure());
    return;
  }

  yield put(
    actions.getSuggestStepByPhase.success({
      [phase]: requestValue,
    })
  );
}

export function* travelersSagas() {
  yield all([
    yield takeLatest(actions.getTravelerList.start, getTravelerListSaga),
    yield takeLatest(actions.createTraveler.start, createTravelerSaga),
    yield takeLatest(actions.getTraveler.start, getTravelerSaga),
    yield takeLatest(actions.addFileTraveler.start, addFileTravelerSaga),
    yield takeLatest(actions.getFileTraveler.start, getFileTravelerSaga),
    yield takeLatest(actions.removeTraveler.start, removeTravelerSaga),
    yield takeLatest(actions.updateTraveler.start, updateTravelerSaga),
    yield takeLatest(actions.submitTraveler.start, submitTravelerSaga),
    yield takeLatest(actions.getPhaseList.start, getPhaseListSaga),
    yield takeLatest(
      actions.getSuggestOperationByPhase.start,
      getSuggestOperationByPhaseSaga
    ),
    yield takeLatest(
      actions.getSuggestStepByPhase.start,
      getSuggestStepByPhaseSaga
    ),
  ]);
}
