// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JobsOperationModalFileList_container__3KevO {\n  position: relative;\n  margin-top: 15px;\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  justify-content: center;\n  grid-row-gap: 32px;\n  row-gap: 32px;\n  grid-column-gap: 32px;\n  column-gap: 32px;\n  width: 600px; }\n\n.JobsOperationModalFileList_leftAlignment__1ReLT {\n  justify-content: flex-start; }\n\n.JobsOperationModalFileList_classNamePDfDocument__3fAx4 .react-pdf__Page__canvas {\n  height: 150px;\n  width: 140px;\n  max-height: 150px;\n  max-width: 140px; }\n", "",{"version":3,"sources":["webpack://src/components/Jobs/JobsOperationModalFileList/JobsOperationModalFileList.module.scss"],"names":[],"mappings":"AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,eAAe;EACf,mBAAmB;EACnB,uBAAuB;EACvB,kBAAa;EAAb,aAAa;EACb,qBAAgB;EAAhB,gBAAgB;EAChB,YAAY,EAAA;;AAGd;EACE,2BAA2B,EAAA;;AAG7B;EAGM,aAAa;EACb,YAAY;EACZ,iBAAiB;EACjB,gBAAgB,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.container {\n  position: relative;\n  margin-top: 15px;\n  display: flex;\n  flex-wrap: wrap;\n  align-items: center;\n  justify-content: center;\n  row-gap: 32px;\n  column-gap: 32px;\n  width: 600px;\n}\n\n.leftAlignment {\n  justify-content: flex-start;\n}\n\n.classNamePDfDocument {\n  :global {\n    .react-pdf__Page__canvas {\n      height: 150px;\n      width: 140px;\n      max-height: 150px;\n      max-width: 140px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "JobsOperationModalFileList_container__3KevO",
	"leftAlignment": "JobsOperationModalFileList_leftAlignment__1ReLT",
	"classNamePDfDocument": "JobsOperationModalFileList_classNamePDfDocument__3fAx4"
};
export default ___CSS_LOADER_EXPORT___;
