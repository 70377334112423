import React from 'react';
import { object, array, func, bool } from 'prop-types';
import { Form, FormControl } from 'react-bootstrap';
import { MAX_LENGTH_INPUT } from 'constants/ui';
import st from './JobsIssueDetails.module.scss';

JobsIssueDetails.propTypes = {
  issueDetailsLabels: array,
  issueDetails: object,
  setIssueDetails: func,
  isShowErrorIssueDetails: bool,
  setIsShowErrorIssueDetails: func,
  errors: object,
};

function JobsIssueDetails({
  issueDetailsLabels = [],
  issueDetails = {},
  setIssueDetails = () => {},
  isShowErrorIssueDetails = false,
  setIsShowErrorIssueDetails = () => {},
  errors = {},
}) {
  const renderIssueDetails = () => {
    return issueDetailsLabels.map((item, index) => {
      const nameInput = item || `additionalProp${index + 1}`;
      return (
        <div key={item} className={st.holderItem}>
          <Form.Label>{item}</Form.Label>

          <FormControl
            name={nameInput}
            value={issueDetails[nameInput] || ''}
            onChange={(e) => {
              setIssueDetails((prev) => ({
                ...prev,
                [nameInput]: e.target.value,
              }));

              setIsShowErrorIssueDetails(false);
            }}
            maxLength={MAX_LENGTH_INPUT}
            isInvalid={isShowErrorIssueDetails && errors?.[nameInput]}
          />
        </div>
      );
    });
  };

  return <div className={st.container}>{renderIssueDetails()}</div>;
}

export default JobsIssueDetails;
