import { createReducer } from '@reduxjs/toolkit';
import {
  REPORT_BREAKDOWN,
  REPORT_FIELD_LIST_EMPLOYEE,
} from 'constants/reports';
import { PAGE_SIZE } from 'constants/ui';
import * as actions from './actions';

export const initialState = {
  reports: {
    data: [],
    isLoading: false,
  },
  fetchParams: {
    pagination: {
      page: 0,
      size: PAGE_SIZE,
      count: 0,
    },
    isLoading: false,
  },
  settings: {
    reportType: REPORT_BREAKDOWN[0],
    timeFrom: new Date(),
    timeTo: new Date(),
    filters: [],
    fields: REPORT_FIELD_LIST_EMPLOYEE,
  },
  newSettings: {
    reportType: REPORT_BREAKDOWN[0],
    timeFrom: new Date(),
    timeTo: new Date(),
    filters: [],
    fields: REPORT_FIELD_LIST_EMPLOYEE,
  },
  file: {
    data: '',
    isLoading: false,
    loadingFile: false,
  },
};

export const reportsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.getReportList.start, (state) => {
      return {
        ...state,
        reports: {
          ...state.reports,
          isLoading: true,
        },
        settings: {
          ...state.newSettings,
        },
      };
    })
    .addCase(actions.getReportList.success, (state, { payload }) => {
      const { report, page, count, size, fields } = payload;

      return {
        ...state,
        reports: {
          ...state.reports,
          data: report,
          isLoading: false,
        },
        fetchParams: {
          ...state.fetchParams,
          pagination: {
            ...state.fetchParams.pagination,
            page,
            count,
            size,
          },
          isLoading: false,
        },
        settings: {
          ...state.settings,
          fields,
        },
      };
    })
    .addCase(actions.getReportList.failure, (state) => ({
      ...state,
      reports: {
        ...state.reports,
        isLoading: false,
      },
    }))
    .addCase(actions.changeSettingsReport, (state, { payload }) => {
      return {
        ...state,
        newSettings: {
          ...state.newSettings,
          ...payload,
        },
      };
    })
    .addCase(actions.clearNewSettings, (state) => {
      return {
        ...state,
        newSettings: {
          ...state.settings,
        },
      };
    })
    .addCase(actions.changePage, (state, action) => {
      const page = action.payload;
      return {
        ...state,
        fetchParams: {
          ...state.fetchParams,
          pagination: {
            ...state.fetchParams.pagination,
            page,
          },
        },
      };
    })
    .addCase(actions.changePageSize, (state, action) => {
      const size = action.payload;
      return {
        ...state,
        fetchParams: {
          ...state.fetchParams,
          pagination: {
            ...state.fetchParams.pagination,
            size,
          },
        },
      };
    })
    .addCase(actions.loadFileCSV.start, (state) => {
      return {
        ...state,
        file: {
          ...state.file,
          isLoading: true,
          loadingFile: false,
        },
      };
    })
    .addCase(actions.loadFileCSV.success, (state, action) => {
      const data = action.payload;
      return {
        ...state,
        file: {
          ...state.file,
          data,
          isLoading: true,
          loadingFile: true,
        },
      };
    })
    .addCase(actions.loadFileCSV.failure, (state) => {
      return {
        ...state,
        file: {
          ...state.file,
          isLoading: false,
          loadingFile: false,
        },
      };
    })
    .addCase(actions.changeLoadingFile, (state, action) => {
      const data = action.payload;
      return {
        ...state,
        file: {
          ...state.file,
          loadingFile: data,
          isLoading: data,
        },
      };
    });
});
