import React, { useMemo } from 'react';
import { Modal as ModalBootstrap, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { VARIANT_OUTLINE_UI, VARIANT_UI } from 'constants/ui';
import cn from 'classnames';
import st from './Modal.module.scss';
import './Modal.scss';

Modal.propTypes = {
  title: PropTypes.string,
  size: PropTypes.string,
  titleAcceptBtn: PropTypes.string,
  children: PropTypes.node,
  onAcceptBtn: PropTypes.func,
  heightBody: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  disabledAcceptBtn: PropTypes.bool,
  buttonBottomLeft: PropTypes.func,
  buttonBottomRight: PropTypes.func,
  closeButton: PropTypes.bool,
  backdrop: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  onClose: PropTypes.func,
  onHide: PropTypes.func,
  variantAcceptBtn: PropTypes.string,
  classNameBody: PropTypes.string,
  classNameTitle: PropTypes.string,
  classNameButtonBottomGroup: PropTypes.string,
  fullWidthDesktop: PropTypes.bool,
};

export default function Modal({
  show = false,
  setShow = () => {},
  title = '',
  size = 'md',
  titleAcceptBtn = 'Accept',
  heightBody,
  disabledAcceptBtn = false,
  children,
  onAcceptBtn = () => {},
  buttonBottomLeft,
  buttonBottomRight,
  closeButton = true,
  backdrop = true,
  onClose = () => {},
  onHide = () => {},
  variantAcceptBtn = VARIANT_UI.primary,
  classNameBody = '',
  classNameTitle = '',
  classNameButtonBottomGroup = '',
  classNameModal = '',
  fullWidthDesktop = false,
}) {
  const classFullWidth = 'fullWidth';

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  const handleHide = () => {
    handleClose();
    onHide();
  };

  const handleAccept = () => {
    onAcceptBtn();
    handleClose();
  };

  const currentHeightBody = useMemo(() => {
    switch (typeof heightBody) {
      case 'number':
        return `${heightBody}px`;
      case 'string':
        return heightBody;
      default:
        return 'auto';
    }
  }, [heightBody]);

  return (
    <>
      <ModalBootstrap
        show={show}
        onHide={handleHide}
        size={size}
        centered
        backdrop={backdrop}
        className={cn(classNameModal, { [classFullWidth]: fullWidthDesktop })}>
        <ModalBootstrap.Header closeButton={closeButton}>
          <ModalBootstrap.Title className={classNameTitle}>
            {title}
          </ModalBootstrap.Title>
        </ModalBootstrap.Header>
        <ModalBootstrap.Body
          className={cn(st.modalBody, classNameBody)}
          style={{ height: currentHeightBody }}>
          {children}
        </ModalBootstrap.Body>
        <ModalBootstrap.Footer>
          <div className={cn(st.buttonBottomGroup, classNameButtonBottomGroup)}>
            <div className={st.buttonBottomGroupLeft}>
              {typeof buttonBottomLeft === 'function' &&
                buttonBottomLeft({ onClose: handleClose })}
            </div>
            <div className={st.buttonBottomGroupRigth}>
              {typeof buttonBottomRight === 'function' ? (
                buttonBottomRight({ onClose: handleClose, onHide: handleHide })
              ) : (
                <>
                  <Button
                    variant={VARIANT_OUTLINE_UI.secondary}
                    onClick={handleHide}>
                    Cancel
                  </Button>
                  <Button
                    variant={variantAcceptBtn}
                    onClick={handleAccept}
                    disabled={disabledAcceptBtn}>
                    {titleAcceptBtn}
                  </Button>
                </>
              )}
            </div>
          </div>
        </ModalBootstrap.Footer>
      </ModalBootstrap>
    </>
  );
}
