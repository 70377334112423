import React from 'react';
import { Button } from 'react-bootstrap';
import { VARIANT_OUTLINE_UI } from 'constants/ui';
import ModalButton from 'componentsShared/ModalButton/ModalButton';
import Text from 'componentsShared/Text/Text';
import JobsControlValues from 'components/Jobs/JobsControlValues/JobsControlValues';
import st from './ModalBatchJobControlValue.module.scss';

function ModalBatchJobControlValue({
  dataCV = {},
  jobControlValues,
  setJobControlValues,
  CVDataList = {},
  errorsControlValues,
  isShowErrorControlValues,
  setIsShowErrorControlValues,
}) {
  const getControlValuesHeaders = (bundleId) => {
    const headers = CVDataList?.[bundleId]?.headers;
    if (!headers || headers.length === 0) {
      return [];
    }

    return (
      headers.map((item) => ({
        value: item.value,
      })) || []
    );
  };

  const renderIdsList = (jobIds = []) => {
    if (!jobIds || jobIds.length === 0) {
      return '';
    }

    return jobIds.map(
      (item, index) => `${jobIds.length > 0 && index !== 0 ? ', ' : ''}#${item}`
    );
  };

  return (
    <ModalButton
      titleAcceptBtn='OK'
      title='Control Values'
      buttonRenderer={({ onClick }) => (
        <Button variant={VARIANT_OUTLINE_UI.primary} onClick={onClick}>
          Control Values
        </Button>
      )}
      bodyContent={() => {
        return (
          <div className={st.container}>
            {Object.entries(dataCV).map(([bundleId, jobIds]) => {
              return (
                <div className={st.itemCV} key={`control-value-${bundleId}`}>
                  <Text varinant='normal2' bold>
                    {renderIdsList(jobIds)}:
                  </Text>
                  <div className={st.holderInputListCV}>
                    <JobsControlValues
                      controlValuesHeaders={getControlValuesHeaders(bundleId)}
                      jobControlValues={jobControlValues}
                      setJobControlValues={setJobControlValues}
                      classNameHolderItemCV={st.classNameHolderItemCV}
                      classNameContainer={st.classNameContainer}
                      errors={errorsControlValues}
                      isShowError={isShowErrorControlValues}
                      setIsShowErrorControlValues={setIsShowErrorControlValues}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        );
      }}
    />
  );
}

export default ModalBatchJobControlValue;
