import React, { useEffect, useRef, useState } from 'react';
import { Popover, Overlay } from 'react-bootstrap';
import * as travelersActions from 'store/travelers/actions';
import { useDispatch } from 'react-redux';
import MultiDropDownContent from 'components/MultiDropDownContent/MultiDropDownContent';
import { ArrowForwardIcon } from 'assets/icons';
import Text from 'componentsShared/Text/Text';
import cn from 'classnames';
import st from './MultiDropDown.module.scss';

function MultiDropDown({
  phaseArray = [],
  handleSelectStepIndex = () => {},
  type = '',
  selectStepString = '',
  isError = false,
  firstJobId = '',
}) {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [target, setTarget] = useState(null);
  const ref = useRef(null);
  const popoverBodyRef = useRef(null);

  const handleClick = (event) => {
    setShow(!show);
    setTarget(event.target);
  };

  const onSelectDropdown = () => {
    setShow(false);
  };

  useEffect(() => {
    dispatch(travelersActions.getPhaseList.start());
  }, [dispatch]);

  return (
    <div ref={ref} className={st.container}>
      <div
        onClick={handleClick}
        className={cn(st.selectStep, {
          [st.selectStepEmpty]: !selectStepString,
          [st.isError]: isError,
        })}>
        <Text variant='normal' className={st.selectText}>
          {selectStepString || 'Select a step'}{' '}
        </Text>
        <ArrowForwardIcon
          className={cn(st.selectStepIcon, { [st.selectStepIconOpen]: show })}
        />
      </div>

      <Overlay
        show={show}
        target={target}
        placement='top-start'
        container={ref}
        rootClose
        onHide={() => setShow(false)}>
        <Popover id='popover-container'>
          <Popover.Body ref={popoverBodyRef}>
            <MultiDropDownContent
              phaseArray={phaseArray}
              handleSelectStepIndex={handleSelectStepIndex}
              type={type}
              onSelectDropdown={onSelectDropdown}
              selectStepString={selectStepString}
              firstJobId={firstJobId}
            />
          </Popover.Body>
        </Popover>
      </Overlay>
    </div>
  );
}

export default MultiDropDown;
