import { store } from 'store/store';
import * as uiActions from 'store/ui/actions';
import { VARIANT_UI } from 'constants/ui';

import { v4 as uuidv4 } from 'uuid';

const NotificationsService = {
  throwNotification({
    title = 'Info',
    text = '',
    delay = 0,
    variant = VARIANT_UI.secondary,
    isCloseButton = true,
  }) {
    const toastId = uuidv4();
    store.dispatch(
      uiActions.addToast({
        title,
        text,
        delay,
        variant,
        isCloseButton,
        toastId,
      })
    );
  },
};

export default NotificationsService;
