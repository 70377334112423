import React, { useState } from 'react';
import { VARIANT_OUTLINE_UI } from 'constants/ui';
import { Button } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as jobsActions from 'store/jobs/actions';
import Modal from 'componentsShared/Modal/Modal';
import Text from 'componentsShared/Text/Text';
import st from './JobListActionButtonBatch.module.scss';

function JobListActionButtonBatch() {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);

  const isCreateBatch =
    useSelector((state) => state?.jobs?.jobsBatch?.isCreateBatch) ?? false;
  const selectJobIdList =
    useSelector((state) => state?.jobs?.jobsBatch?.selectJobIdList) ?? [];
  const jobData = useSelector((state) => state?.jobs?.jobs?.data) ?? {};
  const showButtonBatch =
    useSelector((state) => state?.jobs?.jobsBatch?.showButtonBatch) ?? false;

  const getBatchDataList = () => {
    const currentJobData = Object.values(jobData).reduce((acc, item) => {
      return [
        ...acc,
        ...(item?.list?.map((itemJob) => ({ ...itemJob, batchId: item.id })) ||
          []),
      ];
    }, []);

    const selectJobList = currentJobData.filter(({ id }) =>
      selectJobIdList.includes(id)
    );

    const summaryList = selectJobList.reduce((acc, valueJob) => {
      const cuurentJobSummaru = {
        phase: valueJob.current_step.phase,
        step: valueJob.current_step.name,
        operation: valueJob.current_operation.name,
        ids: [valueJob.id],
        isProgress: !!valueJob.user,
      };

      return {
        ...acc,
        [valueJob.batchId]: {
          ...cuurentJobSummaru,
          ids: [...(acc?.[valueJob.batchId]?.ids || []), valueJob.id],
        },
      };
    }, {});

    return summaryList;
  };

  const batchDataList = getBatchDataList();

  const onSubmitJobs = () => {
    const jobIdsListObj = Object.values(batchDataList).reduce(
      (acc, item) => {
        return {
          availableListIds: [
            ...acc.availableListIds,
            ...(!item.isProgress ? [item.ids] : []),
          ],
          progressListIds: [
            ...acc.progressListIds,
            ...(item.isProgress ? [item.ids] : []),
          ],
        };
      },
      {
        availableListIds: [],
        progressListIds: [],
      }
    );

    dispatch(jobsActions.changeIsCreateBatch(false));
    dispatch(jobsActions.takeBatchJob.start(jobIdsListObj));
  };

  const onChangeIsCreateBatch = (value) => {
    dispatch(jobsActions.clearSelectJobIdList());
    dispatch(jobsActions.changeIsCreateBatch(value));
  };

  const renderButtonBottomLeft = ({ onClose }) => {
    return (
      <div className={st.holderButtonBottom}>
        <Button variant={VARIANT_OUTLINE_UI.primary} onClick={onClose}>
          Cancel
        </Button>
      </div>
    );
  };

  const renderJobDataList = (ids = []) => {
    if (!ids || ids.length === 0) {
      return null;
    }

    return ids.map((id) => (
      <div key={`job-id-${id}`}>
        <Text variant='normal' className={st.jobId}>
          #{id}
        </Text>
      </div>
    ));
  };

  const renderBatchDataList = () => {
    const batchDataListArray = Object.values(batchDataList);

    if (batchDataListArray.length === 0) {
      return null;
    }

    return batchDataListArray.map((item) => {
      const key = `batch-${item.step}-${item.ids[0]}`;
      return (
        <div className={st.holderSummaryItem} key={key}>
          <div>
            <Text variant='normal' bold className={st.title}>
              {item.phase}/{item.step}/{item.operation}:
            </Text>
          </div>
          <div className={st.jobIdList}>{renderJobDataList(item.ids)}</div>
        </div>
      );
    });
  };

  const renderButtonBottomRight = () => {
    return (
      <div className={st.holderButtonBottom}>
        <Button onClick={onSubmitJobs}>Submit</Button>
      </div>
    );
  };

  if (!showButtonBatch) {
    return <></>;
  }

  return (
    <div>
      {isCreateBatch && (
        <div className={st.buttonGroup}>
          <Button
            onClick={() => onChangeIsCreateBatch(false)}
            variant={VARIANT_OUTLINE_UI.secondary}>
            Cancel
          </Button>
          <Button
            onClick={() => setShowModal(true)}
            disabled={selectJobIdList.length === 0}>
            Take Batch ({selectJobIdList.length})
          </Button>
        </div>
      )}
      {!isCreateBatch && (
        <Button
          onClick={() => onChangeIsCreateBatch(true)}
          variant={VARIANT_OUTLINE_UI.dark}>
          Create Batch
        </Button>
      )}

      <Modal
        title='Job Batch'
        size='lg'
        show={showModal}
        setShow={setShowModal}
        buttonBottomLeft={renderButtonBottomLeft}
        buttonBottomRight={renderButtonBottomRight}
        heightBody='calc(100vh - 240px)'
        classNameBody={st.modalBody}>
        <div className={st.holderSummaryList}>{renderBatchDataList()}</div>
      </Modal>
    </div>
  );
}

export default JobListActionButtonBatch;
