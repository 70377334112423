import React, { useEffect, useMemo, useState } from 'react';
import AdminHeaderLayout from 'layouts/AdminHeaderLayout/AdminHeaderLayout';
import { useSelector, useDispatch } from 'react-redux';
import * as employeesActions from 'store/employees/actions';
import ModalButton from 'componentsShared/ModalButton/ModalButton';
import {
  MANAGER_NOTIFICATIONS_LABEL,
  NO_INFORMATION_FOUND_SEARCH_TEXT,
  TIME_SEARCH_DEBOUNCE,
  VARIANT_OUTLINE_UI,
  VARIANT_UI,
} from 'constants/ui';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { EditIcon } from 'assets/icons';
import InviteEmployeeForm from 'components/Form/InviteEmployeeForm/InviteEmployeeForm';
import OffcanvasEmployeeEdit from 'components/Offcanvas/OffcanvasEmployeeEdit/OffcanvasEmployeeEdit';
import { debounce } from 'helpers/functions';
import { ACTION_COLUMN } from 'constants/table';
import { getQueryVariable } from 'helpers/path';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from 'react-router';
import ListComponent from 'componentsShared/ListComponent/ListComponent';
import FooterPagination from 'componentsShared/FooterPagination/FooterPagination';
import { USER_ROLES } from 'constants/userRoles';
import st from './AdminEmployeesPage.module.scss';

const columnTable = [
  { dataKey: 'name', width: 274, title: 'Name', flexGrow: true },
  { dataKey: 'email', width: 250, title: 'Email', truncated: true },
  { dataKey: 'status', width: 200, title: 'Status', truncated: true },
  { dataKey: 'roles', width: 200, title: 'Role' },
  { dataKey: ACTION_COLUMN, width: 100, title: '' },
];

const debouncedSearch = debounce((onSearch, data) => {
  onSearch(data);
}, TIME_SEARCH_DEBOUNCE);

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Required'),
  name: Yup.string().required('Required'),
  roles: Yup.array().min(1).required('Required'),
  password: Yup.string().required('Required'),
  isManagerNotification: Yup.boolean(),
  isQANotification: Yup.boolean(),
});

function AdminEmployeesPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryVariable = getQueryVariable(location.search);
  const currentPage = queryVariable.page || 0;

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };
  const { register, formState, handleSubmit, reset, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [selectEmployeeId, setSelectEmployeeId] = useState();

  const isLoadingEmployee =
    useSelector((state) => state?.employees?.employees?.isLoading) ?? false;
  const isLoadingCreateEmployee =
    useSelector((state) => state?.employees?.employees?.isLoadingCreate) ??
    false;
  const isLoadingChangeEmployee =
    useSelector((state) => state?.employees?.employees?.isLoadingChange) ??
    false;
  const employeeList =
    useSelector((state) => state?.employees?.employees?.data) ?? {};
  const serchValue =
    useSelector((state) => state?.employees?.fetchParams?.search) ?? '';
  const isLoadingSearch =
    useSelector((state) => state?.employees?.fetchParams?.isLoading) ?? false;
  const paginationObj =
    useSelector((state) => state?.employees?.fetchParams?.pagination) ?? {};

  const isLoading =
    isLoadingEmployee || isLoadingCreateEmployee || isLoadingChangeEmployee;
  const employeeListArray = Object.values(employeeList);

  const dataTable = employeeListArray.map((employee) => {
    return {
      id: employee.id,
      name: employee.name,
      email: employee.login,
      status: employee.status,
      roles:
        employee?.roles
          .filter(
            (item) =>
              item !== USER_ROLES.managerNotifications &&
              item !== USER_ROLES.QANotifications
          )
          .join(', ') || '',
    };
  });

  const onSubmit = (value, onCloseModal) => {
    const currentRole = [
      ...value.roles.map((item) => item.value),
      ...(value.isManagerNotification ? [USER_ROLES.managerNotifications] : []),
      ...(value.isQANotification ? [USER_ROLES.QANotifications] : []),
    ];

    const validValue = {
      name: value.name.trim(),
      login: value.email.trim(),
      roles: currentRole,
      password: value.password.trim(),
    };
    dispatch(
      employeesActions.createEmployee.start({
        valueForm: validValue,
        onCloseModal,
      })
    );
  };

  const actionsTable = [
    {
      icon: <EditIcon className={st.editIcon} />,
      onClick: (rowData) => {
        setSelectEmployeeId(rowData.id);
        setShowOffcanvas(true);
      },
    },
  ];

  const initialValueEditForm = useMemo(() => {
    const curretnEmployee = employeeList[selectEmployeeId];
    if (!curretnEmployee) {
      return undefined;
    }
    return {
      login: curretnEmployee?.login || '',
      name: curretnEmployee?.name || '',
      roles:
        curretnEmployee?.roles?.map((item) => ({
          label:
            item === USER_ROLES.managerNotifications
              ? MANAGER_NOTIFICATIONS_LABEL
              : item,
          value: item,
        })) || [],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectEmployeeId]);

  const onChangeFilterSearch = (value) => {
    dispatch(employeesActions.changeFilterSearch(value));
  };

  const loadEmployeeList = (isClearPage) => {
    dispatch(
      employeesActions.getEmployeeList.start({
        filter: serchValue,
        isPagination: true,
        isClearPage,
      })
    );
  };

  const handleChangeSearch = (value) => {
    onChangeFilterSearch(value);
    if (value) {
      return debouncedSearch(() => {
        loadEmployeeList(true);
      });
    }
    loadEmployeeList(true);
  };

  const handleChangePage = (page) => {
    dispatch(employeesActions.changePage(page));
    loadEmployeeList();
  };

  useEffect(() => {
    dispatch(employeesActions.getUserRoles.start());
    if (!+currentPage) {
      onChangeFilterSearch('');
    }
    handleChangePage(+currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <AdminHeaderLayout
      title='Employees'
      classNameContentHolder={st.layoutContentHolder}
      searchValue={serchValue}
      handleChangeSearch={handleChangeSearch}
      isLoadingSearch={isLoadingSearch}
      placeholderSearch='Search by name'
      inviteButton={() => (
        <ModalButton
          onHide={() => {
            reset();
          }}
          title='Add a new employee'
          backdrop='static'
          bodyContent={() => (
            <InviteEmployeeForm
              register={register}
              errors={errors}
              setValue={setValue}
            />
          )}
          buttonBottomRight={({ onHide }) => (
            <>
              <Button variant={VARIANT_OUTLINE_UI.secondary} onClick={onHide}>
                Cancel
              </Button>
              <Button
                disabled={isLoading}
                variant={VARIANT_UI.primary}
                onClick={handleSubmit((value) => onSubmit(value, onHide))}>
                Add Employee
              </Button>
            </>
          )}
          buttonRenderer={({ onClick }) => (
            <Button
              disabled={isLoading}
              variant={VARIANT_UI.primary}
              onClick={onClick}>
              New Employee
            </Button>
          )}
        />
      )}
      renderFooter={() => (
        <FooterPagination
          page={paginationObj.page}
          countItem={paginationObj.count}
          size={paginationObj.size}
          onChange={handleChangePage}
          isFullLine
          isShowPlaceholder={dataTable.length === 0}
        />
      )}>
      <ListComponent
        data={dataTable}
        columns={columnTable}
        actions={actionsTable}
        classNameRowListWrapper={st.rowListWrapper}
        classNameRowListHolder={st.rowListHolder}
        isLoading={isLoading}
        placeholderText={NO_INFORMATION_FOUND_SEARCH_TEXT}
      />

      {initialValueEditForm && (
        <OffcanvasEmployeeEdit
          selectEmployeeId={selectEmployeeId}
          showOffcanvas={showOffcanvas}
          setShowOffcanvas={setShowOffcanvas}
          setSelectEmployeeId={setSelectEmployeeId}
          initialValue={initialValueEditForm}
        />
      )}
    </AdminHeaderLayout>
  );
}

export default AdminEmployeesPage;
