import React, { useEffect, useRef, useState } from 'react';
import { Button, FormControl, FormGroup } from 'react-bootstrap';
import * as notesActions from 'store/notes/actions';
import Text from 'componentsShared/Text/Text';
import CardNotes from 'components/Notes/CardNotes/CardNotes';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { MAX_LENGTH_INPUT, VARIANT_OUTLINE_UI } from 'constants/ui';
import Loader from 'componentsShared/Loader/Loader';
import { array, func, bool } from 'prop-types';
import cn from 'classnames';
import st from './RFQComments.module.scss';

RFQComments.propTypes = {
  notesArray: array,
  handleChangeNote: func,
  handleCreateNotes: func,
  disabledButtons: bool,
};

function RFQComments({
  notesArray,
  handleChangeNote,
  handleCreateNotes,
  disabledButtons = false,
}) {
  const dispatch = useDispatch();
  const isLoading =
    useSelector((state) => state?.notes?.notes?.isLoading) ?? false;
  const isAddingNote =
    useSelector((state) => state?.notes?.isAddingNote) ?? false;

  const modalContentRef = useRef(null);

  const [valueNotes, setValueNotes] = useState('');

  const renderNotesCard = () => {
    return notesArray.map(({ title, text, currentUser }) => {
      return (
        <CardNotes
          title={title}
          text={text}
          isActive={currentUser}
          handleChangeNote={handleChangeNote}
          key={uuidv4()}
          isFullWidth
          isShowPhase={false}
        />
      );
    });
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setValueNotes(value);
  };

  const createNotes = () => {
    handleCreateNotes(valueNotes, true);
    setValueNotes('');
  };

  useEffect(() => {
    if (isAddingNote && modalContentRef.current) {
      modalContentRef.current.scrollTo(0, modalContentRef.current.scrollHeight);
      dispatch(notesActions.changeIsAddingNote());
    }
  }, [isAddingNote, dispatch]);

  return (
    <div>
      <div className={st.holderTitle}>
        <Text variant='normal' className={st.title}>
          Comments
        </Text>
      </div>
      <div className={st.holderModalContent}>
        <div className={st.modalContent} ref={modalContentRef}>
          {notesArray.length > 0 && renderNotesCard()}
        </div>
        <Loader isVisible={isLoading} />
      </div>
      <div
        className={cn(st.modalFooter, {
          [st.modalFooterSelectVisibleCustomer]: false,
        })}>
        <FormGroup>
          <FormControl
            value={valueNotes}
            onChange={handleChange}
            as='textarea'
            rows={3}
            maxLength={MAX_LENGTH_INPUT}
            readOnly={disabledButtons}
          />
        </FormGroup>
        <div className={st.holderButtons}>
          <Button
            onClick={createNotes}
            disabled={!valueNotes || isLoading || disabledButtons}>
            Send Note
          </Button>
          <Button
            onClick={() => setValueNotes('')}
            disabled={!valueNotes || isLoading || disabledButtons}
            variant={VARIANT_OUTLINE_UI.secondary}>
            Clear
          </Button>
        </div>
      </div>
    </div>
  );
}

export default RFQComments;
