// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeaderBurgerPhone_holderBurgerIcon__2piTA {\n  padding: 10px;\n  cursor: pointer; }\n  .HeaderBurgerPhone_holderBurgerIcon__2piTA > svg {\n    width: 25px;\n    height: 25px; }\n\n.HeaderBurgerPhone_buttonGroup__3L-Bg {\n  display: flex;\n  flex-direction: column; }\n", "",{"version":3,"sources":["webpack://src/components/HeaderBurgerPhone/HeaderBurgerPhone.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,eAAe,EAAA;EAFjB;IAKI,WAAW;IACX,YAAY,EAAA;;AAIhB;EACE,aAAa;EACb,sBAAsB,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.holderBurgerIcon {\n  padding: 10px;\n  cursor: pointer;\n\n  & > svg {\n    width: 25px;\n    height: 25px;\n  }\n}\n\n.buttonGroup {\n  display: flex;\n  flex-direction: column;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"holderBurgerIcon": "HeaderBurgerPhone_holderBurgerIcon__2piTA",
	"buttonGroup": "HeaderBurgerPhone_buttonGroup__3L-Bg"
};
export default ___CSS_LOADER_EXPORT___;
