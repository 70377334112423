import { getQueryParam } from 'helpers/path';
import { api } from './ApiService';

export const getReports = ({ query, body }) => {
  const queryParam = getQueryParam(query);

  return api.post(`/reports${queryParam}`, body);
};

export const getReportsFileCSV = ({ query, body }) => {
  const queryParam = getQueryParam(query);

  return api.post(`/reports/import_to_csv${queryParam}`, body, null, true);
};
