import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Text from 'componentsShared/Text/Text';
import { ACTION_COLUMN } from 'constants/table';
import { string, array } from 'prop-types';
import Loader from 'componentsShared/Loader/Loader';
import moment from 'moment';
import cn from 'classnames';
import st from './ListComponent.module.scss';

ListComponent.propTypes = {
  data: array,
  columns: array,
  actions: array,
  classNameRowListWrapper: string,
};

function ListComponent({
  data = [],
  columns = [],
  actions = [],
  classNameRowListWrapper,
  isLoading = false,
  isBackgroundColorGrayLoader = false,
  searchValue = '',
  placeholderText = '',
  placeholderTextSearch = '',
  classNameRowListHolder,
  classNameHeaderHolder = '',
  classNameHeaderText = '',
  classNameHeaderContainer = '',
  classNameColumnHolder = '',
  classNameColumnText = '',
  classNameItemRowContainer = '',
  classNameContainer = '',
  isShowActionColumn = true,
}) {
  const renderAction = (rowData) => {
    return actions.map(({ icon, onClick }) => {
      return (
        <div key={uuidv4()}>
          <div className={st.actionItem} onClick={() => onClick(rowData)}>
            {icon}
          </div>
        </div>
      );
    });
  };

  const renderRowList = () => {
    return data.map((item) => {
      const widthActions = columns?.[columns.length - 1].width || 0;

      return (
        <div
          className={cn(st.itemRowContainer, classNameItemRowContainer)}
          key={uuidv4()}>
          {columns.map((columnValue) => {
            const width = columnValue.width || 0;
            const isFlexGrow = !!columnValue.flexGrow;
            const isTruncated = !!columnValue.truncated;
            const isDate = !!columnValue?.isDate;
            const valid = columnValue?.valid;
            const isClickable = !!columnValue?.onClick;

            const formatDate = columnValue?.formatDate;

            const dataField = item?.[columnValue?.dataKey];
            let text = dataField;

            if (isDate && dataField) {
              text = moment(dataField).format(formatDate);
            }

            if (valid) {
              text = valid(dataField);
            }

            if (columnValue.dataKey === ACTION_COLUMN) {
              return;
            }

            return (
              <div
                key={uuidv4()}
                onClick={
                  isClickable ? () => columnValue.onClick(dataField) : () => {}
                }
                style={{
                  minWidth: width,
                  maxWidth: isFlexGrow ? 'auto' : width,
                }}
                className={cn(
                  st.boxContainer,
                  {
                    [st.flexGrow]: isFlexGrow,
                    [st.truncated]: isTruncated,
                    [st.isClickable]: isClickable,
                  },
                  classNameColumnHolder
                )}>
                <Text varint='normal' className={classNameColumnText}>
                  {text || ''}
                </Text>
              </div>
            );
          })}

          {isShowActionColumn && (
            <div
              className={st.actionWrapper}
              style={{ minWidth: widthActions, maxWidth: widthActions }}>
              {actions.length > 0 && renderAction(item)}
            </div>
          )}
        </div>
      );
    });
  };

  const renderHeader = () => {
    return columns.map((item) => {
      const width = item.width || 0;
      const isFlexGrow = !!item.flexGrow;
      return (
        <div
          className={cn(
            st.boxContainerHeader,
            { [st.flexGrow]: isFlexGrow },
            item.className,
            classNameHeaderHolder
          )}
          key={uuidv4()}
          style={{ minWidth: width, maxWidth: isFlexGrow ? 'auto' : width }}>
          <Text variant='normal' bold className={classNameHeaderText}>
            {item.title}
          </Text>
        </div>
      );
    });
  };

  const renderPlaceholder = () => {
    return (
      <Text variant='normal' className={st.placeholderText}>
        {searchValue ? placeholderTextSearch : placeholderText}
      </Text>
    );
  };

  return (
    <div className={cn(classNameContainer)}>
      <div className={cn(st.headerContainer, classNameHeaderContainer)}>
        {renderHeader()}
      </div>
      <div
        className={cn(st.listRowHolder, classNameRowListHolder, {
          [st.holderPlaceholder]: data.length === 0,
        })}>
        <div
          className={cn(st.listRowContainer, classNameRowListWrapper, {
            [st.containerPlaceholder]: data.length === 0,
          })}>
          {data.length > 0 ? renderRowList() : renderPlaceholder()}
        </div>
        <Loader
          isVisible={isLoading}
          isBackgroundColorGray={isBackgroundColorGrayLoader}
        />
      </div>
    </div>
  );
}

export default ListComponent;
