import React, { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { Button } from 'react-bootstrap';
import Offcanvas from 'componentsShared/Offcanvas/Offcanvas';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as customersActions from 'store/customers/actions';
import { useDispatch, useSelector } from 'react-redux';
import { VARIANT_UI } from 'constants/ui';
import { object, func, bool, oneOfType, number, string } from 'prop-types';
import OffcanvasCustomerEditContent from 'components/Offcanvas/OffcanvasCustomerEditContent/OffcanvasCustomerEditContent';
import { reValidEmail } from 'constants/regulars';
import { CUSTOMER_STATUS } from 'constants/customer';
import ModalBlockCustomerLogin from 'components/Modal/ModalBlockCustomerLogin/ModalBlockCustomerLogin';
import ModalUnblockCustomerLogin from 'components/Modal/ModalUnblockCustomerLogin/ModalUnblockCustomerLogin';
import st from './OffcanvasCustomerEdit.module.scss';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Required')
    .matches(reValidEmail, 'Invalid email'),
  name: Yup.string().required('Required'),
});

OffcanvasCustomerEdit.propTypes = {
  showOffcanvas: bool,
  setShowOffcanvas: func,
  customer: object,
  companyId: oneOfType([number, string]),
  setSelectCustomerId: func,
};

function OffcanvasCustomerEdit({
  showOffcanvas = false,
  customer,
  companyId,
  setShowOffcanvas = () => {},
  setSelectCustomerId = () => {},
}) {
  const dispatch = useDispatch();

  const statusBlocked =
    customer?.status?.toUpperCase() === CUSTOMER_STATUS.BLOCKED;

  const companyName =
    useSelector(
      (state) => state?.customers?.customers?.data?.[companyId]?.name
    ) ?? '';
  const isLoadingCustomer =
    useSelector((state) => state?.customers?.customers?.isLoading) ?? false;
  const isLoadingCreateCustomer =
    useSelector((state) => state?.customers?.customers?.isLoadingCreate) ??
    false;
  const isLoadingChangeCustomer =
    useSelector((state) => state?.customers?.customers?.isLoadingChange) ??
    false;
  const isLoadingResendInvite =
    useSelector(
      (state) => state?.customers?.customers?.isLoadingResendInvite
    ) ?? false;

  const initialValue = useMemo(
    () => ({
      name: customer?.name,
      email: customer?.email,
    }),
    [customer]
  );

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: initialValue,
  };

  const { register, handleSubmit, formState, reset } = useForm(formOptions);
  const { errors, isDirty } = formState;
  const [emailValue, setEmailValue] = useState(initialValue.email);
  const [nameValue, setNameValue] = useState(initialValue.name);

  const isDisabledInvite = !emailValue || !emailValue.match(reValidEmail);

  const isLoading =
    isLoadingCustomer ||
    isLoadingCreateCustomer ||
    isLoadingChangeCustomer ||
    isLoadingResendInvite;

  const isChangedEmail = useMemo(() => {
    return (
      (!initialValue.email && !!emailValue) ||
      emailValue !== initialValue.email ||
      nameValue !== initialValue.name
    );
  }, [initialValue.email, initialValue.name, emailValue, nameValue]);

  const onSubmit = (data, onClose) => {
    const validData = { data, id: customer.id, companyId };
    const isChangeEmail = customer.email !== data.email;
    dispatch(
      customersActions.changeCustomerLogin.start({
        ...validData,
        isChangeEmail,
        onCloseModal: onClose,
      })
    );
    reset();
  };

  const handleBlockCustomer = (onClose) => {
    dispatch(
      customersActions.blockCustomerLogin.start({
        companyId,
        customerId: customer.id,
      })
    );
    onClose();
    reset();
  };

  const handleUnblockCustomer = (
    onClose,
    onHideModal,
    isChangeEmail,
    email
  ) => {
    dispatch(
      customersActions.unblockCustomerLogin.start({
        companyId,
        customerId: customer.id,
        isChangeEmail,
        email,
        name: customer.name,
        onClose,
        reset,
        onHideModal,
      })
    );
  };

  const handleResendInviteWithSaving = (data, onClose) => {
    const validData = { data, customerId: customer.id, companyId };
    const isChangeEmail = customer.email !== data.email;
    dispatch(
      customersActions.resendInviteCustomerWithSaving.start({
        ...validData,
        isChangeEmail,
        onCloseModal: onClose,
      })
    );
    reset();
  };

  return (
    <Offcanvas
      backdrop={false}
      onHide={() => {
        setShowOffcanvas(false);
        setSelectCustomerId(undefined);
      }}
      width={460}
      show={showOffcanvas}
      setShow={setShowOffcanvas}
      title='Customer Details'
      footerContent={({ onHide }) => {
        return (
          <div className={st.buttonGroup}>
            {!statusBlocked ? (
              <>
                <ModalBlockCustomerLogin
                  isLoading={isLoading}
                  handleAcceptBtn={handleBlockCustomer}
                  onHide={onHide}
                />
                <Button
                  variant={VARIANT_UI.primary}
                  type='submit'
                  disabled={!isDirty}
                  onClick={handleSubmit((value) => onSubmit(value, onHide))}>
                  Save Changes
                </Button>
              </>
            ) : (
              <ModalUnblockCustomerLogin
                isLoading={isLoading}
                handleAcceptBtn={handleUnblockCustomer}
                onHide={onHide}
              />
            )}
          </div>
        );
      }}>
      <OffcanvasCustomerEditContent
        companyName={companyName}
        customer={customer}
        onClose={() => setShowOffcanvas(false)}
        onSubmit={handleSubmit(onSubmit)}
        register={register}
        errors={errors}
        setEmailValue={setEmailValue}
        setNameValue={setNameValue}
        isDisabledInvite={isDisabledInvite}
        isChangedEmail={isChangedEmail}
        handleResendInviteWithSaving={handleSubmit((value) => {
          return handleResendInviteWithSaving(value, () =>
            setShowOffcanvas(false)
          );
        })}
        statusBlocked={statusBlocked}
      />
    </Offcanvas>
  );
}

export default OffcanvasCustomerEdit;
