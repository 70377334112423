// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalUnblockCustomerLogin_deleteCustomer__2n_Oz {\n  width: 100%; }\n\n.ModalUnblockCustomerLogin_content___cfwM {\n  display: flex;\n  flex-direction: column;\n  grid-row-gap: 16px;\n  row-gap: 16px; }\n", "",{"version":3,"sources":["webpack://src/components/Modal/ModalUnblockCustomerLogin/ModalUnblockCustomerLogin.module.scss"],"names":[],"mappings":"AAEA;EACE,WAAW,EAAA;;AAGb;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAa;EAAb,aAAa,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.deleteCustomer {\n  width: 100%;\n}\n\n.content {\n  display: flex;\n  flex-direction: column;\n  row-gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"deleteCustomer": "ModalUnblockCustomerLogin_deleteCustomer__2n_Oz",
	"content": "ModalUnblockCustomerLogin_content___cfwM"
};
export default ___CSS_LOADER_EXPORT___;
