import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

export const initialState = {
  isAuthorized: false,
  isTempPassword: false,
  isLoading: false,
  user: {},
  isClient: false,
  tempPassword: '',
  errorCheckClientToken: false,
  versionBackend: {
    isLoading: false,
    version: '',
  },
  urlRedirectAfterAuthorization: '',
};

export const authReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.loginEmployee.start, (state, action) => {
      const password = action?.payload?.password ?? '';
      return {
        ...state,
        isLoading: true,
        isClient: false,
        tempPassword: password,
      };
    })
    .addCase(actions.loginEmployee.success, (state, action) => {
      const isToken = !!action.payload;
      return {
        ...state,
        isLoading: false,
        isAuthorized: isToken,
        isClient: false,
        urlRedirectAfterAuthorization: '',
      };
    })
    .addCase(actions.loginEmployee.failure, (state) => ({
      ...state,
      isLoading: false,
    }))
    .addCase(actions.getUserData.start, (state) => {
      return {
        ...state,
        isLoading: true,
        isClient: false,
      };
    })
    .addCase(actions.getUserData.success, (state, action) => {
      const data = action.payload;

      return {
        ...state,
        isLoading: false,
        user: data,
        isTempPassword: !!data.need_change_password,
        tempPassword: data.need_change_password ? state.tempPassword : '',
        urlRedirectAfterAuthorization: '',
      };
    })
    .addCase(actions.getUserData.failure, (state) => ({
      ...state,
      isLoading: false,
    }))
    .addCase(actions.loginCustomer.start, (state) => {
      return {
        ...state,
        isLoading: true,
        isClient: false,
      };
    })
    .addCase(actions.loginCustomer.success, (state, action) => {
      const isToken = !!action.payload;
      return {
        ...state,
        isLoading: false,
        isAuthorized: isToken,
        isClient: true,
      };
    })
    .addCase(actions.loginCustomer.failure, (state) => ({
      ...state,
      isLoading: false,
      isClient: false,
    }))
    .addCase(actions.signUpConfirm.start, (state) => {
      return {
        ...state,
        isLoading: true,
      };
    })
    .addCase(actions.signUpConfirm.success, (state) => {
      return {
        ...state,
        isLoading: false,
      };
    })
    .addCase(actions.signUpConfirm.failure, (state) => ({
      ...state,
      isLoading: false,
    }))
    .addCase(actions.changeTempPassword.start, (state) => {
      return {
        ...state,
        isLoading: true,
      };
    })
    .addCase(actions.changeTempPassword.success, (state) => {
      return {
        ...state,
        isLoading: false,
        tempPassword: '',
        isTempPassword: false,
      };
    })
    .addCase(actions.changeTempPassword.failure, (state) => ({
      ...state,
      isLoading: false,
    }))
    .addCase(actions.forgotPassword.start, (state) => {
      return {
        ...state,
        isLoading: true,
      };
    })
    .addCase(actions.forgotPassword.success, (state) => {
      return {
        ...state,
        isLoading: false,
      };
    })
    .addCase(actions.forgotPassword.failure, (state) => ({
      ...state,
      isLoading: false,
    }))
    .addCase(actions.checkClientToken.start, (state) => {
      return {
        ...state,
        isLoading: true,
        errorCheckClientToken: false,
      };
    })
    .addCase(actions.checkClientToken.success, (state) => {
      return {
        ...state,
        isLoading: false,
        errorCheckClientToken: false,
      };
    })
    .addCase(actions.checkClientToken.failure, (state) => ({
      ...state,
      isLoading: false,
      errorCheckClientToken: true,
    }))
    .addCase(actions.getVersionBacked.start, (state) => {
      return {
        ...state,
        versionBackend: {
          ...state.versionBackend,
          isLoading: true,
        },
      };
    })
    .addCase(actions.getVersionBacked.success, (state, action) => {
      const version = action?.payload?.version || '';

      return {
        ...state,
        versionBackend: {
          ...state.versionBackend,
          version,
          isLoading: false,
        },
      };
    })
    .addCase(actions.getVersionBacked.failure, (state) => ({
      ...state,
      versionBackend: {
        ...state.versionBackend,
        isLoading: false,
      },
    }))
    .addCase(actions.changeUrlRedirectAfterAuthorization, (state, action) => {
      const url = action?.payload || '';

      return {
        ...state,
        urlRedirectAfterAuthorization: url,
      };
    });
});
