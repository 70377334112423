import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Text from 'componentsShared/Text/Text';
import { ACTION_COLUMN } from 'constants/table';
import { ArrowDownIcon } from 'assets/icons';
import { func, bool, object, number, array } from 'prop-types';
import cn from 'classnames';
import st from './ItemComponentCompanyAdmin.module.scss';

const columnDataKeyCompanyName = 'name';

ItemComponentCompanyAdmin.propTypes = {
  item: object,
  columns: array,
  actions: array,
  widthActions: number,
  showArrow: bool,
  isOpen: bool,
  setIsOpen: func,
  isCompany: bool,
  handleClick: func,
};

function ItemComponentCompanyAdmin({
  item = {},
  columns = [],
  actions = [],
  widthActions,
  showArrow = false,
  isOpen = false,
  setIsOpen = () => {},
  isCompany = false,
  handleClick = () => {},
}) {
  const renderAction = (rowData) => {
    return actions.map(({ icon, onClick }) => {
      return (
        <div key={uuidv4()}>
          <div
            className={st.actionItem}
            onClick={() => {
              onClick(rowData);
            }}>
            {icon}
          </div>
        </div>
      );
    });
  };

  return (
    <div className={st.itemRowContainer} key={uuidv4()}>
      {columns.map((columnValue) => {
        const columnDataKey = columnValue?.dataKey || '';
        const width = columnValue.width || 0;
        const isFlexGrow = !!columnValue.flexGrow;
        const isTruncated = !!columnValue.truncated;
        const isShowNewRepresentativeText =
          isCompany && columnDataKey === 'representativeName';

        if (columnDataKey === ACTION_COLUMN) {
          return;
        }

        return (
          <div
            key={uuidv4()}
            style={{
              minWidth: width,
              maxWidth: isFlexGrow ? 'auto' : width,
            }}
            className={cn(st.boxContainer, {
              [st.flexGrow]: isFlexGrow,
              [st.truncated]: isTruncated,
              [st.cursorPointer]: isShowNewRepresentativeText,
            })}
            onClick={isShowNewRepresentativeText ? handleClick : () => {}}>
            {showArrow && columnDataKeyCompanyName === columnDataKey && (
              <div
                className={st.holderArrow}
                onClick={() => setIsOpen((prev) => !prev)}>
                <ArrowDownIcon
                  className={cn(st.companyArrow, {
                    [st.companyArrowClose]: !isOpen,
                  })}
                />
              </div>
            )}
            {isShowNewRepresentativeText ? (
              <Text varint='normal' className={st.newRepresentativeText}>
                + New Representative
              </Text>
            ) : (
              <Text varint='normal'>{item?.[columnDataKey] || ''}</Text>
            )}
          </div>
        );
      })}

      <div
        className={st.actionWrapper}
        style={{ minWidth: widthActions, maxWidth: widthActions }}>
        {actions.length > 0 && renderAction(item)}
      </div>
    </div>
  );
}

export default ItemComponentCompanyAdmin;
