import React from 'react';
import Text from 'componentsShared/Text/Text';
import cn from 'classnames';
import {
  oneOfType,
  string,
  func,
  bool,
  shape,
  instanceOf,
  object,
  number,
} from 'prop-types';
import AdminTemplateInput from 'components/Admin/AdminTemplateInput/AdminTemplateInput';
import AdminTemplateFileList from 'components/Admin/AdminTemplateFileList/AdminTemplateFileList';
import { CVIcon } from 'assets/icons';
import { Droppable } from 'react-beautiful-dnd';
import { DND_TYPE } from 'constants/traveler';
import TravelerRequireFile from 'components/Traveler/TravelerRequireFile/TravelerRequireFile';
import st from './AdminTemplateOperation.module.scss';

AdminTemplateOperation.propTypes = {
  currentRef: oneOfType([func, shape({ current: instanceOf(Element) })]),
  provided: object,
  isEdit: bool,
  operation: object,
  operationNumber: oneOfType([number, string]),
  handleAddUpdating: func,
  handleUpdating: func,
  operationIndex: number,
  operationId: oneOfType([number, string]),
  snapshotOperation: object,
  phaseType: string,
  stepId: oneOfType([number, string]),
  stepIndex: number.isRequired,
  placeholderProps: shape({
    props: object,
    type: string,
    sourceDroppableId: string,
  }),
};

function AdminTemplateOperation({
  currentRef,
  provided,
  isEdit = false,
  operation,
  operationNumber,
  handleAddUpdating,
  handleUpdating,
  operationIndex,
  operationId,
  snapshotOperation,
  phaseType,
  stepId,
  stepIndex,
  placeholderProps,
  isDisabledControl = false,
}) {
  const { name, require_file: requireFile, files: fileList } = operation;

  const isControlValues = operation?.isControlValues || false;
  const hasFileList = fileList && fileList.length > 0;

  const onAddUpdaing = () => {
    if (!isControlValues) {
      handleAddUpdating(operationId);
    }
  };

  if (!isDisabledControl && isEdit) {
    return (
      <div ref={currentRef} {...provided.draggableProps}>
        <AdminTemplateInput
          handleSubmit={(value) => handleUpdating(value, operationIndex)}
          initialValue={name}
          titleAcceptBtn='Save'
          titleCancelBtn='Delete'
          phaseType={phaseType}
        />
        <div {...provided.dragHandleProps} />
      </div>
    );
  }
  return (
    <div ref={currentRef} {...provided.draggableProps}>
      <div
        className={cn(st.container, {
          [st.operationDragging]: snapshotOperation.isDragging,
          [st.emptyImgAndRequire]: !hasFileList && !requireFile,
          [st.emptyRequire]: !requireFile,
        })}>
        <div
          className={cn(st.holderOperation, {
            [st.operationHolderDragging]: snapshotOperation.isDragging,
          })}
          {...provided.dragHandleProps}>
          <div>
            <Text variant='normal' className={st.operationName}>
              {operationNumber}.
            </Text>
          </div>
          <div onDoubleClick={onAddUpdaing} className={st.holderOperationName}>
            <div>
              <Text className={st.operationName} variant='normal2'>
                {isControlValues ? 'Provide control values' : name}
              </Text>
            </div>
            {isControlValues && (
              <div className={st.holderCV}>
                <div className={st.holderIconCV}>
                  <CVIcon />
                </div>
                <div>
                  <Text className={st.operationName} variant='normal2'>
                    {name}
                  </Text>
                </div>
              </div>
            )}
          </div>
        </div>

        {!isControlValues && (
          <>
            <Droppable
              droppableId={`${operationId}`}
              type={`${DND_TYPE.file}${stepIndex}`}
              isDropDisabled={!!isControlValues}>
              {(providedDroppableFile, snapshotDroppableFile) => (
                <AdminTemplateFileList
                  requireFile={requireFile}
                  operation={operation}
                  operationId={operationId}
                  stepId={stepId}
                  placeholderProps={placeholderProps}
                  providedDroppableFile={providedDroppableFile}
                  snapshotDroppableFile={snapshotDroppableFile}
                />
              )}
            </Droppable>

            <Droppable
              droppableId={`${operationId}/requireFile`}
              type={`${DND_TYPE.requireFile}${stepIndex}`}
              isDropDisabled={!!isControlValues || !!requireFile}>
              {(providedDroppableFile, snapshotDroppableFile) => (
                <TravelerRequireFile
                  requireFile={requireFile}
                  operationId={operationId}
                  stepId={stepId}
                  placeholderProps={placeholderProps}
                  providedDroppableFile={providedDroppableFile}
                  snapshotDroppableFile={snapshotDroppableFile}
                  hasFileList={hasFileList}
                />
              )}
            </Droppable>
          </>
        )}
      </div>
    </div>
  );
}

export default AdminTemplateOperation;
