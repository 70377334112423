import Loader from 'componentsShared/Loader/Loader';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as authActions from 'store/auth/actions';

function CheckVersionPage() {
  const dispatch = useDispatch();
  const frontVersion = process.env.REACT_APP_VERSION;

  const backendVersion =
    useSelector((state) => state?.auth?.versionBackend?.version) ?? '0';
  const loadVersion =
    useSelector((state) => state?.auth?.versionBackend?.isLoading) ?? false;

  useEffect(() => {
    dispatch(authActions.getVersionBacked.start());
  }, [dispatch]);

  if (loadVersion) {
    return <Loader isVisible />;
  }

  return (
    <>
      <div>Front-end version: {frontVersion}</div>
      <div>Back-end version: {backendVersion}</div>
    </>
  );
}

export default CheckVersionPage;
