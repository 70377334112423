import { getRequestAction } from 'helpers/functions';
import { createAction } from '@reduxjs/toolkit';

const ACTION_PREFIX = 'reports';

export const getReportList = getRequestAction(`${ACTION_PREFIX}/getReportList`);

export const changeSettingsReport = createAction(
  `${ACTION_PREFIX}/changeSettingsReport`
);

export const changePage = createAction(`${ACTION_PREFIX}/changePage`);

export const changePageSize = createAction(`${ACTION_PREFIX}/changePageSize`);

export const clearNewSettings = createAction(
  `${ACTION_PREFIX}/clearNewSettings`
);

export const loadFileCSV = getRequestAction(`${ACTION_PREFIX}/loadFileCSV`);

export const changeLoadingFile = createAction(
  `${ACTION_PREFIX}/changeLoadingFile`
);
