import React from 'react';
import { array } from 'prop-types';

import st from './JobDetailsInfoNCR.module.scss';

JobDetailsInfoNCR.propTypes = {
  jobDetails: array,
};

function JobDetailsInfoNCR({ jobDetails = [] }) {
  const renderJobDetails = () => {
    return jobDetails.map((item) => {
      const { label, value } = item;
      return (
        <div className={st.holderJobDetailsItem} key={label}>
          <div>{label}</div>
          <div>{value}</div>
        </div>
      );
    });
  };

  return <div className={st.holderJobDetailsList}>{renderJobDetails()}</div>;
}

export default JobDetailsInfoNCR;
