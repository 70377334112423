import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import PlaceholderContent from 'componentsShared/PlaceholderContent/PlaceholderContent';
import { string, bool, object, array } from 'prop-types';
import RFQItem from '../RFQItem/RFQItem';
import './RFQListStyle.module.scss';

PlaceholderContent.propTypes = {
  emptyText: string,
  searchEmptyText: string,
  isSearch: bool,
  classNameList: string,
  dataList: object,
  ids: array,
};

function RFQList({
  classNameList,
  dataList = {},
  ids = [],
  emptyText = 'Empty',
  searchEmptyText = 'Empty',
  isSearch = false,
  page = '',
}) {
  const renderRFQList = () => {
    return (
      <TransitionGroup>
        {ids.map((id) => {
          const currentRFQ = dataList[id];
          return (
            <CSSTransition
              key={id}
              timeout={{
                appear: 0,
                enter: 300,
                exit: 300,
              }}
              classNames='rfqItem'>
              {/* content should be wrapped by div to hold styles that drops from CSSTransition container */}
              <div>
                <RFQItem rfq={currentRFQ} key={currentRFQ.id} page={page} />
              </div>
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    );
  };

  const renderEmptyContainer = () => {
    return (
      <PlaceholderContent
        emptyText={emptyText}
        searchEmptyText={searchEmptyText}
        isSearch={isSearch}
      />
    );
  };

  return (
    <div className={classNameList}>
      {ids?.length > 0 ? renderRFQList() : renderEmptyContainer()}
    </div>
  );
}

export default RFQList;
