import React, { useEffect, useState, memo } from 'react';
import Text from 'componentsShared/Text/Text';
import cn from 'classnames';
import { string, bool, func } from 'prop-types';
import st from './Tabs.module.scss';

Tabs.propTypes = {
  defaultActiveKey: string,
  onSelect: func,
  isShowBottomLine: bool,
  classNameContainerTabList: string,
  classNameWrapperTabList: string,
  classNameContainerContent: string,
  classNameContainer: string,
};

function Tabs({
  children,
  defaultActiveKey = '',
  onSelect = () => {},
  isShowBottomLine = true,
  classNameContainerTabList,
  classNameWrapperTabList,
  classNameContainerContent,
  classNameContainer,
  changeSelectTab = null,
  setChangeSelectTab = () => {},
}) {
  const isArrayChildren = children || Array.isArray(children);

  const [selectTab, setSelectTab] = useState(defaultActiveKey || '');

  const handleSelect = (eventKey) => {
    onSelect(eventKey);
    setSelectTab(eventKey);
  };

  const titleArray =
    (isArrayChildren &&
      children.map((item) => {
        const currentEventKey = item.props.eventKey;
        const key = `tab-${currentEventKey}`;
        const isActive = item.props.eventKey === selectTab;

        return (
          <div
            className={st.holderTitleItem}
            onClick={() => handleSelect(currentEventKey)}
            key={key}>
            <Text variant='normal2' className={st.titleItem} bold>
              {item?.props?.title || ''}
            </Text>
            {isActive && <div className={st.isActiveLine} />}
          </div>
        );
      })) ||
    [];

  const renderComponent = () => {
    if (!isArrayChildren) {
      return null;
    }

    const currentItem =
      children.find((item) => {
        return item.props.eventKey === selectTab;
      }) || null;

    const component = currentItem?.props?.children;
    return component;
  };

  useEffect(() => {
    if (isArrayChildren && !!children?.[0] && !defaultActiveKey) {
      const firstTab = children?.[0]?.props?.eventKey || '';
      setSelectTab(firstTab);
    }
  }, [children, isArrayChildren, defaultActiveKey]);

  useEffect(() => {
    if (changeSelectTab && changeSelectTab !== selectTab) {
      handleSelect(changeSelectTab);
      setChangeSelectTab(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [changeSelectTab]);

  if (!isArrayChildren) {
    return null;
  }

  return (
    <div className={cn(classNameContainer)}>
      <div className={cn(st.containerTabList, classNameContainerTabList)}>
        <div className={cn(st.wrapperTabList, classNameWrapperTabList)}>
          <div className={st.holderTitleList}>
            {titleArray.map((item) => item)}
          </div>
          {isShowBottomLine && <div className={st.lineBottom} />}
        </div>
      </div>

      <div className={cn(st.containerContent, classNameContainerContent)}>
        {renderComponent()}
      </div>
    </div>
  );
}

export function Tab({ children }) {
  return children;
}

export default memo(Tabs);
