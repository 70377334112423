// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdminTemplateInput_container__1DYsO {\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  margin-bottom: 7px;\n  padding: 0; }\n  .AdminTemplateInput_container__1DYsO .rbt {\n    width: 100%; }\n\n.AdminTemplateInput_buttonEditDone__3bKw5 {\n  margin-left: 11px;\n  min-width: 76px; }\n", "",{"version":3,"sources":["webpack://src/components/Admin/AdminTemplateInput/AdminTemplateInput.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,uBAAuB;EACvB,kBAAkB;EAClB,UAAU,EAAA;EALZ;IASM,WAAW,EAAA;;AAKjB;EACE,iBAAiB;EACjB,eAAe,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.container {\n  display: flex;\n  justify-content: space-between;\n  align-items: flex-start;\n  margin-bottom: 7px;\n  padding: 0;\n\n  :global {\n    .rbt {\n      width: 100%;\n    }\n  }\n}\n\n.buttonEditDone {\n  margin-left: 11px;\n  min-width: 76px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AdminTemplateInput_container__1DYsO",
	"buttonEditDone": "AdminTemplateInput_buttonEditDone__3bKw5"
};
export default ___CSS_LOADER_EXPORT___;
