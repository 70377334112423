import { api } from './ApiService';

export const downloadFile = (path) => {
  return api.get(`/files/${path}`);
};

export const downloadBase64File = (path) => {
  return api.get(`/base64files/${path}`, null, null, true);
};

export const getFileType = ({ object, id }) => {
  return api.get(`/${object}/${id}/files/`);
};

export const addFileType = ({ object, id, data }) => {
  return api.post(`/${object}/${id}/files/`, data);
};

export const downloadClientBase64File = (path) => {
  return api.get(`/client/base64files/${path}`, null, null, true);
};

export const getClientFileType = ({ object, id }) => {
  return api.get(`/client/${object}/${id}/files/`);
};

export const addClientFileType = ({ object, id, data }) => {
  return api.post(`/client/${object}/${id}/files/`, data);
};

export const removeFileType = ({ path }) => {
  return api.delete(`/files/${path}`);
};

export const removeClientFileType = ({ path }) => {
  return api.delete(`/client/files/${path}`);
};

export const getBlobUrlForFile = (url) => {
  return fetch(url)
    .then((res) => res.blob())
    .then((resBlob) => {
      const localImageUrl = URL.createObjectURL(resBlob);
      return localImageUrl;
    });
};

export const revokeURLList = (urlArray) => {
  urlArray.forEach((item) => {
    return URL.revokeObjectURL(item);
  });
};
