import { ROUTES } from 'router/routes';
import { USER_ROLES } from 'constants/userRoles';

export const DEFAULT_PATHS_MAP = {
  [USER_ROLES.admin]: {
    redirectPath: `${ROUTES.productionManager.path}`,
  },
  [USER_ROLES.painter]: {
    redirectPath: `${ROUTES.jobList.path}`,
  },
  [USER_ROLES.preparer]: {
    redirectPath: `${ROUTES.jobList.path}`,
  },
  [USER_ROLES.manager]: {
    redirectPath: `${ROUTES.productionManager.path}`,
  },
  [USER_ROLES.qa]: {
    redirectPath: `${ROUTES.jobList.path}`,
  },
  [USER_ROLES.shipping]: {
    redirectPath: `${ROUTES.customerListShipping.path}`,
  },
  [USER_ROLES.customer]: {
    redirectPath: `${ROUTES.orderListCustomer.path}`,
  },
};
