import React, { useState } from 'react';
import Text from 'componentsShared/Text/Text';
import { Button } from 'react-bootstrap';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { DND_TYPE, DROPPABLE_DELETE_ID } from 'constants/controlValue';
import DragCustomPlaceholder from 'componentsShared/DragCustomPlaceholder/DragCustomPlaceholder';
import ControlValueItem from 'components/ControlValue/ControlValueItem/ControlValueItem';
import { VARIANT_OUTLINE_UI } from 'constants/ui';
import {
  getDraggedDom,
  getDragStartPlaceholder,
  getDragUpdatePlaceholder,
  reorder,
} from 'helpers/dragAndDrop';
import { v4 as uuidv4 } from 'uuid';
import { TrashIcon } from 'assets/icons';
import { deepClone } from 'helpers/functions';
import { array, func } from 'prop-types';
import st from './ControlValuesConstructor.module.scss';

ControlValuesConstructor.propTypes = {
  dataConstructor: array,
  setDataConstructor: func,
};

function ControlValuesConstructor({ dataConstructor, setDataConstructor }) {
  const [placeholderProps, setPlaceholderProps] = useState({
    props: {},
    type: '',
  });
  const [isDrag, setIsDrag] = useState(false);

  const handleAddHeader = () => {
    const id = uuidv4();
    setDataConstructor((prev) => [...prev, { id, value: '' }]);
  };

  const handleChangeValue = (value, index) => {
    const currentDataConstructor = deepClone(dataConstructor);
    currentDataConstructor[index].value = value;
    setDataConstructor(currentDataConstructor);
  };

  const onDragEnd = (result) => {
    setPlaceholderProps({ props: {}, type: '' });
    const startDroppableIndex = result?.source?.index ?? 0;
    const startDroppableId = result?.source?.droppableId || '';
    const endDroppableIndex = result?.destination?.index ?? 0;
    const endDroppableId = result?.destination?.droppableId || '';

    if (
      !result.destination ||
      (startDroppableIndex === endDroppableIndex &&
        endDroppableId === startDroppableId)
    ) {
      setIsDrag(false);
      return;
    }
    const droppableDeleteIdArray = Object.values(DROPPABLE_DELETE_ID);
    const currentEndDroppableId = endDroppableId.replace(/[0-9]/g, '');

    if (droppableDeleteIdArray.includes(currentEndDroppableId)) {
      if (currentEndDroppableId === DROPPABLE_DELETE_ID.controlValues) {
        const currentDataConstructor = deepClone(dataConstructor);
        currentDataConstructor.splice(startDroppableIndex, 1);
        setDataConstructor(currentDataConstructor);
      }
      setIsDrag(false);
      return;
    }

    const currentValue = reorder(
      dataConstructor,
      startDroppableIndex,
      endDroppableIndex
    );
    setDataConstructor(currentValue);

    setIsDrag(false);
  };

  const onDragStart = (result) => {
    const draggedDOM = getDraggedDom(result.draggableId);
    if (!draggedDOM) {
      return;
    }
    const dragStartPlaceholder = getDragStartPlaceholder({
      draggedDOM,
      result,
    });
    setIsDrag(true);
    setPlaceholderProps(dragStartPlaceholder);
  };

  const onDragUpdate = (result) => {
    if (!result.destination) {
      return;
    }
    const draggedDOM = getDraggedDom(result.draggableId);
    if (!draggedDOM) {
      return;
    }
    const dragUpdatePlaceholder = getDragUpdatePlaceholder({
      draggedDOM,
      result,
    });
    setIsDrag(true);
    setPlaceholderProps(dragUpdatePlaceholder);
  };

  const renderControlValuesList = () => {
    return (
      <Droppable droppableId='droppable' type={DND_TYPE.controlValues}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} className={st.holderControlValuesList}>
            {dataConstructor.map((item, i) => {
              const controlValueNumber = i + 1;
              return (
                <Draggable key={item.id} draggableId={item.id} index={i}>
                  {(providedControlValue, snapshotControlValue) => (
                    <ControlValueItem
                      currentRef={providedControlValue.innerRef}
                      provided={providedControlValue}
                      controlValueNumber={controlValueNumber}
                      stepIndex={i}
                      value={item.value}
                      isDraggingOver={snapshot.isDraggingOver}
                      snapshot={snapshotControlValue}
                      isDrag={isDrag}
                      handleChangeValue={handleChangeValue}
                      index={i}
                    />
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
            <DragCustomPlaceholder
              placeholderProps={placeholderProps}
              type={DND_TYPE.controlValues}
              isDraggingOver={snapshot.isDraggingOver}
            />
          </div>
        )}
      </Droppable>
    );
  };

  return (
    <div>
      <div className={st.title}>
        <Text variant='normal'>Parameters</Text>
      </div>
      <DragDropContext
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        onDragUpdate={onDragUpdate}>
        {dataConstructor?.length > 0 && renderControlValuesList()}

        <Droppable
          droppableId={DROPPABLE_DELETE_ID.controlValues}
          type={DND_TYPE.controlValues}>
          {(providedDeleteStep) => (
            <div ref={providedDeleteStep.innerRef}>
              {isDrag ? (
                <div className={st.containerDroppableDelete}>
                  <TrashIcon className={st.trashIcon} />
                </div>
              ) : (
                <div className={st.holderButtonAdd}>
                  <Button
                    variant={VARIANT_OUTLINE_UI.secondary}
                    onClick={handleAddHeader}>
                    Add Parameter
                  </Button>
                </div>
              )}
              <div className={st.placeholderNone}>
                {providedDeleteStep.placeholder}
              </div>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default ControlValuesConstructor;
