import React from 'react';
import { FormControl } from 'react-bootstrap';
import { FORMAT_DATE_FULL_MONTH_KEBAB } from 'constants/ui';
import { func } from 'prop-types';
import Datetime from 'react-datetime';
import moment from 'moment';
import cn from 'classnames';
import st from './DatetimePicker.module.scss';

DatetimePicker.propTypes = {
  setValueDate: func,
};

function DatetimePicker({
  valueDate,
  setValueDate = () => {},
  positionTop = false,
  isInvalid = false,
}) {
  const currentDate = valueDate ? new Date(valueDate) : undefined;

  const yesterday = moment().subtract(1, 'day');
  const getValidDate = (current) => {
    return current.isAfter(yesterday);
  };

  return (
    <Datetime
      dateFormat={FORMAT_DATE_FULL_MONTH_KEBAB}
      timeFormat={false}
      className={cn({ [st.positionTop]: positionTop })}
      value={currentDate}
      onChange={(e) => {
        if (typeof e !== 'string') {
          setValueDate(moment(e).format());
          return;
        }
      }}
      isValidDate={getValidDate}
      renderInput={(props) => {
        return (
          <div>
            <FormControl {...props} isInvalid={isInvalid} onChange={() => {}} />
          </div>
        );
      }}
    />
  );
}

export default DatetimePicker;
