import React, { useEffect, useState } from 'react';
import { FORMAT_DATE_USA, VARIANT_OUTLINE_UI, VARIANT_UI } from 'constants/ui';
import Text from 'componentsShared/Text/Text';
import Modal from 'componentsShared/Modal/Modal';
import NavigationService from 'services/navigation/NavigationService';
import { getRoutePath } from 'helpers/path';
import { ROUTES } from 'router/routes';
import { MoreVertIcon } from 'assets/icons';
import { useDispatch, useSelector } from 'react-redux';
import * as rfqActions from 'store/rfqs/actions';
import DropdownButton from 'componentsShared/DropdownButton/DropdownButton';
import { ucFirst } from 'helpers/string';
import moment from 'moment';
import cn from 'classnames';
import st from './RFQItem.module.scss';

function RFQItem({ rfq, page }) {
  const dispatch = useDispatch();

  const isNewRFQ =
    useSelector((state) => state?.rfqs?.isAddingRFQ?.isNewRFQ) ?? false;
  const addingRFQId =
    useSelector((state) => state?.rfqs?.isAddingRFQ?.rfqId) ?? null;

  const isAddingCurrentRFQ =
    isNewRFQ && addingRFQId?.toString() === rfq.id?.toString();

  const [showModalRemove, setShowModalRemove] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const rgqUpdated = `Update ${moment(rfq.updated).format(FORMAT_DATE_USA)}`;

  const handleOpenModal = () => {
    setShowModalRemove(true);
  };

  const dropdownList = [
    {
      content: () => (
        <Text variant='normal' className={st.removeRFQText}>
          Remove RFQ
        </Text>
      ),
      onClick: handleOpenModal,
    },
  ];

  const handleClickItem = (e) => {
    e.preventDefault();
    dispatch(rfqActions.changeScrollRFQId(`${rfq.id}-${page}`));

    NavigationService.navigateToPath(
      getRoutePath(ROUTES.rfqDetails.path, {
        id: rfq.id,
      })
    );
  };

  const handleRemove = () => {
    dispatch(rfqActions.removeRFQ.start(rfq.id));
    setIsRemoving(true);
    setShowModalRemove(false);
  };

  const handleClickDropdown = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    const clearIsAddingRFQTimer = setTimeout(() => {
      dispatch(rfqActions.clearIsAddingRFQ());
    }, 2000);

    return () => clearTimeout(clearIsAddingRFQTimer);
  }, [dispatch]);

  return (
    <div className={st.rootWrapper} id={`rfq-card-${rfq.id}`}>
      <div className={st.containerRFQ} onClick={handleClickItem}>
        <div className={st.holderRFQInfo}>
          <div className={st.rfqInfo}>
            <Text variant='normal' className={st.rfqNumber}>
              {rfq.id}
            </Text>
          </div>
          <div className={st.rfqStatus}>
            <Text variant='normal2' className={st.rfqStatusText}>
              {ucFirst(rgqUpdated)}
            </Text>
          </div>
        </div>
        <div className={st.holderCardAction}>
          <DropdownButton
            list={dropdownList}
            className={st.moreButton}
            startIcon={<MoreVertIcon />}
            btnVariant={VARIANT_OUTLINE_UI.light}
            arrowNone
            size='lg'
            onClick={handleClickDropdown}
          />
        </div>
      </div>
      <div
        className={cn(st.removingOverlay, {
          [st.removingOverlayVisible]: isRemoving,
        })}
      />
      {isAddingCurrentRFQ && <div className={st.addingAnimation} />}
      <Modal
        title='Remove RFQ'
        show={showModalRemove}
        setShow={setShowModalRemove}
        titleAcceptBtn='Remove'
        onAcceptBtn={handleRemove}
        variantAcceptBtn={VARIANT_UI.danger}>
        <div>
          <Text variant='normal'>
            Would you like to remove RFQ{' '}
            <Text variant='normal' bold>
              {rfq.id}
            </Text>
            ?
          </Text>
        </div>
      </Modal>
    </div>
  );
}

export default RFQItem;
