import React, { useState } from 'react';
import { VARIANT_OUTLINE_UI } from 'constants/ui';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import ModalButtonNotes from 'components/Notes/ModalButtonNotes/ModalButtonNotes';
import { array, string, func, number, bool, oneOfType } from 'prop-types';
import { Loader } from 'react-bootstrap-typeahead';
import st from './ButtonNotes.module.scss';

ButtonNotes.propTypes = {
  countNotes: oneOfType([number, string]),
  notesArray: array,
  titleHeader: string,
  handleChangeNote: func,
  handleCreateNotes: func,
  isShowSelectVisibleCustomer: bool,
};

function ButtonNotes({
  countNotes = 0,
  notesArray = [],
  titleHeader = '',
  handleChangeNote = () => {},
  handleCreateNotes = () => {},
  isShowSelectVisibleCustomer = false,
}) {
  const [openModal, setOpenModal] = useState(false);
  const isLoading =
    useSelector((state) => state?.notes?.notes?.isLoading) ?? false;

  return (
    <div className={st.container}>
      <div className={st.holderButtonNotes}>
        <Button
          variant={VARIANT_OUTLINE_UI.secondary}
          className={st.buttonNotes}
          onClick={() => setOpenModal(true)}>
          Notes
          {countNotes && (
            <div className={st.countNotes}>
              {isLoading ? <Loader /> : countNotes}
            </div>
          )}
        </Button>
      </div>
      <ModalButtonNotes
        openModal={openModal}
        setOpenModal={setOpenModal}
        notesArray={notesArray}
        titleHeader={titleHeader}
        handleChangeNote={handleChangeNote}
        handleCreateNotes={handleCreateNotes}
        isShowSelectVisibleCustomer={isShowSelectVisibleCustomer}
      />
    </div>
  );
}

export default ButtonNotes;
