import React from 'react';
import { oneOfType, string, number } from 'prop-types';
import Text from 'componentsShared/Text/Text';
import cn from 'classnames';
import st from './JobsOperationHeader.module.scss';

JobsOperationHeader.propTypes = {
  currentOperationIndex: oneOfType([number, string]),
  quantityOperation: oneOfType([number, string]),
};

function JobsOperationHeader({
  currentOperationIndex,
  quantityOperation,
  isShowText = true,
}) {
  return (
    <div className={st.container}>
      <div
        className={cn(st.holderHeader, {
          [st.holderHeaderWithoutText]: !isShowText,
        })}>
        {isShowText && (
          <Text className={st.operationNumber} varinant='normal2' bold>
            OPERATION {currentOperationIndex + 1} OF {quantityOperation}
          </Text>
        )}
      </div>
      <div className={st.line} />
    </div>
  );
}

export default JobsOperationHeader;
