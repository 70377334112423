import React, { useEffect, useState } from 'react';
import Loader from 'componentsShared/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { ArrowForwardIcon } from 'assets/icons';
import { Button } from 'react-bootstrap';
import * as templatesActions from 'store/templates/actions';
import TravelerTemplateStep from 'components/TravalerTemplate/TravelerTemplateStep/TravelerTemplateStep';
import { bool, oneOfType, number, string, array, func } from 'prop-types';
import { VARIANT_OUTLINE_UI } from 'constants/ui';
import cn from 'classnames';
import PlaceholderContent from 'componentsShared/PlaceholderContent/PlaceholderContent';
import st from './TravelerTemplatePhase.module.scss';
import TravelerTemplateSelectList from '../TravelerTemplateSelectList/TravelerTemplateSelectList';

TravelerPhaseTemplate.propTypes = {
  selectTemplateId: oneOfType([number, string]),
  isEmptyTemplate: bool.isRequired,
  selectTemplateList: array,
  handleSelectTemplate: func,
};

function TravelerPhaseTemplate({
  selectTemplateId,
  isEmptyTemplate,
  selectTemplateList,
  handleSelectTemplate = () => {},
}) {
  const dispatch = useDispatch();
  const isLoadingTravelers =
    useSelector((state) => state?.travelers?.travelers?.isLoading) ?? false;
  const isLoadingPhaseList =
    useSelector((state) => state?.travelers?.phaseList?.isLoading) ?? false;
  const currentTemplate =
    useSelector(
      (state) => state?.templates?.templates?.data?.[selectTemplateId]
    ) ?? {};
  const isLoadingTemplate =
    useSelector((state) => state?.templates?.templates?.isLoading) ?? false;
  const [openPhaseList, setOpenPhaseList] = useState([]);

  const isLoader =
    isLoadingTravelers || isLoadingTemplate || isLoadingPhaseList;

  const handlePhaseList = (valuePhase) => {
    if (openPhaseList.includes(valuePhase)) {
      return setOpenPhaseList((prev) =>
        prev.filter((item) => item !== valuePhase)
      );
    }
    return setOpenPhaseList((prev) => [...prev, valuePhase]);
  };

  const renderPhaseListArray = () => {
    return currentTemplate?.phaseList?.map((phase) => {
      const phaseName = phase.name;
      const phaseTitle = phase.human_name;
      const currentStepList = currentTemplate?.[phaseName]?.steps || [];
      return (
        <div className={st.phaseContainer} key={`wizard-phase-${phaseName}`}>
          <div
            className={st.phaseTitleContainer}
            onClick={() => handlePhaseList(phaseName)}>
            <ArrowForwardIcon
              className={cn({
                [st.phaseTitleIconClose]: !openPhaseList.includes(phaseName),
              })}
            />
            <h5 className={st.phaseTitle}>{phaseTitle}</h5>
          </div>
          <div
            className={cn({
              [st.hiddenPhaseContent]: openPhaseList.includes(phaseName),
            })}>
            <TravelerTemplateStep
              stepList={currentStepList}
              phaseType={phaseName}
              templateId={selectTemplateId}
            />
          </div>
        </div>
      );
    });
  };

  const renderEmptyPhase = () => {
    return (
      <PlaceholderContent
        classNameContainer={st.emptyContainer}
        emptyText='No traveler template selected'
      />
    );
  };

  useEffect(() => {
    dispatch(templatesActions.getTemplateListForWizard.start());
  }, [dispatch]);

  useEffect(() => {
    setOpenPhaseList([]);
  }, [selectTemplateId]);

  if (isEmptyTemplate && !isLoader) {
    return <div className={st.container}>Empty Template</div>;
  }

  return (
    <div className={st.container}>
      <div className={st.buttonGroup}>
        <div className={st.buttonGroupLeft}>
          <Button
            variant={VARIANT_OUTLINE_UI.secondary}
            onClick={() =>
              dispatch(
                templatesActions.selectAllTemplateOperationControl({
                  templateId: selectTemplateId,
                })
              )
            }
            size='sm'
            disabled={!selectTemplateId}>
            Select All
          </Button>

          <Button
            variant={VARIANT_OUTLINE_UI.secondary}
            onClick={() =>
              dispatch(
                templatesActions.deselectAllTemplateOperationControl({
                  templateId: selectTemplateId,
                })
              )
            }
            size='sm'
            disabled={!selectTemplateId}>
            Deselect All
          </Button>
        </div>
        <div className={st.holderSelectTemplateList}>
          <TravelerTemplateSelectList
            selectTemplateList={selectTemplateList}
            handleSelectTemplate={handleSelectTemplate}
          />
        </div>
      </div>
      {currentTemplate?.phaseList?.length > 0
        ? renderPhaseListArray()
        : renderEmptyPhase()}
      <Loader isVisible={isLoader} />
    </div>
  );
}

export default TravelerPhaseTemplate;
