/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from 'react-router';
import AdminPhaseContent from 'components/Admin/AdminPhaseContent/AdminPhaseContent';
import CheckActivePhase from 'hooks/CheckActivePhase';
import st from './TemplateConstructorWrapper.module.scss';

function TemplateConstructorWrapper({
  setActivePhase = () => {},
  areScrollToPhase = '',
  setAreScrollToPhase = () => {},
  isEditTemplate = false,
}) {
  const location = useLocation();

  const phaseList =
    useSelector((state) => state?.travelers?.phaseList?.data) ?? {};
  const newPhaseList =
    useSelector((state) => state?.templates?.newPhaseList) ?? [];
  const phasesWrapperRef = useRef();
  const locationID = location.pathname.replace('/admin/travelerTemplates/', '');
  const templateID = locationID === 'new' ? undefined : locationID;

  const phase = useMemo(() => {
    const arrayPhaseList = Object.values(phaseList);
    if (
      (!newPhaseList || newPhaseList.length === 0) &&
      arrayPhaseList.length === 0
    ) {
      return [];
    }

    if (
      (!newPhaseList || newPhaseList.length === 0) &&
      arrayPhaseList.length > 0
    ) {
      return arrayPhaseList;
    }

    if (newPhaseList.length > 0) {
      return newPhaseList;
    }
  }, [newPhaseList, phaseList]);

  const phaseIds = useMemo(() => {
    return newPhaseList.map((item) => item.name);
  }, [newPhaseList]);

  const { activePhaseId } = CheckActivePhase({
    phaseIds,
    ref: phasesWrapperRef,
    initialId: phaseIds?.[0],
    addingScroll: 250,
  });

  const scrollToPhase = (id) => {
    const currentPhaseOffsetTop = document.getElementById(id)?.offsetTop || 0;
    const firstPhaseOffsetTop =
      document.getElementById(phaseIds[0])?.offsetTop || 0;
    const position = currentPhaseOffsetTop - firstPhaseOffsetTop;
    phasesWrapperRef.current.scrollTo({
      top: position,
      left: 0,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (activePhaseId) {
      setActivePhase(activePhaseId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePhaseId]);

  useEffect(() => {
    if (newPhaseList[0]) {
      scrollToPhase(newPhaseList[0]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (areScrollToPhase) {
      scrollToPhase(areScrollToPhase);
      setAreScrollToPhase('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areScrollToPhase]);

  const renderPhasesContent = () => {
    return (
      <>
        {phase.map(({ name, type, human_name }) => (
          <AdminPhaseContent
            phaseType={type}
            phaseName={name}
            templateId={templateID}
            isDisabledControl={!isEditTemplate}
            key={name}
            titlePhase={human_name}
          />
        ))}
      </>
    );
  };

  return (
    <div className={st.container} ref={phasesWrapperRef}>
      <div className={st.phaseListWrapper}>
        {phase.length > 0 && renderPhasesContent()}
      </div>
    </div>
  );
}

export default TemplateConstructorWrapper;
