// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OffcanvasCustomerCreate_buttonGroup__27kaH {\n  display: flex;\n  flex-direction: column;\n  grid-row-gap: 8px;\n  row-gap: 8px; }\n\n.OffcanvasCustomerCreate_formGroupInvite__2u_Fd {\n  margin-top: 6px;\n  display: flex;\n  grid-column-gap: 8px;\n  column-gap: 8px;\n  align-items: center; }\n", "",{"version":3,"sources":["webpack://src/components/Offcanvas/OffcanvasCustomerCreate/OffcanvasCustomerCreate.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAY;EAAZ,YAAY,EAAA;;AAGd;EACE,eAAe;EACf,aAAa;EACb,oBAAe;EAAf,eAAe;EACf,mBAAmB,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.buttonGroup {\n  display: flex;\n  flex-direction: column;\n  row-gap: 8px;\n}\n\n.formGroupInvite {\n  margin-top: 6px;\n  display: flex;\n  column-gap: 8px;\n  align-items: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonGroup": "OffcanvasCustomerCreate_buttonGroup__27kaH",
	"formGroupInvite": "OffcanvasCustomerCreate_formGroupInvite__2u_Fd"
};
export default ___CSS_LOADER_EXPORT___;
