// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".InviteEmployeeForm_wrapperForm__170ye {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  grid-row-gap: 15px;\n  row-gap: 15px; }\n\n.InviteEmployeeForm_formGroup__2Ig4e {\n  width: 100%; }\n  .InviteEmployeeForm_formGroup__2Ig4e .form-control.is-invalid {\n    background-image: none; }\n\n.InviteEmployeeForm_passwordWrapper__1QvpE {\n  position: relative; }\n  .InviteEmployeeForm_passwordWrapper__1QvpE .form-control {\n    padding-right: 32px; }\n\n.InviteEmployeeForm_iconShowPassword__2aDJD {\n  position: absolute;\n  height: 100%;\n  top: 0;\n  right: 14px;\n  display: flex;\n  align-items: center;\n  cursor: pointer; }\n", "",{"version":3,"sources":["webpack://src/components/Form/InviteEmployeeForm/InviteEmployeeForm.module.scss"],"names":[],"mappings":"AAEA;EACE,YAAY;EACZ,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,kBAAa;EAAb,aAAa,EAAA;;AAGf;EACE,WAAW,EAAA;EADb;IAIM,sBAAsB,EAAA;;AAK5B;EACE,kBAAkB,EAAA;EADpB;IAIM,mBAAmB,EAAA;;AAKzB;EACE,kBAAkB;EAClB,YAAY;EACZ,MAAM;EACN,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,eAAe,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.wrapperForm {\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  row-gap: 15px;\n}\n\n.formGroup {\n  width: 100%;\n  :global {\n    .form-control.is-invalid {\n      background-image: none;\n    }\n  }\n}\n\n.passwordWrapper {\n  position: relative;\n  :global {\n    .form-control {\n      padding-right: 32px;\n    }\n  }\n}\n\n.iconShowPassword {\n  position: absolute;\n  height: 100%;\n  top: 0;\n  right: 14px;\n  display: flex;\n  align-items: center;\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperForm": "InviteEmployeeForm_wrapperForm__170ye",
	"formGroup": "InviteEmployeeForm_formGroup__2Ig4e",
	"passwordWrapper": "InviteEmployeeForm_passwordWrapper__1QvpE",
	"iconShowPassword": "InviteEmployeeForm_iconShowPassword__2aDJD"
};
export default ___CSS_LOADER_EXPORT___;
