// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DatetimePicker_positionTop__1hPzd > div {\n  bottom: 42px; }\n", "",{"version":3,"sources":["webpack://src/componentsShared/DatetimePicker/DatetimePicker.module.scss"],"names":[],"mappings":"AAEA;EAEI,YAAY,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.positionTop {\n  & > div {\n    bottom: 42px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"positionTop": "DatetimePicker_positionTop__1hPzd"
};
export default ___CSS_LOADER_EXPORT___;
