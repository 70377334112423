/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import * as templatesActions from 'store/templates/actions';
import * as travelersActions from 'store/travelers/actions';
import TemplateWrapperHeaderDetails from 'components/TravalerTemplate/TemplateWrapperHeaderDetails/TemplateWrapperHeaderDetails';
import Loader from 'componentsShared/Loader/Loader';
import TemplateConstructorWrapper from 'components/TravalerTemplate/TemplateConstructorWrapper/TemplateConstructorWrapper';
import * as controlValuesActions from 'store/controlValues/actions';
import {
  getArrayTextStepWithoutOperations,
  getValidValueCreateTemplate,
} from 'helpers/functions';
import BeforeRedirect from 'componentsShared/BeforeRedirect/BeforeRedirect';
import Modal from 'componentsShared/Modal/Modal';
import Text from 'componentsShared/Text/Text';
import { Button } from 'react-bootstrap';
import { VARIANT_UI } from 'constants/ui';
import st from './AdminTemplateCreatePage.module.scss';

function AdminTemplateCreatePage() {
  const dispatch = useDispatch();

  const phaseList =
    useSelector((state) => state?.travelers?.phaseList?.data) ?? {};
  const newPhaseList =
    useSelector((state) => state?.templates?.newPhaseList) ?? [];
  const isLoading =
    useSelector((state) => state?.templates?.templates?.isLoading) ?? false;
  const isLoadingTraveler =
    useSelector((state) => state?.travelers?.travelers?.isLoading) ?? false;
  const isLoadingCreate =
    useSelector((state) => state?.templates?.templates?.isLoadingCreate) ??
    false;
  const currentTemplate =
    useSelector((state) => state?.templates?.newTemplate) ?? {};

  const [isEditTemplate, setIsEditTemplate] = useState(true);
  const [formNameValue, setFormNameValue] = useState('');
  const [activePhase, setActivePhase] = useState('');
  const [areScrollToPhase, setAreScrollToPhase] = useState('');
  const [showNotSaveModal, setShowNotSaveModal] = useState(false);

  const handleToggleEditTemplate = (value) => {
    setIsEditTemplate(value);
  };

  const handleChangeFormName = (e) => {
    setFormNameValue(e.target.value);
  };

  const arrayStepWithoutOperations = useMemo(() => {
    const phaseListArray = Object.values(phaseList);

    return getArrayTextStepWithoutOperations(
      currentTemplate,
      (newPhaseList.length === 0 ? phaseListArray : newPhaseList) || []
    );
  }, [phaseList, newPhaseList, currentTemplate]);

  const handleSaveTemplate = () => {
    if (arrayStepWithoutOperations.length > 0) {
      setShowNotSaveModal(true);
      return;
    }
    const currentPhase =
      newPhaseList.length > 0 ? newPhaseList : Object.values(phaseList);

    const currentValue = getValidValueCreateTemplate(
      {
        ...currentTemplate,
        name: formNameValue,
      },
      currentPhase
    );
    dispatch(templatesActions.createTemplate.start(currentValue));
  };

  const phase = useMemo(() => {
    const arrayPhaseList = Object.values(phaseList);
    if (
      (!newPhaseList || newPhaseList.length === 0) &&
      arrayPhaseList.length === 0
    ) {
      return [];
    }

    if (
      (!newPhaseList || newPhaseList.length === 0) &&
      arrayPhaseList.length > 0
    ) {
      return arrayPhaseList.map((item) => ({
        ...item,
        default: true,
      }));
    }

    if (newPhaseList.length > 0) {
      return newPhaseList.map((item) => {
        const isDefaultPhase = arrayPhaseList.some(
          (currentPhase) => currentPhase.name === item.name
        );
        return {
          ...item,
          default: isDefaultPhase,
        };
      });
    }
  }, [newPhaseList, phaseList]);

  const isBeforeRedirect = useMemo(
    () =>
      (Object.values(currentTemplate).length !== 0 ||
        formNameValue.length !== 0) &&
      !isLoading &&
      !isLoadingTraveler &&
      !isLoadingCreate,
    [currentTemplate, formNameValue, isLoading, isLoadingTraveler]
  );

  const isDisabledSave = useMemo(
    () => !formNameValue || Object.values(currentTemplate).length === 0,
    [formNameValue, currentTemplate]
  );

  const handleCloseNotSaveModal = () => {
    setShowNotSaveModal(false);
  };

  useEffect(() => {
    dispatch(travelersActions.getPhaseList.start());
    dispatch(
      controlValuesActions.getControlValueList.start({ include_deleted: true })
    );
  }, [dispatch]);

  if (isLoading || isLoadingTraveler || isLoadingCreate) {
    return (
      <AdminLayout>
        <div className={st.holderLoader}>
          <Loader isVisible />
        </div>
      </AdminLayout>
    );
  }

  return (
    <AdminLayout>
      <div className={st.container}>
        <TemplateWrapperHeaderDetails
          phaseList={phase}
          isEditTemplate={isEditTemplate}
          handleToggleEditTemplate={handleToggleEditTemplate}
          handleChangeFormName={handleChangeFormName}
          formNameValue={formNameValue}
          handleSaveTemplate={handleSaveTemplate}
          activePhase={activePhase}
          setAreScrollToPhase={setAreScrollToPhase}
          isNew
          isDisabledSave={isDisabledSave}
        />

        <TemplateConstructorWrapper
          setActivePhase={setActivePhase}
          areScrollToPhase={areScrollToPhase}
          setAreScrollToPhase={setAreScrollToPhase}
          isEditTemplate={isEditTemplate}
        />

        <Modal
          show={showNotSaveModal}
          title='Can not save'
          onHide={handleCloseNotSaveModal}
          buttonBottomRight={({ onHide }) => (
            <Button variant={VARIANT_UI.primary} onClick={onHide}>
              Got It
            </Button>
          )}>
          <Text variant='normal'>
            Traveler has a step with no operations.
            <br />
            <br />
            Add operations in the Step or remove it in order to save changes.
          </Text>
        </Modal>
        <BeforeRedirect when={isBeforeRedirect} />
      </div>
    </AdminLayout>
  );
}

export default AdminTemplateCreatePage;
