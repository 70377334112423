import moment from 'moment';

export const isEqualDate = (firstDate, secondDate) => {
  if (!firstDate || !secondDate) {
    if (firstDate === secondDate) {
      return true;
    }

    return false;
  }

  const firstDay = moment(new Date(firstDate)).date();
  const firstMonth = moment(new Date(firstDate)).month();
  const firstYear = moment(new Date(firstDate)).year();

  const secondDay = moment(new Date(secondDate)).date();
  const secondMonth = moment(new Date(secondDate)).month();
  const secondYear = moment(new Date(secondDate)).year();

  return (
    firstDay === secondDay &&
    firstMonth === secondMonth &&
    firstYear === secondYear
  );
};

export const isBeforeDate = (firstDate, secondDate) => {
  const isEqualDateValue = isEqualDate(firstDate, secondDate);

  if (isEqualDateValue) {
    return true;
  }

  return moment(firstDate).isBefore(secondDate);
};
