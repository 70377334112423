import React, { useEffect, useRef } from 'react';
import { string, number, bool, func } from 'prop-types';
import * as filesActions from 'store/files/actions';
import { useDispatch, useSelector } from 'react-redux';
import Loader from 'componentsShared/Loader/Loader';
import FilePDF from 'componentsShared/FilePDF/FilePDF';
import { ZOOM_IMG_ARRAY } from 'constants/ui';
import st from './ModalFileBody.module.scss';

ModalFileBody.propTypes = {
  filePath: string,
  pageNumber: number,
  isTypePDF: bool,
  setNumPages: func,
};

function ModalFileBody({
  filePath = '',
  pageNumber = 1,
  isTypePDF = false,
  setNumPages = () => {},
  zoomImgIndex = 1,
}) {
  const dispatch = useDispatch();
  const holderRef = useRef(null);
  const isLoadingFile =
    useSelector((state) => state?.files?.files?.isLoading) ?? '';
  const filesUrlObject =
    useSelector((state) => state?.files?.files?.dataUrl) ?? {};

  const currentUrl = filesUrlObject?.[filePath];

  const onDocumentLoadSuccess = (value) => {
    setNumPages(value.numPages || 0);
  };

  const renderUrlFile = () => {
    if (isTypePDF) {
      return (
        <FilePDF
          currentUrl={currentUrl}
          onDocumentLoadSuccess={onDocumentLoadSuccess}
          pageNumber={pageNumber}
          widthPDF={holderRef?.current?.offsetWidth || 400}
        />
      );
    }

    return (
      <img
        src={currentUrl}
        style={{ minWidth: `${100 * ZOOM_IMG_ARRAY[zoomImgIndex]}%` }}
        className={st.jobSummaryImg}
        alt='Img'
      />
    );
  };

  // const open = () => {
  //   window.open(currentUrl, 'EPrescription');
  // };

  useEffect(() => {
    if (filePath) {
      dispatch(filesActions.getFile.start({ path: filePath }));
    }
  }, [filePath, dispatch]);

  return (
    <div className={st.wrapperModalContent}>
      <div className={st.holderImg} ref={holderRef}>
        {currentUrl && renderUrlFile()}
        <Loader isVisible={isLoadingFile} />
      </div>
    </div>
  );
}

export default ModalFileBody;
