import React, { useEffect, useRef, useState } from 'react';
import { Button, Form, FormControl, FormGroup } from 'react-bootstrap';
import { MinusIcon } from 'assets/icons';
import * as notesActions from 'store/notes/actions';
import Text from 'componentsShared/Text/Text';
import CardNotes from 'components/Notes/CardNotes/CardNotes';
import { array, string, func, bool } from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { MAX_LENGTH_INPUT } from 'constants/ui';
import PlaceholderContent from 'componentsShared/PlaceholderContent/PlaceholderContent';
import Loader from 'componentsShared/Loader/Loader';
import cn from 'classnames';
import st from './ModalButtonNotes.module.scss';

ModalButtonNotes.propTypes = {
  openModal: bool,
  setOpenModal: func,
  notesArray: array,
  titleHeader: string,
  handleChangeNote: func,
  handleCreateNotes: func,
};

function ModalButtonNotes({
  openModal = false,
  setOpenModal = () => {},
  notesArray = [],
  titleHeader = '',
  handleChangeNote = () => {},
  handleCreateNotes = () => {},
  isShowSelectVisibleCustomer = false,
}) {
  const dispatch = useDispatch();
  const isLoading =
    useSelector((state) => state?.notes?.notes?.isLoading) ?? false;
  const isAddingNote =
    useSelector((state) => state?.notes?.isAddingNote) ?? false;

  const modalContentRef = useRef(null);

  const [valueNotes, setValueNotes] = useState('');
  const [isVisibleToCustomer, setIsVisibleToCustomer] = useState(false);

  const renderNotesCard = () => {
    return notesArray.map(({ title, text, currentUser, isShowCustomer }) => {
      return (
        <CardNotes
          title={title}
          text={text}
          isActive={currentUser}
          handleChangeNote={handleChangeNote}
          key={uuidv4()}
          isShowCustomer={isShowCustomer}
        />
      );
    });
  };

  const renderEmptyNotes = () => {
    return (
      <PlaceholderContent
        emptyText='This is the very beginning of your conversation'
        classNameContainer={st.emptyContainer}
        classNameText={st.emptyText}
      />
    );
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setValueNotes(value);
  };

  const createNotes = () => {
    const isShowToCustomer = isShowSelectVisibleCustomer && isVisibleToCustomer;

    handleCreateNotes(valueNotes, isShowToCustomer);
    setValueNotes('');
  };

  const handleChangeCheckbox = (e) => {
    const value = e.target.checked;
    setIsVisibleToCustomer(value);
  };

  useEffect(() => {
    if (isAddingNote && modalContentRef.current) {
      modalContentRef.current.scrollTo(0, modalContentRef.current.scrollHeight);
      dispatch(notesActions.changeIsAddingNote());
    }
  }, [isAddingNote, dispatch]);

  return (
    <div className={cn(st.modalWrapper, { [st.modalWrapperOpen]: openModal })}>
      <div className={st.modalHeader}>
        <Text variant='normal' bold className={st.modalHeaderText}>
          {titleHeader}
        </Text>
        <div
          className={st.containerIconMinus}
          onClick={() => setOpenModal(false)}>
          <MinusIcon />
        </div>
      </div>
      <div className={st.holderModalContent}>
        <div className={st.modalContent} ref={modalContentRef}>
          {notesArray.length > 0 ? renderNotesCard() : renderEmptyNotes()}
        </div>
        <Loader isVisible={isLoading} />
      </div>
      <div
        className={cn(st.modalFooter, {
          [st.modalFooterSelectVisibleCustomer]: isShowSelectVisibleCustomer,
        })}>
        <FormGroup>
          <FormControl
            value={valueNotes}
            onChange={handleChange}
            as='textarea'
            rows={3}
            maxLength={MAX_LENGTH_INPUT}
          />
        </FormGroup>
        {isShowSelectVisibleCustomer && (
          <div className={st.holderCheckBoxSelectCustomer}>
            <Form.Check
              label='Display this note to the customer'
              checked={isVisibleToCustomer}
              onChange={handleChangeCheckbox}
            />
          </div>
        )}
        <Button onClick={createNotes} disabled={!valueNotes || isLoading}>
          Send Note
        </Button>
      </div>
    </div>
  );
}

export default ModalButtonNotes;
