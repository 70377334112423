import React from 'react';
import Text from 'componentsShared/Text/Text';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { DND_TYPE } from 'constants/traveler';
import DragCustomPlaceholder from 'componentsShared/DragCustomPlaceholder/DragCustomPlaceholder';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useParams } from 'react-router';
import { REQUIRE_FILE_DRAGGABLE_ID } from 'constants/files';
import TravelerService from 'services/traveler/TravelerService';
import { ArrowBarUpIcon } from 'assets/icons';
import cn from 'classnames';
import st from './TravelerRequireFile.module.scss';

TravelerRequireFile.propTypes = {
  requireFile: PropTypes.bool,
  operationId: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]),
  stepId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placeholderProps: PropTypes.shape({
    props: PropTypes.object,
    type: PropTypes.string,
    sourceDroppableId: PropTypes.string,
  }),
  hasFileList: PropTypes.bool,
};

function TravelerRequireFile({
  requireFile = false,
  operationId,
  stepId,
  placeholderProps,
  providedDroppableFile,
  snapshotDroppableFile,
  hasFileList = false,
}) {
  const { id: travelerId } = useParams();

  const isStatusActual = TravelerService.isStatusActual(travelerId);

  const renderRequireFile = () => {
    return (
      <Draggable
        key={`${stepId}/${operationId}${REQUIRE_FILE_DRAGGABLE_ID}`}
        draggableId={`${stepId}/${operationId}${REQUIRE_FILE_DRAGGABLE_ID}`}
        index={0}>
        {(providedFile, snapshotFile) => (
          <div
            {...providedFile.draggableProps}
            ref={providedFile.innerRef}
            className={st.containerFile}>
            <div
              {...(!isStatusActual ? providedFile.dragHandleProps : {})}
              className={cn(st.fileWrapper, {
                [st.fileDragging]: snapshotFile.isDragging,
              })}>
              <div
                className={cn(st.holderFile, {
                  [st.fileHolderDragging]: snapshotFile.isDragging,
                })}>
                <ArrowBarUpIcon className={st.iconFile} />
              </div>
              <Text varint='normal' className={st.name}>
                Require file upload
              </Text>
            </div>
            {isStatusActual && <div {...providedFile.dragHandleProps} />}
          </div>
        )}
      </Draggable>
    );
  };

  return (
    <div
      className={cn(st.droppableFile, {
        [st.holderRequiredIsFileList]: hasFileList,
        [st.emptyRequireFile]: !requireFile,
      })}
      ref={providedDroppableFile.innerRef}>
      {requireFile && renderRequireFile()}
      {providedDroppableFile.placeholder}

      <DragCustomPlaceholder
        placeholderProps={placeholderProps}
        type={DND_TYPE.requireFile}
        isDraggingOver={snapshotDroppableFile.isDraggingOver}
      />
    </div>
  );
}

export default TravelerRequireFile;
