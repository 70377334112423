import React from 'react';
import Text from 'componentsShared/Text/Text';
import { ClearIcon } from 'assets/icons';
import st from './Chip.module.scss';

function Chip({ label, handleRemoveChip, showRemoveBtn = false, icon = null }) {
  return (
    <div className={st.selectedChip}>
      {icon && <div className={st.holderIcon}>{icon}</div>}
      <Text variant='normal3' bold className={st.textSetting}>
        {label}
      </Text>
      {showRemoveBtn && (
        <div
          className={st.holderClearIcon}
          onClick={() => handleRemoveChip(label)}>
          <ClearIcon className={st.clearIcon} />
        </div>
      )}
    </div>
  );
}

export default Chip;
