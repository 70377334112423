import React, { useEffect, useRef } from 'react';
import { Form } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { array, bool, func, object } from 'prop-types';
import autosize from 'autosize';
import RequiredLabel from 'componentsShared/RequiredLabel/RequiredLabel';
import PartsDetailsFiles from 'components/Parts/PartsDetailsFiles/PartsDetailsFiles';
import PartsButtonTraveler from 'components/Parts/PartsButtonTraveler/PartsButtonTraveler';
import AuthService from 'services/auth/AuthService';
import { MAX_LENGTH_INPUT } from 'constants/ui';
import st from './PartsDetailsForm.module.scss';

PartsDetailsForm.propTypes = {
  formValue: object,
  setFormValue: func,
  errors: object,
  formDirty: object,
  setFormDirty: func,
  customerOptionList: array,
  isCustomer: bool,
  isRemotePart: bool,
  isNewPart: bool,
  isDirtyForm: bool,
  travelerData: object,
};

function PartsDetailsForm({
  formValue = {},
  setFormValue = () => {},
  errors = {},
  formDirty = {},
  setFormDirty = () => {},
  customerOptionList = [],
  isCustomer = false,
  isRemotePart = false,
  isNewPart = false,
  isDirtyForm = false,
  travelerData = {},
  cacheClientFile = [],
  setCacheClientFile = () => {},
}) {
  const isShippingRole = AuthService.isShippingRole();
  const isClient = AuthService.isClient();
  const revisionNotesId = uuidv4();
  const textareaRef = useRef(null);

  const handleChange = (e) => {
    const currentName = e.target.name;
    const currentValue = e.target.value;
    setFormValue((prev) => ({ ...prev, [currentName]: currentValue }));
    setFormDirty((prev) => ({ ...prev, [currentName]: true }));
  };

  useEffect(() => {
    if (revisionNotesId && textareaRef.current) {
      autosize(textareaRef.current);
    }
  }, [revisionNotesId]);

  return (
    <div className={st.container}>
      {!isClient && (
        <div>
          <Form.Group>
            <Form.Label>
              <RequiredLabel />
              Customer
            </Form.Label>
            <Form.Select
              name='customer'
              placeholder='Select Customer'
              value={formValue.customer || ''}
              onChange={handleChange}
              disabled={isCustomer || isRemotePart}
              isInvalid={errors.customer && formDirty.customer}>
              {!isCustomer && <option value=''>Select Customer</option>}
              {customerOptionList.map((item) => (
                <option key={uuidv4()} value={item.value}>
                  {item.name}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </div>
      )}
      <div>
        <Form.Group>
          <Form.Label>
            <RequiredLabel />
            Part Type Name
          </Form.Label>
          <Form.Control
            isInvalid={errors.name && formDirty.name}
            name='name'
            onChange={handleChange}
            value={formValue.name}
            maxLength={MAX_LENGTH_INPUT}
            disabled={isRemotePart}
          />
        </Form.Group>
      </div>
      <div className={st.formGroupColumn}>
        <Form.Group className={st.groupColumn}>
          <Form.Label>
            <RequiredLabel />
            Part Number
          </Form.Label>
          <Form.Control
            isInvalid={errors.number && formDirty.number}
            name='number'
            onChange={handleChange}
            value={formValue.number}
            maxLength={MAX_LENGTH_INPUT}
            disabled={isRemotePart}
          />
        </Form.Group>
        <Form.Group className={st.groupColumn}>
          <Form.Label>
            <RequiredLabel />
            Revision
          </Form.Label>
          <Form.Control
            isInvalid={errors.revision && formDirty.revision}
            name='revision'
            onChange={handleChange}
            value={formValue.revision}
            maxLength={MAX_LENGTH_INPUT}
            disabled={isRemotePart}
          />
        </Form.Group>
        <div>
          {!isShippingRole && !isClient && (
            <PartsButtonTraveler
              isNewPart={isNewPart}
              isDirtyForm={isDirtyForm}
              travelerData={travelerData}
              isRemotePart={isRemotePart}
            />
          )}
        </div>
      </div>
      <PartsDetailsFiles
        isNewPart={isNewPart}
        isRemotePart={isRemotePart}
        cacheClientFile={cacheClientFile}
        setCacheClientFile={setCacheClientFile}
        isCustomer={isCustomer}
      />
      <div>
        <Form.Group className={st.revisionNotesHolder}>
          <Form.Label>Revision Notes</Form.Label>
          <Form.Control
            id={revisionNotesId}
            ref={textareaRef}
            placeholder='Type Something'
            name='revision_notes'
            as='textarea'
            onChange={handleChange}
            value={formValue.revision_notes}
            maxLength={MAX_LENGTH_INPUT}
            disabled={isRemotePart}
          />
        </Form.Group>
      </div>
    </div>
  );
}
export default PartsDetailsForm;
