import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';

export const initialState = {
  scoreItems: {
    data: {},
    sortIds: [],
    isLoading: false,
  },
};

export const scoreItemsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.getScoreList.start, (state) => ({
      ...state,
      scoreItems: {
        ...state.scoreItems,
        isLoading: true,
      },
    }))
    .addCase(actions.getScoreList.success, (state, action) => {
      const { data, ids } = action.payload;

      return {
        ...state,
        scoreItems: {
          ...state.scoreItems,
          data,
          sortIds: ids,
          isLoading: false,
        },
      };
    })
    .addCase(actions.getScoreList.failure, (state) => ({
      ...state,
      scoreItems: {
        ...state.scoreItems,
        isLoading: false,
      },
    }));
});
