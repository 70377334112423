import React, { useEffect, useRef } from 'react';
import OrderConstructor from 'components/Order/OrderConstructor/OrderConstructor';
import { useDispatch, useSelector } from 'react-redux';
import Text from 'componentsShared/Text/Text';
import { func, string } from 'prop-types';
import * as partTypesActions from 'store/partTypes/actions';
import PartsCardConstructor from 'components/Parts/PartsCardConstructor/PartsCardConstructor';
import { v4 as uuidv4 } from 'uuid';
import { deepClone } from 'helpers/functions';
import st from './OrderDetails.module.scss';

OrderDetails.propTypes = {
  PONumber: string,
  setPONumber: func,
  emptyText: string,
};

function OrderDetails({
  PONumber,
  setPONumber = () => {},
  emptyText = 'Nothing has been selected yet',
}) {
  const dispatch = useDispatch();

  const containerRef = useRef(null);

  const partTypeList =
    useSelector((state) => state?.partTypes?.partTypes?.data) ?? {};
  const selectedPartTypesList =
    useSelector((state) => state?.partTypes?.selectedPartTypes) ?? [];
  const selectedNewPartId =
    useSelector((state) => state?.partTypes?.isAddingPart?.selectedNewPartId) ??
    null;

  const addOrderPositions = (value) => {
    const partTypeId = value.part_type_id;

    const currentSelectedPartTypesList = deepClone(selectedPartTypesList);
    let result = [];
    const indexSelectPart = currentSelectedPartTypesList.findIndex(
      (item) => item.id === partTypeId
    );

    if (indexSelectPart !== -1) {
      const curretPart = currentSelectedPartTypesList?.[indexSelectPart];
      curretPart.quantity += 1;
      result = [...currentSelectedPartTypesList];
    } else {
      const currentData = {
        ...partTypeList[value.part_type_id],
        quantity: value.quantity,
        key: uuidv4(),
      };
      result = [...selectedPartTypesList, currentData];
    }

    dispatch(
      partTypesActions.addSelectedPartTypes({
        data: result,
        isNewPart: partTypeId,
      })
    );
  };

  const handleChangeQuantity = (value, index) => {
    dispatch(
      partTypesActions.changeQuantitySelectedPartTypes({
        value,
        index,
      })
    );
  };

  const incrementQuantity = (index) => {
    dispatch(
      partTypesActions.incrementQuantitySelectedPartTypes({
        index,
      })
    );
  };

  const decrementQuantity = (index) => {
    dispatch(
      partTypesActions.decrementQuantitySelectedPartTypes({
        index,
      })
    );
  };

  const handleDeleteOrderPositions = (index) => {
    dispatch(
      partTypesActions.deleteSelectedPartTypes({
        index,
      })
    );
  };

  const renderOrderPositions = () => {
    return selectedPartTypesList.map((item, index) => {
      const isAddingCurrentPart = item.id === selectedNewPartId;

      return (
        <PartsCardConstructor
          key={item.key}
          index={index}
          part={item}
          quantity={item.quantity}
          handleDeleteOrderPositions={handleDeleteOrderPositions}
          decrementQuantity={decrementQuantity}
          handleChangeQuantity={handleChangeQuantity}
          incrementQuantity={incrementQuantity}
          isAddingCurrentPart={isAddingCurrentPart}
        />
      );
    });
  };

  useEffect(() => {
    let timerClearSelectedNewPartId = 0;

    if (selectedNewPartId && containerRef.current) {
      const arrayChildren = Array.from(containerRef.current?.children);

      const currentCard =
        arrayChildren?.find(
          ({ id }) => id.replace('part-', '') === selectedNewPartId.toString()
        ) || null;

      currentCard?.scrollIntoView({ behavior: 'smooth' });

      timerClearSelectedNewPartId = setTimeout(() => {
        dispatch(partTypesActions.clearSelectedNewPartId());
      }, 2500);
    }

    return () => {
      clearTimeout(timerClearSelectedNewPartId);
    };
  }, [selectedNewPartId, dispatch]);

  return (
    <div className={st.container}>
      <OrderConstructor
        onSubmit={addOrderPositions}
        PONumber={PONumber}
        setPONumber={setPONumber}
      />
      <div className={st.holderOrderPositionsList} ref={containerRef}>
        {selectedPartTypesList.length > 0 ? (
          renderOrderPositions()
        ) : (
          <div className={st.containerEmpty}>
            <Text variant='normal'>{emptyText}</Text>
          </div>
        )}
      </div>
    </div>
  );
}
export default OrderDetails;
