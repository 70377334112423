import React from 'react';
import { Spinner } from 'react-bootstrap';
import { VARIANT_UI } from 'constants/ui';
import Text from 'componentsShared/Text/Text';
import { CheckIcon, ClearIcon, QRIcon } from 'assets/icons';
import { string, bool, func } from 'prop-types';
import cn from 'classnames';
import st from './ReaderQRContentErrorOrLoader.module.scss';

ReaderQRContentErrorOrLoader.propTypes = {
  isLoading: bool,
  isSuccess: bool,
  isError: bool,
  textLoading: string,
  textSuccess: string,
  textError: string,
  showIconQR: bool,
  isContent: bool,
  contentIcon: func,
  contentText: string,
  classBodyContentStatus: string,
};

function ReaderQRContentErrorOrLoader({
  isLoading = false,
  isSuccess = false,
  isError = false,
  textLoading = 'Loading',
  textSuccess = 'Success',
  textError = 'Error',
  showIconQR = true,
  isContent = false,
  contentIcon = () => {},
  contentText = '',
  classBodyContentStatus = '',
}) {
  const getIcon = () => {
    if (isLoading) {
      return <Spinner animation='border' variant={VARIANT_UI.primary} />;
    }

    if (isSuccess) {
      return <CheckIcon />;
    }

    if (isContent) {
      return contentIcon();
    }

    return <ClearIcon className={st.iconDanger} />;
  };

  const getText = () => {
    if (isLoading) {
      return textLoading;
    }

    if (isSuccess) {
      return textSuccess;
    }

    if (isContent) {
      return contentText;
    }

    return textError;
  };

  if (isLoading || isSuccess || isError || isContent) {
    return (
      <div className={cn(st.bodyContentStatus, classBodyContentStatus)}>
        <div
          className={cn(st.wrapperQrIcon, {
            [st.wrapperQrIconWidth]: !showIconQR && isLoading,
          })}>
          <div>
            {showIconQR && (
              <QRIcon
                className={cn({
                  [st.qrIconPrimary]: isLoading,
                  [st.qrIconSuccess]: isSuccess,
                  [st.qrIconError]: isError,
                })}
              />
            )}
          </div>
          <div
            className={cn(st.containerSpinner, {
              [st.containerSpinnerPositionStatic]: !showIconQR,
            })}>
            {getIcon()}
          </div>
        </div>
        <div className={st.holderText}>
          <Text variant='normal' className={st.textStatus}>
            {getText()}
          </Text>
        </div>
      </div>
    );
  }

  return null;
}

export default ReaderQRContentErrorOrLoader;
