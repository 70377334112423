import React, { useEffect } from 'react';
import CenteredColumnLayout from 'layouts/CenteredColumnLayout/CenteredColumnLayout';
import Loader from 'componentsShared/Loader/Loader';
import HeaderContent from 'componentsShared/HeaderContent/HeaderContent';
import * as jobsActions from 'store/jobs/actions';
import * as customersActions from 'store/customers/actions';
import * as controlValuesActions from 'store/controlValues/actions';
import { useSelector, useDispatch } from 'react-redux';
import JobsOperationWrapper from 'components/Jobs/JobsOperationWrapper/JobsOperationWrapper';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from 'react-router';
import { ROUTES } from 'router/routes';
import NavigationService from 'services/navigation/NavigationService';
import ButtonBack from 'componentsShared/ButtonBack/ButtonBack';
import { getQuerySearchParams } from 'helpers/path';
import ModalJobNotes from 'components/Modal/ModalJobNotes/ModalJobNotes';
import { revokeURLList } from 'services/api/files';
import { JOBS_QA_TYPES } from 'constants/jobs';
import st from './JobDetailsFinalQAPage.module.scss';

const JobDetailsFinalQAPage = () => {
  const location = useLocation();
  const jobIdList = getQuerySearchParams(location.search, 'ids');
  const firstJobId = jobIdList[0];

  const dispatch = useDispatch();

  const currentJob =
    useSelector((state) => state?.jobs?.jobs?.data?.[firstJobId]) ?? {};
  const isLoadingJob =
    useSelector((state) => state?.jobs?.jobs?.isLoading) ?? false;
  const isLoadingRejectJob =
    useSelector((state) => state?.jobs?.jobs?.isLoadingReject) ?? false;
  const isLoadingControlValues =
    useSelector((state) => state?.controlValues?.controlValues?.isLoading) ??
    false;
  const isNeedsQA =
    useSelector((state) => state?.jobs?.jobs?.isNeedsQA) ?? false;
  const customerName =
    useSelector(
      (state) =>
        state?.customers?.customers?.data?.[currentJob?.part_type?.customer_id]
          ?.name
    ) ?? '';
  const filesUrlObject =
    useSelector((state) => state?.jobs?.files?.dataUrl) ?? {};

  const customerId = currentJob?.part_type?.customer_id;
  const notes = currentJob?.part_type?.revision_notes || '';

  const {
    current_step: currentStep,
    current_operation: currentOperation,
    current_operation_index: currentOperationInde,
  } = currentJob;

  const bundleId = currentJob?.current_step?.bundle_id;

  const isControlValues =
    currentJob?.current_step?.bundle_idx ===
    currentJob?.current_operation_index;

  const quantityOperation = currentStep?.operations?.length || 0;
  const currentOperationIndex = currentJob.current_operation_index;
  const isLastOperation =
    +currentStep?.operations?.length - 1 === +currentOperationInde;

  const isLastOperationWithNeedsQA = currentStep?.needs_qa && isLastOperation;

  const isFinalQa =
    currentJob?.phase === JOBS_QA_TYPES.finalQA ||
    currentJob?.current_step?.phase === JOBS_QA_TYPES.finalQA ||
    currentJob?.current_step?.phase?.startsWith(JOBS_QA_TYPES.finalQA) ||
    currentJob?.phase?.startsWith(JOBS_QA_TYPES.finalQA);

  const handleBackToMyJobs = () => {
    dispatch(jobsActions.changeIsScrollJob(true));
    NavigationService.replacePath(ROUTES.jobList.path);
  };

  useEffect(() => {
    dispatch(jobsActions.getJob.start({ id: firstJobId }));
    dispatch(jobsActions.getReasonRejectJob.start());
  }, [dispatch, firstJobId]);

  useEffect(() => {
    if (customerId && !customerName) {
      dispatch(customersActions.getCustomer.start(customerId));
    }
  }, [dispatch, customerName, customerId]);

  useEffect(() => {
    if (bundleId) {
      dispatch(controlValuesActions.getControlValues.start(bundleId));
    }
  }, [dispatch, bundleId]);

  useEffect(() => {
    if (firstJobId && currentOperation?.require_file) {
      dispatch(jobsActions.getFileInterprocessQA.start({ id: firstJobId }));
    }
  }, [dispatch, firstJobId, currentOperation]);

  useEffect(() => {
    revokeURLList(Object.values(filesUrlObject));
    dispatch(jobsActions.clearJobFileList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <CenteredColumnLayout>
      <HeaderContent
        backBtn={
          <ButtonBack isCheckModal={false} onClick={handleBackToMyJobs} />
        }
        title={currentStep?.name || ''}>
        <ModalJobNotes notes={notes} />
      </HeaderContent>
      <div className={st.mainContentHolder}>
        <JobsOperationWrapper
          isNeedsQA={isNeedsQA}
          jobId={firstJobId}
          currentOperation={currentOperation}
          quantityOperation={quantityOperation}
          currentOperationIndex={currentOperationIndex}
          isLastOperationWithNeedsQA={isLastOperationWithNeedsQA}
          isLastOperation={isLastOperation}
          currentStep={currentStep}
          isControlValues={isControlValues}
          jobIdList={jobIdList}
          isFinalQA={isFinalQa}
        />
        <Loader
          isVisible={
            isLoadingJob ||
            isLoadingControlValues ||
            isLoadingRejectJob ||
            !currentOperation
          }
          isBackgroundColorGray
        />
      </div>
    </CenteredColumnLayout>
  );
};

export default JobDetailsFinalQAPage;
