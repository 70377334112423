export const USER_ROLES = {
  admin: 'Administrator',
  manager: 'Manager',
  managerNotifications: 'ManagerNotifications',
  QANotifications: 'QANotifications',
  qa: 'QA',
  shipping: 'Shipping',
  customer: 'Customer',
  painter: 'Painter',
  preparer: 'Preparer',
};
