import React, { useMemo } from 'react';
import Avatar from 'components/Avatar/Avatar';
import Text from 'componentsShared/Text/Text';
import Status from 'componentsShared/Status/Status';
import { object, func } from 'prop-types';
import EditEmployeeForm from 'components/Form/EditEmployeeForm/EditEmployeeForm';
import st from './OffcanvasEmployeeEditContent.module.scss';

OffcanvasEmployeeEditContent.propTypes = {
  employee: object,
  register: func,
  onSubmit: func,
  errors: object,
  handleChangeRole: func,
  initialValue: object,
};

function OffcanvasEmployeeEditContent({
  employee,
  register,
  onSubmit,
  errors,
  handleChangeRole,
  initialValue,
}) {
  const variantStatus = useMemo(() => {
    if (!employee?.status) {
      return;
    }
    if (employee?.status === 'Invited') {
      return 'warning';
    }
    return 'success';
  }, [employee]);

  return (
    <div className={st.container}>
      <div className={st.headerHolder}>
        <div className={st.headerInfo}>
          <Avatar />
          <div className={st.headerInfoHolder}>
            <Text variant='normal'>{employee?.name}</Text>
            <Text variant='normal2' className={st.headerInfoEmail}>
              {employee?.login}
            </Text>
          </div>
        </div>
        {employee?.status && (
          <Status title={employee?.status} variant={variantStatus} />
        )}
      </div>
      <div>
        <EditEmployeeForm
          initialValue={initialValue}
          register={register}
          onSubmit={onSubmit}
          errors={errors}
          handleChangeRole={handleChangeRole}
        />
      </div>
    </div>
  );
}

export default OffcanvasEmployeeEditContent;
