import React from 'react';
import { string, func, number } from 'prop-types';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import Loader from 'componentsShared/Loader/Loader';
import Skeleton from 'componentsShared/Skeleton/Skeleton';

FilePDF.propTypes = {
  currentUrl: string,
  onDocumentLoadSuccess: func,
  pageNumber: number,
  widthPDF: number,
};

function FilePDF({
  currentUrl = '',
  onDocumentLoadSuccess = () => {},
  pageNumber = 1,
  widthPDF = 400,
  heightPDF = null,
  isLoadSkeleton = false,
  classNameSkeleton,
  classNamePDfDocument,
}) {
  return (
    <Document
      file={{
        url: currentUrl,
      }}
      onLoadSuccess={onDocumentLoadSuccess}
      loading={
        isLoadSkeleton ? (
          <Skeleton isVisible classNameSkeleton={classNameSkeleton} />
        ) : (
          <Loader isVisible />
        )
      }
      className={classNamePDfDocument}>
      <Page pageNumber={pageNumber} width={widthPDF} height={heightPDF} />
    </Document>
  );
}

export default FilePDF;
