import { all, put, takeLatest, call } from 'redux-saga/effects';
import { handleRequestError } from 'services/api/apiTools';
import ApiService from 'services/api';
import AuthService from 'services/auth/AuthService';
import { MODULE_TYPES } from 'constants/files';
import * as actions from './actions';

export function* getNoteListSaga(actionOrArgument) {
  const params = actionOrArgument.payload || actionOrArgument;
  const isClient = AuthService.isClient();

  const currentParams = {
    id: params.id,
    ...(isClient ? { type: params.type } : {}),
  };

  let requestValue;
  let currentApi;

  if (params.type === MODULE_TYPES.jobs) {
    currentApi = ApiService.getJobNoteList;
  }
  if (params.type === MODULE_TYPES.rfqs) {
    currentApi = ApiService.getRFQNoteList;
  }
  if (isClient) {
    currentApi = ApiService.getClientNoteList;
  }

  try {
    requestValue = yield call(currentApi, currentParams);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getNoteList.failure());
    return;
  }

  yield put(
    actions.getNoteList.success({ data: { [params.id]: requestValue } })
  );
}

export function* createNoteSaga(action) {
  const params = action.payload;
  const isClient = AuthService.isClient();

  const currentParams = {
    data: params.data,
    id: params.id,
    ...(isClient ? { type: params.type } : {}),
  };

  let currentApi;

  if (params.type === MODULE_TYPES.jobs) {
    currentApi = ApiService.createJobNote;
  }
  if (params.type === MODULE_TYPES.rfqs) {
    currentApi = ApiService.createRFQNote;
  }
  if (isClient) {
    currentApi = ApiService.createClientNote;
  }

  try {
    yield call(currentApi, currentParams);
    yield call(getNoteListSaga, { id: params.id, type: params.type });
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.createNote.failure());
    return;
  }

  yield put(actions.createNote.success());
}

export function* notesSagas() {
  yield all([
    yield takeLatest(actions.getNoteList.start, getNoteListSaga),
    yield takeLatest(actions.createNote.start, createNoteSaga),
  ]);
}
