import Text from 'componentsShared/Text/Text';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useParams } from 'react-router';
import * as partTypesActions from 'store/partTypes/actions';
import PartsCard from 'components/Parts/PartsCard/PartsCard';
import { object, bool } from 'prop-types';
import NavigationService from 'services/navigation/NavigationService';
import { getRoutePath } from 'helpers/path';
import { ROUTES } from 'router/routes';
import moment from 'moment';
import { TRAVELER_STATUS } from 'constants/traveler';
import { FORMAT_DATE_USA } from 'constants/ui';
import st from './PartsWrapperCardManager.module.scss';

PartsWrapperCardManager.propTypes = {
  data: object,
  isCustomerNameShow: bool,
  isCustomerRole: bool,
};

function PartsWrapperCardManager({
  data,
  isCustomerNameShow,
  isCustomerRole,
  page,
}) {
  const dispatch = useDispatch();
  const { id: customerId } = useParams();

  const currentCustomerId = customerId || data?.customer_id;

  const isNewPart =
    useSelector((state) => state?.partTypes?.isAddingPart?.isNewPart) ?? false;
  const addingPartId =
    useSelector((state) => state?.partTypes?.isAddingPart?.partId) ?? null;
  const customerName =
    useSelector(
      (state) => state?.customers?.customers?.data?.[currentCustomerId]?.name
    ) ?? '';

  const [isRemoving, setIsRemoving] = useState(false);
  const [showModalRemove, setShowModalRemove] = useState(false);

  const isAddingCurrentPart =
    isNewPart && addingPartId?.toString() === data?.id?.toString();

  const partName = useMemo(() => {
    if (isCustomerNameShow) {
      return `${customerName}: ${data.name}`;
    }
    return data.name;
  }, [isCustomerNameShow, customerName, data]);

  const handleRemove = () => {
    dispatch(
      partTypesActions.removePart.start({ id: data.id, isPagination: true })
    );
    setIsRemoving(true);
  };

  const onRemove = () => {
    setShowModalRemove(true);
  };

  const handleClickCard = () => {
    if (isCustomerRole) {
      return;
    }

    dispatch(partTypesActions.changeScrollPartId(`${data.id}-${page}`));

    NavigationService.navigateToPath(
      getRoutePath(ROUTES.partTypesDetails.path, {
        id: data.id,
      })
    );
  };

  const dropdownList = [
    {
      content: () => (
        <Text variant='normal' className={st.removeOrderText}>
          Remove Part
        </Text>
      ),
      onClick: onRemove,
    },
  ];

  const isNeedsTraveler = useMemo(() => {
    if (isCustomerRole) {
      return false;
    }

    return (
      !data?.traveler?.id || data?.traveler?.status === TRAVELER_STATUS.DRAFT
    );
  }, [data?.traveler?.id, data?.traveler?.status, isCustomerRole]);

  const travelerStatusText = useMemo(() => {
    if (isCustomerRole) {
      if (data.updated) {
        return `Update ${moment(data.updated).format(FORMAT_DATE_USA)}`;
      }
      return '';
    }

    if (isNeedsTraveler) {
      return 'Traveler Needed';
    }

    return 'Traveler Active';
  }, [isCustomerRole, data.updated, isNeedsTraveler]);

  const partNumber = useMemo(() => {
    const currentRevision = data.revision ? `-Revision-${data.revision}` : '';
    return ` ${data.number}${currentRevision}`;
  }, [data.number, data.revision]);

  useEffect(() => {
    const clearIsAddingPartTimer = setTimeout(() => {
      dispatch(partTypesActions.clearIsAddingPart());
    }, 2000);

    return () => clearTimeout(clearIsAddingPartTimer);
  }, [dispatch]);

  return (
    <PartsCard
      id={data.id}
      partName={partName}
      partNumber={partNumber}
      travelerStatusText={travelerStatusText}
      handleRemove={handleRemove}
      cutomerName={customerName}
      isRemoving={isRemoving}
      showModalRemove={showModalRemove}
      setShowModalRemove={setShowModalRemove}
      isAddingCurrentPart={isAddingCurrentPart}
      dropdownList={dropdownList}
      isNeedsTraveler={isNeedsTraveler}
      handleClickCard={handleClickCard}
      isCustomerRole={isCustomerRole}
    />
  );
}

export default PartsWrapperCardManager;
