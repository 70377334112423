import Text from 'componentsShared/Text/Text';
import React from 'react';
import { CloseButton } from 'react-bootstrap';
import { string, func, bool } from 'prop-types';
import cn from 'classnames';
import st from './TypeaheadToken.module.scss';

TypeaheadToken.propTypes = {
  onRemove: func,
  label: string,
  disabled: bool,
};

function TypeaheadToken({ onRemove = () => {}, label, disabled = false }) {
  return (
    <div className={cn(st.container, { [st.containerDisabled]: disabled })}>
      <Text variant='normal3'>{label}</Text>
      <CloseButton onClick={() => onRemove(label)} disabled={disabled} />
    </div>
  );
}

export default TypeaheadToken;
