export const reValidEmail =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const moreOneZeroBeginning = /^0{2,}/;

export const reContainEightCharacters = /^(?=.{8,})/;

export const reContainSixCharacters = /^(?=.{6,})/;

export const reContainOneLowercase = /^(?=.*[a-z])/;

export const reContainOneUppercase = /^(?=.*[A-Z])/;

export const reContainOneNumber = /^(?=.*[0-9])/;

export const reContainOneSpecialCaseCharacter =
  // eslint-disable-next-line no-useless-escape
  /^(?=.*[~!@#$%^&*\(\)\[\]\{\}`;.?,_:\\'\"|/+-])/;
