import { combineReducers } from 'redux';
import { createReducer } from '@reduxjs/toolkit';
import {
  travelersReducer,
  initialState as travelersInitialState,
} from 'store/travelers/reducer';
import { uiReducer, initialState as uiInitialState } from 'store/ui/reducer';
import {
  authReducer,
  initialState as authInitialState,
} from 'store/auth/reducer';
import {
  customersReducer,
  initialState as customersInitialState,
} from 'store/customers/reducer';
import {
  employeesReducer,
  initialState as employeesInitialState,
} from 'store/employees/reducer';
import {
  templatesReducer,
  initialState as templatesInitialState,
} from 'store/templates/reducer';
import {
  partTypesReducer,
  initialState as partTypesInitialState,
} from 'store/partTypes/reducer';
import {
  ordersReducer,
  initialState as ordersInitialState,
} from 'store/orders/reducer';
import {
  controlValuesReducer,
  initialState as controlValuesInitialState,
} from 'store/controlValues/reducer';
import {
  jobsReducer,
  initialState as jobsInitialState,
} from 'store/jobs/reducer';
import {
  filesReducer,
  initialState as filesInitialState,
} from 'store/files/reducer';
import {
  rfqsReducer,
  initialState as rfqsInitialState,
} from 'store/rfqs/reducer';
import {
  worklogsReducer,
  initialState as worklogsInitialState,
} from 'store/worklogs/reducer';
import {
  notesReducer,
  initialState as notesInitialState,
} from 'store/notes/reducer';
import {
  scoreItemsReducer,
  initialState as scoreItemsInitialState,
} from 'store/scoreItems/reducer';
import {
  reportsReducer,
  initialState as reportsInitialState,
} from 'store/reports/reducer';
import {
  metadataReducer,
  initialState as metadataInitialState,
} from 'store/metadata/reducer';

import { ncrReducer, initialState as ncrInitialState } from 'store/ncr/reducer';

import * as rootActions from 'store/rootActions';

export const appReducer = combineReducers({
  travelers: travelersReducer,
  ui: uiReducer,
  auth: authReducer,
  customers: customersReducer,
  orders: ordersReducer,
  employees: employeesReducer,
  templates: templatesReducer,
  partTypes: partTypesReducer,
  controlValues: controlValuesReducer,
  jobs: jobsReducer,
  files: filesReducer,
  rfqs: rfqsReducer,
  worklogs: worklogsReducer,
  notes: notesReducer,
  scoreItems: scoreItemsReducer,
  ncr: ncrReducer,
  reports: reportsReducer,
  metadata: metadataReducer,
});

const initialState = {
  travelers: travelersInitialState,
  ui: uiInitialState,
  auth: authInitialState,
  customers: customersInitialState,
  orders: ordersInitialState,
  employees: employeesInitialState,
  templates: templatesInitialState,
  partTypes: partTypesInitialState,
  controlValues: controlValuesInitialState,
  jobs: jobsInitialState,
  files: filesInitialState,
  rfqs: rfqsInitialState,
  worklogs: worklogsInitialState,
  notes: notesInitialState,
  scoreItems: scoreItemsInitialState,
  ncr: ncrInitialState,
  reports: reportsInitialState,
  metadata: metadataInitialState,
};

export const rootReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(rootActions.resetState, () => initialState)
    .addDefaultCase(appReducer);
});
