import CenteredColumnLayout from 'layouts/CenteredColumnLayout/CenteredColumnLayout';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as customersActions from 'store/customers/actions';
import Loader from 'componentsShared/Loader/Loader';
import HeaderContent from 'componentsShared/HeaderContent/HeaderContent';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useParams, useLocation } from 'react-router';
import { ROUTES } from 'router/routes';
import NavigationService from 'services/navigation/NavigationService';
import { Tabs, Tab } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { getQueryParam, getQueryVariable } from 'helpers/path';
import CustomersPOManagerShipping from 'components/Customers/CustomersPOManagerShipping/CustomersPOManagerShipping';
import CustomersPartManagerShipping from 'components/Customers/CustomersPartManagerShipping/CustomersPartManagerShipping';
import ButtonBack from 'componentsShared/ButtonBack/ButtonBack';
import st from './CustomerDetailsShippingPage.module.scss';

const tabList = [
  {
    title: 'Purchase Orders',
    component: <CustomersPOManagerShipping />,
    key: 'purchaseOrders',
  },
  {
    title: 'Parts',
    component: <CustomersPartManagerShipping />,
    key: 'part',
  },
];

function CustomerDetailsShippingPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryVariable = getQueryVariable(location.search);
  const currentType = queryVariable.type;
  const [type, setType] = useState(currentType);

  const { id: customerId } = useParams();

  const customer =
    useSelector((state) => state?.customers?.customers?.data?.[customerId]) ??
    {};
  const isLoadingCustomers =
    useSelector((state) => state?.customers?.customers?.isLoading) ?? false;
  const partTypesPage =
    useSelector((state) => state?.partTypes?.fetchParams?.pagination?.page) ??
    0;

  const handleBack = () => {
    NavigationService.replacePath(ROUTES.customerListManager.path);
  };

  const getCurrentPage = (typeKey) => {
    switch (typeKey) {
      case tabList[1].key:
        return partTypesPage;
      default:
        return 0;
    }
  };

  const handleChangeTab = (item) => {
    setType(item);
    const queryParams = {
      ...queryVariable,
      page: getCurrentPage(item) ?? queryVariable.page,
      type: item,
    };
    const queryParamsString = getQueryParam(queryParams);
    const path = `${location.pathname}${queryParamsString}`;
    NavigationService.replacePath(path);
  };

  const onSelectTab = (item) => {
    handleChangeTab(item);
  };

  const renderTabList = () => {
    return tabList.map(({ title, component, key }) => {
      return (
        <Tab eventKey={key} title={title} key={key}>
          {component}
        </Tab>
      );
    });
  };

  useEffect(() => {
    if (!currentType) {
      const defaultTab = tabList[0].key;
      handleChangeTab(defaultTab);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentType]);

  useEffect(() => {
    dispatch(customersActions.getCustomer.start(customerId));
  }, [dispatch, customerId]);

  return (
    <CenteredColumnLayout>
      <HeaderContent
        backBtn={<ButtonBack isCheckModal={false} onClick={handleBack} />}
        title={customer.name || ''}
      />
      <div className={st.tabsWrapper}>
        <Tabs
          activeKey={type}
          defaultActiveKey={tabList[0]?.key}
          id={uuidv4()}
          onSelect={onSelectTab}>
          {renderTabList()}
        </Tabs>
      </div>

      <Loader isVisible={isLoadingCustomers} isBackgroundColorGray />
    </CenteredColumnLayout>
  );
}

export default CustomerDetailsShippingPage;
