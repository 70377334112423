import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import { handleRequestError } from 'services/api/apiTools';
import {
  getParsingArrayToObj,
  getValidControlValueList,
} from 'helpers/functions';
import ApiService from 'services/api';
import { PAGE_SIZE } from 'constants/ui';
import * as actions from './actions';

export function* getControlValueListSaga(action) {
  const { isPagination, ...params } = action?.payload || {};
  let requestValue;

  const page = yield select(
    (state) => state?.controlValues?.fetchParams?.pagination?.page
  ) ?? 0;
  const size = yield select(
    (state) => state?.controlValues?.fetchParams?.pagination?.size
  ) ?? PAGE_SIZE;

  const paramsApi = {
    ...params,
    page: isPagination ? page : null,
    size: isPagination ? size : null,
  };

  try {
    requestValue = yield call(ApiService.getControlValueList, paramsApi);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getControlValueList.failure());
    return;
  }

  const validControlValueList = getValidControlValueList(requestValue.items);
  const controlValuesObj = getParsingArrayToObj(validControlValueList);
  const controlValuesIds = Object.keys(controlValuesObj);
  const activeIds = requestValue.items
    .filter(({ deleted }) => !deleted)
    .map(({ id }) => id);

  yield put(
    actions.getControlValueList.success({
      data: controlValuesObj,
      ids: controlValuesIds,
      activeIds,
      page: requestValue.page,
      count: requestValue.count,
    })
  );
}

export function* createControlValueSaga(action) {
  const params = action.payload;
  try {
    yield call(ApiService.createControlValue, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.createControlValue.failure());
    return;
  }

  yield call(getControlValueListSaga);
  yield put(actions.createControlValue.success());
}

export function* changeControlValueSaga(action) {
  const { data, id } = action.payload;
  try {
    yield call(ApiService.changeControlValue, { data, id });
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.changeControlValue.failure());
    return;
  }

  yield call(getControlValueListSaga);
  yield put(actions.changeControlValue.success());
}

export function* removeControlValueSaga(action) {
  const params = action.payload;
  try {
    yield call(ApiService.removeControlValue, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.removeControlValue.failure());
    return;
  }

  yield call(getControlValueListSaga);
  yield put(actions.removeControlValue.success());
}

export function* getControlValuesSaga(action) {
  const params = action.payload;
  let requestValue;
  try {
    requestValue = yield call(ApiService.getControlValue, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getControlValues.failure());
    return;
  }

  const validControlValuesList = getValidControlValueList([requestValue]);
  const controlValuesObj = getParsingArrayToObj(validControlValuesList);
  const controlValuesIds = [requestValue.id];
  const activeIds = requestValue?.deleted ? [] : [requestValue.id];

  yield put(
    actions.getControlValues.success({
      data: controlValuesObj,
      ids: controlValuesIds,
      activeIds,
    })
  );
}

export function* getBatchControlValueListSaga(action) {
  const params = action.payload;
  let requestValue;

  try {
    requestValue = yield all(
      params.map((id) => call(ApiService.getControlValue, id))
    );
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getBatchControlValueList.failure());
    return;
  }
  const validControlValuesList = getValidControlValueList(requestValue);
  const data = getParsingArrayToObj(validControlValuesList);
  const ids = Object.keys(data);

  yield put(
    actions.getBatchControlValueList.success({
      ids,
      data,
    })
  );
}

export function* controlValuesSagas() {
  yield all([
    yield takeLatest(
      actions.getControlValueList.start,
      getControlValueListSaga
    ),
    yield takeLatest(actions.createControlValue.start, createControlValueSaga),
    yield takeLatest(actions.changeControlValue.start, changeControlValueSaga),
    yield takeLatest(actions.removeControlValue.start, removeControlValueSaga),
    yield takeLatest(actions.getControlValues.start, getControlValuesSaga),
    yield takeLatest(
      actions.getBatchControlValueList.start,
      getBatchControlValueListSaga
    ),
  ]);
}
