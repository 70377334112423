import React, { useEffect } from 'react';
import Modal from 'componentsShared/Modal/Modal';
import { Button } from 'react-bootstrap';
import TravelerPhaseTemplate from 'components/TravalerTemplate/TravelerTemplatePhase/TravelerTemplatePhase';
import { VARIANT_OUTLINE_UI } from 'constants/ui';
import { useSelector } from 'react-redux';
import { bool, func, oneOfType, number, string } from 'prop-types';
import TemplateService from 'services/template/TemplateService';

ModalTravelerTemlates.propTypes = {
  showModal: bool.isRequired,
  setShowModal: func.isRequired,
  handleSubmit: func,
  handleSkip: func,
  handleClose: func,
  selectTemplateId: oneOfType([number, string]),
  setSelectTemplateId: func,
};

function ModalTravelerTemlates({
  showModal,
  setShowModal,
  handleSubmit = () => {},
  handleSkip = () => {},
  handleClose = () => {},
  selectTemplateId,
  setSelectTemplateId = () => {},
}) {
  const templateList =
    useSelector((state) => state?.templates?.templates?.data) ?? {};
  const currentTemplate =
    useSelector(
      (state) => state?.templates?.templates?.data?.[selectTemplateId]
    ) ?? {};
  const isLoadingTemplate =
    useSelector((state) => state?.templates?.templates?.isLoading) ?? false;
  const templateListArray = Object.values(templateList);

  const selectTemplateList = templateListArray.map((templateItem) => ({
    value: templateItem.id,
    name: templateItem.name,
  }));

  const isDirtyTemplate = TemplateService.isDirtyTemplate(selectTemplateId);
  const isEmptyTemplate = TemplateService.isEmptyTemplate();

  useEffect(() => {
    if (selectTemplateId === undefined && templateListArray.length > 0) {
      setSelectTemplateId(templateListArray[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [templateListArray]);

  return (
    <>
      <Modal
        show={showModal}
        setShow={setShowModal}
        title='New Traveler'
        heightBody='calc(100vh - 240px)'
        size='lg'
        backdrop='static'
        disabledAcceptBtn={!isDirtyTemplate || isLoadingTemplate}
        onAcceptBtn={() => handleSubmit(currentTemplate)}
        titleAcceptBtn='Create'
        onHide={handleClose}
        buttonBottomLeft={({ onClose }) => (
          <>
            <Button
              disabled={isLoadingTemplate}
              variant={VARIANT_OUTLINE_UI.secondary}
              onClick={() => handleSkip(onClose)}>
              Skip Wizard
            </Button>
          </>
        )}>
        <TravelerPhaseTemplate
          selectTemplateId={selectTemplateId}
          isEmptyTemplate={isEmptyTemplate}
          selectTemplateList={selectTemplateList}
          handleSelectTemplate={setSelectTemplateId}
        />
      </Modal>
    </>
  );
}

export default ModalTravelerTemlates;
