import React, { useEffect, useRef, useState } from 'react';
import { VARIANT_OUTLINE_UI, VARIANT_UI } from 'constants/ui';
import Modal from 'componentsShared/Modal/Modal';
import AuthService from 'services/auth/AuthService';
import { Button, Form } from 'react-bootstrap';
import { Bell } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import * as metadataActions from 'store/metadata/actions';

import Loader from 'componentsShared/Loader/Loader';
import st from './HeaderNotification.module.scss';

function HeaderNotification({ setStopCheckMessage }) {
  const dispatch = useDispatch();

  const isUpadate =
    useSelector((state) => state?.metadata?.metadata?.data?.isUpadate) ?? false;
  const isLoading =
    useSelector((state) => state?.metadata?.metadata?.data?.isLoading) ?? false;
  const message =
    useSelector((state) => state?.metadata?.metadata?.data?.message) ?? '';

  const [isOpen, setIsOpen] = useState(false);
  const [valueForm, setValueForm] = useState('');
  const [isEditMessage, setIsEditMessage] = useState(false);
  const [isStatusClose, setIsStatusClose] = useState(false);

  const refInput = useRef(null);

  const isAdminRole = AuthService.isAdminRole();
  const isManagerRole = AuthService.isManagerRole();

  const handleSave = () => {
    setIsEditMessage(false);
    dispatch(metadataActions.updateMessage.start({ message: valueForm }));
  };

  const handleToggleStatusClose = () => {
    setIsStatusClose((prev) => !prev);
  };

  const handleDiscardChanges = () => {
    setIsStatusClose(false);
    setIsEditMessage(false);
    setValueForm(message);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setValueForm(value);
  };

  const handleOpenModal = () => {
    setIsOpen(true);
    setStopCheckMessage(true);
    dispatch(metadataActions.updateIsUpadate());
  };

  const renderFooterButton = () => {
    if (!isAdminRole && !isManagerRole) {
      return null;
    }

    if (!isEditMessage) {
      return (
        <Button
          variant={VARIANT_OUTLINE_UI.primary}
          onClick={() => setIsEditMessage(true)}>
          Edit Message
        </Button>
      );
    }

    if (!isStatusClose) {
      return (
        <div className={st.groupButton}>
          <Button
            variant={VARIANT_OUTLINE_UI.primary}
            onClick={handleToggleStatusClose}>
            Cancel
          </Button>
          <Button variant={VARIANT_UI.primary} onClick={handleSave}>
            Save
          </Button>
        </div>
      );
    }

    return (
      <div className={st.groupButton}>
        <Button
          variant={VARIANT_OUTLINE_UI.danger}
          onClick={handleDiscardChanges}>
          Discard Changes
        </Button>
        <Button variant={VARIANT_UI.primary} onClick={handleToggleStatusClose}>
          Keep Editing
        </Button>
      </div>
    );
  };

  useEffect(() => {
    if (!isOpen) {
      setStopCheckMessage(false);
    } else {
      dispatch(metadataActions.getMessage.start());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    if (isOpen) {
      setValueForm(message);
    }
  }, [isOpen, message]);

  useEffect(() => {
    if (isEditMessage && refInput.current) {
      refInput.current.focus();
    }
  }, [isEditMessage]);

  return (
    <>
      <div className={st.holderIcon}>
        <Bell className={st.bellIcon} onClick={handleOpenModal} />
        {isUpadate && <div className={st.updatednotification} />}
      </div>
      <Modal
        title='Message Board'
        show={isOpen}
        setShow={setIsOpen}
        buttonBottomRight={renderFooterButton}
        size='lg'
        heightBody='calc(100vh - 220px)'
        classNameModal={st.containerModal}>
        <Loader isVisible={isLoading} />
        <Form.Control
          as='textarea'
          className={st.input}
          value={valueForm}
          onChange={handleChange}
          ref={refInput}
          disabled={!isEditMessage}
        />
      </Modal>
    </>
  );
}

export default HeaderNotification;
