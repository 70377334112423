import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from 'react-router'; // TODO: add to dependencies
import { Link } from 'react-router-dom';
import Text from 'componentsShared/Text/Text';
import AuthService from 'services/auth/AuthService';
import NavigationService from 'services/navigation/NavigationService';
import { ADMIN_NAV } from 'constants/navBar';
import { getValidPath } from 'helpers/path';
import { ROUTES } from 'router/routes';
import cn from 'classnames';
import st from './AdminNavBar.module.scss';

function AdminNavBar() {
  const location = useLocation();

  const currentRole = AuthService.getHighestUserRole();
  const isAdminRole = AuthService.isAdminRole();
  const menuItems = ADMIN_NAV[currentRole] || [];

  const isActiveReport =
    getValidPath(ROUTES.adminReports.path) === location.pathname;

  return (
    <div className={st.container}>
      <Link
        key='admin-nav-bar-back'
        className={st.navItem}
        to={getValidPath(NavigationService.getDefaultPath())}>
        <Text variant='normal' bold className={st.navItemText}>
          Workspace
        </Text>
      </Link>
      <div className={st.separator} />

      {menuItems.map((item) => {
        const isActive = item.link === location.pathname;
        return (
          <Link
            key={`admin-nav-bar-${item.label}`}
            className={cn(st.navItem, { [st.navItemAtcive]: isActive })}
            to={item.link}>
            <Text
              variant='normal'
              bold
              className={cn(st.navItemText, {
                [st.navItemTextAtcive]: isActive,
              })}>
              {item.label}
            </Text>
          </Link>
        );
      })}
      {isAdminRole && (
        <>
          <div className={st.separator} />
          <Link
            className={cn(st.navItem, { [st.navItemAtcive]: isActiveReport })}
            to={getValidPath(ROUTES.adminReports.path)}>
            <Text
              variant='normal'
              bold
              className={cn(st.navItemText, {
                [st.navItemTextAtcive]: isActiveReport,
              })}>
              Reports
            </Text>
          </Link>
        </>
      )}
    </div>
  );
}

export default AdminNavBar;
