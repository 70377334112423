// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".NewOrderPage_mainContentHolder__VEODw {\n  position: relative; }\n", "",{"version":3,"sources":["webpack://src/pages/NewOrderPage/NewOrderPage.module.scss"],"names":[],"mappings":"AAEA;EACE,kBAAkB,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.mainContentHolder {\n  position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainContentHolder": "NewOrderPage_mainContentHolder__VEODw"
};
export default ___CSS_LOADER_EXPORT___;
