import React from 'react';
import Text from 'componentsShared/Text/Text';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { DND_TYPE } from 'constants/traveler';
import DragCustomPlaceholder from 'componentsShared/DragCustomPlaceholder/DragCustomPlaceholder';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useParams } from 'react-router';
import { INTERMEDIATE_FILE_DRAGGABLE_ID } from 'constants/files';
import TravelerService from 'services/traveler/TravelerService';
import { getIconFile } from 'helpers/file';
import cn from 'classnames';
import st from './TravelerFileList.module.scss';

TravelerFileList.propTypes = {
  operation: PropTypes.object.isRequired,
  operationId: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]),
  stepId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  placeholderProps: PropTypes.shape({
    props: PropTypes.object,
    type: PropTypes.string,
    sourceDroppableId: PropTypes.string,
  }),
  requireFile: PropTypes.bool,
};

function TravelerFileList({
  operation,
  operationId,
  stepId,
  placeholderProps,
  providedDroppableFile,
  snapshotDroppableFile,
  requireFile = false,
}) {
  const { id: travelerId } = useParams();
  const { files: fileList } = operation;

  const hasFileList = Array.isArray(fileList) && fileList.length > 0;

  const isStatusActual = TravelerService.isStatusActual(travelerId);

  const getFileName = (fileName) => {
    if (!fileName) {
      return '';
    }

    const fileNameArray = fileName.split('/');
    const currentFileName = fileNameArray[fileNameArray.length - 1];
    return currentFileName;
  };

  const renderFileList = () => {
    return fileList.map((fileName, i) => {
      return (
        <Draggable
          key={`${stepId}/${operationId}${INTERMEDIATE_FILE_DRAGGABLE_ID}${fileName}`}
          draggableId={`${stepId}/${operationId}${INTERMEDIATE_FILE_DRAGGABLE_ID}${fileName}`}
          index={i}>
          {(providedFile, snapshotFile) => (
            <div
              {...providedFile.draggableProps}
              ref={providedFile.innerRef}
              className={cn(st.containerFile, {
                [st.containerFileIsRequireFile]: requireFile,
              })}>
              <div
                {...(!isStatusActual ? providedFile.dragHandleProps : {})}
                className={cn(st.fileWrapper, {
                  [st.fileDragging]: snapshotFile.isDragging,
                })}>
                <div
                  className={cn(st.holderFile, {
                    [st.fileHolderDragging]: snapshotFile.isDragging,
                  })}>
                  {getIconFile(fileName, st.iconFile, true)}
                </div>
                <Text varint='normal' className={st.name}>
                  {getFileName(fileName)}
                </Text>
              </div>
              {isStatusActual && <div {...providedFile.dragHandleProps} />}
            </div>
          )}
        </Draggable>
      );
    });
  };

  return (
    <div className={cn(st.droppableFile)} ref={providedDroppableFile.innerRef}>
      <div className={cn(st.fileList)}>{hasFileList && renderFileList()}</div>
      {providedDroppableFile.placeholder}

      <DragCustomPlaceholder
        placeholderProps={placeholderProps}
        type={DND_TYPE.file}
        isDraggingOver={snapshotDroppableFile.isDraggingOver}
      />
    </div>
  );
}

export default TravelerFileList;
