import { BoxArrowUpRightIcon, InfoSquareFillIcon } from 'assets/icons';
import ModalButton from 'componentsShared/ModalButton/ModalButton';
import Text from 'componentsShared/Text/Text';
import Tooltip from 'componentsShared/Tooltip/Tooltip';
import {
  REPORT_BREAKDOWN,
  REPORT_FIELDS_KEY,
  REPORT_FIELD_LIST_CUSTOMER,
  REPORT_FIELD_LIST_EMPLOYEE,
  REPORT_FIELD_LIST_JOB,
  REPORT_FIELD_LIST_NCR,
  REPORT_TYPE_API,
} from 'constants/reports';
import { VARIANT_OUTLINE_UI } from 'constants/ui';
import React, { Fragment } from 'react';
import { Button } from 'react-bootstrap';
import { ROUTES } from 'router/routes';
import { getRoutePath } from './path';
import st from './reports.module.scss';

export const validBodyGenerateApi = (value) => {
  const reportType = REPORT_TYPE_API[value.reportType];
  const filters =
    value.filters.length > 0
      ? {
          [REPORT_FIELDS_KEY[value.reportType]]: value.filters
            .map((item) => item.value)
            .join(';'),
        }
      : {};

  const fields = getReportFielList(value.reportType);

  return {
    report_type: reportType,
    fields,
    filters,
    time_from: value.timeFrom,
    time_to: value.timeTo,
  };
};

export const validFieldNCR = (value) => {
  if (value === 'false') {
    return false;
  }
  return 'True';
};

export const validFieldTotalTime = (sec) => {
  if (!sec) {
    return '0d, 0h, 0m';
  }

  const [hour, minute, second] = sec.split(':');

  const result = `${hour}h, ${minute}m, ${second}s`;

  return result;
};

export const getReportFielList = (value) => {
  if (value === REPORT_BREAKDOWN[1].key) {
    return REPORT_FIELD_LIST_JOB;
  }

  if (value === REPORT_BREAKDOWN[2].key) {
    return REPORT_FIELD_LIST_CUSTOMER;
  }

  if (value === REPORT_BREAKDOWN[3].key) {
    return REPORT_FIELD_LIST_NCR;
  }

  return REPORT_FIELD_LIST_EMPLOYEE;
};

export const validFieldTravelerId = (value) => {
  if (!value) {
    return null;
  }

  return (
    <div
      className={st.holderFieldTraveler}
      style={{
        display: 'flex',
        alignItems: 'center',
        color: '#0d6efd',
      }}>
      {value}
      <BoxArrowUpRightIcon className={st.travelerIcon} />
    </div>
  );
};

export const handleClickTravelerField = (id) => {
  if (!id) {
    return;
  }
  const path = getRoutePath(ROUTES.treavelersDetails.path, {
    id,
  });
  const currentUrl = window.location.origin;
  const pathTraveler = `${currentUrl}/${path}`;
  window.open(pathTraveler, '_blank');
};

export const validFieldNCRFORNcrReport = (value) => {
  if (!value || typeof value !== 'string') {
    return <></>;
  }

  const dataNCR = value.split(';');

  const renderTooltipText = () => {
    return dataNCR.map((item, index) => {
      const [key, valueNcr] = item.split('=');
      return (
        <React.Fragment key={key}>
          {index !== 0 && <br />}
          <Text variant='normal3' bold className={st.ncrFieldTitleModalTooltip}>
            {key}
          </Text>
          <br />
          {valueNcr}
          <br />{' '}
        </React.Fragment>
      );
    });
  };

  const renderModalBody = () => {
    return dataNCR.map((item) => {
      const [key, valueNcr] = item.split('=');
      return (
        <div key={key}>
          <Text variant='normal3' bold className={st.ncrFieldTitleModal}>
            {key}
          </Text>
          <br />
          <Text variant='normal2' className={st.ncrFieldTextTextModal}>
            {valueNcr}
          </Text>
        </div>
      );
    });
  };

  return (
    <ModalButton
      title='NCR Report Details'
      bodyContent={() => {
        return <div className={st.holderModalContent}>{renderModalBody()}</div>;
      }}
      buttonBottomRight={({ onHide }) => (
        <Button variant={VARIANT_OUTLINE_UI.primary} onClick={onHide}>
          Close
        </Button>
      )}
      buttonRenderer={({ onClick }) => (
        <Tooltip
          placement='left'
          textTooltip={<>{renderTooltipText()}</>}
          isTooltip>
          <div className={st.containerNcrField} onClick={onClick}>
            <InfoSquareFillIcon />
          </div>
        </Tooltip>
      )}
    />
  );
};
