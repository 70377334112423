import { useEffect, useState } from 'react';

function CheckActivePhase({ phaseIds, ref, initialId, addingScroll = 120 }) {
  const [activePhaseId, setActivePhaseId] = useState(initialId || phaseIds[0]);
  const [heightLastComponent, setHeightLastComponent] = useState(150);
  const [heightContainer, setHeightContainer] = useState(800);

  const onWindowScoll = () => {
    const postionsPhases = phaseIds.map((id, index) => {
      const offsetTop = document.getElementById(id)?.offsetTop;
      const offsetHeight = document.getElementById(id)?.offsetHeight;

      if (index === phaseIds.length - 1) {
        setHeightLastComponent(offsetHeight);
      }
      return offsetTop + offsetHeight;
    });
    const scrollTopWrapper = ref.current.scrollTop + addingScroll;
    const offsetHeightWrapper = ref.current.offsetHeight;
    const phaseIndex = postionsPhases.findIndex(
      (value) => value > scrollTopWrapper
    );

    setHeightContainer(offsetHeightWrapper);
    setActivePhaseId(phaseIds[phaseIndex]);
  };

  useEffect(() => {
    setActivePhaseId(initialId);
  }, [initialId]);

  useEffect(() => {
    if (phaseIds.length > 0) {
      const { current } = ref;
      current.addEventListener('scroll', onWindowScoll);
      return () => {
        current.removeEventListener('scroll', onWindowScoll);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phaseIds]);

  return {
    activePhaseId,
    heightLastComponent,
    heightContainer,
  };
}

export default CheckActivePhase;
