import React, { useEffect, useMemo, useState } from 'react';
import { FunctionsIcon } from 'assets/icons';
import ModalBodyOrderScore from 'components/Modal/ModalBodyOrderScore/ModalBodyOrderScore';
import { scoringMatrixData } from 'constants/order';
import Text from 'componentsShared/Text/Text';
import { useDispatch, useSelector } from 'react-redux';
import * as ordersActions from 'store/orders/actions';
import { v4 as uuidv4 } from 'uuid';
// eslint-disable-next-line import/no-extraneous-dependencies
import { isEqual } from 'lodash';
import { JOBS_STATUS } from 'constants/jobs';
import { isEqualDate } from 'helpers/date';
import cn from 'classnames';
import st from './ScorePOCard.module.scss';

function ScorePOCard({ order }) {
  const dispatch = useDispatch();

  const scoreData =
    useSelector((state) => state?.scoreItems?.scoreItems?.data) ?? false;
  const scoreIds =
    useSelector((state) => state?.scoreItems?.scoreItems?.sortIds) ?? false;

  const orderIsDone = order.status === JOBS_STATUS.done;
  const finalScore = order.final_score;

  const [show, setShow] = useState(false);
  const [initialDate, setInitialDate] = useState(null);
  const [valueDate, setValueDate] = useState(null);
  const [initialValue, setInitialValue] = useState({});
  const [scoreValue, setScoreValue] = useState({});
  const [isShowError, setIsShowError] = useState(false);

  const disabledModalAcceptBtn = useMemo(() => {
    return (
      isEqual(initialValue, scoreValue) && isEqualDate(initialDate, valueDate)
    );
  }, [scoreValue, initialValue, initialDate, valueDate]);

  const errorScore = useMemo(() => {
    let errors = {};

    scoreIds.forEach((item) => {
      const currentScoreData = scoreData[item];
      const scoreItemName = currentScoreData.name;
      const currentValue = scoreValue[scoreItemName];
      let currentError = '';

      if (typeof currentValue === 'number') {
        if (+currentScoreData.min_value > currentValue) {
          currentError = 'The value exceeds the minimum value';
        }

        if (currentValue > +currentScoreData.max_value) {
          currentError = 'The value exceeds the maximum value';
        }
      } else {
        currentError = 'Required';
      }

      errors = { ...errors, [scoreItemName]: currentError };
    });

    const dateErrors = Object.values(errors).every(
      (item) => item === 'Required'
    )
      ? {}
      : errors;

    return dateErrors;
  }, [scoreIds, scoreData, scoreValue]);

  const handleChangeScore = (name, value) => {
    setScoreValue((prev) => ({ ...prev, [name]: value }));
  };

  const renderScoreDataArray = () => {
    return Object.values(scoringMatrixData).map((item) => {
      const { icon, label } = item;

      return (
        <div className={st.containerItemScore} key={uuidv4()}>
          <Text varint='normal2' className={st.itemScoreText}>
            {initialValue[label] || 0}
          </Text>
          {icon}
        </div>
      );
    });
  };

  const handleResetValueForm = () => {
    setScoreValue(initialValue);
  };

  const handleClickScore = (e) => {
    if (!orderIsDone) {
      e.stopPropagation();
      setShow(true);
    }
  };

  const handleChangeOrderScore = () => {
    dispatch(
      ordersActions.updateOrderScore.start({
        id: order.id,
        data: {
          score: scoreValue,
        },
      })
    );
  };

  const handleChangeDate = () => {
    dispatch(
      ordersActions.updateOrderEndDate.start({
        id: order.id,
        data: {
          end_date: valueDate,
        },
      })
    );
  };

  const handleSave = (onHideModal) => {
    const isErrorScore = Object.values(errorScore).some((item) => !!item);

    if (isErrorScore) {
      setIsShowError(true);
      return;
    }

    const isSaveScore =
      Object.keys(scoreValue).length > 0 && !isEqual(initialValue, scoreValue);

    const isSaveDate = !!valueDate && !isEqualDate(initialDate, valueDate);

    if (isSaveScore) {
      handleChangeOrderScore();
    }

    if (isSaveDate) {
      handleChangeDate();
    }

    onHideModal();
  };

  useEffect(() => {
    if (scoreIds?.length > 0) {
      const data = scoreIds.reduce((acc, item) => {
        const scoreName = scoreData[item].name;
        const currentValue = order?.score?.[scoreName];
        const validValue = !currentValue ? '' : +currentValue;

        return { ...acc, [scoreName]: validValue };
      }, {});

      const endDate = order?.end_date || null;

      if (endDate) {
        setInitialDate(new Date(endDate));
        setValueDate(new Date(endDate));
      }

      setInitialValue(data);
      setScoreValue(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoreIds, order?.score]);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <div
        className={cn(st.container, { [st.cursorDefault]: orderIsDone })}
        onClick={handleClickScore}>
        <div className={st.scoreDataList}>{renderScoreDataArray()}</div>
        <div className={st.finalScore}>
          <FunctionsIcon /> {finalScore}
        </div>
      </div>

      <ModalBodyOrderScore
        show={show}
        setShow={setShow}
        handleResetValueForm={handleResetValueForm}
        scoreData={scoreData}
        scoreValue={scoreValue}
        handleChangeScore={handleChangeScore}
        finalScore={finalScore}
        valueDate={valueDate}
        setValueDate={setValueDate}
        errorScore={errorScore}
        isShowError={isShowError}
        handleSave={handleSave}
        disabledModalAcceptBtn={disabledModalAcceptBtn}
      />
    </div>
  );
}

export default ScorePOCard;
