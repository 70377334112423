import React from 'react';
import { Form, FormControl } from 'react-bootstrap';
import { func, object } from 'prop-types';
import RequiredLabel from 'componentsShared/RequiredLabel/RequiredLabel';
import { MAX_LENGTH_INPUT } from 'constants/ui';
import st from './EditControlValuesBundleForm.module.scss';

EditControlValuesBundleForm.propTypes = {
  register: func,
  errors: object,
};

function EditControlValuesBundleForm({ register, errors }) {
  return (
    <Form className={st.wrapperForm} onSubmit={(e) => e.preventDefault()}>
      <div className={st.formGroup}>
        <Form.Label>
          <RequiredLabel />
          Bundle name
        </Form.Label>
        <FormControl
          {...register('name')}
          isInvalid={errors.name}
          maxLength={MAX_LENGTH_INPUT}
        />
      </div>
    </Form>
  );
}

export default EditControlValuesBundleForm;
