import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CenteredColumnLayout from 'layouts/CenteredColumnLayout/CenteredColumnLayout';
import * as ordersActions from 'store/orders/actions';
import * as partTypesActions from 'store/partTypes/actions';
import NavigationService from 'services/navigation/NavigationService';
import OrderDetails from 'components/Order/OrderDetails/OrderDetails';
import Loader from 'componentsShared/Loader/Loader';
import OrderHeader from 'components/Order/OrderHeader/OrderHeader';
import PartService from 'services/part/PartService';
import AuthService from 'services/auth/AuthService';
import BeforeRedirect from 'componentsShared/BeforeRedirect/BeforeRedirect';
import st from './NewOrderPage.module.scss';

const NewOrderPage = () => {
  const dispatch = useDispatch();
  const [PONumber, setPONumber] = useState('');

  const currentCustomerId =
    useSelector((state) => state?.customers?.currentCustomerId) ?? null;
  const currentCustomerName =
    useSelector(
      (state) => state?.customers?.customers?.data?.[currentCustomerId]?.name
    ) ?? '';
  const isLoading =
    useSelector((state) => state?.orders?.orders?.isLoading) ?? false;
  const selectedPartTypesList =
    useSelector((state) => state?.partTypes?.selectedPartTypes) ?? [];
  const isConvertFromRFQ =
    useSelector((state) => state?.orders?.isConvertFromRFQ) ?? false;

  const isClient = AuthService.isClient();

  const title = useMemo(() => {
    if (!isClient && currentCustomerName) {
      return `${currentCustomerName}: New Purchase Order`;
    }

    return 'New Purchase Order';
  }, [isClient, currentCustomerName]);

  const getIsActiveTravelerInPartList = () => {
    const partIds = selectedPartTypesList.map((item) => {
      return item.id;
    });
    const uniquePartIds = [...new Set(partIds)];

    return PartService.isActiveTravelerInPartList(uniquePartIds);
  };

  const isActiveTravelerInPartList = getIsActiveTravelerInPartList();
  const isDisabledSave = !PONumber || selectedPartTypesList?.length === 0;
  const isCheckModalBackBtn = !!PONumber || selectedPartTypesList?.length > 0;

  const isDisabledSubmit =
    !isActiveTravelerInPartList ||
    !PONumber ||
    selectedPartTypesList?.length === 0;

  const handleSaveOrder = () => {
    const positions = selectedPartTypesList.map((item) => ({
      part_type_id: item.id,
      quantity: item.quantity,
    }));

    dispatch(
      ordersActions.createOrder.start({
        customer_id: currentCustomerId,
        positions,
        purchase_number: PONumber,
      })
    );
  };

  const handleSubmitOrder = () => {
    const positions = selectedPartTypesList.map((item) => ({
      part_type_id: item.id,
      quantity: item.quantity,
    }));

    dispatch(
      ordersActions.submitNewOrder.start({
        customer_id: currentCustomerId,
        positions,
        purchase_number: PONumber,
      })
    );
  };

  const onСlickBack = () => {
    NavigationService.back();
  };

  useEffect(() => {
    if (!isConvertFromRFQ) {
      dispatch(partTypesActions.addInitialSelectedPartTypes([]));
    } else {
      dispatch(ordersActions.changeIsConvertFromRFQ(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <CenteredColumnLayout>
      <OrderHeader
        onСlickBack={onСlickBack}
        handleNavigateBack={onСlickBack}
        handleSubmitOrder={handleSubmitOrder}
        isLoading={isLoading}
        handleSaveOrder={handleSaveOrder}
        title={title}
        isDisabledSubmit={isDisabledSubmit}
        isDisabledSave={isDisabledSave}
        isCheckModalBackBtn={isCheckModalBackBtn}
      />
      <div className={st.mainContentHolder}>
        <OrderDetails PONumber={PONumber} setPONumber={setPONumber} />
        <Loader isVisible={isLoading} isBackgroundColorGray />
      </div>

      <BeforeRedirect when={isCheckModalBackBtn && !isLoading} />
    </CenteredColumnLayout>
  );
};

export default NewOrderPage;
