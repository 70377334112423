import React from 'react';
import { Form, FormControl } from 'react-bootstrap';
import { MAX_LENGTH_INPUT } from 'constants/ui';
import { object, func } from 'prop-types';
import RequiredLabel from 'componentsShared/RequiredLabel/RequiredLabel';
import st from './EditCompanyForm.module.scss';

EditCompanyForm.propTypes = {
  onSubmit: func,
  register: func,
  errors: object,
};

function EditCompanyForm({ onSubmit = () => {}, register = () => {}, errors }) {
  return (
    <Form className={st.wrapperForm} onSubmit={onSubmit}>
      <div className={st.formGroup}>
        <Form.Label>
          <RequiredLabel />
          Company Name
        </Form.Label>
        <FormControl
          {...register('name')}
          isInvalid={errors.name}
          maxLength={MAX_LENGTH_INPUT}
        />
      </div>
    </Form>
  );
}

export default EditCompanyForm;
