import React from 'react';
import { Router, Switch } from 'react-router-dom';
import history from 'history/history';
import { ROUTES } from 'router/routes';
import { getValidPath } from 'helpers/path';
import TravelersDetailsPage from 'pages/TravelersDetailsPage/TravelersDetailsPage';
import SignInPage from 'pages/SignInPage/SignInPage';
import LoginEmployeePage from 'pages/LoginEmployeePage/LoginEmployeePage';
import SignOutPage from 'pages/SignOutPage/SignOutPage';
import NewTravelerPage from 'pages/NewTravelerPage/NewTravelerPage';
import CustomerDetailsPage from 'pages/CustomerDetailsPage/CustomerDetailsPage';
import AdminCustomersPage from 'pages/AdminCustomersPage/AdminCustomersPage';
import AdminEmployeesPage from 'pages/AdminEmployeesPage/AdminEmployeesPage';
import AdminTravelerTemplatesPage from 'pages/AdminTravelerTemplatesPage/AdminTravelerTemplatesPage';
import AdminControlValuesPage from 'pages/AdminControlValuesPage/AdminControlValuesPage';
import AdminNCRPage from 'pages/AdminNCRPage/AdminNCRPage';
import AdminReportsPage from 'pages/AdminReportsPage/AdminReportsPage';
import NewOrderPage from 'pages/NewOrderPage/NewOrderPage';
import OrdersDetailsPage from 'pages/OrdersDetailsPage/OrdersDetailsPage';
import JobDetailsPage from 'pages/JobDetailsPage/JobDetailsPage';
import JobListPage from 'pages/JobListPage/JobListPage';
import JobDetailsQAPage from 'pages/JobDetailsQAPage/JobDetailsQAPage';
import CustomerListManagerPage from 'pages/CustomerListManagerPage/CustomerListManagerPage';
import CustomerDetailsManagerPage from 'pages/CustomerDetailsManagerPage/CustomerDetailsManagerPage';
import NewPartTypesPage from 'pages/NewPartTypesPage/NewPartTypesPage';
import PartTypesDetailsPage from 'pages/PartTypesDetailsPage/PartTypesDetailsPage';
import PartTypesManagerPage from 'pages/PartTypesManagerPage/PartTypesManagerPage';
import POListCustomerPage from 'pages/POListCustomerPage/POListCustomerPage';
import PartTypesListCustomerPage from 'pages/PartTypesListCustomerPage/PartTypesListCustomerPage';
import RFQDetailsPage from 'pages/RFQDetailsPage/RFQDetailsPage';
import NewRFQPage from 'pages/NewRFQPage/NewRFQPage';
import CustomerListShippingPage from 'pages/CustomerListShippingPage/CustomerListShippingPage';
import CustomerDetailsShippingPage from 'pages/CustomerDetailsShippingPage/CustomerDetailsShippingPage';
import PartTypesShippingPage from 'pages/PartTypesShippingPage/PartTypesShippingPage';
import OrderDetailsHistoryPage from 'pages/OrderDetailsHistoryPage/OrderDetailsHistoryPage';
import ProductionManagerPage from 'pages/ProductionManagerPage/ProductionManagerPage';
import JobDetailsFinalQAPage from 'pages/JobDetailsFinalQAPage/JobDetailsFinalQAPage';
import JobDetailsNCRPage from 'pages/JobDetailsNCRPage/JobDetailsNCRPage';
import ProductionDetailsManagerPage from 'pages/ProductionDetailsManagerPage/ProductionDetailsManagerPage';
import RFQListCustomerPage from 'pages/RFQListCustomerPage/RFQListCustomerPage';
import SignUpConfirmPage from 'pages/SignUpConfirmPage/SignUpConfirmPage';
import ChangeTempPasswordPage from 'pages/ChangeTempPasswordPage/ChangeTempPasswordPage';
import ForgotPasswordPage from 'pages/ForgotPasswordPage/ForgotPasswordPage';
import CheckVersionPage from 'pages/CheckVersionPage/CheckVersionPage';
import CheckQrCodePage from 'pages/CheckQrCodePage/CheckQrCodePage';
import AdminTravelerTemplateDetailsPage from 'pages/AdminTravelerTemplateDetailsPage/AdminTravelerTemplateDetailsPage';
import BatchJobDetailsPage from 'pages/BatchJobDetailsPage/BatchJobDetailsPage';
import AdminTravelerTemplateCreatePage from 'pages/AdminTemplateCreatePage/AdminTemplateCreatePage';
import EmptyPage from 'pages/EmptyPage/EmptyPage';
import CustomRoute from './CustomRouter';
import ProtectedRoute from './ProtectedRoute';
import CustomRedirect from './CustomRedirect';

function ProtectedRouter() {
  return (
    <Router history={history}>
      <Switch>
        <CustomRoute
          exact
          path={getValidPath(ROUTES.createTraveler.path)}
          component={ProtectedRoute(NewTravelerPage)}
          roles={ROUTES.createTraveler.roles}
          isProtected
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.treavelersDetails.path)}
          component={ProtectedRoute(TravelersDetailsPage)}
          roles={ROUTES.treavelersDetails.roles}
          isProtected
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.adminCustomers.path)}
          component={ProtectedRoute(AdminCustomersPage)}
          roles={ROUTES.adminCustomers.roles}
          isProtected
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.adminEmployees.path)}
          component={ProtectedRoute(AdminEmployeesPage)}
          roles={ROUTES.adminEmployees.roles}
          isProtected
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.adminTravelerTemplates.path)}
          component={ProtectedRoute(AdminTravelerTemplatesPage)}
          roles={ROUTES.adminTravelerTemplates.roles}
          isProtected
        />

        <CustomRoute
          exact
          path={getValidPath(ROUTES.adminTravelerTemplateCreate.path)}
          component={ProtectedRoute(AdminTravelerTemplateCreatePage)}
          roles={ROUTES.adminTravelerTemplateCreate.roles}
          isProtected
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.adminTravelerTemplateDetails.path)}
          component={ProtectedRoute(AdminTravelerTemplateDetailsPage)}
          roles={ROUTES.adminTravelerTemplateDetails.roles}
          isProtected
        />

        <CustomRoute
          exact
          path={getValidPath(ROUTES.adminNCR.path)}
          component={ProtectedRoute(AdminNCRPage)}
          roles={ROUTES.adminNCR.roles}
          isProtected
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.adminReports.path)}
          component={ProtectedRoute(AdminReportsPage)}
          roles={ROUTES.adminReports.roles}
          isProtected
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.adminControlValues.path)}
          component={ProtectedRoute(AdminControlValuesPage)}
          roles={ROUTES.adminControlValues.roles}
          isProtected
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.customerDetails.path)}
          component={ProtectedRoute(CustomerDetailsPage)}
          roles={ROUTES.customerDetails.roles}
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.createOrder.path)}
          component={ProtectedRoute(NewOrderPage)}
          roles={ROUTES.createOrder.roles}
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.jobDetails.path)}
          component={ProtectedRoute(JobDetailsPage)}
          roles={ROUTES.jobDetails.roles}
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.jobDetailsBatch.path)}
          component={ProtectedRoute(BatchJobDetailsPage)}
          roles={ROUTES.jobDetailsBatch.roles}
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.jobDetailsNCR.path)}
          component={ProtectedRoute(JobDetailsNCRPage)}
          roles={ROUTES.jobDetailsNCR.roles}
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.ordersDetails.path)}
          component={ProtectedRoute(OrdersDetailsPage)}
          roles={ROUTES.ordersDetails.roles}
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.jobList.path)}
          component={ProtectedRoute(JobListPage)}
          roles={ROUTES.jobList.roles}
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.jobDetailsQA.path)}
          component={ProtectedRoute(JobDetailsQAPage)}
          roles={ROUTES.jobDetailsQA.roles}
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.customerListManager.path)}
          component={ProtectedRoute(CustomerListManagerPage)}
          roles={ROUTES.customerListManager.roles}
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.customerDetailsManager.path)}
          component={ProtectedRoute(CustomerDetailsManagerPage)}
          roles={ROUTES.customerDetailsManager.roles}
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.createPartTypes.path)}
          component={ProtectedRoute(NewPartTypesPage)}
          roles={ROUTES.createPartTypes.roles}
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.partTypesDetails.path)}
          component={ProtectedRoute(PartTypesDetailsPage)}
          roles={ROUTES.partTypesDetails.roles}
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.partTypesListManager.path)}
          component={ProtectedRoute(PartTypesManagerPage)}
          roles={ROUTES.partTypesListManager.roles}
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.orderListCustomer.path)}
          component={ProtectedRoute(POListCustomerPage)}
          roles={ROUTES.orderListCustomer.roles}
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.partTypesListCustomer.path)}
          component={ProtectedRoute(PartTypesListCustomerPage)}
          roles={ROUTES.partTypesListCustomer.roles}
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.rfqListCustomer.path)}
          component={ProtectedRoute(RFQListCustomerPage)}
          roles={ROUTES.rfqListCustomer.roles}
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.customerListShipping.path)}
          component={ProtectedRoute(CustomerListShippingPage)}
          roles={ROUTES.customerListShipping.roles}
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.customerDetailsShipping.path)}
          component={ProtectedRoute(CustomerDetailsShippingPage)}
          roles={ROUTES.customerDetailsShipping.roles}
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.partTypesListShipping.path)}
          component={ProtectedRoute(PartTypesShippingPage)}
          roles={ROUTES.partTypesListShipping.roles}
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.orderDetailsHistory.path)}
          component={ProtectedRoute(OrderDetailsHistoryPage)}
          roles={ROUTES.orderDetailsHistory.roles}
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.productionManager.path)}
          component={ProtectedRoute(ProductionManagerPage)}
          roles={ROUTES.productionManager.roles}
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.productionDetailsManager.path)}
          component={ProtectedRoute(ProductionDetailsManagerPage)}
          roles={ROUTES.productionDetailsManager.roles}
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.jobDetailsFinalQA.path)}
          component={ProtectedRoute(JobDetailsFinalQAPage)}
          roles={ROUTES.jobDetailsFinalQA.roles}
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.emptyPage.path)}
          component={ProtectedRoute(EmptyPage)}
          roles={ROUTES.emptyPage.roles}
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.checkVersion.path)}
          component={CheckVersionPage}
          roles={ROUTES.checkVersion.roles}
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.signIn.path)}
          component={SignInPage}
          roles={ROUTES.signIn.roles}
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.signUpConfirm.path)}
          component={SignUpConfirmPage}
          roles={ROUTES.signUpConfirm.roles}
          isRedirect={false}
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.changeTempPassword.path)}
          component={ProtectedRoute(ChangeTempPasswordPage)}
          roles={ROUTES.changeTempPassword.roles}
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.loginEmployee.path)}
          component={LoginEmployeePage}
          roles={ROUTES.loginEmployee.roles}
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.forgotPassword.path)}
          component={ForgotPasswordPage}
          roles={ROUTES.forgotPassword.roles}
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.signOut.path)}
          component={SignOutPage}
          roles={ROUTES.signOut.roles}
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.createRfq.path)}
          component={ProtectedRoute(NewRFQPage)}
          roles={ROUTES.createRfq.roles}
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.rfqDetails.path)}
          component={ProtectedRoute(RFQDetailsPage)}
          roles={ROUTES.rfqDetails.roles}
        />
        <CustomRoute
          exact
          path={getValidPath(ROUTES.checkQrCode.path)}
          component={CheckQrCodePage}
          roles={ROUTES.checkQrCode.roles}
        />
        <CustomRedirect />
      </Switch>
    </Router>
  );
}

export default ProtectedRouter;
