import React, { useState } from 'react';
import { ucFirst } from 'helpers/string';
import { func, object, number, array } from 'prop-types';
import ItemComponentCompanyAdmin from 'components/Customers/ItemComponentCompanyAdmin/ItemComponentCompanyAdmin';

HolderItemComponentCompanyAdmin.propTypes = {
  item: object,
  columns: array,
  actions: array,
  actionsCustomerTable: array,
  widthActions: number,
  setShowCustomerCreateOffcanvas: func,
  setSelectCompanyId: func,
};

function HolderItemComponentCompanyAdmin({
  item = {},
  columns = [],
  actions = [],
  actionsCustomerTable = [],
  widthActions,
  setShowCustomerCreateOffcanvas = () => {},
  setSelectCompanyId = () => {},
}) {
  const [isOpen, setIsOpen] = useState(false);

  const customerDataList = item.logins.map((itemCusromer) => ({
    email: itemCusromer.email,
    id: itemCusromer.id,
    status: ucFirst(itemCusromer.status.toLowerCase()),
    representativeName: itemCusromer.name,
    companyId: item.id,
  }));

  const onClickItem = () => {
    setSelectCompanyId(item.id);
    setShowCustomerCreateOffcanvas(true);
  };

  return (
    <>
      <ItemComponentCompanyAdmin
        item={item}
        columns={columns}
        actions={actions}
        widthActions={widthActions}
        showArrow
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isCompany
        handleClick={onClickItem}
      />

      {isOpen &&
        !!customerDataList.length &&
        customerDataList.map((customerItem) => {
          return (
            <ItemComponentCompanyAdmin
              item={customerItem}
              columns={columns}
              actions={actionsCustomerTable}
              widthActions={widthActions}
            />
          );
        })}
    </>
  );
}

export default HolderItemComponentCompanyAdmin;
