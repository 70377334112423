// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".AdminMenu_container__15sEw {\n  padding: 16px 0;\n  display: flex;\n  flex-direction: column;\n  height: 100%; }\n\n.AdminMenu_headerLogoContainer__1ZYx3 {\n  display: flex;\n  align-items: flex-end;\n  grid-column-gap: 8px;\n  column-gap: 8px;\n  padding-left: 24px;\n  padding-right: 24px;\n  margin-bottom: 24px; }\n  .AdminMenu_headerLogoContainer__1ZYx3 img {\n    width: 178px;\n    height: 28px; }\n\n.AdminMenu_navBarContainer__3wjzx {\n  flex-grow: 1; }\n\n.AdminMenu_uesrMenuContainer__14XuV {\n  margin-left: 12px; }\n", "",{"version":3,"sources":["webpack://src/components/AdminMenu/AdminMenu.module.scss"],"names":[],"mappings":"AAEA;EACE,eAAe;EACf,aAAa;EACb,sBAAsB;EACtB,YAAY,EAAA;;AAGd;EACE,aAAa;EACb,qBAAqB;EACrB,oBAAe;EAAf,eAAe;EACf,kBAAkB;EAClB,mBAAmB;EACnB,mBAAmB,EAAA;EANrB;IASI,YAAY;IACZ,YAAY,EAAA;;AAIhB;EACE,YAAY,EAAA;;AAGd;EACE,iBAAiB,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.container {\n  padding: 16px 0;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n}\n\n.headerLogoContainer {\n  display: flex;\n  align-items: flex-end;\n  column-gap: 8px;\n  padding-left: 24px;\n  padding-right: 24px;\n  margin-bottom: 24px;\n\n  img {\n    width: 178px;\n    height: 28px;\n  }\n}\n\n.navBarContainer {\n  flex-grow: 1;\n}\n\n.uesrMenuContainer {\n  margin-left: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "AdminMenu_container__15sEw",
	"headerLogoContainer": "AdminMenu_headerLogoContainer__1ZYx3",
	"navBarContainer": "AdminMenu_navBarContainer__3wjzx",
	"uesrMenuContainer": "AdminMenu_uesrMenuContainer__14XuV"
};
export default ___CSS_LOADER_EXPORT___;
