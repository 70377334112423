import React, { useEffect, useState, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { JOBS_TYPES, JOB_ERROR_QR_CODE } from 'constants/jobs';
import NavigationService from 'services/navigation/NavigationService';
import { getRoutePath } from 'helpers/path';
import { ROUTES } from 'router/routes';
import * as jobsActions from 'store/jobs/actions';
import { string, number, bool, oneOfType } from 'prop-types';
import JobsCard from 'components/Jobs/JobsCard/JobsCard';

JobsCardWrapper.propTypes = {
  jobId: oneOfType([number, string]),
  isNeedsQA: bool,
  type: string,
  isAssigned: bool,
};

const getValidDataJob = (infoJob) => {
  const currentPhaseName =
    infoJob?.phases?.find((item) => item.name === infoJob?.current_step?.phase)
      ?.human_name || '';

  return {
    customerName: infoJob?.customer_name || '',
    partTypeName: infoJob?.part_type?.name || '',
    partTypeNumber: infoJob?.part_type?.number || '',
    partTypeRevision: infoJob?.part_type?.revision || '',
    partTypeRevisionNotes: infoJob?.part_type?.revision_notes || '',
    partId: infoJob?.part_type?.id,
    poNumber: infoJob?.po_number || '',
    currentPhase: infoJob?.current_step?.phase || '',
    currentPhaseName,
    currentStep: infoJob?.current_step?.name || '',
    currentOperation: infoJob?.current_operation?.name || '',
    quantity: infoJob.quantity,
  };
};

function JobsCardWrapper({ jobId, isNeedsQA, type, isAssigned }) {
  const dispatch = useDispatch();

  const cuurentJob =
    useSelector((state) => state?.jobs?.jobs?.data?.[jobId]) ?? {};
  const checkedJobId =
    useSelector((state) => state?.jobs?.checkJob?.jobId) ?? null;

  const jobList = cuurentJob?.list || [];
  const jobIdList = jobList.map(({ id }) => id);
  const infoJob = jobList?.[0] || {};
  const jobLength = jobIdList?.length || 0;
  const jobData = getValidDataJob(infoJob);

  const jobSummaryInfo = {
    partDateils: {
      customerName: jobData.customerName,
      partTypeName: jobData.partTypeName,
      partTypeNumber: jobData.partTypeNumber,
      partTypeRevision: jobData.partTypeRevision,
      partTypeRevisionNotes: jobData.partTypeRevisionNotes,
    },
    partId: jobData.partId,
  };

  const [showModalSummary, setShowModalSummary] = useState(false);
  const [showModalQR, setShowModalQR] = useState(false);
  const [isErrorReaderQR, setIsErrorReaderQR] = useState(false);
  const [isError, setIsError] = useState(false);
  const [typeError, setTypeError] = useState(null);
  const [selectAvailableJob, setSelectAvailableJob] = useState([]);

  const modalTitleAcceptBtn =
    type === JOBS_TYPES.assigned ? 'Continue Job' : 'Take Job';

  const getJobStatus = () => {
    let value = '';

    switch (type) {
      case JOBS_TYPES.available:
      case JOBS_TYPES.needsQA:
        value = `${jobData.currentPhaseName} / ${jobData.currentStep}`;
        break;
      case JOBS_TYPES.assigned:
        value = `${jobData.currentPhaseName} / ${jobData.currentOperation}`;
        break;
      default:
        value = '';
    }
    return value;
  };

  const jobStatus = getJobStatus();

  const handleOpenModalSummary = (value) => {
    if (!isNeedsQA) {
      const arrayJobIds = isAssigned ? [...jobIdList] : [value];

      setShowModalSummary(true);
      setSelectAvailableJob(arrayJobIds);
    }
  };

  const onCancelModal = () => {
    setIsErrorReaderQR(false);
    setIsError(false);
    setTypeError(null);
    setShowModalSummary(true);
  };

  const handleChangeTypeQR = () => {
    setIsError(false);
    setTypeError(null);
  };

  const handleAcceptBtn = () => {
    setShowModalSummary(false);
    dispatch(jobsActions.changeScrollJobId(selectAvailableJob?.[0] || ''));

    if (type === JOBS_TYPES.assigned) {
      NavigationService.navigateToPath(
        getRoutePath(ROUTES.jobDetails.path, null, {
          ids: selectAvailableJob,
        })
      );
      return;
    }
    dispatch(
      jobsActions.takeJob.start({
        ids: selectAvailableJob,
      })
    );
  };

  const handleReadQR = (url) => {
    const [, currentJobId] = url.split('/api/jobs/qr/');
    const numberJobIdQR = +currentJobId;
    if (jobIdList.includes(numberJobIdQR)) {
      setSelectAvailableJob((prev) => {
        const arrayJobId = prev.includes(numberJobIdQR) ? [] : [numberJobIdQR];
        return [...prev, ...arrayJobId];
      });

      setShowModalQR(false);
      setShowModalSummary(true);
      return;
    }

    setIsError(true);
    setTypeError(JOB_ERROR_QR_CODE.notMatchSeries.type);
  };

  const handleReaderError = () => {
    setIsErrorReaderQR(true);
  };

  const changeSelectJobIdList = (id) => {
    dispatch(jobsActions.changeSelectJobIdList(id));
  };

  useEffect(() => {
    if (jobIdList.includes(checkedJobId) && !isNeedsQA) {
      const currentJobIds = isAssigned ? jobIdList : [checkedJobId];
      setSelectAvailableJob(currentJobIds);
      setShowModalSummary(true);
      dispatch(jobsActions.clearJobIdCheckJob());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedJobId, jobIdList]);

  return (
    <JobsCard
      jobData={jobData}
      showModalSummary={showModalSummary}
      setShowModalSummary={setShowModalSummary}
      showModalQR={showModalQR}
      setShowModalQR={setShowModalQR}
      modalTitleAcceptBtn={modalTitleAcceptBtn}
      handleOpenModalSummary={handleOpenModalSummary}
      handleAcceptBtn={handleAcceptBtn}
      disabledCard={isNeedsQA}
      jobStatus={jobStatus}
      quantityCard={`${jobLength} of ${jobData.quantity} pcs`}
      isProgressJob={isAssigned}
      handleReadQR={handleReadQR}
      isErrorReaderQR={isErrorReaderQR}
      handleReaderError={handleReaderError}
      isError={isError}
      typeError={typeError}
      onCancelModal={onCancelModal}
      selectAvailableJob={selectAvailableJob}
      setSelectAvailableJob={setSelectAvailableJob}
      jobList={jobList}
      handleChangeTypeQR={handleChangeTypeQR}
      isInterprocessQA={false}
      changeSelectJobIdList={changeSelectJobIdList}
      jobId={jobId}
      jobSummaryInfo={jobSummaryInfo}
    />
  );
}

export default memo(JobsCardWrapper);
