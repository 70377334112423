import React from 'react';
import PartsDetailsForm from 'components/Form/PartsDetailsForm/PartsDetailsForm';
import { array, object, func, bool } from 'prop-types';
import Text from 'componentsShared/Text/Text';
import st from './PartsDetails.module.scss';

PartsDetails.propTypes = {
  formValue: object,
  setFormValue: func,
  formDirty: object,
  setFormDirty: func,
  isNewPart: bool,
  errors: object,
  customerOptionList: array,
  travelerData: object,
  isRemotePart: bool,
};

function PartsDetails({
  formValue = {},
  setFormValue = () => {},
  formDirty = {},
  setFormDirty = () => {},
  isNewPart = false,
  errors = {},
  customerOptionList = [],
  isCustomer = false,
  isDirtyForm = false,
  travelerData = {},
  isRemotePart = false,
  cacheClientFile = [],
  setCacheClientFile = () => {},
}) {
  return (
    <div className={st.container}>
      {isRemotePart && (
        <div className={st.holderAlert}>
          <Text variant='normal'>
            This part is archived. No actions available on it.
          </Text>
        </div>
      )}
      <PartsDetailsForm
        formValue={formValue}
        setFormValue={setFormValue}
        formDirty={formDirty}
        setFormDirty={setFormDirty}
        errors={errors}
        customerOptionList={customerOptionList}
        isCustomer={isCustomer}
        isRemotePart={isRemotePart}
        isNewPart={isNewPart}
        isDirtyForm={isDirtyForm}
        travelerData={travelerData}
        cacheClientFile={cacheClientFile}
        setCacheClientFile={setCacheClientFile}
      />
    </div>
  );
}
export default PartsDetails;
