// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JobsSummaryWrapper_containerTabComponent__2c5_B {\n  height: 100%; }\n\n.JobsSummaryWrapper_containerTabComponentIsPDF__3o9L7 {\n  height: calc(100% - 42px); }\n\n.JobsSummaryWrapper_containerTabList__1Kdoc {\n  padding: 0 16px; }\n\n.JobsSummaryWrapper_container__3ZPoj {\n  height: 100%;\n  padding-left: 16px;\n  padding-right: 16px; }\n\n.JobsSummaryWrapper_containerContent__r-a76 {\n  overflow-y: auto;\n  height: calc(100% - 16px); }\n\n.JobsSummaryWrapper_wrapper__1gimG {\n  height: 100%; }\n\n.JobsSummaryWrapper_holderPages__38Bce {\n  padding-top: 8px;\n  padding-bottom: 8px; }\n", "",{"version":3,"sources":["webpack://src/components/Jobs/JobsSummaryWrapper/JobsSummaryWrapper.module.scss"],"names":[],"mappings":"AAEA;EACE,YAAY,EAAA;;AAGd;EACE,yBAAyB,EAAA;;AAG3B;EACE,eAAe,EAAA;;AAGjB;EACE,YAAY;EACZ,kBAAkB;EAClB,mBAAmB,EAAA;;AAGrB;EACE,gBAAgB;EAChB,yBAAyB,EAAA;;AAG3B;EACE,YAAY,EAAA;;AAGd;EACE,gBAAgB;EAChB,mBAAmB,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.containerTabComponent {\n  height: 100%;\n}\n\n.containerTabComponentIsPDF {\n  height: calc(100% - 42px);\n}\n\n.containerTabList {\n  padding: 0 16px;\n}\n\n.container {\n  height: 100%;\n  padding-left: 16px;\n  padding-right: 16px;\n}\n\n.containerContent {\n  overflow-y: auto;\n  height: calc(100% - 16px);\n}\n\n.wrapper {\n  height: 100%;\n}\n\n.holderPages {\n  padding-top: 8px;\n  padding-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerTabComponent": "JobsSummaryWrapper_containerTabComponent__2c5_B",
	"containerTabComponentIsPDF": "JobsSummaryWrapper_containerTabComponentIsPDF__3o9L7",
	"containerTabList": "JobsSummaryWrapper_containerTabList__1Kdoc",
	"container": "JobsSummaryWrapper_container__3ZPoj",
	"containerContent": "JobsSummaryWrapper_containerContent__r-a76",
	"wrapper": "JobsSummaryWrapper_wrapper__1gimG",
	"holderPages": "JobsSummaryWrapper_holderPages__38Bce"
};
export default ___CSS_LOADER_EXPORT___;
