import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { createMigrate, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { VARIANT_UI } from 'constants/ui';
import NotificationsService from 'services/notifications/NotificationsService';

import { migrations } from './migrations';
import { rootReducer } from './rootReducer';
import rootSaga from './rootSaga';

const rootPersistConfig = {
  key: 'root',
  storage,
  whitelist: [
    'auth',
    'customers',
    'orders',
    'partTypes',
    'rfqs',
    'jobs',
    'templates',
    'metadata',
  ],
  version: 5,
  migrate: createMigrate(migrations, { debug: false }),
};

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

const sagaMiddleware = createSagaMiddleware({
  onError: (error) => {
    NotificationsService.throwNotification({
      title: 'Error!',
      text: error,
      variant: VARIANT_UI.danger,
    });
  },
});

export const store = configureStore({
  reducer: persistedReducer,
  middleware: [sagaMiddleware],
  devTools: process.env.NODE_ENV !== 'production',
});

export const persistor = persistStore(store);

sagaMiddleware.run(rootSaga);
