/* eslint-disable react-hooks/exhaustive-deps */
import React, { useMemo, useState } from 'react';
import { AddCircleIcon } from 'assets/icons';
import { MAX_LENGTH_INPUT, VARIANT_UI } from 'constants/ui';
import ModalButton from 'componentsShared/ModalButton/ModalButton';
import { useSelector } from 'react-redux';
import { Form, InputGroup } from 'react-bootstrap';
import Text from 'componentsShared/Text/Text';
import st from './TemplateAddPhaseButton.module.scss';

function TemplateAddPhaseButton({
  isShowAddButton = false,
  handleAddPhase = () => {},
  index = 0,
  typeEntity = 'templates',
}) {
  const phaseList =
    useSelector((state) => state?.travelers?.phaseList?.data) ?? {};
  const newPhaseList =
    useSelector((state) => state?.[typeEntity]?.newPhaseList) ?? [];
  const phaseListArray = Object.values(phaseList);
  const phaseListCanAdded = [...phaseListArray].slice(
    1,
    phaseListArray.length - 1
  );

  const [radioValue, setRadioValue] = useState(phaseListCanAdded[0]);
  const [postfixPhase, setPostfixPhase] = useState('');
  const [dirtyPostfix, setDirtyPostfix] = useState(false);

  const isInvalidPostfixPhase = useMemo(() => {
    if (
      !newPhaseList ||
      newPhaseList.length === 0 ||
      postfixPhase.length === 0
    ) {
      return false;
    }

    const isInvalid = newPhaseList.some(
      (item) => item.name === `${radioValue.name} ${postfixPhase}`
    );

    return isInvalid && 'This phase name is busy. Use another please';
  }, [newPhaseList, postfixPhase, radioValue?.name]);

  const isDisabledAdd = newPhaseList.length >= 15;

  const handleChangeRadioValue = (type) => {
    const currentPhase = phaseListCanAdded.find((item) => type === item.type);

    if (currentPhase.human_name === 'Final QA') {
      return setRadioValue({ ...currentPhase, human_name: 'QA' });
    }
    setRadioValue(currentPhase);
  };

  const handleChangePostfix = (e) => {
    const { value } = e.target;
    setDirtyPostfix(true);
    setPostfixPhase(value);
  };

  const handleHideModal = () => {
    setRadioValue(phaseListCanAdded[0]);
    setPostfixPhase('');
    setDirtyPostfix(false);
  };

  const onAddPhase = () => {
    handleAddPhase({ phase: radioValue, postfix: postfixPhase, index });
    handleHideModal();
  };

  const renderCheckRadioBox = () => {
    return phaseListCanAdded.map(({ type, human_name }) => {
      const humanName = human_name === 'Final QA' ? 'QA' : human_name;
      return (
        <div key={type} className={st.holderRadioBox}>
          <Form.Check
            type='radio'
            name='reason'
            checked={radioValue?.type === type}
            onChange={() => handleChangeRadioValue(type)}
          />
          <Text
            variant='normal'
            className={st.labelRadiobox}
            onClick={() => handleChangeRadioValue(type)}>
            {humanName}
          </Text>
        </div>
      );
    });
  };

  if (!isShowAddButton || isDisabledAdd) {
    return null;
  }

  return (
    <ModalButton
      title='New Phase'
      titleAcceptBtn='Add'
      onHide={handleHideModal}
      onAcceptBtn={onAddPhase}
      variantAcceptBtn={VARIANT_UI.primary}
      disabledAcceptBtn={!radioValue || !postfixPhase || isInvalidPostfixPhase}
      bodyContent={() => (
        <div className={st.wrapperContent}>
          <Text variant='normal'>Select a phase to add:</Text>
          <div className={st.holderPhaseList}>{renderCheckRadioBox()}</div>
          <div className={st.wrapperPhasePrefix}>
            <Text variant='normal'>Give a name to the phase:</Text>
            <div className={st.holderPhasePrefix}>
              <Text variant='normal' className={st.selectPhaseName}>
                {radioValue?.human_name || ''}
              </Text>
              <InputGroup hasValidation className={st.holderForm}>
                <Form.Control
                  isInvalid={
                    (dirtyPostfix && !postfixPhase) || isInvalidPostfixPhase
                  }
                  name='postfix'
                  onChange={handleChangePostfix}
                  value={postfixPhase}
                  maxLength={MAX_LENGTH_INPUT}
                />

                <Form.Control.Feedback
                  type='invalid'
                  className={st.errorMessage}>
                  {isInvalidPostfixPhase}
                </Form.Control.Feedback>
              </InputGroup>
            </div>
          </div>
        </div>
      )}
      buttonRenderer={({ onClick: openModal }) => (
        <div className={st.holderAddPhase} onClick={openModal}>
          <AddCircleIcon />
        </div>
      )}
    />
  );
}

export default TemplateAddPhaseButton;
