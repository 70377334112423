import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';
import {
  NO_MATCHES_FOUND,
  EMPTY_PRODUCTION_PAGE_TEXT,
  TIME_SEARCH_DEBOUNCE,
  VARIANT_OUTLINE_UI,
} from 'constants/ui';
import * as ordersActions from 'store/orders/actions';
import * as customersActions from 'store/customers/actions';
import * as scoreItemsActions from 'store/scoreItems/actions';
import HeaderContent from 'componentsShared/HeaderContent/HeaderContent';
import OrderList from 'components/Order/OrderList/OrderList';
import InputSearch from 'componentsShared/InputSearch/InputSearch';
import { debounce } from 'helpers/functions';
import CenteredColumnLayout from 'layouts/CenteredColumnLayout/CenteredColumnLayout';
import NavigationService from 'services/navigation/NavigationService';
import { getRoutePath, getQueryVariable } from 'helpers/path';
import { ROUTES } from 'router/routes';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from 'react-router';
import Loader from 'componentsShared/Loader/Loader';
import FooterPagination from 'componentsShared/FooterPagination/FooterPagination';
import st from './ProductionManagerPage.module.scss';

const debouncedSearch = debounce((onSearch, data) => {
  onSearch(data);
}, TIME_SEARCH_DEBOUNCE);

function ProductionManagerPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryVariable = getQueryVariable(location.search);
  const currentPage = queryVariable.page || 0;

  const isLoadingOrders =
    useSelector((state) => state?.orders?.orders?.isLoading) ?? false;
  const isLoadingScore =
    useSelector((state) => state?.scoreItems?.scoreItems?.isLoading) ?? false;
  const orderList = useSelector((state) => state?.orders?.orders?.data) ?? {};
  const orderIds = useSelector((state) => state?.orders?.orders.sortIds) ?? [];
  const searchValue =
    useSelector((state) => state?.orders?.fetchParams?.search) ?? '';
  const isLoadingSearch =
    useSelector((state) => state?.orders?.fetchParams?.isLoading) ?? false;
  const paginationObj =
    useSelector((state) => state?.orders?.fetchParams?.pagination) ?? {};
  const isFindByJob =
    useSelector((state) => state?.orders?.fetchParams?.isFindByJob) ?? false;
  const showDataOrderAndJob =
    useSelector((state) => state?.orders?.dataOrderAndJob) ?? '';
  const isScrollJob =
    useSelector((state) => state?.orders?.isScrollJob) ?? false;

  const [isScrollJobState, setIsScrollJobState] = useState(false);

  const findByJob = isFindByJob ? +searchValue.replace('#', '') : null;

  const loadPOList = (isClearPage) => {
    dispatch(
      ordersActions.getOrdersList.start({
        params: { filter: searchValue, submitted_only: true },
        isClearPage,
        showLoader: true,
        isPagination: true,
      })
    );
  };

  const onChangeFilterSearch = (value) => {
    dispatch(ordersActions.changeFilterSearch(value));
  };

  const handleChangeSearch = (value) => {
    onChangeFilterSearch(value);
    if (value) {
      return debouncedSearch(() => {
        loadPOList(true);
      });
    }
    loadPOList(true);
  };

  const handleClickJobCard = (jobId, orderId) => {
    dispatch(
      ordersActions.changeDataOrderAndJob(
        `${jobId}-${orderId}-${paginationObj.page}`
      )
    );
    NavigationService.navigateToPath(
      getRoutePath(ROUTES.productionDetailsManager.path, {
        id: jobId,
      })
    );
  };

  const handleChangePage = (page) => {
    dispatch(ordersActions.changePage(page));
    loadPOList();
  };

  useEffect(() => {
    if (!+currentPage && !isScrollJob) {
      onChangeFilterSearch('');
    }
    handleChangePage(currentPage);
    dispatch(customersActions.setCurrentCustomer(null));
    dispatch(scoreItemsActions.getScoreList.start());

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const isShowJob =
      showDataOrderAndJob &&
      isScrollJob &&
      orderList &&
      !isLoadingOrders &&
      !isLoadingScore &&
      typeof paginationObj.page === 'number';

    if (isShowJob) {
      const [jobId, , page] = showDataOrderAndJob.split('-');

      if (page.toString() === paginationObj.page.toString()) {
        setTimeout(() => {
          const jobCardElement = document.getElementById(`job-card-${jobId}`);
          if (jobCardElement) {
            jobCardElement.scrollIntoView({
              block: 'center',
              behavior: 'smooth',
            });
          }
        }, 750);
      } else {
        handleChangePage(page);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    showDataOrderAndJob,
    isScrollJob,
    orderList,
    isLoadingOrders,
    paginationObj.page,
    isLoadingScore,
  ]);

  useEffect(() => {
    if (isScrollJob) {
      setIsScrollJobState(true);
      dispatch(ordersActions.changeIsScrollJob());
    }
  }, [isScrollJob, dispatch]);

  return (
    <CenteredColumnLayout
      classNamePageContentHolder={st.classNamePageContentHolder}
      classNamePageWrapper={st.layoutPageWrapper}
      renderFooter={() => (
        <FooterPagination
          page={paginationObj.page}
          countItem={paginationObj.count}
          size={paginationObj.size}
          onChange={handleChangePage}
          className={st.footerWrapper}
          classNameLine={st.footerLine}
          isShowPlaceholder={orderIds.length === 0}
          placeholder={<div className={st.placeholderFooter} />}
        />
      )}>
      <div className={st.headerContainer}>
        <HeaderContent
          isActive
          title='Production'
          componentActive={() => (
            <InputSearch
              value={searchValue}
              onChange={handleChangeSearch}
              className={st.holderSearch}
              isLoadingSearch={isLoadingSearch}
              placeholder='Search by PO#, Part'
            />
          )}
          className={st.holderHeader}
        />
        <div className={st.containerActionsHeader}>
          <Button
            variant={VARIANT_OUTLINE_UI.secondary}
            size='sm'
            onClick={() => dispatch(ordersActions.orderExpandAll())}>
            Expand All
          </Button>
          <Button
            variant={VARIANT_OUTLINE_UI.secondary}
            size='sm'
            onClick={() => dispatch(ordersActions.orderCollapseAll())}>
            Collapse All
          </Button>
        </div>
      </div>
      <div className={st.containerList}>
        <div className={st.contentList}>
          <OrderList
            classNameList={st.orderList}
            dataList={orderList}
            ids={orderIds}
            handleClickJobCard={handleClickJobCard}
            isProductionPOCard
            emptyText={EMPTY_PRODUCTION_PAGE_TEXT}
            searchEmptyText={NO_MATCHES_FOUND}
            isSearch={!!searchValue}
            findByJob={findByJob}
            showDataOrderAndJob={isScrollJobState ? showDataOrderAndJob : ''}
          />
        </div>

        <Loader
          isVisible={isLoadingOrders || isLoadingScore}
          isBackgroundColorGray
          className={st.loaderWrapper}
        />
      </div>
    </CenteredColumnLayout>
  );
}

export default ProductionManagerPage;
