import React, { useEffect, useState } from 'react';
import AdminHeaderLayout from 'layouts/AdminHeaderLayout/AdminHeaderLayout';
import { useSelector, useDispatch } from 'react-redux';
import * as controlValuesActions from 'store/controlValues/actions';
import { EditIcon } from 'assets/icons';
import {
  FORMAT_DATE_FULL_MONTH,
  NO_INFORMATION_FOUND_SEARCH_TEXT,
} from 'constants/ui';
import OffcanvasControlValuesCreate from 'components/Offcanvas/OffcanvasControlValuesCreate/OffcanvasControlValuesCreate';
import OffcanvasEditControlValues from 'components/Offcanvas/OffcanvasEditControlValues/OffcanvasEditControlValues';
import FooterPagination from 'componentsShared/FooterPagination/FooterPagination';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from 'react-router';
import { getQueryVariable } from 'helpers/path';
import moment from 'moment';
import ListComponent from 'componentsShared/ListComponent/ListComponent';
import { ACTION_COLUMN } from 'constants/table';
import st from './AdminControlValuesPage.module.scss';

const columnTable = [
  {
    dataKey: 'name',
    width: 366,
    title: 'Control Values Bundle',
    flexGrow: true,
  },
  { dataKey: 'author', width: 220, title: 'Author', truncated: true },
  { dataKey: 'date', width: 220, title: 'Creation Date', truncated: true },
  { dataKey: ACTION_COLUMN, width: 100, title: '' },
];

function AdminControlValuesPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryVariable = getQueryVariable(location.search);
  const currentPage = queryVariable.page || 0;

  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [selectControlValueId, setSelectControlValueId] = useState();

  const controlValueList =
    useSelector((state) => state?.controlValues?.controlValues?.data) ?? {};
  const isLoadingControlValue =
    useSelector((state) => state?.controlValues?.controlValues?.isLoading) ??
    false;
  const isLoadingCreateControlValue =
    useSelector(
      (state) => state?.controlValues?.controlValues?.isLoadingCreate
    ) ?? false;
  const isLoadingChangeControlValue =
    useSelector(
      (state) => state?.controlValues?.controlValues?.isLoadingChange
    ) ?? false;
  const controlValuesActiveIds =
    useSelector(
      (state) => state?.controlValues?.controlValues?.sortActiveIds
    ) ?? [];
  const paginationObj =
    useSelector((state) => state?.controlValues?.fetchParams?.pagination) ?? {};

  const dataTable = controlValuesActiveIds.map((id) => {
    const currentControlValues = controlValueList[id];

    return {
      id: currentControlValues.id,
      name: currentControlValues.name,
      author: currentControlValues?.author?.name || '-',
      date: currentControlValues.created
        ? moment(currentControlValues.created).format(FORMAT_DATE_FULL_MONTH)
        : '-',
    };
  });

  const actionsTable = [
    {
      icon: <EditIcon className={st.editIcon} />,
      onClick: (rowData) => {
        setSelectControlValueId(rowData.id);
        setShowOffcanvas(true);
      },
    },
  ];

  const loadControlValueList = () => {
    dispatch(
      controlValuesActions.getControlValueList.start({ isPagination: true })
    );
  };

  const handleChangePage = (page) => {
    dispatch(controlValuesActions.changePage(page));
    loadControlValueList();
  };

  useEffect(() => {
    handleChangePage(currentPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AdminHeaderLayout
      title='Control Values'
      classNameContentHolder={st.layoutContentHolder}
      inviteButton={() => <OffcanvasControlValuesCreate />}
      renderFooter={() => (
        <FooterPagination
          page={paginationObj.page}
          countItem={paginationObj.count}
          size={paginationObj.size}
          onChange={handleChangePage}
          isFullLine
          isShowPlaceholder={dataTable.length === 0}
        />
      )}
      isSearch={false}>
      <ListComponent
        data={dataTable}
        columns={columnTable}
        actions={actionsTable}
        classNameRowListWrapper={st.rowListWrapper}
        classNameRowListHolder={st.rowListHolder}
        isLoading={
          isLoadingControlValue ||
          isLoadingCreateControlValue ||
          isLoadingChangeControlValue
        }
        placeholderText={NO_INFORMATION_FOUND_SEARCH_TEXT}
      />
      {selectControlValueId && (
        <OffcanvasEditControlValues
          selectControlValue={controlValueList?.[selectControlValueId]}
          selectControlValueId={selectControlValueId}
          showOffcanvas={showOffcanvas}
          setShowOffcanvas={setShowOffcanvas}
        />
      )}
    </AdminHeaderLayout>
  );
}

export default AdminControlValuesPage;
