import { all, put, takeLatest, call } from 'redux-saga/effects';
import { handleRequestError } from 'services/api/apiTools';
import { getParsingArrayToObj } from 'helpers/functions';
import ApiService from 'services/api';
import * as actions from './actions';

export function* getScoreListSaga() {
  let requestValue;
  try {
    requestValue = yield call(ApiService.getScoreList);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getScoreList.failure());
    return;
  }

  const scoreItemObj = getParsingArrayToObj(requestValue);
  const scoreItemIds = Object.keys(scoreItemObj);

  yield put(
    actions.getScoreList.success({
      data: scoreItemObj,
      ids: scoreItemIds,
    })
  );
}

export function* scoreItemsSagas() {
  yield all([yield takeLatest(actions.getScoreList.start, getScoreListSaga)]);
}
