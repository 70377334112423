import React from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import * as templatesActions from 'store/templates/actions';
import { array, number, string, oneOfType } from 'prop-types';
import { CVIcon } from 'assets/icons';
import Text from 'componentsShared/Text/Text';
import st from './TravelerTemplateOperation.module.scss';

TravelerTemplateOperation.propTypes = {
  operationList: array.isRequired,
  stepIndex: number.isRequired,
  phaseType: string.isRequired,
  templateId: oneOfType([number, string]),
};

function TravelerTemplateOperation({
  operationList,
  stepIndex,
  phaseType,
  templateId,
}) {
  const dispatch = useDispatch();

  const handleCheckedOperation = (value, operationIndex) => {
    dispatch(
      templatesActions.updateTemplateOperationControl({
        phaseType,
        stepIndex,
        operationIndex,
        value,
        templateId,
      })
    );
  };

  const handleCheck = (e, index) => {
    const value = e.target.checked;

    return handleCheckedOperation(value, index);
  };

  const renderOperationList = () => {
    return operationList.map((operation, index) => {
      const { name, isControlValues } = operation;

      return (
        <div
          className={st.containerOperation}
          key={`wizard-operation-${operation.id}`}>
          <Form.Group className={st.formGroup}>
            <Form.Check
              inline
              type='checkbox'
              id={`checkbox-${name}`}
              checked={operation.control}
              onChange={(e) => handleCheck(e, index)}
            />
            <Form.Label htmlFor={`checkbox-${name}`} className={st.holderLabel}>
              {isControlValues && (
                <div className={st.holderIconCV}>
                  <CVIcon />
                </div>
              )}
              <Text variant='normal'>{name}</Text>
            </Form.Label>
          </Form.Group>
        </div>
      );
    });
  };

  return (
    <div className={st.container}>
      {operationList.length > 0 && renderOperationList()}
    </div>
  );
}

export default TravelerTemplateOperation;
