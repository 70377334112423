import React, { useMemo } from 'react';
import CenteredColumnLayout from 'layouts/CenteredColumnLayout/CenteredColumnLayout';
import SignUpConfirmForm from 'components/Form/SignUpConfirmForm/SignUpConfirmForm';
import * as Yup from 'yup';
import {
  reContainEightCharacters,
  reContainOneLowercase,
  reContainOneNumber,
  reContainOneSpecialCaseCharacter,
  reContainOneUppercase,
  reContainSixCharacters,
} from 'constants/regulars';
import { useDispatch, useSelector } from 'react-redux';
import * as authActions from 'store/auth/actions';
import { ROUTES } from 'router/routes';
import AuthService from 'services/auth/AuthService';
import st from './ChangeTempPasswordPage.module.scss';

function ChangeTempPasswordPage() {
  const dispatch = useDispatch();

  const oldPassword = useSelector((state) => state?.auth?.tempPassword) ?? '';

  const isAdminRole = AuthService.isAdminRole();
  const isManagerRole = AuthService.isManagerRole();

  const validationSchema = useMemo(() => {
    if (isAdminRole || isManagerRole) {
      return Yup.object().shape({
        password: Yup.string()
          .required('Required')
          .matches(reContainEightCharacters, 'Must Contain 8 Characters')
          .matches(reContainOneLowercase, 'Must Contain One Lowercase')
          .matches(reContainOneUppercase, 'Must Contain One Uppercase')
          .matches(reContainOneNumber, 'Must Contain One Number')
          .matches(
            reContainOneSpecialCaseCharacter,
            'Must Contain One Special Case Character'
          ),
        passwordConfirmation: Yup.string().oneOf(
          [Yup.ref('password'), null],
          'Passwords must match'
        ),
      });
    }

    return Yup.object().shape({
      password: Yup.string()
        .required('Required')
        .matches(reContainSixCharacters, 'Must Contain 8 Characters'),
      passwordConfirmation: Yup.string().oneOf(
        [Yup.ref('password'), null],
        'Passwords must match'
      ),
    });
  }, [isAdminRole, isManagerRole]);

  const onSubmit = (data) => {
    dispatch(
      authActions.changeTempPassword.start({
        old_password: oldPassword,
        new_password: data.password,
      })
    );
  };

  return (
    <CenteredColumnLayout showNavbar={false} showUserMenu={false}>
      <div className={st.container}>
        <div className={st.containerForm}>
          <h4 className={st.title}>Set a strong password</h4>
          <SignUpConfirmForm
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            hrefRedirectLink={ROUTES.signOut.path}
            handleClickRedirectLink={() =>
              dispatch(authActions.signOut({ isRedirect: true }))
            }
            textRedirectLink='Login as employee'
          />
        </div>
      </div>
    </CenteredColumnLayout>
  );
}

export default ChangeTempPasswordPage;
