import {
  all,
  put,
  takeLatest,
  call,
  select,
  takeEvery,
} from 'redux-saga/effects';
import { handleRequestError } from 'services/api/apiTools';
import { getParsingArrayToObj } from 'helpers/functions';
import ApiService from 'services/api';
import NavigationService from 'services/navigation/NavigationService';
import { getRoutePath } from 'helpers/path';
import { ROUTES } from 'router/routes';
import AuthService from 'services/auth/AuthService';
import { PAGE_SIZE } from 'constants/ui';
import { MODULE_TYPES } from 'constants/files';
import { getBlobUrlForFile } from 'services/api/files';
import { store } from 'store/store';
import * as actions from './actions';

export function* getPartSaga(action) {
  const isClient = AuthService.isClient();
  let requestValue;

  const currentApi = isClient ? ApiService.getClientPart : ApiService.getPart;

  try {
    requestValue = yield call(currentApi, action.payload);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getPart.failure());
    return;
  }

  yield put(
    actions.getPart.success({
      fetchedValue: { [requestValue.id]: requestValue },
      id: requestValue.id,
    })
  );
}

export function* getPartListSaga(actionOrArgument) {
  const isClient = AuthService.isClient();

  const { isClearPage, isPagination, ...params } =
    actionOrArgument.payload ?? actionOrArgument;

  const filterSearch = yield select((state) =>
    state?.partTypes?.fetchParams?.search?.trim()
  ) ?? '';
  const page = yield select(
    (state) => state?.partTypes?.fetchParams?.pagination?.page
  ) ?? 0;
  const size = yield select(
    (state) => state?.partTypes?.fetchParams?.pagination?.size
  ) ?? PAGE_SIZE;

  let requestValue;

  const currentApi = isClient
    ? ApiService.getClientPartList
    : ApiService.getPartList;

  try {
    requestValue = yield call(currentApi, {
      page: isPagination ? page : null,
      size: isPagination ? size : null,
      ...params,
      filter: filterSearch,
    });
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getPartList.failure());
    return;
  }

  const partListObj = getParsingArrayToObj(requestValue.items);
  const partIds = Object.keys(partListObj);

  yield put(
    actions.getPartList.success({
      partListObj,
      partIds,
      page: requestValue.page,
      count: requestValue.count,
    })
  );
}

export function* createPartSaga(action) {
  const isClient = AuthService.isClient();
  const { part, cacheClientFile } = action.payload;
  let requestValue;

  const currentApi = isClient
    ? ApiService.createClientPart
    : ApiService.createPart;
  try {
    requestValue = yield call(currentApi, part);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.createPart.failure());
    return;
  }

  const partId = requestValue.id;

  if (isClient) {
    let fileSagas = [];

    Object.entries(cacheClientFile).forEach((item) => {
      item[1].forEach((file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('type', item[0]);
        const callFunction = call(addFileSaga, {
          id: partId,
          file: formData,
        });

        fileSagas = [...fileSagas, callFunction];
      });
    });
    yield all([...fileSagas]);

    NavigationService.replacePath(
      getRoutePath(ROUTES.partTypesListCustomer.path)
    );
  } else {
    NavigationService.replacePath(
      getRoutePath(ROUTES.partTypesDetails.path, { id: requestValue.id })
    );
  }

  yield put(actions.createPart.success(partId));
}

export function* changePartSaga(action) {
  const { data, id } = action.payload;
  const isClient = AuthService.isClient();

  let requestValue;
  const currentApi = isClient
    ? ApiService.changeClientPart
    : ApiService.changePart;

  try {
    requestValue = yield call(currentApi, { data, id });
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.changePart.failure());
    return;
  }
  const currentData = getParsingArrayToObj([requestValue]);

  yield put(actions.changePart.success({ data: currentData }));
}

export function* removePartSaga(action) {
  const { isPagination, id } = action.payload;
  const isClient = AuthService.isClient();

  const currentApi = isClient
    ? ApiService.removeClientPart
    : ApiService.removePart;

  try {
    yield call(currentApi, id);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.removePart.failure());
    return;
  }

  const currentCustomerId = yield select(
    (state) => state?.customers?.currentCustomerId
  ) ?? null;

  yield call(getPartListSaga, { customer_id: currentCustomerId, isPagination });
  yield put(actions.removePart.success());
}

export function* addFileSaga(action) {
  const { file, id } = action || action.payload;
  const isClient = AuthService.isClient();

  const currentApi = isClient
    ? ApiService.addClientFileType
    : ApiService.addFileType;

  try {
    yield call(currentApi, {
      data: file,
      id,
      object: MODULE_TYPES.parttypes,
    });

    yield call(getFilesSaga, { id });
  } catch (errors) {
    handleRequestError(
      'Dear Customer, not all files were successfully uploaded. Please contact support at Strobels Supply for assistance.'
    );
    yield put(actions.addFile.failure());
    return;
  }

  yield put(actions.addFile.success());
}

export function* getFilesSaga(actionOrArgument) {
  const { id } = actionOrArgument.payload ?? actionOrArgument;
  const isClient = AuthService.isClient();

  const currentApi = isClient
    ? ApiService.getClientFileType
    : ApiService.getFileType;

  let requestValue;
  try {
    requestValue = yield call(currentApi, {
      id,
      object: MODULE_TYPES.parttypes,
    });
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getFiles.failure());
    return;
  }

  yield put(actions.getFiles.success({ data: { [id]: requestValue } }));
}

export function* getFileListByAllTypeSaga(action) {
  const { partId } = action.payload;

  const apiGetFileParams = {
    id: partId,
    object: MODULE_TYPES.parttypes,
  };

  const filesUrlObject = yield select(
    (state) => state?.partTypes?.files?.dataUrl
  ) ?? {};

  if (filesUrlObject) {
    Object.values(filesUrlObject).forEach((item) => {
      return URL.revokeObjectURL(item);
    });
  }

  let requestValueGetFiles;

  try {
    requestValueGetFiles = yield call(ApiService.getFileType, apiGetFileParams);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getFileListByAllType.failure());
    return;
  }

  return store.dispatch(
    actions.getFileListByAllType.success({
      data: requestValueGetFiles,
    })
  );
}

export function* removeFilesSaga(action) {
  const { id, path } = action.payload;
  const isClient = AuthService.isClient();

  const currentApi = isClient
    ? ApiService.removeClientFileType
    : ApiService.removeFileType;

  try {
    yield call(currentApi, {
      path,
    });

    yield call(getFilesSaga, { id });
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.removeFiles.failure());
    return;
  }

  yield put(actions.removeFiles.success());
}

export function* getFileByTypeSaga(action) {
  const { path } = action.payload;
  let requestValueDownloadFile;
  try {
    requestValueDownloadFile = yield call(ApiService.downloadBase64File, path);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getFileByType.failure());
    return;
  }

  const contentType = requestValueDownloadFile.headers['content-type'];
  const url = `data:${contentType};base64,${requestValueDownloadFile.data}`;

  const fileBlob = yield call(getBlobUrlForFile, url);

  const dataUrl = { [path]: { url: fileBlob, type: contentType, load: true } };

  yield put(actions.getFileByType.success({ dataUrl }));
}

export function* partTypesSagas() {
  yield all([
    yield takeLatest(actions.getPart.start, getPartSaga),
    yield takeLatest(actions.getPartList.start, getPartListSaga),
    yield takeLatest(actions.createPart.start, createPartSaga),
    yield takeLatest(actions.changePart.start, changePartSaga),
    yield takeLatest(actions.removePart.start, removePartSaga),
    yield takeLatest(actions.addFile.start, addFileSaga),
    yield takeLatest(actions.getFiles.start, getFilesSaga),
    yield takeLatest(
      actions.getFileListByAllType.start,
      getFileListByAllTypeSaga
    ),
    yield takeLatest(actions.removeFiles.start, removeFilesSaga),
    yield takeEvery(actions.getFileByType.start, getFileByTypeSaga),
  ]);
}
