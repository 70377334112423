import { URL_GET_JOB_ID } from 'constants/jobs';
import { removeNumber } from 'helpers/string';
import { store } from 'store/store';

const JobService = {
  getCurrentState() {
    return store.getState();
  },
  checkUrlGetJob(url) {
    const urlWithQuery = removeNumber(url);
    let currentUrl = '';

    if (urlWithQuery.includes('?')) {
      const [value] = urlWithQuery.split('?');
      currentUrl = value;
    } else {
      currentUrl = urlWithQuery;
    }

    const urlGetJob = URL_GET_JOB_ID.replace(':id', '');

    return currentUrl === urlGetJob;
  },
  getCurrentTab(list, jobId) {
    const state = this.getCurrentState();
    const jobList = state.jobs?.jobs?.data ?? {};
    const currentSeries = Object?.values(jobList)?.filter((seriesValue) => {
      return seriesValue?.list?.find(({ id }) => id === jobId);
    });
    const currentSeriesID = currentSeries?.[0]?.id || null;

    if (currentSeriesID) {
      const currentDataTab = list.find(({ ids }) => {
        return ids.includes(currentSeriesID);
      });
      const typeTap = currentDataTab?.type || null;
      return typeTap;
    }

    return null;
  },
};

export default JobService;
