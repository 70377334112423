import { Spinner } from 'react-bootstrap';
import { VARIANT_UI } from 'constants/ui';
import { bool, string } from 'prop-types';
import Text from 'componentsShared/Text/Text';
import cn from 'classnames';
import st from './Loader.module.scss';

Loader.propTypes = {
  isVisible: bool,
  isGradient: bool,
  className: string,
  text: string,
};

function Loader({
  isVisible = false,
  isGradient = false,
  className,
  isBackgroundColorGray = false,
  text = '',
}) {
  return (
    <div
      className={cn(
        st.containerLoader,
        {
          [st.visible]: isVisible,
          [st.backgroundColorGray]: isBackgroundColorGray,
          [st.loaderGradientWrapper]: isGradient,
        },
        className
      )}>
      <Spinner animation='border' variant={VARIANT_UI.primary} />
      {text && (
        <div>
          <Text variant='normal2' className={st.text}>
            {text}
          </Text>
        </div>
      )}
    </div>
  );
}

export default Loader;
