import React from 'react';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import { func, node, string, bool } from 'prop-types';
import InputSearch from 'componentsShared/InputSearch/InputSearch';
import cn from 'classnames';
import st from './AdminHeaderLayout.module.scss';

AdminHeaderLayout.propTypes = {
  children: node,
  inviteButton: func,
  title: string,
  searchValue: string,
  handleChangeSearch: func,
  isSearch: bool,
  renderFooter: func,
  classNameContentHolder: string,
  placeholderSearch: string,
};

function AdminHeaderLayout({
  children,
  inviteButton = () => {},
  title = '',
  searchValue,
  handleChangeSearch,
  isSearch = true,
  renderFooter,
  classNameContentHolder,
  isLoadingSearch,
  placeholderSearch = 'Search',
}) {
  const onChangeSearch = (value) => {
    handleChangeSearch(value);
  };

  return (
    <AdminLayout>
      <div className={st.contaier}>
        <div className={st.headerWrapper}>
          <div className={st.headerContent}>
            <h4 className={st.headerTitle}>{title}</h4>
          </div>
          <div className={st.headerContent}>
            <div>{inviteButton()}</div>

            {isSearch && (
              <InputSearch
                className={st.holderInputSearch}
                value={searchValue}
                onChange={onChangeSearch}
                isLoadingSearch={isLoadingSearch}
                placeholder={placeholderSearch}
              />
            )}
          </div>
        </div>

        <div className={cn(st.contentHolder, classNameContentHolder)}>
          {children}
        </div>
        {typeof renderFooter === 'function' && renderFooter()}
      </div>
    </AdminLayout>
  );
}

export default AdminHeaderLayout;
