import React, { useEffect, useMemo, useState } from 'react';
import { VARIANT_OUTLINE_UI, VARIANT_UI } from 'constants/ui';
import { Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { func } from 'prop-types';
import * as travelersActions from 'store/travelers/actions';
import JobsDetailsQAFooterActive from 'components/Jobs/JobsDetailsQAFooterActive/JobsDetailsQAFooterActive';
import { getStepListArray } from 'helpers/functions';
import st from './JobsDetailsQAFooter.module.scss';

JobsDetailsQAFooter.propTypes = {
  handleSubmit: func,
};

function JobsDetailsQAFooter({ handleSubmit, firstJobId = null }) {
  const dispatch = useDispatch();
  const [isDecline, setIsDecline] = useState(false);
  const [isSign, setIsSign] = useState(false);
  const [selectStep, setSelectStep] = useState({
    next: -1,
    prev: -1,
  });

  const currentJob =
    useSelector((state) => state?.jobs?.jobs?.data?.[firstJobId]) ?? {};

  const isLastStep = useMemo(() => {
    if (
      typeof selectStep.next !== 'number' ||
      !currentJob?.steps ||
      currentJob.steps.length === 0
    ) {
      return false;
    }

    return currentJob.steps.length === selectStep.next;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectStep.next]);

  const selectStepObj = useMemo(() => {
    const jobStepList = currentJob?.steps;

    const phaseNextStep = currentJob?.phases?.find(
      ({ name }) => name === jobStepList?.[selectStep.next]?.phase
    )?.human_name;
    const phasePrevStep = currentJob?.phases?.find(
      ({ name }) => name === jobStepList?.[selectStep.prev]?.phase
    )?.human_name;

    const selectNextStepString =
      selectStep.next > -1
        ? `${phaseNextStep} / ${jobStepList?.[selectStep.next]?.name}`
        : '';
    const selectPrevStepString =
      selectStep.prev > -1
        ? `${phasePrevStep} / ${jobStepList?.[selectStep.prev]?.name}`
        : '';

    return {
      next: selectNextStepString,
      prev: selectPrevStepString,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectStep, currentJob?.steps]);

  const nextPhaseArray = useMemo(() => {
    const stepList = [...(currentJob?.steps || [])];
    const currentStepIndex = currentJob.curent_step_index;
    const nextStepList = stepList.splice(
      currentStepIndex + 1,
      stepList.length - currentStepIndex + 1
    );

    return getStepListArray(nextStepList, currentStepIndex);
  }, [currentJob.curent_step_index, currentJob.steps]);

  const prevPhaseArray = useMemo(() => {
    const stepList = [...(currentJob?.steps || [])];
    const currentStepIndex = currentJob.curent_step_index;
    stepList.splice(
      currentStepIndex + 1,
      stepList.length - currentStepIndex + 1
    );

    return getStepListArray(stepList, -1);
  }, [currentJob.curent_step_index, currentJob.steps]);

  const handleSelectStepIndex = (type, stepIndex) => {
    setSelectStep((prev) => ({ ...prev, [type]: stepIndex }));
  };

  const handleAccept = () => {
    const nextStepIndex = isSign ? selectStep.next : selectStep.prev;
    handleSubmit({
      approved: !!isSign,
      curent_step_index: currentJob.curent_step_index,
      current_operation_index: currentJob.current_operation_index,
      next_operation_index: 0,
      next_step_index: nextStepIndex,
    });
  };

  const handleClickSign = () => {
    if (isLastStep) {
      const nextStepIndex = currentJob.curent_step_index + 1;
      handleSubmit({
        approved: true,
        curent_step_index: currentJob.curent_step_index,
        current_operation_index: currentJob.current_operation_index,
        next_operation_index: 0,
        next_step_index: nextStepIndex,
      });
      return;
    }
    setIsSign(true);
  };

  useEffect(() => {
    if (typeof currentJob.curent_step_index === 'number') {
      return setSelectStep((prev) => ({
        ...prev,
        next: currentJob.curent_step_index + 1,
      }));
    }
  }, [currentJob.curent_step_index]);

  useEffect(() => {
    dispatch(travelersActions.getPhaseList.start());
  }, [dispatch]);

  return (
    <div className={st.container}>
      <div className={st.content}>
        {!isDecline && !isSign && (
          <div className={st.buttonGroup}>
            <Button
              variant={VARIANT_OUTLINE_UI.primary}
              onClick={() => setIsDecline(true)}>
              Decline
            </Button>
            <Button variant={VARIANT_UI.primary} onClick={handleClickSign}>
              Sign Off
            </Button>
          </div>
        )}
        <JobsDetailsQAFooterActive
          type='next'
          show={isSign}
          setShow={setIsSign}
          label='Select the next step:'
          titleBtnAccept='Confirm Sign Off'
          handleAccept={handleAccept}
          phaseArray={nextPhaseArray}
          handleSelectStepIndex={handleSelectStepIndex}
          selectStepString={selectStepObj.next}
          disabledAcceptBtn={selectStep.next === -1}
          firstJobId={firstJobId}
        />
        <JobsDetailsQAFooterActive
          type='prev'
          show={isDecline}
          setShow={setIsDecline}
          label='Select a step to do next:'
          titleBtnAccept='Confirm Decline'
          handleAccept={handleAccept}
          varinantBtnAccept={VARIANT_UI.danger}
          varinantBtnCancel={VARIANT_OUTLINE_UI.secondary}
          phaseArray={prevPhaseArray}
          handleSelectStepIndex={handleSelectStepIndex}
          selectStepString={selectStepObj.prev}
          disabledAcceptBtn={selectStep.prev === -1}
          firstJobId={firstJobId}
        />
      </div>
    </div>
  );
}

export default JobsDetailsQAFooter;
