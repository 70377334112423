import { getQueryParam } from 'helpers/path';
import { api } from './ApiService';

export const getControlValueList = (params) => {
  const queryParam = getQueryParam(params);
  return api.get(`/bundles${queryParam}`);
};

export const getControlValue = (id) => {
  return api.get(`/bundles/${id}`);
};

export const createControlValue = (data) => {
  return api.post('/bundles', data);
};

export const changeControlValue = ({ id, data }) => {
  return api.put(`/bundles/${id}`, data);
};

export const removeControlValue = (id) => {
  return api.delete(`/bundles/${id}`);
};
