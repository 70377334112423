import React from 'react';
import Text from 'componentsShared/Text/Text';
import cn from 'classnames';
import { oneOfType, string, shape, object, number, bool } from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { DND_TYPE } from 'constants/traveler';
import { getIconFile } from 'helpers/file';
import DragCustomPlaceholder from 'componentsShared/DragCustomPlaceholder/DragCustomPlaceholder';
import { INTERMEDIATE_FILE_DRAGGABLE_ID } from 'constants/files';
import st from './AdminTemplateFileList.module.scss';

AdminTemplateFileList.propTypes = {
  operation: object,
  operationId: oneOfType([number, string]),
  stepId: oneOfType([number, string]),
  placeholderProps: shape({
    props: object,
    type: string,
    sourceDroppableId: string,
  }),
  requireFile: bool,
};

function AdminTemplateFileList({
  operation,
  operationId,
  stepId,
  placeholderProps,
  providedDroppableFile,
  snapshotDroppableFile,
  requireFile = false,
}) {
  const { files: fileList } = operation;

  const hasFileList = Array.isArray(fileList) && fileList.length > 0;

  const getFileName = (fileName) => {
    if (!fileName) {
      return '';
    }

    const fileNameArray = fileName.split('/');
    const currentFileName = fileNameArray[fileNameArray.length - 1];
    return currentFileName;
  };

  const renderFileList = () => {
    return fileList.map((fileName, i) => {
      return (
        <Draggable
          key={`${stepId}${operationId}${INTERMEDIATE_FILE_DRAGGABLE_ID}${fileName}`}
          draggableId={`${stepId}${operationId}${INTERMEDIATE_FILE_DRAGGABLE_ID}${fileName}`}
          index={i}>
          {(providedFile, snapshotFile) => (
            <div
              {...providedFile.draggableProps}
              ref={providedFile.innerRef}
              className={cn(st.containerFile, {
                [st.containerFileIsRequireFile]: requireFile,
              })}>
              <div
                {...providedFile.dragHandleProps}
                className={cn(st.fileWrapper, {
                  [st.fileDragging]: snapshotFile.isDragging,
                })}>
                <div
                  className={cn(st.holderFile, {
                    [st.fileHolderDragging]: snapshotFile.isDragging,
                  })}>
                  {getIconFile(fileName, st.iconFile, true)}
                </div>
                <Text varint='normal' className={st.name}>
                  {getFileName(fileName)}
                </Text>
              </div>
            </div>
          )}
        </Draggable>
      );
    });
  };

  return (
    <div className={st.droppableFile} ref={providedDroppableFile.innerRef}>
      <div className={cn(st.fileList)}>{hasFileList && renderFileList()}</div>

      {providedDroppableFile.placeholder}

      <DragCustomPlaceholder
        placeholderProps={placeholderProps}
        type={DND_TYPE.file}
        isDraggingOver={snapshotDroppableFile.isDraggingOver}
      />
    </div>
  );
}

export default AdminTemplateFileList;
