import React, { useMemo, useState } from 'react';
import Text from 'componentsShared/Text/Text';
import NavigationService from 'services/navigation/NavigationService';
import { ROUTES } from 'router/routes';
import Modal from 'componentsShared/Modal/Modal';
import ModalBack from 'components/Modal/ModalBack/ModalBack';
import { bool, object } from 'prop-types';
import { getRoutePath } from 'helpers/path';
import {
  MODAL_TITLE_BTN_DISCARD_CHANGES,
  VARIANT_OUTLINE_UI,
  VARIANT_UI,
} from 'constants/ui';
import { TRAVELER_STATUS } from 'constants/traveler';
import { Button, Form } from 'react-bootstrap';
import st from './PartsButtonTraveler.module.scss';

PartsButtonTraveler.propTypes = {
  isNewPart: bool,
  isDirtyForm: bool,
  travelerData: object,
  isRemotePart: bool,
};

function PartsButtonTraveler({
  isNewPart,
  isDirtyForm,
  travelerData,
  isRemotePart = false,
}) {
  const [showModalRemove, setShowModalRemove] = useState(false);
  const [showModalBack, setShowModalBack] = useState(false);

  const travelerObject = useMemo(() => {
    if (travelerData.travelerId) {
      if (travelerData.status === TRAVELER_STATUS.DRAFT) {
        return {
          title: 'Edit Traveler',
          callback: () =>
            handleNavigateTravelerDetails(travelerData.travelerId),
          variant: VARIANT_UI.primary,
        };
      }
      if (travelerData.status === TRAVELER_STATUS.ACTUAL) {
        return {
          title: 'View Traveler',
          callback: () =>
            handleNavigateTravelerDetails(travelerData.travelerId),
          variant: VARIANT_OUTLINE_UI.primary,
        };
      }
    }
    return {
      title: 'Create Traveler',
      callback: () => handleNavigateCreateTraveler(),
      variant: VARIANT_UI.primary,
    };
  }, [travelerData]);

  const handleNavigateCreateTraveler = () => {
    NavigationService.navigateToPath(ROUTES.createTraveler.path);
  };

  const handleNavigateTravelerDetails = (travelerId) => {
    NavigationService.navigateToPath(
      getRoutePath(ROUTES.treavelersDetails.path, {
        id: travelerId,
      })
    );
  };

  const handleClick = () => {
    if (isRemotePart) {
      return;
    }

    if (isNewPart) {
      return setShowModalRemove(true);
    }
    if (isDirtyForm) {
      return setShowModalBack(true);
    }
    travelerObject.callback();
  };

  return (
    <>
      <div className={st.container}>
        <Form.Label>Traveler</Form.Label>
        <Button onClick={handleClick} variant={travelerObject.variant}>
          {travelerObject.title}
        </Button>
      </div>
      <Modal
        show={showModalRemove}
        setShow={setShowModalRemove}
        title='Save Changes'
        buttonBottomRight={() => <></>}
        heightBody={70}>
        <Text variant='normal'>
          Please save the part details to create the Traveler.
        </Text>
      </Modal>
      <ModalBack
        show={showModalBack}
        setShow={setShowModalBack}
        navigateBack={handleNavigateCreateTraveler}
        titleBtn={MODAL_TITLE_BTN_DISCARD_CHANGES}
      />
    </>
  );
}
export default PartsButtonTraveler;
