// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OffcanvasCustomerEditContent_container__2IGM_ {\n  display: flex;\n  flex-direction: column;\n  grid-row-gap: 16px;\n  row-gap: 16px; }\n\n.OffcanvasCustomerEditContent_headerHolder__53NBw {\n  display: flex;\n  justify-content: space-between;\n  align-items: center; }\n\n.OffcanvasCustomerEditContent_headerInfo__3tr3_ {\n  display: flex;\n  align-items: center;\n  grid-column-gap: 12px;\n  column-gap: 12px; }\n", "",{"version":3,"sources":["webpack://src/components/Offcanvas/OffcanvasCustomerEditContent/OffcanvasCustomerEditContent.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAa;EAAb,aAAa,EAAA;;AAGf;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB,EAAA;;AAGrB;EACE,aAAa;EACb,mBAAmB;EACnB,qBAAgB;EAAhB,gBAAgB,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.container {\n  display: flex;\n  flex-direction: column;\n  row-gap: 16px;\n}\n\n.headerHolder {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.headerInfo {\n  display: flex;\n  align-items: center;\n  column-gap: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "OffcanvasCustomerEditContent_container__2IGM_",
	"headerHolder": "OffcanvasCustomerEditContent_headerHolder__53NBw",
	"headerInfo": "OffcanvasCustomerEditContent_headerInfo__3tr3_"
};
export default ___CSS_LOADER_EXPORT___;
