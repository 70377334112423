export const ANOTHER_REASON_VALUE = 'another';

export const UNSUBMITING_REASON_LIST = [
  {
    label: 'The Job was taken by mistake',
    value: 'The Job was taken by mistake',
  },
  {
    label: 'Required materials are missing',
    value: 'Required materials are missing',
  },
  { label: 'By illness', value: 'By illness' },
  { label: 'Another reason...', value: ANOTHER_REASON_VALUE },
];

export const JOBS_TYPES = {
  assigned: 'assigned',
  available: 'available',
  needsQA: 'needsQA',
  ncr: 'ncr',
};

export const JOBS_QA_TYPES = {
  interprocessQA: 'interprocessQA',
  finalQA: 'finalQA',
  ncr: 'ncr',
};

export const JOBS_TYPES_BACKEND = {
  assigned: JOBS_TYPES.assigned,
  available: JOBS_TYPES.available,
  needs_qa: JOBS_TYPES.needsQA,
  ncr: JOBS_TYPES.ncr,
};

export const JOBS_TYPES_ALL = 'all';

export const JOBS_STATUS = {
  active: 'ACTIVE',
  needsQA: 'NEEDS_QA',
  done: 'DONE',
  NCR: 'NCR',
};

export const JOBS_STATUS_TEXT = {
  ACTIVE: 'Active',
  NEEDS_QA: 'Needs QA',
  DONE: 'Done',
  NCR: 'NCR',
};

export const MAX_LENGTH_REASON = 100;

export const TYPE_MODAL = {
  qrCode: 'qrCode',
  findId: 'findId',
};

export const TIMER_ERROR_QR_CODE = 20000;

export const URL_GET_JOB_ID = '/jobs/:id';

export const JOB_ERROR_QR_CODE = {
  isNotAvailable: {
    type: 'isNotAvailable',
    text: 'This job is not available',
  },
  unknown: { type: 'unknown', text: 'Unknown job ID' },
  notMatchSeries: {
    type: 'notMatchSeries',
    text: 'This job does not match to the series',
  },
};

export const CARD_JOB_STATUS_NCR_TEXT = 'NCR Required';
