import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as authActions from 'store/auth/actions';
import Loader from 'componentsShared/Loader/Loader';

export default function ProtectedRoute(Component) {
  function DecoratedRoute(routeProps) {
    const dispatch = useDispatch();
    const user = useSelector((state) => state?.auth?.user) ?? {};
    const isLoading = useSelector((state) => state?.auth?.isLoading) ?? false;

    const isUser = Object.keys(user)?.length > 0;

    useEffect(() => {
      if (!isUser) {
        dispatch(authActions.getUserData.start());
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user, dispatch]);

    if (isLoading || !user) {
      return <Loader isVisible isBackgroundColorGray />;
    }

    return <Component {...routeProps} />;
  }
  return DecoratedRoute;
}
