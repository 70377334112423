import React from 'react';
import Status from 'componentsShared/Status/Status';
import { Gear, Search } from 'react-bootstrap-icons';
import { IS_PHONE_MD, VARIANT_UI } from 'constants/ui';
import { string, func, bool, node } from 'prop-types';
import HeaderContentTitle from 'components/HeaderContentTitle/HeaderContentTitle';
import useMediaQuery from 'hooks/useMediaQuery';
import cn from 'classnames';
import st from './HeaderContent.module.scss';

HeaderContent.propTypes = {
  title: string,
  onClickSearch: func,
  onClickGear: func,
  children: node,
  componentActive: func,
  isActive: bool,
  status: string,
  backBtn: node,
  className: string,
  flagTitleTooltip: bool,
  setFlagTitleTooltip: func,
  triggerTitleTooltip: bool,
};

function HeaderContent({
  title = '',
  onClickSearch = () => {},
  onClickGear = () => {},
  children,
  componentActive,
  isActive = false,
  status = '',
  backBtn,
  className,
  flexGrowNone = false,
  flagTitleTooltip,
  setFlagTitleTooltip,
  triggerTitleTooltip,
}) {
  const isPhoneMD = useMediaQuery(IS_PHONE_MD);

  if (isPhoneMD) {
    return (
      <div className={st.containerPhone}>
        <div
          className={cn(st.headerContaner, className, {
            [st.flexGrowNone]: flexGrowNone,
          })}>
          {backBtn}
          {status && (
            <Status
              title={status}
              className={st.status}
              variant={VARIANT_UI.info}
            />
          )}
          {title && (
            <HeaderContentTitle
              title={title}
              flexGrowNone={flexGrowNone}
              flagTitleTooltip={flagTitleTooltip}
              setFlagTitleTooltip={setFlagTitleTooltip}
              triggerTitleTooltip={triggerTitleTooltip}
            />
          )}
          {children}
        </div>
        {isActive && (
          <div className={st.headerAction}>
            {componentActive ? (
              componentActive()
            ) : (
              <>
                <Search size='19' onClick={onClickSearch} />
                <Gear size='19' onClick={onClickGear} />
              </>
            )}
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      className={cn(st.headerContaner, className, {
        [st.flexGrowNone]: flexGrowNone,
      })}>
      {backBtn}
      {status && (
        <Status
          title={status}
          className={st.status}
          variant={VARIANT_UI.info}
        />
      )}
      {title && (
        <HeaderContentTitle
          title={title}
          flexGrowNone={flexGrowNone}
          flagTitleTooltip={flagTitleTooltip}
          setFlagTitleTooltip={setFlagTitleTooltip}
          triggerTitleTooltip={triggerTitleTooltip}
        />
      )}
      {children}
      {isActive && (
        <div className={st.headerAction}>
          {componentActive ? (
            componentActive()
          ) : (
            <>
              <Search size='19' onClick={onClickSearch} />
              <Gear size='19' onClick={onClickGear} />
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default HeaderContent;
