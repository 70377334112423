import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';

export const initialState = {
  newNCR: {},
  ncr: {
    data: {},
    dataHeaders: {},
    sortIds: [],
    isLoading: false,
    isLoadingCreate: false,
    isLoadingChange: false,
  },
};

export const ncrReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.getNCRList.start, (state) => {
      return {
        ...state,
        ncr: {
          ...state.ncr,
          isLoading: true,
        },
      };
    })
    .addCase(actions.getNCRList.success, (state, action) => {
      const { data, ids, dataHeaders } = action.payload;

      return {
        ...state,
        ncr: {
          ...state.ncr,
          data,
          dataHeaders,
          sortIds: ids,
          isLoading: false,
        },
      };
    })
    .addCase(actions.getNCRList.failure, (state) => ({
      ...state,
      ncr: {
        ...state.ncr,
        isLoading: false,
      },
    }))
    .addCase(actions.createNCR.start, (state) => ({
      ...state,
      ncr: {
        ...state.ncr,
        isLoadingCreate: true,
      },
    }))
    .addCase(actions.createNCR.success, (state) => {
      return {
        ...state,
        ncr: {
          ...state.ncr,
          isLoadingCreate: false,
        },
      };
    })
    .addCase(actions.createNCR.failure, (state) => ({
      ...state,
      ncr: {
        ...state.ncr,
        isLoadingCreate: false,
      },
    }))
    .addCase(actions.removeNCR.start, (state) => ({
      ...state,
      ncr: {
        ...state.ncr,
        isLoading: true,
      },
    }))
    .addCase(actions.removeNCR.success, (state) => {
      return {
        ...state,
        ncr: {
          ...state.ncr,
          isLoading: false,
        },
      };
    })
    .addCase(actions.removeNCR.failure, (state) => ({
      ...state,
      ncr: {
        ...state.ncr,
        isLoading: false,
      },
    }));
});
