import React, { useState } from 'react';
import { array, bool } from 'prop-types';
import Text from 'componentsShared/Text/Text';
import Loader from 'componentsShared/Loader/Loader';
import { v4 as uuidv4 } from 'uuid';
import { CheckCircleIcon, RadioButtonUncheckedIcon } from 'assets/icons';
import { ucFirst } from 'helpers/string';
import cn from 'classnames';
import { JOBS_QA_TYPES } from 'constants/jobs';
import st from './OrderHistory.module.scss';
import OrderHistoryFileList from '../OrderHistoryFileList/OrderHistoryFileList';

OrderHistory.propTypes = {
  historyValue: array,
  isLoading: bool,
  isBackgroundColorGrayLoader: bool,
  isDoneStatus: bool,
};

function OrderHistory({
  historyValue = [],
  isLoading,
  isBackgroundColorGrayLoader,
  isDoneStatus,
  jobFilePathList = [],
}) {
  const [isOpen, setIsOpen] = useState(false);

  const indexFinalQa = historyValue.findIndex((item) =>
    item?.name?.startsWith(JOBS_QA_TYPES.finalQA)
  );

  const renderHistoryList = () => {
    const activeIndex = historyValue.findIndex(({ active }) => active);

    return historyValue.map((item, index) => {
      const isDone = activeIndex > index || isDoneStatus;
      const phaseName = item.name || '';
      const key = uuidv4();
      const showFileList = indexFinalQa === index && jobFilePathList.length > 0;

      return (
        <div className={st.historyItemContainer}>
          <div className={st.historyItemWrapper} key={key}>
            <div className={st.holderName}>
              {isDone ? <CheckCircleIcon /> : <RadioButtonUncheckedIcon />}
              <Text
                variant='normal'
                bold
                className={cn(st.nameText, { [st.nameTextPending]: !isDone })}>
                {ucFirst(phaseName)} {isDone ? 'done' : ''}
              </Text>
            </div>
            {showFileList && (
              <OrderHistoryFileList
                fileList={jobFilePathList}
                className={st.fileList}
                isOpen={isOpen}
              />
            )}
          </div>
          {showFileList && (
            <div
              className={st.containerArrowIcon}
              onClick={() => setIsOpen((prev) => !prev)}>
              <Text variant='normal' bold className={st.controlShowFile}>
                {isOpen ? 'Hide files' : 'Show files'}
              </Text>
            </div>
          )}
        </div>
      );
    });
  };

  return (
    <div className={st.container}>
      <h4 className={st.title}>Job History</h4>
      <div className={st.historyListWrapper}>
        {historyValue.length > 0 && renderHistoryList()}
        <Loader
          isVisible={isLoading}
          isBackgroundColorGray={isBackgroundColorGrayLoader}
        />
      </div>
    </div>
  );
}

export default OrderHistory;
