// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TravelerTemplateStep_container__3H7AZ {\n  padding-left: 20px;\n  display: flex;\n  flex-direction: column;\n  grid-row-gap: 8px;\n  row-gap: 8px; }\n\n.TravelerTemplateStep_containerPadding__OB_Uy {\n  padding-top: 8px;\n  padding-bottom: 12px; }\n\n.TravelerTemplateStep_stepTitleContainer__guTvc {\n  display: flex;\n  grid-column-gap: 10px;\n  column-gap: 10px;\n  align-items: center; }\n\n.TravelerTemplateStep_stepTitle___8KQ7 {\n  margin: 0; }\n\n.TravelerTemplateStep_stepTitleIconClose__1u_Rj {\n  transform: rotate(90deg); }\n\n.TravelerTemplateStep_hiddenStepContent__1_9B6 {\n  position: absolute;\n  opacity: 0;\n  pointer-events: none; }\n", "",{"version":3,"sources":["webpack://src/components/TravalerTemplate/TravelerTemplateStep/TravelerTemplateStep.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,aAAa;EACb,sBAAsB;EACtB,iBAAY;EAAZ,YAAY,EAAA;;AAGd;EACE,gBAAgB;EAChB,oBAAoB,EAAA;;AAGtB;EACE,aAAa;EACb,qBAAgB;EAAhB,gBAAgB;EAChB,mBAAmB,EAAA;;AAGrB;EACE,SAAS,EAAA;;AAGX;EACE,wBAAwB,EAAA;;AAG1B;EACE,kBAAkB;EAClB,UAAU;EACV,oBAAoB,EAAA","sourcesContent":[".container {\n  padding-left: 20px;\n  display: flex;\n  flex-direction: column;\n  row-gap: 8px;\n}\n\n.containerPadding {\n  padding-top: 8px;\n  padding-bottom: 12px;\n}\n\n.stepTitleContainer {\n  display: flex;\n  column-gap: 10px;\n  align-items: center;\n}\n\n.stepTitle {\n  margin: 0;\n}\n\n.stepTitleIconClose {\n  transform: rotate(90deg);\n}\n\n.hiddenStepContent {\n  position: absolute;\n  opacity: 0;\n  pointer-events: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "TravelerTemplateStep_container__3H7AZ",
	"containerPadding": "TravelerTemplateStep_containerPadding__OB_Uy",
	"stepTitleContainer": "TravelerTemplateStep_stepTitleContainer__guTvc",
	"stepTitle": "TravelerTemplateStep_stepTitle___8KQ7",
	"stepTitleIconClose": "TravelerTemplateStep_stepTitleIconClose__1u_Rj",
	"hiddenStepContent": "TravelerTemplateStep_hiddenStepContent__1_9B6"
};
export default ___CSS_LOADER_EXPORT___;
