import { JOBS_QA_TYPES, JOBS_TYPES, JOBS_TYPES_ALL } from 'constants/jobs';
import React from 'react';
import { useSelector } from 'react-redux';
import AuthService from 'services/auth/AuthService';
import JobListTabs from 'components/Jobs/JobListTabs/JobListTabs';
import st from './JobsListContainer.module.scss';

function JobsListContainer({ isChangedTab, setIsChangedTab }) {
  const isIncludeOnlyWorkerWithoutQA =
    AuthService.isIncludeOnlyWorkerWithoutQA();
  const isIncludeOnlyQAWithoutWorker =
    AuthService.isIncludeOnlyQAWithoutWorker();
  const isIncludeWorkerAndQA = AuthService.isIncludeWorkerAndQA();

  const assignedIds =
    useSelector((state) => state?.jobs?.jobs?.assignedIds) ?? [];
  const availableIds =
    useSelector((state) => state?.jobs?.jobs?.availableIds) ?? [];
  const needsQAIds =
    useSelector((state) => state?.jobs?.jobs?.needsQAIds) ?? [];
  const interprocessIds =
    useSelector((state) => state?.jobs?.jobs?.interprocessIds) ?? [];
  const ncrIds = useSelector((state) => state?.jobs?.jobs?.ncrIds) ?? [];

  const assignedQuantity =
    useSelector((state) => state?.jobs?.jobs?.assignedQuantity) ?? 0;
  const availableQuantity =
    useSelector((state) => state?.jobs?.jobs?.availableQuantity) ?? 0;
  const needsQAQuantity =
    useSelector((state) => state?.jobs?.jobs?.needsQAQuantity) ?? 0;
  const interprocessQuantity =
    useSelector((state) => state?.jobs?.jobs?.interprocessQuantity) ?? 0;
  const ncrQuantity =
    useSelector((state) => state?.jobs?.jobs?.ncrQuantity) ?? 0;

  const getCurrentData = () => {
    if (isIncludeOnlyWorkerWithoutQA) {
      return [
        {
          title: `In progress (${assignedQuantity})`,
          ids: assignedIds,
          type: JOBS_TYPES.assigned,
          showButtonBatch: true,
        },
        {
          title: `Available (${availableQuantity})`,
          ids: availableIds,
          type: JOBS_TYPES.available,
          showButtonBatch: true,
        },
        {
          title: `Needs QA Check (${needsQAQuantity})`,
          ids: needsQAIds,
          type: JOBS_TYPES.needsQA,
          showButtonBatch: false,
        },
        {
          title: `All (${
            assignedQuantity + availableQuantity + needsQAQuantity
          })`,
          ids: [...assignedIds, ...availableIds, ...needsQAIds],
          type: JOBS_TYPES_ALL,
          showButtonBatch: false,
        },
      ];
    }

    if (isIncludeOnlyQAWithoutWorker) {
      return [
        {
          title: `Interprocess Check (${interprocessQuantity})`,
          ids: interprocessIds,
          type: JOBS_QA_TYPES.interprocessQA,
          showButtonBatch: false,
        },
        {
          title: `Final Check (${assignedQuantity + availableQuantity})`,
          ids: [...assignedIds, ...availableIds],
          finalIdsProgress: [...assignedIds],
          type: JOBS_QA_TYPES.finalQA,
          showButtonBatch: true,
        },
        {
          title: `NCR Required (${ncrQuantity})`,
          ids: [...ncrIds],
          type: JOBS_QA_TYPES.ncr,
          showButtonBatch: false,
        },
        {
          title: `All (${
            assignedQuantity +
            availableQuantity +
            interprocessQuantity +
            ncrQuantity
          })`,
          ids: [...assignedIds, ...availableIds, ...interprocessIds, ...ncrIds],
          type: JOBS_TYPES_ALL,
          showButtonBatch: false,
        },
      ];
    }

    if (isIncludeWorkerAndQA) {
      return [
        {
          title: `Interprocess Check (${interprocessQuantity})`,
          ids: interprocessIds,
          type: JOBS_QA_TYPES.interprocessQA,
          showButtonBatch: false,
        },
        {
          title: `NCR Required (${ncrQuantity})`,
          ids: [...ncrIds],
          type: JOBS_QA_TYPES.ncr,
          showButtonBatch: false,
        },
        {
          title: `In progress (${assignedQuantity})`,
          ids: assignedIds,
          type: JOBS_TYPES.assigned,
          showButtonBatch: true,
        },
        {
          title: `Available (${availableQuantity})`,
          ids: availableIds,
          type: JOBS_TYPES.available,
          showButtonBatch: true,
        },
        {
          title: `All (${
            interprocessQuantity +
            assignedQuantity +
            availableQuantity +
            ncrQuantity
          })`,
          ids: [...interprocessIds, ...assignedIds, ...availableIds, ...ncrIds],
          type: JOBS_TYPES_ALL,
          showButtonBatch: false,
        },
      ];
    }

    return [];
  };

  const cuuretData = getCurrentData();

  return (
    <div className={st.container}>
      <JobListTabs
        data={cuuretData}
        isChangedTab={isChangedTab}
        setIsChangedTab={setIsChangedTab}
      />
    </div>
  );
}

export default JobsListContainer;
