// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Modal_modalBody__1ALTY {\n  overflow-y: auto; }\n\n.Modal_buttonBottomGroup__2Lrwq {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center; }\n\n.Modal_buttonBottomGroupLeft__k6fVD {\n  display: flex;\n  align-items: center;\n  grid-column-gap: 8px;\n  column-gap: 8px; }\n\n.Modal_buttonBottomGroupRigth__3FFka {\n  display: flex;\n  align-items: center;\n  grid-column-gap: 8px;\n  column-gap: 8px; }\n", "",{"version":3,"sources":["webpack://src/componentsShared/Modal/Modal.module.scss"],"names":[],"mappings":"AAEA;EACE,gBAAgB,EAAA;;AAGlB;EACE,WAAW;EACX,aAAa;EACb,8BAA8B;EAC9B,mBAAmB,EAAA;;AAGrB;EACE,aAAa;EACb,mBAAmB;EACnB,oBAAe;EAAf,eAAe,EAAA;;AAGjB;EACE,aAAa;EACb,mBAAmB;EACnB,oBAAe;EAAf,eAAe,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.modalBody {\n  overflow-y: auto;\n}\n\n.buttonBottomGroup {\n  width: 100%;\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n}\n\n.buttonBottomGroupLeft {\n  display: flex;\n  align-items: center;\n  column-gap: 8px;\n}\n\n.buttonBottomGroupRigth {\n  display: flex;\n  align-items: center;\n  column-gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalBody": "Modal_modalBody__1ALTY",
	"buttonBottomGroup": "Modal_buttonBottomGroup__2Lrwq",
	"buttonBottomGroupLeft": "Modal_buttonBottomGroupLeft__k6fVD",
	"buttonBottomGroupRigth": "Modal_buttonBottomGroupRigth__3FFka"
};
export default ___CSS_LOADER_EXPORT___;
