import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalButton from 'componentsShared/ModalButton/ModalButton';
import Text from 'componentsShared/Text/Text';
import * as jobsActions from 'store/jobs/actions';
import * as travelersActions from 'store/travelers/actions';
import { Button } from 'react-bootstrap';
import { VARIANT_OUTLINE_UI } from 'constants/ui';
import MultiDropDown from 'componentsShared/MultiDropDown/MultiDropDown';
import { getStepListArray } from 'helpers/functions';
import st from './ModalMoveJobStep.module.scss';

function ModalMoveJobStep({
  jobId,
  isJobDone = false,
  setShowModalDone = () => {},
}) {
  const dispatch = useDispatch();

  const job = useSelector((state) => state?.jobs?.jobs?.data?.[jobId]) ?? {};

  const [selectStep, setSelectStep] = useState(-1);

  const phaseArray = useMemo(() => {
    const stepList = [...(job?.steps || [])];
    return getStepListArray(stepList, -1);
  }, [job.steps]);

  const selectStepString = useMemo(() => {
    const jobStepList = job?.steps;

    const phaseStep = job?.phases?.find(
      ({ name }) => name === jobStepList?.[selectStep]?.phase
    )?.human_name;

    const currentSelectStepString =
      selectStep > -1
        ? `${phaseStep} / ${jobStepList?.[selectStep]?.name}`
        : '';

    return currentSelectStepString;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectStep, job?.steps]);

  const handleSelectStepIndex = (type, stepIndex) => {
    setSelectStep(stepIndex);
  };

  const handleMoveToStep = () => {
    dispatch(
      jobsActions.moveJobToStep.start({
        data: {
          ids: [jobId],
          curent_step_index: job.curent_step_index,
          current_operation_index: job.current_operation_index,
          next_step_index: selectStep,
          reason: '',
        },
      })
    );
    setSelectStep(-1);
  };

  useEffect(() => {
    dispatch(jobsActions.getJob.start({ id: jobId }));
    dispatch(travelersActions.getPhaseList.start());
  }, [dispatch, jobId]);

  return (
    <ModalButton
      title='Move Job to Step'
      titleAcceptBtn='Move Job'
      classNameModalContainer={st.modalContainer}
      onAcceptBtn={handleMoveToStep}
      onHide={() => setSelectStep(-1)}
      disabledAcceptBtn={selectStep === -1}
      bodyContent={() => {
        return (
          <div>
            <Text variant='normal'>
              Select the step to which you want to move the job
            </Text>

            <div className={st.containerSelectStep}>
              <MultiDropDown
                phaseArray={phaseArray}
                handleSelectStepIndex={handleSelectStepIndex}
                selectStepString={selectStepString}
                firstJobId={jobId}
              />
            </div>
          </div>
        );
      }}
      buttonRenderer={({ onClick }) => (
        <div className={st.buttonGroup}>
          <Button
            variant={VARIANT_OUTLINE_UI.secondary}
            onClick={isJobDone ? () => setShowModalDone(true) : onClick}>
            Move to Step
          </Button>
        </div>
      )}
    />
  );
}

export default ModalMoveJobStep;
