import React from 'react';
import { VARIANT_UI } from 'constants/ui';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import * as ncrActions from 'store/ncr/actions';
import Offcanvas from 'componentsShared/Offcanvas/Offcanvas';
import { bool, func } from 'prop-types';
import st from './OffcanvasNCRCreate.module.scss';
import OffcanvasEditNCR from '../OffcanvasEditNCR/OffcanvasEditNCR';

const validationSchema = Yup.object().shape({
  label: Yup.string().required('Required'),
});

OffcanvasNCRCreate.propTypes = {
  showOffcanvasNewNCR: bool,
  setShowOffcanvasNewNCR: func,
};

function OffcanvasNCRCreate({
  showOffcanvasNewNCR = false,
  setShowOffcanvasNewNCR = () => {},
}) {
  const dispatch = useDispatch();

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };
  const { register, handleSubmit, formState, reset } = useForm(formOptions);
  const { errors } = formState;

  const isLoadingNCR =
    useSelector((state) => state?.ncr?.ncr?.isLoading) ?? false;
  const isLoadingCreateNCR =
    useSelector((state) => state?.ncr?.ncr?.isLoadingCreate) ?? false;
  const isLoadingChangeNCR =
    useSelector((state) => state?.ncr?.ncr?.isLoadingChange) ?? false;
  const ncrList = useSelector((state) => state?.ncr?.ncr?.dataHeaders) ?? {};
  const ncrListArray = Object.values(ncrList);
  const isLoading = isLoadingNCR || isLoadingCreateNCR || isLoadingChangeNCR;

  const handleClearFields = () => {
    reset();
  };

  const handleSubmitTraveler = (onHide) => {
    handleSubmit((value) => onSubmit(value, onHide))();
  };

  const onSubmit = (data, onHideModal) => {
    const currentData = ncrListArray.map(({ label }) => label);

    dispatch(
      ncrActions.createNCR.start({ headers: [...currentData, data.label] })
    );
    onHideModal();
  };

  const handleOpenOffcanvas = () => {
    setShowOffcanvasNewNCR(true);
  };

  return (
    <>
      <Offcanvas
        show={showOffcanvasNewNCR}
        setShow={setShowOffcanvasNewNCR}
        backdrop={false}
        onHide={handleClearFields}
        title='Create NCR Field'
        width={460}
        footerContent={({ onHide }) => (
          <div className={st.buttonGroup}>
            <Button
              variant={VARIANT_UI.primary}
              onClick={() => handleSubmitTraveler(onHide)}>
              Save Field
            </Button>
          </div>
        )}>
        <OffcanvasEditNCR
          register={register}
          errors={errors}
          onSubmit={handleSubmit(onSubmit)}
        />
      </Offcanvas>
      <Button
        disabled={isLoading}
        variant={VARIANT_UI.primary}
        onClick={handleOpenOffcanvas}>
        Add NCR Field
      </Button>
    </>
  );
}

export default OffcanvasNCRCreate;
