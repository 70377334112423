import React, { useState } from 'react';
import { FormControl } from 'react-bootstrap';
import Text from 'componentsShared/Text/Text';
import {
  MAX_LENGTH_INPUT,
  PAGE_SIZE,
  TIME_SEARCH_DEBOUNCE,
} from 'constants/ui';
import { debounce } from 'helpers/functions';
import cn from 'classnames';
import st from './FooterPaginationSize.module.scss';

const debouncedSize = debounce((onChangeSize, data) => {
  onChangeSize(data);
}, TIME_SEARCH_DEBOUNCE);

function FooterPaginationSize({
  className = '',
  handleChangeSize = () => {},
  size = PAGE_SIZE,
}) {
  const [stateSize, setStateSize] = useState(size);

  const handleChange = (e) => {
    const { value } = e.target;

    const positivValue = value.replace(/[^\d]/, '');
    const number = parseInt(positivValue, 10);

    if (Number.isNaN(number)) {
      return;
    }

    if (number !== 0) {
      setStateSize(positivValue);
      debouncedSize(handleChangeSize, positivValue);
    }
  };

  return (
    <div className={cn(st.container, className)}>
      <Text variant='normal2' className={st.text}>
        Show
      </Text>
      <FormControl
        className={st.inputSize}
        type='number'
        value={stateSize}
        onChange={handleChange}
        maxLength={MAX_LENGTH_INPUT}
        min='0'
        size='sm'
      />
      <Text variant='normal2' className={st.text}>
        items by page
      </Text>
    </div>
  );
}

export default FooterPaginationSize;
