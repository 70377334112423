import { getQueryParam } from 'helpers/path';
import { api } from './ApiService';

export const getPart = (id) => {
  return api.get(`parttypes/${id}`);
};

export const getPartList = (query) => {
  const queryParam = getQueryParam(query);
  return api.get(`parttypes${queryParam}`);
};

export const createPart = (data) => {
  return api.post('parttypes', data);
};

export const changePart = ({ id, data }) => {
  return api.put(`parttypes/${id}`, data);
};

export const removePart = (id) => {
  return api.delete(`parttypes/${id}`);
};
