import React from 'react';
import Text from 'componentsShared/Text/Text';
import Modal from 'componentsShared/Modal/Modal';
import { bool, func, string } from 'prop-types';
import st from './ModalBack.module.scss';

ModalBack.propTypes = {
  show: bool,
  setShow: func,
  navigateBack: func,
  titleBtn: string,
};

function ModalBack({
  show,
  setShow = () => {},
  navigateBack = () => {},
  titleBtn = 'Go Back',
  onHide = () => {},
}) {
  return (
    <Modal
      show={show}
      setShow={setShow}
      onHide={onHide}
      title='Leave Page'
      titleAcceptBtn={titleBtn}
      onAcceptBtn={navigateBack}>
      <div className={st.modalRemoveContent}>
        <Text variant='normal'>
          Are you sure you want to leave this page? The changes will not be
          saved.
        </Text>
      </div>
    </Modal>
  );
}

export default ModalBack;
