import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Form, Button } from 'react-bootstrap';
import { VARIANT_UI } from 'constants/ui';
import { string, func, bool } from 'prop-types';
import cn from 'classnames';
import st from './AttachFilesButton.module.scss';

AttachFilesButton.propTypes = {
  accept: string,
  handleChange: func,
  className: string,
  title: string,
  varinant: string,
  disabled: bool,
  size: string,
};

function AttachFilesButton({
  accept = '',
  handleChange = () => {},
  className,
  title = '',
  varinant = VARIANT_UI.primary,
  disabled = false,
  size = 'md',
}) {
  const inputId = uuidv4();

  const handleFileUpload = (e) => {
    const file = e.target.files ? e.target.files[0] : null;
    handleChange(file, e);
  };

  if (disabled) {
    return (
      <div className={cn(st.container, className)}>
        <Button variant={varinant} disabled size={size}>
          {title}
        </Button>
      </div>
    );
  }

  return (
    <div className={cn(st.container, className)}>
      <Button as={Form.Label} htmlFor={inputId} variant={varinant} size={size}>
        {title}
        <input
          className={st.inputFile}
          type='file'
          accept={accept}
          hidden
          onChange={handleFileUpload}
          id={inputId}
        />
      </Button>
    </div>
  );
}

export default AttachFilesButton;
