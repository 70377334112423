import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';

export const initialState = {
  notes: {
    data: {},
    isLoading: false,
  },
  isAddingNote: false,
};

export const notesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.getNoteList.start, (state) => ({
      ...state,
      notes: { ...state.notes, isLoading: true },
    }))
    .addCase(actions.getNoteList.success, (state, action) => {
      const { data } = action.payload;

      return {
        ...state,
        notes: {
          ...state.notes,
          data,
          isLoading: false,
        },
      };
    })
    .addCase(actions.getNoteList.failure, (state) => ({
      ...state,
      notes: { ...state.notes, isLoading: false },
    }))
    .addCase(actions.createNote.start, (state) => ({
      ...state,
      notes: { ...state.notes, isLoading: true },
    }))
    .addCase(actions.createNote.success, (state) => {
      return {
        ...state,
        notes: {
          ...state.notes,
          isLoading: false,
        },
        isAddingNote: true,
      };
    })
    .addCase(actions.createNote.failure, (state) => ({
      ...state,
      notes: { ...state.notes, isLoading: false },
    }))
    .addCase(actions.changeIsAddingNote, (state, action) => {
      const value = action.params || false;
      return {
        ...state,
        isAddingNote: value,
      };
    });
});
