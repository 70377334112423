import React, { useState } from 'react';
import Text from 'componentsShared/Text/Text';
import { IMAGE_TYPE_ARRAY } from 'constants/files';
import {
  checkFileTypeDocOrXlsFromPath,
  getIconFile,
  getTypeFile,
} from 'helpers/file';
import cn from 'classnames';
import ModalFilePreview from 'components/Modal/ModalFilePreview/ModalFilePreview';
import * as filesActions from 'store/files/actions';
import { useDispatch } from 'react-redux';
import st from './JobsWorklogFileList.module.scss';

function JobsWorklogFileList({ fileList = [], name = '', className = '' }) {
  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedDataFile, setSelectedDataFile] = useState({
    path: '',
    content_type: '',
  });

  const handleClickCard = (filePath, type) => {
    const isTypeDocOrXls = checkFileTypeDocOrXlsFromPath(filePath);

    if (isTypeDocOrXls) {
      dispatch(
        filesActions.getFile.start({
          path: filePath,
          download: true,
        })
      );
      return;
    }

    setSelectedDataFile({
      path: filePath,
      content_type: type,
    });
  };

  const closeModal = () => {
    setSelectedDataFile({
      path: '',
      content_type: '',
    });
  };

  return (
    <div className={cn(st.container, className)}>
      <div className={st.containerInfo}>
        {name && (
          <div className={st.wrapperCVText}>
            <Text variant='normal'>{name}</Text>
          </div>
        )}

        <div
          className={st.containerArrowIcon}
          onClick={() => setIsOpen((prev) => !prev)}>
          <Text variant='normal' className={st.controlShowFile}>
            {isOpen ? 'Hide details' : 'See details'}
          </Text>
        </div>
      </div>

      {isOpen && (
        <div className={st.containerFileList}>
          {fileList?.length > 0 && (
            <>
              <div className={st.itemFiles}>
                {fileList.map((item) => {
                  const pathArray = item?.split('/') || [];
                  const validPathName = pathArray[pathArray.length - 1];

                  const typeFile = getTypeFile(item);

                  const isTypeImage =
                    IMAGE_TYPE_ARRAY?.includes(typeFile) || false;
                  const isTypePDF = typeFile?.includes('pdf') || false;

                  return (
                    <div
                      key={item}
                      className={cn(st.itemFileWrapper, {
                        [st.cursorDefault]: !isTypeImage && !isTypePDF,
                      })}
                      onClick={() => handleClickCard(item, typeFile)}>
                      {getIconFile(item, st.iconFile)}
                      <Text className={st.itemFileName}>{validPathName}</Text>
                    </div>
                  );
                })}
              </div>
            </>
          )}
        </div>
      )}
      <ModalFilePreview
        data={selectedDataFile}
        showModal={selectedDataFile.path}
        setShowModal={closeModal}
      />
    </div>
  );
}

export default JobsWorklogFileList;
