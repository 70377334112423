import { DND_TYPE, DROPPABLE_DELETE_ID } from 'constants/traveler';
import React from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { number, bool, func } from 'prop-types';
import st from './WrapperDroppableDelete.module.scss';

WrapperDroppableDelete.propTypes = {
  stepIndex: number,
  isDrag: bool,
  component: func,
  dragComponent: func,
};

function WrapperDroppableDelete({
  stepIndex,
  isDrag,
  component,
  dragComponent,
  isDisabledControl = false,
}) {
  if (isDisabledControl) {
    return null;
  }

  return (
    <Droppable
      droppableId={`${DROPPABLE_DELETE_ID.operation}${stepIndex}`}
      type={`${stepIndex}`}>
      {(providedDeleteStep) => (
        <div ref={providedDeleteStep.innerRef}>
          <Droppable
            droppableId={`${DROPPABLE_DELETE_ID.requireFile}${stepIndex}`}
            type={`${DND_TYPE.requireFile}${stepIndex}`}>
            {(providedDeleteRequireFile) => (
              <div ref={providedDeleteRequireFile.innerRef}>
                <Droppable
                  droppableId={`${DROPPABLE_DELETE_ID.file}${stepIndex}`}
                  type={`${DND_TYPE.file}${stepIndex}`}>
                  {(providedDroppableFile) => (
                    <div
                      className={st.container}
                      ref={providedDroppableFile.innerRef}>
                      {isDrag ? dragComponent() : component()}
                      <div className={st.placeholderNone}>
                        {providedDroppableFile.placeholder}
                        {providedDeleteStep.placeholder}
                        {providedDeleteRequireFile.placeholder}
                      </div>
                    </div>
                  )}
                </Droppable>
              </div>
            )}
          </Droppable>
        </div>
      )}
    </Droppable>
  );
}

export default WrapperDroppableDelete;
