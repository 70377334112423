import CenteredColumnLayout from 'layouts/CenteredColumnLayout/CenteredColumnLayout';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as ordersActions from 'store/orders/actions';
import * as customersActions from 'store/customers/actions';
import { Button } from 'react-bootstrap';
import OrderList from 'components/Order/OrderList/OrderList';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useParams } from 'react-router';
import Loader from 'componentsShared/Loader/Loader';
import NavigationService from 'services/navigation/NavigationService';
import { ROUTES } from 'router/routes';
import HeaderContent from 'componentsShared/HeaderContent/HeaderContent';

function CustomerDetailsPage() {
  const { id: customerId } = useParams();
  const dispatch = useDispatch();

  const isLoadingOrders =
    useSelector((state) => state?.orders?.orders?.isLoading) ?? false;
  const orderList = useSelector((state) => state?.orders?.orders?.data) ?? {};
  const orderIds = useSelector((state) => state?.orders?.orders.sortIds) ?? [];

  const handleCreateOrder = () => {
    NavigationService.navigateToPath(ROUTES.createOrder.path);
  };

  useEffect(() => {
    dispatch(
      ordersActions.getOrdersList.start({
        params: { customerId },
        showLoader: true,
      })
    );
    dispatch(customersActions.setCurrentCustomer(customerId));
  }, [dispatch, customerId]);

  useEffect(() => {
    if (customerId) {
      dispatch(customersActions.getCustomer.start(customerId));
    }
  }, [dispatch, customerId]);

  return (
    <CenteredColumnLayout>
      <HeaderContent title='Purchase Orders' isActive>
        <Button variant='primary' size='sm' onClick={handleCreateOrder}>
          New PO
        </Button>
      </HeaderContent>
      <OrderList dataList={orderList} ids={orderIds} />
      <Loader isVisible={isLoadingOrders} isBackgroundColorGray />
    </CenteredColumnLayout>
  );
}

export default CustomerDetailsPage;
