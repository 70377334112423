import React, { useEffect, useRef, useState } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import { array, func } from 'prop-types';
import { MAX_LENGTH_INPUT } from 'constants/ui';
import { Typeahead } from 'react-bootstrap-typeahead';
import autosize from 'autosize';
import { v4 as uuidv4 } from 'uuid';
import { ClearIcon } from 'assets/icons';
import { Search } from 'react-bootstrap-icons';
import st from './TravelerTemplateSelectList.module.scss';

TravelerTemplateSelectList.propTypes = {
  selectTemplateList: array,
  handleSelectTemplate: func,
};

function TravelerTemplateSelectList({
  selectTemplateList,
  handleSelectTemplate = () => {},
}) {
  const firstSelectTemplate = selectTemplateList?.[0]?.name;

  const options =
    selectTemplateList.map((item) => ({
      label: item.name || '',
      id: item.value,
    })) || [];

  const inputId = uuidv4();
  const textareaRef = useRef(null);
  const [selected, setSelected] = useState([
    { label: selectTemplateList?.[0]?.name || '' },
  ]);
  const [value, setValue] = useState(selectTemplateList?.[0]?.name);

  const handleChange = (selectedValue) => {
    setSelected(selectedValue);
    handleSelectTemplate(selectedValue?.[0]?.id || '');
    if (selectedValue?.[0]?.label) {
      setValue(selectedValue?.[0]?.label || '');
    }
  };

  const onClearSearch = () => {
    handleSelectTemplate('');
    setSelected([]);
    setValue('');
  };

  const getIconForFilterSearch = () => {
    if (selected.length === 1) {
      return (
        <InputGroup.Text onClick={onClearSearch} className={st.inputGroupClear}>
          <ClearIcon />
        </InputGroup.Text>
      );
    }
    return (
      <InputGroup.Text>
        <Search />
      </InputGroup.Text>
    );
  };

  useEffect(() => {
    if (inputId && textareaRef.current) {
      autosize(textareaRef.current);
    }
  }, [inputId]);

  useEffect(() => {
    if (firstSelectTemplate) {
      setSelected([{ label: firstSelectTemplate || '' }]);
      setValue(firstSelectTemplate);
    }
  }, [firstSelectTemplate]);

  return (
    <div className={st.container}>
      <Typeahead
        options={options}
        selected={selected}
        onInputChange={setValue}
        onChange={handleChange}
        id={inputId}
        renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
          <InputGroup>
            <FormControl
              {...inputProps}
              ref={(input) => {
                inputRef(input);
                referenceElementRef(input);
                textareaRef.current = input;
              }}
              rows={1}
              size='sm'
              value={value}
              maxLength={MAX_LENGTH_INPUT}
            />
            {getIconForFilterSearch()}
          </InputGroup>
        )}
      />
    </div>
  );
}

export default TravelerTemplateSelectList;
