import { Card, Placeholder } from 'react-bootstrap';
import { bool, string } from 'prop-types';
import cn from 'classnames';
import st from './Skeleton.module.scss';

Skeleton.propTypes = {
  isVisible: bool,
  className: string,
  classNameSkeleton: string,
};

function Skeleton({ isVisible = false, className, classNameSkeleton }) {
  return (
    <div
      className={cn(
        st.containerPlaceholder,
        {
          [st.visible]: isVisible,
        },
        className
      )}>
      <Placeholder animation='glow' className={st.holderPlaceholder}>
        <Placeholder
          className={cn(st.placeholder, classNameSkeleton)}
          as={Card.Text}
        />
      </Placeholder>
    </div>
  );
}

export default Skeleton;
