import React, { useMemo, useState } from 'react';
import { Form, FormControl, Button } from 'react-bootstrap';
import { MAX_LENGTH_INPUT, VARIANT_OUTLINE_UI, VARIANT_UI } from 'constants/ui';
import * as customersActions from 'store/customers/actions';
import { useDispatch, useSelector } from 'react-redux';
import { object, number, string, oneOfType, func, bool } from 'prop-types';
import RequiredLabel from 'componentsShared/RequiredLabel/RequiredLabel';
import Text from 'componentsShared/Text/Text';
import Status from 'componentsShared/Status/Status';
import { CUSTOMER_STATUS } from 'constants/customer';
import Modal from 'componentsShared/Modal/Modal';
import st from './EditCustomerForm.module.scss';

EditCustomerForm.propTypes = {
  customerId: oneOfType([number, string]),
  onClose: func,
  onSubmit: func,
  register: func,
  errors: object,
  setEmailValue: func,
  isDisabledInvite: bool,
  isChangedEmail: func,
  handleResendInviteWithSaving: func,
  isCreate: bool,
  customer: object,
  statusBlocked: bool,
};

function EditCustomerForm({
  companyName,
  customerId,
  onClose = () => {},
  onSubmit = () => {},
  register = () => {},
  errors,
  setEmailValue = () => {},
  setNameValue = () => {},
  isDisabledInvite,
  isChangedEmail,
  handleResendInviteWithSaving = () => {},
  isCreate = false,
  customer,
  statusBlocked = false,
}) {
  const dispatch = useDispatch();
  const [showModal, setShowModal] = useState(false);

  const variantStatus = useMemo(() => {
    let value = '';
    switch (customer?.status?.toUpperCase()) {
      case CUSTOMER_STATUS.INVITED:
        value = VARIANT_UI.warning;
        break;
      case CUSTOMER_STATUS.ACTIVE:
        value = VARIANT_UI.success;
        break;
      case CUSTOMER_STATUS.NEW:
        value = VARIANT_UI.primary;
        break;
      default:
        value = VARIANT_UI.secondary;
        break;
    }

    return value;
  }, [customer]);

  const isLoadingResendInvite =
    useSelector(
      (state) => state?.customers?.customers?.isLoadingResendInvite
    ) ?? false;

  const handleResendInvite = () => {
    if (isChangedEmail) {
      setShowModal(true);
    } else {
      dispatch(customersActions.resendInviteCustomer.start(customerId));
      onClose();
    }
  };

  const formPropsEmail = { ...register('email') };
  const formPropsName = { ...register('name') };

  return (
    <>
      <Form className={st.wrapperForm} onSubmit={onSubmit}>
        <div className={st.formGroup}>
          <Form.Label>Company Name</Form.Label>
          <div>
            <Text variant='normal' className={st.nameCompany}>
              {companyName}
            </Text>
          </div>
        </div>

        <div className={st.formGroup}>
          <Form.Label>
            <RequiredLabel />
            Representative Name
          </Form.Label>
          <FormControl
            {...formPropsName}
            onChange={(e) => {
              formPropsName.onChange(e);
              setNameValue(e.target.value);
            }}
            setNameValue
            isInvalid={errors.name}
            maxLength={MAX_LENGTH_INPUT}
            disabled={statusBlocked}
          />
        </div>

        <div className={st.formGroup}>
          <Form.Label>
            <RequiredLabel />
            Representative Email
          </Form.Label>
          <FormControl
            {...formPropsEmail}
            onChange={(e) => {
              formPropsEmail.onChange(e);
              setEmailValue(e.target.value);
            }}
            isInvalid={errors.email}
            maxLength={MAX_LENGTH_INPUT}
            disabled={statusBlocked}
          />
        </div>

        {!isCreate && (
          <>
            {!statusBlocked && (
              <div className={st.buttonGroup}>
                <Button
                  disabled={
                    isLoadingResendInvite ||
                    isDisabledInvite ||
                    Object.keys(errors).length > 0
                  }
                  variant={VARIANT_OUTLINE_UI.primary}
                  onClick={handleResendInvite}>
                  Resend Invite
                </Button>
              </div>
            )}

            <div className={st.formGroup}>
              <Form.Label>Account Status</Form.Label>
              <div className={st.holderStatus}>
                <Status
                  title={customer.status}
                  className={st.status}
                  variant={variantStatus}
                />
              </div>
            </div>
          </>
        )}
      </Form>
      <Modal
        title='Unsaved Changes'
        show={showModal}
        setShow={setShowModal}
        titleAcceptBtn='Save and Proceed'
        onAcceptBtn={handleResendInviteWithSaving}
        variantAcceptBtn={VARIANT_UI.primary}>
        <div className={st.wrapperModalContent}>
          <Text variant='normal'>
            The changes should be saved in order to proceed.
          </Text>
        </div>
      </Modal>
    </>
  );
}

export default EditCustomerForm;
