import { store } from 'store/store';

import history from 'history/history';

import { ROUTES } from 'router/routes';
import { DEFAULT_PATHS_MAP } from 'router/defaultPathsMap';
import AuthService from 'services/auth/AuthService';
import { getValidPath } from 'helpers/path';
import { KEY_DEFAULT_REDIRECT } from 'constants/ui';

const NavigationService = {
  getCurrentState() {
    return store.getState();
  },
  navigateToPath(path, state) {
    history.push(getValidPath(path), state);
  },
  replacePath(path, state) {
    history.replace(getValidPath(path), state);
  },
  getDefaultPath(roles) {
    const accountRole = AuthService.getHighestUserRole(roles);
    return (
      DEFAULT_PATHS_MAP?.[accountRole]?.redirectPath || ROUTES.redirectUrl.path
    );
  },
  navigateToDefaultPath() {
    const redirectPath = this.getDefaultPath();
    this.navigateToPath(redirectPath);
  },
  back(isCheckDefault) {
    const isDefaultRedirect = history?.location?.state?.[KEY_DEFAULT_REDIRECT];

    if (isCheckDefault && isDefaultRedirect) {
      const defaultPath = this.getDefaultPath();
      this.replacePath(defaultPath);
      return;
    }

    history.goBack();
  },
};

export default NavigationService;
