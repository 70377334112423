import { createAction } from '@reduxjs/toolkit';
import { getRequestAction } from 'helpers/functions';

const ACTION_PREFIX = 'customers';

export const getCustomerList = getRequestAction(
  `${ACTION_PREFIX}/getCustomerList`
);

export const createCustomer = getRequestAction(
  `${ACTION_PREFIX}/createCustomer`
);

export const changeCustomer = getRequestAction(
  `${ACTION_PREFIX}/changeCustomer`
);

export const resendInviteCustomer = getRequestAction(
  `${ACTION_PREFIX}/resendInviteCustomer`
);

export const setCurrentCustomer = createAction(
  `${ACTION_PREFIX}/setCurrentCustomer`
);

export const removeCustomer = getRequestAction(
  `${ACTION_PREFIX}/removeCustomer`
);

export const changeFilterSearch = createAction(
  `${ACTION_PREFIX}/changeFilterSearch`
);

export const getCustomer = getRequestAction(`${ACTION_PREFIX}/getCustomer`);

export const changePage = createAction(`${ACTION_PREFIX}/changePage`);

export const resendInviteCustomerWithSaving = getRequestAction(
  `${ACTION_PREFIX}/resendInviteCustomerWithSaving`
);

export const createCustomerLogin = getRequestAction(
  `${ACTION_PREFIX}/createCustomerLogin`
);

export const changeCustomerLogin = getRequestAction(
  `${ACTION_PREFIX}/changeCustomerLogin`
);

export const blockCustomerLogin = getRequestAction(
  `${ACTION_PREFIX}/blockCustomerLogin`
);

export const unblockCustomerLogin = getRequestAction(
  `${ACTION_PREFIX}/unblockCustomerLogin`
);
