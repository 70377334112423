import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import DropdownButton from 'componentsShared/DropdownButton/DropdownButton';
import { MoreVertIcon } from 'assets/icons';
import { VARIANT_OUTLINE_UI } from 'constants/ui';
import HeaderContent from 'componentsShared/HeaderContent/HeaderContent';
import { bool, array, func, string } from 'prop-types';
import ButtonBack from 'componentsShared/ButtonBack/ButtonBack';
import { useDispatch } from 'react-redux';
import * as ordersActions from 'store/orders/actions';
import * as partTypesActions from 'store/partTypes/actions';
// import AuthService from 'services/auth/AuthService';
// import RFQService from 'services/rfq/RFQService';
// // eslint-disable-next-line import/order
// import { useParams } from 'react-router-dom';
import Modal from 'componentsShared/Modal/Modal';
import Text from 'componentsShared/Text/Text';
import { ROUTES } from 'router/routes';
import NavigationService from 'services/navigation/NavigationService';
import st from './RFQHeader.module.scss';

RFQHeader.propTypes = {
  onСlickBack: func,
  // handleSubmitRFQ: func,
  isLoading: bool,
  handleSaveRFQ: func,
  dropdownList: array,
  title: string,
  isBurger: bool,
  // isDisabledSubmit: bool,
  isDisabledSave: bool,
  isCheckModalBackBtn: bool,
  handleNavigateBack: func,
  // isEmptyParts: bool,
  isNewRFQ: bool,
};

function RFQHeader({
  onСlickBack = () => {},
  // handleSubmitRFQ = () => {},
  isLoading = false,
  handleSaveRFQ = () => {},
  dropdownList = [],
  title = 'RFQ',
  isBurger = false,
  // isDisabledSubmit = false,
  isDisabledSave = false,
  isCheckModalBackBtn = false,
  handleNavigateBack = () => {},
  // isEmptyParts = false,
  isNewRFQ = false,
}) {
  const dispatch = useDispatch();

  // const { id: rfqId } = useParams();

  const [showModalEmptyParts, setShowModalEmptyParts] = useState(false);

  // const isActiveStatus = RFQService.isStatusActive(rfqId);

  // const onSubmitRFQ = () => {
  //   if (isEmptyParts) {
  //     return setShowModalEmptyParts(true);
  //   }
  //   handleSubmitRFQ();
  // };

  const onNavigateNewPO = () => {
    dispatch(ordersActions.changeIsConvertFromRFQ(true));
    dispatch(partTypesActions.addQuantityForSelectedPartTypes());
    NavigationService.navigateToPath(ROUTES.createOrder.path);
  };

  const handleConvertToPO = () => {
    onNavigateNewPO();
  };

  return (
    <>
      <HeaderContent
        title={title}
        backBtn={
          <ButtonBack
            onClick={onСlickBack}
            handleNavigateBack={handleNavigateBack}
            isCheckModal={isCheckModalBackBtn}
          />
        }
        isActive
        componentActive={() => (
          <div className={st.buttonGroup}>
            {/* <>
              {isActiveStatus ? ( */}
            {!isNewRFQ && (
              <Button
                onClick={handleConvertToPO}
                variant={VARIANT_OUTLINE_UI.secondary}>
                Convert to PO
              </Button>
            )}

            <Button
              onClick={handleSaveRFQ}
              disabled={isLoading || isDisabledSave}>
              Save
            </Button>

            {/* ) : (
                <>
                  <Button
                    onClick={onSubmitRFQ}
                    disabled={isLoading || isDisabledSubmit}>
                    Submit
                  </Button>
                  <Button
                    variant={VARIANT_OUTLINE_UI.secondary}
                    onClick={handleSaveRFQ}
                    disabled={isLoading || isDisabledSave}>
                    Save Draft
                  </Button>
                </>
              )}
            </> */}
            {isBurger && (
              <DropdownButton
                list={dropdownList}
                classNameBtn={st.moreButton}
                startIcon={<MoreVertIcon />}
                btnVariant={VARIANT_OUTLINE_UI.secondary}
                arrowNone
                disabled={isLoading}
              />
            )}
          </div>
        )}
      />

      <Modal
        show={showModalEmptyParts}
        setShow={setShowModalEmptyParts}
        title='Empty Parts'
        buttonBottomRight={({ onClose }) => (
          <Button onClick={onClose}>OK</Button>
        )}>
        <div className={st.modalContent}>
          <Text variant='normal'>
            Traveller has no associated part numbers. You should add any part
            numbers to submit traveler.
          </Text>
        </div>
      </Modal>
    </>
  );
}

export default RFQHeader;
