// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalMoveJobStep_modalContainer__1G_qI .modal-body {\n  position: initial !important; }\n\n.ModalMoveJobStep_containerSelectStep__1nfbi {\n  margin-top: 8px; }\n", "",{"version":3,"sources":["webpack://src/components/Modal/ModalMoveJobStep/ModalMoveJobStep.module.scss"],"names":[],"mappings":"AAEA;EAGM,4BAA4B,EAAA;;AAKlC;EACE,eAAe,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.modalContainer {\n  :global {\n    .modal-body {\n      position: initial !important;\n    }\n  }\n}\n\n.containerSelectStep {\n  margin-top: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"modalContainer": "ModalMoveJobStep_modalContainer__1G_qI",
	"containerSelectStep": "ModalMoveJobStep_containerSelectStep__1nfbi"
};
export default ___CSS_LOADER_EXPORT___;
