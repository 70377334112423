import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import { handleRequestError } from 'services/api/apiTools';
import { getParsingArrayToObj, getValidEmployee } from 'helpers/functions';
import ApiService from 'services/api';
import NotificationsService from 'services/notifications/NotificationsService';
import { PAGE_SIZE, VARIANT_UI } from 'constants/ui';
import {
  ERROR_TEXT_REUSE_EMAIL,
  HEADER_ATTENTION,
} from 'constants/notifications';
import * as actions from './actions';

export function* getEmployeeListSaga(action) {
  const { isPagination } = action?.payload || {};

  const filterSearch = yield select((state) =>
    state?.employees?.fetchParams?.search?.trim()
  ) ?? '';
  const page = yield select(
    (state) => state?.employees?.fetchParams?.pagination?.page
  ) ?? 0;
  const size = yield select(
    (state) => state?.employees?.fetchParams?.pagination?.size
  ) ?? PAGE_SIZE;

  const filter = {
    filter: filterSearch,
    page: isPagination ? page : null,
    size: isPagination ? size : null,
  };

  let requestValue;
  try {
    requestValue = yield call(ApiService.getEmployeeList, filter);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getEmployeeList.failure());
    return;
  }

  const validValue = getValidEmployee(requestValue.items);

  const employeeObj = getParsingArrayToObj(validValue);
  const employeeIds = Object.keys(employeeObj);

  yield put(
    actions.getEmployeeList.success({
      data: employeeObj,
      ids: employeeIds,
      page: requestValue.page,
      count: requestValue.count,
    })
  );
}

export function* createEmployeeSaga(action) {
  const { valueForm, onCloseModal } = action.payload;
  let requestValue;

  try {
    requestValue = yield call(ApiService.checkUserEmail, valueForm.login);
    const isUniqueEmail = requestValue.unique;

    if (!isUniqueEmail) {
      yield put(actions.createEmployee.failure());
      NotificationsService.throwNotification({
        title: HEADER_ATTENTION,
        text: ERROR_TEXT_REUSE_EMAIL,
        variant: VARIANT_UI.danger,
      });
      return;
    }

    yield call(ApiService.createEmployee, valueForm);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.createEmployee.failure());
    return;
  }

  yield put(actions.getEmployeeList.start({ isPagination: true }));
  yield put(actions.createEmployee.success());
  onCloseModal();
}

export function* changeEmployeeSaga(action) {
  const { data, login } = action.payload;
  try {
    yield call(ApiService.changeEmployee, { data, login });
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.changeEmployee.failure());
    return;
  }

  yield put(actions.getEmployeeList.start({ isPagination: true }));
  yield put(actions.changeEmployee.success());
}

export function* removeEmployeeSaga(action) {
  const params = action.payload;
  try {
    yield call(ApiService.removeEmployee, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.removeEmployee.failure());
    return;
  }

  yield put(actions.getEmployeeList.start({ isPagination: true }));
  yield put(actions.removeEmployee.success());
}

export function* getUserRolesSaga() {
  let requestValue;

  try {
    requestValue = yield call(ApiService.getUserRoles);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getUserRoles.failure());
    return;
  }

  yield put(actions.getUserRoles.success(requestValue));
}

export function* employeesSagas() {
  yield all([
    yield takeLatest(actions.getEmployeeList.start, getEmployeeListSaga),
    yield takeLatest(actions.createEmployee.start, createEmployeeSaga),
    yield takeLatest(actions.changeEmployee.start, changeEmployeeSaga),
    yield takeLatest(actions.removeEmployee.start, removeEmployeeSaga),
    yield takeLatest(actions.getUserRoles.start, getUserRolesSaga),
  ]);
}
