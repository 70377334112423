import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import Router from 'router/Router';
import { store, persistor } from 'store/store';
import ErrorBoundary from 'componentsShared/ErrorBoundary/ErrorBoundary';
import ToastsProvider from 'componentsShared/Toasts/Toasts';

import 'styles/index.scss';

function App() {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ToastsProvider />
          <Router />
        </PersistGate>
      </Provider>
    </ErrorBoundary>
  );
}

export default App;
