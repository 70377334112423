// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JobsDetailsQA_title__17Kwd {\n  margin-bottom: 8px; }\n\n.JobsDetailsQA_containerFiles__31gG9 {\n  display: flex;\n  flex-direction: column;\n  margin-top: 16px;\n  grid-row-gap: 12px;\n  row-gap: 12px; }\n", "",{"version":3,"sources":["webpack://src/components/Jobs/JobsDetailsQA/JobsDetailsQA.module.scss"],"names":[],"mappings":"AAEA;EACE,kBAAkB,EAAA;;AAGpB;EACE,aAAa;EACb,sBAAsB;EACtB,gBAAgB;EAChB,kBAAa;EAAb,aAAa,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.title {\n  margin-bottom: 8px;\n}\n\n.containerFiles {\n  display: flex;\n  flex-direction: column;\n  margin-top: 16px;\n  row-gap: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": "JobsDetailsQA_title__17Kwd",
	"containerFiles": "JobsDetailsQA_containerFiles__31gG9"
};
export default ___CSS_LOADER_EXPORT___;
