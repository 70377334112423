import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as worklogsActions from 'store/worklogs/actions';
import * as ordersActions from 'store/orders/actions';
import * as jobsActions from 'store/jobs/actions';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useParams } from 'react-router';
import CenteredColumnLayout from 'layouts/CenteredColumnLayout/CenteredColumnLayout';
import HeaderContent from 'componentsShared/HeaderContent/HeaderContent';
import ButtonBack from 'componentsShared/ButtonBack/ButtonBack';
import OrderHistory from 'components/Order/OrderHistory/OrderHistory';
import ButtonNotesWrapper from 'components/Notes/ButtonNotesWrapper/ButtonNotesWrapper';
import NavigationService from 'services/navigation/NavigationService';
import { ORDER_STATUS } from 'constants/order';
import { FILE_TYPES } from 'constants/files';

const OrderDetailsHistoryPage = () => {
  const dispatch = useDispatch();
  const { jobId } = useParams();

  const isLoadingWorklogs =
    useSelector((state) => state?.worklogs?.worklogs?.isLoading) ?? false;
  const currentJobHistory =
    useSelector((state) => state?.worklogs?.worklogs?.data?.[jobId]) ?? {};
  const jobFileList =
    useSelector((state) => state?.jobs?.files?.data?.[jobId]) ?? [];
  const orderId = currentJobHistory.purchase_order_id;

  const jobFilePathList = [
    ...(jobFileList?.[FILE_TYPES.finalQaAttachment]?.map(({ path }) => path) ||
      []),
  ];

  const currentOrder =
    useSelector((state) => state?.orders?.orders?.data?.[orderId]) ?? {};
  const clientName = useSelector((state) => state?.auth?.user?.name) ?? '';

  const curretnPartTypePosition =
    currentOrder?.job_details?.find((item) => +item.job_id === +jobId)
      ?.part_type_id || null;

  const isDoneStatus =
    currentOrder?.job_details?.find((item) => +item?.job_id === +jobId)
      ?.status === ORDER_STATUS.DONE;

  const currentPart = !curretnPartTypePosition
    ? {}
    : currentOrder?.positions?.find(
        (item) => item.part_type.id === curretnPartTypePosition
      )?.part_type || {};

  const title = useMemo(() => {
    if (isLoadingWorklogs) {
      return '';
    }

    return `${currentPart?.name} ${currentPart?.number}-Revision-${currentPart?.revision}`;
  }, [
    isLoadingWorklogs,
    currentPart.name,
    currentPart.number,
    currentPart.revision,
  ]);

  const titleNotes = `Notes for ${clientName}: ${currentPart?.name}`;

  const historyValue = currentJobHistory?.phases?.length
    ? currentJobHistory.phases.map(({ phase, timestamp }, index) => ({
        name: phase,
        date: timestamp,
        active: index === currentJobHistory.current_phase,
      }))
    : [];

  useEffect(() => {
    dispatch(worklogsActions.getJobHistory.start(jobId));
  }, [dispatch, jobId, orderId]);

  useEffect(() => {
    if (orderId) {
      dispatch(ordersActions.getOrder.start(orderId));
    }
  }, [orderId, dispatch]);

  useEffect(() => {
    if (jobId) {
      dispatch(jobsActions.getFileInterprocessQA.start({ id: jobId }));
    }
  }, [dispatch, jobId]);

  useEffect(() => {
    dispatch(jobsActions.clearJobFileList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <CenteredColumnLayout>
      <HeaderContent
        title={title}
        backBtn={
          <ButtonBack
            isCheckModal={false}
            onClick={() => {
              dispatch(ordersActions.changeIsScrollJob(true));
              NavigationService.back(true);
            }}
          />
        }
        isActive
        componentActive={() => (
          <ButtonNotesWrapper jobId={jobId} title={titleNotes} />
        )}
      />
      <div>
        <OrderHistory
          historyValue={historyValue}
          isLoading={isLoadingWorklogs}
          isBackgroundColorGrayLoader
          isDoneStatus={isDoneStatus}
          jobFilePathList={jobFilePathList}
        />
      </div>
    </CenteredColumnLayout>
  );
};

export default OrderDetailsHistoryPage;
