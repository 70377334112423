import React, { useEffect, useRef, useState } from 'react';
import { string, bool, func } from 'prop-types';
import Text from 'componentsShared/Text/Text';
import { ArrowDropDownCircleIcon } from 'assets/icons';
import cn from 'classnames';
import st from './HeaderContentTitle.module.scss';

HeaderContentTitle.propTypes = {
  title: string,
  flexGrowNone: bool,
  classNameText: string,
  flagTitleTooltip: bool,
  setFlagTitleTooltip: func,
  triggerTitleTooltip: bool,
};

function HeaderContentTitle({
  title = '',
  flexGrowNone = false,
  classNameText,
  classNameHolderIcon,
  flagTitleTooltip = false,
  setFlagTitleTooltip = () => {},
  triggerTitleTooltip = false,
}) {
  const holderTitleRef = useRef(null);
  const titleRef = useRef(null);
  const [isHideTitle, setIsHideTitle] = useState(false);
  const [showOpenTitle, setShowOpenTitle] = useState(false);

  const handleOpenModal = (e) => {
    e.stopPropagation();
    setShowOpenTitle(true);
  };

  const handleCloseModal = (e) => {
    e.stopPropagation();
    setShowOpenTitle(false);
  };

  useEffect(() => {
    if (
      title &&
      titleRef.current?.offsetWidth >= holderTitleRef.current?.offsetWidth
    ) {
      setIsHideTitle(true);
    } else {
      setIsHideTitle(false);
    }
  }, [title, flagTitleTooltip]);

  useEffect(() => {
    if (triggerTitleTooltip) {
      setFlagTitleTooltip(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerTitleTooltip]);

  return (
    <>
      <div
        className={cn(st.headerHolder, {
          [st.flexGrowNone]: flexGrowNone,
        })}
        ref={holderTitleRef}>
        <Text
          className={cn(st.headerTitle, classNameText)}
          currentRef={titleRef}>
          {title}
        </Text>
        {showOpenTitle && (
          <>
            <div
              className={st.holderModal}
              style={{
                width: holderTitleRef.current?.getBoundingClientRect()?.width
                  ? holderTitleRef.current?.getBoundingClientRect()?.width + 40
                  : 0,
                top:
                  holderTitleRef.current?.getBoundingClientRect()?.top > 0
                    ? holderTitleRef.current?.getBoundingClientRect()?.top - 16
                    : 0,
                left:
                  holderTitleRef.current?.getBoundingClientRect()?.left > 0
                    ? holderTitleRef.current?.getBoundingClientRect()?.left - 16
                    : 0,
              }}>
              <Text className={cn(st.headerTitle, classNameText)}>{title}</Text>
            </div>
            <div className={st.hiddenWrapper} onClick={handleCloseModal} />
          </>
        )}
      </div>
      {isHideTitle && (
        <div
          className={cn(st.containerIcon, classNameHolderIcon)}
          onClick={handleOpenModal}>
          <ArrowDropDownCircleIcon className={st.arrowIcon} />
        </div>
      )}
    </>
  );
}

export default HeaderContentTitle;
