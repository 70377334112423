// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JobsIssueDetails_container__2dbp2 {\n  display: flex;\n  flex-wrap: wrap;\n  width: 100%;\n  grid-column-gap: 32px;\n  column-gap: 32px;\n  grid-row-gap: 16px;\n  row-gap: 16px;\n  margin-top: 16px; }\n\n.JobsIssueDetails_holderItem__32mB1 {\n  width: calc(50% - 16px);\n  display: flex;\n  flex-direction: column;\n  grid-column-gap: 8px;\n  column-gap: 8px;\n  justify-content: space-between; }\n", "",{"version":3,"sources":["webpack://src/components/Jobs/JobsIssueDetails/JobsIssueDetails.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,eAAe;EACf,WAAW;EACX,qBAAgB;EAAhB,gBAAgB;EAChB,kBAAa;EAAb,aAAa;EACb,gBAAgB,EAAA;;AAGlB;EACE,uBAAuB;EACvB,aAAa;EACb,sBAAsB;EACtB,oBAAe;EAAf,eAAe;EACf,8BAA8B,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.container {\n  display: flex;\n  flex-wrap: wrap;\n  width: 100%;\n  column-gap: 32px;\n  row-gap: 16px;\n  margin-top: 16px;\n}\n\n.holderItem {\n  width: calc(50% - 16px);\n  display: flex;\n  flex-direction: column;\n  column-gap: 8px;\n  justify-content: space-between;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "JobsIssueDetails_container__2dbp2",
	"holderItem": "JobsIssueDetails_holderItem__32mB1"
};
export default ___CSS_LOADER_EXPORT___;
