import React from 'react';
import HeaderNav from 'components/HeaderNav/HeaderNav';
import { Button } from 'react-bootstrap';
import { VARIANT_OUTLINE_UI } from 'constants/ui';
import { List as ListIconBootstrap } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import OffcanvasButton from 'componentsShared/OffcanvasButton/OffcanvasButton';
import NavigationService from 'services/navigation/NavigationService';
import { ROUTES } from 'router/routes';
import st from './HeaderBurgerPhone.module.scss';

function HeaderBurgerPhone() {
  const userName = useSelector((state) => state?.auth?.user?.name) ?? '';

  const handleLogout = () => {
    NavigationService.replacePath(ROUTES.signOut.path);
  };

  return (
    <OffcanvasButton
      backdrop={false}
      width={460}
      title={userName}
      footerContent={() => (
        <div className={st.buttonGroup}>
          <Button variant={VARIANT_OUTLINE_UI.secondary} onClick={handleLogout}>
            Logout
          </Button>
        </div>
      )}
      bodyContent={() => <HeaderNav />}
      buttonRenderer={({ onClick }) => (
        <div className={st.holderBurgerIcon} onClick={onClick}>
          <ListIconBootstrap />
        </div>
      )}
    />
  );
}

export default HeaderBurgerPhone;
