import { createAction } from '@reduxjs/toolkit';
import { getRequestAction } from 'helpers/functions';

const ACTION_PREFIX = 'controlValues';

export const getControlValueList = getRequestAction(
  `${ACTION_PREFIX}/getControlValueList`
);

export const createControlValue = getRequestAction(
  `${ACTION_PREFIX}/createControlValue`
);

export const changeControlValue = getRequestAction(
  `${ACTION_PREFIX}/changeControlValue`
);

export const removeControlValue = getRequestAction(
  `${ACTION_PREFIX}/removeControlValue`
);

export const getControlValues = getRequestAction(
  `${ACTION_PREFIX}/getControlValues`
);

export const changePage = createAction(`${ACTION_PREFIX}/changePage`);

export const getBatchControlValueList = getRequestAction(
  `${ACTION_PREFIX}/getBatchControlValueList`
);
