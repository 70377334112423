import React, { useEffect, useState } from 'react';
import ModalButton from 'componentsShared/ModalButton/ModalButton';
import { VARIANT_OUTLINE_UI, VARIANT_UI } from 'constants/ui';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Offcanvas from 'componentsShared/Offcanvas/Offcanvas';
import Text from 'componentsShared/Text/Text';
import { useDispatch } from 'react-redux';
import * as controlValuesActions from 'store/controlValues/actions';
import OffcanvasEditControlValuesContent from 'components/Offcanvas/OffcanvasEditControlValuesContent/OffcanvasEditControlValuesContent';
import { number, string, oneOfType, func, object, bool } from 'prop-types';
import st from './OffcanvasEditControlValues.module.scss';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
});

const getValidControlValueRequest = (dataForm, dataConstructor, id) => {
  const headers = dataConstructor.map(({ value }) => value);

  return {
    name: dataForm.name,
    id,
    headers,
  };
};

OffcanvasEditControlValues.propTypes = {
  selectControlValue: object,
  showOffcanvas: bool,
  setShowOffcanvas: func,
  selectControlValueId: oneOfType([number, string]),
};

function OffcanvasEditControlValues({
  selectControlValue,
  showOffcanvas,
  setShowOffcanvas,
  selectControlValueId,
}) {
  const dispatch = useDispatch();
  const [dataConstructor, setDataConstructor] = useState(
    selectControlValue?.value || []
  );

  const [isModifiedData, setIsModifiedData] = useState(false);

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };
  const { register, handleSubmit, formState, setValue } = useForm(formOptions);
  const { errors, isDirty: isDirtyForm } = formState;

  const isDirty = isDirtyForm || isModifiedData;
  const selectedControlValueName = selectControlValue?.name || '';

  const handleRemoveEmployee = (onHideModal) => {
    dispatch(
      controlValuesActions.removeControlValue.start(selectControlValueId)
    );
    onHideModal();
  };

  const hendleChangeDataConstructor = (value) => {
    setDataConstructor(value);
    setIsModifiedData(true);
  };

  const onSubmit = (data, onHideModal) => {
    const currentValue = getValidControlValueRequest(
      data,
      dataConstructor,
      selectControlValueId
    );

    dispatch(
      controlValuesActions.changeControlValue.start({
        data: currentValue,
        id: selectControlValueId,
      })
    );
    setShowOffcanvas(false);
    onHideModal();
  };

  useEffect(() => {
    if (selectedControlValueName) {
      setValue('name', selectedControlValueName);
      setDataConstructor(selectControlValue?.headers || []);
    }
  }, [selectedControlValueName, setValue, selectControlValue]);

  return (
    <Offcanvas
      backdrop={false}
      onHide={() => {
        setShowOffcanvas(false);
      }}
      width={460}
      show={showOffcanvas}
      setShow={setShowOffcanvas}
      title={`Edit Bundle: ${selectedControlValueName}`}
      footerContent={({ onHide }) => (
        <div className={st.buttonGroup}>
          <Button
            variant={VARIANT_UI.primary}
            onClick={handleSubmit((value) => onSubmit(value, onHide))}
            disabled={!isDirty}>
            Save Changes
          </Button>
          <ModalButton
            title='Delete Control Value'
            titleAcceptBtn='Delete'
            heightBody={110}
            onAcceptBtn={() => handleRemoveEmployee(onHide)}
            variantAcceptBtn={VARIANT_UI.danger}
            bodyContent={() => (
              <Text variant='normal'>
                Would you like to delete {selectedControlValueName}?
              </Text>
            )}
            buttonRenderer={({ onClick: openModal }) => (
              <Button
                variant={VARIANT_OUTLINE_UI.secondary}
                onClick={openModal}>
                Delete Bundle
              </Button>
            )}
          />
        </div>
      )}>
      <OffcanvasEditControlValuesContent
        register={register}
        errors={errors}
        onSubmit={handleSubmit(onSubmit)}
        dataConstructor={dataConstructor}
        setDataConstructor={hendleChangeDataConstructor}
      />
    </Offcanvas>
  );
}

export default OffcanvasEditControlValues;
