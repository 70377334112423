import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

export const initialState = {
  worklogs: {
    data: {},
    sortIds: [],
    jobId: null,
    isLoading: false,
  },
};

export const worklogsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.getWorklog.start, (state) => {
      return {
        ...state,
        worklogs: { ...state.worklogs, isLoading: true },
      };
    })
    .addCase(actions.getWorklog.success, (state, action) => {
      const currentValue = action.payload;

      return {
        ...state,
        worklogs: {
          ...state.worklogs,
          ...currentValue,
          isLoading: false,
        },
      };
    })
    .addCase(actions.getWorklog.failure, (state) => ({
      ...state,
      worklogs: { ...state.worklogs, isLoading: false },
    }))
    .addCase(actions.getJobHistory.start, (state) => {
      return {
        ...state,
        worklogs: { ...state.worklogs, isLoading: true },
      };
    })
    .addCase(actions.getJobHistory.success, (state, action) => {
      const currentValue = action.payload;

      return {
        ...state,
        worklogs: {
          ...state.worklogs,
          ...currentValue,
          isLoading: false,
        },
      };
    })
    .addCase(actions.getJobHistory.failure, (state) => ({
      ...state,
      worklogs: { ...state.worklogs, isLoading: false },
    }));
});
