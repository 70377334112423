// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OffcanvasCompanyEditContent_container__2cnvA {\n  display: flex;\n  flex-direction: column;\n  grid-row-gap: 16px;\n  row-gap: 16px; }\n", "",{"version":3,"sources":["webpack://src/components/Offcanvas/OffcanvasCompanyEditContent/OffcanvasCompanyEditContent.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,kBAAa;EAAb,aAAa,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.container {\n  display: flex;\n  flex-direction: column;\n  row-gap: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "OffcanvasCompanyEditContent_container__2cnvA"
};
export default ___CSS_LOADER_EXPORT___;
