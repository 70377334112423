import React, { useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { bool, func, string, number } from 'prop-types';
import st from './OffcanvasButton.module.scss';

OffcanvasButton.propTypes = {
  placement: string,
  closeButton: bool,
  title: string,
  onHide: func,
  onClose: func,
  width: number,
  buttonRenderer: func.isRequired,
  bodyContent: func.isRequired,
  headerContent: func,
  footerContent: func,
  backdrop: bool,
};

function OffcanvasButton({
  placement = 'end',
  buttonRenderer = () => {},
  closeButton = true,
  title = '',
  onClose = () => {},
  onHide = () => {},
  bodyContent = () => {},
  width = 400,
  headerContent,
  footerContent,
  backdrop = true,
}) {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleHide = () => {
    setShow(false);
    onHide();
  };

  const handleClose = () => {
    setShow(false);
    onClose();
  };

  return (
    <>
      {buttonRenderer({ onClick: handleShow })}
      <Offcanvas
        show={show}
        onHide={handleHide}
        placement={placement}
        backdrop={backdrop}
        style={{ width: `${width}px` }}>
        <Offcanvas.Header closeButton={closeButton} className={st.headerHolder}>
          <Offcanvas.Title>
            {typeof headerContent === 'function' ? headerContent() : title}
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {bodyContent({ onClose: handleClose, onHide: handleHide })}
        </Offcanvas.Body>
        {typeof footerContent === 'function' && (
          <div className={st.footerContainer}>
            {footerContent({ onClose: handleClose, onHide: handleHide })}
          </div>
        )}
      </Offcanvas>
      {show && !backdrop && <div className={st.offcanvasBackdrop} />}
    </>
  );
}

export default OffcanvasButton;
