// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JobDetailsInfoNCR_holderJobDetailsList__3-8c3 {\n  display: flex;\n  flex-wrap: wrap;\n  width: 100%;\n  grid-column-gap: 32px;\n  column-gap: 32px;\n  grid-row-gap: 16px;\n  row-gap: 16px;\n  margin-top: 16px; }\n\n.JobDetailsInfoNCR_holderJobDetailsItem__2kXuW {\n  max-width: 33%;\n  min-width: 200px; }\n", "",{"version":3,"sources":["webpack://src/components/Jobs/JobDetailsInfoNCR/JobDetailsInfoNCR.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,eAAe;EACf,WAAW;EACX,qBAAgB;EAAhB,gBAAgB;EAChB,kBAAa;EAAb,aAAa;EACb,gBAAgB,EAAA;;AAGlB;EACE,cAAc;EACd,gBAAgB,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.holderJobDetailsList {\n  display: flex;\n  flex-wrap: wrap;\n  width: 100%;\n  column-gap: 32px;\n  row-gap: 16px;\n  margin-top: 16px;\n}\n\n.holderJobDetailsItem {\n  max-width: 33%;\n  min-width: 200px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"holderJobDetailsList": "JobDetailsInfoNCR_holderJobDetailsList__3-8c3",
	"holderJobDetailsItem": "JobDetailsInfoNCR_holderJobDetailsItem__2kXuW"
};
export default ___CSS_LOADER_EXPORT___;
