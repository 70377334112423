import React from 'react';
import EditControlValuesBundleForm from 'components/Form/EditControlValuesBundleForm/EditControlValuesBundleForm';
import ControlValuesConstructor from 'components/ControlValue/ControlValuesConstructor/ControlValuesConstructor';
import { func, array, object } from 'prop-types';
import st from './OffcanvasEditControlValuesContent.module.scss';

OffcanvasEditControlValuesContent.propTypes = {
  register: func,
  errors: object,
  onSubmit: func,
  dataConstructor: array,
  setDataConstructor: func,
};

function OffcanvasEditControlValuesContent({
  register,
  errors,
  onSubmit,
  dataConstructor,
  setDataConstructor,
}) {
  return (
    <div>
      <EditControlValuesBundleForm
        onSubmit={onSubmit}
        register={register}
        errors={errors}
      />
      <div className={st.holderControlValuesConstructor}>
        <ControlValuesConstructor
          dataConstructor={dataConstructor}
          setDataConstructor={setDataConstructor}
        />
      </div>
    </div>
  );
}

export default OffcanvasEditControlValuesContent;
