import { getRequestAction } from 'helpers/functions';
import { createAction } from '@reduxjs/toolkit';

const ACTION_PREFIX = 'employees';

export const getEmployeeList = getRequestAction(
  `${ACTION_PREFIX}/getEmployeeList`
);

export const createEmployee = getRequestAction(
  `${ACTION_PREFIX}/createEmployee`
);

export const changeEmployee = getRequestAction(
  `${ACTION_PREFIX}/changeEmployee`
);

export const removeEmployee = getRequestAction(
  `${ACTION_PREFIX}/removeEmployee`
);

export const getUserRoles = getRequestAction(`${ACTION_PREFIX}/getUserRoles`);

export const changeFilterSearch = createAction(
  `${ACTION_PREFIX}/changeFilterSearch`
);

export const changePage = createAction(`${ACTION_PREFIX}/changePage`);
