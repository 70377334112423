import React, { useRef } from 'react';
import { number, func, string, bool } from 'prop-types';
import Text from 'componentsShared/Text/Text';
import JobStatus from 'components/Jobs/JobStatus/JobStatus';
import cn from 'classnames';
import {
  READY_TO_SHIP_PHASE,
  READY_TO_SHIP_TEXT_STATUS,
} from 'constants/order';
import Status from 'componentsShared/Status/Status';
import { VARIANT_UI } from 'constants/ui';
import st from './OrderItemJobCard.module.scss';

OrderItemJobCard.propTypes = {
  partCardProgress: number,
  stepName: string,
  operationName: string,
  handleClickCard: func,
  isCustomer: bool,
  phaseName: string,
};

function OrderItemJobCard({
  jobId = null,
  partCardProgress = 0,
  stepName = '',
  operationName = '',
  handleClickCard = () => {},
  status = '',
  isCustomer = false,
  isNotMatchinJob = false,
  phaseName = '',
}) {
  // const isLoadingJobQR =
  //   useSelector((state) => state?.jobs?.jobsQr?.isLoading) ?? false;
  const containerRef = useRef(null);
  const showStatus = status && !isCustomer;
  const readyToShipStatus =
    isCustomer && phaseName === READY_TO_SHIP_PHASE
      ? READY_TO_SHIP_TEXT_STATUS
      : '';

  // const showQR = (e) => {
  //   e.stopPropagation();
  //   if (jobId) {
  //     dispatch(jobsActions.getJobQr.start(jobId));
  //   }
  // };

  return (
    <div className={st.container} id={`job-card-${jobId}`}>
      <div
        key={`job-card-${jobId}`}
        className={cn(st.partCardItem)}
        onClick={() => handleClickCard(jobId)}>
        <div className={st.containerInfo} ref={containerRef}>
          <div className={st.partCardItemHolderText}>
            <div className={st.wrapperTextJobId}>
              <Text variant='normal' className={st.holderTextJobId}>
                <Text variant='normal' className={st.textJobId}>
                  #
                </Text>{' '}
                {jobId}
              </Text>
            </div>

            {stepName && operationName && (
              <div>
                <Text variant='normal' className={st.textJobInfo}>
                  {stepName} / {operationName}
                </Text>
              </div>
            )}
          </div>
          <div className={st.holderAction}>
            {/* {!isCustomer && (
            <Button
              className={st.buttonBottomRightQR}
              onClick={showQR}
              variant={VARIANT_OUTLINE_UI.primary}
              size='sm'
              disabled={isLoadingJobQR}>
              <QRIcon className={st.QRIcon} />
            </Button>
          )} */}
            {showStatus && <JobStatus status={status} />}
            {readyToShipStatus && false && (
              <Status
                title={readyToShipStatus}
                variant={VARIANT_UI.primary}
                size='small'
                className={st.readyToShipStatus}
              />
            )}
          </div>
        </div>
        <div
          className={st.partCardProgress}
          style={{ width: `${partCardProgress}%` }}
        />
      </div>
      {isNotMatchinJob && <div className={st.holderNotMatchinJob} />}
    </div>
  );
}

export default OrderItemJobCard;
