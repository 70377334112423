import React, { useEffect, useMemo, useState } from 'react';
import AdminHeaderLayout from 'layouts/AdminHeaderLayout/AdminHeaderLayout';
import { useSelector, useDispatch } from 'react-redux';
import * as ncrActions from 'store/ncr/actions';
import { EditIcon } from 'assets/icons';
import { NO_INFORMATION_FOUND_SEARCH_TEXT } from 'constants/ui';
import ListComponent from 'componentsShared/ListComponent/ListComponent';
import { ACTION_COLUMN } from 'constants/table';
import OffcanvasNCREdit from 'components/Offcanvas/OffcanvasNCREdit/OffcanvasNCREdit';
import OffcanvasNCRCreate from 'components/Offcanvas/OffcanvasNCRCreate/OffcanvasNCRCreate';
import st from './AdminNCRPage.module.scss';

const columnTable = [
  { dataKey: 'order', width: 120, title: 'Order' },
  {
    dataKey: 'label',
    width: 300,
    title: 'Field Label',
    truncated: true,
    flexGrow: true,
  },
  { dataKey: ACTION_COLUMN, width: 100, title: '' },
];

function AdminNCRPage() {
  const dispatch = useDispatch();

  const [showOffcanvasNewNCR, setShowOffcanvasNewNCR] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [selectNCRId, setSelectNCRId] = useState();

  const isLoadingNCR =
    useSelector((state) => state?.ncr?.ncr?.isLoading) ?? false;
  const isLoadingCreateNCR =
    useSelector((state) => state?.ncr?.ncr?.isLoadingCreate) ?? false;
  const isLoadingChangeNCR =
    useSelector((state) => state?.ncr?.ncr?.isLoadingChange) ?? false;
  const ncrList = useSelector((state) => state?.ncr?.ncr?.dataHeaders) ?? {};

  const ncrListArray = Object.values(ncrList);

  const dataTable = ncrListArray.map((ncr, index) => ({
    id: ncr.id,
    order: `Field ${index + 1}`,
    label: ncr.label,
  }));

  const selectNCRIndex = useMemo(() => {
    if (!selectNCRId && dataTable.length === 0) {
      return null;
    }

    return dataTable.findIndex((item) => item.id === selectNCRId);
  }, [dataTable, selectNCRId]);

  const actionsTable = [
    {
      icon: <EditIcon className={st.editIcon} />,
      onClick: (rowData) => {
        setSelectNCRId(rowData.id);
        setShowOffcanvas(true);
      },
    },
  ];

  const loadNCRList = () => {
    dispatch(ncrActions.getNCRList.start());
  };

  useEffect(() => {
    loadNCRList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <AdminHeaderLayout
      title='Non-Conformance Report fields'
      classNameContentHolder={st.layoutContentHolder}
      isSearch={false}
      inviteButton={() => (
        <OffcanvasNCRCreate
          showOffcanvasNewNCR={showOffcanvasNewNCR}
          setShowOffcanvasNewNCR={setShowOffcanvasNewNCR}
        />
      )}>
      <>
        <ListComponent
          data={dataTable}
          columns={columnTable}
          actions={actionsTable}
          classNameRowListWrapper={st.rowListWrapper}
          classNameRowListHolder={st.rowListHolder}
          isLoading={isLoadingNCR || isLoadingCreateNCR || isLoadingChangeNCR}
          placeholderText={NO_INFORMATION_FOUND_SEARCH_TEXT}
        />

        {showOffcanvas && (
          <OffcanvasNCREdit
            selectNCRIndex={selectNCRIndex}
            selectedNCR={ncrList?.[selectNCRId]}
            selectNCRId={selectNCRId}
            showOffcanvas={showOffcanvas}
            setShowOffcanvas={setShowOffcanvas}
            ncrList={ncrList}
          />
        )}
      </>
    </AdminHeaderLayout>
  );
}

export default AdminNCRPage;
