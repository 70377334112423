import { store } from 'store/store';

const TemplateService = {
  getCurrentState() {
    return store.getState();
  },
  isDirtyTemplate(templateId) {
    const currentState = this.getCurrentState();
    const currentTemplate =
      currentState.templates?.templates?.data?.[templateId] ?? {};
    const { id, name, ...phases } = currentTemplate;
    const phasesArray = Object.values(phases);

    return phasesArray.some((phaseItem) => {
      return phaseItem?.steps?.some((stepIem) =>
        stepIem.operations.some(({ control }) => control)
      );
    });
  },
  isEmptyTemplate() {
    const currentState = this.getCurrentState();
    const templateIds = currentState.templates?.templates?.sortIds ?? [];

    return templateIds.length === 0;
  },
};

export default TemplateService;
