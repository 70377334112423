import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import { handleRequestError } from 'services/api/apiTools';
import { addShowForPOList, getParsingArrayToObj } from 'helpers/functions';
import NavigationService from 'services/navigation/NavigationService';
import NotificationsService from 'services/notifications/NotificationsService';
import { getRoutePath } from 'helpers/path';
import { ROUTES } from 'router/routes';
import { PAGE_SIZE, VARIANT_UI } from 'constants/ui';
import { MODULE_TYPES } from 'constants/files';
import ApiService from 'services/api';
import AuthService from 'services/auth/AuthService';
import * as actions from './actions';

export function* getRFQListSaga(action) {
  const { params, showLoader = true, isPagination } = action.payload || {};
  const isClient = AuthService.isClient();

  const filterSearch = yield select((state) =>
    state?.rfqs?.fetchParams?.search?.trim()
  ) ?? '';
  const page = yield select(
    (state) => state?.rfqs?.fetchParams?.pagination?.page
  ) ?? 0;
  const size = yield select(
    (state) => state?.rfqs?.fetchParams?.pagination?.size
  ) ?? PAGE_SIZE;

  const filter = {
    customer_id: params?.customerId || null,
    filter: filterSearch,
    page: isPagination ? page : null,
    size: isPagination ? size : null,
  };

  let requestValue;

  const currentApi = isClient
    ? ApiService.getClientRFQList
    : ApiService.getRFQList;

  try {
    requestValue = yield call(currentApi, filter);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getRFQList.failure());
    return;
  }

  const validData = addShowForPOList(requestValue.items);
  const rfqsObj = getParsingArrayToObj(validData);
  const rfqsIds = Object.keys(rfqsObj);

  yield put(
    actions.getRFQList.success({
      fetchedValue: rfqsObj,
      ids: rfqsIds,
      showLoader,
      page: requestValue.page,
      count: requestValue.count,
    })
  );
}

export function* removeRFQSaga(action) {
  const isClient = AuthService.isClient();

  const params = action.payload;

  const currentApi = isClient
    ? ApiService.removeClientRFQ
    : ApiService.removeRFQ;

  try {
    yield call(currentApi, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.removeRFQ.failure());
    return;
  }

  const currentCustomerId = yield select(
    (state) => state?.customers?.currentCustomerId
  ) ?? null;

  if (currentCustomerId || isClient) {
    yield put(
      actions.getRFQList.start({
        params: { customerId: currentCustomerId },
        showLoader: false,
      })
    );
  }

  yield put(actions.removeRFQ.success());
}

export function* createRFQSaga(action) {
  const isClient = AuthService.isClient();

  const params = action.payload;
  let requestValue;

  const currentApi = isClient
    ? ApiService.createClientRFQ
    : ApiService.createRFQ;

  try {
    requestValue = yield call(currentApi, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.createRFQ.failure());
    return;
  }

  NavigationService.replacePath(
    getRoutePath(ROUTES.rfqDetails.path, { id: requestValue.id })
  );

  yield put(actions.createRFQ.success(requestValue.id));
}

export function* submitRFQSaga(action) {
  const isClient = AuthService.isClient();
  const params = action.payload;

  const currentUpdateApi = isClient
    ? ApiService.updateClientRFQ
    : ApiService.updateRFQ;

  const currentSubmitApi = isClient
    ? ApiService.submitClientRFQ
    : ApiService.submitRFQ;

  try {
    yield call(currentUpdateApi, params);
    yield call(currentSubmitApi, params.id);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.submitRFQ.failure());
    return;
  }

  yield put(actions.submitRFQ.success());
}

export function* submitNewRFQSaga(action) {
  const isClient = AuthService.isClient();

  const params = action.payload;
  let requestCreateValue;

  const currentCreateApi = isClient
    ? ApiService.createClientRFQ
    : ApiService.createRFQ;

  const currentSubmitApi = isClient
    ? ApiService.submitClientRFQ
    : ApiService.submitRFQ;

  try {
    requestCreateValue = yield call(currentCreateApi, params);
    yield call(currentSubmitApi, requestCreateValue.id);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.submitNewRFQ.failure());
    return;
  }

  yield put(actions.submitNewRFQ.success(requestCreateValue.id));
}

export function* getRFQSaga(action) {
  const isClient = AuthService.isClient();

  const params = action.payload;
  let requestValue;

  const currentApi = isClient ? ApiService.getClientRFQ : ApiService.getRFQ;

  try {
    requestValue = yield call(currentApi, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getRFQ.failure());
    return;
  }

  yield put(
    actions.getRFQ.success({
      data: { [requestValue.id]: requestValue },
      sortIds: [requestValue.id],
    })
  );
}

export function* updateRFQSaga(actionOrArgument) {
  const isClient = AuthService.isClient();

  const params = actionOrArgument.payload ?? actionOrArgument;
  let requestValue;

  const currentApi = isClient
    ? ApiService.updateClientRFQ
    : ApiService.updateRFQ;

  try {
    requestValue = yield call(currentApi, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.updateRFQ.failure());
    return;
  }

  yield put(
    actions.updateRFQ.success({
      data: { [requestValue.id]: requestValue },
    })
  );

  NotificationsService.throwNotification({
    title: 'Successfully',
    text: 'RFQ has been updated',
    variant: VARIANT_UI.success,
  });
}

export function* addFileSaga(action) {
  const { file, id, dataRFQ } = action.payload;
  const isClient = AuthService.isClient();

  const currentApi = isClient
    ? ApiService.addClientFileType
    : ApiService.addFileType;

  let requestValue;
  try {
    requestValue = yield call(currentApi, {
      data: file,
      id,
      object: MODULE_TYPES.rfqs,
    });

    yield call(getFilesSaga, { id });

    const uniqueFiles = [...new Set([...dataRFQ.files, requestValue])];

    yield call(updateRFQSaga, {
      data: { ...dataRFQ, files: uniqueFiles },
      id,
    });
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.addFile.failure());
    return;
  }

  yield put(actions.addFile.success());
}

export function* getFilesSaga(actionOrArgument) {
  const { id } = actionOrArgument.payload ?? actionOrArgument;
  const isClient = AuthService.isClient();

  const currentApi = isClient
    ? ApiService.getClientFileType
    : ApiService.getFileType;

  let requestValue;
  try {
    requestValue = yield call(currentApi, {
      id,
      object: MODULE_TYPES.rfqs,
    });
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getFiles.failure());
    return;
  }

  yield put(actions.getFiles.success({ data: { [id]: requestValue } }));
}

export function* removeFilesSaga(action) {
  const { id, path, dataRFQ } = action.payload;
  const isClient = AuthService.isClient();

  const currentApi = isClient
    ? ApiService.removeClientFileType
    : ApiService.removeFileType;

  try {
    yield call(currentApi, {
      path,
    });

    yield call(getFilesSaga, { id });

    yield call(updateRFQSaga, {
      data: { ...dataRFQ },
      id,
    });
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.removeFiles.failure());
    return;
  }

  yield put(actions.removeFiles.success());
}

export function* rfqSagas() {
  yield all([
    yield takeLatest(actions.getRFQList.start, getRFQListSaga),
    yield takeLatest(actions.removeRFQ.start, removeRFQSaga),
    yield takeLatest(actions.createRFQ.start, createRFQSaga),
    yield takeLatest(actions.submitRFQ.start, submitRFQSaga),
    yield takeLatest(actions.submitNewRFQ.start, submitNewRFQSaga),
    yield takeLatest(actions.getRFQ.start, getRFQSaga),
    yield takeLatest(actions.updateRFQ.start, updateRFQSaga),
    yield takeLatest(actions.addFile.start, addFileSaga),
    yield takeLatest(actions.getFiles.start, getFilesSaga),
    yield takeLatest(actions.removeFiles.start, removeFilesSaga),
  ]);
}
