import React, { useRef } from 'react';
import DropdownButton from 'componentsShared/DropdownButton/DropdownButton';
import Text from 'componentsShared/Text/Text';
import { VARIANT_OUTLINE_UI } from 'constants/ui';
import { useSelector } from 'react-redux';
import Loader from 'componentsShared/Loader/Loader';
import { v4 as uuidv4 } from 'uuid';
import st from './MultiDropDownContent.module.scss';

function MultiDropDownContent({
  phaseArray = [],
  handleSelectStepIndex = () => {},
  type = '',
  onSelectDropdown = () => {},
  firstJobId = '',
}) {
  const popoverBodyRef = useRef(null);
  const currentJob =
    useSelector((state) => state?.jobs?.jobs?.data?.[firstJobId]) ?? {};

  const isLoadingPhaseList =
    useSelector((state) => state?.travelers?.phaseList?.isLoading) ?? false;

  const handleClickDropDown = () => {
    if (popoverBodyRef?.current?.children) {
      const arrayChild = [...popoverBodyRef.current.children];
      arrayChild.map((item) => item.classList.remove('show'));
    }
  };

  const renderDropdownStepList = (currentValue) => {
    if (currentValue.length > 0) {
      return currentValue.map((value) => ({
        content: () => (
          <Text variant='normal' className={st.textRemove}>
            {value.step}
          </Text>
        ),
        onClick: () => handleSelectStepIndex(type, value.index),
      }));
    }
    return [];
  };

  const renderPhaseList = () => {
    return phaseArray.map((item) => {
      const dropdownStepList = renderDropdownStepList(item);

      const phaseKey = item?.[0]?.phase;
      const phase =
        currentJob?.phases?.find(({ name }) => name === phaseKey)?.human_name ||
        '';

      if (isLoadingPhaseList) {
        return <Loader isVisible key={uuidv4()} />;
      }

      return (
        <DropdownButton
          key={uuidv4()}
          onClick={handleClickDropDown}
          className={st.dropdownButton}
          list={dropdownStepList}
          btnVariant={VARIANT_OUTLINE_UI.primary}
          direction='end'
          title={phase}
          onSelect={onSelectDropdown}
        />
      );
    });
  };

  return <>{phaseArray.length > 0 && renderPhaseList()}</>;
}

export default MultiDropDownContent;
