import React from 'react';
import Text from 'componentsShared/Text/Text';
import { Form } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { oneOfType, string, func, number, bool } from 'prop-types';
import cn from 'classnames';
import st from './ControlValuesStep.module.scss';

ControlValuesStep.propTypes = {
  controlValues: oneOfType([number, string]),
  stepIndex: number,
  isStatusActual: bool,
  informalQa: bool,
  handleUpdateInformalQa: func,
};

function ControlValuesStep({
  controlValues: controlValuesProps,
  stepIndex,
  isStatusActual = false,
  informalQa = false,
  handleUpdateInformalQa = () => {},
  isDisabledControl = false,
}) {
  const isShowControlValuesLabel =
    (controlValuesProps && isStatusActual) || !isStatusActual;

  const radioId = uuidv4();

  const handleChangeInformalQa = (value) => {
    handleUpdateInformalQa(stepIndex, value);
  };

  if (isDisabledControl) {
    return (
      <div className={st.container}>
        <div className={st.dividingLine} />
        <div className={st.holderDispalyQualityInspection}>
          <Text variant='normal2' bold className={st.qualityInspectionText}>
            QUALITY INSPECTION
          </Text>
          <Form.Check
            inline
            type='radio'
            label={informalQa ? 'Informal Check' : 'Formal Check'}
            name={radioId}
            checked
          />
        </div>
      </div>
    );
  }

  return (
    <div
      className={cn(st.container, {
        [st.marginBottom]: !isShowControlValuesLabel && !isStatusActual,
      })}>
      <div className={st.dividingLine} />
      <Text variant='normal2' bold className={st.qualityInspectionText}>
        QUALITY INSPECTION
      </Text>
      {!isStatusActual ? (
        <div>
          <Form.Check
            inline
            type='radio'
            label='Formal Check'
            name={radioId}
            checked={!informalQa}
            onChange={() => handleChangeInformalQa(false)}
          />
          <Form.Check
            inline
            type='radio'
            label='Informal Check'
            name={radioId}
            checked={informalQa}
            onChange={() => handleChangeInformalQa(true)}
          />
        </div>
      ) : (
        <div>
          <Text variant='normal'>
            {informalQa ? 'Informal Check' : 'Formal Check'}
          </Text>
        </div>
      )}
    </div>
  );
}

export default ControlValuesStep;
