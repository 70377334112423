import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { VARIANT_OUTLINE_UI, VARIANT_UI } from 'constants/ui';
import PropTypes from 'prop-types';
import cn from 'classnames';
import st from './ModalButton.module.scss';

ModalButton.propTypes = {
  title: PropTypes.string,
  size: PropTypes.string,
  titleAcceptBtn: PropTypes.string,
  bodyContent: PropTypes.func,
  buttonRenderer: PropTypes.func,
  onAcceptBtn: PropTypes.func,
  onClose: PropTypes.func,
  onHide: PropTypes.func,
  heightBody: PropTypes.number,
  disabledAcceptBtn: PropTypes.bool,
  buttonBottomLeft: PropTypes.func,
  buttonBottomRight: PropTypes.func,
  closeButton: PropTypes.bool,
  backdrop: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  classNameModalBody: PropTypes.string,
  classNameModalContainer: PropTypes.string,
};

export default function ModalButton({
  title = '',
  size = 'md',
  titleAcceptBtn = 'Accept',
  heightBody,
  disabledAcceptBtn = false,
  bodyContent = () => {},
  buttonRenderer = () => {},
  onAcceptBtn = () => {},
  onClose = () => {},
  onHide = () => {},
  buttonBottomLeft,
  buttonBottomRight,
  closeButton = true,
  backdrop = true,
  variantAcceptBtn = VARIANT_UI.primary,
  classNameModalBody,
  classNameModalContainer,
}) {
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => {
    setShow(false);
    onClose();
  };

  const handleHide = () => {
    setShow(false);
    onHide();
  };

  const handleAccept = () => {
    onAcceptBtn();
    handleClose();
  };

  return (
    <>
      {buttonRenderer({ onClick: handleShow })}
      <Modal
        show={show}
        onHide={handleHide}
        size={size}
        centered
        className={cn(st.modalContainer, classNameModalContainer)}
        backdrop={backdrop}>
        <Modal.Header closeButton={closeButton}>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body
          className={cn(st.modalBody, classNameModalBody)}
          style={{ height: heightBody ? `${heightBody}px` : 'auto' }}>
          {bodyContent({ onClick: handleClose, onHide: handleHide })}
        </Modal.Body>
        <Modal.Footer className={st.modalFooter}>
          <div className={st.buttonBottomLeft}>
            {typeof buttonBottomLeft === 'function' &&
              buttonBottomLeft({ onClose: handleClose, onHide: handleHide })}
          </div>
          <div className={st.buttonBottomGroupRigth}>
            {typeof buttonBottomRight === 'function' ? (
              buttonBottomRight({ onClose: handleClose, onHide: handleHide })
            ) : (
              <>
                <Button
                  variant={VARIANT_OUTLINE_UI.secondary}
                  onClick={handleHide}>
                  Cancel
                </Button>
                <Button
                  variant={variantAcceptBtn}
                  onClick={handleAccept}
                  disabled={disabledAcceptBtn}>
                  {titleAcceptBtn}
                </Button>
              </>
            )}
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}
