/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import CenteredColumnLayout from 'layouts/CenteredColumnLayout/CenteredColumnLayout';
import Loader from 'componentsShared/Loader/Loader';
import HeaderContent from 'componentsShared/HeaderContent/HeaderContent';
import * as jobsActions from 'store/jobs/actions';
import { useSelector, useDispatch } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from 'react-router';
import { ROUTES } from 'router/routes';
import * as controlValuesActions from 'store/controlValues/actions';
import NavigationService from 'services/navigation/NavigationService';
import ButtonBack from 'componentsShared/ButtonBack/ButtonBack';
import { getQuerySearchParams } from 'helpers/path';
import JobsBatchOperation from 'components/Jobs/JobsBatchOperation/JobsBatchOperation';
import st from './BatchJobDetailsPage.module.scss';

const BatchJobDetailsPage = () => {
  const location = useLocation();
  const jobIdList = getQuerySearchParams(location.search, 'ids');

  const dispatch = useDispatch();

  const jobData = useSelector((state) => state?.jobs?.jobsBatch?.data) ?? {};
  const CVDataList =
    useSelector((state) => state?.controlValues?.controlValues?.data) ?? {};
  const sortIdsList =
    useSelector((state) => state?.jobs?.jobsBatch?.sortIdsList) ?? [];
  const isLoadingJob =
    useSelector((state) => state?.jobs?.jobs?.isLoading) ?? false;
  const isLoadingRejectJob =
    useSelector((state) => state?.jobs?.jobs?.isLoadingReject) ?? false;
  const isLoadingControlValues =
    useSelector((state) => state?.controlValues?.controlValues?.isLoading) ??
    false;

  const jobListData = sortIdsList.map((item) => {
    const value = jobData?.[item[0]] || {};

    return {
      ...value,
      quantityOperation: value.current_step?.operations?.length || 0,
      currentOperationIndex: value?.current_operation_index,
      isLastOperation:
        +value.current_step?.operations?.length - 1 ===
        +value?.current_operation_index,
      bundleId: value?.current_step?.bundle_id,
      isControlValues:
        value?.current_step?.bundle_idx === value?.current_operation_index,
      currentStep: value.current_step,
      currentOperation: value.current_operation,
      currentOperationInde: value.current_operation_index,
      operationName: value.current_operation?.name,
      operationFileList: value.current_operation?.files,
      ids: item,
      stepName: value?.current_step?.name,
      phaseName: value?.current_step?.phase,
    };
  });

  const handleBackToMyJobs = () => {
    NavigationService.replacePath(ROUTES.jobList.path);
  };

  useEffect(() => {
    dispatch(jobsActions.getReasonRejectJob.start());
  }, [dispatch]);

  useEffect(() => {
    if (sortIdsList.length > 0) {
      dispatch(jobsActions.getBatchJob.start(sortIdsList));
    }
  }, [dispatch, sortIdsList]);

  useEffect(() => {
    const jobHasBundle =
      (jobListData.length > 0 && jobListData.filter((item) => item.bundleId)) ||
      [];
    const isJobHasBundle = jobHasBundle.length > 0;
    const isBundle = jobHasBundle.every(
      ({ bundleId }) => CVDataList?.[bundleId] || false
    );

    if (isJobHasBundle && !isBundle) {
      const bundleIds = jobHasBundle.map((item) => item.bundleId);
      const uniqueBundleIds = [...new Set([...bundleIds])];
      dispatch(
        controlValuesActions.getBatchControlValueList.start(uniqueBundleIds)
      );
    }
  }, [dispatch, CVDataList, jobListData]);

  return (
    <CenteredColumnLayout>
      <HeaderContent
        backBtn={
          <ButtonBack isCheckModal={false} onClick={handleBackToMyJobs} />
        }
        title='Batch Job'
      />
      <div className={st.mainContentHolder}>
        <JobsBatchOperation jobListData={jobListData} jobIdList={jobIdList} />
        <Loader
          isVisible={
            isLoadingJob || isLoadingRejectJob || isLoadingControlValues
          }
          isBackgroundColorGray
        />
      </div>
    </CenteredColumnLayout>
  );
};

export default BatchJobDetailsPage;
