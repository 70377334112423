import React from 'react';
import { object, array, func, bool } from 'prop-types';
import { FormControl } from 'react-bootstrap';
import HeaderContentTitle from 'components/HeaderContentTitle/HeaderContentTitle';
import { MAX_LENGTH_INPUT } from 'constants/ui';
import cn from 'classnames';
import st from './JobsControlValues.module.scss';

JobsControlValues.propTypes = {
  controlValuesHeaders: array,
  jobControlValues: object,
  setJobControlValues: func,
  isShowError: bool,
  setIsShowError: func,
  errors: object,
};

function JobsControlValues({
  controlValuesHeaders = [],
  jobControlValues = {},
  setJobControlValues = () => {},
  isShowError = false,
  setIsShowError = () => {},
  errors = {},
  classNameHolderItemCV,
  classNameContainer,
}) {
  const renderControlValues = () => {
    return controlValuesHeaders.map((item, index) => {
      const nameInput = item.value ? item.value : `additionalProp${index + 1}`;
      return (
        <div
          key={item.value}
          className={cn(st.holderItemControlValue, classNameHolderItemCV)}>
          <div className={st.holderControlValueTitle}>
            <HeaderContentTitle
              title={item.value}
              classNameText={st.controlValueTitle}
              classNameHolderIcon={st.controlValueTitleHolderIcon}
            />
          </div>
          <div className={st.holderControlValueInput}>
            <FormControl
              name={nameInput}
              value={jobControlValues[nameInput] || ''}
              onChange={(e) => {
                setJobControlValues((prev) => ({
                  ...prev,
                  [nameInput]: e.target.value,
                }));

                setIsShowError(false);
              }}
              size='sm'
              maxLength={MAX_LENGTH_INPUT}
              isInvalid={isShowError && errors?.[nameInput]}
            />
          </div>
        </div>
      );
    });
  };

  return (
    <div className={cn(st.container, classNameContainer)}>
      {renderControlValues()}
    </div>
  );
}

export default JobsControlValues;
