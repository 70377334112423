import { MODULE_TYPES } from 'constants/files';
import { ucFirst } from 'helpers/string';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AuthService from 'services/auth/AuthService';
import * as notesActions from 'store/notes/actions';
import { oneOfType, string, number, bool } from 'prop-types';
import { FORMAT_DATE_USA } from 'constants/ui';
import RFQComments from '../RFQComments/RFQComments';

RFQCommentsWrapper.propTypes = {
  rfqId: oneOfType([number, string]),
  isNewRFQ: bool,
};

const getTitleUserCardNote = (userData, customerData, userLogin) => {
  const isClient = AuthService.isClient();
  if (userData) {
    return `${AuthService.getHighestUserRole(userData?.roles)}: ${
      userData?.name
    }`;
  }

  if (isClient && customerData?.email === userLogin) {
    return 'Me';
  }

  return customerData?.name ? `Customer: ${customerData?.name}` : 'Customer';
};

function RFQCommentsWrapper({ rfqId, isNewRFQ = false }) {
  const dispatch = useDispatch();

  const currentNotes =
    useSelector((state) => state?.notes?.notes?.data?.[rfqId]) ?? [];
  const userLogin = useSelector((state) => state?.auth?.user?.login) ?? '';

  const notesArray =
    currentNotes.length > 0
      ? currentNotes.map((item) => {
          const titleUser = getTitleUserCardNote(
            item?.user,
            item?.customer,
            userLogin
          );
          const phase = ucFirst(item.phase || '');
          return {
            title: {
              user: titleUser,
              phase,
              date: moment(item.created).format(FORMAT_DATE_USA),
            },
            text: item.text,
            currentUser:
              userLogin === item?.user?.login ||
              userLogin === item?.customer?.email,
          };
        })
      : [];

  const handleChangeNote = () => {};

  const handleCreateNotes = (value, isVisibleToCustomer = false) => {
    dispatch(
      notesActions.createNote.start({
        id: rfqId,
        data: { text: value, visible_to_customer: isVisibleToCustomer },
        type: MODULE_TYPES.rfqs,
      })
    );
  };

  useEffect(() => {
    if (rfqId) {
      dispatch(
        notesActions.getNoteList.start({ id: rfqId, type: MODULE_TYPES.rfqs })
      );
    }
  }, [rfqId, dispatch]);

  return (
    <RFQComments
      notesArray={notesArray}
      handleChangeNote={handleChangeNote}
      handleCreateNotes={handleCreateNotes}
      disabledButtons={isNewRFQ}
    />
  );
}

export default RFQCommentsWrapper;
