import CenteredColumnLayout from 'layouts/CenteredColumnLayout/CenteredColumnLayout';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as partTypesActions from 'store/partTypes/actions';
import * as customersActions from 'store/customers/actions';
import { Button } from 'react-bootstrap';
import HeaderContent from 'componentsShared/HeaderContent/HeaderContent';
import PartsList from 'components/Parts/PartsList/PartsList';
import NavigationService from 'services/navigation/NavigationService';
import { ROUTES } from 'router/routes';
import InputSearch from 'componentsShared/InputSearch/InputSearch';
import { debounce } from 'helpers/functions';
import {
  NO_MATCHES_FOUND,
  EMPTY_PARTS_EMPLOYEE_ROLE_TEXT,
  TIME_SEARCH_DEBOUNCE,
} from 'constants/ui';
import FooterPagination from 'componentsShared/FooterPagination/FooterPagination';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from 'react-router';
import { getQueryVariable } from 'helpers/path';
import Loader from 'componentsShared/Loader/Loader';
import st from './PartTypesManagerPage.module.scss';

const debouncedSearch = debounce((onSearch, data) => {
  onSearch(data);
}, TIME_SEARCH_DEBOUNCE);

function PartTypesManagerPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const queryVariable = getQueryVariable(location.search);
  const currentPage = queryVariable.page || 0;

  const partTypesData =
    useSelector((state) => state?.partTypes?.partTypes?.data) ?? {};
  const partTypesIds =
    useSelector((state) => state?.partTypes?.partTypes?.sortIds) ?? [];
  const isLoading =
    useSelector((state) => state?.partTypes?.partTypes?.isLoading) ?? false;
  const isLoadingCustomers =
    useSelector((state) => state?.customers?.customers?.isLoading) ?? false;
  const searchValue =
    useSelector((state) => state?.partTypes?.fetchParams?.search) ?? '';
  const paginationObj =
    useSelector((state) => state?.partTypes?.fetchParams?.pagination) ?? {};
  const isLoadingSearch =
    useSelector((state) => state?.partTypes?.fetchParams?.isLoading) ?? false;
  const isScrollPart =
    useSelector((state) => state?.partTypes?.isScrollPart) ?? false;
  const scrollPartId =
    useSelector((state) => state?.partTypes?.scrollPartId) ?? '';

  const handleCreatePart = () => {
    NavigationService.navigateToPath(ROUTES.createPartTypes.path);
  };

  const onChangeFilterSearch = (value) => {
    dispatch(partTypesActions.changeFilterSearch(value));
  };

  const loadPartTypesList = (isClearPage) => {
    dispatch(
      partTypesActions.getPartList.start({
        filter: searchValue,
        isClearPage,
        isPagination: true,
      })
    );
  };

  const handleChangeSearch = (value) => {
    onChangeFilterSearch(value);
    if (value) {
      return debouncedSearch(() => {
        loadPartTypesList(true);
      });
    }
    loadPartTypesList(true);
  };

  const handleChangePage = (page) => {
    dispatch(partTypesActions.changePage(page));
    loadPartTypesList();
  };

  useEffect(() => {
    if (!+currentPage && !isScrollPart) {
      onChangeFilterSearch('');
    }
    handleChangePage(currentPage);

    dispatch(customersActions.getCustomerList.start());
    dispatch(customersActions.setCurrentCustomer(null));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const isShowPart =
      isScrollPart &&
      scrollPartId &&
      !isLoading &&
      !isLoadingCustomers &&
      !isLoadingSearch;

    if (isShowPart) {
      const [partId] = scrollPartId.split('-');

      const partCardElement = document.getElementById(`part-card-${partId}`);

      if (partCardElement) {
        setTimeout(() => {
          partCardElement.scrollIntoView({
            block: 'center',
            behavior: 'smooth',
          });

          dispatch(partTypesActions.changeIsScrollPart());
          dispatch(partTypesActions.changeScrollPartId());
        }, 1000);
      }
    }
  }, [
    isScrollPart,
    scrollPartId,
    isLoading,
    isLoadingCustomers,
    isLoadingSearch,
    dispatch,
  ]);

  return (
    <CenteredColumnLayout
      classNamePageContentHolder={st.layoutContentHolder}
      classNamePageWrapper={st.layoutPageWrapper}
      renderFooter={() => (
        <FooterPagination
          page={paginationObj.page}
          countItem={paginationObj.count}
          size={paginationObj.size}
          onChange={handleChangePage}
          className={st.footerWrapper}
          classNameLine={st.footerLine}
          isShowPlaceholder={partTypesIds.length === 0}
          placeholder={<div className={st.placeholderFooter} />}
        />
      )}>
      <div className={st.holderHeader}>
        <HeaderContent
          className={st.headerContainer}
          flexGrowNone
          title='Parts'
          isActive
          componentActive={() => (
            <InputSearch
              placeholder='Search by number, name'
              value={searchValue}
              onChange={handleChangeSearch}
              isLoadingSearch={isLoadingSearch}
            />
          )}>
          <Button variant='primary' onClick={handleCreatePart}>
            New Part
          </Button>
        </HeaderContent>
      </div>
      <div className={st.holderPartsList}>
        <div className={st.holderPartsListContent}>
          <PartsList
            dataList={partTypesData}
            ids={partTypesIds}
            isCustomerNameShow
            classNameList={st.partsListContainer}
            emptyText={EMPTY_PARTS_EMPLOYEE_ROLE_TEXT}
            searchEmptyText={NO_MATCHES_FOUND}
            isSearch={!!searchValue}
          />
        </div>

        <Loader
          isVisible={isLoading || isLoadingCustomers}
          isBackgroundColorGray
          className={st.loaderWrapper}
        />
      </div>
    </CenteredColumnLayout>
  );
}

export default PartTypesManagerPage;
