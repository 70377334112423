/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react';
import { FormControl } from 'react-bootstrap';
import { MAX_LENGTH_INPUT, TIME_SEARCH_DEBOUNCE } from 'constants/ui';
import { v4 as uuidv4 } from 'uuid';
import { Typeahead } from 'react-bootstrap-typeahead';
import Chip from 'components/Chip/Chip';
import { useDispatch, useSelector } from 'react-redux';
import { debounce } from 'helpers/functions';
import * as employeesActions from 'store/employees/actions';
import * as jobsActions from 'store/jobs/actions';
import * as customersActions from 'store/customers/actions';
import { REPORT_BREAKDOWN } from 'constants/reports';
import cn from 'classnames';
import st from './ReportAdditionalSettings.module.scss';

const debouncedSearch = debounce((onSearch, data) => {
  onSearch(data);
}, TIME_SEARCH_DEBOUNCE);

function ReportAdditionalSettings({
  selected = [],
  setSelected = () => {},
  reportType = REPORT_BREAKDOWN[0].key,
}) {
  const dispatch = useDispatch();

  const [value, setValue] = useState('');

  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const searchValueEmployee =
    useSelector((state) => state?.employees?.fetchParams?.search) ?? '';
  const employeeList =
    useSelector((state) => state?.employees?.employees?.data) ?? {};
  const isLoadingSearchEmployee =
    useSelector((state) => state?.employees?.employees?.isLoading) ?? false;

  const jobsList = useSelector((state) => state?.jobs?.jobs?.data) ?? {};
  const isLoadingSearchJobs =
    useSelector((state) => state?.jobs?.jobs?.isLoading) ?? false;

  const searchValueCustomer =
    useSelector((state) => state?.customers?.fetchParams?.search) ?? '';
  const customerList =
    useSelector((state) => state?.customers?.customers?.data) ?? {};
  const isLoadingSearchCustomer =
    useSelector((state) => state?.customers?.customers?.isLoading) ?? false;

  const inputId = uuidv4();

  const options = useMemo(() => {
    if (isLoadingSearch) {
      return [];
    }

    let list;

    if (reportType === REPORT_BREAKDOWN[0].key) {
      list = Object.values(employeeList);
      return list.map((item) => ({ label: item.name, value: item.employeeId }));
    }
    if (reportType === REPORT_BREAKDOWN[1].key) {
      list = Object.values(jobsList);
      return list.map((item) => ({
        label: item.id.toString(),
        value: item.id,
      }));
    }
    if (reportType === REPORT_BREAKDOWN[2].key) {
      list = Object.values(customerList);
      return list.map((item) => ({ label: item.name, value: item.id }));
    }
  }, [
    isLoadingSearch,
    reportType,
    employeeList,
    jobsList,
    customerList,
    value,
  ]);

  const currentOptions = useMemo(() => {
    const optionList = options.map(({ label }) => label);
    const selectedList = selected.map(({ label }) => label);

    const filterOptions = optionList.filter(
      (item) => !selectedList.includes(item)
    );
    return filterOptions.map((item) => ({ label: item }));
  }, [options, selected]);

  const isInvalidInput = useMemo(() => {
    return !isLoadingSearch && currentOptions.length === 0 && value.length > 0;
  }, [value, isLoadingSearch, currentOptions]);

  const handleChange = (selectedValue) => {
    if (selected.length === 1 && selectedValue.length === 0) {
      setIsLoadingSearch(false);
    }

    const selectedLabel = selected.map(({ label }) => label);

    const uniqLabel = selectedValue.filter(
      ({ label }) => !selectedLabel.includes(label)
    );

    const cuurentData = uniqLabel.map((item) => {
      return options.find(({ label }) => label === item.label);
    });

    setSelected([...selected, ...cuurentData]);
    setValue('');
  };

  const handleRemoveChip = (data) => {
    setSelected(selected.filter(({ label }) => label !== data));
  };

  const renderSelectedSettings = () => {
    return selected.map(({ label }) => {
      return (
        <Chip
          label={label}
          handleRemoveChip={handleRemoveChip}
          showRemoveBtn
          key={label}
        />
      );
    });
  };

  const loadSettingsList = () => {
    if (reportType === REPORT_BREAKDOWN[0].key) {
      dispatch(
        employeesActions.getEmployeeList.start({
          filter: searchValueEmployee,
        })
      );
    }
    if (reportType === REPORT_BREAKDOWN[1].key) {
      dispatch(
        jobsActions.getJob.start({ isSearchJobId: true, hideError: true })
      );
    }
    if (reportType === REPORT_BREAKDOWN[2].key) {
      dispatch(
        customersActions.getCustomerList.start({
          filter: searchValueCustomer,
        })
      );
    }
  };

  const onChangeFilterSearch = (data) => {
    if (reportType === REPORT_BREAKDOWN[0].key) {
      dispatch(employeesActions.changeFilterSearch(data));
    }
    if (reportType === REPORT_BREAKDOWN[1].key) {
      dispatch(jobsActions.changeFilterSearch(data));
    }
    if (reportType === REPORT_BREAKDOWN[2].key) {
      dispatch(customersActions.changeFilterSearch(data));
    }
  };

  const handleChangeSearch = (data) => {
    setValue(data);
    setIsLoadingSearch(true);
    onChangeFilterSearch(data);
    if (data) {
      return debouncedSearch(() => {
        loadSettingsList(true);
      });
    }
    loadSettingsList(true);
  };

  useEffect(() => {
    handleChangeSearch('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportType]);

  useEffect(() => {
    if (
      (!isLoadingSearchEmployee && reportType === REPORT_BREAKDOWN[0].key) ||
      (!isLoadingSearchJobs && reportType === REPORT_BREAKDOWN[1].key) ||
      (!isLoadingSearchCustomer && reportType === REPORT_BREAKDOWN[2].key)
    ) {
      setIsLoadingSearch(false);
    }
  }, [
    isLoadingSearchEmployee,
    isLoadingSearchJobs,
    isLoadingSearchCustomer,
    reportType,
  ]);

  return (
    <div className={st.container}>
      <div className={st.chipList}>
        {selected.length > 0 && renderSelectedSettings()}
      </div>
      <Typeahead
        multiple
        options={currentOptions}
        selectedLabel={[]}
        selected={selected}
        onInputChange={handleChangeSearch}
        onChange={handleChange}
        id={inputId}
        emptyLabel={isLoadingSearch ? 'Loading...' : 'No matches found.'}
        renderInput={({ inputRef, referenceElementRef, ...inputProps }) => (
          <div
            className={cn({
              [st.headShakeAnimation]: isInvalidInput,
            })}>
            <FormControl
              {...inputProps}
              ref={(input) => {
                inputRef(input);
                referenceElementRef(input);
              }}
              isInvalid={isInvalidInput}
              size='sm'
              maxLength={MAX_LENGTH_INPUT}
            />
          </div>
        )}
      />
    </div>
  );
}

export default ReportAdditionalSettings;
