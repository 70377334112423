import React, { useMemo } from 'react';
import Modal from 'componentsShared/Modal/Modal';
import { REPORT_BREAKDOWN, REPORT_BREAKDOWN_NCR_KEY } from 'constants/reports';
import { Form } from 'react-bootstrap';
import Text from 'componentsShared/Text/Text';
import ReportAdditionalSettings from 'components/Report/ReportAdditionalSettings/ReportAdditionalSettings';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import { useDispatch, useSelector } from 'react-redux';
import { getReportFielList } from 'helpers/reports';
import * as reportsActions from 'store/reports/actions';
import st from './ModalNewReport.module.scss';

function ModalNewReport({
  show = false,
  setShow = () => {},
  generateReport = () => {},
}) {
  const dispatch = useDispatch();

  const newSettingsReport =
    useSelector((state) => state?.reports?.newSettings) ?? {};

  const reportBreakdown = newSettingsReport.reportType || REPORT_BREAKDOWN[0];
  const selectedSettings = newSettingsReport.filters || [];

  const dates = {
    startDate: newSettingsReport.timeFrom || new Date(),
    endDate: newSettingsReport.timeTo || new Date(),
  };

  const isShowAdditionalSettings = useMemo(() => {
    return reportBreakdown.key !== REPORT_BREAKDOWN_NCR_KEY;
  }, [reportBreakdown]);

  const onChangeFields = (value) => {
    dispatch(reportsActions.changeSettingsReport({ filters: value }));
  };

  const onChangeRadioBox = (value) => {
    const fields = getReportFielList(value.key);

    dispatch(
      reportsActions.changeSettingsReport({
        reportType: value,
        filters: [],
        fields,
      })
    );
  };

  const onChangeDates = (value) => {
    dispatch(
      reportsActions.changeSettingsReport({
        timeFrom: value.startDate,
        timeTo: value.endDate,
      })
    );
  };

  const handleHideMaodal = () => {
    dispatch(reportsActions.clearNewSettings());
  };

  return (
    <Modal
      title='New Report'
      show={show}
      setShow={setShow}
      titleAcceptBtn='Generate'
      heightBody={583}
      onAcceptBtn={generateReport}
      onHide={handleHideMaodal}>
      <div>
        <div className={st.holderReportBreakdown}>
          <Text variant='normal' className={st.title} bold>
            Breakdown by:
          </Text>
          <div className={st.wrapperRadioBox}>
            {REPORT_BREAKDOWN.map((item) => (
              <div key={item.key} className={st.holderRadioBox}>
                <Form.Check
                  type='radio'
                  name='reason'
                  checked={reportBreakdown.key === item.key}
                  onChange={() => onChangeRadioBox(item)}
                />
                <Text
                  variant='normal'
                  className={st.labelRadiobox}
                  onClick={() => onChangeRadioBox(item)}>
                  {item.label}
                </Text>
              </div>
            ))}
          </div>
        </div>
        <div className={st.holderTime}>
          <Text variant='normal' className={st.title} bold>
            Timeframe:
          </Text>
          <div className={st.holderDateRangePicker}>
            <DateRangePicker dates={dates} setDates={onChangeDates} />
          </div>
        </div>
        {isShowAdditionalSettings && (
          <div className={st.wrapperAdditionalSettings}>
            <Text variant='normal' className={st.title} bold>
              {reportBreakdown.label} List:
            </Text>
            <div className={st.holderAdditionalSettings}>
              <ReportAdditionalSettings
                selected={selectedSettings}
                setSelected={onChangeFields}
                reportType={reportBreakdown.key}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  );
}

export default ModalNewReport;
