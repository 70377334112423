import { getQueryParam } from 'helpers/path';
import { api } from './ApiService';

export const getOrdersList = (params) => {
  const queryParam = getQueryParam(params);
  return api.get(`orders${queryParam}`);
};

export const createOrder = (data) => {
  return api.post('/orders/', data);
};

export const getOrder = (id) => {
  return api.get(`/orders/${id}`);
};

export const updateOrder = ({ id, data }) => {
  return api.put(`/orders/${id}`, data);
};

export const submitOrder = (id, data) => {
  return api.put(`orders/${id}/submit`, data);
};

export const removeOrder = (id) => {
  return api.delete(`orders/${id}`);
};

export const updateOrderScore = ({ id, data }) => {
  return api.put(`/orders/${id}/score`, data);
};

export const updateOrderEndDate = ({ id, data }) => {
  return api.put(`/orders/${id}/end_date`, data);
};

export const cancelOrder = (id) => {
  return api.put(`/orders/${id}/cancel`);
};
