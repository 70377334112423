import CenteredColumnLayout from 'layouts/CenteredColumnLayout/CenteredColumnLayout';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as travelersActions from 'store/travelers/actions';
import { getValidStepsByTemplate } from 'helpers/functions';
import ModalTravelerTemlates from 'components/Modal/ModalTravelerTemlates/ModalTravelerTemlates';
import NavigationService from 'services/navigation/NavigationService';

function NewTravelerPage() {
  const dispatch = useDispatch();

  const [selectTemplateId, setSelectTemplateId] = useState();
  const [showModalTemplate, setShowModalTemplate] = useState(true);
  const phaseList =
    useSelector((state) => state?.travelers.phaseList?.data) ?? {};

  const handleSubmit = (value) => {
    const data = getValidStepsByTemplate(value);

    dispatch(
      travelersActions.createTraveler.start({
        data,
        selectTemplateId,
      })
    );
  };

  const handleSkip = (onCloseModal) => {
    dispatch(
      travelersActions.createTraveler.start({
        data: { phases: Object.values(phaseList) },
      })
    );
    onCloseModal(onCloseModal);
  };

  const handleClose = () => {
    NavigationService.back();
  };

  useEffect(() => {
    dispatch(travelersActions.getPhaseList.start());
  }, [dispatch]);

  return (
    <CenteredColumnLayout>
      <ModalTravelerTemlates
        showModal={showModalTemplate}
        setShowModal={setShowModalTemplate}
        handleSubmit={handleSubmit}
        handleSkip={handleSkip}
        handleClose={handleClose}
        selectTemplateId={selectTemplateId}
        setSelectTemplateId={setSelectTemplateId}
      />
    </CenteredColumnLayout>
  );
}

export default NewTravelerPage;
