import React, { memo, useEffect, useRef, useState } from 'react';
import ModalJobSummary from 'components/Modal/ModalJobSummary/ModalJobSummary';
import Text from 'componentsShared/Text/Text';
import Tooltip from 'componentsShared/Tooltip/Tooltip';
import { string, bool, func, array, object } from 'prop-types';
import { CARD_JOB_STATUS_NCR_TEXT, JOBS_STATUS } from 'constants/jobs';
import ModalQR from 'components/Modal/ModalQR/ModalQR';
import JobStatus from 'components/Jobs/JobStatus/JobStatus';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import st from './JobsCard.module.scss';

JobsCard.propTypes = {
  jobData: object,
  showModalSummary: bool,
  setShowModalSummary: func,
  modalTitleAcceptBtn: string,
  handleOpenModalSummary: func,
  handleAcceptBtn: func,
  disabledCard: bool,
  jobStatus: string,
  quantityCard: string,
  jobType: func,
  isProgressJob: bool,
  handleReadQR: func,
  isErrorReaderQR: bool,
  handleReaderError: func,
  isError: bool,
  onCancelModal: func,
  selectAvailableJob: array,
  setSelectAvailableJob: func,
  jobList: array,
  handleChangeTypeQR: func,
  isInterprocessQA: bool,
  isNCR: bool,
  jobSummaryInfo: object,
  isQA: bool,
};

function JobsCard({
  jobData = {},
  showModalSummary = false,
  setShowModalSummary = () => {},
  showModalQR = false,
  setShowModalQR = () => {},
  modalTitleAcceptBtn = '',
  handleOpenModalSummary = () => {},
  handleAcceptBtn = () => {},
  disabledCard = false,
  jobStatus = '',
  quantityCard = '',
  jobType,
  isProgressJob = false,
  handleReadQR = () => {},
  isErrorReaderQR = false,
  handleReaderError = () => {},
  isError = false,
  typeError = null,
  onCancelModal = () => {},
  selectAvailableJob = [],
  setSelectAvailableJob = () => {},
  jobList = [],
  handleChangeTypeQR = () => {},
  isInterprocessQA,
  isNCR = false,
  changeSelectJobIdList = () => {},
  jobSummaryInfo = {},
  isQA = false,
}) {
  const isCreateBatch =
    useSelector((state) => state?.jobs?.jobsBatch?.isCreateBatch) ?? false;
  const selectJobIdList =
    useSelector((state) => state?.jobs?.jobsBatch?.selectJobIdList) ?? [];
  const scrollJobId = useSelector((state) => state?.jobs?.scrollJobId) ?? '';

  const holderJobPartRef = useRef(null);
  const textJobPartNameRef = useRef(null);
  const textJobPartNumberRef = useRef(null);
  const holderJobPoRef = useRef(null);
  const textJobPONumber = useRef(null);
  const textCustomerName = useRef(null);

  const [isPartTooltip, setIsPartTooltip] = useState(false);
  const [isPOTooltip, setIsPOTooltip] = useState(false);
  const [isOpenCard, setIsOpenCard] = useState(false);

  const jobIdList = jobList.map(({ id }) => id);

  useEffect(() => {
    if (scrollJobId && jobIdList.includes(scrollJobId)) {
      setIsOpenCard(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollJobId]);

  useEffect(() => {
    if (
      (jobData.partTypeName || jobData.partTypeNumber) &&
      textJobPartNameRef.current?.offsetWidth +
        textJobPartNumberRef.current?.offsetWidth >
        holderJobPartRef.current?.offsetWidth
    ) {
      setIsPartTooltip(true);
    }

    if (
      isOpenCard &&
      (jobData.poNumber || jobData.customerName) &&
      textJobPONumber.current?.offsetWidth +
        textCustomerName.current?.offsetWidth >
        holderJobPoRef.current?.offsetWidth
    ) {
      setIsPOTooltip(true);
    }
  }, [
    jobData.partTypeName,
    jobData.partTypeNumber,
    jobData.poNumber,
    jobData.customerName,
    isOpenCard,
  ]);

  return (
    <>
      <div className={st.wrapper}>
        <div
          className={cn(st.wrapperCard, { [st.openWrapperCard]: isOpenCard })}>
          <div
            className={cn(st.container, {
              [st.openContainerCard]: isOpenCard,
            })}
            onClick={() => {
              setIsOpenCard((prev) => !prev);
            }}>
            <div className={st.jobInfoContainer}>
              <Tooltip
                placement='bottom'
                textTooltip={`${jobData.partTypeName} ${jobData.partTypeNumber}`}
                isTooltip={isPartTooltip}>
                <div className={st.jobInfoPart} ref={holderJobPartRef}>
                  <Text className={st.jobInfoPartText}>
                    <Text
                      variant='normal2'
                      bold
                      currentRef={textJobPartNameRef}
                      className={st.jobInfoPartNameText}>
                      {jobData.partTypeName}
                    </Text>{' '}
                    <Text
                      variant='normal'
                      currentRef={textJobPartNumberRef}
                      className={st.jobInfoPartNumberText}>
                      {jobData.partTypeNumber}
                      {isQA ? `-Revision-${jobData.partTypeRevision}` : ''}
                    </Text>
                  </Text>
                </div>
              </Tooltip>

              {isOpenCard && (
                <Tooltip
                  placement='bottom'
                  textTooltip={`${jobData.poNumber} ${jobData.customerName}`}
                  isTooltip={isPOTooltip}>
                  <div className={st.jobInfoPO} ref={holderJobPoRef}>
                    <Text className={st.jobInfoPOText}>
                      <Text
                        variant='normal2'
                        bold
                        className={st.jobInfoPONumberText}
                        currentRef={textJobPONumber}>
                        {jobData.poNumber}
                      </Text>{' '}
                      <Text
                        variant='normal'
                        className={st.jobInfoCustomerNameText}
                        currentRef={textCustomerName}>
                        {jobData.customerName}
                      </Text>
                    </Text>
                  </div>
                </Tooltip>
              )}

              <div className={st.jobStatusHolder}>
                <Text variant='normal2' bold className={st.jobStatusText}>
                  {jobStatus}
                </Text>
              </div>
            </div>
            <div className={st.jobAdditionalInfo}>
              <div>
                <Text variant='normal2' className={st.quantityCardText}>
                  {quantityCard}
                </Text>
              </div>
              <div>{typeof jobType === 'function' && jobType()}</div>
            </div>
          </div>
        </div>

        {isOpenCard && (
          <div className={st.partCardList}>
            {jobList.map((item) => {
              const jobId = item.id;
              return (
                <div
                  id={`job-id-${jobId}`}
                  key={`${jobData.customerName}-${jobData.poNumber}-${jobId}`}
                  className={cn(st.partCardItem, {
                    [st.cursorDefault]: disabledCard,
                  })}
                  onClick={() => handleOpenModalSummary(jobId)}>
                  <Text variant='normal'>
                    <Text variant='normal' className={st.textGrid}>
                      #
                    </Text>{' '}
                    {jobId}
                  </Text>

                  <div>
                    {isCreateBatch && (
                      <Form.Group
                        className={st.formGroup}
                        onClick={(e) => {
                          changeSelectJobIdList(jobId);
                          e.stopPropagation();
                        }}>
                        <Form.Check
                          readOnly
                          name='batch-job'
                          checked={selectJobIdList.includes(jobId)}
                        />
                      </Form.Group>
                    )}

                    {isNCR && (
                      <JobStatus
                        status={JOBS_STATUS.NCR}
                        currentStatusText={CARD_JOB_STATUS_NCR_TEXT}
                      />
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <ModalJobSummary
        showModalSummary={showModalSummary}
        setShowModalSummary={setShowModalSummary}
        setShowModalQR={setShowModalQR}
        modalTitleAcceptBtn={modalTitleAcceptBtn}
        handleAcceptBtn={handleAcceptBtn}
        partId={jobData.partId}
        isProgressJob={isProgressJob}
        selectAvailableJob={selectAvailableJob}
        setSelectAvailableJob={setSelectAvailableJob}
        isInterprocessQA={isInterprocessQA}
        jobSummaryInfo={jobSummaryInfo}
        jobIdList={jobIdList}
      />

      <ModalQR
        show={showModalQR}
        setShow={setShowModalQR}
        handleReadQR={handleReadQR}
        isErrorReaderQR={isErrorReaderQR}
        handleReaderError={handleReaderError}
        isError={isError}
        typeError={typeError}
        onCancelModal={onCancelModal}
        handleChangeType={handleChangeTypeQR}
        onlyQRCode
      />
    </>
  );
}

export default memo(JobsCard);
