import React, { useState } from 'react';
import Text from 'componentsShared/Text/Text';
import { CropOriginalIcon, FileIcon } from 'assets/icons';
import { checkFileTypeDocOrXlsFromPath, getTypeFile } from 'helpers/file';
import { IMAGE_TYPE_ARRAY } from 'constants/files';
import ModalFileBody from 'components/Modal/ModalFileBody/ModalFileBody';
import Modal from 'componentsShared/Modal/Modal';
import ModalFilePreviewPagination from 'components/Modal/ModalFilePreviewPagination/ModalFilePreviewPagination';
import { ZoomIn, ZoomOut } from 'react-bootstrap-icons';
import { Button } from 'react-bootstrap';
import { VARIANT_OUTLINE_UI, ZOOM_IMG_ARRAY } from 'constants/ui';
import { useDispatch, useSelector } from 'react-redux';
import * as filesActions from 'store/files/actions';
import st from './JobsWorklogItemFile.module.scss';

const getIconFile = (isTypeImage) => {
  if (isTypeImage) {
    return <CropOriginalIcon className={st.iconFile} />;
  }
  return <FileIcon className={st.iconFile} />;
};

function JobsWorklogItemFile({ path, name }) {
  const dispatch = useDispatch();

  const [zoomImgIndex, setZoomImgIndex] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const filesUrlObject =
    useSelector((state) => state?.files?.files?.dataUrl) ?? {};

  const currentUrl = filesUrlObject?.[path] || '';

  const pathArray = path?.split('/') || [];
  const validPathName = pathArray[pathArray.length - 1];

  const typeFile = getTypeFile(path);

  const isTypeImage = IMAGE_TYPE_ARRAY?.includes(typeFile) || false;
  const isTypePDF = typeFile?.includes('pdf') || false;

  const onChangeZoomImg = (type = 'next') => {
    let index;
    if (type === 'next') {
      index =
        zoomImgIndex >= ZOOM_IMG_ARRAY.length - 1
          ? ZOOM_IMG_ARRAY.length - 1
          : zoomImgIndex + 1;
    } else {
      index = zoomImgIndex === 0 ? 0 : zoomImgIndex - 1;
    }
    setZoomImgIndex(index);
  };

  const handleClickCard = () => {
    const isTypeDocOrXls = checkFileTypeDocOrXlsFromPath(path);

    if (isTypeDocOrXls) {
      dispatch(
        filesActions.getFile.start({
          path,
          download: true,
        })
      );

      return;
    }

    if (isTypeImage || isTypePDF) {
      setShowModal(true);
    }
  };

  const renderButtonBottomLeft = () => {
    if (isTypePDF) {
      return (
        <ModalFilePreviewPagination
          pageNumber={pageNumber}
          numPages={numPages}
          setPageNumber={setPageNumber}
        />
      );
    }
  };

  const openFileInBrowser = () => {
    if (currentUrl) {
      window.open(currentUrl, 'EPrescription');
    }
  };

  const renderButtonBottomRight = () => {
    if (isTypePDF) {
      return <Button onClick={openFileInBrowser}>Open in a browser</Button>;
    }

    return (
      <div className={st.footerButtonGroup}>
        <Button
          variant={VARIANT_OUTLINE_UI.primary}
          onClick={() => onChangeZoomImg('prev')}
          disabled={zoomImgIndex === 0}>
          <ZoomOut />
        </Button>
        <Button
          variant={VARIANT_OUTLINE_UI.primary}
          onClick={() => onChangeZoomImg('next')}
          disabled={zoomImgIndex === ZOOM_IMG_ARRAY.length - 1}>
          <ZoomIn />
        </Button>
      </div>
    );
  };

  return (
    <>
      <div className={st.container}>
        <Text>{name}</Text>

        <div className={st.itemFileWrapper} onClick={handleClickCard}>
          {getIconFile(isTypeImage)}
          <Text className={st.itemFileName}>{validPathName}</Text>
        </div>
      </div>
      <Modal
        title={validPathName || 'File preview'}
        size='lg'
        show={showModal}
        setShow={setShowModal}
        buttonBottomRight={renderButtonBottomRight}
        buttonBottomLeft={renderButtonBottomLeft}
        heightBody='calc(100vh - 240px)'
        classNameTitle={st.modalTitle}>
        <ModalFileBody
          filePath={path}
          isTypePDF={isTypePDF}
          setNumPages={setNumPages}
          pageNumber={pageNumber}
          zoomImgIndex={zoomImgIndex}
        />
      </Modal>
    </>
  );
}

export default JobsWorklogItemFile;
