import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import history from 'history/history';
import { MODAL_TITLE_BTN_DISCARD_CHANGES } from 'constants/ui';
import ModalBack from 'components/Modal/ModalBack/ModalBack';

function BeforeRedirect({ when }) {
  const [showModal, setShowModal] = useState(false);
  const blockedUrl = useRef('');
  const unblock = useRef(() => {});

  const handleConfirm = () => {
    setShowModal(false);
    unblock.current();
    history.push(blockedUrl.current);
    blockedUrl.current = '';
  };

  const handleCancel = () => {
    setShowModal(false);
    blockedUrl.current = '';
  };

  useEffect(() => {
    if (when) {
      unblock.current = history.block((location, action) => {
        if (action === 'POP') {
          return true;
        }
        const pathname = `${location.pathname}${location.search}`;
        blockedUrl.current = pathname;
        setShowModal(true);
        return false;
      });
    }
    return () => unblock.current();
  }, [when]);

  return ReactDOM.createPortal(
    <ModalBack
      show={showModal}
      setShow={setShowModal}
      navigateBack={handleConfirm}
      onHide={handleCancel}
      titleBtn={MODAL_TITLE_BTN_DISCARD_CHANGES}
    />,
    document.body
  );
}

export default BeforeRedirect;
