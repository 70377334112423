// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OffcanvasCompanyEdit_buttonGroup__3rqwT {\n  display: flex;\n  flex-direction: column;\n  grid-row-gap: 8px;\n  row-gap: 8px; }\n", "",{"version":3,"sources":["webpack://src/components/Offcanvas/OffcanvasCompanyEdit/OffcanvasCompanyEdit.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAY;EAAZ,YAAY,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.buttonGroup {\n  display: flex;\n  flex-direction: column;\n  row-gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"buttonGroup": "OffcanvasCompanyEdit_buttonGroup__3rqwT"
};
export default ___CSS_LOADER_EXPORT___;
