import { TRAVELER_STATUS } from 'constants/traveler';
import { store } from 'store/store';

const PartService = {
  getCurrentState() {
    return store.getState();
  },
  isActiveTravelerInPartList(partIds = []) {
    const currentState = this.getCurrentState();

    return partIds.every((itemId) => {
      const travelerData = currentState?.partTypes?.selectedPartTypes?.find(
        (item) => +item.id === +itemId
      )?.traveler;

      return (
        travelerData?.id && travelerData?.status === TRAVELER_STATUS.ACTUAL
      );
    });
  },
};

export default PartService;
