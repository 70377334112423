import { api } from './ApiService';

export const getJobNoteList = ({ id }) => {
  return api.get(`/jobs/notes/${id}`);
};

export const createJobNote = ({ data, id }) => {
  return api.post(`/jobs/notes/${id}`, data);
};

export const getRFQNoteList = ({ id }) => {
  return api.get(`/rfqs/notes/${id}`);
};

export const createRFQNote = ({ data, id }) => {
  return api.post(`/rfqs/notes/${id}`, data);
};
