import { api } from './ApiService';

export const getMessage = () => {
  return api.get('/metadata/message');
};

export const getMessageHash = () => {
  return api.get('/metadata/message/hash', null, null, true);
};

export const updateMessage = (data) => {
  return api.put('/metadata/message', data);
};
