import NavigationService from 'services/navigation/NavigationService';
import React from 'react';
import { Route as DOMRoute, Redirect, useLocation } from 'react-router-dom';
import { ROUTES } from 'router/routes';
import { getValidPath } from 'helpers/path';
import { useSelector } from 'react-redux';
import AuthService from 'services/auth/AuthService';

export default function CustomRoute({
  isProtected = false,
  roles = [],
  isRedirect = true,
  ...routeProps
}) {
  const location = useLocation();

  const isAuthorized =
    useSelector((state) => state?.auth?.isAuthorized) ?? false;
  const isTempPassword =
    useSelector((state) => state?.auth?.isTempPassword) ?? false;
  const userRoles = useSelector((state) => state?.auth?.user?.roles) ?? [];

  const isAccessRoute = AuthService.isAccessRoute({
    userRoles,
    routeRoles: roles,
  });

  const isTempPasswordRoute =
    getValidPath(location.pathname) ===
    getValidPath(ROUTES.changeTempPassword.path);

  if (isRedirect) {
    if (!isAuthorized && isProtected) {
      return <Redirect to={getValidPath(ROUTES.signIn.path)} />;
    }

    if (isAuthorized && isTempPassword && !isTempPasswordRoute) {
      return <Redirect to={getValidPath(ROUTES.changeTempPassword.path)} />;
    }

    if (!isAccessRoute && isAuthorized) {
      const defaultPath = NavigationService.getDefaultPath();
      return <Redirect to={getValidPath(defaultPath)} />;
    }
  }
  return <DOMRoute {...routeProps} />;
}
