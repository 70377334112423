// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JobListPage_contentWrapper__2FnQC {\n  position: relative;\n  min-height: calc(100vh - 185px); }\n\n.JobListPage_pageContentHolder__3TJ-E {\n  width: 100%;\n  padding-left: 0;\n  padding-right: 0; }\n\n.JobListPage_headerContentHolder__3Eisf {\n  display: flex;\n  justify-content: center; }\n\n.JobListPage_headerContent__VkZ5Z {\n  width: 900px;\n  max-width: 900px; }\n", "",{"version":3,"sources":["webpack://src/pages/JobListPage/JobListPage.module.scss"],"names":[],"mappings":"AAEA;EACE,kBAAkB;EAClB,+BAA+B,EAAA;;AAGjC;EACE,WAAW;EACX,eAAe;EACf,gBAAgB,EAAA;;AAGlB;EACE,aAAa;EACb,uBAAuB,EAAA;;AAGzB;EACE,YAAY;EACZ,gBAAgB,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.contentWrapper {\n  position: relative;\n  min-height: calc(100vh - 185px);\n}\n\n.pageContentHolder {\n  width: 100%;\n  padding-left: 0;\n  padding-right: 0;\n}\n\n.headerContentHolder {\n  display: flex;\n  justify-content: center;\n}\n\n.headerContent {\n  width: 900px;\n  max-width: 900px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"contentWrapper": "JobListPage_contentWrapper__2FnQC",
	"pageContentHolder": "JobListPage_pageContentHolder__3TJ-E",
	"headerContentHolder": "JobListPage_headerContentHolder__3Eisf",
	"headerContent": "JobListPage_headerContent__VkZ5Z"
};
export default ___CSS_LOADER_EXPORT___;
