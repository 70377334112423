import { getRequestAction } from 'helpers/functions';
import { createAction } from '@reduxjs/toolkit';

const ACTION_PREFIX = 'orders';

export const getOrdersList = getRequestAction(`${ACTION_PREFIX}/getOrdersList`);

export const createOrder = getRequestAction(`${ACTION_PREFIX}/createOrder`);

export const getOrder = getRequestAction(`${ACTION_PREFIX}/getOrder`);

export const updateOrder = getRequestAction(`${ACTION_PREFIX}/updateOrder`);

export const submitOrder = getRequestAction(`${ACTION_PREFIX}/submitOrder`);

export const submitNewOrder = getRequestAction(
  `${ACTION_PREFIX}/submitNewOrder`
);

export const removeOrder = getRequestAction(`${ACTION_PREFIX}/removeOrder`);

export const clearIsAddingOrder = createAction(
  `${ACTION_PREFIX}/clearIsAddingOrder`
);

export const addIsNewOrder = createAction(`${ACTION_PREFIX}/addIsNewOrder`);

export const openOrderItem = createAction(`${ACTION_PREFIX}/openOrderItem`);

export const orderExpandAll = createAction(`${ACTION_PREFIX}/orderExpandAll`);

export const orderCollapseAll = createAction(
  `${ACTION_PREFIX}/orderCollapseAll`
);

export const changeFilterSearch = createAction(
  `${ACTION_PREFIX}/changeFilterSearch`
);

export const deactivateOrderCardListChange = createAction(
  `${ACTION_PREFIX}/deactivateOrderCardListChange`
);

export const changePage = createAction(`${ACTION_PREFIX}/changePage`);

export const changeIsConvertFromRFQ = createAction(
  `${ACTION_PREFIX}/changeIsConvertFromRFQ`
);

export const updateOrderScore = getRequestAction(
  `${ACTION_PREFIX}/updateOrderScore`
);

export const updateOrderEndDate = getRequestAction(
  `${ACTION_PREFIX}/updateOrderEndDate`
);

export const changeDataOrderAndJob = createAction(
  `${ACTION_PREFIX}/changeDataOrderAndJob`
);

export const changeIsScrollJob = createAction(
  `${ACTION_PREFIX}/changeIsScrollJob`
);

export const cancelOrder = getRequestAction(`${ACTION_PREFIX}/cancelOrder`);
