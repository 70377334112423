import React from 'react';
import Text from 'componentsShared/Text/Text';
import { useSelector } from 'react-redux';
import {
  CropOriginalIcon,
  FileIcon,
  ArrowIcon,
  FolderIcon,
  UFOIcon,
} from 'assets/icons';
import Loader from 'componentsShared/Loader/Loader';
import cn from 'classnames';
import { string, func } from 'prop-types';
import st from './ModalFileSelect.module.scss';

ModalFileSelect.propTypes = {
  selectFile: string.isRequired,
  setSelectFile: func.isRequired,
};

const getFileName = (fileName) => {
  if (!fileName) {
    return '';
  }

  const fileNameArray = fileName.split('/');
  const currentFileName = fileNameArray[fileNameArray.length - 1];
  return currentFileName;
};

function ModalFileSelect({ selectFile, setSelectFile }) {
  const isLoadingFile =
    useSelector((state) => state?.travelers?.files?.isLoading) ?? false;
  const folderList =
    useSelector((state) => state?.travelers?.files?.data) ?? {};
  const folderArray = Object.values(folderList);

  const folderListKeys = Object.keys(folderList);

  const isEmptyFile =
    folderListKeys?.length === 0
      ? true
      : folderListKeys.some((item) => {
          return Object.keys(folderList[item].files).length === 0;
        });

  const getIconFile = (type) => {
    if (type.includes('image')) {
      return <CropOriginalIcon className={st.iconFile} />;
    }
    return <FileIcon className={st.iconFile} />;
  };

  const renderFileList = (filesArray) => {
    return filesArray.map(({ path, content_type }) => {
      return (
        <div
          key={path}
          className={cn(st.wrapperFile, {
            [st.selectFile]: selectFile === path,
          })}
          onClick={() => setSelectFile(path)}>
          {getIconFile(content_type)}
          <Text variant='normal'>{getFileName(path)}</Text>
        </div>
      );
    });
  };

  const renderFolderList = () => {
    return folderArray.map(({ name, files }) => {
      const filesArray = Object.values(files);

      if (filesArray.length === 0) {
        return <div key={`folder-name-${name}`} />;
      }

      return (
        <div key={`folder-name-${name}`}>
          <div className={st.containerFolder}>
            <ArrowIcon className={st.iconArrow} />
            <FolderIcon className={st.iconFolder} />
            <Text variant='normal' className={st.nameFolder}>
              {name || 'File'}
            </Text>
          </div>
          <div className={st.holderFiles}>{renderFileList(filesArray)}</div>
        </div>
      );
    });
  };

  return (
    <div className={st.container}>
      {!isEmptyFile > 0 ? (
        renderFolderList()
      ) : (
        <div className={st.emptyContainer}>
          <UFOIcon className={st.emptyIcon} />
          <h6>There’s no files here yet.</h6>
          <Text variant='normal2' className={st.emptyText}>
            Files appear here after you have added them under the related
            partnumber.
            <br />
            Also you can directly upload it here.
          </Text>
        </div>
      )}
      <Loader isVisible={isLoadingFile} />
    </div>
  );
}

export default ModalFileSelect;
