import React from 'react';
import { MAX_LENGTH_INPUT, VARIANT_UI } from 'constants/ui';
import { FormControl, InputGroup, Spinner } from 'react-bootstrap';
import { ClearIcon } from 'assets/icons';
import { Search } from 'react-bootstrap-icons';
import { bool, string, func } from 'prop-types';
import cn from 'classnames';
import st from './InputSearch.module.scss';

InputSearch.propTypes = {
  value: string,
  onChange: func,
  isLoadingSearch: bool,
  className: string,
  placeholder: string,
  disabled: bool,
  inputSize: string,
};

function InputSearch({
  value = '',
  onChange = () => {},
  isLoadingSearch = false,
  className,
  placeholder = 'Search',
  disabled = false,
  inputSize = 'md',
}) {
  const handleChange = (searchValue) => {
    onChange(searchValue);
  };

  const onClearSearch = () => {
    onChange('');
  };

  const getIconForFilterSearch = () => {
    if (value) {
      return (
        <InputGroup.Text onClick={onClearSearch} className={st.inputGroupClear}>
          <ClearIcon />
        </InputGroup.Text>
      );
    }
    return (
      <InputGroup.Text>
        <Search />
      </InputGroup.Text>
    );
  };

  return (
    <div className={cn(st.holderSearch, className)}>
      <InputGroup>
        <FormControl
          disabled={disabled}
          value={value}
          onChange={(e) => handleChange(e.target.value)}
          placeholder={placeholder}
          size={inputSize}
          maxLength={MAX_LENGTH_INPUT}
        />
        {getIconForFilterSearch()}
      </InputGroup>
      {isLoadingSearch && (
        <div className={st.loaderSearch}>
          <Spinner animation='border' variant={VARIANT_UI.secondary} />
        </div>
      )}
    </div>
  );
}

export default InputSearch;
