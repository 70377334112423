import React, { useState } from 'react';
import { Typeahead, TypeaheadInputMulti } from 'react-bootstrap-typeahead';
import { v4 as uuidv4 } from 'uuid';
import { object, func, array, string, bool } from 'prop-types';
import TypeaheadToken from 'componentsShared/TypeaheadToken/TypeaheadToken';
import cn from 'classnames';
import st from './TypeaheadMultiple.module.scss';

TypeaheadMultiple.propTypes = {
  initialValue: array,
  options: array,
  onChange: func,
  error: string,
  formProps: object,
  positionFixed: bool,
  disabled: bool,
};

function TypeaheadMultiple({
  initialValue = [],
  options = [],
  onChange = () => {},
  error = '',
  formProps,
  positionFixed = true,
  disabled = false,
}) {
  const [selected, setSelected] = useState(initialValue);
  const inputId = uuidv4();

  const handleChange = (selectedValue) => {
    setSelected(selectedValue);
    onChange(selectedValue);
  };

  const onRemoveRole = (value) => {
    const currentValue = selected.filter(({ label }) => value !== label);
    setSelected(currentValue);
    onChange(currentValue);
  };

  return (
    <Typeahead
      className={cn({ [st.containerDisabled]: disabled })}
      options={options}
      selected={selected}
      onChange={handleChange}
      id={inputId}
      multiple
      disabled={disabled}
      positionFixed={positionFixed}
      renderInput={(inputProps) => (
        <TypeaheadInputMulti
          {...inputProps}
          {...formProps}
          selected={selected}
          isInvalid={error}
          disabled={disabled}
          onChange={(e) => {
            inputProps.onChange(e);
            formProps.onChange(e);
          }}>
          {selected.length > 0 &&
            selected.map((option, idx) => (
              <TypeaheadToken
                index={idx}
                key={option.label}
                label={option.label}
                onRemove={onRemoveRole}
                disabled={disabled}
              />
            ))}
        </TypeaheadInputMulti>
      )}
    />
  );
}

export default TypeaheadMultiple;
