import { getQueryParam } from 'helpers/path';
import { api } from './ApiService';

export const getCustomerList = (query) => {
  const queryParam = getQueryParam(query);
  return api.get(`/customers${queryParam}`);
};

export const createCustomer = (data) => {
  return api.post('/customers/', data);
};

export const changeCustomer = ({ id, data }) => {
  return api.put(`/customers/${id}`, data);
};

export const resendInviteCustomer = (id) => {
  return api.put(`/customers/logins/${id}/reinvite`);
};

export const removeCustomer = (id) => {
  return api.delete(`/customers/${id}`);
};

export const getCustomer = (id) => {
  return api.get(`/customers/${id}`);
};

export const checkCustomerEmail = (email) => {
  return api.get(`customers/email-uniqueness-check?email=${email}`);
};

export const createCustomerLogin = ({ data, id }) => {
  return api.post(`/customers/${id}/logins`, data);
};

export const changeCustomerLogin = ({ data, companyId, customerId }) => {
  return api.put(`/customers/${companyId}/logins/${customerId}`, data);
};

export const blockCustomerLogin = ({ companyId, customerId }) => {
  return api.put(`/customers/${companyId}/logins/${customerId}/block`);
};

export const unblockCustomerLogin = ({ companyId, customerId }) => {
  return api.put(`/customers/${companyId}/logins/${customerId}/unblock`);
};
