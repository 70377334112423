import { RFQ_STATUS } from 'constants/rfq';
import { store } from 'store/store';

const RFQService = {
  getCurrentState() {
    return store.getState();
  },
  checkStaus(rfqId) {
    const currentState = this.getCurrentState();
    const rfqStatus = currentState.rfqs?.rfqs?.data?.[rfqId]?.status ?? '';

    return rfqStatus;
  },
  isStatusDraft(rfqId) {
    const status = this.checkStaus(rfqId);
    return status.toUpperCase() === RFQ_STATUS.DRAFT.toUpperCase();
  },
  isStatusActive(rfqId) {
    const status = this.checkStaus(rfqId);
    return status.toUpperCase() === RFQ_STATUS.ACTIVE.toUpperCase();
  },
};

export default RFQService;
