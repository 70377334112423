import { all, put, takeLatest, call } from 'redux-saga/effects';
import { handleRequestError } from 'services/api/apiTools';
import ApiService from 'services/api';
import * as actions from './actions';

export function* getMessageSaga(action) {
  const params = action.payload;

  let requestValue;
  try {
    requestValue = yield call(ApiService.getMessage, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getMessage.failure());
    return;
  }

  yield put(
    actions.getMessage.success({
      hash: requestValue.hash,
      message: requestValue.message,
    })
  );
}

export function* getMessageHashSaga() {
  let requestValue;
  try {
    requestValue = yield call(ApiService.getMessageHash);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getMessageHash.failure());
    return;
  }

  yield put(actions.getMessageHash.success({ hash: requestValue.data || 0 }));
}

export function* updateMessageSaga(action) {
  const data = action?.payload || {};

  let requestValue;
  try {
    requestValue = yield call(ApiService.updateMessage, data);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.updateMessage.failure());
    return;
  }

  yield put(
    actions.updateMessage.success({
      hash: requestValue.hash,
      message: requestValue.message,
    })
  );
}

export function* metadataSagas() {
  yield all([
    yield takeLatest(actions.getMessage.start, getMessageSaga),
    yield takeLatest(actions.getMessageHash.start, getMessageHashSaga),
    yield takeLatest(actions.updateMessage.start, updateMessageSaga),
  ]);
}
