import Text from 'componentsShared/Text/Text';
import React, { useState } from 'react';
import { Form, FormControl, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import * as authActions from 'store/auth/actions';
import { EyeSlashIcon, EyeIcon } from 'assets/icons';
import { MAX_LENGTH_INPUT } from 'constants/ui';
import { Link } from 'react-router-dom';
import { ROUTES } from 'router/routes';
import st from './LoginEmployeeForm.module.scss';

const validationSchema = Yup.object().shape({
  login: Yup.string().required('Required'),
  password: Yup.string().required('Required'),
});

function LoginEmployeeForm() {
  const formOptions = { resolver: yupResolver(validationSchema) };
  const [passwordShown, setPasswordShown] = useState(false);
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  const dispatch = useDispatch();
  const onSubmit = (data) => {
    const validData = { ...data, login: data.login.trim() };
    dispatch(authActions.loginEmployee.start(validData));
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className={st.wrapperForm}>
      <div className={st.formGroup}>
        <Form.Label>Login name or email</Form.Label>
        <FormControl
          {...register('login')}
          isInvalid={errors.login}
          maxLength={MAX_LENGTH_INPUT}
        />
      </div>

      <div className={st.formGroup}>
        <Form.Label>Password</Form.Label>
        <div className={st.passwordWrapper}>
          <FormControl
            type={passwordShown ? 'text' : 'password'}
            isInvalid={errors.password}
            {...register('password')}
            maxLength={MAX_LENGTH_INPUT}
          />
          <div
            className={st.iconShowPassword}
            onClick={() => setPasswordShown((prev) => !prev)}>
            {!passwordShown ? <EyeSlashIcon /> : <EyeIcon />}
          </div>
        </div>
      </div>

      <Button type='submit' className={st.buttonLogin}>
        Log In
      </Button>

      <Link className={st.hrefLoginCustemer} to={ROUTES.signIn.path}>
        <Text variant='normal2' className={st.textLoginCustemer}>
          Login as a customer
        </Text>
      </Link>
    </Form>
  );
}

export default LoginEmployeeForm;
