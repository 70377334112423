import { createReducer } from '@reduxjs/toolkit';

import * as actions from './actions';

export const initialState = {
  metadata: {
    data: {
      message: '',
      hash: 0,
      isUpadate: false,
      isLoading: false,
    },
  },
};

export const metadataReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.getMessage.start, (state) => {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          data: {
            ...state.metadata.data,
            isLoading: true,
          },
        },
      };
    })
    .addCase(actions.getMessage.success, (state, action) => {
      const { hash, message } = action.payload;

      return {
        ...state,
        metadata: {
          ...state.metadata,
          data: {
            ...state.metadata.data,
            hash,
            message,
            isUpadate: false,
            isLoading: false,
          },
        },
      };
    })
    .addCase(actions.getMessage.failure, (state) => ({
      ...state,
      metadata: {
        ...state.metadata,
        data: {
          ...state.metadata.data,
          isLoading: false,
        },
      },
    }))
    .addCase(actions.getMessageHash.start, (state) => {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          data: {
            ...state.metadata.data,
          },
        },
      };
    })
    .addCase(actions.getMessageHash.success, (state, action) => {
      const { hash } = action.payload;

      const isUpadate = hash !== state.metadata.data.hash;

      return {
        ...state,
        metadata: {
          ...state.metadata,
          data: {
            ...state.metadata.data,
            isUpadate,
          },
        },
      };
    })
    .addCase(actions.getMessageHash.failure, (state) => ({
      ...state,
      metadata: {
        ...state.metadata,
      },
    }))
    .addCase(actions.updateMessage.start, (state) => {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          data: {
            ...state.metadata.data,
            isLoading: true,
          },
        },
      };
    })
    .addCase(actions.updateMessage.success, (state, action) => {
      const { hash, message } = action.payload;

      return {
        ...state,
        metadata: {
          ...state.metadata,
          data: {
            ...state.metadata.data,
            hash,
            message,
            isUpadate: false,
            isLoading: false,
          },
        },
      };
    })
    .addCase(actions.updateMessage.failure, (state) => ({
      ...state,
      metadata: {
        ...state.metadata,
        data: {
          ...state.metadata.data,
          isLoading: false,
        },
      },
    }))
    .addCase(actions.updateIsUpadate, (state, action) => {
      const value = action?.payload || false;

      return {
        ...state,
        metadata: {
          ...state.metadata,
          data: {
            ...state.metadata.data,
            isUpadate: value,
          },
        },
      };
    });
});
