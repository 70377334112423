// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalBodyOrderScore_formGroup__1p_5O {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  grid-column-gap: 12px;\n  column-gap: 12px; }\n  .ModalBodyOrderScore_formGroup__1p_5O .form-control {\n    width: 260px; }\n\n.ModalBodyOrderScore_formGroupLabel__36nys {\n  margin: 0 !important; }\n\n.ModalBodyOrderScore_formControlHolder__24cml {\n  width: 260px; }\n\n.ModalBodyOrderScore_labelWrapper__g0Dr3 {\n  display: flex;\n  justify-content: space-between;\n  width: calc(100% - 260px); }\n", "",{"version":3,"sources":["webpack://src/components/Modal/ModalBodyOrderScore/ModalBodyOrderScore.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,8BAA8B;EAC9B,mBAAmB;EACnB,qBAAgB;EAAhB,gBAAgB,EAAA;EAJlB;IAQM,YAAY,EAAA;;AAKlB;EACE,oBAAoB,EAAA;;AAGtB;EACE,YAAY,EAAA;;AAGd;EACE,aAAa;EACb,8BAA8B;EAC9B,yBAAyB,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.formGroup {\n  display: flex;\n  justify-content: space-between;\n  align-items: center;\n  column-gap: 12px;\n\n  :global {\n    .form-control {\n      width: 260px;\n    }\n  }\n}\n\n.formGroupLabel {\n  margin: 0 !important;\n}\n\n.formControlHolder {\n  width: 260px;\n}\n\n.labelWrapper {\n  display: flex;\n  justify-content: space-between;\n  width: calc(100% - 260px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"formGroup": "ModalBodyOrderScore_formGroup__1p_5O",
	"formGroupLabel": "ModalBodyOrderScore_formGroupLabel__36nys",
	"formControlHolder": "ModalBodyOrderScore_formControlHolder__24cml",
	"labelWrapper": "ModalBodyOrderScore_labelWrapper__g0Dr3"
};
export default ___CSS_LOADER_EXPORT___;
