// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OffcanvasEmployeeEdit_deleteEmployee__1T-rR {\n  width: 100%; }\n\n.OffcanvasEmployeeEdit_buttonGroup__3QkuY {\n  display: flex;\n  flex-direction: column;\n  grid-row-gap: 8px;\n  row-gap: 8px; }\n", "",{"version":3,"sources":["webpack://src/components/Offcanvas/OffcanvasEmployeeEdit/OffcanvasEmployeeEdit.module.scss"],"names":[],"mappings":"AAAA;EACE,WAAW,EAAA;;AAGb;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAY;EAAZ,YAAY,EAAA","sourcesContent":[".deleteEmployee {\n  width: 100%;\n}\n\n.buttonGroup {\n  display: flex;\n  flex-direction: column;\n  row-gap: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"deleteEmployee": "OffcanvasEmployeeEdit_deleteEmployee__1T-rR",
	"buttonGroup": "OffcanvasEmployeeEdit_buttonGroup__3QkuY"
};
export default ___CSS_LOADER_EXPORT___;
