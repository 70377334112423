import React from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import PartsWrapperCardManager from 'components/Parts/PartsWrapperCardManager/PartsWrapperCardManager';
import { array, object, string, bool } from 'prop-types';
import PlaceholderContent from 'componentsShared/PlaceholderContent/PlaceholderContent';
import cn from 'classnames';
import Loader from 'componentsShared/Loader/Loader';
import st from './PartsList.module.scss';
import './PartsListStyle.module.scss';

PartsList.propTypes = {
  classNameList: string,
  dataList: object,
  ids: array,
  isCustomerNameShow: bool,
  isCustomerRole: bool,
  emptyText: string,
  searchEmptyText: string,
  isSearch: bool,
};

function PartsList({
  classNameList,
  dataList = {},
  ids = [],
  isCustomerNameShow = false,
  isCustomerRole = false,
  isLoading = false,
  emptyText = 'Empty',
  searchEmptyText = 'Empty',
  isSearch = false,
  page = '',
}) {
  const renderPartsList = () => {
    return (
      <TransitionGroup>
        {ids.map((id) => {
          const currentData = dataList[id];
          return (
            <CSSTransition
              key={id}
              timeout={{
                appear: 0,
                enter: 300,
                exit: 300,
              }}
              classNames='partsItem'>
              {/* content should be wrapped by div to hold styles that drops from CSSTransition container */}
              <div>
                <PartsWrapperCardManager
                  data={currentData}
                  isCustomerNameShow={isCustomerNameShow}
                  isCustomerRole={isCustomerRole}
                  page={page}
                />
              </div>
            </CSSTransition>
          );
        })}
      </TransitionGroup>
    );
  };

  const renderEmptyContainer = () => {
    return (
      <PlaceholderContent
        emptyText={emptyText}
        searchEmptyText={searchEmptyText}
        isSearch={isSearch}
      />
    );
  };

  return (
    <div
      className={cn(st.container, classNameList)}
      style={{ position: 'relative' }}>
      {ids?.length > 0 ? renderPartsList() : renderEmptyContainer()}
      <Loader isVisible={isLoading} isBackgroundColorGray />
    </div>
  );
}

export default PartsList;
