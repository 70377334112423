import React from 'react';
import { node, bool, string, func } from 'prop-types';
import Header from 'components/Header/Header';
import cn from 'classnames';
import st from './CenteredColumnLayout.module.scss';

CenteredColumnLayout.propTypes = {
  children: node.isRequired,
  showNavbar: bool,
  showUserMenu: bool,
  classNamePageContentHolder: string,
  classNamePageWrapper: string,
  renderFooter: func,
};

function CenteredColumnLayout({
  children,
  showNavbar = true,
  showUserMenu = true,
  classNamePageWrapper,
  renderFooter,
  classNamePageContentHolder,
}) {
  const isFooter = typeof renderFooter === 'function';

  return (
    <div className={st.rootWrapper}>
      <div className={st.headerHolder}>
        <Header showNavbar={showNavbar} showUserMenu={showUserMenu} />
      </div>
      <div className={cn(st.pageWrapper, classNamePageWrapper)}>
        <div className={cn(st.pageContentHolder, classNamePageContentHolder)}>
          {children}
        </div>
      </div>
      {isFooter && renderFooter()}
    </div>
  );
}

export default CenteredColumnLayout;
