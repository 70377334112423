import { createAction } from '@reduxjs/toolkit';
import { JOBS_TYPES_BACKEND } from 'constants/jobs';
import { STATUS_CREATE_JOB, STATUS_UPLOAD_FILE } from 'constants/worklog';
import { v4 as uuidv4 } from 'uuid';
import { ucFirst } from './string';

export const getRequestAction = (actionType = '') => ({
  start: createAction(`${actionType}/start`),
  success: createAction(`${actionType}/success`),
  failure: createAction(`${actionType}/failure`),
});

export const getParsingArrayToObj = (array, key = 'id') => {
  if (!array || array.length === 0) {
    return {};
  }
  return array.reduce((acc, item) => ({ ...acc, [item[key]]: item }), {});
};

const getValidStepsValue = (steps) => {
  if (!steps || steps.length === 0) {
    return [];
  }

  return steps.map((step) => {
    const currentStep = { ...step };
    let currentOperations = [];
    if (step.operations.length > 0) {
      currentOperations = step.operations.map((operation) => {
        const currentOperation = {
          ...operation,
        };
        delete currentOperation.id;

        return currentOperation;
      });
    }

    delete currentStep.id;
    return { ...currentStep, operations: currentOperations };
  });
};

const getValidStepsForTemplate = (steps) => {
  if (!steps || steps.length === 0) {
    return [];
  }

  return steps.reduce((accStep, stepItem) => {
    const currentStep = { ...stepItem };
    let currentOperations = [];
    let controlValueObj = { bundle_id: null, bundle_idx: null };
    let countSkipOperationForCV = 0;

    if (currentStep.operations.length > 0) {
      currentOperations = currentStep.operations.reduce(
        (accOperation, operationItem) => {
          const currentOperation = { ...operationItem };

          if (currentOperation.control) {
            if (currentOperation.bundle_id) {
              controlValueObj = {
                bundle_id: currentOperation.bundle_id,
                bundle_idx: countSkipOperationForCV,
              };
            }
            countSkipOperationForCV += 1;
            delete currentOperation.id;
            delete currentOperation.control;

            return [...accOperation, currentOperation];
          }
          return accOperation;
        },
        []
      );
    }

    if (currentOperations.length > 0) {
      delete currentStep.id;
      return [
        ...accStep,
        {
          ...currentStep,
          operations: currentOperations,
          bundle_id: controlValueObj.bundle_id ? currentStep.bundle_id : null,
          bundle_idx:
            typeof controlValueObj.bundle_idx === 'number'
              ? controlValueObj.bundle_idx
              : null,
        },
      ];
    }

    return accStep;
  }, []);
};

export const getValidValueCreateTraveler = (value, phaseKeyList = []) => {
  const { part_type_id, id, ...phase } = value;
  const phaseArray = Object.values(phase);

  let currentSteps = [];

  if (phaseKeyList.length > 0) {
    currentSteps = phaseKeyList.reduce((acc, item) => {
      const stepList =
        phaseArray.find(({ steps }) => steps?.[0]?.phase === item)?.steps || [];
      const validStep = getValidStepsValue(stepList);
      return [...acc, ...validStep];
    }, []);
  } else {
    currentSteps = phaseArray.reduce((acc, item) => {
      return [...acc, ...getValidStepsValue(item?.steps)];
    }, []);
  }

  return {
    part_type_id,
    steps: currentSteps,
  };
};

export const getValidStepsByTemplate = (value) => {
  const { name, id, phaseList, ...phase } = value;

  const currentSteps = Object.values(phase).reduce((acc, item) => {
    return [...acc, ...getValidStepsForTemplate(item?.steps)];
  }, []);

  return {
    steps: currentSteps,
    phases: phaseList,
  };
};

export const addControlInOperation = (operationList) => {
  return operationList.map((operationItem) => ({
    ...operationItem,
    control: false,
  }));
};

export const validationTemlate = (template) => {
  const currentTemplate = deepClone(template);
  currentTemplate.steps = currentTemplate.steps.map((stepItem) => {
    const controlValueIndex = stepItem?.bundle_idx;
    const currentOperations = [...stepItem.operations];

    if (typeof controlValueIndex === 'number') {
      currentOperations[controlValueIndex] = {
        ...currentOperations[controlValueIndex],
        bundle_id: stepItem.bundle_id,
        isControlValues: true,
      };
    }

    return {
      ...stepItem,
      operations: addControlInOperation(currentOperations),
    };
  });

  const phases = currentTemplate?.steps?.reduce((acc, item) => {
    return {
      ...acc,
      [item.phase]: {
        ...(acc?.[item.phase] || {}),
        steps: [...(acc?.[item.phase]?.steps || []), item],
      },
    };
  }, {});

  return {
    id: currentTemplate.id,
    name: currentTemplate.name,
    author: currentTemplate.author,
    created: currentTemplate.created,
    phaseList: currentTemplate.phases,
    ...phases,
  };
};

export const deepClone = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

export const getDataFile = (data) => {
  const dataArray = Object.keys(data);
  const dataFile =
    dataArray?.length === 0
      ? {}
      : dataArray.reduce((acc, item) => {
          const currentData = data?.[item];
          if (!currentData || Object.keys(currentData)?.length === 0) {
            return acc;
          }

          return {
            ...acc,
            [item]: {
              files: getParsingArrayToObj(currentData, 'path'),
              name: ucFirst(item),
            },
          };
        }, {});

  return dataFile;
};

export const structureTransformationFiles = (value) => {
  const currentValue = deepClone(value);

  return {
    ...getDataFile(currentValue?.files),
    ...getDataFile(currentValue?.additional_files),
  };
};

export const addIdTravelers = (arr) => {
  if (!arr || arr.length === 0) {
    return [];
  }

  return arr.map((item) => {
    const res = { ...item };
    const keys = Object.keys(item);
    const nestedKey = keys.find((key) => ['steps', 'operations'].includes(key));
    if (nestedKey) {
      res[nestedKey] = addIdTravelers(item[nestedKey]);
    }
    return {
      ...res,
      id: res.id ? res.id : Math.floor(Math.random() * 10000),
    };
  });
};

export const getTemplateWithControl = (requestTemplate) => {
  const templateList = addIdTravelers(requestTemplate);
  const validTemplateList = templateList.map((templateItem) => {
    return validationTemlate(templateItem);
  });
  const temlateListObj = getParsingArrayToObj(validTemplateList);
  const temlateIds = Object.keys(temlateListObj);

  return {
    temlateListObj,
    temlateIds,
  };
};

export const changeAllTemplateOperationControl = (
  templateId,
  templateList,
  value = true
) => {
  const { id, name, phaseList, ...phaseObject } = templateList?.[templateId];

  const currentPhases = phaseList.reduce((acc, { name: phaseName }) => {
    const currentStep = phaseObject?.[phaseName]?.steps?.map((stepItem) => {
      const cuurentOperation = stepItem.operations.map((operationItem) => ({
        ...operationItem,
        control: value,
      }));
      return { ...stepItem, operations: cuurentOperation };
    });

    return {
      ...acc,
      [phaseName]: {
        ...phaseObject[phaseName],
        steps: currentStep || [],
      },
    };
  }, {});

  return {
    ...templateList,
    [templateId]: { id, name, phaseList, ...currentPhases },
  };
};

export const addId = (array) => {
  if (!array || array.length === 0) {
    return [];
  }

  return array.map((item) => ({ ...item, id: uuidv4() }));
};

export const getValidEmployee = (data) => {
  return data.map((item) => ({
    ...item,
    id: uuidv4(),
    roles: item.roles,
    employeeId: item.id,
  }));
};

export const getPhases = (steps) => {
  if (!steps || steps.length === 0) {
    return {};
  }
  return steps?.reduce((acc, item) => {
    return {
      ...acc,
      [item.phase]: {
        ...(acc?.[item.phase] || {}),
        steps: [...(acc?.[item.phase]?.steps || []), item],
      },
    };
  }, {});
};

export const validationNewTraveler = (traveler) => {
  const phases = getPhases(traveler?.steps);

  return {
    id: traveler.id,
    part_type_id: traveler.part_type_id,
    ...phases,
  };
};

export const getValidValueCreateTemplate = (value, phaseList) => {
  const { name, id, ...phase } = value;
  const phaseSteps = Object.values(phase).filter((item) => !!item);

  const currentSteps = phaseList?.reduce((acc, item) => {
    const stepList =
      phaseSteps?.find(({ steps }) => {
        if (!steps) {
          return false;
        }
        return steps?.[0]?.phase === item?.name;
      })?.steps || [];
    const validStep = getValidStepsValue(stepList);
    return [...acc, ...validStep];
  }, []);

  return {
    id,
    name,
    steps: currentSteps,
    phases: phaseList,
  };
};

export const debounce = (callback, wait) => {
  let timeout;
  return (...args) => {
    const context = this;
    clearTimeout(timeout);
    timeout = setTimeout(() => callback.apply(context, args), wait);
  };
};

export const getValidControlValueList = (value) => {
  if (!value || value.length === 0) {
    return [];
  }

  return value.map((item) => {
    const headers = item.headers.map((itemHeaders) => ({
      value: itemHeaders,
      id: uuidv4(),
    }));

    return {
      ...item,
      headers,
    };
  });
};

export const getValidMyJobList = (value) => {
  return Object.entries(value).reduce(
    (acc, item) => {
      const type = item[0];
      const jobList = item[1];

      let quantityJob = 0;

      const validJobList =
        jobList?.map((jobItem) => {
          quantityJob += jobItem.length;

          return {
            id: uuidv4(),
            list: jobItem,
          };
        }) || [];

      const jobListId = validJobList?.map(({ id }) => id) || [];
      const currentData = getParsingArrayToObj(validJobList);

      let selectedStatus;
      let selectedQuantity;
      switch (JOBS_TYPES_BACKEND[type]) {
        case JOBS_TYPES_BACKEND.assigned:
          selectedStatus = 'assignedIds';
          selectedQuantity = 'assignedQuantity';
          break;
        case JOBS_TYPES_BACKEND.available:
          selectedStatus = 'availableIds';
          selectedQuantity = 'availableQuantity';
          break;
        case JOBS_TYPES_BACKEND.ncr:
          selectedStatus = 'ncrIds';
          selectedQuantity = 'ncrQuantity';
          break;
        default:
          selectedStatus = 'needsQAIds';
          selectedQuantity = 'needsQAQuantity';
      }

      return {
        ...acc,
        data: { ...acc.data, ...currentData },
        [selectedStatus]: jobListId,
        [selectedQuantity]: quantityJob,
        sortIds: [...acc.sortIds, ...jobListId],
      };
    },
    {
      data: {},
      sortIds: [],
    }
  );
};

export const getValidInterprocessJobList = (value) => {
  const jobList = value;
  let quantityJob = 0;

  const validJobList = jobList.map((jobItem) => {
    quantityJob += jobItem.length;

    return {
      id: uuidv4(),
      list: jobItem,
    };
  });
  const jobListId = validJobList?.map(({ id }) => id) || [];
  const currentData = getParsingArrayToObj(validJobList);

  return {
    data: { ...currentData },
    interprocessIds: [...jobListId],
    interprocessQuantity: quantityJob,
    sortIds: [...jobListId],
  };
};

export const addShowForPOList = (data) => {
  return data.map((item) => ({ ...item, show: false }));
};

export const handleShowOrderList = (data, isShow = true) => {
  const cloneData = deepClone(data);
  const dataArray = Object.values(cloneData).map((item) => ({
    ...item,
    show: isShow,
  }));
  return getParsingArrayToObj(dataArray);
};

export const getIsOperationTraveler = (steps) => {
  if (!steps || steps.length === 0) {
    return false;
  }

  return steps.every((item) => item?.operations?.length > 0);
};

export const getArrayTextStepWithoutOperations = (valueTraveler, phaseList) => {
  return phaseList.reduce((acc, { name }) => {
    if (
      !valueTraveler?.[name]?.steps ||
      valueTraveler[name].steps.length === 0
    ) {
      return acc;
    }

    let curretnAcc = acc;

    valueTraveler[name].steps.map((item) => {
      if (!item?.operations || item?.operations.length === 0) {
        curretnAcc = [...curretnAcc, `${name} / ${item.name}`];
      }
      return;
    });

    return curretnAcc;
  }, []);
};

export const getValidWorklogList = (worklog) => {
  const worklogValue = Object.values(worklog);
  if (!worklog || worklog.length === 0) {
    return [];
  }

  const currentValue = worklogValue.reduce(
    (acc, item) => {
      const isNewCurrentStep = acc.currentStep !== item.step_name;
      const isCreateJob = STATUS_CREATE_JOB === item?.type;
      const worklogList = deepClone(acc.worklogList);

      const controlValuesData =
        Object.keys(item?.control_values).length > 0
          ? {
              value: item.control_values,
              informal_qa: item.informal_qa,
            }
          : null;

      const files = item?.files?.length > 0 ? item?.files : null;
      const lastItemWorlogIndex = worklogList.length - 1;
      const lastItemWorlogType = worklogList?.[lastItemWorlogIndex]?.type || '';
      const isStatusUploadFile = item.type === STATUS_UPLOAD_FILE && files;

      const addingCurrentItem =
        !isStatusUploadFile ||
        (isStatusUploadFile && lastItemWorlogType !== STATUS_UPLOAD_FILE);

      const itemWorklog = {
        name: item.message,
        timestamp: item.timestamp,
        employee: item?.user?.name || '',
        additionalText: item.text,
        isControlValues: !!controlValuesData,
        controlValues: controlValuesData,
        isFiles: false,
        isListFile: isStatusUploadFile,
        files,
        type: item.type,
      };

      const newItemWorklog = {
        name: item.step_name,
        timestamp: item.timestamp,
        isStep: true,
        employee: item?.user?.name || '',
      };

      const listFile =
        files && !isStatusUploadFile
          ? files.map((itemFile) => ({
              name: 'Image added',
              timestamp: item.timestamp,
              isFiles: true,
              files: [itemFile],
              employee: item?.user?.name || '',
            }))
          : [];

      if (isStatusUploadFile && lastItemWorlogType === STATUS_UPLOAD_FILE) {
        worklogList[lastItemWorlogIndex].files = [
          ...worklogList[lastItemWorlogIndex].files,
          ...files,
        ];
      }

      if (isCreateJob) {
        return {
          ...acc,
          currentStep: isNewCurrentStep ? item.step_name : acc.currentStep,
          worklogList: [
            ...worklogList,
            ...(addingCurrentItem ? [itemWorklog] : []),
            newItemWorklog,
            ...listFile,
          ],
        };
      }

      return {
        ...acc,
        currentStep: isNewCurrentStep ? item.step_name : acc.currentStep,
        worklogList: [
          ...worklogList,
          ...(isNewCurrentStep ? [newItemWorklog] : []),
          ...(addingCurrentItem ? [itemWorklog] : []),
          ...listFile,
        ],
      };
    },
    { currentStep: '', worklogList: [] }
  );

  return currentValue?.worklogList;
};

export const getStepListArray = (array, currentStepIndex) => {
  if (!array || array.length === 0) {
    return [];
  }

  return array.reduce((acc, item, index) => {
    const currentItem = {
      step: item.name,
      phase: item.phase,
      index: currentStepIndex + index + 1,
    };
    const currentIndex = acc.findIndex(
      (value) => value?.[0]?.phase === item.phase
    );
    if (currentIndex > -1) {
      acc[currentIndex] = [...acc[currentIndex], currentItem];
    } else {
      acc.push([currentItem]);
    }
    return acc;
  }, []);
};

export const getValidTravelerForContorlValue = (value) => {
  const steps =
    value?.steps.map(
      ({
        phase,
        name,
        needs_qa,
        informal_qa,
        operations,
        bundle_id,
        bundle_idx,
      }) => {
        const operationList = operations || [];

        const bundleIdx =
          Number.isInteger(bundle_idx) &&
          Number.isInteger(bundle_id) &&
          bundle_idx < operationList.length
            ? bundle_idx
            : null;
        const bundleId =
          Number.isInteger(bundleIdx) && Number.isInteger(bundle_id)
            ? bundle_id
            : null;

        return {
          phase,
          name,
          needs_qa,
          informal_qa,
          operations: operationList,
          bundle_id: bundleId,
          bundle_idx: bundleIdx,
        };
      }
    ) || [];

  return { ...value, steps };
};

export const getValidQAListOperation = (step) => {
  if (!step?.operations || !Array.isArray(step?.operations)) {
    return [];
  }

  return (
    step?.operations?.reduce((acc, operation, index) => {
      let firstEvent = [];
      const currentPhase = step.phase;
      let event = {
        name: `Done: ${operation.name}`,
      };

      if (index === 0) {
        firstEvent = [
          {
            name: `Started: ${currentPhase}`,
            isStep: true,
          },
        ];
      }

      if (step.bundle_id && step.bundle_idx === index) {
        const controlValuesData =
          Object.keys(step?.control_values).length > 0
            ? {
                value: step.control_values,
              }
            : null;

        event = {
          ...event,
          name: operation.name,
          isControlValues: true,
          controlValues: controlValuesData,
        };
      }

      return [...acc, ...firstEvent, event];
    }, []) || []
  );
};

export const getValidTravelerStepList = (steps) => {
  if (!steps || !Array.isArray(steps)) {
    return [];
  }

  return (
    steps.map((stepItem) => {
      const controlValueIndex = stepItem?.bundle_idx;
      const currentOperations = [...stepItem.operations];

      if (typeof controlValueIndex === 'number') {
        currentOperations[controlValueIndex] = {
          ...currentOperations[controlValueIndex],
          isControlValues: true,
        };
      }

      return {
        ...stepItem,
        operations: currentOperations,
      };
    }) || []
  );
};

export const deleteSameValuesArray = (a = [], b = []) => {
  if (
    !Array.isArray(a) ||
    !Array.isArray(b) ||
    a.length === 0 ||
    b.length === 0
  ) {
    return [];
  }

  const result = [];
  a.forEach((v) => {
    if (b.indexOf(v) === -1) {
      result.push(v);
    }
  });
  return result;
};
