import { Toast, ToastContainer } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import * as uiActions from 'store/ui/actions';

import st from './Toasts.module.scss';

const ToastsProvider = () => {
  const dispatch = useDispatch();
  const toastsData = useSelector((state) => state?.ui?.toasts.data) ?? {};
  const toastIds = useSelector((state) => state?.ui?.toasts.sortIds) ?? [];

  const handleRemoveToast = (toastId) => {
    dispatch(uiActions.removeToast({ toastId }));
  };

  const renderToast = () => {
    return toastIds.map((id) => {
      const { delay, isCloseButton, title, text, variant } = toastsData[id];
      return (
        <Toast
          autohide={!!delay}
          delay={delay}
          onClose={() => handleRemoveToast(id)}
          key={id}
          className={st.notificationToast}>
          <Toast.Header
            closeButton={isCloseButton}
            className={st.toastHeaderWrapper}>
            <div className={cn(st.statusIndicator, st[variant])} />

            <h4 className={st.toastTitle}>{title}</h4>
          </Toast.Header>
          <Toast.Body>{text}</Toast.Body>
        </Toast>
      );
    });
  };

  return (
    <ToastContainer position='top-end' className={st.toastContainer}>
      {toastIds.length > 0 && renderToast()}
    </ToastContainer>
  );
};

export default ToastsProvider;
