import React from 'react';
import { MAX_LENGTH_INPUT, VARIANT_OUTLINE_UI, VARIANT_UI } from 'constants/ui';
import { Button, FormControl } from 'react-bootstrap';
import NavigationService from 'services/navigation/NavigationService';
import Text from 'componentsShared/Text/Text';
import { ROUTES } from 'router/routes';
import { Link } from 'react-router-dom';
import * as templatesActions from 'store/templates/actions';
import { useDispatch } from 'react-redux';
import ModalButton from 'componentsShared/ModalButton/ModalButton';
import { getValidPath } from 'helpers/path';
import st from './TemplateHeaderDetails.module.scss';

function TemplateHeaderDetails({
  isEditTemplate = false,
  template,
  handleToggleEditTemplate = () => {},
  handleChangeFormName = () => {},
  formNameValue = '',
  handleSaveTemplate = () => {},
  isNew = false,
  isDisabledSave = false,
}) {
  const dispatch = useDispatch();

  const handleRemoveTemplate = () => {
    dispatch(templatesActions.removeTemplate.start(template.id));
    NavigationService.navigateToPath(ROUTES.adminTravelerTemplates.path);
  };

  return (
    <div className={st.headerActive}>
      <div className={st.headerTemplateInfo}>
        <div className={st.holderTitleTemplate}>
          <Link to={getValidPath(ROUTES.adminTravelerTemplates.path)}>
            <Text variant='normal' className={st.titleTemplate}>
              Traveler Templates
            </Text>
          </Link>
        </div>
        <div className={st.headerTempalteName}>
          /
          {isEditTemplate ? (
            <FormControl
              onChange={handleChangeFormName}
              size='sm'
              value={formNameValue}
              maxLength={MAX_LENGTH_INPUT}
            />
          ) : (
            <Text variant='normal' bold className={st.headerTempalteNameText}>
              {template?.name}
            </Text>
          )}
        </div>
        {isEditTemplate ? (
          <Button
            size='sm'
            variant={VARIANT_UI.primary}
            onClick={handleSaveTemplate}
            disabled={isDisabledSave}>
            Save Template
          </Button>
        ) : (
          <Button
            size='sm'
            variant={VARIANT_OUTLINE_UI.primary}
            onClick={() => handleToggleEditTemplate(!isEditTemplate)}>
            Edit Template
          </Button>
        )}
      </div>
      {isEditTemplate && !isNew && (
        <div>
          <ModalButton
            title='Delete Template'
            titleAcceptBtn='Delete'
            onAcceptBtn={handleRemoveTemplate}
            variantAcceptBtn={VARIANT_UI.danger}
            bodyContent={() => (
              <div className={st.wrapperTextDelete}>
                <Text className={st.holderTextDelete} variant='normal'>
                  You are about to delete template:
                  <Text bold variant='normal'>
                    {template?.name}
                  </Text>
                  This action can not be undone.
                </Text>
              </div>
            )}
            buttonRenderer={({ onClick: openModal }) => (
              <Button
                size='sm'
                variant={VARIANT_OUTLINE_UI.danger}
                onClick={openModal}>
                Delete Template
              </Button>
            )}
          />
        </div>
      )}
    </div>
  );
}

export default TemplateHeaderDetails;
