import Text from 'componentsShared/Text/Text';
import React from 'react';
import cn from 'classnames';
import { ucFirst } from 'helpers/string';
import { VARIANT_UI } from 'constants/ui';
import { string } from 'prop-types';
import st from './Status.module.scss';

Status.propTypes = {
  title: string,
  className: string,
  variant: string,
  classNameText: string,
  size: string,
};

function Status({
  title = '',
  className,
  variant = VARIANT_UI.primary,
  classNameText,
  size = '',
}) {
  const variantText = size === 'small' ? 'normal3' : 'normal';

  return (
    <div className={cn(st.statusWrapper, className, st[variant])}>
      <Text
        variant={variantText}
        className={cn(st.statusText, classNameText)}
        bold>
        {ucFirst(title.toLowerCase())}
      </Text>
    </div>
  );
}

export default Status;
