// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JobsMultiDropDownNCR_holderSelectStep__3gGlx {\n  margin-top: 16px;\n  width: calc(50% - 16px); }\n", "",{"version":3,"sources":["webpack://src/components/Jobs/JobsMultiDropDownNCR/JobsMultiDropDownNCR.module.scss"],"names":[],"mappings":"AAEA;EACE,gBAAgB;EAChB,uBAAuB,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.holderSelectStep {\n  margin-top: 16px;\n  width: calc(50% - 16px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"holderSelectStep": "JobsMultiDropDownNCR_holderSelectStep__3gGlx"
};
export default ___CSS_LOADER_EXPORT___;
