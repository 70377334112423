import React, { useEffect, useRef, useState } from 'react';
import { VARIANT_OUTLINE_UI, VARIANT_UI } from 'constants/ui';
import Text from 'componentsShared/Text/Text';
import Modal from 'componentsShared/Modal/Modal';
import cn from 'classnames';
import DropdownButton from 'componentsShared/DropdownButton/DropdownButton';
import { MoreVertIcon, WarningIcon } from 'assets/icons';
import { array, string, func, bool } from 'prop-types';
import Tooltip from 'componentsShared/Tooltip/Tooltip';
import st from './PartsCard.module.scss';

PartsCard.propTypes = {
  partName: string,
  partNumber: string,
  travelerStatusText: string,
  handleRemove: func,
  cutomerName: string,
  isRemoving: bool,
  showModalRemove: bool,
  setShowModalRemove: func,
  isAddingCurrentPart: bool,
  dropdownList: array,
  isNeedsTraveler: bool,
  handleClickCard: func,
  isCustomerRole: bool,
};

function PartsCard({
  partName = '',
  partNumber = '',
  travelerStatusText = '',
  handleRemove = () => {},
  cutomerName = '',
  isRemoving = false,
  showModalRemove = false,
  setShowModalRemove = () => {},
  isAddingCurrentPart = false,
  dropdownList = [],
  isNeedsTraveler = false,
  handleClickCard = () => {},
  id,
  isCustomerRole = false,
}) {
  const holderTitleRef = useRef(null);
  const partNameRef = useRef(null);
  const partNumberRef = useRef(null);

  const [isTooltip, setIsTooltip] = useState(false);

  const renderPartInfo = () => {
    return (
      <div className={st.partInfo}>
        <Text className={st.holderTextPartInfo}>
          <Text variant='normal2' currentRef={partNameRef}>
            {partName}
          </Text>
          <Text
            variant='normal'
            className={st.partNumber}
            currentRef={partNumberRef}>
            {partNumber}
          </Text>
        </Text>
      </div>
    );
  };

  const handleClickDropdown = (e) => {
    e.stopPropagation();
  };

  useEffect(() => {
    if (
      partName &&
      partNumber &&
      partNameRef.current?.offsetWidth + partNumberRef.current?.offsetWidth >
        holderTitleRef.current?.offsetWidth
    ) {
      setIsTooltip(true);
    }
  }, [partName, partNumber]);

  return (
    <>
      <div className={st.rootWrapper} id={`part-card-${id}`}>
        <div
          className={cn(st.containerCard, {
            [st.containerCardNeedsTraveler]: isNeedsTraveler,
            [st.cursorDefault]: isCustomerRole,
          })}
          onClick={handleClickCard}>
          <div className={st.holderPartInfo} ref={holderTitleRef}>
            <Tooltip
              placement='auto'
              textTooltip={`${partName}${partNumber}`}
              isTooltip={isTooltip}>
              {renderPartInfo()}
            </Tooltip>

            <div className={st.holderPartAdditionalInfo}>
              {isNeedsTraveler && <WarningIcon className={st.warningIcon} />}
              <Text variant='normal2' className={st.travelerStatus}>
                {travelerStatusText}
              </Text>
            </div>
          </div>
          {!isCustomerRole && (
            <div className={st.holderCardAction}>
              <DropdownButton
                list={dropdownList}
                className={st.moreButton}
                startIcon={<MoreVertIcon />}
                btnVariant={VARIANT_OUTLINE_UI.light}
                arrowNone
                size='lg'
                onClick={handleClickDropdown}
              />
            </div>
          )}
        </div>
        <div
          className={cn(st.removingOverlay, {
            [st.removingOverlayVisible]: isRemoving,
          })}
        />
        {isAddingCurrentPart && <div className={st.addingAnimation} />}
      </div>
      <Modal
        title='Remove Part'
        show={showModalRemove}
        setShow={setShowModalRemove}
        titleAcceptBtn='Remove'
        onAcceptBtn={handleRemove}
        variantAcceptBtn={VARIANT_UI.danger}>
        <div className={st.wrapperModalContent}>
          <div>
            <Text variant='normal'>Would you like to remove Part</Text>{' '}
            <Text variant='normal' bold>
              {cutomerName}: {partName}
            </Text>
          </div>
          <Text variant='normal' bold>
            {partNumber}?
          </Text>
        </div>
      </Modal>
    </>
  );
}

export default PartsCard;
