import React, { useMemo, useState } from 'react';
import Modal from 'componentsShared/Modal/Modal';
import { func, bool, string } from 'prop-types';
import { Button } from 'react-bootstrap';
import { VARIANT_OUTLINE_UI } from 'constants/ui';
import ReaderQRContent from 'componentsShared/ReaderQRContent/ReaderQRContent';
import { JOB_ERROR_QR_CODE, TYPE_MODAL } from 'constants/jobs';
import { LockIcon, QRIcon, UFOIcon, WarningIcon } from 'assets/icons';
import ReaderFindIdJobContent from 'componentsShared/ReaderFindIdJobContent/ReaderFindIdJobContent';
import ReaderQRContentErrorOrLoader from 'componentsShared/ReaderQRContentErrorOrLoader/ReaderQRContentErrorOrLoader';
import st from './ModalQR.module.scss';

ModalQR.propTypes = {
  show: bool,
  setShow: func,
  title: string,
  handleReadQR: func,
  handleCheckId: func,
  handleReaderError: func,
  modalType: string,
  handleChangeType: func,
  isErrorReaderQR: bool,
  isError: bool,
  isLoading: bool,
  onCancelModal: func,
  onlyQRCode: bool,
};

function ModalQR({
  show,
  setShow,
  title = 'QR-code login',
  handleReadQR = () => {},
  handleCheckId = () => {},
  handleReaderError = () => {},
  modalType = TYPE_MODAL.qrCode,
  handleChangeType = () => {},
  isErrorReaderQR = false,
  isError = false,
  typeError = null,
  isLoading = false,
  onCancelModal = () => {},
  onlyQRCode = false,
}) {
  const [selectCamera, setSelectCamera] = useState('user');

  const dataError = useMemo(() => {
    if (!isError || !typeError) {
      return null;
    }

    const errorObj = {
      text: JOB_ERROR_QR_CODE.notMatchSeries.text,
      icon: () => <WarningIcon className={st.errorIconWarning} />,
    };

    if (typeError === JOB_ERROR_QR_CODE.isNotAvailable.type) {
      errorObj.text = JOB_ERROR_QR_CODE.isNotAvailable.text;
      errorObj.icon = () => <LockIcon />;
    }

    if (typeError === JOB_ERROR_QR_CODE.unknown.type) {
      errorObj.text = JOB_ERROR_QR_CODE.unknown.text;
      errorObj.icon = () => <UFOIcon className={st.errorIconUFO} />;
    }

    return errorObj;
  }, [isError, typeError]);

  const renderButtonBottomRight = () => {
    if (isErrorReaderQR && modalType === TYPE_MODAL.qrCode && !onlyQRCode) {
      return (
        <Button
          onClick={() => handleChangeType(TYPE_MODAL.findId)}
          variant={VARIANT_OUTLINE_UI.primary}>
          # Find By ID
        </Button>
      );
    }

    if (isError && typeError) {
      return (
        <div className={st.holderButtonBottomRight}>
          {!onlyQRCode && (
            <Button
              onClick={() => handleChangeType(TYPE_MODAL.findId)}
              variant={VARIANT_OUTLINE_UI.primary}>
              # Find By ID
            </Button>
          )}
          <Button
            className={st.buttonBottomRightQR}
            onClick={() => handleChangeType(TYPE_MODAL.qrCode)}
            variant={VARIANT_OUTLINE_UI.primary}>
            <div className={st.holderTextButtonQR}>
              <QRIcon className={st.QRIcon} />
              Scan QR
            </div>
          </Button>
        </div>
      );
    }

    return null;
  };

  const renderContent = () => {
    if (!show) {
      return <></>;
    }

    if (isLoading || isErrorReaderQR) {
      return (
        <ReaderQRContentErrorOrLoader
          isLoading={isLoading}
          isError={isErrorReaderQR}
          textLoading='Searching...'
          textError='QR code not found'
          showIconQR={!isLoading}
        />
      );
    }

    if (isError && typeError) {
      return (
        <ReaderQRContentErrorOrLoader
          isContent
          contentIcon={dataError.icon}
          contentText={dataError.text}
          showIconQR={false}
          classBodyContentStatus={st.classBodyContentStatusForError}
        />
      );
    }

    if (modalType === TYPE_MODAL.qrCode) {
      return (
        <ReaderQRContent
          handleReadQR={handleReadQR}
          handleError={handleReaderError}
          selectCamera={selectCamera}
          setSelectCamera={setSelectCamera}
        />
      );
    }

    return <ReaderFindIdJobContent handleCheckId={handleCheckId} />;
  };

  return (
    <Modal
      title={title}
      show={show}
      setShow={setShow}
      heightBody={310}
      buttonBottomRight={() => renderButtonBottomRight()}
      onClose={onCancelModal}
      buttonBottomLeft={({ onClose }) => (
        <Button onClick={onClose} variant={VARIANT_OUTLINE_UI.primary}>
          Cancel
        </Button>
      )}
      classNameBody={st.bodyModal}>
      {renderContent()}
    </Modal>
  );
}

export default ModalQR;
