import { createReducer } from '@reduxjs/toolkit';
import { deepClone, validationNewTraveler } from 'helpers/functions';

import * as actions from './actions';

export const initialState = {
  newTraveler: {},
  newPhaseList: [],
  isDirtyNewTraveler: false,
  travelers: {
    data: {},
    sortIds: [],
    isLoading: false,
  },
  phaseList: {
    data: {},
    sortIds: [],
    isLoading: false,
  },
  files: {
    isLoading: false,
    data: {},
  },
  suggest: {
    operation: { all: [] },
    step: { all: [] },
    isLoading: false,
  },
};

export const travelersReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.updateNewTravelerData, (state, action) => {
      const { phase, field, value } = action.payload;

      return {
        ...state,
        newTraveler: {
          ...state.newTraveler,
          [phase]: {
            ...state.newTraveler[phase],
            [field]: value,
          },
        },
        isDirtyNewTraveler: true,
      };
    })
    .addCase(actions.updateDetailsTravelerData, (state, action) => {
      const cuurentTraveler = action.payload;
      const validValue = validationNewTraveler(cuurentTraveler);
      return {
        ...state,
        newTraveler: validValue,
      };
    })
    .addCase(actions.getTravelerList.start, (state) => ({
      ...state,
      travelers: { ...state.travelers, isLoading: true },
    }))
    .addCase(actions.getTravelerList.success, (state, action) => ({
      ...state,
      travelers: {
        ...state.travelers,
        data: action.payload.fetchedValue,
        sortIds: action.payload.ids,
        isLoading: false,
      },
    }))
    .addCase(actions.getTravelerList.failure, (state) => ({
      ...state,
      travelers: { ...state.travelers, isLoading: false },
    }))
    .addCase(actions.createTraveler.start, (state) => ({
      ...state,
      travelers: {
        ...state.travelers,
        isLoading: true,
      },
    }))
    .addCase(actions.createTraveler.success, (state, action) => {
      const currentTraveler = action.payload.fetchedValue;

      return {
        ...state,
        travelers: {
          ...state.travelers,
          data: {
            ...state.travelers.data,
            [currentTraveler.id]: currentTraveler,
          },
          sortIds: [...state.travelers.sortIds, currentTraveler.id],
          isLoading: false,
        },
      };
    })
    .addCase(actions.createTraveler.failure, (state) => ({
      ...state,
      travelers: {
        ...state.travelers,
        isLoading: false,
      },
    }))
    .addCase(actions.getTraveler.start, (state) => ({
      ...state,
      travelers: { ...state.travelers, isLoading: true },
    }))
    .addCase(actions.getTraveler.success, (state, action) => {
      const travelerIds = state.travelers.sortIds;
      const currentTravelersIds = travelerIds.includes(action.payload.id)
        ? travelerIds
        : [...travelerIds, action.payload.id];

      return {
        ...state,
        travelers: {
          ...state.travelers,
          data: { ...state.travelers.data, ...action.payload.fetchedValue },
          sortIds: currentTravelersIds,
          isLoading: false,
        },
      };
    })
    .addCase(actions.getTraveler.failure, (state) => ({
      ...state,
      travelers: { ...state.travelers, isLoading: false },
    }))
    .addCase(actions.addFileTraveler.start, (state) => ({
      ...state,
      files: { ...state.files, isLoading: true },
    }))
    .addCase(actions.addFileTraveler.success, (state) => {
      return {
        ...state,
        files: {
          ...state.files,
          isLoading: false,
        },
      };
    })
    .addCase(actions.addFileTraveler.failure, (state) => ({
      ...state,
      files: { ...state.files, isLoading: false },
    }))
    .addCase(actions.getFileTraveler.start, (state) => ({
      ...state,
      files: { ...state.files, isLoading: true },
    }))
    .addCase(actions.getFileTraveler.success, (state, action) => {
      const dataFiles = action.payload;
      return {
        ...state,
        files: {
          ...state.files,
          data: { ...dataFiles },
          isLoading: false,
        },
      };
    })
    .addCase(actions.getFileTraveler.failure, (state) => ({
      ...state,
      files: { ...state.files, isLoading: false },
    }))
    .addCase(actions.updateTraveler.start, (state) => ({
      ...state,
      travelers: { ...state.travelers, isLoading: true },
    }))
    .addCase(actions.updateTraveler.success, (state, action) => {
      const { travelerData } = action.payload;
      return {
        ...state,
        travelers: {
          ...state.travelers,
          data: { ...state.travelers.data, ...travelerData },
          isLoading: false,
        },
      };
    })
    .addCase(actions.updateTraveler.failure, (state) => ({
      ...state,
      travelers: { ...state.travelers, isLoading: false },
    }))
    .addCase(actions.removeTraveler.start, (state) => ({
      ...state,
      travelers: { ...state.travelers, isLoading: true },
    }))
    .addCase(actions.removeTraveler.success, (state, action) => {
      const { removeTravelerId } = action.payload;
      const cloneState = deepClone(state);
      delete cloneState.travelers.data[removeTravelerId];
      const currentSortIds = cloneState.travelers.sortIds.filter(
        (id) => id.toString() !== removeTravelerId.toString()
      );

      return {
        ...state,
        travelers: {
          ...state.travelers,
          data: { ...cloneState.travelers.data },
          sortIds: [...currentSortIds],
          isLoading: false,
        },
      };
    })
    .addCase(actions.removeTraveler.failure, (state) => ({
      ...state,
      travelers: { ...state.travelers, isLoading: false },
    }))
    .addCase(actions.submitTraveler.start, (state) => ({
      ...state,
      travelers: { ...state.travelers, isLoading: true },
    }))
    .addCase(actions.submitTraveler.success, (state, action) => {
      const { travelerData } = action.payload;
      return {
        ...state,
        travelers: {
          ...state.travelers,
          data: { ...state.travelers.data, ...travelerData },
          isLoading: false,
        },
      };
    })
    .addCase(actions.submitTraveler.failure, (state) => ({
      ...state,
      travelers: { ...state.travelers, isLoading: false },
    }))

    .addCase(actions.getPhaseList.start, (state) => ({
      ...state,
      phaseList: { ...state.phaseList, isLoading: true },
    }))
    .addCase(actions.getPhaseList.success, (state, action) => {
      const { data } = action.payload;
      return {
        ...state,
        phaseList: { ...state.phaseList, isLoading: false, data },
      };
    })
    .addCase(actions.getPhaseList.failure, (state) => ({
      ...state,
      phaseList: { ...state.phaseList, isLoading: false },
    }))
    .addCase(actions.getSuggestOperationByPhase.start, (state) => ({
      ...state,
      suggest: { ...state.suggest, isLoading: true },
    }))
    .addCase(actions.getSuggestOperationByPhase.success, (state, action) => {
      const data = action.payload;

      return {
        ...state,
        suggest: {
          ...state.suggest,
          isLoading: false,
          operation: {
            ...state.suggest.operation,
            ...data,
          },
        },
      };
    })
    .addCase(actions.getSuggestOperationByPhase.failure, (state) => ({
      ...state,
      suggest: { ...state.suggest, isLoading: false },
    }))
    .addCase(actions.getSuggestStepByPhase.start, (state) => ({
      ...state,
      suggest: { ...state.suggest, isLoading: true },
    }))
    .addCase(actions.getSuggestStepByPhase.success, (state, action) => {
      const data = action.payload;

      return {
        ...state,
        suggest: {
          ...state.suggest,
          isLoading: false,
          step: {
            ...state.suggest.step,
            ...data,
          },
        },
      };
    })
    .addCase(actions.getSuggestStepByPhase.failure, (state) => ({
      ...state,
      suggest: { ...state.suggest, isLoading: false },
    }))
    .addCase(actions.discardChangesTravelerData, (state) => {
      const currentNewTraveler = deepClone(state.newTraveler);

      Object.keys(currentNewTraveler).forEach((phase) => {
        if (currentNewTraveler?.[phase]?.steps) {
          delete currentNewTraveler[phase];
        }
      });

      return {
        ...state,
        isDirtyNewTraveler: true,
        newTraveler: { ...currentNewTraveler },
      };
    })
    .addCase(actions.changeDirtyNewTraveler, (state, action) => {
      const value = action?.payload;
      return {
        ...state,
        isDirtyNewTraveler: !!value,
      };
    })
    .addCase(actions.updateNewPhaseList, (state, action) => {
      const data = action?.payload || [];
      return {
        ...state,
        newPhaseList: data,
      };
    });
});
