// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JobListTabs_containerTabList__1SoUb {\n  display: flex;\n  justify-content: center;\n  padding-bottom: 12px; }\n\n.JobListTabs_containerContent__1hsOb {\n  display: flex;\n  justify-content: center;\n  overflow-y: auto;\n  max-height: calc(100vh - 213px);\n  min-height: calc(100vh - 213px); }\n\n.JobListTabs_wrapperTabList__8359A,\n.JobListTabs_container__2xBVE {\n  width: 900px; }\n", "",{"version":3,"sources":["webpack://src/components/Jobs/JobListTabs/JobListTabs.module.scss"],"names":[],"mappings":"AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,oBAAoB,EAAA;;AAGtB;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;EAChB,+BAA+B;EAC/B,+BAA+B,EAAA;;AAGjC;;EAEE,YAAY,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.containerTabList {\n  display: flex;\n  justify-content: center;\n  padding-bottom: 12px;\n}\n\n.containerContent {\n  display: flex;\n  justify-content: center;\n  overflow-y: auto;\n  max-height: calc(100vh - 213px);\n  min-height: calc(100vh - 213px);\n}\n\n.wrapperTabList,\n.container {\n  width: 900px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"containerTabList": "JobListTabs_containerTabList__1SoUb",
	"containerContent": "JobListTabs_containerContent__1hsOb",
	"wrapperTabList": "JobListTabs_wrapperTabList__8359A",
	"container": "JobListTabs_container__2xBVE"
};
export default ___CSS_LOADER_EXPORT___;
