import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import { handleRequestError } from 'services/api/apiTools';
import { PAGE_SIZE } from 'constants/ui';
import ApiService from 'services/api';
import { addId, getParsingArrayToObj } from 'helpers/functions';
import * as actions from './actions';

export function* getNCRListSaga(action) {
  const { isPagination, include_deleted } = action?.payload || {};

  const filterSearch = yield select((state) =>
    state?.ncr?.fetchParams?.search?.trim()
  ) ?? '';

  const page = yield select(
    (state) => state?.ncr?.fetchParams?.pagination?.page
  ) ?? 0;
  const size = yield select(
    (state) => state?.ncr?.fetchParams?.pagination?.size
  ) ?? PAGE_SIZE;

  const filter = {
    filter: filterSearch,
    page: isPagination ? page : null,
    size: isPagination ? size : null,
    include_deleted,
  };

  let requestValue;
  try {
    requestValue = yield call(ApiService.getNCRList, filter);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getNCRList.failure());
    return;
  }
  let headers = requestValue?.headers?.map((item) => ({ label: item })) || [];
  headers = addId(headers);

  const ids = requestValue?.id ? [requestValue.id] : [];

  yield put(
    actions.getNCRList.success({
      data: { ...requestValue, headers },
      dataHeaders: getParsingArrayToObj(headers),
      ids,
    })
  );
}

export function* createNCRSaga(action) {
  const params = action.payload;

  try {
    yield call(ApiService.createNCR, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.createNCR.failure());
    return;
  }

  yield put(actions.getNCRList.start());
  yield put(actions.createNCR.success());
}

export function* removeNCRSaga() {
  try {
    yield call(ApiService.removeNCR);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.removeNCR.failure());
    return;
  }

  yield put(actions.getNCRList.start());
  yield put(actions.removeNCR.success());
}

export function* ncrSagas() {
  yield all([
    yield takeLatest(actions.getNCRList.start, getNCRListSaga),
    yield takeLatest(actions.createNCR.start, createNCRSaga),
    yield takeLatest(actions.removeNCR.start, removeNCRSaga),
  ]);
}
