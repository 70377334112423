import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import * as authActions from 'store/auth/actions';

function SignOutPage() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(authActions.signOut({ isRedirect: true }));
  }, [dispatch]);

  return null;
}

export default SignOutPage;
