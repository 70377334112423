import React from 'react';
import { Offcanvas as OffcanvasBootstrap } from 'react-bootstrap';
import { bool, func, string, node, number } from 'prop-types';
import Text from 'componentsShared/Text/Text';
import st from './Offcanvas.module.scss';

Offcanvas.propTypes = {
  show: bool.isRequired,
  setShow: func.isRequired,
  placement: string,
  closeButton: bool,
  title: string,
  onHide: func,
  children: node,
  width: number,
  footerContent: func,
  backdrop: bool,
};

function Offcanvas({
  show,
  setShow,
  placement = 'end',
  closeButton = true,
  title = '',
  onHide = () => {},
  children,
  width = 400,
  footerContent,
  backdrop = true,
}) {
  const handleHide = () => {
    setShow(false);
    onHide();
  };

  return (
    <>
      <OffcanvasBootstrap
        show={show}
        onHide={handleHide}
        placement={placement}
        backdrop={backdrop}
        style={{ width: `${width}px` }}>
        <OffcanvasBootstrap.Header
          closeButton={closeButton}
          className={st.headerHolder}>
          <OffcanvasBootstrap.Title>
            <Text variant='normal' bold>
              {title}
            </Text>
          </OffcanvasBootstrap.Title>
        </OffcanvasBootstrap.Header>
        <OffcanvasBootstrap.Body>{children}</OffcanvasBootstrap.Body>

        {typeof footerContent === 'function' && (
          <div className={st.footerContainer}>
            {footerContent({ onHide: handleHide })}
          </div>
        )}
      </OffcanvasBootstrap>
      {show && !backdrop && <div className={st.offcanvasBackdrop} />}
    </>
  );
}

export default Offcanvas;
