import { USER_ROLES } from 'constants/userRoles';
import { ROUTES } from 'router/routes';
import { getValidPath } from 'helpers/path';

export const HEADER_NAV = {
  [USER_ROLES.admin]: [
    { name: 'Production', link: ROUTES.productionManager.path },
    { name: 'Customers', link: ROUTES.customerListManager.path },
    { name: 'Parts', link: ROUTES.partTypesListManagerInitialPage.path },
    { name: 'Admin', link: ROUTES.adminCustomers.path },
  ],
  [USER_ROLES.manager]: [
    { name: 'Production', link: ROUTES.productionManager.path },
    { name: 'Customers', link: ROUTES.customerListManager.path },
    { name: 'Parts', link: ROUTES.partTypesListManagerInitialPage.path },
    { name: 'Admin', link: ROUTES.adminTravelerTemplates.path },
  ],
  [USER_ROLES.shipping]: [
    { name: 'Customers', link: ROUTES.customerListShipping.path },
    { name: 'Parts', link: ROUTES.partTypesListShipping.path },
    { name: 'Jobs', link: ROUTES.jobList.path },
  ],
  [USER_ROLES.painter]: [{ name: 'Jobs', link: ROUTES.jobList.path }],
  [USER_ROLES.preparer]: [{ name: 'Jobs', link: ROUTES.jobList.path }],
  [USER_ROLES.qa]: [{ name: 'Jobs', link: ROUTES.jobList.path }],
  [USER_ROLES.customer]: [
    { name: 'Orders', link: ROUTES.orderListCustomer.path },
    { name: 'Rfqs', link: ROUTES.rfqListCustomer.path },
    { name: 'Parts', link: ROUTES.partTypesListCustomer.path },
  ],
};

export const ADMIN_NAV = {
  [USER_ROLES.admin]: [
    {
      label: 'Customers',
      link: getValidPath(ROUTES.adminCustomers.path),
    },
    {
      label: 'Employees',
      link: getValidPath(ROUTES.adminEmployees.path),
    },
    {
      label: 'Traveler Templates',
      link: getValidPath(ROUTES.adminTravelerTemplates.path),
    },
    {
      label: 'Control Values',
      link: getValidPath(ROUTES.adminControlValues.path),
    },
    {
      label: 'NCR Fields',
      link: getValidPath(ROUTES.adminNCR.path),
    },
  ],
  [USER_ROLES.manager]: [
    {
      label: 'Traveler Templates',
      link: getValidPath(ROUTES.adminTravelerTemplates.path),
    },
    {
      label: 'Control Values',
      link: getValidPath(ROUTES.adminControlValues.path),
    },
    {
      label: 'NCR Fields',
      link: getValidPath(ROUTES.adminNCR.path),
    },
  ],
};
