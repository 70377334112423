import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CenteredColumnLayout from 'layouts/CenteredColumnLayout/CenteredColumnLayout';
import * as partTypesActions from 'store/partTypes/actions';
import * as ordersActions from 'store/orders/actions';
import * as customersActions from 'store/customers/actions';
import NavigationService from 'services/navigation/NavigationService';
import Text from 'componentsShared/Text/Text';
import OrderDetails from 'components/Order/OrderDetails/OrderDetails';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useParams } from 'react-router';
import Loader from 'componentsShared/Loader/Loader';
import OrderHeader from 'components/Order/OrderHeader/OrderHeader';
import Modal from 'componentsShared/Modal/Modal';
import moment from 'moment';
import { FORMAT_DATE_USA, VARIANT_UI } from 'constants/ui';
import PartService from 'services/part/PartService';
// eslint-disable-next-line import/no-extraneous-dependencies
import { isEqual } from 'lodash';
import AuthService from 'services/auth/AuthService';
import BeforeRedirect from 'componentsShared/BeforeRedirect/BeforeRedirect';
import st from './OrdersDetailsPage.module.scss';

const OrdersDetailsPage = () => {
  const dispatch = useDispatch();
  const { id: orderId } = useParams();
  const isClient = AuthService.isClient();

  const orderList = useSelector((state) => state?.orders?.orders?.data) ?? {};
  const currentCustomerId =
    useSelector((state) => state?.customers?.currentCustomerId) ?? null;
  const isLoading =
    useSelector((state) => state?.orders?.orders?.isLoading) ?? false;
  const currentCustomerName =
    useSelector(
      (state) => state?.customers?.customers?.data?.[currentCustomerId]?.name
    ) ?? '';
  const selectedPartTypesList =
    useSelector((state) => state?.partTypes?.selectedPartTypes) ?? [];

  const selectedPartTypesListWithoutKey = selectedPartTypesList.map((item) => {
    const data = { ...item };
    delete data.key;
    return data;
  });

  const currentOrder = orderList[orderId];

  const [initeilSelectedPartTypesList, setIniteilSelectedPartTypesList] =
    useState([]);
  const [showModalRemove, setShowModalRemove] = useState(false);
  const [PONumber, setPONumber] = useState('');

  const getIsActiveTravelerInPartList = () => {
    const partIds = selectedPartTypesList.map((item) => {
      return item.id;
    });
    const uniquePartIds = [...new Set(partIds)];
    return PartService.isActiveTravelerInPartList(uniquePartIds);
  };

  const isActiveTravelerInPartList = getIsActiveTravelerInPartList();

  const dropdownList = [
    {
      content: () => (
        <Text variant='normal' className={st.textRemove}>
          Remove PO
        </Text>
      ),
      onClick: () => setShowModalRemove(true),
    },
  ];

  const handleSaveOrder = () => {
    const positions = selectedPartTypesList.map((item) => ({
      part_type_id: item.id,
      quantity: item.quantity,
      id: item.positionId,
    }));

    dispatch(
      ordersActions.updateOrder.start({
        id: orderId,
        data: {
          positions,
          purchase_number: PONumber,
        },
      })
    );
  };

  const handleSubmitOrder = () => {
    const positions = selectedPartTypesList.map((item) => ({
      part_type_id: item.id,
      quantity: item.quantity,
      id: item.positionId,
    }));

    dispatch(
      ordersActions.submitOrder.start({
        id: orderId,
        data: {
          positions,
          purchase_number: PONumber,
        },
      })
    );
  };

  const handleRemovePO = () => {
    dispatch(ordersActions.removeOrder.start(orderId));
    NavigationService.back();
  };

  const navigateBack = () => {
    dispatch(ordersActions.addIsNewOrder());
    dispatch(ordersActions.changeIsScrollJob(true));
    NavigationService.back();
  };

  const isDisabledSave = !PONumber || selectedPartTypesList?.length === 0;

  const isDisabledSubmit =
    !isActiveTravelerInPartList ||
    !PONumber ||
    selectedPartTypesList?.length === 0;

  const isCheckModalBackBtn =
    currentOrder?.purchase_number !== PONumber ||
    !isEqual(initeilSelectedPartTypesList, selectedPartTypesListWithoutKey);

  const title = useMemo(() => {
    const currentPurchaseNumber = currentOrder?.purchase_number
      ? `${currentOrder?.purchase_number} / `
      : '';
    const currentCreatedDate = currentOrder?.created
      ? `${moment(currentOrder?.created).format(FORMAT_DATE_USA)}`
      : '';

    const infoPO = `${currentPurchaseNumber}${currentCreatedDate}`;

    if (isClient) {
      return infoPO;
    }

    return `${currentCustomerName}: ${infoPO}`;
  }, [currentCustomerName, currentOrder, isClient]);

  useEffect(() => {
    const position =
      currentOrder?.positions.map((item) => ({
        ...item.part_type,
        quantity: item.quantity,
        positionId: item.id,
      })) || [];

    setIniteilSelectedPartTypesList(position);
    dispatch(partTypesActions.addInitialSelectedPartTypes(position));
    setPONumber(currentOrder?.purchase_number);
  }, [currentOrder, dispatch]);

  useEffect(() => {
    if (currentCustomerId || isClient) {
      dispatch(
        partTypesActions.getPartList.start({ customer_id: currentCustomerId })
      );

      if (!isClient) {
        dispatch(customersActions.getCustomer.start(currentCustomerId));
      }
    }
  }, [dispatch, currentCustomerId, isClient]);

  useEffect(() => {
    if (orderId) {
      dispatch(ordersActions.getOrder.start(orderId));
    }
  }, [dispatch, orderId]);

  return (
    <CenteredColumnLayout>
      <OrderHeader
        handleNavigateBack={navigateBack}
        onСlickBack={navigateBack}
        handleSubmitOrder={handleSubmitOrder}
        isLoading={isLoading}
        handleSaveOrder={handleSaveOrder}
        dropdownList={dropdownList}
        title={title}
        isDisabledSubmit={isDisabledSubmit}
        isDisabledSave={isDisabledSave}
        isBurger
        isCheckModalBackBtn={isCheckModalBackBtn}
      />
      <div className={st.mainContentHolder}>
        <OrderDetails PONumber={PONumber} setPONumber={setPONumber} />
        <Modal
          title='Remove Purchase Order'
          show={showModalRemove}
          setShow={setShowModalRemove}
          titleAcceptBtn='Remove'
          onAcceptBtn={handleRemovePO}
          variantAcceptBtn={VARIANT_UI.danger}>
          <div className={st.wrapperModalContent}>
            <Text variant='normal'>Would you like to remove Purchase</Text>
            <Text variant='normal' bold>
              {currentCustomerName}: {orderId} /{' '}
              {moment(currentOrder?.created).format(FORMAT_DATE_USA)}?
            </Text>
          </div>
        </Modal>
        <Loader isVisible={isLoading} isBackgroundColorGray />
      </div>
      <BeforeRedirect when={isCheckModalBackBtn && !isLoading} />
    </CenteredColumnLayout>
  );
};

export default OrdersDetailsPage;
