export const MODULE_TYPES = {
  travelers: 'travelers',
  parttypes: 'parttypes',
  jobs: 'jobs',
  rfqs: 'rfqs',
  templates: 'templates',
};

export const FILE_TYPES = {
  cover: 'cover',
  drawing: 'drawing',
  specification: 'specification',
  qaAttachment: 'qa_attachment',
  attachment: 'attachment',
  finalQaAttachment: 'final_qa_attachment',
};

export const FILE_ACCEPT_ONLY_IMAGE =
  'image/svg, image/jpg, image/jpeg, image/png, image/webp';

export const FILE_ACCEPT_ONLY_PDF =
  'application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';

export const FILE_ACCEPT_IMAGE_AND_PDF = `${FILE_ACCEPT_ONLY_IMAGE}, ${FILE_ACCEPT_ONLY_PDF}`;

export const IMAGE_TYPE_ARRAY = [
  'svg',
  'jpg',
  'jpeg',
  'png',
  'webp',
  FILE_TYPES.cover,
];

export const INTERMEDIATE_FILE_DRAGGABLE_ID = '/fileName/';

export const REQUIRE_FILE_DRAGGABLE_ID = '/requireFile';

export const TYPES_DOCS_FILE_ARRAY = [
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
];

export const TYPES_XLS_FILE_ARRAY = [
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

export const TYPES_NAME_FILE_XLS_OR_DOC = ['doc', 'docx', 'xls', 'xlsx'];
