import React from 'react';
import { Button, Form } from 'react-bootstrap';
import Modal from 'componentsShared/Modal/Modal';
import { object, bool, func } from 'prop-types';
import DatetimePicker from 'componentsShared/DatetimePicker/DatetimePicker';
import { VARIANT_OUTLINE_UI } from 'constants/ui';
import OrderScoreMatrix from 'components/Order/OrderScoreMatrix/OrderScoreMatrix';
import st from './ModalBodyOrderScore.module.scss';

ModalBodyOrderScore.propTypes = {
  show: bool,
  setShow: func,
  handleResetValueForm: func,
  scoreData: object,
  disabledModalAcceptBtn: bool,
  scoreValue: object,
  handleChangeScore: func,
  setValueDate: func,
  isShowError: bool,
};

function ModalBodyOrderScore({
  show = false,
  setShow = () => {},
  handleResetValueForm = () => {},
  handleSave = () => {},
  scoreData = {},
  scoreValue = {},
  finalScore = 0,
  handleChangeScore = () => {},
  valueDate = null,
  setValueDate = () => {},
  errorScore = {},
  isShowError = false,
  disabledModalAcceptBtn = false,
}) {
  return (
    <Modal
      show={show}
      setShow={setShow}
      onHide={handleResetValueForm}
      title='Production Scoring Matrix'
      buttonBottomRight={({ onHide }) => (
        <>
          <Button variant={VARIANT_OUTLINE_UI.secondary} onClick={onHide}>
            Cancel
          </Button>
          <Button
            onClick={() => handleSave(onHide)}
            disabled={disabledModalAcceptBtn}>
            Save Changes
          </Button>
        </>
      )}>
      <div>
        <OrderScoreMatrix
          scoreData={scoreData}
          scoreValue={scoreValue}
          handleChangeScore={handleChangeScore}
          finalScore={finalScore}
          errorScore={errorScore}
          isShowError={isShowError}
        />

        <div className={st.formGroup}>
          <div className={st.labelWrapper}>
            <Form.Label className={st.formGroupLabel}>
              Projected End Date
            </Form.Label>
          </div>

          <div className={st.formControlHolder}>
            <DatetimePicker
              positionTop
              valueDate={valueDate}
              setValueDate={setValueDate}
            />
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalBodyOrderScore;
