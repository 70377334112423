import React from 'react';
import { Button } from 'react-bootstrap';
import { VARIANT_OUTLINE_UI, VARIANT_UI } from 'constants/ui';
import ModalButton from 'componentsShared/ModalButton/ModalButton';
import Text from 'componentsShared/Text/Text';
import { func, bool } from 'prop-types';
import st from './ModalBlockCustomerLogin.module.scss';

ModalBlockCustomerLogin.propTypes = {
  isLoading: bool,
  handleAcceptBtn: func,
  onHide: func,
};

function ModalBlockCustomerLogin({
  isLoading = false,
  handleAcceptBtn = () => {},
  onHide = () => {},
}) {
  return (
    <ModalButton
      title='Account blocking'
      titleAcceptBtn='Block'
      onAcceptBtn={() => handleAcceptBtn(onHide)}
      variantAcceptBtn={VARIANT_UI.danger}
      bodyContent={() => (
        <Text variant='normal'>
          Blocked users lose their account access.
          <br />
          They are not listed in workspaces.
          <br />
          <br />
          You can unblock users anytime later.
          <br />
          This will require re-activation of their account.
        </Text>
      )}
      buttonRenderer={({ onClick }) => (
        <Button
          variant={VARIANT_OUTLINE_UI.secondary}
          onClick={onClick}
          className={st.deleteCustomer}
          disabled={isLoading}>
          Block Account
        </Button>
      )}
    />
  );
}

export default ModalBlockCustomerLogin;
