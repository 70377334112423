import React from 'react';
import DropdownButton from 'componentsShared/DropdownButton/DropdownButton';
import { VARIANT_OUTLINE_UI } from 'constants/ui';
import { ROUTES } from 'router/routes';
import { useSelector } from 'react-redux';
import NavigationService from 'services/navigation/NavigationService';
import HeaderWrapperNotification from 'components/HeaderWrapperNotification/HeaderWrapperNotification';
import st from './HeaderUserMenu.module.scss';

function HeaderUserMenu() {
  const userName = useSelector((state) => state?.auth?.user?.name) ?? '';

  const handleLogout = () => {
    NavigationService.replacePath(ROUTES.signOut.path);
  };

  const dropdownList = [
    {
      content: () => 'Logout',
      onClick: handleLogout,
    },
  ];

  return (
    <div className={st.container}>
      <HeaderWrapperNotification />
      <DropdownButton
        id='dropdown-profile-button'
        title={userName}
        className={st.headerProfileBtn}
        list={dropdownList}
        btnVariant={VARIANT_OUTLINE_UI.light}
      />
    </div>
  );
}

export default HeaderUserMenu;
