import React, { useEffect } from 'react';
import SignUpConfirmForm from 'components/Form/SignUpConfirmForm/SignUpConfirmForm';
import CenteredColumnLayout from 'layouts/CenteredColumnLayout/CenteredColumnLayout';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useParams } from 'react-router';
import Loader from 'componentsShared/Loader/Loader';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import {
  reContainEightCharacters,
  reContainOneLowercase,
  reContainOneNumber,
  reContainOneSpecialCaseCharacter,
  reContainOneUppercase,
} from 'constants/regulars';
import * as authActions from 'store/auth/actions';
import Text from 'componentsShared/Text/Text';
import { Button } from 'react-bootstrap';
import NavigationService from 'services/navigation/NavigationService';
import { ROUTES } from 'router/routes';
import st from './SignUpConfirmPage.module.scss';

function SignUpConfirmPage() {
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required('Required')
      .matches(reContainEightCharacters, 'Must Contain 8 Characters')
      .matches(reContainOneLowercase, 'Must Contain One Lowercase')
      .matches(reContainOneUppercase, 'Must Contain One Uppercase')
      .matches(reContainOneNumber, 'Must Contain One Number')
      .matches(
        reContainOneSpecialCaseCharacter,
        'Must Contain One Special Case Character'
      ),
    passwordConfirmation: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Passwords must match'
    ),
  });

  const dispatch = useDispatch();
  const { token } = useParams();

  const errorCheckClientToken =
    useSelector((state) => state?.auth?.errorCheckClientToken) ?? false;
  const isLoading = useSelector((state) => state?.auth?.isLoading) ?? false;
  const isAuthorized =
    useSelector((state) => state?.auth?.isAuthorized) ?? false;

  const onSubmit = (data) => {
    dispatch(
      authActions.signUpConfirm.start({ new_password: data.password, token })
    );
  };

  useEffect(() => {
    if (token) {
      dispatch(authActions.checkClientToken.start({ token }));
    }
  }, [dispatch, token]);

  useEffect(() => {
    if (isAuthorized) {
      dispatch(authActions.signOut({ isRedirect: false }));
    }
  }, [isAuthorized, dispatch]);

  return (
    <CenteredColumnLayout showNavbar={false} showUserMenu={false}>
      <div className={st.container}>
        {errorCheckClientToken ? (
          <div className={st.holderError}>
            <Text variant='normal' className={st.errorText}>
              Account activation has failed due to exceeding the invitation
              lifetime. Please proceed to password restoration in order to
              receive a new activation email.
            </Text>
            <Button
              onClick={() =>
                NavigationService.navigateToPath(ROUTES.forgotPassword.path)
              }>
              Restore password
            </Button>
          </div>
        ) : (
          <div className={st.containerForm}>
            <h4 className={st.title}>Set a strong password</h4>
            <SignUpConfirmForm
              onSubmit={onSubmit}
              validationSchema={validationSchema}
            />
          </div>
        )}
        <Loader isVisible={isLoading} isBackgroundColorGray />
      </div>
    </CenteredColumnLayout>
  );
}

export default SignUpConfirmPage;
