import React, { useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import { VARIANT_UI } from 'constants/ui';
import PropTypes from 'prop-types';
import cn from 'classnames';
import st from './DropdownButtonControlOpen.module.scss';

DropdownButtonControlOpen.propTypes = {
  title: PropTypes.string,
  list: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      onClick: PropTypes.func,
    })
  ).isRequired,
  className: PropTypes.string,
  startIcon: PropTypes.node,
  classNameBtn: PropTypes.string,
  arrowNone: PropTypes.bool,
  align: PropTypes.string,
  disabled: PropTypes.bool,
  direction: PropTypes.string,
  onToggle: PropTypes.func,
  onSelect: PropTypes.func,
  onMouseDown: PropTypes.func,
};

function DropdownButtonControlOpen({
  title = '',
  list = [],
  className,
  classNameBtn,
  startIcon,
  btnVariant = VARIANT_UI.primary,
  arrowNone = false,
  align = 'end',
  size = 'md',
  disabled = false,
  direction = 'down',
  onToggle = () => {},
  onSelect = () => {},
  onClick: onClickToggle = () => {},
  onMouseDown = () => {},
  isShowDropdown = true,
}) {
  const [show, setShow] = useState(false);

  const handleValue = (value) => {
    onToggle(value);

    if (isShowDropdown) {
      setShow(value);
    }
  };

  const renderDropdownList = () =>
    list.map(({ content, onClick, disabled: disabledItem = false }) => {
      return (
        <Dropdown.Item onClick={onClick} key={uuidv4()} disabled={disabledItem}>
          {content ? content() : <></>}
        </Dropdown.Item>
      );
    });

  return (
    <Dropdown
      className={cn(st.dropdownContainer, className, {
        [st.arrowNone]: arrowNone,
      })}
      show={show}
      align={align}
      drop={direction}
      onToggle={handleValue}
      onSelect={onSelect}
      onClick={onClickToggle}
      onMouseDown={onMouseDown}>
      <Dropdown.Toggle
        variant={btnVariant}
        className={classNameBtn}
        size={size}
        disabled={disabled}>
        {startIcon}
        {title}
      </Dropdown.Toggle>
      <Dropdown.Menu>{list?.length > 0 && renderDropdownList()}</Dropdown.Menu>
    </Dropdown>
  );
}

export default DropdownButtonControlOpen;
