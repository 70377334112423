import React from 'react';
import Text from 'componentsShared/Text/Text';
import JobsControlValues from 'components/Jobs/JobsControlValues/JobsControlValues';
import JobsOperationModalFileList from 'components/Jobs/JobsOperationModalFileList/JobsOperationModalFileList';
import AttachFilesButton from 'components/AttachFilesButton/AttachFilesButton';
import { FILE_ACCEPT_IMAGE_AND_PDF, FILE_TYPES } from 'constants/files';
import st from './JobsOperationContent.module.scss';

function JobsOperationContent({
  showControlValues,
  quantityOperation,
  controlValuesData,
  currentOperationIndex,
  operationName,
  fileList = [],
  jobControlValues,
  setJobControlValues,
  errorsControlValues,
  isShowErrorControlValues,
  setIsShowErrorControlValues,
  isRequireFile = false,
  onUploadFile = () => {},
  jobFilePathList = [],
  isLoadingFiles = false,
  isFinalQA = false,
}) {
  const fileAttachmentType = isFinalQA
    ? FILE_TYPES.finalQaAttachment
    : FILE_TYPES.qaAttachment;

  const currentTraveletFileList = Array.isArray(fileList)
    ? fileList.map((item) => ({
        filePath: item,
        isWorkerUpload: false,
      }))
    : [];

  const currentUploadFileList =
    isRequireFile && Array.isArray(jobFilePathList)
      ? jobFilePathList.map((item) => ({
          filePath: item,
          isWorkerUpload: true,
        }))
      : [];

  const renderFileList = () => {
    const currentFileList = [
      ...currentTraveletFileList,
      ...currentUploadFileList,
    ];

    return (
      <div>
        <JobsOperationModalFileList
          fileList={currentFileList}
          showControlValues={showControlValues}
        />
        {isRequireFile && (
          <AttachFilesButton
            handleChange={onUploadFile(fileAttachmentType)}
            title='Add File'
            disabled={isLoadingFiles}
            accept={FILE_ACCEPT_IMAGE_AND_PDF}
            className={st.attachFilesButton}
          />
        )}
      </div>
    );
  };

  return (
    <div className={st.holderJobOperation}>
      <Text className={st.operationNumber} varinant='normal2' bold>
        OPERATION {currentOperationIndex + 1} OF {quantityOperation}
      </Text>
      <Text className={st.operationName} bold>
        {showControlValues ? (
          <>
            Provide Control Values:
            <br />
            {controlValuesData.name}
          </>
        ) : (
          operationName
        )}
      </Text>
      {renderFileList()}
      {showControlValues && (
        <JobsControlValues
          jobControlValues={jobControlValues}
          setJobControlValues={setJobControlValues}
          controlValuesHeaders={controlValuesData.headers}
          errors={errorsControlValues}
          isShowError={isShowErrorControlValues}
          setIsShowError={setIsShowErrorControlValues}
        />
      )}
    </div>
  );
}

export default JobsOperationContent;
