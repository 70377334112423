import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FILE_TYPES } from 'constants/files';
import Loader from 'componentsShared/Loader/Loader';
import * as jobsActions from 'store/jobs/actions';
import Text from 'componentsShared/Text/Text';
import JobsPreviewFile from 'components/Jobs/JobsPreviewFile/JobsPreviewFile';
import st from './ModalJobDocumentation.module.scss';

function ModalJobDocumentation({ jobIdList }) {
  const dispatch = useDispatch();

  const firstJobId = jobIdList[0];

  const jobFileList =
    useSelector((state) => state?.jobs?.files?.data?.[firstJobId]) ?? [];

  const isLoadingFile =
    useSelector((state) => state?.jobs?.files?.isLoading) ?? '';

  const jobFilePathList = [
    ...(jobFileList?.[FILE_TYPES.qaAttachment]?.map(({ path }) => path) || []),
    ...(jobFileList?.[FILE_TYPES.finalQaAttachment]?.map(({ path }) => path) ||
      []),
  ];

  const loadFile = (path) => {
    dispatch(jobsActions.getJobOperationFile.start({ path }));
  };

  const renderFileList = () => {
    return jobFilePathList.map((path) => (
      <JobsPreviewFile
        file={path}
        key={`file-technical-drawings-${path}`}
        entityType='jobs'
        loadFile={loadFile}
        classNameHolderImg={st.classNameHolderImg}
        classNameContainer={st.classNameContainer}
        classNameImg={st.classNameImg}
        classNameHolderFile={st.classNameHolderFile}
        classNameHolderFileName={st.classNameHolderFileName}
        heightPDF={130}
        classNamePDfDocument={st.classNamePDfDocument}
      />
    ));
  };

  useEffect(() => {
    if (firstJobId) {
      dispatch(jobsActions.getFileInterprocessQA.start({ id: firstJobId }));
    }
  }, [dispatch, firstJobId]);

  useEffect(() => {
    dispatch(jobsActions.clearJobFileList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div className={st.wrapperModalContent}>
      {jobIdList.length > 0 && jobFilePathList.length > 0 && renderFileList()}
      {jobIdList.length > 0 && jobFilePathList.length === 0 && (
        <div className={st.holderPlaceholderEmptyFile}>
          <Text variant='normal' className={st.placeholderEmptyFile}>
            There are no documentation for this job
          </Text>
        </div>
      )}
      <Loader isVisible={isLoadingFile} className={st.loader} />
    </div>
  );
}

export default ModalJobDocumentation;
