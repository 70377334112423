import { createReducer } from '@reduxjs/toolkit';
import { PAGE_SIZE } from 'constants/ui';
import * as actions from './actions';

export const initialState = {
  customers: {
    data: {},
    sortIds: [],
    isLoading: false,
    isLoadingCreate: false,
    isLoadingChange: false,
    isLoadingResendInvite: false,
  },
  currentCustomerId: null,
  fetchParams: {
    pagination: {
      page: 0,
      size: PAGE_SIZE,
      count: 0,
    },
    search: '',
    isLoading: false,
  },
};

export const customersReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.getCustomerList.start, (state, action) => {
      const { filter, isClearPage } = action?.payload || {};

      return {
        ...state,
        customers: {
          ...state.customers,
          isLoading: true,
        },
        fetchParams: {
          ...state.fetchParams,
          pagination: {
            ...state.fetchParams.pagination,
            page: isClearPage ? 0 : state.fetchParams.pagination.page,
          },
          isLoading: !!filter,
        },
      };
    })
    .addCase(actions.getCustomerList.success, (state, action) => {
      const { data, ids, page, count } = action.payload;

      return {
        ...state,
        customers: {
          ...state.customers,
          data,
          sortIds: ids,
          isLoading: false,
        },
        fetchParams: {
          ...state.fetchParams,
          pagination: {
            ...state.fetchParams.pagination,
            page,
            count,
          },
          isLoading: false,
        },
      };
    })
    .addCase(actions.getCustomerList.failure, (state) => ({
      ...state,
      customers: {
        ...state.customers,
        isLoading: false,
      },
      fetchParams: {
        ...state.fetchParams,
        isLoading: false,
      },
    }))
    .addCase(actions.createCustomer.start, (state) => ({
      ...state,
      customers: {
        ...state.customers,
        isLoadingCreate: true,
      },
    }))
    .addCase(actions.createCustomer.success, (state) => {
      return {
        ...state,
        customers: {
          ...state.customers,
          isLoadingCreate: false,
        },
      };
    })
    .addCase(actions.createCustomer.failure, (state) => ({
      ...state,
      customers: {
        ...state.customers,
        isLoadingCreate: false,
      },
    }))
    .addCase(actions.changeCustomer.start, (state) => ({
      ...state,
      customers: {
        ...state.customers,
        isLoadingChange: true,
      },
    }))
    .addCase(actions.changeCustomer.success, (state) => {
      return {
        ...state,
        customers: {
          ...state.customers,
          isLoadingChange: false,
        },
      };
    })
    .addCase(actions.changeCustomer.failure, (state) => ({
      ...state,
      customers: {
        ...state.customers,
        isLoadingChange: false,
      },
    }))
    .addCase(actions.resendInviteCustomer.start, (state) => ({
      ...state,
      customers: {
        ...state.customers,
        isLoadingResendInvite: true,
      },
    }))
    .addCase(actions.resendInviteCustomer.success, (state) => {
      return {
        ...state,
        customers: {
          ...state.customers,
          isLoadingResendInvite: false,
        },
      };
    })
    .addCase(actions.resendInviteCustomer.failure, (state) => ({
      ...state,
      customers: {
        ...state.customers,
        isLoadingResendInvite: false,
      },
    }))
    .addCase(actions.setCurrentCustomer, (state, { payload }) => ({
      ...state,
      currentCustomerId: payload,
    }))
    .addCase(actions.removeCustomer.start, (state) => ({
      ...state,
      customers: {
        ...state.customers,
        isLoading: true,
      },
    }))
    .addCase(actions.removeCustomer.success, (state) => {
      return {
        ...state,
        customers: {
          ...state.customers,
          isLoading: false,
        },
      };
    })
    .addCase(actions.removeCustomer.failure, (state) => ({
      ...state,
      customers: {
        ...state.customers,
        isLoading: false,
      },
    }))
    .addCase(actions.changeFilterSearch, (state, action) => {
      const currentValue = action.payload;

      return {
        ...state,
        fetchParams: {
          ...state.fetchParams,
          pagination: {
            ...state.fetchParams.pagination,
          },
          search: currentValue,
        },
      };
    })
    .addCase(actions.getCustomer.start, (state) => ({
      ...state,
      customers: {
        ...state.customers,
        isLoading: true,
      },
    }))
    .addCase(actions.getCustomer.success, (state, action) => {
      const { data, ids } = action.payload;

      return {
        ...state,
        customers: {
          ...state.customers,
          data,
          sortIds: ids,
          isLoading: false,
        },
      };
    })
    .addCase(actions.getCustomer.failure, (state) => ({
      ...state,
      customers: {
        ...state.customers,
        isLoading: false,
      },
    }))
    .addCase(actions.changePage, (state, action) => {
      const page = action.payload;
      return {
        ...state,
        fetchParams: {
          ...state.fetchParams,
          pagination: {
            ...state.fetchParams.pagination,
            page,
          },
        },
      };
    })
    .addCase(actions.resendInviteCustomerWithSaving.start, (state) => ({
      ...state,
      customers: {
        ...state.customers,
        isLoadingChange: true,
        isLoadingResendInvite: true,
        isLoading: true,
      },
    }))
    .addCase(actions.resendInviteCustomerWithSaving.success, (state) => {
      return {
        ...state,
        customers: {
          ...state.customers,
          isLoadingChange: false,
          isLoadingResendInvite: false,
          isLoading: false,
        },
      };
    })
    .addCase(actions.resendInviteCustomerWithSaving.failure, (state) => ({
      ...state,
      customers: {
        ...state.customers,
        isLoadingChange: false,
        isLoadingResendInvite: false,
        isLoading: false,
      },
    }))
    .addCase(actions.createCustomerLogin.start, (state) => ({
      ...state,
      customers: {
        ...state.customers,
        isLoadingCreate: true,
      },
    }))
    .addCase(actions.createCustomerLogin.success, (state) => {
      return {
        ...state,
        customers: {
          ...state.customers,
          isLoadingCreate: false,
        },
      };
    })
    .addCase(actions.createCustomerLogin.failure, (state) => ({
      ...state,
      customers: {
        ...state.customers,
        isLoadingCreate: false,
      },
    }))
    .addCase(actions.blockCustomerLogin.start, (state) => ({
      ...state,
      customers: {
        ...state.customers,
        isLoading: true,
      },
    }))
    .addCase(actions.blockCustomerLogin.success, (state) => {
      return {
        ...state,
        customers: {
          ...state.customers,
          isLoading: false,
        },
      };
    })
    .addCase(actions.blockCustomerLogin.failure, (state) => ({
      ...state,
      customers: {
        ...state.customers,
        isLoading: false,
      },
    }))
    .addCase(actions.unblockCustomerLogin.start, (state) => ({
      ...state,
      customers: {
        ...state.customers,
        isLoading: true,
      },
    }))
    .addCase(actions.unblockCustomerLogin.success, (state) => {
      return {
        ...state,
        customers: {
          ...state.customers,
          isLoading: false,
        },
      };
    })
    .addCase(actions.unblockCustomerLogin.failure, (state) => ({
      ...state,
      customers: {
        ...state.customers,
        isLoading: false,
      },
    }));
});
