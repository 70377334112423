import { PAGE_SIZE } from 'constants/ui';

export const migrations = {
  0: (state) => {
    return {
      ...state,
      auth: {
        isAuthorized: false,
        isTempPassword: false,
        isLoading: false,
        user: {},
        isClient: false,
        tempPassword: '',
        errorCheckClientToken: false,
      },
      customers: {
        customers: {
          data: {},
          sortIds: [],
          isLoading: false,
          isLoadingCreate: false,
          isLoadingChange: false,
          isLoadingResendInvite: false,
        },
        currentCustomerId: state.customers.currentCustomerId,
        fetchParams: {
          pagination: {
            page: 0,
            size: PAGE_SIZE,
            count: 0,
          },
          search: '',
          isLoading: false,
        },
      },
      orders: {
        orders: {
          data: {},
          sortIds: [],
          isLoading: false,
          isRemoving: false,
        },
        isAddingOrder: {
          orderId: null,
          isNewOrder: false,
        },
        fetchParams: {
          pagination: {
            page: 0,
            size: PAGE_SIZE,
            count: 0,
          },
          search: '',
          isLoading: false,
        },
        orderCardList: {
          isOpenOrderList: false,
          isChange: false,
        },
      },
      partTypes: {
        partTypes: {
          data: {},
          sortIds: [],
          isLoading: false,
        },
        fetchParams: {
          pagination: {
            page: 0,
            size: PAGE_SIZE,
            count: 0,
          },
          search: '',
          isLoading: false,
        },
        isAddingPart: {
          partId: null,
          isNewPart: false,
          selectedNewPartId: null,
        },
        files: {
          data: {},
          dataUrl: {},
          isLoading: false,
        },
        selectedPartTypes: [],
        partTypeId: null,
      },
      rfqs: {
        rfqs: {
          data: {},
          sortIds: [],
          isLoading: false,
          isRemoving: false,
        },
        fetchParams: {
          pagination: {
            page: 0,
            size: PAGE_SIZE,
            count: 0,
          },
          search: '',
          isLoading: false,
        },
        files: {
          data: {},
          isLoading: false,
        },
        isAddingRFQ: {
          rfqId: null,
          isNewRFQ: false,
        },
      },
    };
  },
  1: (state) => {
    return {
      ...state,
      auth: {
        isAuthorized: false,
        isTempPassword: false,
        isLoading: false,
        user: {},
        isClient: false,
        tempPassword: '',
        errorCheckClientToken: false,
        versionBackend: {
          isLoading: false,
          version: '',
        },
      },
      customers: {
        customers: {
          data: {},
          sortIds: [],
          isLoading: false,
          isLoadingCreate: false,
          isLoadingChange: false,
          isLoadingResendInvite: false,
        },
        currentCustomerId: state.customers.currentCustomerId,
        fetchParams: {
          pagination: {
            page: 0,
            size: PAGE_SIZE,
            count: 0,
          },
          search: '',
          isLoading: false,
        },
      },
      orders: {
        orders: {
          data: {},
          sortIds: [],
          isLoading: false,
          isRemoving: false,
        },
        isAddingOrder: {
          orderId: null,
          isNewOrder: false,
        },
        fetchParams: {
          pagination: {
            page: 0,
            size: PAGE_SIZE,
            count: 0,
          },
          search: '',
          isLoading: false,
        },
        orderCardList: {
          isOpenOrderList: false,
          isChange: false,
        },
        isConvertFromRFQ: false,
      },
      partTypes: {
        partTypes: {
          data: {},
          sortIds: [],
          isLoading: false,
        },
        fetchParams: {
          pagination: {
            page: 0,
            size: PAGE_SIZE,
            count: 0,
          },
          search: '',
          isLoading: false,
        },
        isAddingPart: {
          partId: null,
          isNewPart: false,
        },
        files: {
          data: {},
          dataUrl: {},
          isLoading: false,
        },
        selectedPartTypes: [],
        partTypeId: null,
      },
      rfqs: {
        rfqs: {
          data: {},
          sortIds: [],
          isLoading: false,
          isRemoving: false,
        },
        fetchParams: {
          pagination: {
            page: 0,
            size: PAGE_SIZE,
            count: 0,
          },
          search: '',
          isLoading: false,
        },
        files: {
          data: {},
          isLoading: false,
        },
        isAddingRFQ: {
          rfqId: null,
          isNewRFQ: false,
        },
      },
    };
  },
  2: (state) => {
    return {
      ...state,
      auth: {
        isAuthorized: false,
        isTempPassword: false,
        isLoading: false,
        user: {},
        isClient: false,
        tempPassword: '',
        errorCheckClientToken: false,
        versionBackend: {
          isLoading: false,
          version: '',
        },
      },
      customers: {
        customers: {
          data: {},
          sortIds: [],
          isLoading: false,
          isLoadingCreate: false,
          isLoadingChange: false,
          isLoadingResendInvite: false,
        },
        currentCustomerId: state.customers.currentCustomerId,
        fetchParams: {
          pagination: {
            page: 0,
            size: PAGE_SIZE,
            count: 0,
          },
          search: '',
          isLoading: false,
        },
      },
      orders: {
        orders: {
          data: {},
          sortIds: [],
          isLoading: false,
          isRemoving: false,
        },
        isAddingOrder: {
          orderId: null,
          isNewOrder: false,
        },
        fetchParams: {
          pagination: {
            page: 0,
            size: PAGE_SIZE,
            count: 0,
          },
          search: '',
          isLoading: false,
        },
        orderCardList: {
          isOpenOrderList: false,
          isChange: false,
        },
        isConvertFromRFQ: false,
      },
      partTypes: {
        partTypes: {
          data: {},
          sortIds: [],
          isLoading: false,
        },
        fetchParams: {
          pagination: {
            page: 0,
            size: PAGE_SIZE,
            count: 0,
          },
          search: '',
          isLoading: false,
        },
        isAddingPart: {
          partId: null,
          isNewPart: false,
        },
        files: {
          data: {},
          dataUrl: {},
          isLoading: false,
        },
        selectedPartTypes: [],
        partTypeId: null,
      },
      rfqs: {
        rfqs: {
          data: {},
          sortIds: [],
          isLoading: false,
          isRemoving: false,
        },
        fetchParams: {
          pagination: {
            page: 0,
            size: PAGE_SIZE,
            count: 0,
          },
          search: '',
          isLoading: false,
        },
        files: {
          data: {},
          isLoading: false,
        },
        isAddingRFQ: {
          rfqId: null,
          isNewRFQ: false,
        },
      },
      jobs: {
        jobs: {
          data: {},
          sortIds: [],
          isLoading: false,
          assignedIds: [],
          availableIds: [],
          needsQAIds: [],
          interprocessIds: [],
          isNeedsQA: null,
        },
        getJobSummary: {
          sortIds: [],
          availableIds: [],
          unavailableIds: [],
          isLoading: false,
        },
        checkJob: {
          jobId: null,
          isLoadingCheckJob: false,
          typeError: '',
        },
        filterValue: {
          search: '',
          isLoading: false,
        },
        files: {
          data: {},
          dataUrl: {},
          isLoading: false,
        },
        jobsQr: {
          isLoading: false,
        },
      },
    };
  },
  3: (state) => {
    return {
      ...state,
      auth: {
        isAuthorized: false,
        isTempPassword: false,
        isLoading: false,
        user: {},
        isClient: false,
        tempPassword: '',
        errorCheckClientToken: false,
        versionBackend: {
          isLoading: false,
          version: '',
        },
      },
      customers: {
        customers: {
          data: {},
          sortIds: [],
          isLoading: false,
          isLoadingCreate: false,
          isLoadingChange: false,
          isLoadingResendInvite: false,
        },
        currentCustomerId: state.customers.currentCustomerId,
        fetchParams: {
          pagination: {
            page: 0,
            size: PAGE_SIZE,
            count: 0,
          },
          search: '',
          isLoading: false,
        },
      },
      orders: {
        orders: {
          data: {},
          sortIds: [],
          isLoading: false,
          isRemoving: false,
        },
        isAddingOrder: {
          orderId: null,
          isNewOrder: false,
        },
        fetchParams: {
          pagination: {
            page: 0,
            size: PAGE_SIZE,
            count: 0,
          },
          search: '',
          isLoading: false,
        },
        orderCardList: {
          isOpenOrderList: false,
          isChange: false,
        },
        isConvertFromRFQ: false,
      },
      partTypes: {
        partTypes: {
          data: {},
          sortIds: [],
          isLoading: false,
        },
        fetchParams: {
          pagination: {
            page: 0,
            size: PAGE_SIZE,
            count: 0,
          },
          search: '',
          isLoading: false,
        },
        isAddingPart: {
          partId: null,
          isNewPart: false,
        },
        files: {
          data: {},
          dataUrl: {},
          isLoading: false,
        },
        selectedPartTypes: [],
        partTypeId: null,
      },
      rfqs: {
        rfqs: {
          data: {},
          sortIds: [],
          isLoading: false,
          isRemoving: false,
        },
        fetchParams: {
          pagination: {
            page: 0,
            size: PAGE_SIZE,
            count: 0,
          },
          search: '',
          isLoading: false,
        },
        files: {
          data: {},
          isLoading: false,
        },
        isAddingRFQ: {
          rfqId: null,
          isNewRFQ: false,
        },
      },
      jobs: {
        jobs: {
          data: {},
          sortIds: [],
          isLoading: false,
          assignedIds: [],
          availableIds: [],
          needsQAIds: [],
          interprocessIds: [],
          ncrIds: [],
          assignedQuantity: 0,
          availableQuantity: 0,
          needsQAQuantity: 0,
          interprocessQuantity: 0,
          ncrQuantity: 0,
          isNeedsQA: null,
        },
        getJobSummary: {
          sortIds: [],
          availableIds: [],
          unavailableIds: [],
          isLoading: false,
        },
        checkJob: {
          jobId: null,
          isLoadingCheckJob: false,
          typeError: '',
        },
        filterValue: {
          search: '',
          isLoading: false,
        },
        files: {
          data: {},
          dataUrl: {},
          isLoading: false,
        },
        jobsQr: {
          isLoading: false,
        },
      },
    };
  },
  4: (state) => {
    return {
      ...state,
      auth: {
        isAuthorized: false,
        isTempPassword: false,
        isLoading: false,
        user: {},
        isClient: false,
        tempPassword: '',
        errorCheckClientToken: false,
        versionBackend: {
          isLoading: false,
          version: '',
        },
      },
      customers: {
        customers: {
          data: {},
          sortIds: [],
          isLoading: false,
          isLoadingCreate: false,
          isLoadingChange: false,
          isLoadingResendInvite: false,
        },
        currentCustomerId: state.customers.currentCustomerId,
        fetchParams: {
          pagination: {
            page: 0,
            size: PAGE_SIZE,
            count: 0,
          },
          search: '',
          isLoading: false,
        },
      },
      orders: {
        orders: {
          data: {},
          sortIds: [],
          isLoading: false,
          isRemoving: false,
        },
        isAddingOrder: {
          orderId: null,
          isNewOrder: false,
        },
        fetchParams: {
          pagination: {
            page: 0,
            size: PAGE_SIZE,
            count: 0,
          },
          search: '',
          isLoading: false,
        },
        orderCardList: {
          isOpenOrderList: false,
          isChange: false,
        },
        isConvertFromRFQ: false,
      },
      partTypes: {
        partTypes: {
          data: {},
          sortIds: [],
          isLoading: false,
        },
        fetchParams: {
          pagination: {
            page: 0,
            size: PAGE_SIZE,
            count: 0,
          },
          search: '',
          isLoading: false,
        },
        isAddingPart: {
          partId: null,
          isNewPart: false,
        },
        files: {
          data: {},
          dataUrl: {},
          isLoading: false,
        },
        selectedPartTypes: [],
        partTypeId: null,
      },
      rfqs: {
        rfqs: {
          data: {},
          sortIds: [],
          isLoading: false,
          isRemoving: false,
        },
        fetchParams: {
          pagination: {
            page: 0,
            size: PAGE_SIZE,
            count: 0,
          },
          search: '',
          isLoading: false,
        },
        files: {
          data: {},
          isLoading: false,
        },
        isAddingRFQ: {
          rfqId: null,
          isNewRFQ: false,
        },
      },
      jobs: {
        jobs: {
          data: {},
          sortIds: [],
          isLoading: false,
          assignedIds: [],
          availableIds: [],
          needsQAIds: [],
          interprocessIds: [],
          ncrIds: [],
          assignedQuantity: 0,
          availableQuantity: 0,
          needsQAQuantity: 0,
          interprocessQuantity: 0,
          ncrQuantity: 0,
          isNeedsQA: null,
        },
        getJobSummary: {
          sortIds: [],
          availableIds: [],
          unavailableIds: [],
          isLoading: false,
        },
        checkJob: {
          jobId: null,
          isLoadingCheckJob: false,
          typeError: '',
        },
        filterValue: {
          search: '',
          isLoading: false,
        },
        files: {
          data: {},
          dataUrl: {},
          isLoading: false,
        },
        jobsQr: {
          isLoading: false,
        },
        reasonRejectJob: {
          data: [],
          isLoading: false,
        },
      },
    };
  },
  5: (state) => {
    return {
      ...state,
      auth: {
        isAuthorized: false,
        isTempPassword: false,
        isLoading: false,
        user: {},
        isClient: false,
        tempPassword: '',
        errorCheckClientToken: false,
        versionBackend: {
          isLoading: false,
          version: '',
        },
      },
      customers: {
        customers: {
          data: {},
          sortIds: [],
          isLoading: false,
          isLoadingCreate: false,
          isLoadingChange: false,
          isLoadingResendInvite: false,
        },
        currentCustomerId: state.customers.currentCustomerId,
        fetchParams: {
          pagination: {
            page: 0,
            size: PAGE_SIZE,
            count: 0,
          },
          search: '',
          isLoading: false,
        },
      },
      orders: {
        orders: {
          data: {},
          sortIds: [],
          isLoading: false,
          isRemoving: false,
        },
        isAddingOrder: {
          orderId: null,
          isNewOrder: false,
        },
        fetchParams: {
          pagination: {
            page: 0,
            size: PAGE_SIZE,
            count: 0,
          },
          search: '',
          isLoading: false,
        },
        orderCardList: {
          isOpenOrderList: false,
          isChange: false,
        },
        isConvertFromRFQ: false,
      },
      partTypes: {
        partTypes: {
          data: {},
          sortIds: [],
          isLoading: false,
        },
        fetchParams: {
          pagination: {
            page: 0,
            size: PAGE_SIZE,
            count: 0,
          },
          search: '',
          isLoading: false,
        },
        isAddingPart: {
          partId: null,
          isNewPart: false,
        },
        files: {
          data: {},
          dataUrl: {},
          isLoading: false,
        },
        selectedPartTypes: [],
        partTypeId: null,
      },
      rfqs: {
        rfqs: {
          data: {},
          sortIds: [],
          isLoading: false,
          isRemoving: false,
        },
        fetchParams: {
          pagination: {
            page: 0,
            size: PAGE_SIZE,
            count: 0,
          },
          search: '',
          isLoading: false,
        },
        files: {
          data: {},
          isLoading: false,
        },
        isAddingRFQ: {
          rfqId: null,
          isNewRFQ: false,
        },
      },
      jobs: {
        jobs: {
          data: {},
          sortIds: [],
          isLoading: false,
          isLoadingReject: false,
          assignedIds: [],
          availableIds: [],
          needsQAIds: [],
          ncrIds: [],
          assignedQuantity: 0,
          availableQuantity: 0,
          needsQAQuantity: 0,
          interprocessQuantity: 0,
          ncrQuantity: 0,
          interprocessIds: [],
          isNeedsQA: null,
        },
        getJobSummary: {
          sortIds: [],
          availableIds: [],
          unavailableIds: [],
          isLoading: false,
        },
        checkJob: {
          jobId: null,
          isLoadingCheckJob: false,
          typeError: '',
          jobIdPhone: null,
        },
        filterValue: {
          search: '',
          isLoading: false,
        },
        files: {
          data: {},
          dataUrl: {},
          isLoading: false,
        },
        jobsQr: {
          isLoading: false,
        },
        reasonRejectJob: {
          data: [],
          isLoading: false,
        },
        jobsBatch: {
          isCreateBatch: false,
          selectJobIdList: [],
          showButtonBatch: false,
          data: {},
          sortIdsList: [],
        },
      },
    };
  },
};
