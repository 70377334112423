// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TravelerTemplateOperation_container__2nqXh {\n  display: flex;\n  flex-direction: column;\n  grid-row-gap: 4px;\n  row-gap: 4px;\n  padding-left: 35px; }\n  .TravelerTemplateOperation_container__2nqXh:first-child {\n    padding-top: 8px; }\n\n.TravelerTemplateOperation_containerOperation__15Mda {\n  display: flex;\n  align-items: center; }\n  .TravelerTemplateOperation_containerOperation__15Mda .form-label {\n    margin-bottom: 0; }\n  .TravelerTemplateOperation_containerOperation__15Mda .form-check-inline {\n    margin-right: 6px; }\n\n.TravelerTemplateOperation_formGroup__2SzKh {\n  display: flex; }\n\n.TravelerTemplateOperation_holderLabel__2uLzz {\n  display: flex;\n  align-items: center; }\n\n.TravelerTemplateOperation_holderIconCV__3jR4Y {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  margin-right: 6px; }\n", "",{"version":3,"sources":["webpack://src/components/TravalerTemplate/TravelerTemplateOperation/TravelerTemplateOperation.module.scss"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,iBAAY;EAAZ,YAAY;EACZ,kBAAkB,EAAA;EAJpB;IAOI,gBAAgB,EAAA;;AAIpB;EACE,aAAa;EACb,mBAAmB,EAAA;EAFrB;IAMM,gBAAgB,EAAA;EANtB;IAUM,iBAAiB,EAAA;;AAKvB;EACE,aAAa,EAAA;;AAGf;EACE,aAAa;EACb,mBAAmB,EAAA;;AAGrB;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,iBAAiB,EAAA","sourcesContent":[".container {\n  display: flex;\n  flex-direction: column;\n  row-gap: 4px;\n  padding-left: 35px;\n\n  &:first-child {\n    padding-top: 8px;\n  }\n}\n\n.containerOperation {\n  display: flex;\n  align-items: center;\n\n  :global {\n    .form-label {\n      margin-bottom: 0;\n    }\n\n    .form-check-inline {\n      margin-right: 6px;\n    }\n  }\n}\n\n.formGroup {\n  display: flex;\n}\n\n.holderLabel {\n  display: flex;\n  align-items: center;\n}\n\n.holderIconCV {\n  height: 100%;\n  display: flex;\n  align-items: center;\n  margin-right: 6px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "TravelerTemplateOperation_container__2nqXh",
	"containerOperation": "TravelerTemplateOperation_containerOperation__15Mda",
	"formGroup": "TravelerTemplateOperation_formGroup__2SzKh",
	"holderLabel": "TravelerTemplateOperation_holderLabel__2uLzz",
	"holderIconCV": "TravelerTemplateOperation_holderIconCV__3jR4Y"
};
export default ___CSS_LOADER_EXPORT___;
