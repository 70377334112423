import { createReducer } from '@reduxjs/toolkit';
import * as actions from './actions';

export const initialState = {
  files: {
    data: {},
    dataUrl: {},
    isLoading: false,
  },
};

export const filesReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.getFile.start, (state) => ({
      ...state,
      files: { ...state.files, isLoading: true },
    }))
    .addCase(actions.getFile.success, (state, action) => {
      const { data, dataUrl } = action.payload;

      return {
        ...state,
        files: { ...state.files, dataUrl, data, isLoading: false },
      };
    })
    .addCase(actions.getFile.failure, (state) => ({
      ...state,
      files: { ...state.files, isLoading: false },
    }));
});
