// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".JobsList_paddingBottom__3qofB {\n  padding-bottom: 8px; }\n", "",{"version":3,"sources":["webpack://src/components/Jobs/JobsList/JobsList.module.scss"],"names":[],"mappings":"AAEA;EACE,mBAAmB,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.paddingBottom {\n  padding-bottom: 8px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"paddingBottom": "JobsList_paddingBottom__3qofB"
};
export default ___CSS_LOADER_EXPORT___;
