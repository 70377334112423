import React, { useState } from 'react';
import { VARIANT_UI } from 'constants/ui';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import * as controlValuesActions from 'store/controlValues/actions';
import OffcanvasButton from 'componentsShared/OffcanvasButton/OffcanvasButton';
import OffcanvasEditControlValuesContent from 'components/Offcanvas/OffcanvasEditControlValuesContent/OffcanvasEditControlValuesContent';
import { object, array } from 'prop-types';
import st from './OffcanvasControlValuesCreate.module.scss';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
});

getValidValueRequest.propTypes = {
  dataForm: object,
  dataConstructor: array,
};

function getValidValueRequest(dataForm, dataConstructor) {
  return {
    name: dataForm.name,
    headers: dataConstructor.map(({ value }) => value),
  };
}

function OffcanvasControlValuesCreate() {
  const dispatch = useDispatch();
  const [dataConstructor, setDataConstructor] = useState([]);

  const isLoadingControlValue =
    useSelector((state) => state?.controlValues?.controlValues?.isLoading) ??
    false;
  const isLoadingCreateControlValue =
    useSelector(
      (state) => state?.controlValues?.controlValues?.isLoadingCreate
    ) ?? false;
  const isLoadingChangeControlValue =
    useSelector(
      (state) => state?.controlValues?.controlValues?.isLoadingChange
    ) ?? false;

  const isLoading =
    isLoadingControlValue ||
    isLoadingCreateControlValue ||
    isLoadingChangeControlValue;

  const formOptions = {
    resolver: yupResolver(validationSchema),
  };
  const { register, handleSubmit, formState, reset } = useForm(formOptions);
  const { errors } = formState;

  const handleClearFields = () => {
    reset();
    setDataConstructor([]);
  };
  const onSubmit = (data, onHideModal) => {
    const currentValue = getValidValueRequest(data, dataConstructor);
    dispatch(controlValuesActions.createControlValue.start(currentValue));

    if (typeof onHideModal === 'function') {
      onHideModal();
    }
  };

  return (
    <OffcanvasButton
      backdrop={false}
      onHide={handleClearFields}
      title='New Control Value bundle'
      width={460}
      bodyContent={() => (
        <OffcanvasEditControlValuesContent
          register={register}
          errors={errors}
          onSubmit={handleSubmit(onSubmit)}
          dataConstructor={dataConstructor}
          setDataConstructor={setDataConstructor}
        />
      )}
      buttonRenderer={({ onClick }) => (
        <Button
          variant={VARIANT_UI.primary}
          onClick={onClick}
          disabled={isLoading}>
          New Bundle
        </Button>
      )}
      footerContent={({ onHide }) => (
        <div className={st.buttonGroup}>
          <Button
            variant={VARIANT_UI.primary}
            disabled={isLoading}
            onClick={handleSubmit((value) => onSubmit(value, onHide))}>
            Save Changes
          </Button>
        </div>
      )}
    />
  );
}

export default OffcanvasControlValuesCreate;
