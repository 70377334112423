import AdminMenu from 'components/AdminMenu/AdminMenu';
import React from 'react';
import { node } from 'prop-types';
import st from './AdminLayout.module.scss';

AdminLayout.propTypes = {
  children: node,
};

function AdminLayout({ children }) {
  return (
    <div className={st.container}>
      <div className={st.holderMenu}>
        <AdminMenu />
      </div>
      <div className={st.content}>{children}</div>
    </div>
  );
}

export default AdminLayout;
