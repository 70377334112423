import { createAction } from '@reduxjs/toolkit';
import { getRequestAction } from 'helpers/functions';

const ACTION_PREFIX = 'notes';

export const getNoteList = getRequestAction(`${ACTION_PREFIX}/getNoteList`);

export const createNote = getRequestAction(`${ACTION_PREFIX}/createNote`);

export const changeIsAddingNote = createAction(
  `${ACTION_PREFIX}/changeIsAddingNote`
);
