import React, { memo, useEffect } from 'react';
import Modal from 'componentsShared/Modal/Modal';
import { string, number, bool, oneOfType, func, object } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import * as jobsActions from 'store/jobs/actions';
import * as partTypesActions from 'store/partTypes/actions';
import Loader from 'componentsShared/Loader/Loader';
import JobsSummaryWrapper from 'components/Jobs/JobsSummaryWrapper/JobsSummaryWrapper';
import { Button } from 'react-bootstrap';
import { VARIANT_OUTLINE_UI } from 'constants/ui';
import { QRIcon } from 'assets/icons';
import st from './ModalJobSummary.module.scss';

ModalJobSummary.propTypes = {
  showModalSummary: bool,
  setShowModalSummary: func,
  modalTitleAcceptBtn: string,
  handleAcceptBtn: func,
  partId: oneOfType([number, string]),
  isProgressJob: bool,
  isInterprocessQA: bool,
  jobSummaryInfo: object,
};

function ModalJobSummary({
  showModalSummary = false,
  setShowModalSummary = () => {},
  modalTitleAcceptBtn = '',
  handleAcceptBtn = () => {},
  partId,
  isProgressJob = false,
  setShowModalQR = () => {},
  selectAvailableJob = [],
  setSelectAvailableJob = () => {},
  isInterprocessQA,
  jobSummaryInfo = {},
  jobIdList = [],
}) {
  const dispatch = useDispatch();

  const availableJobIds =
    useSelector((state) => state?.jobs?.getJobSummary?.availableIds) ?? [];
  const unavailableIdsJobIds =
    useSelector((state) => state?.jobs?.getJobSummary?.unavailableIds) ?? [];
  const isLoadingSummary =
    useSelector((state) => state?.jobs?.getJobSummary?.isLoading) ?? false;

  const { customerName, partTypeName } = jobSummaryInfo?.partDateils ?? {};

  const title =
    customerName && partTypeName
      ? `Job: ${customerName}, ${partTypeName}`
      : 'Job Summary';

  const renderButtonBottomLeft = ({ onClose }) => {
    return (
      <div className={st.holderButtonBottom}>
        <Button variant={VARIANT_OUTLINE_UI.primary} onClick={onClose}>
          Cancel
        </Button>
      </div>
    );
  };

  const renderButtonBottomRight = () => {
    return (
      <div className={st.holderButtonBottom}>
        {!isProgressJob && (
          <Button
            className={st.buttonBottomRightQR}
            onClick={() => {
              setShowModalQR(true);
              setShowModalSummary(false);
            }}
            variant={VARIANT_OUTLINE_UI.primary}>
            <div className={st.holderTextButtonQR}>
              <QRIcon className={st.QRIcon} />
              Scan Next
            </div>
          </Button>
        )}
        <Button
          onClick={handleAcceptBtn}
          disabled={selectAvailableJob.length === 0}>
          {modalTitleAcceptBtn} ({selectAvailableJob.length})
        </Button>
      </div>
    );
  };

  useEffect(() => {
    if (selectAvailableJob.length > 0 && showModalSummary) {
      const firstJob = selectAvailableJob[0];
      dispatch(jobsActions.getJobSummary.start(firstJob));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, showModalSummary]);

  useEffect(() => {
    if (showModalSummary && partId) {
      dispatch(
        partTypesActions.getFileListByAllType.start({
          partId,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModalSummary, partId, dispatch]);

  useEffect(() => {
    if (showModalSummary && selectAvailableJob[0]) {
      dispatch(
        jobsActions.getFileInterprocessQA.start({
          id: selectAvailableJob[0],
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModalSummary, selectAvailableJob[0], dispatch]);

  return (
    <div>
      <Modal
        title={title}
        size='lg'
        show={showModalSummary}
        setShow={setShowModalSummary}
        buttonBottomLeft={renderButtonBottomLeft}
        buttonBottomRight={renderButtonBottomRight}
        heightBody='calc(100vh - 240px)'
        classNameBody={st.modalBody}
        classNameTitle={st.modalTitle}
        fullWidthDesktop>
        {!isLoadingSummary ? (
          <JobsSummaryWrapper
            selectAvailableJob={selectAvailableJob}
            setSelectAvailableJob={setSelectAvailableJob}
            availableJobIds={availableJobIds}
            unavailableIdsJobIds={unavailableIdsJobIds}
            isProgressJob={isProgressJob}
            isInterprocessQA={isInterprocessQA}
            jobSummaryInfo={jobSummaryInfo}
            jobIdList={jobIdList}
          />
        ) : (
          <Loader isVisible />
        )}
      </Modal>
    </div>
  );
}

export default memo(ModalJobSummary);
