// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OffcanvasEditControlValuesContent_holderTemplatePhase__3N7aj {\n  margin-top: 24px; }\n", "",{"version":3,"sources":["webpack://src/components/Offcanvas/OffcanvasEditControlValuesContent/OffcanvasEditControlValuesContent.module.scss"],"names":[],"mappings":"AAAA;EACE,gBAAgB,EAAA","sourcesContent":[".holderTemplatePhase {\n  margin-top: 24px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"holderTemplatePhase": "OffcanvasEditControlValuesContent_holderTemplatePhase__3N7aj"
};
export default ___CSS_LOADER_EXPORT___;
