import { getQueryParam, getQueryVariable } from 'helpers/path';
import React, { useEffect, useMemo, useState } from 'react';
import { Pagination } from 'react-bootstrap';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from 'react-router';
import { v4 as uuidv4 } from 'uuid';
import NavigationService from 'services/navigation/NavigationService';
import { oneOfType, string, func, bool, number } from 'prop-types';
import cn from 'classnames';
import st from './FooterPagination.module.scss';

FooterPagination.propTypes = {
  page: oneOfType([number, string]),
  countItem: oneOfType([number, string]),
  onChange: func,
  size: oneOfType([number, string]),
  className: string,
  classNameLine: string,
  isFullLine: bool,
};

function FooterPagination({
  page = 0,
  countItem,
  onChange = () => {},
  size,
  className,
  classNameLine,
  isFullLine = false,
  placeholder = <></>,
  isShowPlaceholder = false,
}) {
  const location = useLocation();
  const queryVariable = getQueryVariable(location.search);
  const currentPage = +page + 1;
  const [isEllipsis, setIsEllipsis] = useState({
    start: false,
    end: false,
  });
  const countPages = Math.ceil(countItem / size);
  const isEmptyPage = countPages === 0;

  const pages = useMemo(() => {
    if (isEmptyPage) {
      setIsEllipsis({ start: false, end: false });
      return [1];
    }
    if (countPages <= 9) {
      setIsEllipsis({ start: false, end: false });
      return Array(countPages)
        .fill()
        .map((x, i) => i + 1);
    }
    const arrayPages = [
      1,
      currentPage - 2,
      currentPage - 1,
      currentPage,
      currentPage + 1 > countPages ? -1 : currentPage + 1,
      currentPage + 2 > countPages ? -1 : currentPage + 2,
      countPages,
    ].filter((item) => item > 0);

    const uniquePages = [...new Set(arrayPages)];
    setIsEllipsis({
      start: uniquePages[1] !== 2,
      end:
        uniquePages[uniquePages.length - 1] - 1 !==
        uniquePages[uniquePages.length - 2],
    });

    return uniquePages;
  }, [countPages, currentPage, isEmptyPage]);

  const disabledStartArrow = useMemo(() => {
    return currentPage === 1;
  }, [currentPage]);

  const disabledEndArrow = useMemo(() => {
    return currentPage === countPages || isEmptyPage;
  }, [currentPage, countPages, isEmptyPage]);

  const onChangePages = (value) => {
    if (value !== currentPage) {
      onChange(value > 0 ? value - 1 : 0);
    }
    return null;
  };

  useEffect(() => {
    if (!isShowPlaceholder) {
      const queryParams = { ...queryVariable, page };
      const queryParamsString = getQueryParam(queryParams);
      const path = `${location.pathname}${queryParamsString}`;
      NavigationService.replacePath(path);
      onChangePages(currentPage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, isShowPlaceholder]);

  useEffect(() => {
    if (+page > +countPages - 1) {
      const queryParams = { ...queryVariable, page: countPages };
      const queryParamsString = getQueryParam(queryParams);
      const path = `${location.pathname}${queryParamsString}`;
      NavigationService.replacePath(path);
      onChangePages(+countPages);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, countPages]);

  if (isShowPlaceholder) {
    return placeholder;
  }

  return (
    <div>
      <div
        className={cn(
          st.dividingLine,
          { [st.fullDividingLine]: isFullLine },
          classNameLine
        )}
      />
      <div className={cn(st.container, className)}>
        <Pagination>
          <Pagination.First
            disabled={disabledStartArrow}
            onClick={() => onChangePages(1)}
          />
          <Pagination.Prev
            disabled={disabledStartArrow}
            onClick={() => onChangePages(currentPage - 1)}
          />
          {pages.map((item, index) => {
            const currentKey = uuidv4();
            return (
              <React.Fragment key={currentKey}>
                {index === pages.length - 1 && isEllipsis.end && (
                  <Pagination.Ellipsis disabled />
                )}
                <Pagination.Item
                  disabled={isEmptyPage}
                  active={currentPage === item}
                  onClick={() => onChangePages(item)}>
                  {item}
                </Pagination.Item>
                {index === 0 && isEllipsis.start && (
                  <Pagination.Ellipsis disabled />
                )}
              </React.Fragment>
            );
          })}
          <Pagination.Next
            disabled={disabledEndArrow}
            onClick={() => onChangePages(currentPage + 1)}
          />
          <Pagination.Last
            disabled={disabledEndArrow}
            onClick={() => onChangePages(countPages)}
          />
        </Pagination>
      </div>
    </div>
  );
}

export default FooterPagination;
