import React, { useState } from 'react';
import TravelerStep from 'components/Traveler/TravelerStep/TravelerStep';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { deepClone } from 'helpers/functions';
import {
  deleteFile,
  deleteOperation,
  deleteRequireFile,
  getDraggedDom,
  getDragStartPlaceholder,
  getDragUpdatePlaceholder,
  getTypeDragTraveler,
  reorder,
  reorderFile,
  reorderOperation,
  reorderRequireFile,
} from 'helpers/dragAndDrop';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import { string } from 'prop-types';
import * as travelersActions from 'store/travelers/actions';
import DropdownButton from 'componentsShared/DropdownButton/DropdownButton';
import DragCustomPlaceholder from 'componentsShared/DragCustomPlaceholder/DragCustomPlaceholder';
import Text from 'componentsShared/Text/Text';
import {
  ARRAY_KEY_HIDE_CONTROL_VALUES,
  DND_TYPE,
  DROPPABLE_DELETE_ID,
} from 'constants/traveler';
import { VARIANT_OUTLINE_UI } from 'constants/ui';
import cn from 'classnames';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useParams } from 'react-router';
import { INTERMEDIATE_FILE_DRAGGABLE_ID } from 'constants/files';
import TravelerService from 'services/traveler/TravelerService';
import Loader from 'componentsShared/Loader/Loader';
import st from './PhasesContent.module.scss';

PhasesContent.propTypes = {
  phaseType: string.isRequired,
  phaseName: string.isRequired,
};

function PhasesContent({ phaseType, phaseName, titlePhase }) {
  const dispatch = useDispatch();
  const phaseSteps =
    useSelector((state) => state?.travelers?.newTraveler?.[phaseName]?.steps) ??
    [];
  const suggestStep =
    useSelector((state) => state?.travelers?.suggest?.step?.[phaseType]) ?? [];
  const isLoadingSuggest =
    useSelector((state) => state?.travelers?.suggest?.isLoading) ?? false;
  const controlValuesData =
    useSelector((state) => state?.controlValues?.controlValues?.data) ?? {};
  const [isDrag, setIsDrag] = useState({ stepId: '', type: '' });
  const [placeholderProps, setPlaceholderProps] = useState({
    props: {},
    type: '',
    sourceDroppableId: '',
  });
  const { id: travelerId } = useParams();
  const isStatusActual = TravelerService.isStatusActual(travelerId);

  const isStep = phaseSteps?.length > 0;

  const isShowControlValues =
    !ARRAY_KEY_HIDE_CONTROL_VALUES.includes(phaseType);

  const updateSteps = (value, field = 'steps') => {
    dispatch(
      travelersActions.updateNewTravelerData({
        phase: phaseName,
        field,
        value,
      })
    );
  };

  const handleAddControlValues = (stepIndex, bundleId, controlValuesIdx) => {
    const currentSteps = deepClone(phaseSteps);
    const modifiedStep = currentSteps[stepIndex];
    const controlValuesName = controlValuesData?.[bundleId]?.name || '';
    const resultOperation = {
      name: controlValuesName,
      id: uuidv4(),
      bundle_id: bundleId || null,
      isControlValues: true,
    };

    modifiedStep.bundle_id = bundleId;
    modifiedStep.bundle_idx = controlValuesIdx;
    modifiedStep.operations = [
      ...(modifiedStep?.operations || []),
      resultOperation,
    ];

    currentSteps.splice(stepIndex, 1, modifiedStep);
    updateSteps(currentSteps);
  };

  const handleAddFileRequireFile = (stepIndex) => {
    const currentSteps = deepClone(phaseSteps);
    const modifiedStep = currentSteps[stepIndex];

    const operetionLength = currentSteps[stepIndex].operations.length;
    let currentOperationIndex;

    let i = 0;
    while (i < operetionLength) {
      currentOperationIndex = operetionLength - 1 - i;

      if (
        modifiedStep.operations[currentOperationIndex].bundle_id ||
        currentOperationIndex === modifiedStep?.bundle_idx
      ) {
        i += 1;
      } else {
        break;
      }
    }

    modifiedStep.operations[currentOperationIndex].require_file = true;

    currentSteps.splice(stepIndex, 1, modifiedStep);

    updateSteps(currentSteps);
  };

  const handleUpdateInformalQa = (stepIndex, value) => {
    const currentSteps = deepClone(phaseSteps);
    const modifiedStep = currentSteps[stepIndex];
    modifiedStep.informal_qa = value;
    currentSteps.splice(stepIndex, 1, modifiedStep);
    updateSteps(currentSteps);
  };

  const handleAddStep = (type, prevStepIndex) => {
    const currentSteps = deepClone(phaseSteps);
    const modifiedStep = {
      phase: phaseName,
      name: type,
      id: uuidv4(),
      operations: [],
    };

    if (typeof prevStepIndex === 'number') {
      currentSteps.splice(prevStepIndex + 1, 0, modifiedStep);
      return updateSteps(currentSteps);
    }

    return updateSteps([...currentSteps, modifiedStep]);
  };

  const getDropdownListStep = (prevStepIndex) => {
    if (isLoadingSuggest) {
      return [
        {
          content: () => (
            <Text variant='normal'>
              <Loader isVisible className={st.dropdownLoader} />
            </Text>
          ),
          disabled: true,
        },
      ];
    }
    if (suggestStep.length === 0) {
      return [
        {
          content: () => <Text variant='normal'>Empty Steps</Text>,
          disabled: true,
        },
      ];
    }
    return suggestStep.map((item) => ({
      content: () => <Text variant='normal'>{item}</Text>,
      onClick: () => handleAddStep(item, prevStepIndex),
    }));
  };

  const dropdownListStep = getDropdownListStep();

  const handleToggleStep = (isOpen) => {
    if (isOpen) {
      dispatch(
        travelersActions.getSuggestStepByPhase.start({
          phase: phaseType,
        })
      );
    }
  };

  const handleChangeOperation = (
    stepIndex,
    value,
    operationIndex,
    bundle_id
  ) => {
    const currentSteps = deepClone(phaseSteps);
    const modifiedStep = currentSteps[stepIndex];

    let resultOperation;
    if (typeof operationIndex === 'number') {
      modifiedStep.operations[operationIndex].name = value;
    } else {
      resultOperation = {
        name: value,
        id: uuidv4(),
        bundle_id: bundle_id || null,
      };
      modifiedStep.operations = [
        ...(modifiedStep?.operations || []),
        resultOperation,
      ];
    }

    currentSteps.splice(stepIndex, 1, modifiedStep);

    updateSteps(currentSteps);
  };

  const handleAddFileForOperation = (stepIndex, value) => {
    const currentSteps = deepClone(phaseSteps);
    const modifiedStep = currentSteps[stepIndex];

    const operetionLength = currentSteps[stepIndex].operations.length;
    let currentOperationIndex;

    let i = 0;
    while (i < operetionLength) {
      currentOperationIndex = operetionLength - 1 - i;

      if (
        modifiedStep.operations[currentOperationIndex].bundle_id ||
        currentOperationIndex === modifiedStep?.bundle_idx
      ) {
        i += 1;
      } else {
        break;
      }
    }

    const operationFileList =
      modifiedStep.operations[currentOperationIndex].files;

    if (Array.isArray(operationFileList)) {
      const isFindFile = modifiedStep.operations[
        currentOperationIndex
      ].files.find((itemFileName) => itemFileName === value);

      if (!isFindFile) {
        modifiedStep.operations[currentOperationIndex].files = [
          ...operationFileList,
          value,
        ];
      }
    } else {
      modifiedStep.operations[currentOperationIndex].files = [value];
    }

    currentSteps.splice(stepIndex, 1, modifiedStep);

    updateSteps(currentSteps);
  };

  const handleDeleteStep = (stepIndex) => {
    const currentSteps = deepClone(phaseSteps);
    currentSteps.splice(stepIndex, 1);
    updateSteps(currentSteps);
  };

  const handleDeleteOperation = (stepIndex, operationIndex) => {
    const currentSteps = deepClone(phaseSteps);
    const currentOperations = currentSteps[stepIndex].operations;

    currentOperations.splice(operationIndex, 1);
    updateSteps(currentSteps);
  };

  const onDragEnd = (result) => {
    setPlaceholderProps({ props: {}, type: '', sourceDroppableId: '' });
    const startDroppableIndex = result?.source?.index ?? 0;
    const startDroppableId = result?.source?.droppableId || '';
    const endDroppableIndex = result?.destination?.index ?? 0;
    const endDroppableId = result?.destination?.droppableId || '';

    if (
      !result.destination ||
      (startDroppableIndex === endDroppableIndex &&
        endDroppableId === startDroppableId)
    ) {
      setIsDrag({ stepId: '', type: '' });
      return;
    }
    const resultType = result.type;
    const droppableDeleteIdArray = Object.values(DROPPABLE_DELETE_ID);
    const currentEndDroppableId = endDroppableId.replace(/[0-9]/g, '');
    let currentSteps;
    let currentOperations;
    let stepIndex;
    let reorderOperationValue;
    let fileName;
    let draggableIdArray;
    let operationId;

    if (droppableDeleteIdArray.includes(currentEndDroppableId)) {
      if (currentEndDroppableId === DROPPABLE_DELETE_ID.operation) {
        reorderOperationValue = deleteOperation(
          phaseSteps[parseInt(result.type, 10)],
          result.source.index
        );
        currentSteps = deepClone(phaseSteps);
        currentSteps[result.type].operations = reorderOperationValue.operations;
        currentSteps[result.type].bundle_id = reorderOperationValue.bundleId;
        currentSteps[result.type].bundle_idx =
          reorderOperationValue.controlValuesIdx;

        updateSteps(currentSteps);
      }
      if (currentEndDroppableId === DROPPABLE_DELETE_ID.file) {
        draggableIdArray = result.draggableId.split(
          INTERMEDIATE_FILE_DRAGGABLE_ID
        );
        fileName = draggableIdArray[draggableIdArray.length - 1];
        stepIndex = result.type.replace(DND_TYPE.file, '');
        currentOperations = deleteFile(
          phaseSteps[parseInt(stepIndex, 10)].operations,
          result.source.droppableId,
          fileName
        );
        currentSteps = deepClone(phaseSteps);
        currentSteps[stepIndex].operations = currentOperations;
        updateSteps(currentSteps);
      }

      if (currentEndDroppableId === DROPPABLE_DELETE_ID.requireFile) {
        operationId = result.source.droppableId.replace('/requireFile', '');
        stepIndex = result.type.replace(DND_TYPE.requireFile, '');
        currentOperations = deleteRequireFile(
          phaseSteps[parseInt(stepIndex, 10)].operations,
          operationId
        );
        currentSteps = deepClone(phaseSteps);
        currentSteps[stepIndex].operations = currentOperations;
        updateSteps(currentSteps);
      }

      setIsDrag({ stepId: '', type: '' });
      return;
    }

    switch (getTypeDragTraveler(resultType)) {
      case DND_TYPE.step:
        currentSteps = reorder(
          phaseSteps,
          result.source.index,
          result.destination.index
        );
        updateSteps(currentSteps);
        break;
      case DND_TYPE.file:
        draggableIdArray = result.draggableId.split(
          INTERMEDIATE_FILE_DRAGGABLE_ID
        );
        fileName = draggableIdArray[draggableIdArray.length - 1];
        stepIndex = result.type.replace(DND_TYPE.file, '');
        currentOperations = reorderFile(
          phaseSteps[parseInt(stepIndex, 10)].operations,
          result.source.droppableId,
          result.destination.droppableId,
          fileName,
          result.destination.index
        );
        currentSteps = deepClone(phaseSteps);
        currentSteps[stepIndex].operations = currentOperations;
        updateSteps(currentSteps);
        break;
      case DND_TYPE.requireFile:
        stepIndex = result.type.replace(DND_TYPE.requireFile, '');
        currentOperations = reorderRequireFile(
          phaseSteps[parseInt(stepIndex, 10)].operations,
          startDroppableId,
          endDroppableId
        );
        currentSteps = deepClone(phaseSteps);
        currentSteps[stepIndex].operations = currentOperations;
        updateSteps(currentSteps);
        break;
      default:
        reorderOperationValue = reorderOperation(
          phaseSteps[parseInt(result.type, 10)],
          result.source.index,
          result.destination.index
        );
        currentSteps = deepClone(phaseSteps);
        currentSteps[result.type].operations = reorderOperationValue.operations;
        currentSteps[result.type].bundle_idx =
          reorderOperationValue.controlValuesIdx;
        updateSteps(currentSteps);
    }
    setIsDrag({ stepId: '', type: '' });
  };

  const onDragStart = (result) => {
    const resultType = result.type;
    let stepId = '';
    let stepIndex;
    switch (getTypeDragTraveler(resultType)) {
      case DND_TYPE.step:
        break;
      case DND_TYPE.file:
        stepIndex = resultType.replace(DND_TYPE.file, '');
        stepId = phaseSteps[stepIndex].id;
        setIsDrag({ stepId: stepId.toString(), type: DND_TYPE.file });
        break;
      case DND_TYPE.requireFile:
        stepIndex = resultType.replace(DND_TYPE.requireFile, '');
        stepId = phaseSteps[stepIndex].id;
        setIsDrag({ stepId: stepId.toString(), type: DND_TYPE.requireFile });
        break;
      default:
        stepId = result.source.droppableId.replace(DND_TYPE.operation, '');
        setIsDrag({ stepId: stepId.toString(), type: DND_TYPE.operation });
        break;
    }

    const draggedDOM = getDraggedDom(result.draggableId);
    if (!draggedDOM) {
      return;
    }
    const dragStartPlaceholder = getDragStartPlaceholder({
      draggedDOM,
      result,
    });
    setPlaceholderProps(dragStartPlaceholder);
  };

  const onDragUpdate = (result) => {
    if (!result.destination) {
      return;
    }
    const draggedDOM = getDraggedDom(result.draggableId);
    if (!draggedDOM) {
      return;
    }
    const dragUpdatePlaceholder = getDragUpdatePlaceholder({
      draggedDOM,
      result,
    });

    setPlaceholderProps(dragUpdatePlaceholder);
  };

  const renderSteps = () => {
    return (
      <Droppable droppableId='droppable' type={DND_TYPE.step}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} className={st.stepList}>
            {phaseSteps.map((step, i) => {
              const stepNumber = i + 1;
              const isDragCurrentStep = isDrag.stepId === step.id.toString();
              const isDragOperation =
                isDragCurrentStep && isDrag.type === DND_TYPE.operation;
              const isDragFile =
                isDragCurrentStep && isDrag.type === DND_TYPE.file;
              const isDragRequireFile =
                isDragCurrentStep && isDrag.type === DND_TYPE.requireFile;
              const dropdownListForEmptyButton = getDropdownListStep(i);

              const currentOperations = deepClone(step.operations);
              const isControlValues =
                !!step?.bundle_id && typeof step?.bundle_idx === 'number';

              return (
                <Draggable
                  key={step.id.toString()}
                  draggableId={step.id.toString()}
                  index={i}>
                  {(providedStep, snapshotStep) => (
                    <TravelerStep
                      currentRef={providedStep.innerRef}
                      provided={providedStep}
                      stepNumber={stepNumber}
                      name={step.name}
                      operations={currentOperations}
                      stepId={step.id}
                      handleChangeOperation={handleChangeOperation}
                      handleDeleteStep={handleDeleteStep}
                      handleDeleteOperation={handleDeleteOperation}
                      handleAddFileForOperation={handleAddFileForOperation}
                      stepIndex={i}
                      isDraggingOver={snapshot.isDraggingOver}
                      isDragOperation={isDragOperation}
                      isDragFile={isDragFile}
                      isDragRequireFile={isDragRequireFile}
                      snapshotStep={snapshotStep}
                      placeholderProps={placeholderProps}
                      phaseName={phaseName}
                      dropdownListStep={dropdownListForEmptyButton}
                      handleToggleStep={handleToggleStep}
                      bundleId={step.bundle_id}
                      informalQa={step.informal_qa}
                      handleUpdateInformalQa={handleUpdateInformalQa}
                      isShowControlValues={isShowControlValues}
                      handleAddControlValues={handleAddControlValues}
                      handleAddFileRequireFile={handleAddFileRequireFile}
                      isControlValues={isControlValues}
                      phaseType={phaseType}
                    />
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
            <DragCustomPlaceholder
              placeholderProps={placeholderProps}
              type={DND_TYPE.step}
              isDraggingOver={snapshot.isDraggingOver}
            />
          </div>
        )}
      </Droppable>
    );
  };

  const renderButtonAddStep = () =>
    !isStatusActual && !isStep ? (
      <DropdownButton
        title='Add Step'
        list={dropdownListStep}
        btnVariant={VARIANT_OUTLINE_UI.secondary}
        size='sm'
        onToggle={handleToggleStep}
        align='start'
      />
    ) : null;

  return (
    <div
      className={cn(st.container, { [st.containerMarging]: isStep })}
      id={phaseName}>
      <div className={st.wrapperPhase}>
        <Text variant='normal3' className={st.holderPhase}>
          Phase:
          <h3 className={st.phaseName}>{titlePhase}</h3>
        </Text>
      </div>

      <DragDropContext
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
        onDragUpdate={onDragUpdate}>
        {isStep ? renderSteps() : renderButtonAddStep()}
      </DragDropContext>
    </div>
  );
}

export default PhasesContent;
