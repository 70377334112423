import React from 'react';
import CenteredColumnLayout from 'layouts/CenteredColumnLayout/CenteredColumnLayout';
import { Button } from 'react-bootstrap';
import NavigationService from 'services/navigation/NavigationService';
import Text from 'componentsShared/Text/Text';
import st from './EmptyPage.module.scss';

function EmptyPage() {
  const handleClick = () => {
    NavigationService.navigateToDefaultPath();
  };

  return (
    <CenteredColumnLayout>
      <div className={st.container}>
        <div className={st.content}>
          <div className={st.textWrapper}>
            <Text variant='normal' className={st.numberError} bold>
              404
            </Text>
            <Text variant='normal' className={st.wrongText} bold>
              Oops! Something is wrong
            </Text>
            <Text variant='normal'>
              Sorry, the page you are looking for doesn’t exist or has been
              removed.
            </Text>
          </div>
          <Button onClick={handleClick}>Go Home</Button>
        </div>
      </div>
    </CenteredColumnLayout>
  );
}

export default EmptyPage;
