import React, { useEffect } from 'react';
import Text from 'componentsShared/Text/Text';
import { useDispatch, useSelector } from 'react-redux';
import {
  MAX_FILE_SIZE,
  NOTIFICATION_ERROR_TYPE_FILE_TITLE,
  NOTIFICATION_LARGE_SIZE_FILE_TEXT,
  NOTIFICATION_LARGE_SIZE_FILE_TITLE,
  VARIANT_OUTLINE_UI,
  VARIANT_UI,
} from 'constants/ui';
import * as partTypesActions from 'store/partTypes/actions';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useParams } from 'react-router';
import AttachFilesButton from 'components/AttachFilesButton/AttachFilesButton';
import { FILE_ACCEPT_IMAGE_AND_PDF, FILE_TYPES } from 'constants/files';
import FileList from 'componentsShared/FileList/FileList';
import { bool } from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import NotificationsService from 'services/notifications/NotificationsService';
import st from './PartsDetailsFiles.module.scss';

PartsDetailsFiles.propTypes = {
  isNewPart: bool,
  isRemotePart: bool,
};

function PartsDetailsFiles({
  isNewPart = false,
  isRemotePart = false,
  cacheClientFile = [],
  setCacheClientFile = () => {},
  isCustomer = false,
}) {
  const dispatch = useDispatch();
  const { id: partId } = useParams();

  const currentDataFiles =
    useSelector((state) => state?.partTypes?.files?.data?.[partId]) ?? {};
  const isLoading =
    useSelector((state) => state?.partTypes?.files?.isLoading) ?? false;

  const dataFile = isCustomer ? cacheClientFile : currentDataFiles;

  const filesDataList = [
    {
      title: 'Part Cover',
      buttonTitle: 'Add File',
      type: FILE_TYPES.cover,
      showButton:
        !dataFile?.[FILE_TYPES.cover] ||
        dataFile?.[FILE_TYPES.cover]?.length === 0,
    },
    {
      title: 'Work Specifications',
      buttonTitle: 'Add File',
      type: FILE_TYPES.specification,
      showButton: true,
    },
    {
      title: 'Technical Drawings',
      buttonTitle: 'Add File',
      type: FILE_TYPES.drawing,
      showButton: true,
    },
  ];

  const onUploadFile = (type) => (file, e) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);

    e.target.value = '';

    const fileTypeArray = FILE_ACCEPT_IMAGE_AND_PDF.split(',').map((item) =>
      item.trim()
    );

    if (!fileTypeArray.includes(file.type)) {
      NotificationsService.throwNotification({
        title: NOTIFICATION_LARGE_SIZE_FILE_TITLE,
        text: NOTIFICATION_ERROR_TYPE_FILE_TITLE,
        variant: VARIANT_UI.danger,
      });

      return;
    }

    if (!file || file.size > MAX_FILE_SIZE) {
      NotificationsService.throwNotification({
        title: NOTIFICATION_LARGE_SIZE_FILE_TITLE,
        text: NOTIFICATION_LARGE_SIZE_FILE_TEXT,
        variant: VARIANT_UI.danger,
      });
    } else {
      if (isCustomer) {
        setCacheClientFile((prev) => {
          return {
            ...prev,
            [type]: [...prev[type], file],
          };
        });
        return;
      }

      handleUploadFile(formData);
    }
  };

  const handleUploadFile = (file) => {
    dispatch(partTypesActions.addFile.start({ id: partId, file }));
  };

  const handleRemoveFile = (path) => {
    dispatch(partTypesActions.removeFiles.start({ id: partId, path }));
  };

  const renderFilesDataList = () => {
    return filesDataList.map((item) => {
      return (
        <div className={st.holderFiles} key={uuidv4()}>
          <Text variant='normal' bold>
            {item.title}
          </Text>
          <FileList
            list={dataFile?.[item.type]}
            handleRemoveFile={handleRemoveFile}
            showClearIcon={!isRemotePart}
            disableClearIcon={isLoading}
            classNameFileText={st.fileText}
            classNameHolderFileName={st.holderFileName}
            classNameHolderFile={st.holderFileItem}
            classNameContainer={st.containerFile}
            isShowPreview={!isCustomer}
          />
          {item.showButton && (
            <AttachFilesButton
              handleChange={onUploadFile(item.type)}
              title={item.buttonTitle}
              varinant={VARIANT_OUTLINE_UI.primary}
              disabled={isLoading || (isNewPart && !isCustomer) || isRemotePart}
              accept={FILE_ACCEPT_IMAGE_AND_PDF}
            />
          )}
        </div>
      );
    });
  };

  useEffect(() => {
    if (partId) {
      dispatch(partTypesActions.getFiles.start({ id: partId }));
    }
  }, [dispatch, partId]);

  return <div className={st.containerFiles}>{renderFilesDataList()}</div>;
}
export default PartsDetailsFiles;
