import { fork } from 'redux-saga/effects';

import { travelersSagas } from 'store/travelers/sagas';
import { authSagas } from 'store/auth/sagas';
import { customersSagas } from 'store/customers/sagas';
import { ordersSagas } from 'store/orders/sagas';
import { employeesSagas } from 'store/employees/sagas';
import { templatesSagas } from 'store/templates/sagas';
import { partTypesSagas } from 'store/partTypes/sagas';
import { controlValuesSagas } from 'store/controlValues/sagas';
import { jobsSagas } from 'store/jobs/sagas';
import { filesSagas } from 'store/files/sagas';
import { rfqSagas } from 'store/rfqs/sagas';
import { worklogsSagas } from 'store/worklogs/sagas';
import { notesSagas } from 'store/notes/sagas';
import { scoreItemsSagas } from 'store/scoreItems/sagas';
import { ncrSagas } from 'store/ncr/sagas';
import { reportsSagas } from 'store/reports/sagas';
import { metadataSagas } from 'store/metadata/sagas';

export default function* rootSaga() {
  yield fork(travelersSagas);
  yield fork(authSagas);
  yield fork(customersSagas);
  yield fork(ordersSagas);
  yield fork(employeesSagas);
  yield fork(templatesSagas);
  yield fork(partTypesSagas);
  yield fork(controlValuesSagas);
  yield fork(jobsSagas);
  yield fork(filesSagas);
  yield fork(rfqSagas);
  yield fork(worklogsSagas);
  yield fork(notesSagas);
  yield fork(scoreItemsSagas);
  yield fork(ncrSagas);
  yield fork(reportsSagas);
  yield fork(metadataSagas);
}
