import React, { useEffect } from 'react';
import CenteredColumnLayout from 'layouts/CenteredColumnLayout/CenteredColumnLayout';
import Loader from 'componentsShared/Loader/Loader';
import HeaderContent from 'componentsShared/HeaderContent/HeaderContent';
import * as jobsActions from 'store/jobs/actions';
import * as customersActions from 'store/customers/actions';
import { useSelector, useDispatch } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useLocation } from 'react-router';
import { ROUTES } from 'router/routes';
import NavigationService from 'services/navigation/NavigationService';
import JobDetailsQA from 'components/Jobs/JobsDetailsQA/JobsDetailsQA';
import JobsDetailsQAFooter from 'components/Jobs/JobsDetailsQAFooter/JobsDetailsQAFooter';
import ButtonBack from 'componentsShared/ButtonBack/ButtonBack';
import { FILE_TYPES } from 'constants/files';
import { getQuerySearchParams } from 'helpers/path';
import st from './JobDetailsQAPage.module.scss';

const JobDetailsQAPage = () => {
  const location = useLocation();
  const jobIdList = getQuerySearchParams(location.search, 'ids');
  const firstJobId = jobIdList[0];

  const dispatch = useDispatch();

  const currentJob =
    useSelector((state) => state?.jobs?.jobs?.data?.[firstJobId]) ?? {};
  const isLoadingJob =
    useSelector((state) => state?.jobs?.jobs?.isLoading) ?? false;
  const isLoadingRejectJob =
    useSelector((state) => state?.jobs?.jobs?.isLoadingReject) ?? false;
  const isLoadingCustomer =
    useSelector((state) => state?.customers?.customers?.isLoading) ?? false;
  const customerName =
    useSelector(
      (state) =>
        state?.customers?.customers?.data?.[currentJob?.part_type?.customer_id]
          ?.name
    ) ?? '';
  const currentDataFiles =
    useSelector(
      (state) =>
        state?.jobs?.files?.data?.[firstJobId]?.[FILE_TYPES.qaAttachment]
    ) ?? [];

  const customerId = currentJob?.part_type?.customer_id;
  const title = `${customerName}: ${currentJob?.part_type?.name} ${currentJob?.part_type?.number}-Revision-${currentJob?.part_type?.revision}`;

  const handleBackToMyJobs = () => {
    dispatch(jobsActions.changeIsScrollJob(true));
    NavigationService.replacePath(ROUTES.jobList.path);
  };

  const handleSubmit = (value) => {
    const files = currentDataFiles.map((item) => item.path);
    const validValue = { ...value, files, ids: jobIdList };

    dispatch(jobsActions.changeIsScrollJob(true));
    dispatch(jobsActions.approveJobQA.start({ data: validValue }));
  };

  useEffect(() => {
    dispatch(jobsActions.getJob.start({ id: firstJobId }));
  }, [dispatch, firstJobId]);

  useEffect(() => {
    if (customerId && !customerName) {
      dispatch(customersActions.getCustomer.start(customerId));
    }
  }, [dispatch, customerName, customerId]);

  return (
    <CenteredColumnLayout
      classNamePageWrapper={st.layout}
      renderFooter={() => (
        <JobsDetailsQAFooter
          handleSubmit={handleSubmit}
          firstJobId={firstJobId}
        />
      )}>
      <HeaderContent
        backBtn={
          <ButtonBack isCheckModal={false} onClick={handleBackToMyJobs} />
        }
        title={title}
      />
      <div className={st.mainContentHolder}>
        <JobDetailsQA job={currentJob} />
        <Loader
          isVisible={isLoadingJob || isLoadingCustomer || isLoadingRejectJob}
        />
      </div>
    </CenteredColumnLayout>
  );
};

export default JobDetailsQAPage;
