import React, { useEffect, useMemo, useState } from 'react';
import { InputGroup, Button, Form } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import * as partTypesActions from 'store/partTypes/actions';
import { Typeahead, Highlighter } from 'react-bootstrap-typeahead';
import { useDispatch, useSelector } from 'react-redux';
import Text from 'componentsShared/Text/Text';
import {
  MAX_LENGTH_INPUT,
  TIME_SEARCH_DEBOUNCE,
  VARIANT_OUTLINE_UI,
} from 'constants/ui';
import { func, string } from 'prop-types';
import { TRAVELER_STATUS } from 'constants/traveler';
import RequiredLabel from 'componentsShared/RequiredLabel/RequiredLabel';
import AuthService from 'services/auth/AuthService';
import { WarningIcon } from 'assets/icons';
import { debounce } from 'helpers/functions';
import NavigationService from 'services/navigation/NavigationService';
import { ROUTES } from 'router/routes';
import { getRoutePath } from 'helpers/path';
import st from './OrderConstructor.module.scss';

const debouncedSearch = debounce((onSearch, data) => {
  onSearch(data);
}, TIME_SEARCH_DEBOUNCE);

const getOperaionPartType = (partTypeList) => {
  if (partTypeList.length === 0) {
    return [];
  }
  return partTypeList.map((item) => {
    const isActiveTraveler = item?.traveler?.status === TRAVELER_STATUS.ACTUAL;
    const info = `${item.number}-Revision-${item.revision}`;

    return {
      id: item.id,
      name: item.name,
      info,
      isActiveTraveler,
    };
  });
};

OrderConstructor.propTypes = {
  onSubmit: func,
  PONumber: string,
  setPONumber: func,
};

function OrderConstructor({
  onSubmit = () => {},
  PONumber = '',
  setPONumber = () => {},
}) {
  const dispatch = useDispatch();

  const [isLoadingSearch, setIsLoadingSearch] = useState(false);

  const searchValuePart =
    useSelector((state) => state?.partTypes?.fetchParams?.search) ?? '';
  const isLoadingSearchParts =
    useSelector((state) => state?.partTypes?.fetchParams?.isLoading) ?? false;
  const currentCustomerId =
    useSelector((state) => state?.customers?.currentCustomerId) ?? null;
  const partTypeList =
    useSelector((state) => state?.partTypes?.partTypes?.data) ?? {};
  const [selected, setSelected] = useState([]);
  const partTypeListArray = Object.values(partTypeList);
  const isClient = AuthService.isClient();

  const inputId = uuidv4();
  const handleChange = (selectedValue) => {
    if (selected.length === 1 && selectedValue.length === 0) {
      setIsLoadingSearch(false);
    }

    setSelected(selectedValue);
  };

  const options = useMemo(() => {
    return isLoadingSearch ? [] : getOperaionPartType(partTypeListArray);
  }, [isLoadingSearch, partTypeListArray]);

  const handleResetField = () => {
    setSelected([]);
  };

  const onNavigateNewPart = () => {
    NavigationService.navigateToPath(getRoutePath(ROUTES.createPartTypes.path));
  };

  const handleSubmit = () => {
    if (selected.length > 0) {
      onSubmit({ quantity: 1, part_type_id: selected[0].id });
      handleResetField();
    }
  };

  const handleCreatePart = () => {
    onNavigateNewPart();
  };

  const onChangePartFilterSearch = (value) => {
    dispatch(partTypesActions.changeFilterSearch(value));
  };

  const loadPartTypesList = () => {
    dispatch(
      partTypesActions.getPartList.start({
        customer_id: currentCustomerId,
        filter: searchValuePart,
      })
    );
  };

  const handleChangeSearch = (value) => {
    setIsLoadingSearch(true);
    onChangePartFilterSearch(value);
    if (value) {
      return debouncedSearch(() => {
        loadPartTypesList(true);
      });
    }
    loadPartTypesList(true);
  };

  const handleChangePONumber = (e) => {
    const { value } = e.target;
    setPONumber(value);
  };

  useEffect(() => {
    handleChangeSearch('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoadingSearchParts) {
      setIsLoadingSearch(false);
    }
  }, [isLoadingSearchParts]);

  return (
    <div className={st.container}>
      <div>
        <Form.Group>
          <Form.Label>
            <RequiredLabel />
            Purchase Order number
          </Form.Label>
          <Form.Control
            type='PONumber'
            value={PONumber}
            onChange={handleChangePONumber}
            maxLength={MAX_LENGTH_INPUT}
          />
        </Form.Group>
      </div>
      <div className={st.containerPart}>
        <div className={st.holderSuggestPart}>
          <Form.Group>
            <Form.Label>
              <RequiredLabel />
              Part Number
            </Form.Label>
            <InputGroup className={st.inputGroupWrapper}>
              <Typeahead
                labelKey={(option) =>
                  option.info && option.name
                    ? `${option.name} ${option.info}`
                    : ''
                }
                options={options}
                selected={selected}
                onChange={handleChange}
                onInputChange={handleChangeSearch}
                id={inputId}
                placeholder='Part Number'
                emptyLabel={
                  isLoadingSearch ? 'Loading...' : 'No matches found.'
                }
                renderMenuItemChildren={(option, props) => {
                  return (
                    <div
                      key={option.id}
                      value={option.info}
                      className={st.typeaheadItem}>
                      <div className={st.typeaheadItemInfo}>
                        <Text className={st.holderItemText}>
                          <Text
                            variant='normal'
                            className={st.typeaheadItemName}>
                            <Highlighter search={props.text}>
                              {option.name}
                            </Highlighter>
                          </Text>{' '}
                          <Text
                            variant='normal'
                            className={st.typeaheadItemNumber}>
                            <Highlighter search={props.text}>
                              {option.info}
                            </Highlighter>
                          </Text>
                        </Text>
                      </div>
                      {!isClient && (
                        <div className={st.typeaheadItemStatusTravelerWrapper}>
                          {!option.isActiveTraveler && (
                            <WarningIcon className={st.warningIcon} />
                          )}
                        </div>
                      )}
                    </div>
                  );
                }}
              />
              <InputGroup.Text
                id={`${
                  selected.length === 0
                    ? 'inputGroupButtonDisable'
                    : 'inputGroupButton'
                }`}
                onClick={() => {
                  if (selected.length === 0) {
                    return null;
                  }
                  return handleSubmit();
                }}>
                <Text variant='normal' className={st.inputGroupButtonText}>
                  Add to PO
                </Text>
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
        </div>

        <div>
          <Button
            variant={VARIANT_OUTLINE_UI.secondary}
            onClick={handleCreatePart}>
            New Part
          </Button>
        </div>
      </div>
    </div>
  );
}

export default OrderConstructor;
