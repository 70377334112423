import React, { memo } from 'react';
import Text from 'componentsShared/Text/Text';
import { Form } from 'react-bootstrap';
import cn from 'classnames';
import { bool, func, array } from 'prop-types';
import st from './ModalJobRelatedBody.module.scss';

ModalJobRelatedBody.propTypes = {
  selectAvailableJob: array,
  setSelectAvailableJob: func,
  availableJobIds: array,
  unavailableIdsJobIds: array,
  inProgress: bool,
  isInterprocessQA: bool,
};

const STATUS_JOBS = {
  available: 'Available',
  unavailable: 'Unavailable',
};

function ModalJobRelatedBody({
  selectAvailableJob = [],
  setSelectAvailableJob = () => {},
  availableJobIds = [],
  unavailableIdsJobIds = [],
  inProgress = false,
  isInterprocessQA,
}) {
  const isSelectAll = selectAvailableJob.length === availableJobIds.length;

  const handleChangeCheckbox = (jobId) => {
    setSelectAvailableJob((prev) => {
      if (selectAvailableJob.includes(jobId)) {
        return prev.filter((item) => jobId.toString() !== item.toString());
      }
      return [...prev, jobId];
    });
  };

  const renderJobList = (status, jobList) => {
    const isAvailableStatus = status === STATUS_JOBS.available;

    return jobList.map((item) => (
      <div className={st.holderJobItem} key={`${status}-${item}`}>
        <div className={st.holderJobCheck}>
          {isAvailableStatus && (
            <Form.Check
              checked={selectAvailableJob.includes(item)}
              onChange={() => handleChangeCheckbox(item)}
              disabled={inProgress}
            />
          )}
          <Text
            variant='normal'
            className={cn(st.checkLabel, {
              [st.checkLabelAvailable]: isAvailableStatus,
            })}>
            #{item}
          </Text>
        </div>
      </div>
    ));
  };

  const handleMassSelection = () => {
    const jobIds = isSelectAll ? [] : [...availableJobIds];
    setSelectAvailableJob(jobIds);
  };

  return (
    <div className={st.container}>
      <div>
        <div className={st.wrapperTitle}>
          <div className={st.holderTitle}>
            <Text variant='normal' bold className={st.title}>
              {isInterprocessQA ? 'Jobs ready for check' : 'Available Jobs'}
            </Text>
          </div>

          {!inProgress && (
            <div className={st.selectAllButton} onClick={handleMassSelection}>
              <Text className={st.selectAllButtonText} variant='normal'>
                {isSelectAll ? 'Deselect All' : 'Select All'}
              </Text>
            </div>
          )}
        </div>
        <div className={st.holderJobList}>
          {renderJobList(STATUS_JOBS.available, availableJobIds)}
        </div>
      </div>
      {!inProgress && unavailableIdsJobIds?.length > 0 && (
        <>
          <div className={st.line} />
          <div>
            <div className={st.holderTitle}>
              <Text variant='normal' bold className={st.title}>
                {isInterprocessQA
                  ? 'Jobs not ready for check'
                  : 'Unavailable Jobs'}
              </Text>
            </div>
            <div className={st.holderJobList}>
              {renderJobList(STATUS_JOBS.unavailable, unavailableIdsJobIds)}
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default memo(ModalJobRelatedBody);
