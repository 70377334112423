import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CenteredColumnLayout from 'layouts/CenteredColumnLayout/CenteredColumnLayout';
import RFQHeader from 'components/RFQ/RFQHeader/RFQHeader';
import RFQDetails from 'components/RFQ/RFQDetails/RFQDetails';
import * as rfqsActions from 'store/rfqs/actions';
import * as partTypesActions from 'store/partTypes/actions';
import Loader from 'componentsShared/Loader/Loader';
import NavigationService from 'services/navigation/NavigationService';
import AuthService from 'services/auth/AuthService';
import BeforeRedirect from 'componentsShared/BeforeRedirect/BeforeRedirect';
import st from './NewRFQPage.module.scss';

const NewRFQPage = () => {
  const dispatch = useDispatch();
  const [rfqInfo, setRFQInfo] = useState({ description: '' });
  const currentCustomerId =
    useSelector((state) => state?.customers?.currentCustomerId) ?? null;
  const currentCustomerName =
    useSelector(
      (state) => state?.customers?.customers?.data?.[currentCustomerId]?.name
    ) ?? '';
  const rfqLoading =
    useSelector((state) => state?.rfqs?.rfqs?.isLoading) ?? false;
  const customerLoading =
    useSelector((state) => state?.customers?.customers?.isLoading) ?? false;
  const selectedPartTypesList =
    useSelector((state) => state?.partTypes?.selectedPartTypes) ?? [];

  const isLoading = rfqLoading || customerLoading;

  const isShippingRole = AuthService.isShippingRole();

  const isCheckModalBackBtn =
    !!rfqInfo.description || selectedPartTypesList?.length > 0;

  const title = useMemo(() => {
    if (!isShippingRole && currentCustomerName) {
      return `${currentCustomerName}: New RFQ`;
    }

    return 'New RFQ';
  }, [isShippingRole, currentCustomerName]);

  const onСlickBack = () => {
    NavigationService.back();
  };

  const isEmptyParts = selectedPartTypesList.positions?.length === 0;

  const handleSaveRFQ = () => {
    const positions = selectedPartTypesList.map((item) => item.id);

    dispatch(
      rfqsActions.createRFQ.start({
        customer_id: currentCustomerId,
        part_type_ids: positions,
        description: rfqInfo.description,
      })
    );
  };

  const handleSubmitRFQ = () => {
    const positions = selectedPartTypesList.map((item) => item.id);

    dispatch(
      rfqsActions.submitNewRFQ.start({
        customer_id: currentCustomerId,
        part_type_ids: positions,
        description: rfqInfo.description,
      })
    );
  };

  useEffect(() => {
    dispatch(partTypesActions.addInitialSelectedPartTypes([]));
  }, [dispatch]);

  return (
    <CenteredColumnLayout>
      <RFQHeader
        title={title}
        onСlickBack={onСlickBack}
        handleNavigateBack={onСlickBack}
        handleSubmitRFQ={handleSubmitRFQ}
        handleSaveRFQ={handleSaveRFQ}
        isEmptyParts={isEmptyParts}
        isCheckModalBackBtn={isCheckModalBackBtn}
        isNewRFQ
      />
      <div className={st.mainContentHolder}>
        <RFQDetails rfqInfo={rfqInfo} setRFQInfo={setRFQInfo} isNewRFQ />
        <Loader isVisible={isLoading} />
      </div>
      <BeforeRedirect when={isCheckModalBackBtn && !isLoading} />
    </CenteredColumnLayout>
  );
};

export default NewRFQPage;
