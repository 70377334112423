import { VARIANT_OUTLINE_UI, VARIANT_UI } from 'constants/ui';
import React from 'react';
import { Button } from 'react-bootstrap';
import { string, func } from 'prop-types';
import st from './JobsOperationFooter.module.scss';

JobsOperationFooter.propTypes = {
  tiltleApplyBtn: string,
  tiltleCancelBtn: string,
  onApply: func,
  onCancel: func,
  buttonsLeft: func,
  buttonsRight: func,
};

function JobsOperationFooter({
  tiltleApplyBtn = 'Submit Done',
  tiltleCancelBtn = 'Can’t Submit',
  onApply = () => {},
  onCancel = () => {},
  buttonsLeft,
  buttonsRight,
  children,
}) {
  return (
    <div className={st.container}>
      <div className={st.buttonGroupLeft}>
        {typeof buttonsLeft === 'function' && buttonsLeft()}
      </div>
      <div className={st.buttonGroupRight}>
        {typeof buttonsRight === 'function' ? (
          buttonsRight()
        ) : (
          <>
            {children}
            <Button variant={VARIANT_OUTLINE_UI.secondary} onClick={onCancel}>
              {tiltleCancelBtn}
            </Button>
            <Button variant={VARIANT_UI.primary} onClick={onApply}>
              {tiltleApplyBtn}
            </Button>
          </>
        )}
      </div>
    </div>
  );
}

export default JobsOperationFooter;
