import { all, put, takeLatest, call, select } from 'redux-saga/effects';
import { handleRequestError } from 'services/api/apiTools';
import { getTemplateWithControl } from 'helpers/functions';
import { PAGE_SIZE } from 'constants/ui';
import ApiService from 'services/api';
import { MODULE_TYPES } from 'constants/files';
import { ROUTES } from 'router/routes';
import NavigationService from 'services/navigation/NavigationService';
import { getRoutePath } from 'helpers/path';
import * as actions from './actions';

export function* getTemplateListSaga(action) {
  const { isPagination, include_deleted } = action?.payload || {};

  const filterSearch = yield select((state) =>
    state?.templates?.fetchParams?.search?.trim()
  ) ?? '';

  const page = yield select(
    (state) => state?.templates?.fetchParams?.pagination?.page
  ) ?? 0;
  const size = yield select(
    (state) => state?.templates?.fetchParams?.pagination?.size
  ) ?? PAGE_SIZE;

  const filter = {
    filter: filterSearch,
    page: isPagination ? page : null,
    size: isPagination ? size : null,
    include_deleted,
  };

  let requestValue;
  try {
    requestValue = yield call(ApiService.getTemlateList, filter);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getTemplateList.failure());
    return;
  }

  const { temlateListObj, temlateIds } = getTemplateWithControl(
    requestValue.items
  );

  yield put(
    actions.getTemplateList.success({
      data: temlateListObj,
      ids: temlateIds,
      page: requestValue.page,
      count: requestValue.count,
    })
  );
}

export function* createTemplateSaga(action) {
  let requestValue;

  try {
    requestValue = yield call(ApiService.createTemlate, action.payload);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.createTemplate.failure());
    return;
  }

  NavigationService.replacePath(
    getRoutePath(ROUTES.adminTravelerTemplateDetails.path, {
      id: requestValue.id,
    })
  );

  yield put(actions.getTemplate.start(requestValue.id));
  yield put(actions.createTemplate.success());
}

export function* changeTemplateSaga(action) {
  const params = action.payload;
  let requestValue;

  try {
    requestValue = yield call(ApiService.changeTemplate, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.changeTemplate.failure());
    return;
  }

  yield put(actions.getTemplate.start(requestValue.id));
  yield put(actions.changeTemplate.success());
}

export function* removeTemplateSaga(action) {
  try {
    yield call(ApiService.removeTemplate, action.payload);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.removeTemplate.failure());
    return;
  }

  yield put(actions.getTemplateList.start({ isPagination: true }));
  yield put(actions.removeTemplate.success());
}

export function* getTemplateListForWizardSaga(action) {
  const params = action.payload;
  let requestValue;
  try {
    requestValue = yield call(ApiService.getTemlateList, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getTemplateListForWizard.failure());
    return;
  }
  const { temlateListObj, temlateIds } = getTemplateWithControl(
    requestValue.items
  );

  yield put(
    actions.getTemplateListForWizard.success({
      temlateListObj,
      temlateIds,
    })
  );
}

export function* addFileForTemplatedSaga(action) {
  const { file, id, phaseName, stepIndex } = action.payload;
  let requestValue;

  try {
    requestValue = yield call(ApiService.addFileType, {
      data: file,
      id,
      object: MODULE_TYPES.templates,
    });
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.addFileForTemplate.failure());
    return;
  }

  yield put(
    actions.addFileForTemplate.success({
      fileName: requestValue,
      phaseName,
      stepIndex,
    })
  );
}

export function* getTemplateSaga(action) {
  const idTemplate = action?.payload;

  let requestValue;
  try {
    requestValue = yield call(ApiService.getTemplate, idTemplate);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getTemplate.failure());
    return;
  }

  const { temlateListObj, temlateIds } = getTemplateWithControl([requestValue]);

  yield put(
    actions.getTemplate.success({
      data: temlateListObj,
      ids: temlateIds,
    })
  );
}

export function* templatesSagas() {
  yield all([
    yield takeLatest(actions.getTemplateList.start, getTemplateListSaga),
    yield takeLatest(actions.createTemplate.start, createTemplateSaga),
    yield takeLatest(actions.changeTemplate.start, changeTemplateSaga),
    yield takeLatest(actions.removeTemplate.start, removeTemplateSaga),
    yield takeLatest(
      actions.getTemplateListForWizard.start,
      getTemplateListForWizardSaga
    ),
    yield takeLatest(actions.addFileForTemplate.start, addFileForTemplatedSaga),
    yield takeLatest(actions.getTemplate.start, getTemplateSaga),
  ]);
}
