// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".DateRangePicker_wrapperTimeFrame__1AJFm {\n  position: relative;\n  width: 220px; }\n\n.DateRangePicker_holderCalendarIcon__1j6b6 {\n  position: absolute;\n  right: 12px;\n  top: 0;\n  bottom: 0;\n  height: 38px;\n  display: flex;\n  align-items: center;\n  pointer-events: none; }\n\n.DateRangePicker_calendarIcon__10nhm {\n  margin-top: -2px; }\n", "",{"version":3,"sources":["webpack://src/components/DateRangePicker/DateRangePicker.module.scss"],"names":[],"mappings":"AAEA;EACE,kBAAkB;EAClB,YAAY,EAAA;;AAGd;EACE,kBAAkB;EAClB,WAAW;EACX,MAAM;EACN,SAAS;EACT,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,oBAAoB,EAAA;;AAGtB;EACE,gBAAgB,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.wrapperTimeFrame {\n  position: relative;\n  width: 220px;\n}\n\n.holderCalendarIcon {\n  position: absolute;\n  right: 12px;\n  top: 0;\n  bottom: 0;\n  height: 38px;\n  display: flex;\n  align-items: center;\n  pointer-events: none;\n}\n\n.calendarIcon {\n  margin-top: -2px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapperTimeFrame": "DateRangePicker_wrapperTimeFrame__1AJFm",
	"holderCalendarIcon": "DateRangePicker_holderCalendarIcon__1j6b6",
	"calendarIcon": "DateRangePicker_calendarIcon__10nhm"
};
export default ___CSS_LOADER_EXPORT___;
