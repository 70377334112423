import { createReducer } from '@reduxjs/toolkit';
import { PAGE_SIZE } from 'constants/ui';
import { handleShowOrderList } from 'helpers/functions';
import * as actions from './actions';

export const initialState = {
  orders: {
    data: {},
    sortIds: [],
    isLoading: false,
    isRemoving: false,
  },
  isAddingOrder: {
    orderId: null,
    isNewOrder: false,
  },
  fetchParams: {
    pagination: {
      page: 0,
      size: PAGE_SIZE,
      count: 0,
    },
    search: '',
    isLoading: false,
    isFindByJob: false,
  },
  orderCardList: {
    isOpenOrderList: false,
    isChange: false,
  },
  isConvertFromRFQ: false,
  dataOrderAndJob: '',
  isScrollJob: false,
};

export const ordersReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(actions.getOrdersList.start, (state, action) => {
      const { params, showLoader = false, isClearPage } = action?.payload || {};

      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: !!showLoader,
        },

        fetchParams: {
          ...state.fetchParams,
          pagination: {
            ...state.fetchParams.pagination,
            page: isClearPage ? 0 : state.fetchParams.pagination.page,
          },
          isLoading: !!params?.filter,
          isFindByJob: false,
        },
      };
    })
    .addCase(actions.getOrdersList.success, (state, { payload }) => {
      const { page, count, isFindByJob } = payload;

      return {
        ...state,
        orders: {
          ...state.orders,
          data: payload.fetchedValue,
          sortIds: payload.ids,
          isLoading: false,
        },
        fetchParams: {
          ...state.fetchParams,
          pagination: {
            ...state.fetchParams.pagination,
            page,
            count,
          },
          isLoading: false,
          isFindByJob,
        },
      };
    })
    .addCase(actions.getOrdersList.failure, (state, { payload }) => ({
      ...state,
      orders: {
        ...state.orders,
        isLoading: !!payload?.showLoader,
      },
      fetchParams: {
        ...state.fetchParams,
        isLoading: false,
        isFindByJob: false,
      },
    }))
    .addCase(actions.createOrder.start, (state) => ({
      ...state,
      orders: { ...state.orders, isLoading: true },
    }))
    .addCase(actions.createOrder.success, (state, action) => {
      const isAddingOrderId = action.payload;

      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: false,
        },
        isAddingOrder: {
          ...state.isAddingOrder,
          orderId: isAddingOrderId,
        },
      };
    })
    .addCase(actions.createOrder.failure, (state) => ({
      ...state,
      orders: { ...state.orders, isLoading: false },
    }))
    .addCase(actions.getOrder.start, (state) => ({
      ...state,
      orders: { ...state.orders, isLoading: true },
    }))
    .addCase(actions.getOrder.success, (state, { payload }) => {
      return {
        ...state,
        orders: {
          ...state.orders,
          data: payload.data,
          sortIds: payload.sortIds,
          isLoading: false,
        },
      };
    })
    .addCase(actions.getOrder.failure, (state) => ({
      ...state,
      orders: { ...state.orders, isLoading: false },
    }))
    .addCase(actions.updateOrder.start, (state) => ({
      ...state,
      orders: { ...state.orders, isLoading: true },
    }))
    .addCase(actions.updateOrder.success, (state, action) => {
      const { data } = action.payload;

      return {
        ...state,
        orders: {
          ...state.orders,
          data: { ...state.orders.data, ...data },
          isLoading: false,
        },
      };
    })
    .addCase(actions.updateOrder.failure, (state) => ({
      ...state,
      orders: { ...state.orders, isLoading: false },
    }))
    .addCase(actions.submitOrder.start, (state) => ({
      ...state,
      orders: { ...state.orders, isLoading: true },
    }))
    .addCase(actions.submitOrder.success, (state) => {
      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: false,
        },
        isAddingOrder: {
          ...state.isAddingOrder,
          isNewOrder: true,
        },
      };
    })
    .addCase(actions.submitOrder.failure, (state) => ({
      ...state,
      orders: { ...state.orders, isLoading: false },
    }))
    .addCase(actions.submitNewOrder.start, (state) => ({
      ...state,
      orders: { ...state.orders, isLoading: true },
    }))
    .addCase(actions.submitNewOrder.success, (state, action) => {
      const isAddingOrderId = action.payload;

      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: false,
        },
        isAddingOrder: {
          ...state.isAddingOrder,
          orderId: isAddingOrderId,
          isNewOrder: true,
        },
      };
    })
    .addCase(actions.submitNewOrder.failure, (state) => ({
      ...state,
      orders: { ...state.orders, isLoading: false },
    }))
    .addCase(actions.removeOrder.start, (state) => ({
      ...state,
      orders: { ...state.orders, isRemoving: true, isLoading: true },
    }))
    .addCase(actions.removeOrder.success, (state) => {
      return {
        ...state,
        orders: {
          ...state.orders,
          isRemoving: false,
          isLoading: false,
        },
      };
    })
    .addCase(actions.removeOrder.failure, (state) => ({
      ...state,
      orders: { ...state.orders, isRemoving: false, isLoading: false },
    }))
    .addCase(actions.clearIsAddingOrder, (state) => ({
      ...state,
      isAddingOrder: {
        ...state.isAddingOrder,
        orderId: null,
        isNewOrder: false,
      },
    }))
    .addCase(actions.addIsNewOrder, (state) => ({
      ...state,
      isAddingOrder: {
        ...state.isAddingOrder,
        isNewOrder: true,
      },
    }))
    .addCase(actions.openOrderItem, (state, action) => {
      const orderId = action.payload;
      return {
        ...state,
        orders: {
          ...state.orders,
          data: {
            ...state.orders.data,
            [orderId]: { ...state.orders.data[orderId], show: true },
          },
        },
      };
    })
    .addCase(actions.orderExpandAll, (state) => {
      const data = handleShowOrderList(state.orders.data);
      return {
        ...state,
        orders: {
          ...state.orders,
          data,
        },
        orderCardList: {
          ...state.orderCardList,
          isOpenOrderList: true,
          isChange: true,
        },
      };
    })
    .addCase(actions.orderCollapseAll, (state) => {
      const data = handleShowOrderList(state.orders.data, false);
      return {
        ...state,
        orders: {
          ...state.orders,
          data,
        },
        orderCardList: {
          ...state.orderCardList,
          isOpenOrderList: false,
          isChange: true,
        },
      };
    })
    .addCase(actions.changeFilterSearch, (state, action) => {
      const currentValue = action.payload;
      return {
        ...state,
        fetchParams: {
          ...state.fetchParams,
          pagination: {
            ...state.fetchParams.pagination,
          },
          search: currentValue,
        },
      };
    })
    .addCase(actions.deactivateOrderCardListChange, (state) => {
      return {
        ...state,
        orderCardList: {
          ...state.orderCardList,
          isChange: false,
        },
      };
    })
    .addCase(actions.changePage, (state, action) => {
      const page = action.payload;
      return {
        ...state,
        fetchParams: {
          ...state.fetchParams,
          pagination: {
            ...state.fetchParams.pagination,
            page,
          },
        },
      };
    })
    .addCase(actions.changeIsConvertFromRFQ, (state, action) => {
      const value = action.payload;

      return {
        ...state,
        isConvertFromRFQ: !!value,
      };
    })
    .addCase(actions.updateOrderScore.start, (state) => ({
      ...state,
      orders: { ...state.orders, isLoading: true },
    }))
    .addCase(actions.updateOrderScore.success, (state) => {
      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: false,
        },
      };
    })
    .addCase(actions.updateOrderScore.failure, (state) => ({
      ...state,
      orders: { ...state.orders, isLoading: false },
    }))
    .addCase(actions.updateOrderEndDate.start, (state) => ({
      ...state,
      orders: { ...state.orders, isLoading: true },
    }))
    .addCase(actions.updateOrderEndDate.success, (state) => {
      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: false,
        },
      };
    })
    .addCase(actions.updateOrderEndDate.failure, (state) => ({
      ...state,
      orders: { ...state.orders, isLoading: false },
    }))
    .addCase(actions.changeDataOrderAndJob, (state, action) => {
      const value = action.payload || '';

      return {
        ...state,
        dataOrderAndJob: value,
      };
    })
    .addCase(actions.changeIsScrollJob, (state, action) => {
      const value = action.payload || false;

      return {
        ...state,
        isScrollJob: value,
      };
    })
    .addCase(actions.cancelOrder.start, (state) => ({
      ...state,
      orders: { ...state.orders, isLoading: true },
    }))
    .addCase(actions.cancelOrder.success, (state, action) => {
      const data = action.payload || {};

      return {
        ...state,
        orders: {
          ...state.orders,
          isLoading: false,
          data: { ...state.orders.data, ...data },
        },
      };
    })
    .addCase(actions.cancelOrder.failure, (state) => ({
      ...state,
      orders: { ...state.orders, isLoading: false },
    }));
});
