import React, { useMemo } from 'react';
import ModalButton from 'componentsShared/ModalButton/ModalButton';
import { VARIANT_OUTLINE_UI, VARIANT_UI } from 'constants/ui';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import Offcanvas from 'componentsShared/Offcanvas/Offcanvas';
import Text from 'componentsShared/Text/Text';
import { useDispatch } from 'react-redux';
import * as ncrActions from 'store/ncr/actions';
import { oneOfType, string, func, bool, object, number } from 'prop-types';
import OffcanvasEditNCR from '../OffcanvasEditNCR/OffcanvasEditNCR';
import st from './OffcanvasNCREdit.module.scss';

const validationSchema = Yup.object().shape({
  label: Yup.string().required('Required'),
});

OffcanvasNCREdit.propTypes = {
  selectedNCR: object,
  showOffcanvas: bool,
  setShowOffcanvas: func,
  selectNCRId: oneOfType([number, string]),
  ncrList: object,
};

function OffcanvasNCREdit({
  selectNCRIndex,
  selectedNCR,
  showOffcanvas,
  setShowOffcanvas,
  selectNCRId,
  ncrList = {},
}) {
  const dispatch = useDispatch();
  const selectNCRNumber = selectNCRIndex + 1;

  const initialValue = useMemo(
    () => ({
      label: selectedNCR?.label,
    }),
    [selectedNCR]
  );

  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: initialValue,
  };
  const { register, handleSubmit, formState, reset } = useForm(formOptions);
  const { errors, isDirty: isDirtyForm } = formState;

  const handleRemoveEmployee = (onHideModal) => {
    const currentData = {
      ...ncrList,
    };
    delete currentData[selectNCRId];

    const headers = Object.values(currentData).map(({ label }) => label);
    const isEmptyHeaders = headers.length === 0;

    if (isEmptyHeaders) {
      dispatch(ncrActions.removeNCR.start());
    } else {
      dispatch(ncrActions.createNCR.start({ headers }));
    }
    onHideModal();
  };

  const isDirty = isDirtyForm;

  const onSubmit = (data, onHideModal) => {
    const currentData = {
      ...ncrList,
      [selectNCRId]: { ...ncrList[selectNCRId], label: data.label },
    };
    const headers = Object.values(currentData).map(({ label }) => label);

    dispatch(ncrActions.createNCR.start({ headers }));

    setShowOffcanvas(false);
    onHideModal();
  };

  const handleClearFields = () => {
    reset();
  };

  const handleSubmitNCR = (onHide) => {
    handleSubmit((value) => onSubmit(value, onHide))();
  };

  return (
    <>
      <Offcanvas
        backdrop={false}
        onHide={handleClearFields}
        width={460}
        show={showOffcanvas}
        setShow={setShowOffcanvas}
        title={`NCR Field: Field ${selectNCRNumber}`}
        footerContent={({ onHide }) => (
          <div className={st.buttonGroup}>
            <Button
              variant={VARIANT_UI.primary}
              onClick={() => handleSubmitNCR(onHide)}
              disabled={!isDirty}>
              Save Field
            </Button>
            <ModalButton
              title='Delete NCR Field'
              titleAcceptBtn='Delete'
              heightBody={110}
              onAcceptBtn={() => handleRemoveEmployee(onHide)}
              variantAcceptBtn={VARIANT_UI.danger}
              bodyContent={() => (
                <Text variant='normal'>
                  Would you like to delete Field {selectNCRNumber}?
                </Text>
              )}
              buttonRenderer={({ onClick: openModal }) => (
                <Button
                  variant={VARIANT_OUTLINE_UI.secondary}
                  onClick={openModal}>
                  Delete NCR Field
                </Button>
              )}
            />
          </div>
        )}>
        <OffcanvasEditNCR
          register={register}
          errors={errors}
          onSubmit={handleSubmit(onSubmit)}
        />
      </Offcanvas>
    </>
  );
}

export default OffcanvasNCREdit;
