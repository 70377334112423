import React from 'react';
import Text from 'componentsShared/Text/Text';
import { string, bool } from 'prop-types';
import cn from 'classnames';
import st from './PlaceholderContent.module.scss';

PlaceholderContent.propTypes = {
  emptyText: string,
  searchEmptyText: string,
  isSearch: bool,
  classNameContainer: string,
  classNameText: string,
};

function PlaceholderContent({
  emptyText = 'Empty',
  searchEmptyText = 'Empty',
  isSearch = false,
  classNameContainer,
  classNameText,
}) {
  return (
    <div className={cn(st.emptyContainer, classNameContainer)}>
      <Text variant='normal' className={cn(st.emptyText, classNameText)}>
        {isSearch ? searchEmptyText : emptyText}
      </Text>
    </div>
  );
}

export default PlaceholderContent;
