// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ModalBlockCustomerLogin_deleteCustomer__Qjo7G {\n  width: 100%; }\n", "",{"version":3,"sources":["webpack://src/components/Modal/ModalBlockCustomerLogin/ModalBlockCustomerLogin.module.scss"],"names":[],"mappings":"AAEA;EACE,WAAW,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.deleteCustomer {\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"deleteCustomer": "ModalBlockCustomerLogin_deleteCustomer__Qjo7G"
};
export default ___CSS_LOADER_EXPORT___;
