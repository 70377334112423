import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Text from 'componentsShared/Text/Text';
import JobsQAItemControlValues from 'components/Jobs/JobsQAItemControlValues/JobsQAItemControlValues';
import moment from 'moment';
import { getValidWorklogList } from 'helpers/functions';
import { FORMAT_DATE_USA_WITH_TIME } from 'constants/ui';
import JobsWorklogItemFile from 'components/Jobs/JobsWorklogItemFile/JobsWorklogItemFile';
import JobsWorklogItemAdditionalText from 'components/Jobs/JobsWorklogItemAdditionalText/JobsWorklogItemAdditionalText';
import JobsWorklogFileList from 'components/Jobs/JobsWorklogFileList/JobsWorklogFileList';
import st from './WorklogList.module.scss';

function WorklogList({ worklog }) {
  const dataList = getValidWorklogList(worklog);
  const dataHeader = [
    {
      name: 'Event',
      className: st.fieldName,
    },
    {
      name: 'Employee',
      className: st.fieldEmployee,
    },
    {
      name: 'Timestamp',
      className: st.fieldTimestamp,
    },
  ];

  const getOperationField = (value) => {
    if (value.isControlValues) {
      return (
        <JobsQAItemControlValues
          controlValues={value.controlValues}
          name={value.name}
        />
      );
    }

    if (value.isFiles) {
      return <JobsWorklogItemFile name={value.name} path={value.files[0]} />;
    }

    if (value.additionalText) {
      return (
        <JobsWorklogItemAdditionalText
          name={value.name}
          additionalText={value.additionalText}
        />
      );
    }

    if (value.isListFile) {
      return <JobsWorklogFileList name={value.name} fileList={value.files} />;
    }

    return (
      <Text varint='normal' bold={value.isStep}>
        {value.name}
      </Text>
    );
  };

  const renderOperationList = () => {
    return dataList.map((item) => {
      return (
        <div className={st.itemOperationContainer} key={uuidv4()}>
          <div className={st.fieldName}>{getOperationField(item)}</div>
          <div className={st.fieldEmployee}>
            <Text varint='normal'>{item.employee}</Text>
          </div>
          <div className={st.fieldTimestamp}>
            <Text varint='normal'>
              {item.timestamp
                ? moment(item.timestamp).format(FORMAT_DATE_USA_WITH_TIME)
                : '-'}
            </Text>
          </div>
        </div>
      );
    });
  };

  const renderHeader = () => {
    return dataHeader.map((item) => {
      return (
        <div className={item.className} key={uuidv4()}>
          <Text variant='normal' bold>
            {item.name}
          </Text>
        </div>
      );
    });
  };

  return (
    <div className={st.container}>
      <div className={st.headerContainer}>{renderHeader()}</div>
      <div className={st.listOperationContainer}>
        <div className={st.listOperationHolder}>
          {dataList.length > 0 && renderOperationList()}
          <div className={st.finishLine} />
        </div>
      </div>
    </div>
  );
}

export default WorklogList;
