import React, { useMemo, useState } from 'react';
import Text from 'componentsShared/Text/Text';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'react-bootstrap';
import * as jobsActions from 'store/jobs/actions';
import JobsOperationFooter from 'components/Jobs/JobsOperationFooter/JobsOperationFooter';
import { oneOfType, string, number, array } from 'prop-types';
import JobsOperationHeader from '../JobsOperationHeader/JobsOperationHeader';
import JobsIssueDetails from '../JobsIssueDetails/JobsIssueDetails';
import st from './JobsNCR.module.scss';
import JobDetailsInfoNCR from '../JobDetailsInfoNCR/JobDetailsInfoNCR';
import JobsMultiDropDownNCR from '../JobsMultiDropDownNCR/JobsMultiDropDownNCR';

JobsNCR.propTypes = {
  jobId: oneOfType([number, string]),
  jobIdList: array,
};

function JobsNCR({ jobId, jobIdList = [] }) {
  const dispatch = useDispatch();

  const currentJob =
    useSelector((state) => state?.jobs?.jobs?.data?.[jobId]) ?? {};
  const ncrList = useSelector((state) => state?.ncr?.ncr?.dataHeaders) ?? {};

  const ncrListArray = Object.values(ncrList) || [];
  const issueDetailsLabels = ncrListArray.map(({ label }) => label);

  const [issueDetails, setIssueDetails] = useState({});
  const [isShowErrorIssueDetails, setIsShowErrorIssueDetails] = useState(false);
  const [isShowErrorSelectStep, setIsShowErrorSelectStep] = useState(false);
  const [selectStep, setSelectStep] = useState(-1);

  const errorsIssueDetails = useMemo(() => {
    const currentErrors = {};

    if (!Array.isArray(issueDetailsLabels) || issueDetailsLabels.length === 0) {
      return currentErrors;
    }

    issueDetailsLabels.forEach((item) => {
      if (!issueDetails?.[item]) {
        currentErrors[item] = 'Require';
      }
    });

    return currentErrors;
  }, [issueDetails, issueDetailsLabels]);

  const onSubmit = () => {
    const isErrorIssueDetails = Object.keys(errorsIssueDetails).length > 0;
    const isErrorSelectStep = selectStep === -1;

    if (isErrorIssueDetails || isErrorSelectStep) {
      if (isErrorIssueDetails) {
        setIsShowErrorIssueDetails(true);
      }

      if (isErrorSelectStep) {
        setIsShowErrorSelectStep(true);
      }
      return;
    }

    const currentDataJob = {
      values: issueDetails,
      new_step: selectStep,
      new_operation: 0,
      new_status: 'ACTIVE',
    };

    const reqs = jobIdList.map((id) => ({ ...currentDataJob, id }));

    dispatch(
      jobsActions.submitJobNCR.start({
        reqs,
      })
    );
  };

  const jobDetails = [
    { label: 'Customer Name', value: currentJob?.customer_name || '' },
    { label: 'PO Number', value: currentJob?.purchase_order_number || '' },
    { label: 'Part Name', value: currentJob?.part_type?.name || '' },
    { label: 'Part Number', value: currentJob?.part_type?.number || '' },
    { label: 'Quantity', value: jobIdList?.length || 0 },
  ];

  return (
    <div className={st.container}>
      <JobsOperationHeader isShowText={false} />
      <div className={st.content}>
        <div>
          <Text variant='normal' bold>
            Job Details
          </Text>
          <JobDetailsInfoNCR jobDetails={jobDetails} />
        </div>
        {issueDetailsLabels.length > 0 && (
          <div className={st.wrapperJobDetails}>
            <Text variant='normal' bold>
              Issue Details
            </Text>
            <JobsIssueDetails
              issueDetailsLabels={issueDetailsLabels}
              issueDetails={issueDetails}
              setIssueDetails={setIssueDetails}
              isShowErrorIssueDetails={isShowErrorIssueDetails}
              setIsShowErrorIssueDetails={setIsShowErrorIssueDetails}
              errors={errorsIssueDetails}
            />
          </div>
        )}
        <div className={st.containerSelectStep}>
          <Text variant='normal' bold>
            Next Step to move the Jobs
          </Text>

          <JobsMultiDropDownNCR
            jobId={jobId}
            selectStep={selectStep}
            setSelectStep={setSelectStep}
            isError={isShowErrorSelectStep}
            setIsError={setIsShowErrorSelectStep}
          />
        </div>
      </div>
      <JobsOperationFooter
        buttonsRight={() => (
          <Button onClick={onSubmit}>Submit Report ({jobIdList.length})</Button>
        )}
        buttonsLeft={() => (
          <div>
            <Text variant='normal' className={st.footerInfoText}>
              {jobIdList.length} part{jobIdList.length === 1 ? '' : 's'} in the
              job series
            </Text>
          </div>
        )}
      />
    </div>
  );
}

export default JobsNCR;
