import { all, put, takeLatest, call } from 'redux-saga/effects';
import { handleRequestError } from 'services/api/apiTools';
import ApiService from 'services/api';
import { getParsingArrayToObj } from 'helpers/functions';
import * as actions from './actions';

export function* getWorklogsSaga(action) {
  const params = action.payload;
  let requestValue;
  try {
    requestValue = yield call(ApiService.getWorklog, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getWorklog.failure());
    return;
  }

  const data = getParsingArrayToObj(requestValue.items);
  const jobId = params;

  yield put(
    actions.getWorklog.success({
      data: { [jobId]: data },
      sortIds: jobId,
      jobId,
    })
  );
}

export function* getJobHistorySaga(action) {
  const params = action.payload;
  let requestValue;

  try {
    requestValue = yield call(ApiService.getJobHistory, params);
  } catch (errors) {
    handleRequestError(errors);
    yield put(actions.getJobHistory.failure());
    return;
  }

  const jobId = params;

  yield put(
    actions.getJobHistory.success({
      data: { [jobId]: requestValue },
      sortIds: jobId,
      jobId,
    })
  );
}

export function* worklogsSagas() {
  yield all([
    yield takeLatest(actions.getWorklog.start, getWorklogsSaga),
    yield takeLatest(actions.getJobHistory.start, getJobHistorySaga),
  ]);
}
