import React from 'react';
import { bool } from 'prop-types';
import HeaderNav from 'components/HeaderNav/HeaderNav';
import HeaderLogo from 'components/HeaderLogo/HeaderLogo';
import HeaderUserMenu from 'components/HeaderUserMenu/HeaderUserMenu';
import useMediaQuery from 'hooks/useMediaQuery';
import { IS_PHONE } from 'constants/ui';
import HeaderBurgerPhone from 'components/HeaderBurgerPhone/HeaderBurgerPhone';
import HeaderWrapperNotification from 'components/HeaderWrapperNotification/HeaderWrapperNotification';
import st from './Header.module.scss';

Header.propTypes = {
  showNavbar: bool,
  showUserMenu: bool,
};

function Header({ showNavbar = true, showUserMenu = true }) {
  const isPhone = useMediaQuery(IS_PHONE);

  if (isPhone) {
    return (
      <header className={st.headerWrapper}>
        <div className={st.headerContainer}>
          <div className={st.headerLogoContainer}>
            <HeaderLogo />
          </div>
          <div className={st.headerHolderBurger}>
            <HeaderWrapperNotification />
            {showNavbar && showUserMenu && <HeaderBurgerPhone />}
          </div>
        </div>
      </header>
    );
  }

  return (
    <header className={st.headerWrapper}>
      <div className={st.headerContainer}>
        <div className={st.headerLogoContainer}>
          <HeaderLogo />
        </div>
        {showNavbar && (
          <div className={st.headerNavContainer}>
            <HeaderNav />
          </div>
        )}
        {showUserMenu && (
          <div className={st.headerDropdownContainer}>
            <HeaderUserMenu />
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;
