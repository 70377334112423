import Text from 'componentsShared/Text/Text';
import React, { useState } from 'react';
import { Form, FormControl, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { getValidPath } from 'helpers/path';

import { EyeSlashIcon, EyeIcon } from 'assets/icons';
import { MAX_LENGTH_INPUT } from 'constants/ui';
import { Link } from 'react-router-dom';
import { ROUTES } from 'router/routes';
import st from './SignUpConfirmForm.module.scss';

function SignUpConfirmForm({
  onSubmit = () => {},
  validationSchema = {},
  textRedirectLink = 'Login as a customer',
  hrefRedirectLink = ROUTES.signIn.path,
  handleClickRedirectLink = () => {},
}) {
  const formOptions = { resolver: yupResolver(validationSchema) };
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;

  return (
    <Form onSubmit={handleSubmit(onSubmit)} className={st.wrapperForm}>
      <div className={st.formGroup}>
        <Form.Label>Create a New Password</Form.Label>
        <div className={st.passwordWrapper}>
          <FormControl
            type={passwordShown ? 'text' : 'password'}
            isInvalid={errors.password}
            {...register('password')}
            maxLength={MAX_LENGTH_INPUT}
          />
          <div
            className={st.iconShowPassword}
            onClick={() => setPasswordShown((prev) => !prev)}>
            {!passwordShown ? <EyeSlashIcon /> : <EyeIcon />}
          </div>
        </div>
        <Text variant='normal2' className={st.errorText}>
          {errors?.password?.message}
        </Text>
      </div>

      <div className={st.formGroup}>
        <Form.Label>Confirm Password</Form.Label>
        <div className={st.passwordWrapper}>
          <FormControl
            type={confirmPasswordShown ? 'text' : 'password'}
            isInvalid={errors.passwordConfirmation}
            {...register('passwordConfirmation')}
            maxLength={MAX_LENGTH_INPUT}
          />
          <div
            className={st.iconShowPassword}
            onClick={() => setConfirmPasswordShown((prev) => !prev)}>
            {!confirmPasswordShown ? <EyeSlashIcon /> : <EyeIcon />}
          </div>
        </div>
        <Text variant='normal2' className={st.errorText}>
          {errors?.passwordConfirmation?.message}
        </Text>
      </div>

      <Button type='submit' className={st.buttonLogin}>
        Confirm
      </Button>

      <Link
        className={st.hrefLoginCustemer}
        onClick={handleClickRedirectLink}
        to={getValidPath(hrefRedirectLink)}>
        <Text variant='normal2' className={st.textLoginCustemer}>
          {textRedirectLink}
        </Text>
      </Link>
    </Form>
  );
}

export default SignUpConfirmForm;
