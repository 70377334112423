import React, { useState } from 'react';
import { Form, FormControl } from 'react-bootstrap';
import { object, func } from 'prop-types';
import { EyeSlashIcon, EyeIcon } from 'assets/icons';
import TypeaheadMultiple from 'componentsShared/TypeaheadMultiple/TypeaheadMultiple';
import { useSelector } from 'react-redux';
import RequiredLabel from 'componentsShared/RequiredLabel/RequiredLabel';
import { MAX_LENGTH_INPUT } from 'constants/ui';
import { USER_ROLES } from 'constants/userRoles';
import st from './InviteEmployeeForm.module.scss';

InviteEmployeeForm.propTypes = {
  errors: object,
  setValue: func,
};

function InviteEmployeeForm({ register, errors, setValue }) {
  const employeeRoles =
    useSelector((state) => state?.employees?.roles?.data) ?? [];
  const [passwordShown, setPasswordShown] = useState(false);

  const handleChangeRole = (value) => {
    setValue('roles', value);
  };

  const optionsRole = employeeRoles
    .filter(
      (item) =>
        item !== USER_ROLES.managerNotifications &&
        item !== USER_ROLES.QANotifications
    )
    .map((item) => ({
      label: item,
      value: item,
    }));

  return (
    <Form className={st.wrapperForm}>
      <div className={st.formGroup}>
        <Form.Label>
          <RequiredLabel />
          Employee name
        </Form.Label>
        <FormControl
          {...register('name')}
          isInvalid={errors.name}
          maxLength={MAX_LENGTH_INPUT}
        />
      </div>

      <div className={st.formGroup}>
        <Form.Label>
          <RequiredLabel />
          Login name or email
        </Form.Label>
        <FormControl
          {...register('email')}
          isInvalid={errors.email}
          maxLength={MAX_LENGTH_INPUT}
        />
      </div>

      <div className={st.formGroup}>
        <Form.Label>
          <RequiredLabel />
          User role
        </Form.Label>
        <TypeaheadMultiple
          error={errors.roles}
          onChange={handleChangeRole}
          formProps={{ ...register('roles') }}
          options={optionsRole}
        />
      </div>

      <div className={st.formGroup}>
        <Form.Label>Get email notifications as</Form.Label>
        <Form.Check label='Manager' {...register('isManagerNotification')} />
        <Form.Check label='QA' {...register('isQANotification')} />
      </div>

      <div className={st.formGroup}>
        <Form.Label>
          <RequiredLabel />
          First login password
        </Form.Label>
        <div className={st.passwordWrapper}>
          <FormControl
            {...register('password')}
            isInvalid={errors.password}
            type={passwordShown ? 'text' : 'password'}
            maxLength={MAX_LENGTH_INPUT}
          />
          <div
            className={st.iconShowPassword}
            onClick={() => setPasswordShown((prev) => !prev)}>
            {!passwordShown ? <EyeSlashIcon /> : <EyeIcon />}
          </div>
        </div>
      </div>
    </Form>
  );
}

export default InviteEmployeeForm;
