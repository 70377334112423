import { getRequestAction } from 'helpers/functions';
import { createAction } from '@reduxjs/toolkit';

const ACTION_PREFIX_TOASTS = 'metadata';

export const getMessage = getRequestAction(
  `${ACTION_PREFIX_TOASTS}/getMessage`
);

export const getMessageHash = getRequestAction(
  `${ACTION_PREFIX_TOASTS}/getMessageHash`
);

export const updateMessage = getRequestAction(
  `${ACTION_PREFIX_TOASTS}/updateMessage`
);

export const updateIsUpadate = createAction(
  `${ACTION_PREFIX_TOASTS}/updateIsUpadate`
);
