import React, { useEffect, useState } from 'react';
import { VARIANT_OUTLINE_UI, VARIANT_UI } from 'constants/ui';
import { Button } from 'react-bootstrap';
import { QRIcon } from 'assets/icons';
import { TYPE_MODAL } from 'constants/jobs';
import { useDispatch, useSelector } from 'react-redux';
import ModalQR from 'components/Modal/ModalQR/ModalQR';
import { func, bool } from 'prop-types';
import * as jobsActions from 'store/jobs/actions';
import st from './JobListActionButtonQRCode.module.scss';

JobListActionButtonQRCode.propTypes = {
  handleFindJob: func,
  onlyQRCode: bool,
};

function JobListActionButtonQRCode({
  handleFindJob = () => {},
  onlyQRCode = false,
}) {
  const dispatch = useDispatch();

  const isCreateBatch =
    useSelector((state) => state?.jobs?.jobsBatch?.isCreateBatch) ?? false;
  const isLoadingCheckJob =
    useSelector((state) => state?.jobs?.checkJob?.isLoadingCheckJob) ?? false;
  const typeErrorQR =
    useSelector((state) => state?.jobs?.checkJob?.typeError) ?? '';
  const checkedJobId =
    useSelector((state) => state?.jobs?.checkJob?.jobId) ?? null;
  const checkedJobIdPhone =
    useSelector((state) => state?.jobs?.checkJob?.jobIdPhone) ?? null;

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState(TYPE_MODAL.qrCode);
  const [isErrorReaderQR, setIsErrorReaderQR] = useState(false);
  const [isError, setIsError] = useState(false);
  const [typeError, setTypeError] = useState(null);

  const [isLoadingQrPhone, setIsLoadingQrPhone] = useState(false);

  const openModal = (type) => {
    setModalType(type);
    setShowModal(true);
  };

  const onCancelModal = () => {
    setIsErrorReaderQR(false);
    setIsError(false);
    setTypeError(null);
    dispatch(jobsActions.clearErrorCheckJob());
    dispatch(jobsActions.changeJobIdPhone({ qr: null }));
  };

  const handleChangeType = (type) => {
    onCancelModal();
    setModalType(type);
  };

  const handleReaderError = () => {
    setIsErrorReaderQR(true);
  };

  const onFindJob = (jobId) => {
    handleFindJob(jobId);
  };

  const handleCheckId = (jobId) => {
    onFindJob(jobId);
  };

  const handleReadQR = (url) => {
    const [, jobId] = url.split('/api/jobs/qr/');
    onFindJob(jobId);
  };

  useEffect(() => {
    if (typeErrorQR) {
      setIsError(true);
      setTypeError(typeErrorQR);
    }
  }, [typeErrorQR]);

  useEffect(() => {
    if (checkedJobId) {
      onCancelModal();
      setShowModal(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedJobId]);

  useEffect(() => {
    if (checkedJobIdPhone) {
      setModalType(TYPE_MODAL.findId);
      setIsLoadingQrPhone(true);
      setShowModal(true);
      onFindJob(checkedJobIdPhone);
      setIsLoadingQrPhone(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedJobIdPhone]);

  return (
    <div>
      <div className={st.holderButton}>
        {!onlyQRCode && (
          <Button
            onClick={() => openModal(TYPE_MODAL.findId)}
            variant={VARIANT_OUTLINE_UI.primary}
            disabled={isCreateBatch}>
            # Find By ID
          </Button>
        )}
        <Button
          onClick={() => openModal(TYPE_MODAL.qrCode)}
          variant={VARIANT_UI.primary}
          disabled={isCreateBatch}>
          <div className={st.holderTextButtonQR}>
            <QRIcon className={st.QRIcon} />
            Scan QR
          </div>
        </Button>
      </div>

      <ModalQR
        handleReadQR={handleReadQR}
        handleCheckId={handleCheckId}
        show={showModal}
        setShow={setShowModal}
        modalType={modalType}
        handleChangeType={handleChangeType}
        handleReaderError={handleReaderError}
        isErrorReaderQR={isErrorReaderQR}
        isError={isError}
        typeError={typeError}
        onCancelModal={onCancelModal}
        onlyQRCode={onlyQRCode}
        isLoading={isLoadingCheckJob || isLoadingQrPhone}
      />
    </div>
  );
}

export default JobListActionButtonQRCode;
