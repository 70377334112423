import { getRequestAction } from 'helpers/functions';
import { createAction } from '@reduxjs/toolkit';

const ACTION_PREFIX = 'rfqs';

export const getRFQList = getRequestAction(`${ACTION_PREFIX}/getRFQList`);

export const createRFQ = getRequestAction(`${ACTION_PREFIX}/createRFQ`);

export const removeRFQ = getRequestAction(`${ACTION_PREFIX}/removeRFQ`);

export const changeFilterSearch = createAction(
  `${ACTION_PREFIX}/changeFilterSearch`
);

export const changePage = createAction(`${ACTION_PREFIX}/changePage`);

export const submitRFQ = getRequestAction(`${ACTION_PREFIX}/submitRFQ`);

export const submitNewRFQ = getRequestAction(`${ACTION_PREFIX}/submitNewRFQ`);

export const getRFQ = getRequestAction(`${ACTION_PREFIX}/getRFQ`);

export const updateRFQ = getRequestAction(`${ACTION_PREFIX}/updateRFQ`);

export const addFile = getRequestAction(`${ACTION_PREFIX}/addFile`);

export const getFiles = getRequestAction(`${ACTION_PREFIX}/getFiles`);

export const clearIsAddingRFQ = createAction(
  `${ACTION_PREFIX}/clearIsAddingRFQ`
);

export const addIsNewRFQ = createAction(`${ACTION_PREFIX}/addIsNewRFQ`);

export const removeFiles = getRequestAction(`${ACTION_PREFIX}/removeFiles`);

export const changeIsScrollRFQ = createAction(
  `${ACTION_PREFIX}/changeIsScrollRFQ`
);

export const changeScrollRFQId = createAction(
  `${ACTION_PREFIX}/changeScrollRFQId`
);
