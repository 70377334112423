import Text from 'componentsShared/Text/Text';
import React from 'react';
import cn from 'classnames';
import { string } from 'prop-types';
import { JOBS_STATUS_TEXT } from 'constants/jobs';
import st from './JobStatus.module.scss';

JobStatus.propTypes = {
  status: string,
  className: string,
  classNameStatusText: string,
  currentStatusText: string,
};

function JobStatus({
  status = '',
  className,
  classNameStatusText,
  quantity = null,
  currentStatusText = '',
}) {
  const statusText = currentStatusText || JOBS_STATUS_TEXT[status] || '';

  if (!status || !statusText) {
    return <></>;
  }

  return (
    <div className={cn(st.statusWrapper, className, st[status])}>
      <Text
        variant='normal3'
        className={cn(st.statusText, classNameStatusText)}
        bold>
        {quantity !== null && (
          <div className={st.holderQuantity}>{quantity}</div>
        )}
        <div>{statusText}</div>
      </Text>
    </div>
  );
}

export default JobStatus;
