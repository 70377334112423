import { TRAVELER_STATUS } from 'constants/traveler';
import { store } from 'store/store';

const TravelerService = {
  getCurrentState() {
    return store.getState();
  },
  checkStaus(travelerId) {
    const currentState = this.getCurrentState();
    const travelerStatus =
      currentState.travelers?.travelers?.data?.[travelerId]?.status ?? '';

    return travelerStatus;
  },
  isStatusDraft(travelerId) {
    const status = this.checkStaus(travelerId);
    return status.toUpperCase() === TRAVELER_STATUS.DRAFT.toUpperCase();
  },
  isStatusActual(travelerId) {
    const status = this.checkStaus(travelerId);
    return status.toUpperCase() === TRAVELER_STATUS.ACTUAL.toUpperCase();
  },
  getStatusTraveler(travelerId) {
    const travelerStatus = this.checkStaus(travelerId);

    let currentStatus = '';
    switch (travelerStatus.toUpperCase()) {
      case TRAVELER_STATUS.DRAFT.toUpperCase():
        currentStatus = TRAVELER_STATUS.DRAFT;
        break;
      case TRAVELER_STATUS.ACTUAL.toUpperCase():
        currentStatus = TRAVELER_STATUS.ACTUAL;
        break;
      default:
        currentStatus = '';
    }
    return currentStatus;
  },
  isEmptySteps(travelerId) {
    const currentState = this.getCurrentState();
    const travelerStepList =
      currentState.travelers?.travelers?.data?.[travelerId]?.steps ?? [];
    return travelerStepList.length === 0;
  },
};

export default TravelerService;
