// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".WrapperDroppableDelete_placeholderNone__VeFjR {\n  position: absolute;\n  opacity: 0;\n  z-index: 0; }\n", "",{"version":3,"sources":["webpack://src/components/Traveler/WrapperDroppableDelete/WrapperDroppableDelete.module.scss"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,UAAU;EACV,UAAU,EAAA","sourcesContent":[".placeholderNone {\n  position: absolute;\n  opacity: 0;\n  z-index: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"placeholderNone": "WrapperDroppableDelete_placeholderNone__VeFjR"
};
export default ___CSS_LOADER_EXPORT___;
