import { getQueryParam } from 'helpers/path';
import { api } from './ApiService';

export const getClientData = () => {
  return api.get('/client/me');
};

export const loginCustomer = (data) => {
  return api.post('/client/login', data);
};

export const getClientPOList = (params) => {
  const queryParam = getQueryParam(params);
  return api.get(`/client/orders${queryParam}`);
};

export const getClientPartList = (query) => {
  const queryParam = getQueryParam(query);
  return api.get(`/client/parttypes${queryParam}`);
};

export const createClientOrder = (data) => {
  return api.post(`/client/orders`, data);
};

export const updateClientOrder = ({ id, data }) => {
  return api.put(`/client/orders/${id}`, data);
};

export const removeClientOrder = (id) => {
  return api.delete(`/client/orders/${id}`);
};

export const getClientOrder = (id) => {
  return api.get(`/client/orders/${id}`);
};

export const createClientPart = (data) => {
  return api.post('/client/parttypes', data);
};

export const getClientPart = (id) => {
  return api.get(`/client/parttypes/${id}`);
};

export const changeClientPart = ({ id, data }) => {
  return api.put(`/client/parttypes/${id}`, data);
};

export const removeClientPart = (id) => {
  return api.delete(`/client/parttypes/${id}`);
};

export const getJobHistory = (id) => {
  return api.get(`/client/jobhistory/${id}`);
};

export const getClientNoteList = ({ id, type }) => {
  return api.get(`/client/${type}/notes/${id}`);
};

export const createClientNote = ({ data, id, type }) => {
  return api.post(`/client/${type}/notes/${id}`, data);
};

export const getClientRFQList = (params) => {
  const queryParam = getQueryParam(params);
  return api.get(`/client/rfqs${queryParam}`);
};

export const createClientRFQ = (data) => {
  return api.post('/client/rfqs/', data);
};

export const getClientRFQ = (id) => {
  return api.get(`/client/rfqs/${id}`);
};

export const updateClientRFQ = ({ id, data }) => {
  return api.put(`/client/rfqs/${id}`, data);
};

export const removeClientRFQ = (id) => {
  return api.delete(`/client/rfqs/${id}`);
};

export const submitClientRFQ = (id) => {
  return api.put(`/client/rfqs/${id}`);
};
