import React from 'react';
import PropTypes from 'prop-types';

import st from './ErrorBoundary.module.scss';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, errorMessage: '' };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, errorMessage: error.toString() };
  }

  componentDidCatch() {
    // Customized error handling goes here!
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={st.rootWrapper}>Error: {this.state.errorMessage}</div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

export default ErrorBoundary;
