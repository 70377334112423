// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeaderLogo_logo__3EEAR {\n  width: 197px;\n  height: 30px; }\n", "",{"version":3,"sources":["webpack://src/components/HeaderLogo/HeaderLogo.module.scss"],"names":[],"mappings":"AAEA;EACE,YAAY;EACZ,YAAY,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.logo {\n  width: 197px;\n  height: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"logo": "HeaderLogo_logo__3EEAR"
};
export default ___CSS_LOADER_EXPORT___;
