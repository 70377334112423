import React, { useMemo, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { PlusCircle } from 'react-bootstrap-icons';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import ModalButton from 'componentsShared/ModalButton/ModalButton';
import Text from 'componentsShared/Text/Text';
import InputOperation from 'components/Traveler/InputOperation/InputOperation';
import TravelerOperation from 'components/Traveler/TravelerOperation/TravelerOperation';
import DropdownButton from 'componentsShared/DropdownButton/DropdownButton';
import * as travelersActions from 'store/travelers/actions';
import { useDispatch, useSelector } from 'react-redux';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useParams } from 'react-router';
import ModalFileSelect from 'components/Modal/ModalFileSelect/ModalFileSelect';
import { MoreVertIcon, TrashIcon } from 'assets/icons';
import WrapperDroppableDelete from 'components/Traveler/WrapperDroppableDelete/WrapperDroppableDelete';
import { DND_TYPE } from 'constants/traveler';
import { FILE_ACCEPT_IMAGE_AND_PDF, FILE_TYPES } from 'constants/files';
import AttachFilesButton from 'components/AttachFilesButton/AttachFilesButton';
import {
  MAX_FILE_SIZE,
  NOTIFICATION_ERROR_TYPE_FILE_TITLE,
  NOTIFICATION_LARGE_SIZE_FILE_TEXT,
  NOTIFICATION_LARGE_SIZE_FILE_TITLE,
  VARIANT_OUTLINE_UI,
  VARIANT_UI,
} from 'constants/ui';
import DragCustomPlaceholder from 'componentsShared/DragCustomPlaceholder/DragCustomPlaceholder';
import TravelerService from 'services/traveler/TravelerService';
import ControlValuesStep from 'components/ControlValuesStep/ControlValuesStep';
import NotificationsService from 'services/notifications/NotificationsService';
import cn from 'classnames';
import DropdownButtonControlOpen from 'componentsShared/DropdownButtonControlOpen/DropdownButtonControlOpen';
import Modal from 'componentsShared/Modal/Modal';
import st from './TravelerStep.module.scss';

TravelerStep.propTypes = {
  stepNumber: PropTypes.number,
  type: PropTypes.string,
  operations: PropTypes.array.isRequired,
  handleChangeOperation: PropTypes.func.isRequired,
  stepId: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]),
  handleDeleteStep: PropTypes.func.isRequired,
  handleDeleteOperation: PropTypes.func.isRequired,
  currentRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  stepIndex: PropTypes.number,
  isDraggingOver: PropTypes.bool,
  handleAddFileForOperation: PropTypes.func.isRequired,
  isDragOperation: PropTypes.bool,
  isDragRequireFile: PropTypes.bool,
  isDragFile: PropTypes.bool,
  placeholderProps: PropTypes.shape({
    props: PropTypes.object,
    type: PropTypes.string,
    sourceDroppableId: PropTypes.string,
  }),
  phaseType: PropTypes.string,
  dropdownListStep: PropTypes.array,
  handleToggleStep: PropTypes.func,
  bundleId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  informalQa: PropTypes.bool,
  handleUpdateInformalQa: PropTypes.func,
  isShowControlValues: PropTypes.bool,
  handleAddControlValues: PropTypes.func,
  handleAddFileRequireFile: PropTypes.func,
  isControlValues: PropTypes.bool,
};

function TravelerStep({
  stepNumber = 1,
  name = '',
  operations,
  handleChangeOperation = () => {},
  stepId,
  handleDeleteStep = () => {},
  handleDeleteOperation = () => {},
  currentRef,
  provided,
  stepIndex,
  isDraggingOver,
  handleAddFileForOperation,
  isDragOperation,
  isDragFile,
  isDragRequireFile,
  snapshotStep,
  placeholderProps,
  phaseName,
  dropdownListStep,
  handleToggleStep,
  bundleId,
  informalQa = false,
  handleUpdateInformalQa = () => {},
  isShowControlValues = false,
  handleAddControlValues = () => {},
  handleAddFileRequireFile = () => {},
  isControlValues = false,
  phaseType,
}) {
  const dispatch = useDispatch();
  const { id: travelerId } = useParams();

  const controlValuesActiveIds =
    useSelector(
      (state) => state?.controlValues?.controlValues?.sortActiveIds
    ) ?? [];
  const controlValuesData =
    useSelector((state) => state?.controlValues?.controlValues?.data) ?? {};

  const [updaingOperationIds, setUpdaingOperationIds] = useState([]);
  const [isNewOperation, setIsNewOperation] = useState(false);
  const [showHideContent, setShowHideContent] = useState(false);
  const [selectFile, setSelectFile] = useState('');
  const [showModalHasControlValues, setShowModalHasControlValues] =
    useState(false);
  const [showModalNoneControlValues, setShowModalNoneControlValues] =
    useState(false);

  const isStatusActual = TravelerService.isStatusActual(travelerId);
  const isControlValuesStorage = controlValuesActiveIds.length > 0;

  const getDropdownListControlValues = () => {
    if (!controlValuesActiveIds || controlValuesActiveIds.length === 0) {
      return [];
    }

    return controlValuesActiveIds.map((item) => {
      const label = controlValuesData?.[item]?.name || '';
      const currentId = controlValuesData?.[item]?.id;
      const currentIndex = operations.length === 0 ? 0 : operations.length;

      return {
        content: () => <Text variant='normal'>{label}</Text>,
        onClick: () => {
          if (isControlValues) {
            return setShowModalHasControlValues(true);
          }
          handleAddControlValues(stepIndex, currentId, currentIndex);
        },
        disabled: isNewOperation,
      };
    });
  };

  const dropdownListControlValues = getDropdownListControlValues();

  const isDisabledAddImageBtn = useMemo(() => {
    if (
      operations.length === 0 ||
      operations.every(({ bundle_id }) => !!bundle_id) ||
      (operations.length === 1 && isControlValues) ||
      isNewOperation
    ) {
      return true;
    }

    return false;
  }, [operations, isNewOperation, isControlValues]);

  const dropdownListRequire = useMemo(() => {
    return [
      {
        content: () => <Text variant='normal'>File Upload</Text>,
        onClick: () => handleAddFileRequireFile(stepIndex),
        disabled: isDisabledAddImageBtn,
      },
      isControlValuesStorage
        ? {
            content: () => (
              <Text variant='normal' className={st.textCV} bold>
                control values
              </Text>
            ),
            disabled: true,
          }
        : {
            disabled: true,
            content: () => <></>,
          },
      ...dropdownListControlValues,
    ];
  }, [
    dropdownListControlValues,
    isDisabledAddImageBtn,
    handleAddFileRequireFile,
    stepIndex,
    isControlValuesStorage,
  ]);

  const onSelectStep = () => {
    setShowHideContent(false);
  };

  const handleOpenModalFile = (openModal) => {
    openModal();
    dispatch(travelersActions.getFileTraveler.start({ id: travelerId }));
  };

  const handleUploadFile = (file) => {
    dispatch(travelersActions.addFileTraveler.start({ id: travelerId, file }));
  };

  const onUploadFile = (type) => (file, e) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('type', type);

    e.target.value = '';

    const fileTypeArray = FILE_ACCEPT_IMAGE_AND_PDF.split(',').map((item) =>
      item.trim()
    );

    if (!fileTypeArray.includes(file.type)) {
      NotificationsService.throwNotification({
        title: NOTIFICATION_LARGE_SIZE_FILE_TITLE,
        text: NOTIFICATION_ERROR_TYPE_FILE_TITLE,
        variant: VARIANT_UI.danger,
      });

      return;
    }

    if (!file || file.size > MAX_FILE_SIZE) {
      NotificationsService.throwNotification({
        title: NOTIFICATION_LARGE_SIZE_FILE_TITLE,
        text: NOTIFICATION_LARGE_SIZE_FILE_TEXT,
        variant: VARIANT_UI.danger,
      });
    } else {
      handleUploadFile(formData);
    }
  };

  const dropdownListHeader = [
    {
      content: () => (
        <Text variant='normal' className={st.textRemove}>
          Remove Step
        </Text>
      ),
      onClick: () => handleDeleteStep(stepIndex),
    },
  ];

  const handleAddUpdating = (id) => {
    setUpdaingOperationIds((prev) => [...prev, id]);
  };

  const handleUpdating = (value, operationIndex) => {
    if (!value) {
      return handleDelete(operationIndex);
    }

    const operationId = operations[operationIndex].id;
    setUpdaingOperationIds((prev) =>
      prev.filter((item) => item !== operationId)
    );
    return handleChangeOperation(stepIndex, value, operationIndex);
  };

  const handleDelete = (operationIndex) => {
    handleDeleteOperation(stepIndex, operationIndex);
  };

  const cancelNewOperationCreation = () => {
    setIsNewOperation(false);
  };

  const handleAddNewOperation = (value) => {
    if (!value) {
      return setIsNewOperation(false);
    }
    handleChangeOperation(stepIndex, value);
    return setIsNewOperation(false);
  };

  const handleAddFile = () => {
    handleAddFileForOperation(stepIndex, selectFile);
  };

  const handleCloseModalFile = () => {
    setSelectFile('');
  };

  const renderOperations = () => {
    return (
      <Droppable
        droppableId={`${DND_TYPE.operation}${stepId}`}
        type={`${stepIndex}`}>
        {(providedDroppable, snapshotDroppableOperation) => (
          <div ref={providedDroppable.innerRef} className={st.operationList}>
            {operations.map((operation, i) => {
              const operationNumber = i + 1;
              const operationId = operation.id;

              return (
                <Draggable
                  key={`${stepId}${operationId}`}
                  draggableId={`${stepId}${operationId}`}
                  index={i}>
                  {(providedOperation, snapshotOperation) => (
                    <TravelerOperation
                      currentRef={providedOperation.innerRef}
                      provided={providedOperation}
                      isEdit={updaingOperationIds.includes(operationId)} // ! operation.id is UUID
                      operation={operation}
                      operationNumber={operationNumber}
                      handleCancelUpdaing={cancelNewOperationCreation}
                      handleAddUpdating={handleAddUpdating}
                      handleUpdating={handleUpdating}
                      operationIndex={i}
                      operationId={operationId}
                      stepId={stepId}
                      stepIndex={stepIndex}
                      snapshotOperation={snapshotOperation}
                      placeholderProps={placeholderProps}
                      phaseType={phaseType}
                    />
                  )}
                </Draggable>
              );
            })}
            {providedDroppable.placeholder}
            <DragCustomPlaceholder
              placeholderProps={placeholderProps}
              type={DND_TYPE.operation}
              isDraggingOver={snapshotDroppableOperation.isDraggingOver}
            />
          </div>
        )}
      </Droppable>
    );
  };

  return (
    <div
      ref={currentRef}
      {...provided.draggableProps}
      className={st.holderStep}>
      <div
        className={cn(st.container, {
          [st.stepDragging]: snapshotStep.isDragging,
        })}
        onMouseLeave={() => setShowHideContent(false)}
        onMouseMove={() => {
          if (!showHideContent) {
            setShowHideContent(true);
          }
        }}>
        <div
          className={cn(st.content, {
            [st.contentEmptyOperations]: operations.length === 0,
          })}>
          <div className={st.headerContainer}>
            <h4
              className={st.title}
              {...(!isStatusActual ? provided.dragHandleProps : {})}>
              Step {stepNumber}: {name}
            </h4>
            {isStatusActual ? (
              <div {...provided.dragHandleProps} />
            ) : (
              <div className={st.headerAction}>
                <DropdownButton
                  list={dropdownListHeader}
                  className={st.moreButtonWrapper}
                  classNameBtn={st.moreButton}
                  startIcon={<MoreVertIcon />}
                  btnVariant={VARIANT_OUTLINE_UI.secondary}
                  arrowNone
                />
              </div>
            )}
          </div>
          {operations?.length > 0 && renderOperations()}
          {operations?.length === 0 && !isNewOperation && (
            <div className={st.holderAlert}>
              <Alert variant='warning'>
                Add an operation in order to submit traveller
              </Alert>
            </div>
          )}

          {isNewOperation && (
            <InputOperation
              handleSubmit={handleAddNewOperation}
              titleAcceptBtn='Add'
              phaseName={phaseName}
              phaseType={phaseType}
            />
          )}
          {!isStatusActual && (
            <WrapperDroppableDelete
              stepIndex={stepIndex}
              isDrag={isDragOperation || isDragFile || isDragRequireFile}
              dragComponent={() => (
                <div className={st.containerDroppableDelete}>
                  <TrashIcon className={st.trashIcon} />
                </div>
              )}
              component={() => (
                <div className={st.buttonGroup}>
                  <Button
                    variant={VARIANT_OUTLINE_UI.primary}
                    onClick={() => setIsNewOperation(true)}
                    disabled={isNewOperation}
                    size='sm'>
                    Add Operation
                  </Button>
                  <ModalButton
                    title='Attach an image from the cloud'
                    titleAcceptBtn='Attach'
                    heightBody={332}
                    disabledAcceptBtn={!selectFile}
                    onAcceptBtn={handleAddFile}
                    onClose={handleCloseModalFile}
                    bodyContent={() => (
                      <ModalFileSelect
                        selectFile={selectFile}
                        setSelectFile={setSelectFile}
                      />
                    )}
                    buttonRenderer={({ onClick: openModal }) => (
                      <Button
                        disabled={isDisabledAddImageBtn}
                        variant={VARIANT_OUTLINE_UI.primary}
                        onClick={() => handleOpenModalFile(openModal)}
                        size='sm'>
                        Add Image
                      </Button>
                    )}
                    buttonBottomLeft={() => (
                      <AttachFilesButton
                        handleChange={onUploadFile(FILE_TYPES.attachment)}
                        title='Upload new...'
                        varinant={VARIANT_OUTLINE_UI.secondary}
                        accept={FILE_ACCEPT_IMAGE_AND_PDF}
                      />
                    )}
                  />
                  {isShowControlValues && (
                    <DropdownButtonControlOpen
                      title='Require'
                      list={dropdownListRequire}
                      size='sm'
                      btnVariant={VARIANT_OUTLINE_UI.primary}
                    />
                  )}
                </div>
              )}
            />
          )}
          {isShowControlValues && (
            <ControlValuesStep
              controlValues={bundleId}
              stepIndex={stepIndex}
              informalQa={informalQa}
              handleUpdateInformalQa={handleUpdateInformalQa}
              isStatusActual={isStatusActual}
            />
          )}
        </div>

        {!isStatusActual && !isDraggingOver && showHideContent && (
          <div className={st.hiddenContent}>
            <div className={st.hiddenLine} />
            <DropdownButton
              title='Add Step'
              list={dropdownListStep}
              className={st.hiddenButton}
              startIcon={<PlusCircle className={st.hiddenButtonSvg} />}
              btnVariant={VARIANT_OUTLINE_UI.primary}
              onToggle={handleToggleStep}
              onSelect={onSelectStep}
            />
            <div className={st.hiddenLine} />
          </div>
        )}
      </div>

      <Modal
        title='Requiring Control Values'
        show={showModalHasControlValues}
        setShow={setShowModalHasControlValues}
        buttonBottomRight={({ onClose }) => (
          <>
            <Button onClick={onClose}>Okay</Button>
          </>
        )}>
        <Text variant='normal'>
          You can add only one bundle of Control Values to the step
        </Text>
      </Modal>

      <Modal
        title='Requiring Control Values'
        show={showModalNoneControlValues}
        setShow={setShowModalNoneControlValues}
        buttonBottomRight={({ onClose }) => (
          <>
            <Button onClick={onClose}>Okay</Button>
          </>
        )}>
        <Text variant='normal'>
          In order to require Control Values, the shoud be added as a bundle by
          Admin
        </Text>
      </Modal>
    </div>
  );
}

export default TravelerStep;
