import React, { useState } from 'react';
import { Button, Form, FormControl } from 'react-bootstrap';
import { MAX_LENGTH_INPUT, VARIANT_OUTLINE_UI, VARIANT_UI } from 'constants/ui';
import ModalButton from 'componentsShared/ModalButton/ModalButton';
import Text from 'componentsShared/Text/Text';
import { func, bool } from 'prop-types';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { reValidEmail } from 'constants/regulars';
import { useForm } from 'react-hook-form';
import st from './ModalUnblockCustomerLogin.module.scss';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .required('Required')
    .matches(reValidEmail, 'Invalid email'),
});

ModalUnblockCustomerLogin.propTypes = {
  isLoading: bool,
  handleAcceptBtn: func,
  onHide: func,
};

function ModalUnblockCustomerLogin({
  isLoading = false,
  handleAcceptBtn = () => {},
  onHide = () => {},
}) {
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState, reset } = useForm(formOptions);
  const { errors } = formState;

  const [isShowEmail, setIsShowEmail] = useState(false);

  const handleChangeCheckbox = (e) => {
    const value = e.target.checked;
    if (!value) {
      reset();
    }

    setIsShowEmail(value);
  };

  const onSubmit = (onHideModal, value) => {
    const { email } = value || {};
    handleAcceptBtn(onHide, onHideModal, isShowEmail, email);
  };

  return (
    <ModalButton
      title='Account unblocking'
      onHide={reset}
      buttonBottomRight={({ onHide: onHideModal }) => {
        return (
          <>
            <Button
              variant={VARIANT_OUTLINE_UI.secondary}
              onClick={onHideModal}>
              Cancel
            </Button>
            <Button
              variant={VARIANT_UI.primary}
              onClick={
                isShowEmail
                  ? handleSubmit((value) => onSubmit(onHideModal, value))
                  : () => onSubmit(onHideModal)
              }>
              Unblock
            </Button>
          </>
        );
      }}
      bodyContent={() => (
        <div className={st.content}>
          <Text variant='normal'>
            Unblocking users restores their access and requires to re-invite
            them.
          </Text>
          <Form.Check
            label='Change user email'
            checked={isShowEmail}
            onChange={handleChangeCheckbox}
          />

          {isShowEmail && (
            <FormControl
              isInvalid={errors.email}
              {...register('email')}
              maxLength={MAX_LENGTH_INPUT}
            />
          )}
        </div>
      )}
      buttonRenderer={({ onClick }) => (
        <Button
          variant={VARIANT_OUTLINE_UI.secondary}
          onClick={onClick}
          className={st.deleteCustomer}
          disabled={isLoading}>
          Unblock Account
        </Button>
      )}
    />
  );
}

export default ModalUnblockCustomerLogin;
