import { createAction } from '@reduxjs/toolkit';
import { getRequestAction } from 'helpers/functions';

const ACTION_PREFIX = 'partTypes';

export const getPart = getRequestAction(`${ACTION_PREFIX}/getPart`);

export const getPartList = getRequestAction(`${ACTION_PREFIX}/getPartList`);

export const setCurrentPartType = createAction(
  `${ACTION_PREFIX}/setCurrentPartType`
);

export const changeFilterSearch = createAction(
  `${ACTION_PREFIX}/changeFilterSearch`
);
export const createPart = getRequestAction(`${ACTION_PREFIX}/createPart`);

export const changePart = getRequestAction(`${ACTION_PREFIX}/changePart`);

export const removePart = getRequestAction(`${ACTION_PREFIX}/removePart`);

export const changePage = createAction(`${ACTION_PREFIX}/changePage`);

export const clearIsAddingPart = createAction(
  `${ACTION_PREFIX}/clearIsAddingPart`
);

export const addIsNewPart = createAction(`${ACTION_PREFIX}/addIsNewPart`);

export const addFile = getRequestAction(`${ACTION_PREFIX}/addFile`);

export const getFiles = getRequestAction(`${ACTION_PREFIX}/getFiles`);

export const getFileListByAllType = getRequestAction(
  `${ACTION_PREFIX}/getFileListByAllType`
);

export const removeFiles = getRequestAction(`${ACTION_PREFIX}/removeFiles`);

export const addSelectedPartTypes = createAction(
  `${ACTION_PREFIX}/addSelectedPartTypes`
);

export const changeQuantitySelectedPartTypes = createAction(
  `${ACTION_PREFIX}/changeQuantitySelectedPartTypes`
);

export const incrementQuantitySelectedPartTypes = createAction(
  `${ACTION_PREFIX}/incrementQuantitySelectedPartTypes`
);

export const decrementQuantitySelectedPartTypes = createAction(
  `${ACTION_PREFIX}/decrementQuantitySelectedPartTypes`
);

export const deleteSelectedPartTypes = createAction(
  `${ACTION_PREFIX}/deleteSelectedPartTypes`
);

export const addInitialSelectedPartTypes = createAction(
  `${ACTION_PREFIX}/addInitialSelectedPartTypes`
);

export const addQuantityForSelectedPartTypes = createAction(
  `${ACTION_PREFIX}/addQuantityForSelectedPartTypes`
);

export const clearSelectedNewPartId = createAction(
  `${ACTION_PREFIX}/clearSelectedNewPartId`
);

export const getFileByType = getRequestAction(`${ACTION_PREFIX}/getFileByType`);

export const changeIsScrollPart = createAction(
  `${ACTION_PREFIX}/changeIsScrollPart`
);

export const changeScrollPartId = createAction(
  `${ACTION_PREFIX}/changeScrollPartId`
);
