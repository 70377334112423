import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as employeesActions from 'store/employees/actions';
import ModalButton from 'componentsShared/ModalButton/ModalButton';
import { VARIANT_OUTLINE_UI, VARIANT_UI } from 'constants/ui';
import { Button } from 'react-bootstrap';
import Offcanvas from 'componentsShared/Offcanvas/Offcanvas';
import OffcanvasEmployeeEditContent from 'components/Offcanvas/OffcanvasEmployeeEditContent/OffcanvasEmployeeEditContent';
import Text from 'componentsShared/Text/Text';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import AuthService from 'services/auth/AuthService';
import * as Yup from 'yup';
import { object, func, number, string, oneOfType, bool } from 'prop-types';
import { USER_ROLES } from 'constants/userRoles';
import st from './OffcanvasEmployeeEdit.module.scss';

const validationSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  roles: Yup.array().min(1).required('Required'),
  password: Yup.string(),
  isManagerNotification: Yup.boolean(),
  isQANotification: Yup.boolean(),
});

OffcanvasEmployeeEdit.propTypes = {
  selectEmployeeId: oneOfType([number, string]),
  showOffcanvas: bool,
  setShowOffcanvas: func,
  initialValue: object,
  setSelectEmployeeId: func,
};

function OffcanvasEmployeeEdit({
  selectEmployeeId,
  showOffcanvas,
  setShowOffcanvas,
  setSelectEmployeeId,
  initialValue,
}) {
  const dispatch = useDispatch();
  const employeeList =
    useSelector((state) => state?.employees?.employees?.data) ?? {};
  const currentEmloyee = employeeList?.[selectEmployeeId];
  const formOptions = {
    resolver: yupResolver(validationSchema),
    defaultValues: {
      ...initialValue,
      roles: initialValue.roles.filter(
        (item) =>
          item.value !== USER_ROLES.managerNotifications &&
          item !== USER_ROLES.QANotifications
      ),
      isManagerNotification: initialValue.roles.some(
        (item) => item.value === USER_ROLES.managerNotifications
      ),
      isQANotification: initialValue.roles.some(
        (item) => item === USER_ROLES.QANotifications
      ),
    },
  };

  const { register, handleSubmit, formState, setValue } = useForm(formOptions);
  const { errors, isDirty } = formState;

  const handleChangeRole = (value) => {
    setValue('roles', value);
  };

  const handleRemoveEmployee = (onClose) => {
    dispatch(employeesActions.removeEmployee.start(currentEmloyee.login));
    onClose();
  };

  const onSubmit = (data, onClose) => {
    const currentRole = [
      ...data.roles.map((item) => item.value),
      ...(data.isManagerNotification ? [USER_ROLES.managerNotifications] : []),
      ...(data.isQANotification ? [USER_ROLES.QANotifications] : []),
    ];

    const validValue = {
      name: data.name,
      roles: currentRole,
      ...(data?.password ? { password: data.password } : {}),
    };
    dispatch(
      employeesActions.changeEmployee.start({
        data: validValue,
        login: currentEmloyee.login,
      })
    );
    onClose();
  };

  const isCurrentEmployee = AuthService.isCurrentEmployee(initialValue.login);

  return (
    <Offcanvas
      backdrop={false}
      onHide={() => {
        setShowOffcanvas(false);
        setSelectEmployeeId(undefined);
      }}
      width={460}
      show={showOffcanvas}
      setShow={setShowOffcanvas}
      title='Employee Details'
      footerContent={({ onHide }) => (
        <ModalButton
          title='Delete Employee'
          titleAcceptBtn='Delete'
          heightBody={110}
          onAcceptBtn={() => handleRemoveEmployee(onHide)}
          variantAcceptBtn={VARIANT_UI.danger}
          bodyContent={() => (
            <Text variant='normal'>Would you like to delete Employee?</Text>
          )}
          buttonRenderer={({ onClick: openModal }) => (
            <div className={st.buttonGroup}>
              <Button
                variant={VARIANT_UI.primary}
                type='submit'
                disabled={!isDirty}
                onClick={handleSubmit((value) => onSubmit(value, onHide))}>
                Save Changes
              </Button>
              {!isCurrentEmployee && (
                <Button
                  variant={VARIANT_OUTLINE_UI.secondary}
                  onClick={openModal}
                  className={st.deleteEmployee}>
                  Delete Employee
                </Button>
              )}
            </div>
          )}
        />
      )}>
      <OffcanvasEmployeeEditContent
        employee={employeeList?.[selectEmployeeId]}
        register={register}
        onSubmit={handleSubmit(onSubmit)}
        errors={errors}
        handleChangeRole={handleChangeRole}
        initialValue={initialValue}
      />
    </Offcanvas>
  );
}

export default OffcanvasEmployeeEdit;
