import React from 'react';
import { PersonOutlineIcon } from 'assets/icons';
import st from './Avatar.module.scss';

function Avatar() {
  return (
    <div className={st.container}>
      <PersonOutlineIcon />
    </div>
  );
}

export default Avatar;
