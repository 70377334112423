import {
  handleClickTravelerField,
  validFieldNCR,
  validFieldNCRFORNcrReport,
  validFieldTotalTime,
  validFieldTravelerId,
} from 'helpers/reports';
import { FORMAT_DATE_USA_WITH_TIME } from './ui';

export const REPORT_BREAKDOWN_NCR_KEY = 'ncr';

export const REPORT_BREAKDOWN = [
  {
    label: 'Employee',
    key: 'employee',
    chipLabel: 'emplyees',
    fileName: 'Employee',
  },
  {
    label: 'Job ID',
    key: 'jobID',
    chipLabel: 'job ids',
    fileName: 'Job-ID',
  },
  {
    label: 'Customer',
    key: 'customer',
    chipLabel: 'customers',
    fileName: 'Customer',
  },
  {
    label: 'NCR Reports',
    key: REPORT_BREAKDOWN_NCR_KEY,
    chipLabel: 'NCR',
    fileName: 'NCR',
  },
];

export const REPORT_TYPE_API = {
  employee: 'UserReport',
  jobID: 'JobReport',
  customer: 'CustomerReport',
  ncr: 'NCRReport',
};

export const REPORT_FIELDS_KEY = {
  employee: 'u.id',
  jobID: 'job.id',
  customer: 'customer.id',
};

export const REPORT_FIELD_LIST_EMPLOYEE = [
  'u.login',
  'job.id',
  'worklog.step_name',
  'worklog.phase_name',
  'po.purchase_number',
  'pt.number',
  'customer.name',
  'worklog.timestamp',
  'worklog.end_time',
  'total_time',
  'job.was_ncr',
  'pt.current_traveler_id',
];

export const REPORT_FIELD_LIST_JOB = [
  'job.id',
  'u.login',
  'worklog.step_name',
  'worklog.phase_name',
  'po.purchase_number',
  'pt.number',
  'customer.name',
  'worklog.timestamp',
  'worklog.end_time',
  'total_time',
  'job.was_ncr',
  'pt.current_traveler_id',
];

export const REPORT_FIELD_LIST_CUSTOMER = [
  'customer.name',
  'job.id',
  'u.login',
  'worklog.step_name',
  'worklog.phase_name',
  'po.purchase_number',
  'pt.number',
  'worklog.timestamp',
  'worklog.end_time',
  'total_time',
  'job.was_ncr',
  'pt.current_traveler_id',
];

export const REPORT_FIELD_LIST_NCR = [
  'job.id',
  'u.login',
  'worklog.step_name',
  'worklog.phase_name',
  'po.purchase_number',
  'pt.number',
  'customer.name',
  'worklog.timestamp',
  'worklog.control_values',
];

export const COLUMN_TABLE_EMPLOYEE = [
  { dataKey: 'u.login', width: 144, title: 'Employee' },
  {
    dataKey: 'job.id',
    width: 72,
    title: 'Job',
  },
  { dataKey: 'worklog.step_name', width: 200, title: 'Step' },
  { dataKey: 'worklog.phase_name', width: 106, title: 'Phase' },
  { dataKey: 'po.purchase_number', width: 106, title: 'PO Number' },
  { dataKey: 'pt.number', width: 106, title: 'Part Number' },
  { dataKey: 'customer.name', width: 120, title: 'Customer' },
  {
    dataKey: 'worklog.timestamp',
    width: 144,
    title: 'Time started',
    isDate: true,
    formatDate: FORMAT_DATE_USA_WITH_TIME,
  },
  {
    dataKey: 'worklog.end_time',
    width: 144,
    title: 'Time ended',
    isDate: true,
    formatDate: FORMAT_DATE_USA_WITH_TIME,
  },
  {
    dataKey: 'total_time',
    width: 100,
    title: 'Total Time',
    valid: validFieldTotalTime,
  },
  { dataKey: 'job.was_ncr', width: 100, title: 'NCR', valid: validFieldNCR },
  {
    dataKey: 'pt.current_traveler_id',
    width: 100,
    title: 'Traveler',
    valid: validFieldTravelerId,
    onClick: handleClickTravelerField,
  },
];

export const COLUMN_TABLE_JOB = [
  {
    dataKey: 'job.id',
    width: 72,
    title: 'Job',
  },
  { dataKey: 'u.login', width: 144, title: 'Employee' },
  { dataKey: 'worklog.step_name', width: 200, title: 'Step' },
  { dataKey: 'worklog.phase_name', width: 106, title: 'Phase' },
  { dataKey: 'po.purchase_number', width: 106, title: 'PO Number' },
  { dataKey: 'pt.number', width: 106, title: 'Part Number' },
  { dataKey: 'customer.name', width: 120, title: 'Customer' },
  {
    dataKey: 'worklog.timestamp',
    width: 144,
    title: 'Time started',
    isDate: true,
    formatDate: FORMAT_DATE_USA_WITH_TIME,
  },
  {
    dataKey: 'worklog.end_time',
    width: 144,
    title: 'Time ended',
    isDate: true,
    formatDate: FORMAT_DATE_USA_WITH_TIME,
  },
  {
    dataKey: 'total_time',
    width: 100,
    title: 'Total Time',
    valid: validFieldTotalTime,
  },
  { dataKey: 'job.was_ncr', width: 100, title: 'NCR', valid: validFieldNCR },
  {
    dataKey: 'pt.current_traveler_id',
    width: 100,
    title: 'Traveler',
    valid: validFieldTravelerId,
    onClick: handleClickTravelerField,
  },
];

export const COLUMN_TABLE_CUSTOMER = [
  { dataKey: 'customer.name', width: 120, title: 'Customer' },
  {
    dataKey: 'job.id',
    width: 72,
    title: 'Job',
  },
  { dataKey: 'u.login', width: 144, title: 'Employee' },
  { dataKey: 'worklog.step_name', width: 200, title: 'Step' },
  { dataKey: 'worklog.phase_name', width: 106, title: 'Phase' },
  { dataKey: 'po.purchase_number', width: 106, title: 'PO Number' },
  { dataKey: 'pt.number', width: 106, title: 'Part Number' },
  {
    dataKey: 'worklog.timestamp',
    width: 144,
    title: 'Time started',
    isDate: true,
    formatDate: FORMAT_DATE_USA_WITH_TIME,
  },
  {
    dataKey: 'worklog.end_time',
    width: 144,
    title: 'Time ended',
    isDate: true,
    formatDate: FORMAT_DATE_USA_WITH_TIME,
  },
  {
    dataKey: 'total_time',
    width: 100,
    title: 'Total Time',
    valid: validFieldTotalTime,
  },
  {
    dataKey: 'job.was_ncr',
    width: 100,
    title: 'NCR',
    valid: validFieldNCR,
  },
  {
    dataKey: 'pt.current_traveler_id',
    width: 100,
    title: 'Traveler',
    valid: validFieldTravelerId,
    onClick: handleClickTravelerField,
  },
];

export const COLUMN_TABLE_NCR = [
  {
    dataKey: 'job.id',
    width: 72,
    title: 'Job',
  },
  { dataKey: 'u.login', width: 144, title: 'Employee' },
  { dataKey: 'worklog.step_name', width: 200, title: 'Step' },
  { dataKey: 'worklog.phase_name', width: 106, title: 'Phase' },
  { dataKey: 'po.purchase_number', width: 106, title: 'PO Number' },
  { dataKey: 'pt.number', width: 106, title: 'Part Number' },
  { dataKey: 'customer.name', width: 120, title: 'Customer', flexGrow: true },
  {
    dataKey: 'worklog.timestamp',
    width: 144,
    title: 'Time started',
    isDate: true,
    formatDate: FORMAT_DATE_USA_WITH_TIME,
  },
  {
    dataKey: 'worklog.control_values',
    width: 48,
    title: 'Details',
    valid: validFieldNCRFORNcrReport,
  },
];
