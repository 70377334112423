// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".TemplateDeletePhaseButton_deletePhase__o6h8P {\n  cursor: pointer; }\n", "",{"version":3,"sources":["webpack://src/components/TravalerTemplate/TemplateDeletePhaseButton/TemplateDeletePhaseButton.module.scss"],"names":[],"mappings":"AAEA;EACI,eAAe,EAAA","sourcesContent":["@import '~styles/global-imports';\n\n.deletePhase {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"deletePhase": "TemplateDeletePhaseButton_deletePhase__o6h8P"
};
export default ___CSS_LOADER_EXPORT___;
