import React, { useState } from 'react';
import { Form, FormControl, Button } from 'react-bootstrap';
import { MAX_LENGTH_INPUT, VARIANT_OUTLINE_UI } from 'constants/ui';
import { object, func } from 'prop-types';
import { EyeSlashIcon, EyeIcon } from 'assets/icons';
import TypeaheadMultiple from 'componentsShared/TypeaheadMultiple/TypeaheadMultiple';
import { useSelector } from 'react-redux';
import AuthService from 'services/auth/AuthService';
import RequiredLabel from 'componentsShared/RequiredLabel/RequiredLabel';
import { USER_ROLES } from 'constants/userRoles';
import st from './EditEmployeeForm.module.scss';

EditEmployeeForm.propTypes = {
  initialValue: object,
  register: func,
  onSubmit: func,
  errors: object,
  handleChangeRole: func,
};

function EditEmployeeForm({
  initialValue,
  register,
  onSubmit: onSubmitForm,
  errors,
  handleChangeRole,
}) {
  const employeeRoles =
    useSelector((state) => state?.employees?.roles?.data) ?? [];

  const [showFieldPassword, setShowFieldPassword] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);

  const optionsRole = employeeRoles
    .filter(
      (item) =>
        item !== USER_ROLES.managerNotifications &&
        item !== USER_ROLES.QANotifications
    )
    .map((item) => ({
      label: item,
      value: item,
    }));

  const disabledInputRole = AuthService.isCurrentEmployee(initialValue.login);

  return (
    <Form className={st.wrapperForm} onSubmit={onSubmitForm}>
      <div className={st.formGroup}>
        <Form.Label>
          <RequiredLabel />
          Name
        </Form.Label>
        <FormControl
          {...register('name')}
          isInvalid={errors.name}
          maxLength={MAX_LENGTH_INPUT}
        />
      </div>

      <div className={st.formGroup}>
        <Form.Label>
          <RequiredLabel />
          Role
        </Form.Label>
        <TypeaheadMultiple
          onChange={handleChangeRole}
          formProps={{ ...register('roles') }}
          options={optionsRole}
          error={errors.roles}
          initialValue={initialValue.roles.filter(
            (item) =>
              item.value !== USER_ROLES.managerNotifications &&
              item !== USER_ROLES.QANotifications
          )}
          disabled={disabledInputRole}
        />
      </div>

      <div className={st.formGroup}>
        <Form.Label>Get email notifications as</Form.Label>
        <Form.Check
          {...register('isManagerNotification')}
          label='Manager'
          defaultChecked={initialValue.roles.some(
            (item) => item.value === USER_ROLES.managerNotifications
          )}
        />
        <Form.Check
          {...register('isQANotification')}
          label='QA'
          defaultChecked={initialValue.roles.some(
            (item) => item.value === USER_ROLES.QANotifications
          )}
        />
      </div>

      {showFieldPassword && (
        <div className={st.formGroup}>
          <Form.Label>First Login Password</Form.Label>
          <div className={st.passwordWrapper}>
            <FormControl
              {...register('password')}
              isInvalid={errors.password}
              type={passwordShown ? 'text' : 'password'}
              maxLength={MAX_LENGTH_INPUT}
            />
            <div
              className={st.iconShowPassword}
              onClick={() => setPasswordShown((prev) => !prev)}>
              {!passwordShown ? <EyeSlashIcon /> : <EyeIcon />}
            </div>
          </div>
        </div>
      )}

      {!showFieldPassword && (
        <div className={st.buttonGroup}>
          <Button
            variant={VARIANT_OUTLINE_UI.primary}
            onClick={() => setShowFieldPassword(true)}>
            Change Password
          </Button>
        </div>
      )}
    </Form>
  );
}

export default EditEmployeeForm;
