import React, { useState, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import CenteredColumnLayout from 'layouts/CenteredColumnLayout/CenteredColumnLayout';
import RFQHeader from 'components/RFQ/RFQHeader/RFQHeader';
import RFQDetails from 'components/RFQ/RFQDetails/RFQDetails';
import * as rfqsActions from 'store/rfqs/actions';
import * as customersActions from 'store/customers/actions';
import * as partTypesActions from 'store/partTypes/actions';
import Loader from 'componentsShared/Loader/Loader';
import NavigationService from 'services/navigation/NavigationService';
import AuthService from 'services/auth/AuthService';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useParams } from 'react-router';
// eslint-disable-next-line import/no-extraneous-dependencies
import { isEqual } from 'lodash';
import BeforeRedirect from 'componentsShared/BeforeRedirect/BeforeRedirect';
import st from './RFQDetailsPage.module.scss';

const RFQDetailsPage = () => {
  const { id: rfqId } = useParams();
  const isClient = AuthService.isClient();

  const dispatch = useDispatch();
  const [rfqInfo, setRFQInfo] = useState({ description: '' });
  const [initeilSelectedPartTypesList, setIniteilSelectedPartTypesList] =
    useState([]);

  const currentCustomerId =
    useSelector((state) => state?.customers?.currentCustomerId) ?? null;
  const currentCustomerName =
    useSelector(
      (state) => state?.customers?.customers?.data?.[currentCustomerId]?.name
    ) ?? '';
  const isShippingRole = AuthService.isShippingRole();
  const rfqList = useSelector((state) => state?.rfqs?.rfqs?.data) ?? {};
  const rfqLoading =
    useSelector((state) => state?.rfqs?.rfqs?.isLoading) ?? false;
  const customerLoading =
    useSelector((state) => state?.customers?.customers?.isLoading) ?? false;
  const selectedPartTypesList =
    useSelector((state) => state?.partTypes?.selectedPartTypes) ?? [];

  const selectedPartTypesListWithoutKey = selectedPartTypesList.map((item) => {
    const data = { ...item };
    delete data.key;
    return data;
  });

  const currentRFQ = rfqList[rfqId];

  const isLoading = rfqLoading || customerLoading;
  const isCheckModalBackBtn =
    currentRFQ?.description !== rfqInfo.description ||
    !isEqual(initeilSelectedPartTypesList, selectedPartTypesListWithoutKey);

  const title = useMemo(() => {
    if (!isShippingRole && currentCustomerName) {
      return `${currentCustomerName}: ${rfqId}`;
    }

    return rfqId;
  }, [isShippingRole, currentCustomerName, rfqId]);

  const isEmptyParts = selectedPartTypesList?.length === 0;

  const handleSaveRFQ = () => {
    const positions = selectedPartTypesList.map((item) => item.id);

    dispatch(
      rfqsActions.updateRFQ.start({
        data: {
          customer_id: currentCustomerId,
          part_type_ids: positions,
          description: rfqInfo.description,
        },
        id: rfqId,
      })
    );
  };

  const handleSubmitRFQ = () => {
    const positions = selectedPartTypesList.map((item) => item.id);

    dispatch(
      rfqsActions.submitRFQ.start({
        data: {
          customer_id: currentCustomerId,
          part_type_ids: positions,
          description: rfqInfo.description,
        },
        id: rfqId,
      })
    );
  };

  const navigateBack = () => {
    dispatch(rfqsActions.changeIsScrollRFQ(true));
    dispatch(rfqsActions.addIsNewRFQ());
    NavigationService.back();
  };

  useEffect(() => {
    const position = currentRFQ?.part_types;

    setIniteilSelectedPartTypesList(position);
    dispatch(partTypesActions.addInitialSelectedPartTypes(position));

    setRFQInfo((prev) => ({
      ...prev,
      description: currentRFQ?.description || '',
    }));
  }, [currentRFQ, dispatch]);

  useEffect(() => {
    if (currentCustomerId || isClient) {
      dispatch(
        partTypesActions.getPartList.start({ customer_id: currentCustomerId })
      );

      if (!isClient) {
        dispatch(customersActions.getCustomer.start(currentCustomerId));
      }
    }
  }, [dispatch, currentCustomerId, isClient]);

  useEffect(() => {
    if (rfqId) {
      dispatch(rfqsActions.getRFQ.start(rfqId));
    }
  }, [dispatch, rfqId]);

  return (
    <CenteredColumnLayout>
      <RFQHeader
        title={title}
        onСlickBack={navigateBack}
        handleNavigateBack={navigateBack}
        handleSubmitRFQ={handleSubmitRFQ}
        handleSaveRFQ={handleSaveRFQ}
        isEmptyParts={isEmptyParts}
        isCheckModalBackBtn={isCheckModalBackBtn}
      />
      <div className={st.mainContentHolder}>
        <RFQDetails
          rfqInfo={rfqInfo}
          setRFQInfo={setRFQInfo}
          currentRFQ={currentRFQ}
        />
        <Loader isVisible={isLoading} />
      </div>
      <BeforeRedirect when={isCheckModalBackBtn && !isLoading} />
    </CenteredColumnLayout>
  );
};

export default RFQDetailsPage;
